import { computed } from 'mobx';
import moment from 'moment';

import Model from 'core/model/Model';
import { DEFAULT_DATETIME_FORMAT } from 'core/util/dateUtils';

class AutoAckModel extends Model {
  get urlRoot() {
    return '/api/ui/alertingManager/alarms/ack/auto';
  }

  @computed
  get dimensionToKeyPart() {
    return this.get('key.value', {});
  }

  get expired() {
    const expirationTime = this.get('end_time');
    return !expirationTime ? false : moment(expirationTime).isBefore(moment());
  }

  get expirationDetails() {
    const expirationTime = this.get('end_time');
    if (!expirationTime) {
      return { expirationDay: null, expired: false, duration: null };
    }
    const momentDate = moment(expirationTime);
    return {
      expirationDay: momentDate.format(DEFAULT_DATETIME_FORMAT),
      expired: this.expired,
      duration: moment.duration(momentDate.diff(moment())).humanize()
    };
  }

  get measurementDimension() {
    const dimensions = Object.keys(this.dimensionToKeyPart);
    return dimensions.find((dimension) => dimension.endsWith('km_measurement_name'));
  }

  get measurement() {
    return this.dimensionToKeyPart[this.measurementDimension];
  }

  // Unwrap data from 'autoAck' object
  deserialize = (data = {}) => {
    const dataToDeserialize = data.autoAck || data;
    return super.serialize(dataToDeserialize);
  };

  // Wrap data in 'autoAck' object before saving
  serialize = (data = {}) => {
    const dataToSerialize = { autoAck: data.autoAck || data };
    return super.serialize(dataToSerialize);
  };
}

export default AutoAckModel;
