import Collection from 'core/model/Collection';
import { ALARM_NULL_END_DATE } from 'shared/alerting/constants';
import {
  alarmStateRenderer,
  durationRenderer,
  severityRenderer
} from 'app/views/synthetics/utils/alarmsTableRenderers';
import { isAlarmActive, getFirstTriggerTime } from 'shared/synthetics/utils';

class IncidentLogCollection extends Collection {
  sortState = { field: 'end_time', direction: 'asc' };

  get filterFieldWhitelist() {
    return new Set(['details', 'end_time', 'key', 'severity', 'start_time', 'state']);
  }

  getSortValue = (field, model) => {
    const { $syn } = this.store;
    const value = model.get(field);

    if (field === 'agent') {
      const agentId = model.get('key.value.agentId');

      if (agentId) {
        return $syn.agents.get(agentId)?.label || '-';
      }
    }

    if (field === 'details') {
      const { metrics } = value || {};

      return (
        // join all the metric labels as a single string
        (metrics || []).map(({ metric }) => metric).join('') || '-'
      );
    }

    if (field === 'duration') {
      return durationRenderer({ model });
    }

    if (field === 'end_time' && isAlarmActive(model.get('state'))) {
      return 'N/A';
    }

    if (field === 'event_start_time') {
      return getFirstTriggerTime(value) || 'N/A';
    }

    if (field === 'test') {
      const { testId } = model.get('key.value') || {};

      if (testId) {
        const test = $syn.tests.get(testId);

        if (test) {
          return test.get('display_name');
        }
      }

      return '-';
    }

    return value;
  };

  getFilterValues(model) {
    const { $syn } = this.store;
    const agentId = model.get('key.value.agentId');
    const metrics = (model.get('details.metrics') || [])
      .map((metric) => `${metric.metric} ${metric.severity}`)
      .join(' ');
    const rawEndTime = model.get('end_time');
    const endTime = !rawEndTime || rawEndTime === ALARM_NULL_END_DATE ? 'n/a now' : rawEndTime;

    return [
      model.id,
      metrics,
      severityRenderer({ raw: true })({ model }), // returns the raw severity label
      alarmStateRenderer({ raw: true })({ model }), // returns the raw state label
      model.get('details.target'),
      model.get('start_time'),
      endTime,
      $syn.agents.get(agentId)?.filterValue || 'unknown or removed',
      model.get('details.bgp.display'),
      model.get('details.bgp.prefix'),
      model.get('details.bgp.reason')
    ];
  }
}

export default IncidentLogCollection;
