import { computed } from 'mobx';
import moment from 'moment';

import { showErrorToast, showSuccessToast } from 'core/components';
import { getCdnFilters } from 'app/stores/cdn/queries';
import ServiceCollection from './ServiceCollection';
import ProviderCollection from './ProviderCollection';
import TypeCollection from './TypeCollection';
import TrackedServiceCollection from './TrackedServiceCollection';
import ServiceHistoryCollection from './ServiceHistoryCollection';
import * as queries from './queries';

class OttStore {
  services = new ServiceCollection();

  trackedServices = new TrackedServiceCollection();

  providers = new ProviderCollection();

  types = new TypeCollection();

  serviceHistory = new ServiceHistoryCollection();

  queries = queries;

  moduleName = 'ott';

  lookbackOptions = [
    { value: 86400, label: 'Last Day' },
    { value: 604800, label: 'Last Week' },
    { value: 1209600, label: 'Last 2 Weeks' },
    { value: 2592000, label: 'Last 30 Days' }
  ];

  get dangerThreshold() {
    return this.store.$moduleConfig.get('ott', 'thresholds.capacity.critical', 90);
  }

  get warningThreshold() {
    return this.store.$moduleConfig.get('ott', 'thresholds.capacity.warning', 60);
  }

  get filters() {
    return getCdnFilters({ moduleName: this.moduleName });
  }

  get isEnabled() {
    return this.store.$auth.getPermission('ott.enabled', false);
  }

  navigateToOttProvider = (provider) => {
    this.history.push(`/v4/service-provider/ott/provider/${provider}`);
  };

  navigateToOttAgentInstall = () => {
    this.history.push('/v4/settings/universal-agents', { directOtt: true });
  };

  getModuleConfig = () => this.store.$moduleConfig.fetchModuleConfig(this.moduleName);

  setModuleConfig = (newSettings) =>
    this.getModuleConfig()
      .then((existingSettings) =>
        this.store.$moduleConfig.saveModuleConfig({
          module: this.moduleName,
          settings: { ...existingSettings, ...newSettings }
        })
      )
      .then(
        (success) => {
          showSuccessToast('Configuration saved.');
          return success;
        },
        (error) => {
          console.error('moduleConfig save error', error);
          showErrorToast('Error saving configuration.');
          return error;
        }
      );

  // getStatusPercents
  getDeviceHealthBar = (interfaces) => {
    const results = { warning: 0, danger: 0, success: 0, total: 0 };

    interfaces.forEach((intf) => {
      const { service_traffic, snmp_traffic, snmp_speed } = intf.get ? intf.get() : intf;
      const capacity = parseInt(snmp_speed) * 1000000;
      const usagePercent = (snmp_traffic / capacity) * 100;
      results[this.getUsageHealthStatus(usagePercent)] += service_traffic;
      results.total += service_traffic;
    });

    return {
      danger: (results.danger / (results.total || 1)) * 100,
      warning: (results.warning / (results.total || 1)) * 100,
      success: (results.success / (results.total || 1)) * 100,
      totalBits: results.total
    };
  };

  // Compares interface usage against user-defined thresholds
  getUsageHealthStatus = (usagePercent) => {
    if (usagePercent > this.warningThreshold && usagePercent <= this.dangerThreshold) {
      return 'warning';
    }
    if (usagePercent > this.dangerThreshold) {
      return 'danger';
    }
    return 'success';
  };

  // Determine overall health status of a service from
  getServiceStatus = (serviceHealthStatus) => {
    const { success, warning, danger } = serviceHealthStatus;
    if (success >= 75) {
      return 'healthy';
    }

    if (danger > 0) {
      return 'critical';
    }

    if (warning > 0) {
      return 'warning';
    }

    return 'unavailable';
  };

  getLookbackOption = (value) => this.lookbackOptions.find((option) => option.value === value);

  getFromDate = (lookback_seconds) => moment().subtract(lookback_seconds, 'seconds').format('YYYY-MM-DD');

  @computed
  get lastUpdate() {
    return Math.max(this.services.lastUpdate, this.providers.lastUpdate, this.types.lastUpdate);
  }

  getServiceModel = (service) => this.services.models.find((model) => model.get('name') === service);

  getServiceModels = (key, value) => this.services.models.filter((model) => model.get(key).name === value);

  loadServiceHistory({ names, fromDate }) {
    return this.serviceHistory.fetch({ names, fromDate });
  }
}

export default new OttStore();
