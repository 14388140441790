import $auth from 'app/stores/$auth';

/**
 * Get the RBAC labels necessary for the active user to have full Read/Update/Delete permissions on a given RBAC domain.
 * This can be used when creating a new RBAC-associated item, to prepopulate the labels and thereby reduce the risk of a
 * user accidentally locking themselves out of modifying the item they just created.
 *
 * @param type {import('@kentik/ui-shared/rbac/constants').RBACType} RBAC domain you are searching for label restrictions.
 * @returns {number[]} labels associated with read/update/delete permissions required by this user
 */
export const getAssociatedUserRbacLabels = (type) => {
  const rbacMetadata = $auth.getActiveUserProperty('label_metadata') || {};
  return [
    ...new Set([
      ...(rbacMetadata[`${type}::read`] || []),
      ...(rbacMetadata[`${type}::update`] || []),
      ...(rbacMetadata[`${type}::delete`] || [])
    ])
  ];
};
