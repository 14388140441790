import Validator from 'validatorjs';
import { memoize } from 'lodash';
import { MAX_SAMPLING_RATE, MIN_SAMPLING_RATE } from '@kentik/ui-shared/hybrid/constants';

import $clouds from 'app/stores/clouds/$clouds';

import AwsExportConfigFields from 'app/views/setup/tasks/cloud_v2/CloudExportConfigWizard/components/aws/AwsExportConfigFields';
import AwsAutomatedSetup from 'app/views/setup/tasks/cloud_v2/CloudExportConfigWizard/components/aws/AwsAutomatedSetup';

import { trimTransform } from '../utils';

export const AWS_CLOUD_EXPORT_CONFIG_STEP = 'aws-export-configuration';
export const AWS_CLOUD_PROVIDER_CONFIG_STEP = 'aws-provider-configuration';
export const AWS_CLOUD_VERIFICATION_STEP = 'aws-verification';
export const AWS_CLOUD_FINAL_STEP = 'aws-final-steps';
export const AWS_TERRAFORM_STEP = 'aws-terraform';

const s3BucketFieldRules = ['required', 'min:3', 'max:63', 'awsBucket', 'string'];

// NOTE: this is the initial config, but field rendering and some validation is happening in
// src/app/views/setup/tasks/cloud_v2/CloudExportConfigWizard/components/aws/export_config/AwsExportConfigFields.js
export const AWS_EXPORT_ADDITIONAL_STEPS = [
  {
    id: AWS_CLOUD_EXPORT_CONFIG_STEP,
    title: 'Kentik Export AWS Configuration',
    component: AwsExportConfigFields,
    fieldsToRemove: ($cloudExportWizard) => {
      if ($cloudExportWizard.isFlowLogCollectionEnabled) {
        return [];
      }
      return [
        'properties.aws_bucket',
        'properties.aws_bucket_prefix',
        'properties.sampling',
        'properties.sampling_rate',
        'properties.sampling_type'
      ].filter((optionalField) => $cloudExportWizard.formHasField(optionalField));
    },
    generateFields: memoize(
      ($cloudExportWizard) => ({
        'properties.aws_iam_role_arn': {
          name: 'properties.aws_iam_role_arn',
          label: 'AWS Role',
          rules: 'required|min:20|string',
          helpText:
            'IAM user, group, role, and policy names must be unique within the account.' +
            'Names are not distinguished by case. For example, you cannot create resources named both “MyResource” and “myresource”.',
          transform: {
            out: trimTransform
          }
        },
        'properties.aws_iam_role_arn_is_org': {
          defaultValue: false,
          label:
            'This is an AWS organization role. If checked, metadata will be retrieved for all child accounts of this role.'
        },
        'properties.aws_region': {
          label: 'AWS Region',
          // defaultValue: 'us-west-2',
          placeholder: 'select a region...',
          rules: 'required',
          options: $cloudExportWizard.getFilteredOptionsByCloudProvider()
        },
        'properties.aws_bucket': {
          label: 'S3 Bucket Name',
          rules: [...s3BucketFieldRules, 'unique_bucket_name'],
          messages: {
            regex: 'AWS bucket names cannot contain uppercase letters, underscores, nor whitespaces.'
          },
          transform: {
            out: trimTransform
          }
        },
        'properties.aws_bucket_prefix': {
          label: 'S3 Bucket Prefix',
          placeholder: 'FlowLogs',
          transform: {
            out: trimTransform
          }
        },
        'properties.secondary_aws_suffix': {
          defaultValue: '',
          placeholder: 'root',
          label: 'Role Suffix'
        },
        'properties.secondary_aws_regions': {
          defaultValue: [],
          label: 'Topology Regions',
          placeholder: 'Select regions.',
          options: $cloudExportWizard.getFilteredOptionsByCloudProvider()
        },
        'properties.secondary_aws_accounts': {
          defaultValue: [],
          label: 'Secondary AWS Accounts',
          placeholder: 'Enter comma separated list of aws accounts.'
        },
        'properties.secondary_aws_blocked_accounts': {
          defaultValue: [],
          label: 'Accounts Blocklist'
        },
        'properties.sampling': { defaultValue: null },
        'properties.sampling_rate': {
          label: 'Sampling Rate',
          defaultValue: null,
          rules: ['samplingRate'],
          transform: {
            out: (value) => parseInt(value, 10)
          }
        },
        'properties.sampling_type': { defaultValue: null }
      }),
      ($cloudExportWizard) => $cloudExportWizard.selectedCloudProvider
    ),
    registerValidators: (form) => {
      Validator.registerAsync('unique_bucket_name', (value, args, attribute, passes) => {
        // AWS bucket name must be unique across all of current kentik environment
        const awsBucketName = form.getValue('properties.aws_bucket');
        return $clouds
          .checkCloudProviderIdEligibility({
            cloudProvider: 'aws',
            ids: [awsBucketName]
          })
          .then((response) => {
            if (!response) {
              // $clouds.checkCloudProviderIdEligibility returns nothing, will only throw an error
              // no response means no error, all good to proceed
              passes(true);
            }
          })
          .catch((err) => {
            passes(false, err.message);
          });
      });
      Validator.register('awsBucket', (value) => {
        if (value === 'KENTIK-NO-FLOW') {
          return true;
        }
        const regex = /^[a-z0-9][a-z0-9.\-/]*[a-z0-9]$/;
        return regex.test(value);
      });

      Validator.register(
        'samplingRate',
        function samplingRate(value) {
          const { properties } = this.validator.input;
          if (properties?.sampling_type !== 'sampling_rate') {
            return true;
          }

          return value >= MIN_SAMPLING_RATE && value <= MAX_SAMPLING_RATE;
        },
        `Sampling Rate must be a positive number between ${MIN_SAMPLING_RATE} and ${MAX_SAMPLING_RATE}`
      );
    }
  }
];

export const AWS_TERRAFORM_CONFIG_STEP = {
  id: AWS_TERRAFORM_STEP,
  title: 'AWS Terraform',
  component: AwsAutomatedSetup
};
