import React from 'react';
import { action, computed, observable, toJS } from 'mobx';
import { get, omit } from 'lodash';

import { Box, Text } from 'core/components';
import api from 'core/util/api';
import { ReactComponent as RouterSwitchesSVG } from 'app/assets/icons/add-routers-switches-icon.svg';
import SetupTaskCollection from 'app/stores/setup/SetupTaskCollection';
import { CLOUD_PROVIDERS } from 'app/util/constants';
import { showSuccessToast } from 'core/components/toast';

class SetupStore {
  @observable
  vendors = [];

  @observable
  settings;

  @observable.shallow
  snippets = {};

  @observable.shallow
  plans = [];

  @observable.shallow
  planFps = {};

  @observable.shallow
  flowSettings = {};

  @observable
  siteCount = 0;

  @observable
  deviceCount = 0;

  @observable
  nmsDeviceCount = 0;

  @observable
  nmsIcmpOnlyDeviceCount = 0;

  @observable
  customerCount = 0;

  @observable
  hasNetworkClassEstablished = false;

  @observable
  maxRetention;

  setupTasks = new SetupTaskCollection();

  initialize() {
    return this.fetchSettings();
  }

  @action
  fetchSettings() {
    return api.get('/api/ui/setup/settings').then(
      action(
        ({
          siteCount,
          deviceCount,
          customerCount,
          hasNetworkClassEstablished,
          nmsDeviceCount,
          nmsIcmpOnlyDeviceCount
        }) => {
          this.siteCount = siteCount;
          this.deviceCount = deviceCount;
          this.nmsDeviceCount = nmsDeviceCount;
          this.nmsIcmpOnlyDeviceCount = nmsIcmpOnlyDeviceCount;
          this.customerCount = customerCount;
          this.hasNetworkClassEstablished = hasNetworkClassEstablished;
          this.settings = this.store.$auth.onboardingSettings;
        }
      )
    );
  }

  @action
  async getVendors() {
    if (!this.vendors.length) {
      return api.get('/api/ui/setup/vendors').then((vendors) => {
        this.vendors = vendors.concat([
          {
            devices: [],
            deviceType: 'router',
            logo: <RouterSwitchesSVG width={40} height={40} />,
            name: '',
            title: 'Other Router or Switch'
          },
          {
            devices: [],
            deviceType: 'cloud',
            logo: CLOUD_PROVIDERS.GCE.logo,
            logoStyle: { maxWidth: 40 },
            name: CLOUD_PROVIDERS.GCE.id,
            title: CLOUD_PROVIDERS.GCE.name
          },
          {
            devices: [],
            deviceType: 'cloud',
            logo: CLOUD_PROVIDERS.AWS.logo,
            logoStyle: { maxWidth: 40 },
            name: CLOUD_PROVIDERS.AWS.id,
            title: CLOUD_PROVIDERS.AWS.name
          },
          {
            devices: [],
            deviceType: 'cloud',
            logo: CLOUD_PROVIDERS.AZURE.logo,
            logoStyle: { maxWidth: 40 },
            name: CLOUD_PROVIDERS.AZURE.id,
            title: CLOUD_PROVIDERS.AZURE.name
          },
          {
            devices: [],
            deviceType: 'cloud',
            logo: CLOUD_PROVIDERS.OCI.logo,
            logoStyle: { maxWidth: 40 },
            name: CLOUD_PROVIDERS.OCI.id,
            title: CLOUD_PROVIDERS.OCI.name
          }
        ]);
      });
    }

    return this.vendors;
  }

  getVendorConfig(vendor) {
    return this.vendors.find(({ name }) => name === vendor) || {};
  }

  @computed
  get vendorsByCategory() {
    return {
      routers: this.vendors.filter(({ deviceType }) => deviceType !== 'cloud' && deviceType !== 'kprobe'),
      clouds: this.vendors.filter(({ deviceType }) => deviceType === 'cloud')
    };
  }

  @action
  getSnippets(vendor, model = '') {
    return api.get(`/api/ui/setup/snippets/${vendor}/${model}`).then((results) => {
      const snippets = {};

      results.forEach(({ name, snippet, type }) => {
        if (!snippets[type]) {
          snippets[type] = [];
        }
        snippets[type].push({ name, snippet, type });
      });

      this.snippets = snippets;

      return this.snippets;
    });
  }

  @action
  async getPlans(options = {}) {
    if (this.plans.length === 0 || options.force) {
      return api.get('/api/ui/setup/plans').then((plans) => (this.plans = plans));
    }
    return this.plans;
  }

  @action
  fetchMaxRetention() {
    if (!this.maxRetention) {
      return api
        .get('/api/ui/setup/plansMaxRetention')
        .then(({ max_retention }) => (this.maxRetention = max_retention));
    }

    return this.maxRetention;
  }

  @action
  async updateSettings(settings, options) {
    if (this.store.$app.isSharedLink) {
      return Promise.resolve();
    }

    const settingsBody = {
      ...toJS(this.settings),
      ...settings,
      ...(options || {})
    };
    this.settings = settingsBody;

    return api
      .put('/api/ui/setup/settings', {
        body: {
          ...settingsBody
        }
      })
      .then((newSettings) => (this.settings = newSettings));
  }

  getSettings(path, defaultValue) {
    return get(toJS(this.settings), path, defaultValue);
  }

  async writeSetupTasks(what) {
    return api.put('/api/ui/setup/tasks', { data: { what } }).then(() => {
      // Fire and forget
      this.setupTasks.fetch({ force: true });
    });
  }

  get redirectToOnboarding() {
    // All legacy companies will be marked with onboarding: true
    if (this.getSettings('onboarding')) {
      return false;
    }

    // guided demos should never land in onboarding
    if (this.store.$auth.isDemoUser) {
      return false;
    }

    // If they are missing either 'what' or 'why', force them into onboarding
    return !this.getSettings('what') || !this.getSettings('why');
  }

  @action
  async getFlowSettings() {
    return api.get('/api/ui/setup/flow/settings').then((flowSettings) => {
      this.flowSettings = flowSettings;
      return flowSettings;
    });
  }

  @computed
  get planOptions() {
    const hiddenPlansTypes = ['ksynth', 'agent', 'metrics'];
    const metricsPlan = this.plans.find(({ active, plan_type }) => active && plan_type === 'metrics');
    const options = this.plans
      .filter(({ active, plan_type }) => active && !hiddenPlansTypes.includes(plan_type))
      .map((plan) => ({
        value: plan.id,
        label: (
          <Box overflow="hidden">
            <Text as="div">
              {plan.name}{' '}
              <Text muted>
                ({plan.device_count}/{plan.max_devices})
              </Text>
            </Text>
            <Text small muted ellipsis>
              {plan.description}
            </Text>
          </Box>
        ),
        ...plan
      }));

    // disguise metrics plan as No Flow
    if (metricsPlan) {
      options.unshift({
        value: metricsPlan.id,
        label: 'No Flow Plan'
      });
    }

    return options;
  }

  @computed
  get defaultLegacyPlanId() {
    const defaultPlanOption = this.planOptions.find((option) => !option.disabled);
    return (defaultPlanOption && defaultPlanOption.value) || '';
  }

  cloudPlanOptions(provider) {
    return this.plans
      .filter(
        ({ active, plan_type, cloud_provider }) =>
          active &&
          (plan_type === 'cloud' || plan_type === 'universalpak') &&
          (provider ? cloud_provider === provider || cloud_provider === 'universal' : true)
      )
      .map((plan) => ({
        value: plan.id,
        label: (
          /**
           * This test id is used in the jest test's selectPlan() fn.
           * See src/app/views/setup/tasks/cloud_v2/CloudExportConfigWizard/__tests__/CloudExportConfigWizard.test.js
           */
          <Box overflow="hidden" data-testid={`plan-option-${plan.id}`}>
            <Text as="div">
              {plan.name}{' '}
              <Text muted>
                ({plan.device_count}/{plan.max_devices})
              </Text>
            </Text>
            <Text small muted ellipsis>
              {plan.description}
            </Text>
          </Box>
        ),
        ...plan
      }));
  }

  defaultCloudPlanId(provider) {
    const defaultPlanOption = this.cloudPlanOptions(provider).find((option) => !option.disabled);
    return (defaultPlanOption && defaultPlanOption.value) || '';
  }

  @action
  fetchPlanFps() {
    return api.post('/api/ui/setup/planFps').then((results) => (this.planFps = results));
  }

  getFavorites() {
    const favoritesRaw = this.getSettings('favorites', {});

    // we must find and transform the old favorites
    // Old favorites look like this: "saved-view-4338", "dashboard-21057"
    if (Array.isArray(favoritesRaw)) {
      const newFavorites = {};
      favoritesRaw.forEach((favStr) => {
        const favParts = favStr.split('-');
        if (favParts.length === 2) {
          const id = `dashboard$${favParts[1]}`;
          const { name } = this.store.$dashboards.getDashboardMetadata(favParts[1]);
          newFavorites[id] = { id, name: name || id };
        }
        if (favParts.length === 3) {
          const id = `saved-view$${favParts[2]}`;
          const { name } = this.store.$savedViews.getSavedViewMetadata(favParts[2]);
          newFavorites[id] = { id, name: name || id };
        }
      });

      this.updateSettings({ favorites: newFavorites });
      return newFavorites;
    }
    return this.getSettings('favorites', {});
  }

  isFavorite(id) {
    return Object.keys(this.getSettings('favorites', {})).includes(id);
  }

  // favorites are identified by {type}${id}
  @action
  toggleFavorite(id, name, metadata) {
    const storedFavorites = this.getSettings('favorites', {});
    const isFavorite = Object.keys(storedFavorites).includes(id);
    let favorites = {};

    if (isFavorite) {
      favorites = omit(storedFavorites, [id]);
    } else {
      favorites = storedFavorites;
      favorites[id] = { id, name, metadata };
    }

    return this.updateSettings({ favorites }).then((res) => {
      showSuccessToast(
        `${isFavorite ? 'Removed' : 'Added'} ${id.split('$')[0]} ${isFavorite ? 'from' : 'to'} Favorites`
      );
      return res.favorites;
    });
  }
}

export default new SetupStore();
