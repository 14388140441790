import Model from 'core/model/Model';
import $auth from 'app/stores/$auth';

export default class Otp extends Model {
  get urlRoot() {
    return `/api/ui/totp/${$auth.getActiveUserProperty('id')}`;
  }

  get defaults() {
    return {
      name: 'Default',
      type: 'yubi',
      enabled: true
    };
  }
}
