import { observable, action, toJS } from 'mobx';
import moment from 'moment';

import { api } from 'core/util';
import { DEFAULT_DATETIME_FORMAT } from 'core/util/dateUtils';

import SharedLinksCollection from './SharedLinksCollection';

class SharedLinksStore {
  types = {
    dataExplorer: 'data-explorer',
    synthetics: 'synthetics',
    savedView: 'saved-view',
    dashboards: 'dashboards'
  };

  collection = new SharedLinksCollection();

  @observable
  _sharedLink = null;

  @observable
  loading = false;

  get data() {
    return toJS(this._sharedLink);
  }

  @action
  setData(data) {
    this._sharedLink = data;
  }

  @action
  setLoading(isLoading = true) {
    this.loading = isLoading;
  }

  @action
  checkAvailability(public_hash) {
    if (!public_hash || this.store.$app.isSharedLink) {
      return Promise.reject();
    }

    return api.get(`/api/ui/shared-links/check-availability/${public_hash}`, { showErrorToast: false });
  }

  @action
  createSharedLink(data = {}) {
    if (this.store.$app.isSharedLink) {
      return Promise.reject();
    }

    this.loading = true;

    return api.post('/api/ui/shared-links', { data }).then(
      (response) => {
        this.loading = false;

        // reload the store to update any counts/tables/etc
        this.collection.fetch({ force: true });

        return response;
      },
      () => {
        this.loading = false;
        throw new Error('Could not create public share.');
      }
    );
  }

  @action
  updateSharedLink(id, data = {}) {
    if (this.store.$app.isSharedLink) {
      return Promise.reject();
    }

    this.loading = true;

    return api.post(`/api/ui/shared-links/${id}`, { data }).then(
      (response) => {
        this.loading = false;
        return response;
      },
      () => {
        this.loading = false;
        throw new Error('Could not update public share.');
      }
    );
  }

  @action
  deleteSharedLink(key) {
    if (this.store.$app.isSharedLink) {
      return Promise.reject();
    }

    this.loading = true;

    return api.delete('/api/ui/shared-links', key).then(
      (response) => {
        this.loading = false;
        return response;
      },
      () => {
        this.loading = false;
      }
    );
  }

  removeLookbacksFromQuery = (query) => {
    let { lookback_seconds } = query;

    if (lookback_seconds) {
      if (lookback_seconds >= 0 && lookback_seconds < 3600) {
        lookback_seconds = 3600; // set to an hour if we were using a special lookback
      }

      // Due to field transforms, time fields values should always be UTC coming out, and expect
      // UTC coming in (transforms adjust display based on time_format).
      const newEnd = moment.utc();
      const newStart = moment.utc().subtract({ seconds: lookback_seconds });
      query.starting_time = newStart.format(DEFAULT_DATETIME_FORMAT);
      query.ending_time = newEnd.format(DEFAULT_DATETIME_FORMAT);
    }

    query.lookback_seconds = 0;
    query.time_format = 'UTC';

    return query;
  };

  setQueryTimeRanges = (query, starting_time, ending_time) => {
    query.starting_time = starting_time;
    query.ending_time = ending_time;
    query.lookback_seconds = 0;
    query.time_format = 'UTC';
    if (query.kmetrics?.range) {
      query.kmetrics.range = {
        start: starting_time,
        end: ending_time,
        lookback_seconds: 0,
        lookback: 'PT0S'
      };
    }
  };
}

export default new SharedLinksStore();
