import Collection from 'core/model/Collection';

import CostGroupModel from './CostGroupModel';

class CostGroupCollection extends Collection {
  get model() {
    return CostGroupModel;
  }

  get url() {
    return '/api/ui/cost/cost-group';
  }

  get edge() {
    return this.models.filter((model) => model.get('type') !== 'backbone');
  }

  get backbone() {
    return this.getByType('backbone');
  }

  getByType(type) {
    return this.models.filter((model) => model.get('type') === type);
  }
}

export default CostGroupCollection;
