import { action, computed } from 'mobx';
import api from 'core/util/api';
import InterfaceCollection from './InterfaceCollection';
import InterfaceModel from './InterfaceModel';

class InterfacesStore {
  collection = new InterfaceCollection();

  @computed
  get connectivityTypeOptions() {
    const types = this.store.$dictionary.get('interfaceClassification.connectivityTypes');
    return Object.keys(types).map((value) => ({ value, label: types[value] }));
  }

  @computed
  get networkBoundaryOptions() {
    const types = this.store.$dictionary.get('interfaceClassification.networkBoundaryTypes');
    return Object.keys(types).map((value) => ({ value, label: types[value] }));
  }

  @action
  fetchInterfacesForDevice(device) {
    this.collection.deviceId = device.id;
    const options = { query: { device_id: device.id } };
    return this.collection.fetch(options).then(() => {
      this.collection.fetchInterfaceFlow(device);
      this.collection.setSortState('flowInbound.flowMbps', 'desc');
    });
  }

  fetchHighlyUtilizedInterfaces(filter) {
    return this.store.$lookups.deviceInterfaces(filter || '');
  }

  @action
  async fetchInterface({ device_name, snmp_id, interface_name }) {
    const deviceLookup = this.store.$devices.deviceSummariesByName[device_name];

    if (!deviceLookup) {
      return null;
    }

    const intf = new InterfaceModel();

    return intf
      .fetch({
        query: {
          device_id: deviceLookup.id,
          snmp_id,
          interface_name
        }
      })
      .then(() => intf);
  }

  navigateToInterface = (snmpId, deviceName) => {
    this.history.push(`/v4/core/quick-views/interfaces/${deviceName}-${snmpId}`);
  };

  exportCsv = (query, options = {}) => {
    const { name = 'interfaces' } = options;
    const path = '/api/ui/interfaces/csv';
    const fileName = `export-${name}-${new Date().toISOString().slice(0, 10).replace(/-/g, '')}`;
    const exportOptions = { path, fileName, type: 'csv' };

    this.store.$exports.addLoadingExport(exportOptions);
    return api.get(path, { query, rawResponse: true }).then((response) => {
      this.store.$exports.clearLoadingExport(exportOptions);
      this.store.$exports.addPayload(response.text, exportOptions);
    });
  };

  exportInterfacesForDeviceCsv = (deviceModel) => {
    const interfaceCollection = deviceModel.get('interfaceCollection');
    const { query } = interfaceCollection.getFetchOptions(interfaceCollection.lastFetchOptions);
    this.exportCsv(query, { name: `interfaces-${deviceModel.get('device_name')}` });
  };
}

export default new InterfacesStore();
