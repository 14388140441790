import api from 'core/util/api';

class GeoStore {
  countryGeoJson = { type: 'FeatureCollection', features: [] };

  regionGeo = {};

  async getAllMapData() {
    if (this.countryGeoJson.features.length > 0) {
      return this.countryGeoJson;
    }

    return api.get('/api/ui/mapData/all').then((countryGeoJson) => {
      countryGeoJson.forEach((country) => {
        const { features, bounds } = country;

        features.forEach((feature) => {
          feature.properties.code = feature.id;
          feature.properties.bounds = bounds;

          this.countryGeoJson.features.push(feature);
        });
      });

      return this.countryGeoJson;
    });
  }

  async getCountryMapData(countryCode) {
    if (!this.regionGeo[countryCode]) {
      return api.get(`/api/ui/mapData/country/${countryCode}`).then((data) => {
        this.regionGeo[countryCode] = data;
        return data;
      });
    }
    return this.regionGeo[countryCode];
  }

  async getRegionMapData(countryCode, regionName) {
    return api.get(`/api/ui/mapData/region/${countryCode}/${regionName}`);
  }
}

export default new GeoStore();
