import { computed } from 'mobx';
import Collection from 'core/model/Collection';
import DefinitionModel from './InsightDefinitionModel';
import InsightTypeOverrides from './insightTypes';

class InsightDefinitionCollection extends Collection {
  get url() {
    return '/api/ui/insights/definitions';
  }

  get model() {
    return DefinitionModel;
  }

  deserialize = ({ insightDefinitions = [] }) => insightDefinitions;

  @computed
  get allInsightTypesList() {
    return this.models
      .slice()
      .filter((model) => model.get('status') === 'enabled')
      .sort(
        (a, b) =>
          (a.get('family') || '').localeCompare(b.get('family')) || (a.get('label') || '').localeCompare(b.get('label'))
      )
      .map((model, idx) => ({ idx, ...model.get() }));
  }

  @computed
  get kentikInsightTypesList() {
    return this.allInsightTypesList.filter(
      (definition) => !definition.insightName.startsWith('custom.insight') && definition.family !== 'Capacity Planning'
    );
  }

  @computed
  get customInsightTypesList() {
    return this.allInsightTypesList.filter(
      (definition) =>
        definition.insightName.startsWith('custom.insight') &&
        !definition.insightName.startsWith('custom.insight.V4 Synth')
    );
  }

  @computed
  get insightTypes() {
    const types = this.models
      // Include all types in this list, even disabled and hidden. This is needed to preview test insights on InsightDetails
      // .filter(model => model.get('status') === 'enabled')
      .reduce(
        (definitions, model) => {
          const name = model.get('insightName');

          return {
            ...definitions,
            [name]: {
              ...InsightTypeOverrides[name],
              ...model.get()
            }
          };
        },
        {
          custom: InsightTypeOverrides.custom
        }
      );

    return types;
  }

  @computed
  get insightFamilies() {
    return Array.from(
      this.models
        .filter((model) => {
          const { family, status, insightName = '', dataSourceType = '' } = model.get();
          return (
            family &&
            status === 'enabled' &&
            !insightName.startsWith('test.') &&
            dataSourceType !== 'alerting' &&
            family !== 'Capacity Planning'
          );
        })
        .reduce((families, definition) => families.add(definition.get('family')), new Set())
    ).sort();
  }
}

export default InsightDefinitionCollection;
