import { observable, action } from 'mobx';

class NavStore {
  @observable openSection;

  @action
  expandSection = (section) => {
    this.openSection = section;
  };
}

export default new NavStore();
