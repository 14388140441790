import Collection from 'core/model/Collection';
import EmbeddedCacheModel from './EmbeddedCacheModel';

class EmbeddedCacheCollection extends Collection {
  get url() {
    return '/api/ui/cdn/embedded-cache';
  }

  get model() {
    return EmbeddedCacheModel;
  }

  get defaultSortState() {
    return {
      field: 'cidr',
      direction: 'asc'
    };
  }
}

export default EmbeddedCacheCollection;
