import Model from 'core/model/Model';
import { computed } from 'mobx';

class MetricISISAdjacencyModel extends Model {
  get urlRoot() {
    return '/api/ui/recon/isis-adjacencies';
  }

  get title() {
    return this.get('neighbor-ipv4-address');
  }

  get subType() {
    return 'IS-IS Adjacency';
  }

  @computed
  get adjacencyState() {
    const value = this.get('adjacency-state');
    const map = {
      1: 'Down',
      2: 'Initializing',
      3: 'Up',
      4: 'Failed'
    };

    return map[value] || 'Unknown';
  }
}

export default MetricISISAdjacencyModel;
