import { computed } from 'mobx';
import Model from 'core/model/Model';
import { deepClone } from 'core/util';
import $decks from './$decks';
import WidgetCollection from './WidgetCollection';

const SHARE_LEVEL_LABELS = {
  self: 'Personal',
  org: 'Company',
  global: 'Global'
};

class DeckModel extends Model {
  get urlRoot() {
    return '/api/ui/decks';
  }

  get defaults() {
    return {
      share_level: 'self'
    };
  }

  @computed
  get config() {
    const { widgets } = this.get('config') || { widgets: [] };
    return { widgets, layout: this._layout };
  }

  get widgets() {
    return this.config.widgets || [];
  }

  get shareLevel() {
    return SHARE_LEVEL_LABELS[this.get('share_level')];
  }

  get layout() {
    return deepClone(this._layout);
  }

  set layout(layout) {
    const { layout: oldLayout, ...config } = this.get('config');
    // Need to strip out moved/static

    this._layout = layout;
    this.set('config', { layout, ...config });
  }

  addWidget(widgetName, config) {
    const { widgets, ...rest } = this.get('config') || {};

    const [widget] = this.widgetCollection.add({ widgetName, config });
    const widgetConfig = widget.get();

    const processedWidgets = this.widgetCollection.map((w) => w.get());
    this._layout = $decks.getLayout({ widgets: processedWidgets, layout: this._layout });

    // Add to the beginning of the widgets array so that the new widget appears in the top-left corner (0, 0)
    this.set('config', { ...rest, widgets: widgets ? [widgetConfig, ...widgets] : [widgetConfig] });
    return widgetName;
  }

  updateWidget(id, config) {
    const { widgets, ...rest } = this.get('config') || {};

    const idx = widgets.findIndex((widget) => widget.id === id);

    widgets[idx].config = config;

    this.set('config', { ...rest, widgets });
  }

  removeWidget(id) {
    const { widgets, layout, ...rest } = this.get('config') || {};

    const newLayout = { lg: layout.lg.filter((lg) => lg.i !== id), sm: layout.sm.filter((sm) => sm.i !== id) };
    this._layout = newLayout;

    const idx = widgets.findIndex((widget) => widget.id === id);

    this.set('config', {
      ...rest,
      layout: newLayout,
      widgets: widgets ? widgets.slice(0, idx).concat(widgets.slice(idx + 1)) : []
    });
  }

  deserialize(data) {
    const { widgets, layout } = (data && data.config) || { widgets: [] };

    const validWidgets = $decks.filterInvalidWidgets(widgets);
    this.widgetCollection = new WidgetCollection(validWidgets);
    const processedWidgets = this.widgetCollection.map((widget) => widget.get());

    this._layout = $decks.getLayout({ widgets: processedWidgets, layout });

    return { ...data, config: { ...data.config, widgets: processedWidgets } };
  }
}

export default DeckModel;
