import { action, observable, computed } from 'mobx';
import { uniqBy as _uniqBy, difference as _difference } from 'lodash';
import api from 'core/util/api';
import { getDefaultFiltersObject, mergeFilterGroups } from 'core/util/filters';
import {
  getASNQuery,
  getIPsQuery,
  getServicesQuery,
  getSiteDeviceLinkQueries,
  getMultiCloudLinkQueries,
  getCloudInternetLinkQueries,
  getCloudMyNetworkLinkQueries,
  getAllClusterTrafficLinkQuery,
  getInternetMyNetworkLinkQuery,
  getInternetMyNetworkLinkQueries
} from 'app/views/hybrid/maps/hybridMapQueries';
import {
  getDeviceSiteNodeLinkQuery,
  getSiteDeviceNodeLinkQuery
} from 'app/views/hybrid/maps/components/interSiteTraffic/interSiteTrafficQueryHelper';
import { getConnectionKey, groupConnections, separateLink } from 'app/views/hybrid/utils/links';
import { getHealthClass, mergeRollupCheckDetails } from 'app/views/hybrid/utils/health';

import AWSCloudMapCollection from 'app/stores/hybrid/AWSCloudMapCollection';
import GCPCloudMapCollection from 'app/stores/hybrid/GCPCloudMapCollection';
import OciCloudMapCollection from 'app/stores/hybrid/OciCloudMapCollection';
import AzureCloudMapCollection from 'app/stores/hybrid/AzureCloudMapCollection';
import KubeCloudMapCollection from './KubeCloudMapCollection';

import HybridSettingsModel from './HybridSettingsModel';
import TopologyHealthCollection from './TopologyHealthCollection';

const SRC_NAMESPACE_DIMENSION = 'kt_k8s_src_pod_ns';
const DST_NAMESPACE_DIMENSION = 'kt_k8s_dst_pod_ns';

const SRC_OBJECTNAME_DIMENSION = 'kt_k8s_src_pod_name';
const DST_OBJECTNAME_DIMENSION = 'kt_k8s_dst_pod_name';

class HybridMapStore {
  providerMap = {
    aws: 'Amazon',
    gcp: 'Google',
    azure: 'Microsoft Azure',
    oci: 'Oracle Cloud'
  };

  settingsModel = new HybridSettingsModel();

  awsCloudMapCollection = new AWSCloudMapCollection();

  gcpCloudMapCollection = new GCPCloudMapCollection();

  ociCloudMapCollection = new OciCloudMapCollection();

  azureCloudMapCollection = new AzureCloudMapCollection([], { parent: this });

  kubeCloudMapCollection = new KubeCloudMapCollection();

  // latency threshold for kube map to show objects as unhealthy
  latencyThreshold = 300;

  @observable
  maxTraffic = 1_000_000_000;

  @observable.ref
  sites = [];

  @observable.ref
  siteLinks = [];

  @observable.shallow
  kubeState = {
    loading: true,
    kubeInitializationFailed: false,
    clusters: [],
    nodes: [],
    highLatencyItems: [],
    highRetransmitItems: []
  };

  @observable.shallow
  kubeStateAlt = {
    showProblematicPods: false,
    selectedEntityType: null,
    selectedEntityId: null,
    selectedEntityData: null
  };

  // once we have all the cluster data after the first query, we initialize the state
  // structure for each cluster
  @action
  initializeKubeState(clusters) {
    // initialize the state structure for each cluster
    clusters.forEach((cluster) => {
      const { device_name, cluster_id } = cluster;
      this.kubeStateAlt[device_name] = {
        selectedTabId: 'nodes',
        selectedNamespace: null,
        selectedWorkload: null,
        clusterId: cluster_id,
        availableNamespaces: [],
        loadingPodsQuery: false,
        podLinks: [],
        podNodes: []
      };
    });

    this.kubeState.loading = false;
    this.kubeState.clusters = clusters;
    this.kubeState.nodes = clusters.reduce((nodes, cluster) => nodes.concat(cluster.nodes), []);
  }

  @action
  async loadTopologyForWeatherMap(options) {
    return Promise.all(
      ['aws', 'azure', 'gcp'].map((cloud) => {
        const dictionaryData = this.store.$dictionary.get(`cloudMetadata.${cloud}.regions`);

        let promise = () => Promise.resolve({});

        // if cloud is not set up, ignore regions for this cloud
        if (!this.hasCloud(cloud)) {
          return promise().then(() => []);
        }

        if (cloud === 'aws') {
          promise = async () =>
            this.awsCloudMapCollection.fetch(options).then(() =>
              // convert to id => region object
              (this.awsCloudMapCollection?.topology?.Hierarchy?.Regions ?? []).reduce((carry, region) => {
                carry[region.id] = region;
                return carry;
              }, {})
            );
        }

        if (cloud === 'azure') {
          promise = async () =>
            this.azureCloudMapCollection.fetch(options).then((azureTopology) => azureTopology.Entities.locations ?? {});
        }

        if (cloud === 'gcp') {
          promise = async () =>
            this.gcpCloudMapCollection
              .fetch(options)
              .then((gcpTopology) => gcpTopology.Entities.regions ?? [])
              // will convert [region1, region2] into {region1: {id, name, displayName }} to match overview map structure
              .then((gcpRegionsNames) =>
                gcpRegionsNames.reduce((carry, regionName) => {
                  carry[regionName] = { id: regionName, name: regionName, displayName: regionName };
                  return carry;
                }, {})
              );
        }

        return promise().then((cloudRegions) =>
          Object.keys(cloudRegions).map((regionKey) => {
            const region = cloudRegions[regionKey];
            return {
              ...region,
              key: regionKey,
              id: regionKey,
              region: regionKey,
              health: {
                cssClassName: 'healthy'
              },
              Name: dictionaryData[regionKey]?.name,
              type: cloud,
              ...(dictionaryData[regionKey]?.coordinates ?? {})
            };
          })
        );
      })
    ).then((promises) => promises.flat());
  }

  @computed
  get hasSites() {
    return this.sites.length > 0;
  }

  @computed
  get clouds() {
    return this.store.$clouds.collection.reduce((acc, model) => {
      const provider = model.get('cloud_provider').replace('gce', 'gcp');

      if (this.providerMap[provider] && !acc.find((config) => config.provider === provider)) {
        acc.push({ provider, name: this.providerMap[provider] });
      }

      return acc;
    }, []);
  }

  hasCloud(cloudProvider) {
    return this.clouds?.some(({ provider }) => provider.toLowerCase() === cloudProvider.toLowerCase()) ?? false;
  }

  @computed
  get cloudsWithIndex() {
    return this.clouds
      .filter(({ name }) => name)
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((site, index) => ({ ...site, index }));
  }

  @computed
  get baseRoute() {
    return '/v4/kentik-map';
  }

  @computed
  get normalizedSiteLinks() {
    return this.normalizeLinks(this.siteLinks);
  }

  @computed
  get interSiteTrafficEnabled() {
    return this.settingsModel.get('showInterSiteTraffic');
  }

  @computed
  get hasSidebarFilters() {
    const filters = this.settingsModel.get('sidebarQueryOverrides.filters');
    return filters && filters.filterGroups && filters.filterGroups.length > 0;
  }

  @action
  setKubeState = (state) => {
    this.kubeState = { ...this.kubeState, ...state };
  };

  setKubeStateAlt = (clusterName, newState, callback = undefined) => {
    if (clusterName) {
      this.kubeStateAlt = {
        ...this.kubeStateAlt,
        [clusterName]: { ...this.kubeStateAlt[clusterName], ...newState }
      };
    } else {
      this.kubeStateAlt = {
        ...this.kubeStateAlt,
        ...newState
      };
    }

    if (callback) {
      callback(this.kubeStateAlt);
    }
  };

  @action
  toggleProblematicPodsDialog = () => {
    this.kubeStateAlt.showProblematicPods = !this.kubeStateAlt.showProblematicPods;
  };

  @action
  handleShowProblematicPodFromDialog = (clusterName, selectedNamespace, podId) => {
    const availablePodsQuery = this.getQuery({
      all_devices: false,
      show_overlay: false,
      show_total_overlay: false,
      device_types: ['kappa'],
      metric: [SRC_OBJECTNAME_DIMENSION, DST_OBJECTNAME_DIMENSION],
      aggregateTypes: ['avg_bits_per_sec', 'p95th_bits_per_sec', 'max_bits_per_sec'],
      viz_type: 'table',
      depth: 500,
      filters: {
        connector: 'All',
        filterGroups: [
          {
            name: '',
            named: false,
            connector: 'All',
            not: false,
            autoAdded: '',
            filters: [
              {
                filterField: SRC_NAMESPACE_DIMENSION,
                operator: '=',
                filterValue: selectedNamespace
              },
              {
                filterField: DST_NAMESPACE_DIMENSION,
                operator: '=',
                filterValue: selectedNamespace
              }
            ],
            saved_filters: [],
            filterGroups: []
          }
        ]
      }
    });

    this.setKubeStateAlt(clusterName, {
      selectedTabId: 'pods',
      selectedNamespace,
      selectedEntityId: podId,
      selectedWorkload: null,
      availablePodsQuery
    });
  };

  @action
  // eslint-disable-next-line no-unused-vars
  handleShowPodsInNamespaceWorkload = (clusterName, namespace, workload = null, ...rest) => {
    const availablePodsQuery = this.getQuery({
      all_devices: false,
      show_overlay: false,
      show_total_overlay: false,
      device_name: [clusterName],
      metric: [SRC_OBJECTNAME_DIMENSION, DST_OBJECTNAME_DIMENSION, SRC_NAMESPACE_DIMENSION, DST_NAMESPACE_DIMENSION],
      aggregateTypes: ['avg_bits_per_sec', 'avg_ktsubtype__kappa__APPL_LATENCY_MS'],
      viz_type: 'table',
      depth: 500,
      topx: 500,
      filters: {
        connector: 'All',
        filterGroups: [
          {
            name: 'namespace-match',
            named: false,
            connector: 'Any',
            not: false,
            autoAdded: '',
            filters: [
              {
                filterField: SRC_NAMESPACE_DIMENSION,
                operator: '=',
                filterValue: namespace
              },
              {
                filterField: DST_NAMESPACE_DIMENSION,
                operator: '=',
                filterValue: namespace
              }
            ]
          }
        ]
      }
    });

    this.setKubeStateAlt(clusterName, {
      selectedNamespace: namespace,
      selectedWorkload: workload,
      availablePodsQuery,
      loadingPodsQuery: true,
      ...rest
    });
  };

  @action
  handleResetSelectedNamespace = (clusterName) => {
    this.setKubeStateAlt(clusterName, {
      selectedNamespace: null,
      selectedWorkload: null,
      podNodes: [],
      podLinks: [],
      availablePodsQuery: null,
      loadingPodsQuery: false
    });
  };

  @action showK8NodeDetails = (selectedSubnet) => {
    // navigate to the map
    this.store.history.push('/v4/cloud/kube', { selectedSubnet });
  };

  setMaxTraffic(value) {
    const exponent = Math.ceil(Math.log10(value));
    this.maxTraffic = 10 ** exponent;
  }

  async getHealthCollection(level = 'all', id = null) {
    const healthCollectionKey = `healthCollection_${level}${id || ''}`;

    const getData = () => {
      if (level === 'site') {
        return this.getTopologyDataForSite(id);
      }

      if (level === 'device') {
        return this.getTopologyDataForDevice(id);
      }

      return this.getSiteTopologyData();
    };

    return (
      this[healthCollectionKey] ||
      getData().then((topology) => {
        this[healthCollectionKey] = new TopologyHealthCollection(topology);

        setTimeout(() => {
          this[healthCollectionKey] = null;
        }, 60 * 1000);

        return this[healthCollectionKey];
      })
    );
  }

  @action
  async getSiteTopologyData() {
    this.topLevelTopologyPromise =
      this.topLevelTopologyPromise ||
      api.get('/api/ui/topology/sites').then((res) => {
        const { sites = {}, links = {}, health = {}, devices = {} } = res;
        const sitesWithHealth = this.addHealthToNodes({ nodes: sites, nodeType: 'sites', health });

        const indexedSites = Object.values(sitesWithHealth)
          .filter(({ name, type }) => name && type !== 'cloud')
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((site, index) => Object.assign(site, { index }));

        this.sites = indexedSites;
        this.siteLinks = links;

        setTimeout(() => {
          this.topLevelTopologyPromise = null;
        }, 10000);

        return {
          sites: indexedSites,
          sitesById: sitesWithHealth,
          devices: this.addHealthToNodes({ nodes: devices, nodeType: 'devices', health }),
          links,
          health
        };
      });

    return this.topLevelTopologyPromise.then((res) => this.normalizeTopologyLinks(res));
  }

  @action
  async getTopologyDataForSite(siteId) {
    const sitePromiseKey = `cloudHierarchy_${siteId}Promise`;
    this[sitePromiseKey] =
      this[sitePromiseKey] ||
      api.get(`/api/ui/topology/site/${siteId}`).then((res) => {
        const {
          sites = {},
          site_interior_links = [],
          site_to_onprem_links = [],
          site_external_links = [],
          site_external_cloud_links = [],
          health = {}
        } = res;

        const site = this.store.$sites.collection.get(siteId);
        const devicesMap = site.devices.reduce(
          (acc, device) => {
            const { id, device_name, ...rest } = device;
            const deviceModel = this.store.$devices.collection.get(id);
            if (deviceModel.get('device_subtype') === 'kprobe') {
              // capture the kprobe device ids as we'll want to check against this list to ensure that any kprobe devices that made their way
              // into the architecture editor are filtered out
              acc.kprobeDeviceIds.push(+deviceModel.id);
            } else {
              acc[id] = { id: Number(id), name: device_name, ...rest };
            }
            return acc;
          },
          { kprobeDeviceIds: [] }
        );

        const { kprobeDeviceIds, ...devices } = devicesMap;

        setTimeout(() => {
          this[sitePromiseKey] = null;
        }, 10000);

        return {
          sites: this.addHealthToNodes({ nodes: sites, nodeType: 'sites', health }),
          devices: this.addHealthToNodes({ nodes: devices, nodeType: 'devices', health }),
          deviceLinks: site_interior_links,
          siteLinks: site_to_onprem_links,
          providerLinks: site_external_links,
          cloudLinks: site_external_cloud_links,
          health,
          kprobeDeviceIds
        };
      });

    return this[sitePromiseKey].then((res) => this.normalizeTopologyLinks(res));
  }

  @action
  async getTopologyDataForDevice(deviceId, v2 = false) {
    // v2 is testing for NMS basic topology stuff
    const url = v2 ? `/api/ui/topology/device/v2/${deviceId}` : `/api/ui/topology/device/${deviceId}`;

    const devicePromiseKey = `cloudHierarchy_${deviceId}Promise`;
    this[devicePromiseKey] =
      this[devicePromiseKey] ||
      api.get(url).then((res) => {
        const {
          sites = {},
          devices = {},
          site_interior_links = [],
          device_to_onprem_links = [],
          external_links = [],
          external_cloud_links = [],
          unlinked_interfaces = [],
          health = {}
        } = res;

        setTimeout(() => {
          this[devicePromiseKey] = null;
        }, 1000);

        return {
          sites: this.addHealthToNodes({ nodes: sites, nodeType: 'sites', health }),
          devices: this.addHealthToNodes({ nodes: devices, nodeType: 'devices', health }),
          deviceLinks: site_interior_links || [],
          siteLinks: device_to_onprem_links || [],
          cloudLinks: external_cloud_links || [],
          externalLinks: external_links || [],
          unlinkedInterfaces: unlinked_interfaces || [],
          health
        };
      });

    return this[devicePromiseKey].then((res) => this.normalizeTopologyLinks(res));
  }

  parseTopoRegionData(data, region) {
    const { interior_links, vpcs } = data;
    let maxBytes = 0;

    const links = interior_links.map((link) => {
      maxBytes = Math.max(maxBytes, link.metrics.from_bytes + link.metrics.to_bytes);
      return { ...link, id: link.vpc1_id + link.vpc2_id };
    });

    return {
      vpcs: vpcs.map((vpc) => ({ ...vpc, region })),
      links,
      maxBytes
    };
  }

  async getCloudRouteTable(cloudProvider = 'aws', cloudRegion = 'east') {
    return api.get(`/api/ui/topology/route-table/${cloudProvider}/${cloudRegion}`);
  }

  normalizeLinks(links) {
    if (links && links.length > 0) {
      const connectionType = this.settingsModel.get('connectionType');
      return links
        .map((link) => {
          if (link.connections) {
            // escape hatch for links that use the old style
            return link;
          }

          const groupedConnections = groupConnections([link]);
          let connections = [];

          if (connectionType === 'all') {
            connections = _uniqBy(groupedConnections.layer3.concat(groupedConnections.layer2), getConnectionKey);
          }

          if (connectionType === 'layer3') {
            connections = groupedConnections.layer3;
          }

          if (connectionType === 'layer2') {
            connections = groupedConnections.layer2;
          }

          return {
            ...link,
            connections
          };
        })
        .filter((link) => link.connections.length > 0)
        .map((link) => {
          const separatedLinks = separateLink(link);
          const snmp_speed = separatedLinks.reduce((sum, sLink) => sLink.snmp_speed + sum, 0);
          return Object.assign(link, { separatedLinks, snmp_speed });
        })
        .sort((a, b) => (a.description || '').localeCompare(b.description));
    }

    return [];
  }

  normalizeTopologyLinks(topology) {
    const linkProperties = ['links', 'deviceLinks', 'siteLinks', 'providerLinks', 'cloudLinks', 'externalLinks'];
    const normalizedTopology = { ...topology };

    for (let i = 0; i < linkProperties.length; i += 1) {
      const linkName = linkProperties[i];

      if (normalizedTopology[linkName]) {
        normalizedTopology[linkName] = this.normalizeLinks(normalizedTopology[linkName]);
        normalizedTopology[linkName] = this.addHealthToLinks(normalizedTopology[linkName], topology.health);
      }
    }

    return normalizedTopology;
  }

  addHealthToNodes({ nodes, nodeType, health }) {
    return Object.keys(nodes).reduce((acc, nodeId) => {
      // match the node to a health config
      const itemHealth = health[nodeType] && health[nodeType][nodeId];
      const itemHealthChecks = itemHealth && itemHealth.checks;
      let nodeHealth = null;

      if (itemHealthChecks) {
        nodeHealth = { cssClassName: getHealthClass(itemHealth), data: itemHealthChecks };

        if (nodeType === 'sites') {
          // get the site rollup
          const siteRollup = itemHealthChecks.find((check) => check.check_name === 'site_rollup');
          let deviceRollups = [];

          if (siteRollup && siteRollup.details) {
            const deviceRollup = siteRollup.details.device_rollup;

            if (deviceRollup) {
              const deviceIds = Object.values(deviceRollup).flat();

              // get the full device rollups
              deviceRollups = deviceIds.flatMap((deviceId) => {
                const deviceRollupReference = health.devices[deviceId].checks.find(
                  (check) => check.check_name === 'device_rollup'
                );

                return mergeRollupCheckDetails([deviceRollupReference], {
                  rollup: 'device_rollup',
                  referenceData: health.interfaces,
                  nodeId: deviceId
                });
              });
            }
          }

          // site rollup will additionally receive detailed device rollups for display in its health tab
          nodeHealth.data = mergeRollupCheckDetails(itemHealthChecks, {
            rollup: 'site_rollup',
            referenceData: health.devices
          }).concat(deviceRollups);
        } else if (nodeType === 'devices') {
          nodeHealth.data = mergeRollupCheckDetails(itemHealthChecks, {
            rollup: 'device_rollup',
            referenceData: health.interfaces,
            nodeId
          });
        }
      }

      return {
        ...acc,
        [nodeId]: {
          ...nodes[nodeId],
          health: nodeHealth
        }
      };
    }, {});
  }

  addHealthToLinks(links, health) {
    return links.map((link) => {
      const connections = (link.connections || []).map((connection) => {
        connection.interface1 = this.addHealthToInterface(connection.interface1, health);
        connection.interface2 = this.addHealthToInterface(connection.interface2, health);
        return connection;
      });

      return { ...link, connections };
    });
  }

  addHealthToInterface(intf, health) {
    if (intf) {
      const interfaceHealth = health.interfaces && health.interfaces[intf.device_id]?.[intf.snmp_id];

      if (interfaceHealth) {
        intf.health = {
          cssClassName: getHealthClass(interfaceHealth),
          data: interfaceHealth
        };
      } else {
        intf.health = false;
      }
    }

    return intf;
  }

  @computed
  get sidebarSettings() {
    return this.settingsModel.sidebarSettingsWithTimeRange;
  }

  @computed
  get querySettings() {
    return this.settingsModel.get('sidebarQueryOverrides');
  }

  // run all queries through here to decorate with settings from the sidebar such as time and filters
  getQuery(query) {
    const { filters: topLevelFilters, ...timeOptions } = this.querySettings;
    const { filters = getDefaultFiltersObject() } = query;
    const mergedFilters = mergeFilterGroups(filters, topLevelFilters);

    return {
      ...query,
      ...timeOptions,
      filters: mergedFilters,
      fastData: 'Fast'
    };
  }

  getASNQuery(props) {
    const query = getASNQuery(props);
    return this.getQuery(query);
  }

  getServicesQuery(props) {
    const query = getServicesQuery(props);
    return this.getQuery(query);
  }

  getIPsQuery(props) {
    const query = getIPsQuery(props);
    return this.getQuery(query);
  }

  getMultiCloudLinkQueries(props) {
    const { fromOtherCloudToThisCloudLinkQuery, fromThisCloudToOtherCloudLinkQuery } = getMultiCloudLinkQueries(props);

    return {
      fromOtherCloudToThisCloudLinkQuery: this.getQuery(fromOtherCloudToThisCloudLinkQuery),
      fromThisCloudToOtherCloudLinkQuery: this.getQuery(fromThisCloudToOtherCloudLinkQuery)
    };
  }

  getCloudMyNetworkLinkQueries(props) {
    const { fromCloudToMyNetworkLinkQuery, fromMyNetworkToCloudLinkQuery } = getCloudMyNetworkLinkQueries(props);

    return {
      fromCloudToMyNetworkLinkQuery: this.getQuery(fromCloudToMyNetworkLinkQuery),
      fromMyNetworkToCloudLinkQuery: this.getQuery(fromMyNetworkToCloudLinkQuery)
    };
  }

  getCloudInternetLinkQueries(props) {
    const { fromCloudToInternetLinkQuery, fromInternetToCloudLinkQuery } = getCloudInternetLinkQueries(props);

    return {
      fromCloudToInternetLinkQuery: this.getQuery(fromCloudToInternetLinkQuery),
      fromInternetToCloudLinkQuery: this.getQuery(fromInternetToCloudLinkQuery)
    };
  }

  getInternetMyNetworkLinkQuery(props) {
    const query = getInternetMyNetworkLinkQuery(props);

    return this.getQuery(query);
  }

  getAllClusterTrafficLinkQuery(props) {
    const query = getAllClusterTrafficLinkQuery(props);
    return this.getQuery(query);
  }

  getInternetMyNetworkLinkQueries(props) {
    const { fromInternetToMyNetworkLinkQuery, fromMyNetworkToInternetLinkQuery } =
      getInternetMyNetworkLinkQueries(props);

    return {
      fromInternetToMyNetworkLinkQuery: this.getQuery(fromInternetToMyNetworkLinkQuery),
      fromMyNetworkToInternetLinkQuery: this.getQuery(fromMyNetworkToInternetLinkQuery)
    };
  }

  getSiteDeviceLinkQueries(props) {
    const { inboundSiteDeviceLinkQuery, outboundSiteDeviceLinkQuery } = getSiteDeviceLinkQueries(props);
    return {
      inboundSiteDeviceLinkQuery: this.getQuery(inboundSiteDeviceLinkQuery),
      outboundSiteDeviceLinkQuery: this.getQuery(outboundSiteDeviceLinkQuery)
    };
  }

  getNodeLinkQuery({ selectedNode, type }, extraProps = {}) {
    if (this.interSiteTrafficEnabled) {
      const interSiteTrafficType = this.settingsModel.get('interSiteTraffic');

      if (selectedNode.type === 'device' && type === 'site') {
        const deviceModel = this.store.$devices.collection.get(selectedNode.value);

        return [
          this.getQuery(
            getDeviceSiteNodeLinkQuery(deviceModel, extraProps.site, {
              direction: 'inbound',
              type: interSiteTrafficType
            })
          ),
          this.getQuery(
            getDeviceSiteNodeLinkQuery(deviceModel, extraProps.site, {
              direction: 'outbound',
              type: interSiteTrafficType
            })
          )
        ];
      }

      if (selectedNode.type === 'site' && type === 'device') {
        const selectedSiteModel = this.store.$sites.collection.get(selectedNode.value);
        const detailSiteModel = this.store.$sites.collection.models.find((m) => m.get('title') === extraProps.site);

        // directional context is the detail site
        return [
          this.getQuery(
            getSiteDeviceNodeLinkQuery(selectedSiteModel, detailSiteModel, {
              direction: 'outbound',
              type: interSiteTrafficType
            })
          ),

          this.getQuery(
            getSiteDeviceNodeLinkQuery(selectedSiteModel, detailSiteModel, {
              direction: 'inbound',
              type: interSiteTrafficType
            })
          )
        ];
      }
    }

    const typeToDimensionMap = {
      cloud: { src: 'i_trf_origination', dst: 'i_trf_termination' },
      asn: { src: 'AS_src', dst: 'AS_dst' },
      provider: { src: 'i_src_provider_classification', dst: 'i_dst_provider_classification' },
      nextHopASN: { src: 'src_nexthop_asn', dst: 'dst_nexthop_asn' },
      site: { src: 'kt_src_site_title', dst: 'kt_dst_site_title' },
      device: { src: 'device_id', dst: 'device_id' },
      interface: { src: 'InterfaceID_src', dst: 'InterfaceID_dst' },
      awsRegion: { src: 'kt_aws_src_region', dst: 'kt_aws_dst_region' },
      azureRegion: { src: 'kt_az_src_region', dst: 'kt_az_dst_region' },
      gcpRegion: { src: 'ktsubtype__gcp_subnet__STR04', dst: 'ktsubtype__gcp_subnet__STR05' },
      awsVpc: { src: 'kt_aws_src_vpc_id', dst: 'kt_aws_dst_vpc_id' },
      azureVpc: { src: 'kt_az_src_vnet', dst: 'kt_az_dst_vnet' },
      azureSubnet: { src: 'kt_az_src_subnet', dst: 'kt_az_dst_subnet' },
      awsSubnet: { src: 'kt_aws_src_subnet_id', dst: 'kt_aws_dst_subnet_id' },
      gcpSubnet: { src: 'ktsubtype__gcp_subnet__STR08', dst: 'ktsubtype__gcp_subnet__STR09' }
    };

    const isCloudProviderType = (t) =>
      t.startsWith('azure') || t.startsWith('aws') || t.startsWith('gcp') || t.startsWith('oci');
    const isCloudType = (t) => t === 'cloud' || isCloudProviderType(t);
    const isInternetType = (t) => t === 'asn' || t === 'provider' || t === 'nextHopASN';
    const isMyNetworkType = (t) => t === 'site' || t === 'device' || t === 'interface';

    if (this.clouds.length === 0 && (isCloudType(selectedNode.type) || isCloudType(type))) {
      return [];
    }

    if (isCloudProviderType(selectedNode.type)) {
      const props = {
        ...extraProps,
        cloud: null,
        awsRegion: selectedNode.type === 'awsRegion' ? selectedNode.value : extraProps.awsRegion,
        azureRegion: selectedNode.type === 'azureRegion' ? selectedNode.value : extraProps.azureRegion,
        gcpRegion: selectedNode.type === 'gcpRegion' ? selectedNode.value : extraProps.gcpRegion,
        awsVpc: selectedNode.type === 'awsVpc' ? selectedNode.value : extraProps.awsVpc,
        azureVpc: selectedNode.type === 'azureVpc' ? selectedNode.value : extraProps.azureVpc,
        azureSubnet: selectedNode.type === 'azureSubnet' ? selectedNode.value : extraProps.azureSubnet,
        awsSubnet: selectedNode.type === 'awsSubnet' ? selectedNode.value : extraProps.awsSubnet,
        gcpSubnet: selectedNode.type === 'gcpSubnet' ? selectedNode.value : extraProps.gcpSubnet
      };

      if (isCloudType(type)) {
        const { fromOtherCloudToThisCloudLinkQuery, fromThisCloudToOtherCloudLinkQuery } =
          this.getMultiCloudLinkQueries(props);
        fromOtherCloudToThisCloudLinkQuery.metric = typeToDimensionMap[type].src;
        fromThisCloudToOtherCloudLinkQuery.metric = typeToDimensionMap[type].dst;
        return [fromOtherCloudToThisCloudLinkQuery, fromThisCloudToOtherCloudLinkQuery];
      }
    }

    if (isCloudType(selectedNode.type)) {
      const props = {
        ...extraProps,
        cloud: selectedNode.type === 'cloud' ? selectedNode.value : null,
        awsRegion: selectedNode.type === 'awsRegion' ? selectedNode.value : extraProps.awsRegion,
        azureRegion: selectedNode.type === 'azureRegion' ? selectedNode.value : extraProps.azureRegion,
        gcpRegion: selectedNode.type === 'gcpRegion' ? selectedNode.value : extraProps.gcpRegion,
        awsVpc: selectedNode.type === 'awsVpc' ? selectedNode.value : extraProps.awsVpc,
        azureVpc: selectedNode.type === 'azureVpc' ? selectedNode.value : extraProps.azureVpc,
        awsSubnet: selectedNode.type === 'awsSubnet' ? selectedNode.value : extraProps.awsSubnet,
        azureSubnet: selectedNode.type === 'azureSubnet' ? selectedNode.value : extraProps.azureSubnet,
        gcpSubnet: selectedNode.type === 'gcpSubnet' ? selectedNode.value : extraProps.gcpSubnet
      };

      if (isCloudProviderType(type)) {
        const { fromOtherCloudToThisCloudLinkQuery, fromThisCloudToOtherCloudLinkQuery } =
          this.getMultiCloudLinkQueries(props);
        fromThisCloudToOtherCloudLinkQuery.metric = typeToDimensionMap[type].src;
        fromOtherCloudToThisCloudLinkQuery.metric = typeToDimensionMap[type].dst;
        return [fromThisCloudToOtherCloudLinkQuery, fromOtherCloudToThisCloudLinkQuery];
      }

      if (isInternetType(type) && type !== 'nextHopASN') {
        const { fromCloudToInternetLinkQuery, fromInternetToCloudLinkQuery } = this.getCloudInternetLinkQueries(props);
        fromInternetToCloudLinkQuery.metric = typeToDimensionMap[type].src;
        fromCloudToInternetLinkQuery.metric = typeToDimensionMap[type].dst;
        return [fromInternetToCloudLinkQuery, fromCloudToInternetLinkQuery];
      }

      if (isMyNetworkType(type)) {
        const { fromCloudToMyNetworkLinkQuery, fromMyNetworkToCloudLinkQuery } =
          this.getCloudMyNetworkLinkQueries(props);
        fromMyNetworkToCloudLinkQuery.metric = typeToDimensionMap[type].src;
        fromCloudToMyNetworkLinkQuery.metric = typeToDimensionMap[type].dst;
        return [fromMyNetworkToCloudLinkQuery, fromCloudToMyNetworkLinkQuery];
      }
    }

    if (isInternetType(selectedNode.subType)) {
      const props = {
        ...extraProps,
        asn: selectedNode.subType === 'asn' ? selectedNode.value : null,
        provider: selectedNode.subType === 'provider' ? selectedNode.value : null,
        nextHopASN: selectedNode.subType === 'nextHopASN' ? selectedNode.value : null
      };

      if (isCloudType(type) && selectedNode.subType !== 'nextHopASN') {
        const { fromCloudToInternetLinkQuery, fromInternetToCloudLinkQuery } = this.getCloudInternetLinkQueries(props);
        fromCloudToInternetLinkQuery.metric = typeToDimensionMap[type].src;
        fromInternetToCloudLinkQuery.metric = typeToDimensionMap[type].dst;
        return [fromCloudToInternetLinkQuery, fromInternetToCloudLinkQuery];
      }

      if (isMyNetworkType(type)) {
        const { fromInternetToMyNetworkLinkQuery, fromMyNetworkToInternetLinkQuery } =
          this.getInternetMyNetworkLinkQueries(props);
        fromMyNetworkToInternetLinkQuery.metric = typeToDimensionMap[type].src;
        fromInternetToMyNetworkLinkQuery.metric = typeToDimensionMap[type].dst;
        return [fromMyNetworkToInternetLinkQuery, fromInternetToMyNetworkLinkQuery];
      }
    }

    if (isMyNetworkType(selectedNode.type)) {
      const props = {
        ...extraProps,
        site: selectedNode.type === 'site' ? selectedNode.value : null,
        device: selectedNode.type === 'device' ? selectedNode.value : null,
        intf: selectedNode.type === 'interface' ? selectedNode.value : null
      };

      if (isMyNetworkType(type)) {
        const { inboundSiteDeviceLinkQuery, outboundSiteDeviceLinkQuery } = this.getSiteDeviceLinkQueries(props);
        inboundSiteDeviceLinkQuery.metric = typeToDimensionMap[type].src;
        outboundSiteDeviceLinkQuery.metric = typeToDimensionMap[type].src;
        return [inboundSiteDeviceLinkQuery, outboundSiteDeviceLinkQuery];
      }

      if (isCloudType(type)) {
        const { fromCloudToMyNetworkLinkQuery, fromMyNetworkToCloudLinkQuery } =
          this.getCloudMyNetworkLinkQueries(props);
        fromCloudToMyNetworkLinkQuery.metric = typeToDimensionMap[type].src;
        fromMyNetworkToCloudLinkQuery.metric = typeToDimensionMap[type].dst;
        return [fromCloudToMyNetworkLinkQuery, fromMyNetworkToCloudLinkQuery];
      }

      if (isInternetType(type)) {
        const { fromInternetToMyNetworkLinkQuery, fromMyNetworkToInternetLinkQuery } =
          this.getInternetMyNetworkLinkQueries(props);
        fromInternetToMyNetworkLinkQuery.metric = typeToDimensionMap[type].src;
        fromMyNetworkToInternetLinkQuery.metric = typeToDimensionMap[type].dst;
        return [fromInternetToMyNetworkLinkQuery, fromMyNetworkToInternetLinkQuery];
      }
    }

    return [];
  }

  mergeSidebarQueryOptions(options) {
    return options.map((option) => {
      const { query } = option;

      if (query.inboundQuery && query.outboundQuery) {
        return {
          ...option,
          query: {
            inboundQuery: this.getQuery(query.inboundQuery),
            outboundQuery: this.getQuery(query.outboundQuery)
          }
        };
      }

      return {
        ...option,
        query: this.getQuery(query)
      };
    });
  }

  getCloudServices(cloudProviders, timeRange = { start: '', end: '' }) {
    return api.post('/api/ui/topology/cloud-services/', {
      showErrorToast: false,
      query: timeRange,
      data: { cloudProviders }
    });
  }

  getCloudServicesTraffic({ queries }) {
    return api.post('/api/ui/topology/cloud-services/traffic', {
      data: { queries }
    });
  }

  @computed
  get selectedAzureSubscriptions() {
    return (this.settingsModel.get('azureSubscriptionIds') ?? []).filter((subscriptionId) =>
      this.store.$clouds.azureSubscriptionIds.includes(subscriptionId)
    );
  }

  @computed
  get selectedAwsAccounts() {
    return this.settingsModel.get('awsAccountIds', []);
  }

  /**
   * will compare aws accounts in user settings vs currently loaded in aws cloud map and get difference
   * needed to topologyFetcherDisposer for reaction on CloudAwsMap to decide either map show be refreshed or not
   */
  @computed
  get hasAwsAccountsDiff() {
    const userSettingsAccounts = this.selectedAwsAccounts ?? [];
    const loadedAwsAccounts = this.awsCloudMapCollection.currentLoadedAccountIds ?? [];

    const diff = _difference(userSettingsAccounts, loadedAwsAccounts);
    const reverseDiff = _difference(loadedAwsAccounts, userSettingsAccounts);

    return [...diff, ...reverseDiff].length > 0;
  }

  @computed
  get selectedAwsAccountGroup() {
    return this.settingsModel.get('awsSelectedAccountGroup', '');
  }

  getAzureFrontendIPConfigurations({ id, start, end }) {
    const startRange = start || this.sidebarSettings.timeRange.start;
    const endRange = end || this.sidebarSettings.timeRange.end;

    return api.get(`/api/ui/topology/azure/loadbalancer/frontend?id=${id}&start=${startRange}&end=${endRange}`);
  }

  getAzureBackendPools({ id, start, end }) {
    const startRange = start || this.sidebarSettings.timeRange.start;
    const endRange = end || this.sidebarSettings.timeRange.end;

    return api.get(`/api/ui/topology/azure/loadbalancer/backend?id=${id}&start=${startRange}&end=${endRange}`);
  }

  getAzureMetrics({ id, start, end }) {
    const startRange = start || this.sidebarSettings.timeRange.start;
    const endRange = end || this.sidebarSettings.timeRange.end;

    return api.get(`/api/ui/topology/azure/metrics?id=${id}&start=${startRange}&end=${endRange}`);
  }

  getAwsMetrics({ entityType, id, start, end, region, postData = {} }) {
    const startRange = start || this.sidebarSettings.timeRange.start;
    const endRange = end || this.sidebarSettings.timeRange.end;
    postData.region = region;
    return api.post(`/api/ui/topology/aws/metrics/${entityType}/${id}?start=${startRange}&end=${endRange}`, {
      data: postData
    });
  }

  getOciMetrics({ entityType, id, start, end, postData = {} }) {
    const startRange = start || this.sidebarSettings.timeRange.start;
    const endRange = end || this.sidebarSettings.timeRange.end;

    return api.post(`/api/ui/topology/oci/metrics/${entityType}/${id}?start=${startRange}&end=${endRange}`, {
      data: postData
    });
  }

  getGcpMetrics({ entityType, id, start, end }) {
    const startRange = start || this.sidebarSettings.timeRange.start;
    const endRange = end || this.sidebarSettings.timeRange.end;
    return api.get(`/api/ui/topology/gcp/metrics/${entityType}?id=${id}&start=${startRange}&end=${endRange}`);
  }

  getVNetGatewayBGPPeerStatus({ id }) {
    return api.get(`/api/ui/topology/azure/vnetGateway/bgp/peerStatus?id=${id}`);
  }

  getVNetGatewayLearnedRoutes({ id }) {
    return api.get(`/api/ui/topology/azure/vnetGateway/bgp/learnedRoutes?id=${id}`);
  }

  get hasAWSExportOrDevice() {
    return this.hasCloud('AWS') || this.store.$devices.hasAWSDevice;
  }

  get cloudProviders() {
    return Object.keys(this.providerMap);
  }
}

export default new HybridMapStore();
