import Validator from 'validatorjs';
import { memoize } from 'lodash';

import $clouds from 'app/stores/clouds/$clouds';
import GcpExportConfigFields from 'app/views/setup/tasks/cloud_v2/CloudExportConfigWizard/components/gcp/GcpExportConfigFields';

export const GCP_CLOUD_EXPORT_CONFIG_STEP = 'gcp-export-configuration';
export const GCP_CLOUD_PROVIDER_CONFIG_STEP = 'gcp-provider-configuration';
export const GCP_CLOUD_VERIFICATION_STEP = 'gcp-verification';
export const GCP_CLOUD_FINAL_STEP = 'gcp-final-steps';

export const GCP_EXPORT_ADDITIONAL_STEPS = [
  {
    id: GCP_CLOUD_EXPORT_CONFIG_STEP,
    title: 'Kentik Export GCP Configuration',
    component: GcpExportConfigFields,
    registerValidators: (form) => {
      Validator.registerAsync('unique_project_id', (value, args, attribute, passes) => {
        // Gcp project id must be unique across all of current kentik environment
        // Note that this check excludes current CID, so multiple exports with same project id are ok for same company
        const projectId = form.getValue('properties.gce_project');
        return $clouds
          .checkCloudProviderIdEligibility({
            cloudProvider: 'gcp',
            ids: [projectId]
          })
          .then((response) => {
            if (!response) {
              // $clouds.checkCloudProviderIdEligibility returns nothing, will only throw an error
              // no response means no error, all good to proceed
              passes(true);
            }
          })
          .catch((err) => {
            passes(false, err.message);
          });
      });
    },
    fieldsToRemove: ($cloudExportWizard) => {
      if ($cloudExportWizard.isFlowLogCollectionEnabled || $cloudExportWizard.isGcpCloudRunLogEnabled) {
        return [];
      }
      return ['properties.gce_subscription'].filter((optionalField) => $cloudExportWizard.formHasField(optionalField));
    },
    generateFields: memoize(
      () => ({
        'properties.gce_project': {
          label: 'Project ID',
          rules: 'required|unique_project_id'
        },
        'properties.gce_subscription': {
          label: 'Subscription',
          rules: 'required'
        }
      }),
      ($cloudExportWizard) => $cloudExportWizard.selectedCloudProvider
    )
  }
];
