import { action, observable } from 'mobx';
import { isFinite } from 'lodash';
import { api } from 'core/util';
import { KMI_RANK_TYPES } from 'app/util/constants';
import moment from 'moment';

class MarketIntelStore {
  rankingDataPromiseMap = {};

  edgesPromiseMap = {};

  asnDataPromiseMap = {};

  topRankings = {};

  historicalRankings = {};

  @observable
  filterObj = {
    geo_scope: 'planet',
    geo_name: 'earth',
    region_code: 'earth',
    marketName: 'Global',
    ip: 'v4',
    rank_type: 0,
    label: 'All',
    geo_breakdown: 'cc',
    id: 'planet$earth',
    compareAsn: 0
  };

  setFilter(filter) {
    const { rank_type, id } = filter;
    const new_filter = { ...this.filterObj, ...filter };
    if (Number.isInteger(rank_type) && this.filterObj.rank_type !== rank_type) {
      const { labelShort, label } = KMI_RANK_TYPES.byRankType(filter.rank_type);
      new_filter.label = labelShort || label;
    }

    this.filterObj = new_filter;
    if (id && this.filterObj.id !== id) {
      this.fetchMarketName(id);
    }

    return this.filterObj;
  }

  @observable
  myAsnArr = undefined;

  initMI() {
    return Promise.resolve().then(() => {
      if (!this.myAsnArr) {
        return Promise.all([
          this.store.$networkClass.model.fetch(),
          this.store.$moduleConfig.fetchModuleConfig('marketintel'),
          this.getMarketsHierarchy()
        ]).then(() => {
          const validAsns = [];
          this.store.$networkClass.model.internalASNs.forEach((asnStr) =>
            parseInt(asnStr) ? validAsns.push(parseInt(asnStr)) : undefined
          );
          this.myAsnArr = this.store.$moduleConfig.get('marketintel', 'myAsn', validAsns);
          const [compareAsn = 0] = this.myAsnArr;
          this.filterObj.compareAsn = compareAsn;
          if (compareAsn !== 0) {
            this.fetchNetworkDetails({ asn: compareAsn });
          }
        });
      }
      return undefined;
    });
  }

  saveConfig({ myAsnArr }) {
    this.myAsnArr = myAsnArr;
    this.setFilter({ compareAsn: myAsnArr[0] || 0 });
    this.rankingDataPromiseMap = {};
    this.edgesPromiseMap = {};
    this.asnDataPromiseMap = {};
    return myAsnArr.length;
  }

  getAsnDataPromise(asn) {
    if (!this.asnDataPromiseMap[asn]) {
      this.asnDataPromiseMap[asn] = {
        surrounding: {},
        sh: {},
        peers: {},
        providerHist: {},
        customerHist: {},
        shHist: {},
        rankHist: {},
        pfxDist: {}
      };
    }
    return this.asnDataPromiseMap[asn];
  }

  mergeRankings(rankings, myNetwork, baseType) {
    const myRankings = myNetwork.filter((net) => net.rank_type.toString() === baseType).sort((a, b) => a.rank - b.rank);
    myRankings.forEach((ranking) => {
      if (ranking.rank > rankings.length) {
        rankings.push({ ...ranking, myNet: true });
      } else {
        const idx = rankings.findIndex((net) => net.asn === ranking.asn);
        rankings[idx] = { ...rankings[idx], myNet: true };
      }
    });

    return rankings;
  }

  getLatestRankings() {
    const { ip, id } = this.filterObj;
    const key = `${id}$${ip}`;
    const rankingData = {};

    if (!this.rankingDataPromiseMap[key]) {
      this.rankingDataPromiseMap[key] = api.post('/api/ui/market-intel/rankings/all', {
        body: { filter: { id, ip }, asnArr: this.myAsnArr }
      });
    }
    return this.rankingDataPromiseMap[key].then((rankData) => {
      const { rankings, myNetwork } = rankData;
      rankings.forEach((rank) => {
        if (!rankingData[rank.rank_type]) {
          rankingData[rank.rank_type] = [];
        }
        rankingData[rank.rank_type].push(rank);
      });
      Object.keys(rankingData).forEach((rankType) => {
        const rankSubset = rankingData[rankType].sort((a, b) => a.rank - b.rank);
        const mergedRankings = this.mergeRankings(rankSubset, myNetwork, rankType);
        const maxScore = mergedRankings.reduce((max, net) => Math.max(max, net.score), 0);
        rankingData[rankType] = { maxScore, rankings: mergedRankings };
      });
      return rankingData;
    });
  }

  fetchHistoricalRankings(provider) {
    const { id, ip, rank_type } = this.filterObj;
    const key = `${id}$${ip}$${provider || 'top'}$${rank_type}`;
    if (!this.historicalRankings[key]) {
      this.historicalRankings[key] = api.post('/api/ui/market-intel/rankings/top10YTD', {
        body: {
          filter: {
            id,
            ip,
            provider,
            rank_type
          }
        }
      });
    }
    return this.historicalRankings[key];
  }

  @action
  fetchTopRankings() {
    const { ip, id } = this.filterObj;
    const key = `${id}$${ip}`;
    if (!this.topRankings[key]) {
      this.topRankings[key] = api.post('/api/ui/market-intel/rankings/top', { body: { ip, id } });
    }
    return this.topRankings[key];
  }

  fetchPfxDist(asn) {
    const { ip, id, rank_type } = this.filterObj;
    const key = `${ip}-${id}-${rank_type}`;
    if (!this.getAsnDataPromise(asn).pfxDist[key]) {
      this.asnDataPromiseMap[asn].pfxDist[key] = api.post(`/api/ui/market-intel/pfxDist/${asn}`, {
        body: { ip, id, rank_type }
      });
    }
    return this.asnDataPromiseMap[asn].pfxDist[key];
  }

  @action
  getMarketsHierarchy() {
    if (!this.marketsPromise) {
      this.marketsPromise = api.get('/api/ui/market-intel/markets');
    }
    return this.marketsPromise;
  }

  buildMarketNavStack(id, marketList) {
    const { parent_list = [] } = marketList[id];
    const stack = parent_list.length === 0 ? [] : this.buildMarketNavStack(parent_list[0], marketList);
    stack.push(id);

    return stack;
  }

  fetchMarketName(id) {
    this.getMarketsHierarchy().then((markets) => {
      if (id && markets[id]) {
        this.filterObj.marketName = markets[id].name;
      }
    });
  }

  @action
  fetchNetworkDetails({ asn }) {
    if (!this.getAsnDataPromise(asn).details) {
      this.asnDataPromiseMap[asn].details = api.get(`/api/ui/market-intel/network/${asn}`);
    }

    return Promise.all([
      this.asnDataPromiseMap[asn].details,
      this.getSingleHomeCustomers(asn),
      this.fetchPfxDist(asn)
    ]).then(([details, sh, pfxDist]) => {
      if (!details) {
        return null;
      }
      return { ...details, asn, singleHomeCustomers: sh, pfxDist };
    });
  }

  decorateNetworks(subject, compare = [], shCustomers) {
    return subject
      .map((net) => {
        const mutual = compare && !!compare.find((match) => match.asn === net.asn);
        const single_homed = shCustomers ? shCustomers.includes(net.asn) : false;
        return { ...net, mutual, single_homed };
      })
      .sort((a, b) => b.score - a.score)
      .map((net, idx) => ({ ...net, rank: idx + 1 }));
  }

  @action
  searchNetworks(query) {
    if (!query || query === '') {
      return [];
    }
    return api.get(`/api/ui/market-intel/search?q=${encodeURIComponent(query.replace(/\s+/g, ' '))}`);
  }

  @action
  getSurroundingRankings(asn) {
    const { ip, id, compareAsn } = this.filterObj;
    const key = `${ip}-${id}`;
    if (!this.getAsnDataPromise(asn).surrounding[key]) {
      this.asnDataPromiseMap[asn].surrounding[key] = api.post('/api/ui/market-intel/rankings/window', {
        body: { asn, id, ip, asnArr: this.myAsnArr }
      });
    }
    return Promise.all([this.asnDataPromiseMap[asn].surrounding[key], this.fetchTopRankings()]).then(
      ([data, topRanksData]) => {
        const { rankWindowData, backupNameMap } = data;
        const mergedRankings = {};
        Object.keys(topRanksData).forEach((type) => {
          const typeData = rankWindowData[type];
          const topTypeData = topRanksData[type];
          topTypeData.sort((a, b) => a.rank - b.rank);
          const rank_list = [];
          if (typeData) {
            if (typeData.every((r) => this.myAsnArr.includes(parseInt(r.asn)))) {
              typeData.forEach((row) => {
                if (row.rank > 10) {
                  rank_list.push(row);
                }
              });
              rank_list.push(...topTypeData);
            } else {
              const min_rank = typeData.reduce((r_min, r) => Math.min(parseInt(r.rank), r_min), 2);
              if (min_rank > 1) {
                rank_list.push(topTypeData[0]);
              }
              rank_list.push(...typeData);

              if (rank_list.length < 10 && typeData.reduce((r_min, r) => Math.min(parseInt(r.rank), r_min), 10) > 10) {
                const diff = 11 - rank_list.length;
                for (let i = 1; i <= diff; i++) {
                  rank_list.push(topTypeData[i]);
                }
              }
            }
          } else {
            rank_list.push(...topTypeData);
          }
          rank_list.sort((a, b) => a.rank - b.rank);

          // add no ranking row for detail asn
          if (!rank_list.some(({ asn: testAsn }) => parseInt(testAsn) === asn)) {
            rank_list.push({ asn, name: backupNameMap[asn], rank: null, rank_type: type });
          }
          // add no ranking row for my asn
          if (compareAsn !== 0 && !rank_list.some(({ asn: testAsn }) => parseInt(testAsn) === compareAsn)) {
            rank_list.push({
              asn: compareAsn,
              name: backupNameMap[compareAsn],
              rank: null,
              rank_type: type
            });
          }
          mergedRankings[type] = rank_list;
        });
        return mergedRankings;
      }
    );
  }

  @action
  getSingleHomeCustomers(asn, ipOverride) {
    const { ip, id, rank_type } = this.filterObj;
    const key = `${ipOverride || ip}-${id}-${rank_type}`;
    if (!this.getAsnDataPromise(asn).sh[key]) {
      this.asnDataPromiseMap[asn].sh[key] = api.post(`/api/ui/market-intel/sh/${asn}`, {
        body: { ip: ipOverride || ip, id, rank_type }
      });
    }
    return this.asnDataPromiseMap[asn].sh[key];
  }

  @action
  getPeers(asn) {
    const { ip, id } = this.filterObj;
    const key = `${ip}-${id}`;
    if (!this.getAsnDataPromise(asn).peers[key]) {
      this.asnDataPromiseMap[asn].peers[key] = api.post(`/api/ui/market-intel/peers/${asn}`, { body: { ip, id } });
    }
    return this.asnDataPromiseMap[asn].peers[key];
  }

  getPeerData(asn) {
    const { compareAsn } = this.filterObj;
    return Promise.all([this.getPeers(asn), this.getPeers(compareAsn)]).then(([peerData, myPeerData]) => ({
      peerData,
      myPeerData
    }));
  }

  @action
  getCustomersProviders(asn) {
    const { id, compareAsn } = this.filterObj;
    const focusKey = `${asn}$${id}`;
    const compareKey = `${compareAsn}$${id}`;
    if (!this.edgesPromiseMap[focusKey]) {
      this.edgesPromiseMap[focusKey] = api.post('/api/ui/market-intel/edges', {
        body: { id, asn: parseInt(asn) }
      });
    }
    const reqArr = [
      this.getSingleHomeCustomers(asn, 'v4'),
      this.getSingleHomeCustomers(asn, 'v6'),
      this.edgesPromiseMap[focusKey]
    ];
    if (compareAsn && compareAsn !== 0 && compareAsn !== asn) {
      if (!this.edgesPromiseMap[compareKey]) {
        this.edgesPromiseMap[compareKey] = api.post('/api/ui/market-intel/edges', {
          body: { id, asn: compareAsn }
        });
      }
      reqArr.push(this.edgesPromiseMap[compareKey]);
    }

    return Promise.all(reqArr).then(([sh4, sh6, networkEdges, myEdges = {}]) => {
      const data = [];
      data.push({
        asn,
        v4: {
          customerArr: this.decorateNetworks(networkEdges.v4.customers, myEdges.v4?.customers, sh4),
          providerArr: this.decorateNetworks(networkEdges.v4.providers, myEdges.v4?.providers),
          transitFree: networkEdges.v4.providers.length === 0
        },
        v6: {
          customerArr: this.decorateNetworks(networkEdges.v6.customers, myEdges.v6?.customers, sh6),
          providerArr: this.decorateNetworks(networkEdges.v6.providers, myEdges.v6?.providers),
          transitFree: networkEdges.v6.providers.length === 0
        }
      });
      if (compareAsn !== asn && myEdges.v4) {
        data.push({
          asn: compareAsn,
          v4: {
            customerArr: myEdges.v4?.customers || [],
            providerArr: myEdges.v4?.providers || []
          },
          v6: {
            customerArr: myEdges.v6?.customers || [],
            providerArr: myEdges.v6?.providers || []
          }
        });
      }

      return data;
    });
  }

  exportCsv = (options) => {
    const { exportType } = options;

    switch (exportType) {
      case 'rank':
        return this.store.$exports.exportCsv({
          fileName: 'export-kmi-rankings',
          asyncData: () => this.getRankingCsv(options)
        });
      case 'edge':
        return this.store.$exports.exportCsv({
          fileName: 'export-kmi-edges',
          asyncData: () => this.getEdgeCsv(options)
        });
      default:
        return undefined;
    }
  };

  generateCustomerTypeColumn = (rowData, topScoreData) => {
    const { rank_type, score: topScore } = topScoreData;
    const { label, labelShort } = KMI_RANK_TYPES.byRankType(rank_type);
    const title = labelShort || label;
    if (rowData) {
      const { rank, rank_change, score, score_change } = rowData;

      return [
        [`${title} Rank`, rank],
        [`${title} Rank 28d progression`, isFinite(rank_change) ? rank_change : ''],
        [`${title} KMI Score`, score],
        [`Percentage of ${title} top Score`, ((parseInt(score) / topScore) * 100)?.toFixed(1)],
        [`${title} KMI Score 28d delta`, isFinite(score_change) ? score_change : '']
      ];
    }

    return [
      [`${title} Rank`, ''],
      [`${title} Rank 28d progression`, ''],
      [`${title} KMI Score`, ''],
      [`Percentage of ${title} top Score`, ''],
      [`${title} KMI Score 28d delta`, '']
    ];
  };

  getRankingCsv({ ip, id, rank_type, startRank, endRank }) {
    return Promise.all([
      api.post('/api/ui/market-intel/export', {
        body: { id, ip, rank_type, startRank, endRank, exportType: 'rank' }
      }),
      this.getMarketsHierarchy()
    ]).then(([exportData, marketData]) => {
      const { rankings_map, topScores } = exportData;
      const currentDate = moment().format();
      const customerTypeArr = [0, 1, 2, 4]; // how can I make this not hardcoded?
      const market = this.buildMarketNavStack(id, marketData).reduce(
        (acc, m) => (acc === '' ? marketData[m].name : `${acc}->${marketData[m].name}`),
        ''
      );
      const unsorted = [];
      Object.keys(rankings_map).forEach((ranking_asn) => {
        const asn_map = rankings_map[ranking_asn];
        const { asn, name, rank, rank_change, score, score_change } = asn_map[rank_type];
        const baseColumns = [
          ['Export date', currentDate],
          ['Export type', 'rank'],
          ['IP Family', `IP${ip}`],
          ['Market', market],
          ['Ranking Type', KMI_RANK_TYPES.byRankType(rank_type).label],
          ['ASN', asn],
          ['Network Name', name]
        ];

        let additionalColumns = [];

        if (customerTypeArr.includes(rank_type)) {
          // columns for rank types 0, 1, 2, 4
          const focusColumns = this.generateCustomerTypeColumn(asn_map[rank_type], topScores[rank_type]);
          const otherTypes = customerTypeArr.filter((num) => num !== rank_type);
          additionalColumns = focusColumns.concat(
            otherTypes.flatMap((other) => this.generateCustomerTypeColumn(asn_map[other], topScores[other]))
          );
        } else {
          // columns for rank types 10, 20
          const title = KMI_RANK_TYPES.byRankType(rank_type).label;
          additionalColumns = [
            [`${title} Rank`, rank],
            [`${title} Rank 28d progression`, `${rank_change || rank_change === 0 ? '0' : ''}`],
            [`${title} KMI Score`, score],
            [
              `Percentage of ${title} top Score`,
              `${((parseInt(score) / topScores[rank_type].score) * 100)?.toFixed(1) || ''}`
            ],
            [`${title} KMI Score 28d delta`, `${score_change || score_change === 0 ? '0' : ''}`]
          ];
        }

        unsorted.push(baseColumns.concat(additionalColumns));
      });
      const sorted = unsorted.sort((a, b) => a[7][1] - b[7][1]);
      const exportObj = sorted.map((row) => Object.fromEntries(row));

      return exportObj;
    });
  }

  getEdgeCsv({ ip, id, compareAsn, thisAsn, thisAsnName, excludeMutual, includeProviders, onlySH }) {
    const compareKey = `${compareAsn}$${id}`;
    const reqArr = [
      api.post('/api/ui/market-intel/export', {
        body: {
          id,
          ip,
          compareAsn: thisAsn,
          exportType: 'edge'
        }
      }),
      this.getSingleHomeCustomers(thisAsn),
      this.getMarketsHierarchy()
    ];
    if (compareAsn && compareAsn !== thisAsn) {
      if (!this.edgesPromiseMap[compareKey]) {
        this.edgesPromiseMap[compareKey] = api.post('/api/ui/market-intel/edges', {
          body: { id, compareAsn }
        });
      }
      reqArr.push(this.edgesPromiseMap[compareKey]);
    }
    return Promise.all(reqArr).then(([edges, shData, marketData, myEdges = {}]) => {
      const { customers, providers, topScores } = edges;
      const currentDate = moment().format();

      let exportRows = this.decorateNetworks(customers, myEdges[ip]?.customers, shData);
      const providerRows = this.decorateNetworks(providers, myEdges[ip]?.providers);
      if (excludeMutual) {
        exportRows = exportRows.filter((row) => !row.mutual);
      }
      if (onlySH) {
        exportRows = exportRows.filter((row) => row.single_homed);
      }
      if (includeProviders) {
        exportRows = providerRows.concat(exportRows);
      }

      const market = this.buildMarketNavStack(id, marketData).reduce(
        (acc, m) => (acc === '' ? marketData[m].name : `${acc}->${marketData[m].name}`),
        ''
      );

      return exportRows.map((ranking) => {
        const { asn, name, edge_type, mutual, single_homed, score } = ranking;
        const baseColumns = [
          ['Export date', currentDate],
          ['Export type', 'edge'],
          ['IP Family', `IP${ip}`],
          ['Market', market],
          ['Target ASN', thisAsn],
          ['Target Network Name', thisAsnName],
          ['Relation', edge_type === 0 ? 'Provider' : 'Customer'],
          ['ASN', asn],
          ['Network Name', name],
          ['Edge Score', score],
          ['Percentage of Edge top Score', `${((parseInt(score) / topScores[edge_type]) * 100)?.toFixed(1) || ''}`],
          [`Mutual w/ AS${compareAsn}`, mutual ? 'Yes' : 'No'],
          ['Single Homed', edge_type === 0 ? 'n/a' : `${single_homed ? 'Yes' : 'No'}`]
        ];

        return Object.fromEntries(baseColumns);
      });
    });
  }

  getInsights(options) {
    const { limit = 100, lookback = 1, includeGeo = true, asn, asnArr, types, market, magnitude, ip } = options;
    const { id, ip: store_ip } = this.filterObj;
    const queryOptions = { limit, lookback, ip: ip || store_ip, asnArr: asn ? [asn] : asnArr, types, magnitude };
    if (includeGeo) {
      queryOptions.id = market || id;
    }

    return api.post('/api/ui/market-intel/insights', { body: queryOptions }).then((insights) => insights);
  }

  fetchProviderHistory({ asn }) {
    const { ip, id } = this.filterObj;
    const key = `${ip}-${id}`;
    if (!this.getAsnDataPromise(asn).providerHist[key]) {
      this.asnDataPromiseMap[asn].providerHist[key] = api.post(`/api/ui/market-intel/history/providers/${asn}`, {
        body: { ip, id }
      });
    }
    return this.asnDataPromiseMap[asn].providerHist[key];
  }

  fetchCustomerHistory({ asn }) {
    const { ip, id, rank_type } = this.filterObj;
    const key = `${ip}-${id}-${rank_type}`;
    if (!this.getAsnDataPromise(asn).customerHist[key]) {
      this.asnDataPromiseMap[asn].customerHist[key] = api.post(`/api/ui/market-intel/history/customers/${asn}`, {
        body: { ip, id, rank_type }
      });
    }
    return this.asnDataPromiseMap[asn].customerHist[key];
  }

  fetchSHCustomerHistory({ asn }) {
    const { ip, id, rank_type } = this.filterObj;
    const key = `${ip}-${id}-${rank_type}`;
    if (!this.getAsnDataPromise(asn).shHist[key]) {
      this.asnDataPromiseMap[asn].shHist[key] = api.post(`/api/ui/market-intel/history/customers/sh/${asn}`, {
        body: { ip, id, rank_type }
      });
    }
    return this.asnDataPromiseMap[asn].shHist[key];
  }

  fetchRankingHistory({ asn, rank_type: rankOverride = -1 }) {
    const { ip, id, rank_type } = this.filterObj;
    const key = `${ip}-${id}-${rankOverride >= 0 ? rankOverride : rank_type}`;
    if (!this.getAsnDataPromise(asn).rankHist[key]) {
      this.asnDataPromiseMap[asn].rankHist[key] = api.post(`/api/ui/market-intel/history/rankings/${asn}`, {
        body: { ip, id, rank_type: rankOverride >= 0 ? rankOverride : rank_type }
      });
    }
    return this.asnDataPromiseMap[asn].rankHist[key];
  }
}

export default new MarketIntelStore();
