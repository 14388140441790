import QueryModel from 'app/stores/query/QueryModel';
import { addFilters } from 'app/stores/query/FilterUtils';
import $metrics from 'app/stores/metrics/$metrics';
import $sites from 'app/stores/site/$sites';
import moment, { duration } from 'moment';

/**
 * @typedef {object} KmetricsRange
 * @property {string} [lookback] duration in ISO-8601 format, typically this is what is used
 * @property {string} [start] start time, for custom queries
 * @property {string} [end] end time, for custom queries
 * @property {number} [lookback_seconds] lookback seconds. this will be used if lookback is not set, which typically only happens for custom duration.
 */

const fields = {
  interface: {
    ingress: {
      filterField: 'input_port',
      metric: ['InterfaceID_src']
    },
    egress: {
      filterField: 'output_port',
      metric: ['InterfaceID_dst']
    }
  },
  site: {
    filterField: 'i_device_site_name',
    networkFilterValue: 'external',
    metric: ['i_device_site_name'],
    ingress: {
      networkFilterField: 'i_src_network_bndry_name'
    },
    egress: {
      networkFilterField: 'i_dst_network_bndry_name'
    }
  }
};

const DIRECTION_TEXT = {
  ingress: 'Inbound',
  egress: 'Outbound'
};

/**
 * @param range {KmetricsRange}
 * @param lastUpdated {number} update time for the query, used to ensure that DE queries are aligned with ME lookback queries
 * @returns {{show_total_overlay: boolean, all_devices: boolean, metric: string[], meta: {}, lookback_seconds: number, filterDimensions: string[], show_overlay: boolean, starting_time?: string, ending_time?: string}}
 */
const baseQueryModelParams = (range, lastUpdated) => {
  const lookback = range.lookback ? duration(range.lookback).asMilliseconds() : range.lookback_seconds * 1000;
  // timestamps in milliseconds are supported but DE queries complain about unapplied changes when they have to derive the time rather than using a string
  // TODO fix the above and remove moment
  const starting_time = range.start || moment.utc(lastUpdated - lookback).toString();
  const ending_time = range.end || moment.utc(lastUpdated).toString();
  return {
    all_devices: false,
    show_total_overlay: false,
    show_overlay: false,
    lookback_seconds: 0,
    starting_time,
    ending_time,
    // this really means Dimensions
    metric: [],
    filterDimensions: [],
    meta: {}
  };
};

/**
 * @param params {object}
 * @param params.range {KmetricsRange}
 * @param [params.device_name] {string}
 * @param [params.device_ip] {string}
 * @param [params.mac_address] {string}
 * @param params.lastUpdated {number} update time for the query, used to ensure that DE queries are aligned with ME lookback queries
 * @returns {{queryFields: {device_ip?: string, device_name: string, mac_address?: string}, params: {show_total_overlay: boolean, all_devices: boolean, metric: string[], meta: {}, lookback_seconds: number, filterDimensions: string[], show_overlay: boolean}}}
 * @private
 */
const _getDeviceTrafficQuery = ({ range, device_name, device_ip, mac_address, lastUpdated }) => {
  device_name = $metrics.getMetricsDeviceNameFromQueryModel({ device_name, device_ip, mac_address });

  const params = baseQueryModelParams(range, lastUpdated);
  if (!device_name) {
    throw new Error('Device name must be provided for query to function');
  }
  params.metric.push('i_device_id');
  params.device_name = device_name;

  return { params, queryFields: { device_name, device_ip, mac_address } };
};

const queries = {
  /**
   * @param range {KmetricsRange}
   * @param [device_name] {string}
   * @param [device_ip] {string}
   * @param ifindex {number}
   * @param interface_name {string}
   * @param direction {'ingress' | 'egress'}
   * @param lastUpdated {number} update time for the query, used to ensure that DE queries are aligned with ME lookback queries
   * @returns {{queryFields: {device_ip?: string, ifindex: number, device_name: string, name: string}, queryModel: import('./QueryModel').default}}
   */
  interface: ({ range, device_name, device_ip, ifindex, interface_name, direction = 'ingress', lastUpdated }) => {
    const { params } = _getDeviceTrafficQuery({ device_name, device_ip, range, lastUpdated });
    const { filterField, metric } = fields.interface[direction];

    params.metric.push(...metric);
    params.query_title = `${DIRECTION_TEXT[direction]} Device Traffic: ${device_name}`;
    params.meta.query_subtitle = `Interface: ${interface_name}`;

    const queryModel = QueryModel.create(params).serialize();

    addFilters(queryModel, [
      {
        filterField,
        operator: '=',
        filterValue: String(ifindex)
      }
    ]);

    const queryFields = { name: interface_name, ifindex, device_ip, device_name };

    return { queryModel, queryFields };
  },
  /**
   * @param params {object}
   * @param params.range {KmetricsRange} lookback duration in ISO-8601 format
   * @param [params.device_name] {string}
   * @param [params.device_ip] {string}
   * @param [params.mac_address] {string}
   * @param params.lastUpdated {number} update time for the query, used to ensure that DE queries are aligned with ME lookback queries
   * @returns {{queryFields: {device_ip?: string, device_name: string, mac_address?: string}, queryModel: import('./QueryModel').default}}
   */
  device: ({ device_name, range, device_ip, mac_address, lastUpdated }) => {
    const { params, queryFields } = _getDeviceTrafficQuery({
      range,
      device_name,
      device_ip,
      mac_address,
      lastUpdated
    });
    params.query_title = `Device Traffic: ${device_name}`;
    params.show_total_overlay = true;
    params.topx = 40;
    return { queryModel: QueryModel.create(params).serialize(), queryFields };
  },
  /**
   * @param range {KmetricsRange}
   * @param site_name {string}
   * @param direction {'ingress' | 'egress'}
   * @param lastUpdated {number} update time for the query, used to ensure that DE queries are aligned with ME lookback queries
   * @returns {{queryFields: {site_name}, queryModel: import('./QueryModel').default}}
   */
  site: ({ range, site_name, direction = 'ingress', lastUpdated }) => {
    const params = baseQueryModelParams(range, lastUpdated);
    const site = $sites.byName(site_name);
    if (!site) {
      throw new Error(`Site ${site_name} not found!`);
    }
    const { networkFilterValue, filterField, metric } = fields.site;

    const { networkFilterField } = fields.site[direction];

    params.query_title = `${DIRECTION_TEXT[direction]} Traffic at Site: ${site_name}`;
    params.metric.push(...metric);
    params.device_sites = [site.get('id')];

    const queryModel = QueryModel.create(params).serialize();

    addFilters(queryModel, [
      {
        filterField,
        operator: '=',
        filterValue: String(site_name)
      },
      { filterField: networkFilterField, operator: '=', filterValue: networkFilterValue }
    ]);

    const queryFields = { site_name };

    return { queryModel, queryFields };
  }
};

/**
 * Construct a traffic query (DataExplorer) from NMS data (provided by MetricsExplorer)
 *
 * @param type {'site' | 'device' | 'interface'} type of lookup to attempt
 * @param props {object}
 * @param props.range {KmetricsRange} query.kmetrics.range, typically just {lookback: string}
 * @param [props.device_name] {string} device name must be provided for all queries except for site. device_ip can be used to lookup this value.
 * @param [props.device_ip] {string} alternative method of specifying device_name, looks up the relevant device for query.
 * @param [props.ifindex] {number} interface index
 * @param [props.interface_name] {string} interface name
 * @param [props.mac_address] {string} interface mac address
 * @param [props.site_name] {string} only used for site queries
 * @param [props.direction] {'ingress' | 'egress'}
 * @param props.lastUpdated {number} update time for the query, used to ensure that DE queries are aligned with ME lookback queries
 * @returns {{queryModel: import('./QueryModel').default, queryFields: object}}
 */
export const getTrafficQueryFromNms = ({ type, ...props }) => queries[type](props);
