import buildFilterGroup from 'core/util/filters/buildFilterGroup';
import { getOutboundFilters } from 'app/components/filters/simple/simpleFilterUtils';
import { getCdnFilterGroup } from '../cdn/queries';

export const OTT_LANDING_TOPX = 8;

const baseFilters = {
  baseAgg: [
    {
      filterField: 'service_type',
      operator: '<>',
      filterValue: ''
    }
  ],

  knownProviders: [
    {
      filterField: 'service_provider',
      operator: '<>',
      filterValue: 'unknown'
    }
  ],

  knownCategories: [
    {
      filterField: 'service_type',
      operator: '<>',
      filterValue: 'unknown'
    }
  ],

  noDstCdn: buildFilterGroup({
    name: 'noDstCdn',
    filters: [
      {
        filterField: 'dst_cdn',
        operator: '=',
        filterValue: ''
      }
    ]
  }),

  no128: buildFilterGroup({
    name: 'no128',
    filters: [
      {
        filterField: 'inet_src|dst_addr',
        operator: '<>',
        filterValue: '::/128'
      }
    ]
  }),

  Full: buildFilterGroup({
    name: 'Fully Classified',
    named: true,
    filters: [
      {
        filterField: 'service_name',
        operator: '<>',
        filterValue: ''
      },
      {
        filterField: 'service_name',
        operator: '<>',
        filterValue: 'unknown'
      },
      {
        filterField: 'service_type',
        operator: '<>',
        filterValue: ''
      },
      {
        filterField: 'service_type',
        operator: '<>',
        filterValue: 'unknown'
      },
      {
        filterField: 'service_provider',
        operator: '<>',
        filterValue: ''
      },
      {
        filterField: 'service_provider',
        operator: '<>',
        filterValue: 'unknown'
      }
    ]
  }),

  'Provider-Only': buildFilterGroup({
    name: 'Provider-Only',
    named: true,
    filters: [
      {
        filterField: 'service_name',
        operator: '=',
        filterValue: 'unknown'
      },
      {
        filterField: 'service_type',
        operator: '=',
        filterValue: 'unknown'
      },
      {
        filterField: 'service_provider',
        operator: '<>',
        filterValue: ''
      },
      {
        filterField: 'service_provider',
        operator: '<>',
        filterValue: 'unknown'
      }
    ]
  }),

  Pending: buildFilterGroup({
    name: 'Pending',
    named: true,
    filters: [
      {
        filterField: 'service_name',
        operator: '<>',
        filterValue: ''
      },
      {
        filterField: 'service_name',
        operator: '<>',
        filterValue: 'unknown'
      },
      {
        filterField: 'service_type',
        operator: '=',
        filterValue: 'unknown'
      },
      {
        filterField: 'service_provider',
        operator: '=',
        filterValue: 'unknown'
      }
    ]
  }),

  Unknown: buildFilterGroup({
    name: 'Unclassified',
    named: true,
    filters: [
      {
        filterField: 'service_name',
        operator: '=',
        filterValue: ''
      },
      {
        filterField: 'service_type',
        operator: '=',
        filterValue: ''
      },
      {
        filterField: 'service_provider',
        operator: '=',
        filterValue: ''
      }
    ]
  })
};

const getBaseQuery = ({ lookback_seconds = 604800, aggregateType = 'max_bits_per_sec' }) => ({
  all_devices: false,
  device_types: ['router', 'kprobe', 'kappa'],
  fastData: 'Auto',
  show_overlay: false,
  show_total_overlay: false,
  aggregateTypes: [aggregateType],
  lookback_seconds,
  from_to_lookback: lookback_seconds,
  topx: 300,
  depth: 300
});

const getBaseAggQuery = ({
  filters = [],
  lookback_seconds,
  aggregateType,
  includeBaseAggFilter = true,
  extraFilterGroups = []
}) => ({
  ...getBaseQuery({ lookback_seconds, aggregateType }),

  filters: {
    connector: 'All',
    filterGroups: [
      getCdnFilterGroup({ moduleName: 'ott' }),
      baseFilters.noDstCdn,
      baseFilters.no128,
      ...extraFilterGroups,
      buildFilterGroup({
        filters: getOutboundFilters(filters).concat(includeBaseAggFilter ? baseFilters.baseAgg : [])
      })
    ]
  }
});

const getBaseServiceTypeQuery = ({ serviceType, filters = [], lookback_seconds, aggregateType }) => ({
  ...getBaseQuery({ lookback_seconds, aggregateType }),

  filters: {
    connector: 'All',
    filterGroups: [
      getCdnFilterGroup({ moduleName: 'ott' }),
      baseFilters.noDstCdn,
      baseFilters.no128,
      buildFilterGroup({
        filters: getOutboundFilters(filters).concat([
          {
            filterField: 'service_type',
            operator: '=',
            filterValue: serviceType
          }
        ])
      })
    ]
  }
});

const getBaseProviderQuery = ({ provider, filters = [], lookback_seconds, aggregateType }) => ({
  ...getBaseQuery({ lookback_seconds, aggregateType }),

  filters: {
    connector: 'All',
    filterGroups: [
      getCdnFilterGroup({ moduleName: 'ott' }),
      baseFilters.noDstCdn,
      baseFilters.no128,
      buildFilterGroup({
        filters: getOutboundFilters(filters).concat([
          {
            filterField: 'service_provider',
            operator: '=',
            filterValue: provider
          }
        ])
      })
    ]
  }
});

const getBaseServiceQuery = ({ service, filters = [], lookback_seconds, aggregateType }) => ({
  ...getBaseQuery({ lookback_seconds, aggregateType }),

  filters: {
    connector: 'All',
    filterGroups: [
      getCdnFilterGroup({ moduleName: 'ott' }),
      baseFilters.noDstCdn,
      baseFilters.no128,
      buildFilterGroup({
        filters: getOutboundFilters(filters).concat([
          {
            filterField: 'service_name',
            operator: '=',
            filterValue: service
          }
        ])
      })
    ]
  }
});

const getLookbackValues = (lookback_seconds) => {
  const lookbackValues = { lookback_seconds, period_over_period: true };

  if (lookback_seconds < 60) {
    lookbackValues.period_over_period_lookback = 1;
    lookbackValues.period_over_period_lookback_unit = 'month';
  } else {
    lookbackValues.period_over_period_lookback = Math.ceil(lookback_seconds / 3600);
    lookbackValues.period_over_period_lookback_unit = 'hour';
  }

  return lookbackValues;
};

export const getCategoryQuery = ({ filters = [], lookback_seconds, aggregateType }) => ({
  ...getBaseAggQuery({ filters: filters.concat(baseFilters.knownCategories), lookback_seconds, aggregateType }),
  ...getLookbackValues(lookback_seconds),
  metric: ['service_type'],
  topx: OTT_LANDING_TOPX,
  depth: OTT_LANDING_TOPX * 6, // to compare rows that fall in and out of topx
  chunkSize: 30 * 24 * 3600
});

// Top providers per category
export const getCategoryProvidersQuery =
  ({ filters = [], lookback_seconds, aggregateType }) =>
  (serviceType) => ({
    ...getBaseServiceTypeQuery({ serviceType, filters, lookback_seconds, aggregateType }),
    metric: ['service_provider'],
    depth: 6
  });

export const getProvidersQuery = ({ filters = [], lookback_seconds, aggregateType }) => ({
  ...getBaseAggQuery({ filters: filters.concat(baseFilters.knownProviders), lookback_seconds, aggregateType }),
  ...getLookbackValues(lookback_seconds),
  metric: ['service_provider'],
  topx: OTT_LANDING_TOPX,
  depth: OTT_LANDING_TOPX * 6, // to compare rows that fall in and out of topx
  chunkSize: 30 * 24 * 3600
});

export const getConnectivityQuery = ({ provider, service, filters = [], lookback_seconds }) => {
  const baseQuery = provider
    ? getBaseProviderQuery({ provider, filters, lookback_seconds })
    : getBaseServiceQuery({ service, filters, lookback_seconds });

  return {
    ...baseQuery,
    metric: ['i_src_connect_type_name'],
    aggregateTypes: ['max_bits_per_sec'],
    show_total_overlay: true,
    chunkSize: 30 * 24 * 3600
  };
};

// Top services per category
export const getCategoryServicesQuery =
  ({ filters = [], lookback_seconds, aggregateType }) =>
  (serviceType) => ({
    ...getBaseServiceTypeQuery({ serviceType, filters, lookback_seconds, aggregateType }),
    metric: ['service_name'],
    depth: 6
  });

//  Top services per provider
export const getProviderServicesQuery =
  ({ filters = [], lookback_seconds, aggregateType }) =>
  (provider) => ({
    ...getBaseProviderQuery({ provider, filters, lookback_seconds, aggregateType }),
    metric: ['service_name'],
    depth: 6
  });

export const getServicesQuery = ({ filters = [], lookback_seconds, aggregateType, classificationTypes }) => ({
  ...getBaseAggQuery({
    filters,
    lookback_seconds,
    aggregateType,
    includeBaseAggFilter: classificationTypes.length === 0,
    extraFilterGroups: [
      buildFilterGroup({
        name: 'Classifications',
        named: true,
        connector: 'Any',
        filterGroups: classificationTypes.map((type) => baseFilters[type])
      })
    ]
  }),

  metric: ['service_name', 'service_type', 'service_provider'],
  aggregateTypes: ['max_bits_per_sec', 'max_dst_ip', 'max_bytes_per_dst_ip'],
  outsort: 'max_bits_per_sec',
  topx: 20,
  depth: 20,
  chunkSize: 30 * 24 * 3600
});

export const getTopServicesQuery = ({ filters = [], lookback_seconds, aggregateType, ...rest }) => ({
  ...getBaseAggQuery({ filters, lookback_seconds, aggregateType }),
  metric: ['service_name'],
  show_total_overlay: true,
  viz_type: 'table',
  ...rest
});

export const getServiceRankingsByTypeQuery = ({
  serviceType,
  filters = [],
  lookback_seconds,
  aggregateType,
  ...rest
}) => ({
  ...getBaseServiceTypeQuery({ serviceType, filters, lookback_seconds, aggregateType }),
  metric: ['service_name'],
  show_total_overlay: true,
  viz_type: 'table',
  topx: 0,
  depth: 8,
  chunkSize: 30 * 24 * 3600,
  ...rest
});

export const getTopServicesByProviderQuery = ({
  provider,
  filters = [],
  lookback_seconds,
  aggregateType,
  ...rest
}) => ({
  ...getBaseProviderQuery({ provider, filters, lookback_seconds, aggregateType }),
  metric: ['service_name'],
  show_total_overlay: true,
  viz_type: 'table',
  topx: 0,
  depth: 8,
  chunkSize: 30 * 24 * 3600,
  ...rest
});

export const getServiceTypeTrafficQuery = ({
  serviceType,
  provider,
  filters = [],
  lookback_seconds,
  aggregateType
}) => {
  const baseQuery = provider
    ? getBaseProviderQuery({ provider, filters, lookback_seconds, aggregateType })
    : getBaseServiceTypeQuery({ serviceType, filters, lookback_seconds, aggregateType });

  return {
    ...baseQuery,
    show_total_overlay: false,
    metric: ['service_name', 'service_provider', 'service_type', 'i_src_connect_type_name'],
    outsort: 'max_bits_per_sec'
  };
};

export const getProviderToSiteQuery = ({ provider, filters = [], lookback_seconds, aggregateType }) => ({
  ...getBaseProviderQuery({ provider, filters, lookback_seconds, aggregateType }),
  metric: [
    'service_name',
    'i_device_site_name',
    'i_src_connect_type_name',
    'i_src_provider_classification',
    'src_cdn',
    'AS_src'
  ],
  show_total_overlay: false,
  viz_type: 'sankey',
  depth: 20
});

export const getServiceBreakdownQuery = ({
  service,
  metric = 'i_device_site_name',
  filters = [],
  lookback_seconds,
  aggregateType
}) => ({
  ...getBaseServiceQuery({ service, filters, lookback_seconds, aggregateType }),
  viz_type: 'stackedArea',
  metric: [metric],
  show_total_overlay: true,
  depth: 20
});

export const getServiceToSiteQuery = ({ service, filters = [], lookback_seconds, aggregateType }) => ({
  ...getBaseServiceQuery({ service, filters, lookback_seconds, aggregateType }),
  metric: ['i_device_site_name', 'i_src_connect_type_name', 'i_src_provider_classification', 'src_cdn', 'AS_src'],
  show_total_overlay: false,
  viz_type: 'sankey',
  depth: 20
});

export const getTopOtherServices = ({
  service,
  filterField,
  filterValue,
  filters = [],
  lookback_seconds,
  aggregateType
}) => {
  const extraOttFilters = [
    {
      filterField: 'service_name',
      operator: '<>',
      filterValue: ''
    },
    {
      filterField: 'service_name',
      operator: '<>',
      filterValue: 'unknown'
    }
  ];

  if (service) {
    extraOttFilters.push({
      filterField: 'service_name',
      operator: '<>',
      filterValue: service
    });
  }

  if (filterField && filterValue) {
    extraOttFilters.push({
      filterField,
      operator: '=',
      filterValue
    });
  }

  return {
    ...getBaseAggQuery({ filters, lookback_seconds, aggregateType }),
    metric: ['service_name'],
    topx: 5,
    depth: 5,
    filters: {
      connector: 'All',
      filterGroups: [
        getCdnFilterGroup({ moduleName: 'ott' }),
        buildFilterGroup({
          filters: getOutboundFilters(filters).concat(extraOttFilters)
        })
      ]
    }
  };
};

export const getClassificationQuery = ({ filters = [], lookback_seconds }) => ({
  ...getBaseQuery({ lookback_seconds }),

  metric: ['src_cdn', 'service_type', 'service_provider', 'i_src_connect_type_name', 'i_device_site_name'],
  aggregateTypes: ['max_bits_per_sec'],
  show_total_overlay: true,
  topx: 100,
  chunkSize: 7 * 24 * 3600,

  filters: {
    connector: 'All',
    filterGroups: [
      getCdnFilterGroup({ moduleName: 'ott' }),
      baseFilters.noDstCdn,
      baseFilters.no128,
      buildFilterGroup({
        filters: getOutboundFilters(filters)
      })
    ]
  },

  filterDimensionsEnabled: true,
  filterDimensionName: 'Classifications',
  filterDimensionOther: false,
  filterDimensionSort: false,
  filterDimensions: {
    connector: 'All',
    filterGroups: [baseFilters.Full, baseFilters['Provider-Only'], baseFilters.Pending, baseFilters.Unknown]
  }
});

export const getTopSubscribershipQuery = ({ serviceType, filters = [], lookback_seconds }) => ({
  ...getBaseAggQuery({
    filters: filters.concat([
      {
        filterField: 'service_type',
        operator: '=',
        filterValue: serviceType
      }
    ]),
    lookback_seconds
  }),
  viz_type: 'table',
  metric: ['service_name'],
  aggregateTypes: ['p95th_dst_ip'],
  outsort: 'p95th_dst_ip',
  topx: 100,
  depth: 100,
  fastData: 'Full'
});

export const getSubscribershipQuery = ({
  topService,
  otherService,
  service,
  serviceType,
  filters = [],
  lookback_seconds
}) => {
  let pollingInterval;

  const filterGroups = [
    buildFilterGroup({
      name: `${serviceType} Leader: ${topService}`,
      named: true,
      filters: [
        {
          filterField: 'service_name',
          operator: '=',
          filterValue: topService
        }
      ]
    }),
    buildFilterGroup({
      name: `Total ${serviceType}`,
      named: true,
      filters: [
        {
          filterField: 'service_type',
          operator: '=',
          filterValue: serviceType
        }
      ]
    })
  ];

  if (otherService) {
    filterGroups.push(
      buildFilterGroup({
        name: `${topService === service ? `${serviceType} #2` : 'Current'}: ${otherService}`,
        named: true,
        filters: [
          {
            filterField: 'service_name',
            operator: '=',
            filterValue: otherService
          }
        ]
      })
    );
  }

  switch (lookback_seconds) {
    case 3600: // 1 Hour - 5 minute interval
      pollingInterval = 5;
      break;
    case 172800: // 2 Day - 2 hour interval
      pollingInterval = 2;
      break;
    case 259200: // 3 Day - 3 hour interval
      pollingInterval = 3;
      break;
    case 604800: // >= 1 Week - 12hr interval
    case 1209600:
    case 2592000:
    case 1:
    case 2:
      pollingInterval = 12;
      break;
    default:
      pollingInterval = undefined;
  }

  return {
    ...getBaseAggQuery({ filters, lookback_seconds }),
    fastData: 'Full', // Needs to be Full. Fast downsamples the number of unique IPs
    viz_type: 'stackedBar',
    metric: ['service_name'],
    aggregateTypes: ['p95th_dst_ip'],
    topx: 3,
    depth: 3,
    minsPolling: pollingInterval,
    filterDimensionsEnabled: true,
    filterDimensionName: 'Subscribership',
    filterDimensionOther: false,
    filterDimensionSort: false,
    filterDimensions: {
      connector: 'All',
      filterGroups
    }
  };
};

export const getPerformanceQuery = ({ service, metric, filters = [], lookback_seconds, aggregateType }) => ({
  ...getBaseAggQuery({
    filters: filters.concat({
      filterField: 'service_name',
      operator: '=',
      filterValue: service
    }),
    lookback_seconds,
    aggregateType
  }),

  fastData: 'Full', // Needs to be Full. Fast downsamples the number of unique IPs
  topx: 20,
  depth: 350,
  viz_type: 'line',
  metric,

  aggregateTypes: [aggregateType]
});

export const getInterfaceTrafficQuery = ({
  lookback_seconds,
  snmp_id,
  device_name,
  aggregateType = 'max_ktappprotocol__snmp__INT64_00',
  viz_type = 'line'
}) => ({
  ...getBaseQuery({
    lookback_seconds,
    aggregateType
  }),
  filters: {
    connector: 'All',
    filterGroups: [
      buildFilterGroup({
        filters: [
          {
            filterField: 'ktappprotocol__snmp__i_device_name',
            operator: '=',
            filterValue: device_name
          },
          {
            filterField: 'ktappprotocol__snmp__output_port',
            operator: '=',
            filterValue: snmp_id
          }
        ]
      })
    ]
  },
  fastData: 'Full',
  topx: 1,
  depth: 1,
  viz_type,
  metric: ['ktappprotocol__snmp__output_port']
});

export const getServiceInterfaceQuery = ({ lookback_seconds, service, snmp_id, device_name, aggregateType }) => {
  const filters = [
    {
      filterField: 'i_device_name',
      operator: '=',
      filterValue: device_name
    },
    {
      filterField: 'input_port',
      operator: '=',
      filterValue: snmp_id
    },
    {
      filterField: 'service_name',
      operator: '=',
      filterValue: service
    }
  ];

  return {
    ...getBaseAggQuery({ lookback_seconds }),
    fastData: 'Fast',
    topx: 1,
    depth: 1,
    viz_type: 'line',
    metric: ['InterfaceID_src'],
    aggregateTypes: [aggregateType],
    filterDimensionsEnabled: true,
    filterDimensions: {
      connector: 'All',
      filterGroups: [
        buildFilterGroup({
          name: `${device_name} (${snmp_id})`,
          named: true,
          filters
        })
      ]
    }
  };
};
