import Collection from 'core/model/Collection';

import RecentView from './RecentView';

class RecentViewsCollection extends Collection {
  recentViewsMap = {};

  constructor(wholeCompany) {
    super();
    this.wholeCompany = wholeCompany;
  }

  get url() {
    return `/api/ui/recent-view${this.wholeCompany ? '?whole_company=true' : ''}`;
  }

  get model() {
    return RecentView;
  }

  fetch(options) {
    return super.fetch(options).then(() => {
      this.recentViewsMap = this.models.reduce((map, recentView) => {
        map[`${recentView.get('view_type')}-${recentView.get('view_id')}`] = recentView;
        return map;
      }, {});
    });
  }
}

export default RecentViewsCollection;
