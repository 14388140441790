import Model from 'core/model/Model';
import { buildFilterGroup } from 'core/util/filters';
import { computed } from 'mobx';
import { isIpV4Valid, isIpV6Valid } from 'core/util/ip';

class MetricBGPNeighborModel extends Model {
  get urlRoot() {
    return '/api/ui/recon/bgpneighbor';
  }

  get defaults() {
    return {
      index: '',
      enabled: '',
      'local-as': '',
      'neighbor-address': '',
      'peer-as': '',
      'prefix-table-index': '',
      'network-instance': '',
      'timers/hold-time': '',
      'timers/keepalive-interval': '',
      'timers/connect-retry': '',
      'transport/local-address': '',
      'transport/local-port': '',
      'transport/remote-address': '',
      'transport/remote-port': '',
      'session-state': undefined
    };
  }

  get title() {
    return this.get('peer-as');
  }

  get subType() {
    return 'BGP Neighbor';
  }

  get deviceName() {
    return this.get('device').get('name');
  }

  get deviceId() {
    return this.get('device').get('id');
  }

  get neighborIp() {
    const neighborIp = this.get('transport/remote-address');
    return !neighborIp || neighborIp === '0.0.0.0' ? '--' : neighborIp;
  }

  get hasState() {
    return this.get('transport/remote-address') && this.get('peer-as');
  }

  get hasPrefixTableIndex() {
    return this.get('prefix-table-index');
  }

  get prefixTableFilter() {
    // either filter on prefix-table-index or index
    const filters = [
      {
        filterField: 'km_device_id',
        operator: '=',
        filterValue: `${this.deviceId}`
      }
    ];

    if (this.hasPrefixTableIndex) {
      filters.push({
        filterField: 'neighbor-table-index',
        operator: '=',
        filterValue: this.get('prefix-table-index')
      });
    } else {
      filters.push({
        filterField: 'index',
        operator: 'ILIKE',
        filterValue: this.get('index')
      });
    }

    return filters;
  }

  get stateFilter() {
    return [
      {
        filterField: 'km_device_id',
        operator: '=',
        filterValue: `${this.deviceId}`
      },
      {
        filterField: 'transport/remote-address',
        operator: '=',
        filterValue: this.get('transport/remote-address')
      },
      {
        filterField: 'peer-as',
        operator: '=',
        filterValue: this.get('peer-as')
      }
    ];
  }

  @computed
  get sessionState() {
    const value = this.get('session-state');
    const map = {
      1: 'Idle',
      2: 'Connect',
      3: 'Active',
      4: 'Open Sent',
      5: 'Open Confirm',
      6: 'Established'
    };

    return map[value] || 'Unknown';
  }

  @computed
  get sessionType() {
    const remoteAs = this.get('peer-as');
    const localAs = this.get('local-as');

    if (remoteAs === localAs) {
      return 'iBGP';
    }
    return 'eBGP';
  }

  @computed
  get sessionInetFamily() {
    const localIp = this.get('transport/local-address');
    const remoteIIp = this.get('transport/remote-address');

    if (isIpV4Valid(localIp) && isIpV4Valid(remoteIIp)) {
      return 'V4';
    }
    if (isIpV6Valid(localIp) && isIpV6Valid(remoteIIp)) {
      return 'V6';
    }
    return '';
  }

  get stateQuery() {
    return {
      use_kmetrics: true,
      show_overlay: false,
      show_total_overlay: false,
      kmetrics: {
        measurement: '/protocols/bgp/neighbors',
        dimensions: ['peer-as', 'transport/remote-address'],
        metrics: [
          {
            name: 'session-state',
            type: 'gauge'
          }
        ],
        range: {
          lookback: 'PT86400S'
        },
        window: {
          size: 0,
          fn: {
            'session-state': 'last'
          }
        },
        rollups: {
          'last_session-state': {
            metric: 'session-state',
            aggregate: 'last'
          }
        },
        sort: [
          {
            name: 'last_session-state',
            direction: 'desc'
          }
        ],
        limit: 100,
        includeTimeseries: 100,
        viz: {
          type: 'area'
        },
        filters: {
          connector: 'All',
          filterGroups: [
            buildFilterGroup({
              filters: this.stateFilter
            })
          ]
        }
      }
    };
  }

  get prefixesReceivedQuery() {
    return {
      use_kmetrics: true,
      show_overlay: false,
      show_total_overlay: false,
      kmetrics: {
        measurement: '/protocols/bgp/neighbors/prefixes',
        dimensions: ['index'],
        metrics: [
          {
            name: 'received',
            type: 'gauge'
          }
        ],
        range: {
          lookback: 'PT86400S'
        },
        window: {
          size: 0,
          fn: {
            received: 'last'
          }
        },
        rollups: {
          last_received: {
            metric: 'received',
            aggregate: 'last'
          }
        },
        sort: [
          {
            name: 'last_received',
            direction: 'desc'
          }
        ],
        limit: 100,
        includeTimeseries: 100,
        viz: {
          type: 'area'
        },
        filters: {
          connector: 'All',
          filterGroups: [
            buildFilterGroup({
              filters: this.prefixTableFilter
            })
          ]
        }
      }
    };
  }

  get prefixesSentQuery() {
    return {
      use_kmetrics: true,
      show_overlay: false,
      show_total_overlay: false,
      kmetrics: {
        measurement: '/protocols/bgp/neighbors/prefixes',
        dimensions: ['index'],
        metrics: [
          {
            name: 'sent',
            type: 'gauge'
          }
        ],
        range: {
          lookback: 'PT86400S'
        },
        window: {
          size: 0,
          fn: {
            sent: 'last'
          }
        },
        rollups: {
          last_sent: {
            metric: 'sent',
            aggregate: 'last'
          }
        },
        sort: [
          {
            name: 'last_sent',
            direction: 'desc'
          }
        ],
        limit: 100,
        includeTimeseries: 100,
        viz: {
          type: 'area'
        },
        filters: {
          connector: 'All',
          filterGroups: [
            buildFilterGroup({
              filters: this.prefixTableFilter
            })
          ]
        }
      }
    };
  }

  get messagesInQuery() {
    return {
      use_kmetrics: true,
      show_overlay: false,
      show_total_overlay: false,
      kmetrics: {
        measurement: '/protocols/bgp/neighbors',
        dimensions: ['peer-as', 'transport/remote-address'],
        metrics: [
          {
            name: 'messages/received/TOTAL',
            type: 'counter'
          }
        ],
        range: {
          lookback: 'PT86400S'
        },
        window: {
          size: 0,
          fn: {
            'messages/received/TOTAL': 'max'
          }
        },
        rollups: {
          'max_messages/received/TOTAL': {
            metric: 'messages/received/TOTAL',
            aggregate: 'max'
          }
        },
        sort: [
          {
            name: 'max_messages/received/TOTAL',
            direction: 'desc'
          }
        ],
        limit: 100,
        includeTimeseries: 100,
        viz: {
          type: 'area'
        },
        filters: {
          connector: 'All',
          filterGroups: [
            buildFilterGroup({
              filters: this.stateFilter
            })
          ]
        }
      }
    };
  }

  get messagesOutQuery() {
    return {
      use_kmetrics: true,
      show_overlay: false,
      show_total_overlay: false,
      kmetrics: {
        measurement: '/protocols/bgp/neighbors',
        dimensions: ['peer-as', 'transport/remote-address'],
        metrics: [
          {
            name: 'messages/sent/TOTAL',
            type: 'counter'
          }
        ],
        range: {
          lookback: 'PT86400S'
        },
        window: {
          size: 0,
          fn: {
            'messages/sent/TOTAL': 'max'
          }
        },
        rollups: {
          'max_messages/sent/TOTAL': {
            metric: 'messages/sent/TOTAL',
            aggregate: 'max'
          }
        },
        sort: [
          {
            name: 'max_messages/sent/TOTAL',
            direction: 'desc'
          }
        ],
        limit: 100,
        includeTimeseries: 100,
        viz: {
          type: 'area'
        },
        filters: {
          connector: 'All',
          filterGroups: [
            buildFilterGroup({
              filters: this.stateFilter
            })
          ]
        }
      }
    };
  }
}

export default MetricBGPNeighborModel;
