import Collection from 'core/model/Collection';
import { action, observable } from 'mobx';
import MetricBGPNeighborModel from './MetricBGPNeighborModel';

class MetricBGPNeighborCollection extends Collection {
  get defaultSortState() {
    return {
      field: 'name',
      direction: 'asc'
    };
  }

  get url() {
    return '';
  }

  get model() {
    return MetricBGPNeighborModel;
  }

  @observable
  isFetchingPrefixCounts = false;

  @action
  setFetchingPrefixCounts = (fetching) => {
    this.isFetchingPrefixCounts = fetching;
  };
}

export default MetricBGPNeighborCollection;
