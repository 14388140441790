import { action, observable } from 'mobx';
import Collection from 'core/model/Collection';
import api from 'core/util/api';
import KentikAgentModel from './KentikAgentModel';

export default class KentikAgentCollection extends Collection {
  urlParams = {
    unregistered: true,
    registered: true,
    capabilityKey: null
  };

  @observable.ref
  memoryUtilQueryData = [];

  @observable.ref
  cpuUtilQueryData = [];

  constructor({ unregistered = true, registered = true, capabilityKey } = {}) {
    super();

    this.urlParams = {
      unregistered,
      registered
    };
    if (capabilityKey) {
      this.urlParams.capabilityKey = capabilityKey;
    }
  }

  get defaultGroupBy() {
    return 'groupRank';
  }

  get url() {
    // capabilityKey is assumed to be a single value for now
    const { unregistered, registered, capabilityKey } = this.urlParams;

    const baseUrl = `/api/ui/kentik-agent/agents?unregistered=${unregistered}&registered=${registered}`;
    if (capabilityKey) {
      return `${baseUrl}&capabilityKey=${capabilityKey}`;
    }
    return baseUrl;
  }

  get defaultSortState() {
    return {
      field: 'groupRank',
      direction: 'asc'
    };
  }

  get secondarySort() {
    return {
      field: 'status',
      direction: 'desc'
    };
  }

  get model() {
    return KentikAgentModel;
  }

  @action
  fetchNew = () => {
    this.setRequestStatus('fetchingMore');
    return api.get(this.url).then(
      action((results) => {
        results.forEach((result) => {
          const match = this.findByInstallId(result.install_id);
          if (!match) {
            this.add(result);
          }
        });
        this.setLastFetched();
        this.setRequestStatus(null);
        return results;
      })
    );
  };

  findByInstallId(agentId) {
    return this.get().find((item) => item.get('install_id') === agentId);
  }

  findByAgentId(agentId) {
    return this.get().find((item) => item.get('agent_id') === agentId);
  }

  setMemoryUtilQueryData = () => {
    this.memoryUtilQueryData.forEach((result) => {
      const device = this.get(result.agent_id);
      if (device) {
        device.set('memory_util', result.last_kagent_memory_utilization);
      }
    });
  };

  setCpuUtilQueryData = () => {
    this.cpuUtilQueryData.forEach((result) => {
      const device = this.get(result.agent_id);
      if (device) {
        device.set('cpu_util', result.last_kagent_cpu_utilization);
      }
    });
  };
}
