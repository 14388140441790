import { action, computed } from 'mobx';
import { orderBy } from 'lodash';
import moment from 'moment';

import { AGENT_TYPES, AGENT_CAPABILITIES, RECENTLY_ADDED_DAYS } from 'shared/synthetics/constants';

import Collection from 'core/model/Collection';
import AgentModel from './AgentModel';
import { getOptionsForAgents } from './utils';

class AgentCollection extends Collection {
  lastAgentFetch;

  agentCacheDurationMs = 300000; // 5 min

  agentCachePromise;

  get model() {
    return AgentModel;
  }

  get url() {
    return '/api/ui/synthetics/agents/stats';
  }

  get defaultSortState() {
    return { field: 'agent_alias', direction: 'asc' };
  }

  @computed
  get recentlyAddedAgents() {
    const currentMoment = moment();
    const models = this.privateAgents.filter(
      (model) => currentMoment.diff(moment(model.get('edate')), 'days') <= RECENTLY_ADDED_DAYS
    );

    return orderBy(models, ['edate'], ['desc']);
  }

  @action
  async syncAgentHealth() {
    const { $syn } = this.store;
    // just return promise if sync already in progress.
    if (this.agentHealthSyncPromise) {
      return this.agentHealthSyncPromise;
    }

    // TODO: deprecate browserAgents #7660
    const data = { browserIds: [], privateIds: [], globalIds: [] };
    this.privateAgents.each((agent) => data.privateIds.push(agent.id));
    this.globalAgents.each((agent) => data.globalIds.push(agent.id));

    // Put browser ids in with globals batch for now so they get processed on server, results not sorted by type.
    this.browserAgents.each((agent) => data.globalIds.push(agent.id));
    this.pendingAgents.each((agent) => data[`${agent.get('agent_type')}Ids`].push(agent.id));

    return $syn.requests.fetchAgentHealth(data).then(
      action('updateAgentHealth', (agentHealthMap) => {
        const allAgentIds = [...data.privateIds, ...data.globalIds, ...data.browserIds];
        allAgentIds.forEach((agentId) => {
          const agent = this.getAgent(agentId);
          if (agent) {
            const updatedHealth = agentHealthMap[agentId] || { last_seen: null, health: null };
            agent.set(updatedHealth);
            // check if broadband or cloud agent and update in that collection too.
            ['broadband', 'cloud_provider'].forEach((key) => {
              if (agent.get(`metadata.${key}`)) {
                const model = this.get(agentId);
                if (model) {
                  model.set(updatedHealth);
                }
              }
            });
          }
        });
        this.agentHealthSyncPromise = null;
        this.lastUpdated = Date.now();
      })
    );
  }

  @action
  async findAgentByChallenge(challenge, metadata) {
    const { $syn } = this.store;
    return $syn.requests.fetchAgentByChallenge({ challenge, metadata }).then((res) => this.add(res)[0]);
  }

  @computed
  get agentOptions() {
    return this.privateAgentOptions.concat(this.globalAgentOptions);
  }

  /*
  A mapping of functions used as callbacks to the filters that drive the agent type @computeds below
  For example:
    - this.privateAgents is the unfiltered list of agents of type private
    - this.privateAgentsFiltered is the filtered list of agents of type private
    - both of these @computeds would want to use the same filter callback below but a different getter off the collection
*/
  AGENT_FILTER_CALLBACKS = {
    private: (agent) => agent.get('agent_status') === 'OK' && agent.get('agent_type') === AGENT_TYPES.PRIVATE,
    global: (agent) => agent.get('agent_status') === 'OK' && agent.get('agent_type') === AGENT_TYPES.GLOBAL,
    browser: (agent) =>
      agent.get('agent_status') === 'OK' &&
      agent.get('agent_type') === AGENT_TYPES.GLOBAL &&
      agent.get('metadata')?.capabilities?.includes(AGENT_CAPABILITIES.WEB),
    pending: (agent) =>
      agent.get('agent_status') === 'WAIT' &&
      (this.store.$auth.isSudoer || agent.get('agent_type') === AGENT_TYPES.PRIVATE),
    broadband: (agent) => agent.get('agent_status') === 'OK' && !!agent.get('metadata')?.broadband,
    cloud: (agent) => agent.get('agent_status') === 'OK' && !!agent.get('metadata')?.cloud_provider,
    all: (agent) => agent.get('agent_status') === 'OK'
  };

  @computed
  get privateAgents() {
    return this.get().filter(this.AGENT_FILTER_CALLBACKS.private);
  }

  @computed
  get privateAgentsFiltered() {
    return this.models.filter(this.AGENT_FILTER_CALLBACKS.private);
  }

  @computed
  get globalAgents() {
    return this.get().filter(this.AGENT_FILTER_CALLBACKS.global);
  }

  @computed
  get globalAgentsFiltered() {
    return this.models.filter(this.AGENT_FILTER_CALLBACKS.global);
  }

  @computed
  get browserAgents() {
    return this.get().filter(this.AGENT_FILTER_CALLBACKS.browser);
  }

  @computed
  get browserAgentsFiltered() {
    return this.models.filter(this.AGENT_FILTER_CALLBACKS.browser);
  }

  @computed
  get pendingAgents() {
    return this.get().filter(this.AGENT_FILTER_CALLBACKS.pending);
  }

  @computed
  get pendingAgentsFiltered() {
    return this.models.filter(this.AGENT_FILTER_CALLBACKS.pending);
  }

  @computed
  get broadbandAgents() {
    return this.get().filter(this.AGENT_FILTER_CALLBACKS.broadband);
  }

  @computed
  get broadbandAgentsFiltered() {
    return this.models.filter(this.AGENT_FILTER_CALLBACKS.broadband);
  }

  @computed
  get cloudAgents() {
    return this.get().filter(this.AGENT_FILTER_CALLBACKS.cloud);
  }

  @computed
  get cloudAgentsFiltered() {
    return this.models.filter(this.AGENT_FILTER_CALLBACKS.cloud);
  }

  @computed
  get pendingAgentOptions() {
    return this.getOptionsForAgents(this.pendingAgents);
  }

  @computed
  get privateAgentOptions() {
    return this.getOptionsForAgents(this.privateAgents);
  }

  @computed
  get globalAgentOptions() {
    const rustGlobalAgents = this.getOptionsForAgents(this.globalAgents, { showAsNameInLabel: true });
    return rustGlobalAgents.concat(this.getOptionsForAgents(this.browserAgents, { showAsNameInLabel: true }));
  }

  @computed
  get browserAgentOptions() {
    const privateBrowserAgents = this.privateAgentOptions.filter((a) =>
      a.capabilities?.includes(AGENT_CAPABILITIES.WEB)
    );
    return this.getOptionsForAgents(this.browserAgents).concat(privateBrowserAgents);
  }

  @computed
  get allAgents() {
    return this.get().filter(this.AGENT_FILTER_CALLBACKS.all);
  }

  @computed
  get allAgentsOptions() {
    return this.getOptionsForAgents(this.get());
  }

  getOptionsForAgents(agents) {
    return getOptionsForAgents(agents);
  }

  getOptionsForAgentsByIds(ids) {
    // get all the agents by id, filtering out agents that may no longer exist
    const agents = ids.map((id) => this.get(id)).filter((agent) => !!agent);
    return this.getOptionsForAgents(agents);
  }

  getAgentsBySiteId(siteId) {
    return this.privateAgents.filter((agent) => parseInt(agent.get('site_id')) === parseInt(siteId));
  }

  getAgentsByID(ids = []) {
    return ids.map((id) => this.get(id)).filter((agent) => !!agent);
  }

  getTargetAgentsByIps(ips = []) {
    if (!Array.isArray(ips)) {
      ips = [ips];
    }

    return this.get().filter((model) => model.ips.find((ip) => ips.includes(ip)));
  }
}

export default AgentCollection;
