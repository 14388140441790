import Collection from 'core/model/Collection';
import { computed } from 'mobx';

import ProviderModel from './ProviderModel';

class ProviderCollection extends Collection {
  get model() {
    return ProviderModel;
  }

  @computed
  get costGroupsByIdMap() {
    const costGroupByIdMap = {};
    this.models.forEach((provider) => {
      if (provider.costGroups) {
        provider.costGroups.models.forEach((costGroup) => {
          costGroupByIdMap[costGroup.get('id')] = costGroup.toJS();
        });
      }
    });
    return costGroupByIdMap;
  }

  get url() {
    return '/api/ui/cost/provider';
  }

  get defaultSortState() {
    return {
      field: 'name',
      direction: 'asc'
    };
  }
}

export default ProviderCollection;
