import { computed } from 'mobx';
import { POLICY_APPLICATION_LABELS, POLICY_APPLICATIONS, SEVERITY_MAP } from '@kentik/ui-shared/alerting/constants';
import { FILTER_CONNECTORS } from '@kentik/ui-shared/filters/constants';
import { getPolicyManageUrl } from 'app/stores/alerting/policyUtils';
import api from 'core/util/api';

import $notifications from 'app/stores/notifications/$notifications';
import { Model } from 'core/model';
import { NMS_POLICY_CONDITION_CONNECTORS } from '@kentik/ui-shared/nms/policies';
import { getPolicyMetricsFromActivateLevels } from 'app/util/nms/policies';
import { TARGET_TYPES } from 'app/forms/config/nms/constants';
import { serialize, deserialize } from './nmsSerializations';

class NmsPolicyModel extends Model {
  constructor(attributes, options = {}) {
    super(attributes);

    if (!attributes || Object.keys(attributes).length === 0) {
      // If this is a new policy from scratch, we'll provide some basic defaults
      attributes = {
        enabled: true,
        scope: {
          connector: FILTER_CONNECTORS.ALL,
          filterGroups: []
        },
        target: {
          measurements: [],
          entity_type: ''
        },
        levels: [
          {
            severity: SEVERITY_MAP.MAJOR.ALERT_MANAGER,
            activate: {
              connector: NMS_POLICY_CONDITION_CONNECTORS.ALL,
              condition_groups: [
                {
                  conditionGroupFields: {
                    connector: NMS_POLICY_CONDITION_CONNECTORS.ALL,
                    not: false,
                    conditions: [],
                    condition_groups: []
                  }
                }
              ]
            }
          }
        ]
      };

      this.set(this.deserialize(attributes));

      return;
    }

    if (options.deserialize) {
      this.set(this.deserialize(attributes));
    } else {
      this.set(attributes);
    }
  }

  isNmsPolicy = true;

  get urlRoot() {
    return '/api/ui/alerting/policies/nms';
  }

  get applicationLabel() {
    // fake out the application label, bundle it with kmetrics
    return POLICY_APPLICATION_LABELS.kmetrics;
  }

  // NMS policies do not have label support (yet)
  get labels() {
    return [];
  }

  // TODO waaaay in the future, once we phase out the old nms policies
  // and we only have application === 'nms' left, this should return true
  // since those will be the only real metric policies left
  get isMetricPolicy() {
    return false;
  }

  get sortValues() {
    return {
      name: () => this.get('name').trim().toLowerCase()
    };
  }

  @computed
  get manageUrl() {
    const policyId = this.isNew ? '' : this.id;
    return getPolicyManageUrl(this.application, policyId);
  }

  @computed
  get cloneUrl() {
    return getPolicyManageUrl(this.application);
  }

  get omitDuringSerialize() {
    return ['policy_created_at', 'version_created_at', 'rule', 'ruleId'];
  }

  get ruleId() {
    return this.get('rule_id');
  }

  get enabled() {
    return this.get('enabled');
  }

  get status() {
    return this.enabled ? 'active' : 'disabled';
  }

  get policyName() {
    return this.get('name');
  }

  get description() {
    return this.get('description');
  }

  get policyID() {
    return this.get('id');
  }

  get target() {
    return this.get('target');
  }

  get levels() {
    return this.get('levels');
  }

  get versionCreatedAt() {
    return this.get('version_created_at');
  }

  // NMS policies do not have mitigations yet and most likely ever
  get mitigations() {
    return [];
  }

  get entityType() {
    return this.target?.entity_type;
  }

  get targetType() {
    const isCustomEntity = Boolean(this.get('target.custom'));
    if (this.entityType) {
      return this.entityType;
    }
    if (isCustomEntity) {
      return TARGET_TYPES.CUSTOM;
    }
    return undefined;
  }

  get dimensions() {
    return this.target?.custom?.dimensions || [];
  }

  get targetMeasurements() {
    return this.target?.measurements || [];
  }

  get dataSources() {
    return this.targetMeasurements;
  }

  get application() {
    return this.get('application') || POLICY_APPLICATIONS.NMS;
  }

  get metrics() {
    return getPolicyMetricsFromActivateLevels(this.get('levels'));
  }

  addAdditionalNotificationsToPolicy(channelIds = []) {
    this.set(
      'levels',
      this.get('levels').map((level) => {
        if (!level.uiProperties.enabled) {
          return level;
        }

        return {
          ...level,
          notificationChannels: [...new Set([...level.notificationChannels, ...channelIds])]
        };
      })
    );
  }

  serialize = (data) => {
    const policy = serialize(data || this.get());

    return super.serialize(policy);
  };

  deserialize = (data) => deserialize(data);

  enable() {
    this.set('enabled', true);

    return api.post(`${this.urlRoot}/${this.id}/enable`);
  }

  disable() {
    this.set('enabled', false);

    return api.post(`${this.urlRoot}/${this.id}/disable`);
  }

  async save(...args) {
    let hasNotificationsAttached = false;

    if (args[0]) {
      const saveData = args[0];
      hasNotificationsAttached = saveData.levels?.find((l) => l.notificationChannels?.length);
    }

    if (hasNotificationsAttached) {
      return super
        .save(...args)
        .then(() => $notifications.collection.fetch({ force: true }))
        .then(() => {
          this.set('levels', $notifications.addNotificationsToNmsPolicyLevels(this.get('levels'), this.get()));
        });
    }

    return super.save(...args);
  }

  clonePolicy(overrides = {}) {
    const clonedPolicy = this.duplicate({ save: false, deserialize: false, removeId: true });

    clonedPolicy.set({
      name: `${clonedPolicy.get('name')} (Clone)`,
      rule_id: undefined,
      rule: undefined,
      version: undefined,
      version_created_at: undefined,
      policy_created_at: undefined,
      ...overrides
    });

    return clonedPolicy;
  }

  fetch(...args) {
    // fetch notification channels first to prevent a race condition where the notification channels
    // are not available during deserialization of the policy collection
    return $notifications.collection.fetch().then(() => super.fetch(...args));
  }

  fetchActiveAlerts(options) {
    return api.post('/api/ui/alertingManager/alarms', {
      data: {
        filter: {
          // technically, empty application array would work too, but this is lighter on db query and faster
          applications: [POLICY_APPLICATIONS.NMS],
          ruleIds: [this.ruleId],
          ackStates: [],
          states: ['ALARM_STATE_ACTIVE'],
          severities: [],
          active: {
            start: null,
            end: null
          },
          includeArchived: false
        },
        pagination: {
          offset: 0,
          limit: options?.pagination?.limit || 1000
        }
      }
    });
  }

  getActiveAlertCount() {
    return this.fetchActiveAlerts({ pagination: { limit: 1 } }).then(
      (data) => parseInt(data?.pagination?.total_count) || 0
    );
  }
}

export default NmsPolicyModel;
