import PagedCollection from 'core/model/PagedCollection';
import TagModel from './TagModel';

class PagedTagCollection extends PagedCollection {
  get url() {
    return '/api/ui/flow-tags';
  }

  get urlPaths() {
    return {
      pagedFetch: '/api/ui/flow-tags/search'
    };
  }

  get queuedFetchKey() {
    return 'pagedTagCollection';
  }

  get jsonRoot() {
    return 'tags';
  }

  get model() {
    return TagModel;
  }

  get defaultSortState() {
    return {
      field: 'value',
      direction: 'desc'
    };
  }

  get filterFieldWhitelist() {
    return new Set(['flow_tag', 'id', 'edited_by']);
  }
}

export default PagedTagCollection;
