import { action } from 'mobx';
import { get } from 'lodash';

import api from 'core/util/api';
import $moduleConfig from 'app/stores/moduleConfig/$moduleConfig';

import Collection from 'core/model/Collection';
import CapacityPlanModel from './CapacityPlanModel';

class CapacityPlanCollection extends Collection {
  constructor(data = [], options = {}) {
    super(data, options);
    this.fetchInterfaces = options.fetchInterfaces;
  }

  get defaultSortState() {
    return {
      field: 'highestUtilization',
      direction: 'desc'
    };
  }

  get moduleName() {
    return 'capacity';
  }

  get collectionProperty() {
    return 'capacityPlans';
  }

  get model() {
    return CapacityPlanModel;
  }

  get url() {
    return '/api/ui/capacity';
  }

  fetchModuleConfig() {
    if (!this.hasFetched) {
      return $moduleConfig.fetchModuleConfig(this.moduleName, { force: true }).then((settings) => {
        const models = get(settings, this.collectionProperty, []);
        this.processData(models);
      });
    }
    return Promise.resolve(this);
  }

  @action
  async fetch(options = {}) {
    const { preserveSelection } = options;
    const label = 'fetching';

    if (
      !options.force &&
      ((this.requestStatus === label && this.fetchPromise) || this.lastFetched > Date.now() - this.minFetchInterval)
    ) {
      return this.fetchPromise;
    }

    this.lastFetched = Date.now();
    this.requestStatus = label;
    this.hasFetched = true;

    if (!preserveSelection) {
      this.clearSelection();
    }

    return api
      .get(`${this.url}/summary`)
      .then((planSummaries) => this.set(planSummaries))
      .catch((error) => {
        this.error = { label, body: error };
        this.models.clear();
        this.fetchPromise = null;
        throw error;
      })
      .finally(() => {
        this.requestStatus = null;
      });
  }
}

export default CapacityPlanCollection;
