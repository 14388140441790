import React from 'react';
import { computed } from 'mobx';

import Flex from 'core/components/Flex';
import KentikLogo from 'app/components/KentikLogo';
import ShareLevelBadge from 'app/components/ShareLevelBadge';
import SavedViewCollection from './SavedViewCollection';

class SavedViews {
  collection = new SavedViewCollection();

  @computed
  get savedViewOptions() {
    return this.getSavedViewOptions(this.collection.get());
  }

  @computed
  get labelOptions() {
    const labelSet = new Set(
      this.store.$labels.getLabels('saved_view').map((label) => {
        const { option } = label;
        if (label.isPreset) {
          option.isPreset = true;
          option.bg = '#e05420';
          option.color = '#ffffff';
        }
        return option;
      })
    );
    labelSet.add({ value: '[NONE]', label: 'None', showColor: false });
    return Array.from(labelSet).sort((a, b) => {
      if (a.value === '[NONE]') {
        return -1;
      }
      return a.label.toLowerCase().localeCompare(b.label.toLowerCase());
    });
  }

  @computed
  get ownerOptions() {
    const owners = new Set(this.collection.unfiltered.map((model) => `${model.get('user_id')}`));
    return this.store.$users.collection.userSelectOptions.filter(({ value }) => owners.has(`${value}`));
  }

  @computed
  get privacyOptions() {
    return [
      {
        description: 'Saved views shared with other users',
        icon: 'people',
        label: 'Shared',
        value: 'Shared'
      },
      {
        description: 'Personally created saved views',
        icon: 'eye-open',
        label: 'Private',
        value: 'Private'
      },
      {
        description: "Kentik's library of preset saved views",
        icon: <KentikLogo onlyMark alt="Kentik" style={{ maxWidth: 16 }} />,
        label: 'Kentik Presets',
        value: 'Preset'
      }
    ];
  }

  @computed
  get favorites() {
    return this.collection.unfiltered.filter((view) => view.isFavorite);
  }

  getSavedViewMetadata = (id) => {
    const { view_name, view_description } =
      this.collection
        .get()
        .find((model) => `${model.get('id')}` === id)
        ?.get() || {};

    return { description: view_description, name: view_name };
  };

  getSavedViewOptions(savedViews) {
    return savedViews.map((savedView) => ({
      value: savedView.id,
      label: (
        <Flex justifyContent="space-between" alignItems="center" flex={1}>
          {savedView.get('view_name')}
          <Flex ml={1}>
            <ShareLevelBadge item={savedView} minimal />
          </Flex>
        </Flex>
      ),
      filterLabel: savedView.get('view_name')
    }));
  }
}

export default new SavedViews();
