import { FLOWSPEC_MATCH_FIELDS, FLOWSPEC_TRAFFIC_ACTIONS } from '../mitigationsConstants';

const MATCH_DEFAULT = {
  enabled: false,
  infer: false,
  value: {
    predicates: [{}]
  }
};

const DESERIALIZE_TEMPLATE = {
  destIpCidr: { enabled: false, infer: false, value: '' },
  sourceIpCidr: { enabled: false, infer: false, value: '' },
  protocols: MATCH_DEFAULT,
  srcPorts: MATCH_DEFAULT,
  dstPorts: MATCH_DEFAULT,
  icmpCodes: MATCH_DEFAULT,
  icmpTypes: MATCH_DEFAULT,
  tcpFlags: MATCH_DEFAULT,
  packetLengths: MATCH_DEFAULT,
  dscps: MATCH_DEFAULT,
  fragments: MATCH_DEFAULT
};

function serialize(flowspec) {
  // Create object from selected trafficAction, where key is name and value is trafficAction shape
  flowspec[flowspec.trafficAction] = flowspec.trafficActions[flowspec.trafficAction] || {};

  // Form needs `enabled` property, but API expects `disabled`
  FLOWSPEC_MATCH_FIELDS.forEach((field) => {
    if (flowspec[field]) {
      flowspec[field].disabled = !flowspec[field].enabled;
      delete flowspec[field].enabled;
      if (flowspec[field].disabled || flowspec[field].infer) {
        delete flowspec[field].value;
      }
    }
  });

  // Discard unused properties
  delete flowspec.trafficAction;
  delete flowspec.trafficActions;
  delete flowspec.ports;

  return flowspec;
}

function deserialize(flowspec) {
  // Store trafficAction name as value (API gives us trafficAction as a key)
  const trafficAction = flowspec.trafficAction || FLOWSPEC_TRAFFIC_ACTIONS.find((option) => flowspec[option]);
  flowspec.trafficAction = trafficAction || '';

  // Create a trafficActions hash for form & later serialization
  if (!flowspec.trafficActions) {
    flowspec.trafficActions = trafficAction ? { [trafficAction]: flowspec[trafficAction] } : {};
    delete flowspec[trafficAction];

    // Value could be an integer, including 0, so we have to check for undefined
    if (flowspec.trafficActions?.markDSCP?.mark !== undefined) {
      // Coerce values to strings
      flowspec.trafficActions.markDSCP.mark = `${flowspec.trafficActions.markDSCP.mark}`;
    }
  }

  // Determine enabled fields (disabled fields omitted by API)
  FLOWSPEC_MATCH_FIELDS.forEach((field) => {
    if (flowspec[field]) {
      flowspec[field].enabled = true;
    }
    if (flowspec[field]?.value?.predicates) {
      flowspec[field].value.predicates.forEach((entry, i) => {
        // Coerce values to strings
        flowspec[field].value.predicates[i].value = `${entry.value}`;
      });
    }
  });

  // Fill out remaining implied values
  return Object.assign({}, DESERIALIZE_TEMPLATE, flowspec);
}

export default {
  serialize,
  deserialize
};
