import BaseModel from 'core/model/Model';
import { isEmpty } from 'lodash';

const allowAllCidrs = ['0.0.0.0/0', '::/0'];

function addIps(exceptions) {
  // We probably shouldn't allow this data to come in here mixed like this, but this is how I found it.
  return exceptions
    .map((exceptionGroup) =>
      Array.isArray(exceptionGroup.ips)
        ? exceptionGroup.ips.map((ip) => ip.trim()).join(',')
        : exceptionGroup.ips.replace(/\s/g, '')
    )
    .join(',')
    .split(',');
}

const defaults = {
  app_allow: true,
  app_exceptions: [],
  api_allow: true,
  api_exceptions: [],
  agent_allow: true,
  agent_exceptions: []
};

export default class AccessControlModel extends BaseModel {
  get defaults() {
    return defaults;
  }

  static create(data) {
    const acls = AccessControlModel.fromPayload(data);

    return new AccessControlModel(acls);
  }

  static fromPayload(access_control, acl) {
    const result = {};

    if (!access_control) {
      result.settings = defaults;
    } else if (!isEmpty(acl)) {
      result.settings = acl;
    } else {
      // build settings from access_control
      const appAllowAll = access_control.remote_portal_access_allowed[0] === '0.0.0.0/0';
      const apiAllowAll = access_control.remote_api_access_allowed[0] === '0.0.0.0/0';
      const agentAllowAll = access_control.remote_agent_access_allowed[0] === '0.0.0.0/0';

      result.neverSavedBefore = true;
      result.settings = {
        app_allow: appAllowAll,
        app_exceptions: [
          {
            label: 'Exception',
            ips: appAllowAll ? '' : access_control.remote_portal_access_allowed
          }
        ],
        api_allow: apiAllowAll,
        api_exceptions: [
          {
            label: 'Exception',
            ips: apiAllowAll ? '' : access_control.remote_api_access_allowed
          }
        ],
        agent_allow: agentAllowAll,
        agent_exceptions: [
          {
            label: 'Exception',
            ips: agentAllowAll ? '' : access_control.remote_agent_access_allowed
          }
        ]
      };
    }

    return result;
  }

  toPayload() {
    const result = {};
    const acls = this.get();

    result.remote_portal_access_allowed = acls.app_allow ? allowAllCidrs : addIps(acls.app_exceptions);
    result.remote_agent_access_allowed = acls.agent_allow ? allowAllCidrs : addIps(acls.agent_exceptions);
    result.remote_api_access_allowed = acls.api_allow ? allowAllCidrs : addIps(acls.api_exceptions);

    return result;
  }
}
