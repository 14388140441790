import { computed } from 'mobx';
import $dictionary from 'app/stores/$dictionary';
import InterfaceGroupInterfaceModel from 'app/stores/interfaceGroup/InterfaceGroupInterfaceModel';

export default class CapacityInterfaceGroupInterface extends InterfaceGroupInterfaceModel {
  @computed
  get severity() {
    const { inUtilSeverity, outUtilSeverity } = this.get('utilizationSeverity');
    const { inRunoutSeverity, outRunoutSeverity } = this.get('runoutSeverity');
    return (
      Math.max(
        ...[inUtilSeverity, outUtilSeverity, inRunoutSeverity, outRunoutSeverity].filter((num) => Number.isFinite(num))
      ) || 0
    );
  }

  @computed
  get deviceSiteInfo() {
    return `${this.get('device_name')} (${this.get('title')})`;
  }

  @computed
  get providerConnType() {
    const connTypeValue = this.get('connectivity_type');
    const connType = $dictionary.get(`interfaceClassification.connectivityTypes.${connTypeValue}`) || connTypeValue;
    return `${this.get('provider') || 'None'} (${connType || 'None'})`;
  }

  getSortValue(field) {
    if (field === 'traffic') {
      const util = this.get('util');

      if (util) {
        const inBps = util.in.current.bps;
        const outBps = util.out.current.bps;

        // mixes of undefined and 0 get sorted down the list
        if ((inBps === undefined || outBps === undefined) && (inBps === 0 || outBps === 0)) {
          return -1;
        }

        // undefined for both in and out get pinned to the bottom
        if (inBps === undefined && outBps === undefined) {
          return -2;
        }

        // drop down to 0 value for undefined when comparing together
        return Math.max(inBps || 0, outBps || 0);
      }

      return 0;
    }
    if (field === 'util') {
      const util = this.get('util');

      if (util) {
        // in/out percentages are returned as strings, make them numbers
        const inPct = parseFloat(util.in.current.pct);
        const outPct = parseFloat(util.out.current.pct);

        // mixes of NaN and 0 get sorted down the list
        if ((Number.isNaN(inPct) || Number.isNaN(outPct)) && (inPct === 0 || outPct === 0)) {
          return -1;
        }

        // NaN for both in and out get pinned to the bottom
        if (Number.isNaN(inPct) && Number.isNaN(outPct)) {
          return -2;
        }

        // drop down to 0 value for NaN when comparing together
        return Math.max(inPct || 0, outPct || 0);
      }

      return 0;
    }

    if (field === 'runout') {
      const runout = this.get('runoutSeverity');

      if (runout) {
        const { inDate, outDate } = runout;
        const inDateObj = Number.isFinite(new Date(inDate).valueOf()) ? new Date(inDate) : Number.MAX_SAFE_INTEGER;
        const outDateObj = Number.isFinite(new Date(outDate).valueOf()) ? new Date(outDate) : Number.MAX_SAFE_INTEGER;
        const inValue = inDate === 'Over a year' ? Number.MAX_SAFE_INTEGER - 1 : inDateObj;
        const outValue = outDate === 'Over a year' ? Number.MAX_SAFE_INTEGER - 1 : outDateObj;
        return Math.min(inValue, outValue);
      }

      return Number.MAX_SAFE_INTEGER;
    }

    if (field === 'trend') {
      const runout = this.get('runoutSeverity');

      if (runout) {
        const { bpsInTrend, bpsOutTrend } = runout;
        return Math.max(bpsInTrend || 0, Math.abs(bpsOutTrend || 0));
      }

      return Number.MIN_SAFE_INTEGER;
    }

    return super.getSortValue(field);
  }
}
