import safelyParseJSON from 'shared/util/safelyParseJson';
import { COMMUNITY_TEST_SUMMARY } from 'shared/synthetics/constants';
import { getHttpStages } from 'app/views/synthetics/utils/syntheticsUtils';
import requests from './requests';
import TestCollection from './TestCollection';

class CommunityTestCollection extends TestCollection {
  get url() {
    return '/api/ui/synthetics/presets/tests';
  }

  /*
    Fetches community tests and syngest results from a preset company
    and passes along to an aggregator
  */
  loadSummary = () =>
    this.fetch().then(() => {
      // fetch the plans from the presets company and assemble a list of test ids
      const ids = this.map((test) => test.get('id'));

      return (
        requests
          // fetch syngest test results for preset test ids
          .fetchTestResults({ ids, lookbackSeconds: 60 }, { preset: true })
          // pass the syngest test results through to the aggregator
          .then((testResults) => this.setSummary(testResults))
      );
    });

  /*
    Takes syngest test results from the community tests, and saves aggregated results to the test models
  */
  setSummary(results) {
    const tests = results?.tests_health || {};
    const testIds = Object.keys(tests);

    if (testIds.length > 0) {
      testIds.forEach((testId) => {
        const test = tests[testId];
        // eslint-disable-next-line prettier/prettier
        const { overall_health: { health } } = test;
        const tasks = Object.values(test.tasks);

        // convert the mesh into a shape suitable for state of the internet - cloud to use
        const mesh = Object.values(test.mesh || {}).reduce((rows, row) => {
          const columns = Object.values(row.columns).reduce(
            (cols, column) =>
              cols.concat({
                ...column,
                target: column?.target?.ip
              }),
            []
          );

          return rows.concat({ ...row, columns });
        }, []);

        // initialize a default set of aggregated results
        const summary = Object.assign({}, COMMUNITY_TEST_SUMMARY);

        if (tasks.length) {
          // get ping task and associated agents
          const pingTask = tasks.find((t) => !!t.task.ping) || tasks.find((t) => !!t.task.knock);
          const pingTaskAgentIds = Object.keys(pingTask.agents);

          pingTaskAgentIds.forEach((pingTaskAgentId) => {
            // get the ping health entry by agent timestamp
            const { time: pingTaskTime } = pingTask.agents[pingTaskAgentId];
            const pingTaskHealth =
              test?.health_ts?.[pingTaskTime]?.tasks?.[pingTask.task.id]?.agents?.[pingTaskAgentId] || null;

            if (pingTaskHealth) {
              summary.latency += pingTaskHealth.avg_latency;
              summary.packet_loss += pingTaskHealth.packet_loss;
              summary.jitter += pingTaskHealth.avg_jitter;

              if (health !== 'healthy') {
                if (pingTaskHealth.latency_health === health) {
                  summary.latency_health = health;
                }
                if (pingTaskHealth.packet_loss_health === health) {
                  summary.packet_loss_health = health;
                }
                if (pingTaskHealth.jitter_health === health) {
                  summary.jitter_health = health;
                }
              }
            }
          });

          const pingAgentCount = pingTaskAgentIds.length;
          summary.latency /= pingAgentCount;
          summary.jitter /= pingAgentCount;
          summary.packet_loss /= pingAgentCount;

          const httpTask = tasks.find((t) => !!t.task.http);

          if (httpTask) {
            let httpAgentCount = 0;
            const httpTaskAgentIds = Object.keys(httpTask.agents);

            httpTaskAgentIds.forEach((httpTaskAgentId) => {
              // get the http health entry by agent timestamp
              const { time: httpTaskTime } = httpTask.agents[httpTaskAgentId];
              const httpTaskHealth =
                test?.health_ts?.[httpTaskTime]?.tasks?.[httpTask.task.id]?.agents?.[httpTaskAgentId] || null;

              if (httpTaskHealth) {
                const parsedHealthData = safelyParseJSON(httpTaskHealth.data);
                if (Array.isArray(parsedHealthData) && parsedHealthData.length > 0) {
                  const httpHealthMetrics = parsedHealthData[0];
                  if (
                    typeof httpHealthMetrics === 'object' &&
                    httpHealthMetrics !== null &&
                    Object.keys(httpHealthMetrics).length > 0 &&
                    typeof httpHealthMetrics.duration !== 'undefined'
                  ) {
                    httpAgentCount += 1;
                    let last = 0;

                    getHttpStages().forEach((item) => {
                      const curr = httpHealthMetrics[item.key];
                      httpTaskHealth[item.stagesKey] = curr - last;
                      last = curr;
                    });

                    summary.avg_ttlb += httpTaskHealth.avg_latency;
                    summary.size += httpTaskHealth.size;

                    if (httpTaskHealth.status > summary.status) {
                      summary.status = httpTaskHealth.status;
                    }

                    summary.domain_lookup_time += httpTaskHealth.domain_lookup_time;
                    summary.connect_time += httpTaskHealth.connect_time;
                    summary.response_time += httpTaskHealth.response_time;

                    if (health !== 'healthy') {
                      if (httpTaskHealth.latency_health === health) {
                        summary.ttlb_health = health;
                      }
                      if (httpTaskHealth.jitter_health === health) {
                        summary.status_health = health;
                      }
                    }
                  }
                }
              }
            });

            if (httpAgentCount > 0) {
              summary.avg_ttlb /= httpAgentCount;
              summary.size /= httpAgentCount;
              summary.domain_lookup_time /= httpAgentCount;
              summary.connect_time /= httpAgentCount;
              summary.response_time /= httpAgentCount;
            }
          }
        }

        this.get(testId).set({
          health,
          mesh,
          ...summary
        });
      });

      this.each((model) => model.set('healthLoaded', true));
      this.lastUpdated = Date.now();

      // if collection has sortState field of any of the updated health related fields, re-sort to stay in sync.
      const { field } = this.sortState;
      if (
        [
          'healthStatusRaw',
          'latency',
          'jitter',
          'packet_loss',
          'avg_ttlb',
          'size',
          'status',
          'domain_lookup_time',
          'connect_time',
          'response_time'
        ].includes(field)
      ) {
        this.sort();
      }
    }
  }
}

export default CommunityTestCollection;
