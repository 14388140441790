import Model from 'core/model/Model';
import { formatDateTime } from 'core/util/dateUtils';
import { cloneDeep, isEmpty } from 'lodash';
import { computed, observable } from 'mobx';
import moment from 'moment';
import {
  ALL_INTERFACES_BITRATE_QUERY,
  ALL_INTERFACES_ERRORS_DISCARDS_QUERY,
  ALL_INTERFACES_UTILIZATION_QUERY,
  DEVICE_STATUS_QUERY
} from 'app/views/metrics/queries';
import MetricInterfaceCollection from 'app/stores/metrics/MetricInterfaceCollection';
import MetricComponentsCollection from 'app/stores/metrics/MetricComponentsCollection';
import MetricBGPNeighborCollection from 'app/stores/metrics/MetricBGPNeighborCollection';
import MetricISISAdjacencyCollection from 'app/stores/metrics/MetricISISAdjacencyCollection';

class MetricDeviceModel extends Model {
  interfaces = new MetricInterfaceCollection();

  components = new MetricComponentsCollection();

  neighbors = new MetricBGPNeighborCollection();

  isisAdjacencies = new MetricISISAdjacencyCollection();

  @observable.ref
  bgpData = null;

  get urlRoot() {
    return '/api/ui/recon/devices';
  }

  get defaults() {
    return {
      name: '',
      ip_address: '',
      system_description: '',
      sys_object_id: '',
      location: '',
      vendor: '',
      model: '',
      product_name: '',
      hw_version: '',
      os_name: '',
      os_version: '',
      serial_number: '',

      // by default, assume the device has no status
      available: undefined,

      // the default for mn_kmetrics_device.status_reason
      status_reason: {
        reasons: []
      }
    };
  }

  // returns a filter{} object that can be used to filter queries to this device
  @computed
  get deviceFilter() {
    return {
      connector: 'All',
      filterGroups: [
        {
          connector: 'All',
          not: false,
          filters: [
            {
              filterField: 'km_device_id',
              operator: '=',
              filterValue: `${this.id}`
            }
          ]
        }
      ]
    };
  }

  // this query is used to return the Device's `available` metric
  @computed
  get deviceStatusQuery() {
    const query = cloneDeep(DEVICE_STATUS_QUERY);
    query.kmetrics.limit = 1;
    query.kmetrics.filters = this.deviceFilter;
    return query;
  }

  @computed
  get statusLastChanged() {
    const lastChanged = this.get('status_last_changed');
    return `${formatDateTime(lastChanged)} (${moment(lastChanged).fromNow()})`;
  }

  @computed
  get cpuUtilizationQuery() {
    return {
      use_kmetrics: true,
      show_overlay: false,
      show_total_overlay: false,
      kmetrics: {
        measurement: '/system/cpus',
        dimensions: ['device_name'],
        metrics: [
          {
            name: 'total/instant',
            type: 'gauge'
          }
        ],
        range: {
          lookback: 'PT86400S'
        },
        merge: {
          dimensions: ['cpu_index'],
          aggregate: 'avg'
        },
        window: {
          size: 0,
          fn: {
            'total/instant': 'avg'
          }
        },
        rollups: {
          'avg_total/instant': {
            metric: 'total/instant',
            aggregate: 'avg'
          },
          'max_total/instant': {
            metric: 'total/instant',
            aggregate: 'max'
          },
          'last_total/instant': {
            metric: 'total/instant',
            aggregate: 'last'
          }
        },
        sort: [
          {
            name: 'avg_total/instant',
            direction: 'desc'
          },
          {
            name: 'max_total/instant',
            direction: 'desc'
          },
          {
            name: 'last_total/instant',
            direction: 'desc'
          }
        ],
        limit: 10,
        includeTimeseries: 10,
        viz: {
          type: 'area',
          rollup: '',
          metric: 'total/instant'
        },
        filters: this.deviceFilter
      }
    };
  }

  @computed
  get memoryUtilizationQuery() {
    return {
      use_kmetrics: true,
      show_overlay: false,
      show_total_overlay: false,
      kmetrics: {
        measurement: '/system/memory',
        dimensions: ['device_name'],
        metrics: [
          {
            name: 'utilization',
            type: 'gauge'
          }
        ],
        merge: {
          aggregate: 'avg',
          dimensions: ['index']
        },
        range: {
          lookback: 'PT86400S'
        },
        window: {
          size: 0,
          fn: {
            utilization: 'avg'
          }
        },
        rollups: {
          avg_utilization: {
            metric: 'utilization',
            aggregate: 'avg'
          },
          max_utilization: {
            metric: 'utilization',
            aggregate: 'max'
          },
          last_utilization: {
            metric: 'utilization',
            aggregate: 'last'
          }
        },
        sort: [
          {
            name: 'avg_utilization',
            direction: 'desc'
          },
          {
            name: 'max_utilization',
            direction: 'desc'
          },
          {
            name: 'last_utilization',
            direction: 'desc'
          }
        ],
        limit: 5,
        includeTimeseries: 5,
        viz: {
          type: 'area',
          rollup: '',
          metric: 'utilization'
        },
        filters: this.deviceFilter
      }
    };
  }

  @computed
  get memoryComponentsUtilizationQuery() {
    return {
      use_kmetrics: true,
      show_overlay: false,
      show_total_overlay: false,
      kmetrics: {
        measurement: '/components/memory',
        dimensions: ['name', 'index'],
        metrics: [
          {
            name: 'utilization',
            type: 'gauge'
          }
        ],
        range: {
          lookback: 'PT3600S'
        },
        window: {
          size: 0,
          fn: {
            utilization: 'avg'
          }
        },
        rollups: {
          last_utilization: {
            metric: 'utilization',
            aggregate: 'last'
          },
          p95_utilization: {
            metric: 'utilization',
            aggregate: 'p95'
          }
        },
        sort: [
          {
            name: 'last_utilization',
            direction: 'desc'
          },
          {
            name: 'p95_utilization',
            direction: 'desc'
          }
        ],
        limit: 100,
        includeTimeseries: 10,
        filters: this.deviceFilter
      }
    };
  }

  @computed
  get temperatureQuery() {
    return {
      use_kmetrics: true,
      show_overlay: false,
      show_total_overlay: false,
      kmetrics: {
        measurement: '/components/temperature',
        dimensions: ['device_name'],
        metrics: [
          {
            name: 'instant',
            type: 'gauge'
          }
        ],
        range: {
          lookback: 'PT86400S'
        },
        merge: {
          dimensions: ['index'],
          aggregate: 'max'
        },
        window: {
          size: 0,
          fn: {
            instant: 'avg'
          }
        },
        rollups: {
          avg_instant: {
            metric: 'instant',
            aggregate: 'avg'
          },
          max_instant: {
            metric: 'instant',
            aggregate: 'max'
          },
          last_instant: {
            metric: 'instant',
            aggregate: 'last'
          }
        },
        sort: [
          {
            name: 'avg_instant',
            direction: 'desc'
          },
          {
            name: 'max_instant',
            direction: 'desc'
          },
          {
            name: 'last_instant',
            direction: 'desc'
          }
        ],
        limit: 5,
        includeTimeseries: 5,
        viz: {
          type: 'area',
          rollup: '',
          metric: 'instant'
        },
        filters: this.deviceFilter
      }
    };
  }

  @computed
  get temperatureSensorStatusQuery() {
    return {
      use_kmetrics: true,
      show_overlay: false,
      show_total_overlay: false,
      kmetrics: {
        measurement: '/components/temperature',
        dimensions: ['index', 'name'],
        metrics: [
          {
            name: 'oper-status',
            type: 'gauge'
          }
        ],
        range: {
          lookback: 'PT3600S'
        },
        window: {
          size: 0,
          fn: {
            'oper-status': 'avg'
          }
        },
        rollups: {
          'last_oper-status': {
            metric: 'oper-status',
            aggregate: 'last'
          }
        },
        sort: [
          {
            name: 'last_oper-status',
            direction: 'desc'
          }
        ],
        limit: 1000,
        includeTimeseries: 0,
        viz: {
          type: 'column',
          rollup: '',
          metric: ''
        },
        filters: this.deviceFilter
      }
    };
  }

  /**
   * @param {string} metric
   * @param options
   */
  getInterfacesQuery(metric, { vizType = 'line', limit = 5 } = {}) {
    const windowFn = ['errors', 'discards'].some((w) => metric.includes(w)) ? 'sum' : 'avg';
    return {
      use_kmetrics: true,
      show_overlay: false,
      show_total_overlay: false,
      kmetrics: {
        measurement: '/interfaces/counters',
        dimensions: ['name', 'description'],
        metrics: [{ name: metric, type: 'gauge' }],
        range: { lookback: 'PT86400S' },
        window: { size: 0, fn: { [metric]: windowFn } },
        rollups: {
          [`avg_${metric}`]: { metric, aggregate: 'avg' },
          [`max_${metric}`]: { metric, aggregate: 'max' },
          [`min_${metric}`]: { metric, aggregate: 'min' }
        },
        sort: [
          { name: `avg_${metric}`, direction: 'desc' },
          { name: `max_${metric}`, direction: 'desc' },
          { name: `min_${metric}`, direction: 'desc' }
        ],
        limit,
        includeTimeseries: limit,
        viz: { type: vizType, rollup: '', metric },
        filters: this.deviceFilter
      }
    };
  }

  @computed
  get interfacesInUtilizationQuery() {
    return this.getInterfacesQuery('in-utilization');
  }

  @computed
  get interfacesOutUtilizationQuery() {
    return this.getInterfacesQuery('out-utilization');
  }

  @computed
  get interfacesInBitrateQuery() {
    return this.getInterfacesQuery('in-bit-rate');
  }

  @computed
  get interfacesOutBitrateQuery() {
    return this.getInterfacesQuery('out-bit-rate');
  }

  @computed
  get interfacesBitrateQuery() {
    return {
      use_kmetrics: true,
      show_overlay: false,
      show_total_overlay: false,
      kmetrics: {
        ...ALL_INTERFACES_BITRATE_QUERY.kmetrics,
        filters: this.deviceFilter
      }
    };
  }

  @computed
  get interfacesUtilizationQuery() {
    return {
      use_kmetrics: true,
      show_overlay: false,
      show_total_overlay: false,
      kmetrics: {
        ...ALL_INTERFACES_UTILIZATION_QUERY.kmetrics,
        filters: this.deviceFilter
      }
    };
  }

  @computed
  get errorsDiscardsQuery() {
    return {
      use_kmetrics: true,
      show_overlay: false,
      show_total_overlay: false,
      kmetrics: {
        ...ALL_INTERFACES_ERRORS_DISCARDS_QUERY.kmetrics,
        filters: this.deviceFilter
      }
    };
  }

  @computed
  get physicalInterfacesQuery() {
    return {
      use_kmetrics: true,
      show_overlay: false,
      show_total_overlay: false,
      kmetrics: {
        measurement: '/interfaces/counters',
        dimensions: ['ifindex', 'name', 'description'],
        metrics: [
          {
            name: 'oper-status',
            type: 'gauge'
          },
          {
            name: 'admin-status',
            type: 'gauge'
          }
        ],
        range: {
          lookback: 'PT3600S'
        },
        window: {
          size: 0,
          fn: {
            'oper-status': 'last',
            'admin-status': 'last'
          }
        },
        rollups: {
          last_if_OperStatus: {
            metric: 'admin-status',
            aggregate: 'last'
          },
          last_if_AdminStatus: {
            metric: 'admin-status',
            aggregate: 'last'
          }
        },
        sort: [
          {
            name: 'last_if_OperStatus',
            direction: 'desc'
          },
          {
            name: 'last_if_AdminStatus',
            direction: 'desc'
          }
        ],
        limit: 500,
        includeTimeseries: 10,
        filters: {
          connector: 'All',
          filterGroups: [
            {
              name: '',
              named: false,
              connector: 'All',
              not: false,
              autoAdded: '',
              filters: [
                {
                  filterField: 'device_name',
                  metric: '',
                  aggregate: '',
                  operator: 'ILIKE',
                  filterValue: this.get('name')
                },
                {
                  filterField: 'logical',
                  metric: '',
                  aggregate: '',
                  operator: '=',
                  filterValue: 'false'
                }
              ],
              saved_filters: [],
              filterGroups: []
            }
          ]
        },
        rollupFilters: {
          connector: 'All',
          filterGroups: [
            {
              name: '',
              named: false,
              connector: 'All',
              not: false,
              autoAdded: '',
              filters: [
                {
                  filterField: 'last_if_AdminStatus',
                  metric: 'admin-status',
                  aggregate: 'last',
                  operator: '=',
                  filterValue: '1'
                }
              ],
              saved_filters: [],
              filterGroups: []
            }
          ]
        }
      }
    };
  }

  @computed
  get physicalInterfacesUtilizationQuery() {
    return {
      use_kmetrics: true,
      show_overlay: false,
      show_total_overlay: false,
      kmetrics: {
        measurement: '/interfaces/counters',
        dimensions: ['ifindex', 'name', 'description'],
        metrics: [
          {
            name: 'in-bit-rate',
            type: 'gauge'
          },
          {
            name: 'in-utilization',
            type: 'gauge'
          }
        ],
        range: {
          lookback: 'PT3600S'
        },
        window: {
          size: 0,
          fn: {
            'in-bit-rate': 'last',
            'in-utilization': 'last'
          }
        },
        rollups: {
          avg_IfInBitRate: {
            metric: 'in-bit-rate',
            aggregate: 'avg'
          },
          max_IfInBitRate: {
            metric: 'in-bit-rate',
            aggregate: 'max'
          },
          avg_IfInUtilization: {
            metric: 'in-utilization',
            aggregate: 'avg'
          },
          max_IfInUtilization: {
            metric: 'in-utilization',
            aggregate: 'max'
          }
        },
        sort: [
          {
            name: 'avg_IfInBitRate',
            direction: 'desc'
          },
          {
            name: 'max_IfInBitRate',
            direction: 'desc'
          },
          {
            name: 'avg_IfInUtilization',
            direction: 'desc'
          },
          {
            name: 'max_IfInUtilization',
            direction: 'desc'
          }
        ],
        limit: 500,
        includeTimeseries: 10,
        filters: {
          connector: 'All',
          filterGroups: [
            {
              name: '',
              named: false,
              connector: 'All',
              not: false,
              autoAdded: '',
              filters: [
                {
                  filterField: 'logical',
                  metric: '',
                  aggregate: '',
                  operator: '=',
                  filterValue: 'false'
                },
                {
                  filterField: 'device_name',
                  metric: '',
                  aggregate: '',
                  operator: 'ILIKE',
                  filterValue: this.get('name')
                }
              ],
              saved_filters: [],
              filterGroups: []
            }
          ]
        }
      }
    };
  }

  @computed
  get bgpPrefixesSentQuery() {
    return {
      use_kmetrics: true,
      show_overlay: false,
      show_total_overlay: false,
      kmetrics: {
        measurement: '/protocols/bgp/neighbors/prefixes',
        dimensions: ['device_name'],
        metrics: [
          {
            name: 'sent',
            type: 'gauge'
          },
          {
            name: 'received',
            type: 'gauge'
          }
        ],
        range: {
          lookback: 'PT86400S'
        },
        merge: {
          dimensions: ['neighbor-table-index'],
          aggregate: 'sum'
        },
        window: {
          size: 0,
          fn: {
            sent: 'avg',
            received: 'avg'
          }
        },
        rollups: {
          avg_sent: {
            metric: 'sent',
            aggregate: 'avg'
          },
          last_sent: {
            metric: 'sent',
            aggregate: 'last'
          },
          max_sent: {
            metric: 'sent',
            aggregate: 'max'
          },
          avg_received: {
            metric: 'received',
            aggregate: 'avg'
          },
          last_received: {
            metric: 'received',
            aggregate: 'last'
          },
          max_received: {
            metric: 'received',
            aggregate: 'max'
          }
        },
        sort: [
          {
            name: 'avg_sent',
            direction: 'desc'
          },
          {
            name: 'last_sent',
            direction: 'desc'
          },
          {
            name: 'max_sent',
            direction: 'desc'
          },
          {
            name: 'avg_received',
            direction: 'desc'
          },
          {
            name: 'last_received',
            direction: 'desc'
          },
          {
            name: 'max_received',
            direction: 'desc'
          }
        ],
        limit: 50,
        includeTimeseries: 10,
        viz: {
          type: 'area',
          rollup: '',
          metric: 'sent'
        },
        filters: this.deviceFilter
      }
    };
  }

  @computed
  get bgpPrefixesQuery() {
    return {
      use_kmetrics: true,
      show_overlay: false,
      show_total_overlay: false,
      kmetrics: {
        measurement: '/protocols/bgp/neighbors/prefixes',

        // we match on neighbor-table-index to get the prefixes for a specific neighbor
        dimensions: ['neighbor-table-index'],
        metrics: [
          {
            name: 'sent',
            type: 'gauge'
          },
          {
            name: 'received',
            type: 'gauge'
          }
        ],
        range: {
          lookback: 'PT3600S'
        },
        window: {
          size: 0,
          fn: {
            sent: 'avg',
            received: 'avg'
          }
        },
        rollups: {
          last_sent: {
            metric: 'sent',
            aggregate: 'last'
          },
          last_received: {
            metric: 'received',
            aggregate: 'last'
          }
        },
        sort: [
          {
            name: 'last_sent',
            direction: 'desc'
          },
          {
            name: 'last_received',
            direction: 'desc'
          }
        ],
        limit: 10000,
        includeTimeseries: 40,
        viz: {
          type: 'line'
        },
        filters: this.deviceFilter
      }
    };
  }

  get establishedQuery() {
    return {
      use_kmetrics: true,
      show_overlay: false,
      show_total_overlay: false,
      kmetrics: {
        measurement: '/protocols/bgp/neighbors',
        dimensions: ['index'],
        metrics: [
          {
            name: 'last-established',
            type: 'gauge'
          }
        ],
        range: {
          lookback: 'PT3600S'
        },
        window: {
          size: 0,
          fn: {
            'last-established': 'avg'
          }
        },
        rollups: {
          'last_last-established': {
            metric: 'last-established',
            aggregate: 'last'
          }
        },
        sort: [
          {
            name: 'last_last-established',
            direction: 'desc'
          }
        ],
        limit: 10000,
        includeTimeseries: 40,
        viz: {
          type: 'line'
        },
        filters: this.deviceFilter
      }
    };
  }

  @computed
  get statusDisplay() {
    const status = this.get('status');
    if (status === undefined) {
      return 'Unknown';
    }

    return status.charAt(0).toUpperCase() + status.slice(1);
  }

  @computed
  get statusIntent() {
    const status = this.get('status');

    if (status === undefined) {
      return 'none';
    }

    const statusIntent = {
      down: 'danger',
      up: 'success',
      unknown: 'none',
      unobservable: 'warning',
      possibly_down: 'warning'
    };

    return statusIntent[status];
  }

  @computed
  get queryCards() {
    return [
      {
        title: 'CPU Utilization',
        query: this.cpuUtilizationQuery
      },
      {
        title: 'Memory Utilization',
        query: this.memoryUtilizationQuery
      },
      {
        title: 'Component Memory Utilization',
        query: this.memoryComponentsUtilizationQuery
      },
      {
        title: 'Temperature',
        query: this.temperatureQuery
      },
      {
        title: 'Temperature Sensors',
        query: this.temperatureSensorStatusQuery
      },
      {
        title: 'Physical Interfaces',
        query: this.physicalInterfacesQuery
      },
      {
        title: 'Physical Interface Utilization',
        query: this.physicalInterfacesUtilizationQuery
      },
      {
        title: 'BGP Prefixes Sent/Received',
        query: this.bgpPeersQuery
      }
    ];
  }

  @computed
  get uptimeQuery() {
    return {
      use_kmetrics: true,
      show_overlay: false,
      show_total_overlay: false,
      kmetrics: {
        measurement: '/system',
        dimensions: ['device_name'],
        metrics: [
          {
            name: 'boot-time',
            type: 'gauge'
          },
          {
            name: 'uptime-sec',
            type: 'gauge'
          }
        ],
        range: {
          lookback: 'PT900S'
        },
        window: {
          size: 0,
          fn: {
            'boot-time': 'last',
            'uptime-sec': 'last'
          }
        },
        rollups: {
          'boot-time': {
            metric: 'boot-time',
            aggregate: 'last'
          },
          uptime: {
            metric: 'uptime-sec',
            aggregate: 'last'
          }
        },
        sort: [
          {
            name: 'boot-time',
            direction: 'desc'
          },
          {
            name: 'uptime',
            direction: 'desc'
          }
        ],
        limit: 10,
        includeTimeseries: 0,
        filters: this.deviceFilter
      }
    };
  }

  @computed
  get latencyQuery() {
    return {
      use_kmetrics: true,
      show_overlay: false,
      show_total_overlay: false,
      kmetrics: {
        measurement: '/system',
        dimensions: ['device_name'],
        metrics: [
          {
            name: 'rtt-avg',
            type: 'gauge'
          }
        ],
        range: {
          lookback: 'PT3600S'
        },
        window: {
          size: 0,
          fn: {
            'rtt-avg': 'last'
          }
        },
        sort: [
          {
            name: 'last_rtt-avg',
            direction: 'desc'
          }
        ],
        rollups: {
          'min_rtt-avg': {
            metric: 'rtt-avg',
            aggregate: 'min'
          },

          'max_rtt-avg': {
            metric: 'rtt-avg',
            aggregate: 'max'
          },
          'avg_rtt-avg': {
            metric: 'rtt-avg',
            aggregate: 'avg'
          },
          'last_rtt-avg': {
            metric: 'rtt-avg',
            aggregate: 'last'
          }
        },
        viz: {
          type: 'area',
          rollup: '',
          metric: 'rtt-avg'
        },
        limit: 1,
        includeTimeseries: 1,
        filters: this.deviceFilter
      }
    };
  }

  @computed
  get isUnobservable() {
    return this.get('status') === 'unobservable';
  }

  @computed
  get isICMPOnly() {
    return !!this.get('ranger_icmp_ip');
  }

  @computed
  get hasBGPNeighbors() {
    return this.get('neighborCount') > 0;
  }

  @computed
  get hasISISAdjacencies() {
    return this.get('adjacencyCount') > 0;
  }

  @computed
  get hasOSPFFields() {
    return !isEmpty(this.get('ospfGlobal'));
  }

  @computed
  get hasLLDPFields() {
    return !isEmpty(this.get('lldpGlobal'));
  }

  @computed
  get hasBGPGlobalFields() {
    return !isEmpty(this.get('bgpGlobal'));
  }

  @computed
  get uptimeDuration() {
    const uptime = this.get('uptime');

    if (!uptime) {
      return 'Unknown';
    }

    const duration = moment.duration(uptime, 'seconds');

    // shows as "364 days" up until the last day, then shows as "1 year", etc.
    return duration.humanize({ d: 365 });
  }

  @computed
  get bootTimeDuration() {
    const bootTime = this.get('bootTime') / 1000000;

    if (!bootTime) {
      return 'Unknown';
    }

    const date = moment(bootTime);
    const duration = moment.duration(date.diff(Date.now()));

    return `${formatDateTime(date)} (${duration.humanize({ d: 365 })} ago)`;
  }

  @computed
  get labels() {
    const labels = this.get('labels') || [];
    return labels.map((label) => label.name);
  }

  deserialize(data) {
    const result = data;

    data.interfaces ??= [];

    // save counts so filters don't change them
    result.interfaceCount = data.interfaces.length;
    result.componentCount = data.components.length;
    result.neighborCount = data.neighbors.length;
    result.neighborNotEstablishedCount = data.neighbors.filter((neighbor) => neighbor['session-state'] !== 6).length;
    result.adjacencyCount = data.isisAdjacencies.length;

    this.interfaces = new MetricInterfaceCollection(data.interfaces, {
      parent: this,
      totalCount: data.interfaces.length
    });

    this.components = new MetricComponentsCollection(data.components);
    this.neighbors = new MetricBGPNeighborCollection(data.neighbors);
    this.isisAdjacencies = new MetricISISAdjacencyCollection(data.isisAdjacencies);

    return super.deserialize(result);
  }
}

export default MetricDeviceModel;
