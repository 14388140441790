import { action, computed, observable, toJS } from 'mobx';
import moment from 'moment';
import { isEqual, groupBy as lodashGroupBy, isString } from 'lodash';
import Promise from 'bluebird';

import $auth from 'app/stores/$auth';
import $alerting from 'app/stores/alerting/$alerting';
import $mkp from 'app/stores/mkp/$mkp';
import groupByDay from 'core/util/groupByDay';
import { timezone } from 'core/util/dateUtils';
import Collection from 'core/model/Collection';
import { isValidUUID } from 'app/views/alerting/util/uuid';
import {
  STANDARD_ALERT_SEVERITY_TO_ALERT_MANAGER_SEVERITY,
  ALERT_MANAGER_SEVERITY_TO_STANDARD_ALERT_SEVERITY,
  ALARM_STATE_KEY_TO_RAW,
  ALARM_STATE_RAW_TO_KEY,
  ALERT_SEVERITY_RANKS,
  POLICY_APPLICATIONS
} from 'shared/alerting/constants';

import AlertModel from './AlertModel';

const defaultStart = moment.utc().subtract(24, 'hours').toISOString();

const defaultLimit = 1000;

const defaultServerFilter = {
  ackStates: [],
  ackedByUserIds: [],
  active: {
    start: defaultStart,
    end: null
  },
  alarmIds: '',
  applications: ['core'],
  includeSubpolicies: false,
  keyPartsSearch: '',
  lookback: 24 * 3600,
  pagination: {
    limit: defaultLimit,
    offset: 0
  },
  ruleIds: [],
  severities: [],
  sites: [],
  states: ['ALARM_STATE_ACTIVE'],
  tenants: []
};

class AlertCollection extends Collection {
  @observable.ref
  serverFilter = {
    ...defaultServerFilter
  };

  serverSortableFields = [
    'severity',
    'state', // ui field: stateLabel
    'application',
    'policyName',
    'policyID',
    'primary_dimension', // ui field: primaryDimension
    'alarm_id', // ui field: id
    'start_time' // ui field: startTime
  ];

  uiSortLogicFields = ['policyName', 'policyID', 'application'];

  @observable.ref
  policiesByRuleId = {};

  @observable.ref
  interfaceDescriptions = {};

  @observable.ref
  totals = {};

  @observable.ref
  totalCount = 0;

  constructor(data, options = { threeWaySort: true }) {
    super(data, options);
  }

  get filterFieldWhitelist() {
    return new Set(['id', 'state', 'policyName', 'dimensionValues']);
  }

  get queuedFetchKey() {
    return this.id;
  }

  @action
  clearFilters() {
    this.serverFilter = {};
    super.clearFilters();

    this.queuedFetch();
  }

  get url() {
    return '/api/ui/alertingManager/alarms';
  }

  get fetchMethod() {
    return 'post';
  }

  get model() {
    return AlertModel;
  }

  @computed
  get countAckReq() {
    return this.unfiltered.filter((m) => m.isAckReq).length;
  }

  @computed
  get countAlarm() {
    return this.unfiltered.filter((m) => m.isCleared).length;
  }

  @computed
  get countCleared() {
    return this.unfiltered.filter((m) => !m.isAckReq && !m.isCleared).length;
  }

  @computed
  get attacksWithinLast24Hours() {
    return this.models.filter((model) => model.startTime >= defaultStart).length;
  }

  @computed
  get activeAttacks() {
    return this.models.filter((model) => !model.isCleared).length;
  }

  get sortedGroupKeys() {
    if (!this.groupBy) {
      return [];
    }

    const groupedModels = this.groupedData;
    const groupKeys = Object.keys(groupedModels);

    if (this.groupBy === 'severity') {
      return groupKeys.sort((a, b) => ALERT_SEVERITY_RANKS[b] - ALERT_SEVERITY_RANKS[a]);
    }

    return Object.keys(groupedModels).sort((a, b) => a.localeCompare(b));
  }

  getFetchOptions(options = {}) {
    const { data = {} } = options;
    const { pagination, sorting, includeSubpolicies, tenants = [], ...restServerFilter } = this.getServerFilter();
    const paginationOverride = { offset: data.offset ? data.offset : 0, limit: defaultLimit };
    let { ruleIds = [] } = restServerFilter;

    // build a list of parent ruleIds and subtenant ruleIds
    if (ruleIds.length > 0 && includeSubpolicies) {
      ruleIds = $alerting.getRuleIds({ includeSubpolicies, tenantIds: tenants, parentRuleIds: ruleIds });
    }

    // build a list of all ruleIds
    if (ruleIds.length === 0) {
      ruleIds = $alerting.getRuleIds({ includeSubpolicies, tenantIds: tenants });
    }

    // restructure the payload by nesting the filters underneath "filter"
    return {
      ...options,
      data: {
        filter: {
          ...restServerFilter,
          ruleIds,
          includeSubpolicies,
          includeArchived: false
        },
        sorting,
        pagination: paginationOverride
      }
    };
  }

  getActiveAlertsChartData(groupBy = 'policyName') {
    if (this.size === 0) {
      return [];
    }

    // Group the models by Policy, because each series is a Policy
    // use `original` so the chart data never gets filtered
    const groupedByPolicy = lodashGroupBy(this.original, (m) => `${m[groupBy] || m.get(groupBy)}`);

    // For each policy, create a series object
    const series = Object.entries(groupedByPolicy).map(([identifier, policyModels]) => ({
      name: identifier,
      alertIds: policyModels.map((m) => m.id),
      y: policyModels.length
    }));

    return [
      {
        name: 'Alerts',
        data: series
      }
    ];
  }

  getAlertingOverviewData(groupBy = 'policyName') {
    if (this.size === 0) {
      return [];
    }

    // Group the models by Policy, because each series is a Policy
    // use `original` so the chart data never gets filtered
    const grouped = lodashGroupBy(this.original, (m) => `${m[groupBy] || m.get(groupBy)}`);

    // For each policy, create a series object
    return Object.entries(grouped).map(([identifier, policyModels]) => {
      // take the policyModels and group them into day buckets
      const groupedByDay = groupByDay(policyModels, 'start_time');

      // Convert the groupedByDay object into an array of { x: date, y: count, ...metadata } pairs
      const data = Object.entries(groupedByDay).map(([day, alertModelsForDay]) => {
        const utcDate = timezone.momentFn(day).valueOf();

        const count = alertModelsForDay.length;
        const alertIds = alertModelsForDay.map((m) => m.id);

        return { x: utcDate, y: count, alertIds, name: identifier };
      });

      return {
        name: identifier,
        data
      };
    });
  }

  async fetch(options = {}, method = 'fetch') {
    const promises = [];

    // we need to know about policies and tenants before we can set our fetch options
    if (!$alerting.policyCollection.hasFetched) {
      promises.push($alerting.policyCollection.fetch());
    }
    if (!$mkp.tenants.hasFetched) {
      promises.push($mkp.tenants.fetch());
    }

    // temporarily set to fetching so that the collection will look like it's loading
    this.requestStatus = 'fetching';

    return Promise.all(promises).then(() => {
      this.requestStatus = null; // reset back to null and let the collection set it as needed

      return super[method](this.getFetchOptions(options));
    });
  }

  async queuedFetch(options = {}) {
    const queuedFetchPromise = this.fetch(options, 'queuedFetch');
    this.lastFetched = Date.now();
    return queuedFetchPromise;
  }

  loadMoreItems(options) {
    // Prevent InfiniteLoader from loading more items when the local search is being used
    if (isString(this.filterState) && this.filterState !== '') {
      return Promise.resolve();
    }

    return super.loadMoreItems(options);
  }

  // getGroupTotal({ groupKey, group = [], groupBy }) {
  getGroupTotal({ group = [] }) {
    // just return a count of the currently loaded models for this group
    return group.length;
  }

  setServerFilter(filter, options = {}) {
    const { fetch = true, force } = options;
    const defaults = { ...defaultServerFilter };
    const { startDate, endDate, ...restFilter } = filter;

    // adjust filter active date if this Fn was called with legacy startDate and endDate
    if (!filter.active && (startDate || endDate)) {
      restFilter.active = { start: startDate, end: endDate };
    }

    // apply some basic "false-y" defaults to both sides in order to have a more even comparison
    const existingValues = { ...defaults, ...toJS(this.serverFilter) };
    const newValues = { ...defaults, ...restFilter };

    // adjust values for state and severity
    if (Array.isArray(newValues.states)) {
      newValues.states = newValues.states
        .map((state) => ALARM_STATE_KEY_TO_RAW[state] || state)
        .filter((state) => !!ALARM_STATE_RAW_TO_KEY[state]);
    }
    if (Array.isArray(newValues.severities)) {
      newValues.severities = newValues.severities
        .map((severity) => STANDARD_ALERT_SEVERITY_TO_ALERT_MANAGER_SEVERITY[severity] || severity)
        .filter((severity) => !!ALERT_MANAGER_SEVERITY_TO_STANDARD_ALERT_SEVERITY[severity]);
    }

    // format start and end dates
    if (newValues.active?.start) {
      newValues.active.start = moment.utc(newValues.active.start).seconds(0).format();
    }
    if (newValues.active?.end) {
      newValues.active.end = moment.utc(newValues.active.end).seconds(0).format();
    }

    // avoid unnecessary loads
    if (isEqual(newValues, existingValues)) {
      return Promise.resolve();
    }

    this.serverFilter = { ...newValues };

    if (fetch && (!newValues.alarmIds || isValidUUID(newValues.alarmIds))) {
      return this.queuedFetch({ force });
    }

    return Promise.resolve();
  }

  getServerFilter() {
    const {
      lookback,
      active,
      applications = [],
      ackStates = [],
      alarm,
      alarmIds,
      keyPartsSearch,
      ackedByUserIds = [],
      ...filter
    } = toJS(this.serverFilter);

    filter.ackStates = [];
    filter.alarmIds = undefined;
    filter.active = { start: null, end: null };
    filter.ackedByUserIds = [];
    filter.applications = applications || [];
    filter.keyPartsSearch = undefined;

    // If the user is filtering by metric application, we need to include NMS as well
    if (
      filter.applications.includes(POLICY_APPLICATIONS.METRIC) &&
      !filter.applications.includes(POLICY_APPLICATIONS.NMS) &&
      $auth.hasPermission('alerts.nmsNative.enabled', { overrideForSudo: false })
    ) {
      filter.applications = [...filter.applications, POLICY_APPLICATIONS.NMS];
    }

    // If the user is filtering by metric application, we need to include EVENT as well
    if (
      filter.applications.includes(POLICY_APPLICATIONS.METRIC) &&
      !filter.applications.includes(POLICY_APPLICATIONS.KEVENT) &&
      $auth.hasPermission('alerts.eventAlerting.enabled', { overrideForSudo: false })
    ) {
      filter.applications = [...filter.applications, POLICY_APPLICATIONS.KEVENT];
    }

    Object.entries(filter).forEach(([field, value]) => {
      // Permit empty arrays; api treats them as unspecified.
      if (!value) {
        delete filter[field];
      }
    });

    if (Number.isInteger(lookback)) {
      filter.active.start = moment.utc().subtract(lookback, 'seconds').toISOString();
    } else if (active?.start || active?.end) {
      filter.active.start = moment(active?.start).isValid() ? moment.utc(active.start).format() : null;
      filter.active.end = moment(active?.end).isValid() ? moment.utc(active.end).format() : null;
    }

    if (Array.isArray(ackedByUserIds) && ackedByUserIds.length > 0) {
      filter.ackedByUserIds = ackedByUserIds;
    }

    // These use API values, so we can apply them directly
    if (Array.isArray(ackStates)) {
      const validAckStates = [];
      ackStates.forEach((ackState) => {
        if (ackState === 'ACKED_BY_ME') {
          filter.ackedByUserIds = [$auth.activeUser.id];
        } else {
          validAckStates.push(ackState);
        }
      });

      filter.ackStates = validAckStates;
    }

    if (alarmIds) {
      filter.alarmIds = [alarmIds];
    }

    if (this.groupBy || (this.sortState.field && this.sortFieldIsServerSortable)) {
      filter.sorting = {
        fields: []
      };

      if (this.groupBy) {
        if (this.groupByFieldIsUISortLogicDriven) {
          filter.sorting.fields.push({
            name: 'rule_id',
            values: $alerting.getRuleIds({
              includeSubpolicies: false,
              tenantIds: [],
              sortByPolicyField: this.groupBy,
              sortDirection: 'asc'
            })
          });
        } else {
          filter.sorting.fields.push({
            name: this.groupBy,
            order: 'SORT_ORDER_ASCENDING'
          });
        }
      }

      if (this.sortState.field && this.sortFieldIsServerSortable && this.sortState.field !== this.groupBy) {
        let sortDirection = 'SORT_ORDER_UNSPECIFIED';

        if (this.sortState.direction === 'asc') {
          sortDirection = 'SORT_ORDER_ASCENDING';
        } else if (this.sortState.direction === 'desc') {
          sortDirection = 'SORT_ORDER_DESCENDING';
        }

        if (this.sortFieldIsUISortLogicDriven) {
          filter.sorting.fields.push({
            name: 'rule_id',
            values: $alerting.getRuleIds({
              includeSubpolicies: false,
              tenantIds: [],
              sortByPolicyField: this.sortState.field,
              sortDirection: this.sortState.direction
            })
          });
        } else {
          filter.sorting.fields.push({
            name: this.sortState.field,
            order: sortDirection
          });
        }
      }
    }

    if (keyPartsSearch) {
      filter.keys = {
        filters: [
          {
            key: { any: true },
            value: { contains: keyPartsSearch }
          }
        ]
      };
    }

    return { ...filter };
  }

  deserialize(data = {}) {
    const { alarms = [], pagination = {}, policiesByRuleId = {}, interfaceDescriptions } = data;

    this.totalCount = Number(pagination?.total_count || alarms.length);
    this.policiesByRuleId = Object.assign(this.policiesByRuleId, policiesByRuleId);
    this.interfaceDescriptions = Object.assign(this.interfaceDescriptions, interfaceDescriptions);

    const uniqueAlarms = alarms
      .map(({ alarm, initial_context = {} }) => {
        const policy = this.policiesByRuleId[alarm.rule_id];

        if (!policy) {
          console.warn(
            'No matching policy found for alert id ',
            alarm.id,
            'with rule id ',
            alarm.rule_id,
            ' and application ',
            alarm.application
          );
          return null;
        }

        policy.application = policy.application === '' ? POLICY_APPLICATIONS.CORE : policy.application;

        return { alarm, activation_context: initial_context, policy };
      })
      .filter(Boolean); // filter out duds that don't have policy, can be because of corrupted data such as https://github.com/kentik/ui-app/issues/22927

    // Add policy to each alarm
    return super.deserialize(uniqueAlarms);
  }
}

export default AlertCollection;
