import { buildFilterGroup } from 'core/util/filters';

import getBaseQuery from './getBaseQuery';
import getQueryFilterGroups from './getQueryFilterGroups';

export default function trafficRatioQuery(queryFilters, asn) {
  return {
    ...getBaseQuery({ queryFilters, asn }),
    filters: {
      connector: 'All',
      filterGroups: [
        buildFilterGroup({
          filters: [{ filterField: 'src|dst_as', operator: '=', filterValue: asn }]
        })
      ]
    },
    aggregates: [
      {
        value: 'avg_bits_per_sec',
        column: 'f_sum_both_bytes',
        fn: 'average',
        label: 'Bits/s Sampled at Ingress + Egress Average',
        unit: 'bytes',
        group: 'Bits/s Sampled at Ingress + Egress',
        origLabel: 'Average',
        sample_rate: 1,
        raw: true,
        name: 'avg_bits_per_sec'
      },
      {
        value: 'p95th_bits_per_sec',
        column: 'f_sum_both_bytes',
        fn: 'percentile',
        label: 'Bits/s Sampled at Ingress + Egress 95th Percentile',
        rank: 95,
        unit: 'bytes',
        group: 'Bits/s Sampled at Ingress + Egress',
        origLabel: '95th Percentile',
        sample_rate: 1,
        name: 'p95th_bits_per_sec'
      },
      {
        value: 'max_bits_per_sec',
        column: 'f_sum_both_bytes',
        fn: 'max',
        label: 'Bits/s Sampled at Ingress + Egress Max',
        unit: 'bytes',
        group: 'Bits/s Sampled at Ingress + Egress',
        origLabel: 'Max',
        sample_rate: 1,
        name: 'max_bits_per_sec'
      }
    ],
    filterDimensionsEnabled: true,
    filterDimensionName: 'Total',
    filterDimensionOther: false,
    filterDimensions: {
      connector: 'All',
      filterGroups: [
        buildFilterGroup({
          name: 'Outbound',
          named: true,
          filters: [
            { filterField: 'i_src_network_bndry_name', operator: '=', filterValue: 'internal' },
            { filterField: 'i_dst_network_bndry_name', operator: '=', filterValue: 'external' }
          ],
          filterGroups: getQueryFilterGroups(queryFilters, 'src')
        }),
        buildFilterGroup({
          name: 'Inbound',
          named: true,
          filters: [
            { filterField: 'i_src_network_bndry_name', operator: '=', filterValue: 'external' },
            { filterField: 'i_dst_network_bndry_name', operator: '=', filterValue: 'internal' }
          ],
          filterGroups: getQueryFilterGroups(queryFilters, 'dst')
        })
      ]
    }
  };
}
