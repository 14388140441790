import { computed } from 'mobx';
import Model from 'core/model/Model';
import { getIPSortValue } from 'core/util/sortUtils';
import $dictionary from 'app/stores/$dictionary';
import { countryMetrics } from 'app/components/dataviews/views/legend/legendUtils';

let ipFields = [];

class QueryResultModel extends Model {
  get url() {
    return '';
  }

  get defaults() {
    return {
      isOverlay: false,
      sampleRate: 1,
      enabled: true
    };
  }

  @computed
  get hasRawData() {
    const rawData = this.get('rawData');
    return rawData && !!Object.keys(rawData).length;
  }

  @computed
  get isOverlay() {
    return this.get('isOverlay');
  }

  @computed
  get sparklineData() {
    const rawData = this.get('rawData');

    if (rawData) {
      if (rawData.both_bits_per_sec) {
        return rawData.both_bits_per_sec.flow.map((data) => data[1]);
      }
      if (rawData.both_pkts_per_sec) {
        return rawData.both_pkts_per_sec.flow.map((data) => data[1]);
      }
      if (rawData.flows_per_sec) {
        return rawData.flows_per_sec.flow.map((data) => data[1]);
      }
    }

    const timeseries = this.get('timeseries');
    const metric = this.collection.bucket?.firstQuery.get('kmetrics.viz.metric');
    if (timeseries && metric) {
      const idx = this.get('metrics').indexOf(metric) || 0;
      return timeseries.map((point) => point[idx + 1]);
    }

    return [];
  }

  get toggled() {
    return this.get('toggled');
  }

  set toggled(value) {
    const originalModel = this.get('model');
    const { otherPeriodModel } = this;

    if (originalModel) {
      originalModel.set('toggled', value);
    }

    if (otherPeriodModel) {
      otherPeriodModel.set('toggled', value);
    }

    this.set('toggled', value);
  }

  /**
   * @returns {QueryResultModel|null}
   */
  @computed
  get otherPeriodModel() {
    const previousModel = this.get('previousModel');
    const { bucket } = this.collection;

    // comparison summary models have direct reference to previous period model
    if (previousModel) {
      return previousModel;
    }

    if (bucket) {
      const otherPeriodBucket = bucket.get('isPreviousPeriod')
        ? bucket.collection.models[0]
        : bucket.collection.find({ isPreviousPeriod: true });

      if (otherPeriodBucket) {
        const key = this.get('key');
        return otherPeriodBucket.queryResults.find({ key });
      }
    }

    return null;
  }

  getSortValue(field) {
    let value = this.get(field);

    if (countryMetrics.includes(field)) {
      value = this.get(field.toLowerCase());
    }

    if (!ipFields.length) {
      ipFields = $dictionary.get('queryFilters.flatParametricFilterTypes.ip') || [];
    }

    if (!this.isOverlay && (ipFields.includes(field) || field === 'IP Address')) {
      return getIPSortValue(value);
    }

    if (value === undefined || value === null) {
      return -1;
    }

    return value;
  }

  setComparing(value = true) {
    if (this.get('isComparison')) {
      const originalModel = this.get('model');

      if (originalModel) {
        originalModel.setComparing(value);
      }

      return;
    }

    const { otherPeriodModel } = this;

    if (value) {
      // set comparing on other models off
      this.collection.each((model) => {
        if (model.get('comparing')) {
          model.set('comparing', false);
        }
      });

      if (otherPeriodModel) {
        otherPeriodModel.collection.each((model) => {
          if (model.get('comparing')) {
            model.set('comparing', false);
          }
        });
      }
    }

    this.set('comparing', value);

    if (otherPeriodModel) {
      otherPeriodModel.set('comparing', value);
    }
  }
}

export default QueryResultModel;
