import React from 'react';
import { observable, computed, action } from 'mobx';
import { pick } from 'lodash';

import Model from 'core/model/Model';
import deepClone from 'core/util/deepClone';
import { addFilter, addFilterGroup, getDefaultFiltersObject } from 'core/util/filters';
import $dictionary from 'app/stores/$dictionary';
import $dashboards from 'app/stores/dashboard/$dashboards';
import DataViewModel from 'app/stores/query/DataViewModel';
import QueryModel from 'app/stores/query/QueryModel';
import { getQueriesForHash } from 'app/stores/query/urlHash';
import { getModelFilters, injectLegacySavedFilters } from 'app/util/filters';
import RawFlowDataViewModel from 'app/components/dataviews/views/RawFlow/RawFlowDataViewModel';
import SyntheticsTestDataViewModel from 'app/stores/query/SyntheticsTestDataViewModel';
import MetricsExplorerDataViewModel from 'app/components/dataviews/views/metrics/MetricsExplorerDataViewModel';

const parametricModes = {
  override_all: 'Override all existing filters',
  override_specific: 'Override specific existing filters',
  add_group: 'Add as a new Filter Group',
  ignore: 'Ignore'
};

const PanelType = {
  ExplorerQuery: 'explorer_query',
  RawFlow: 'raw_flow',
  SynthTest: 'synth_test',
  Metrics: 'metrics'
};

const dataviewTypes = {
  [PanelType.ExplorerQuery]: DataViewModel,
  [PanelType.RawFlow]: RawFlowDataViewModel,
  [PanelType.SynthTest]: SyntheticsTestDataViewModel,
  [PanelType.Metrics]: MetricsExplorerDataViewModel
};

const panelTypeNeedsQueryId = {
  [PanelType.SynthTest]: true,
  [PanelType.Metrics]: true
};

class DashboardItemModel extends Model {
  @observable.ref
  lastQuery = undefined;

  @observable
  fetchedQuery = false;

  @observable
  selectedModels = [];

  @observable.ref
  dataview = new DataViewModel();

  // We want lastQuery to layer parametric upon, this includes parametric
  lastFullQuery;

  // The query model associated with the current preview while editing
  @observable.ref
  editQuery;

  get urlRoot() {
    return '/api/ui/dashboards/items';
  }

  get defaults() {
    return {
      x: 0,
      y: 0,
      w: 12,
      h: 4,

      minH: 2,
      minW: 3,

      device_locked: false,
      filter_source: 'additive',
      panel_title: 'New Panel',
      panel_type: PanelType.ExplorerQuery,
      parametric_mode: 'add_group',
      time_locked: false,
      dashboard_navigation: false,
      destination_dashboard: undefined
    };
  }

  get omitDuringSerialize() {
    return ['dashboard', 'queries', 'editActiveTab'];
  }

  get removalConfirmText() {
    return {
      title: 'Remove Panel',
      text: (
        <span>
          Are you sure you want to remove <strong>{this.dataview.title}</strong>?
        </span>
      )
    };
  }

  get messages() {
    const title = this.dataview.title || this.get('panel_title');

    return {
      create: `Panel "${title}" was added successfully`,
      update: `Panel "${title}" was updated successfully`,
      destroy: `Panel "${title}" was removed successfully`
    };
  }

  /**
   * Fetch the queries for this panel and then store them in a transient attribute
   */
  @action
  fetchSavedQuery = async () => {
    this.fetchedQuery = false;
    return getQueriesForHash(this.get('saved_query_id')).then(
      action((res) => {
        const queries = res.map((query) => QueryModel.create(query.query || query));
        this.set({ queries });

        // set query object if raw flow item
        if (res && res[0] && this.get('panel_type') === PanelType.RawFlow) {
          const rawFlowQuery = QueryModel.create(res[0]);
          this.set({ rawFlowQuery });
        }

        this.fetchedQuery = true;
      })
    );
  };

  @computed
  get formState() {
    const { formComponent, formConfig } = this.dataview;
    return {
      component: formComponent,
      fields: formConfig
    };
  }

  @action
  openDashboardItemConfigurationDialog = () => {
    this.select();
  };

  @action
  clearSelectedModels = () => {
    this.selectedModels = [];
    this.dataview.setSelectedModels([]);
  };

  @computed
  get panelDescription() {
    const dashboard = this.get('dashboard');
    const panel_description = this.get('panel_description') || '';
    const parametric_mode = this.get('parametric_mode');
    const parametric_overrides = this.get('parametric_overrides');

    if (dashboard) {
      return this.translatePlaceholders(
        panel_description,
        dashboard.get('parametric') && parametric_mode !== 'ignore',
        dashboard.get('parametric_fields')[0],
        parametric_overrides
      );
    }

    return panel_description;
  }

  @action
  updateQuery = (queryOptions) => {
    /*
     * Just keeps track of which query was run last, that way we can call `updateQuery` with no queryOptions and
     * force the dataView to update it's underlying query (when new parametric filters are applied, etc).
     */
    this.dataview.eachBucket('setLoading', [true]);
    if (this.dataview.viewType === 'generator') {
      this.dataview.queryBuckets.each((bucket) => bucket.queryResults.reset());
      this.dataview.component.clear();
    }
    if (queryOptions) {
      this.lastQuery = queryOptions;
    }

    const dashboard = this.get('dashboard');
    const panel_title = this.get('panel_title');
    const filter_source = this.get('filter_source');
    const parametric_mode = this.get('parametric_mode');
    const saved_query_id = this.get('saved_query_id');
    const parametric_overrides = this.get('parametric_overrides');
    const time_locked = this.get('time_locked');
    const device_locked = this.get('device_locked');
    const parametric_fields = dashboard.get('parametric_fields');
    const parametric = dashboard.get('parametric');
    const fieldsToExtract = ['tenantPreviewFilters'];

    if (!time_locked || queryOptions?.forceMaxRange) {
      fieldsToExtract.push(
        'lookback_seconds',
        'starting_time',
        'ending_time',
        'time_format',
        'use_alt_timestamp_field'
      );
    }

    if (!device_locked) {
      fieldsToExtract.push('all_devices', 'device_name', 'device_types', 'device_sites', 'device_labels');
    }

    if (filter_source !== 'locked') {
      fieldsToExtract.push('filters');
    }

    // deep clone to avoid filter clashing across panels
    const query = deepClone(pick(queryOptions || this.lastQuery || dashboard.get('query'), fieldsToExtract));
    query.query_title = this.translatePlaceholders(
      panel_title,
      parametric && parametric_mode !== 'ignore',
      parametric_fields[0],
      parametric_overrides
    );

    const shouldOverlayFilters = filter_source === 'additive';
    const syncDepth = true;

    if (this.dataview.lastUpdated) {
      this.dataview.clear();
    }

    this.dataview.resetTimeRange(true);

    if (this.get('panel_type') === PanelType.RawFlow) {
      this.dataview.setTitle(panel_title);
    }

    if (this.get('panel_type') === PanelType.SynthTest) {
      this.dataview.setTitle(panel_title);
      const overrides = {};
      // queryOptions will be undefined if we got here via an item save vs a dashboard save
      // in that case the lookback is part of the saved_query_id even if we're using the dashboard's lookback
      if (time_locked && queryOptions) {
        const { lookback_seconds, starting_time, ending_time } = queryOptions;
        Object.assign(overrides, { lookback_seconds, starting_time, ending_time });
      }
      this.dataview.initializeHashWithOverrides(saved_query_id, overrides);
    } else if (parametric && parametric_overrides) {
      const { filterField } = parametric_overrides;
      let { operator } = parametric_overrides;
      // Although we have certain parametric fields that should not allow a blank value, we currently have no way to determine
      // which should receive that treatment. For now, the UI should allow a blank value to be specified as a filter value
      let filterValue = parametric_fields[0].value;

      if (filterValue === undefined || filterValue === null) {
        filterValue = dashboard.get('parametric_value');
      }

      if (!operator) {
        operator = $dictionary.dictionary.queryFilters.parametricOperators[dashboard.parametricField.type];
      }

      if (parametric_mode === 'override_all') {
        const filtersObj = getDefaultFiltersObject();
        addFilterGroup(filtersObj);
        addFilter(filtersObj, filterField, operator, filterValue);

        const overrides = {
          filters: filtersObj
        };
        this.dataview.initializeHashWithOverrides(saved_query_id, query, {
          syncDepth,
          overlayFilters: true,
          parametricOverrides: overrides
        });
      } else if (parametric_mode === 'add_group') {
        query.filters = addFilterGroup(query.filters || getDefaultFiltersObject());
        addFilter(query.filters, filterField, operator, filterValue, query.filters.filterGroups.length - 1);

        this.dataview.initializeHashWithOverrides(saved_query_id, query, {
          syncDepth,
          overlayFilters: shouldOverlayFilters
        });
      } else if (parametric_mode === 'override_specific') {
        const overrides = {
          specific: true,
          filterField,
          operator,
          filterValue
        };
        this.dataview.initializeHashWithOverrides(saved_query_id, query, {
          syncDepth,
          overlayFilters: true,
          parametricOverrides: overrides
        });
      } else {
        this.dataview.initializeHashWithOverrides(saved_query_id, query, {
          syncDepth,
          overlayFilters: shouldOverlayFilters
        });
      }
    } else {
      this.dataview.initializeHashWithOverrides(saved_query_id, query, {
        syncDepth,
        overlayFilters: shouldOverlayFilters
      });
    }

    this.lastFullQuery = query;
  };

  @action
  refresh = () => {
    if (panelTypeNeedsQueryId[this.get('panel_type')]) {
      this.dataview.refresh(this.get('saved_query_id'));
    } else {
      this.dataview.refresh();
    }
  };

  @action
  clear() {
    this.dataview.destroy();
  }

  @action
  reflow() {
    this.dataview.reflow();
  }

  @action
  setUpdateFrequency = (frequency) => {
    this.dataview.setUpdateFrequency(frequency);
  };

  @computed
  get fullyLoaded() {
    return this.dataview && this.dataview.fullyLoaded;
  }

  @computed
  get queryBuckets() {
    return this.dataview && this.dataview.queryBuckets.activeBuckets;
  }

  @computed
  get parametricModeDisplay() {
    return parametricModes[this.get('parametric_mode')];
  }

  @computed
  get layoutData() {
    const { id, w, x, y, h, minW, minH } = this.get();

    return {
      lg: {
        i: `${id}`,
        x,
        y,
        w,
        h,
        minH,
        minW
      },
      sm: {
        i: `${id}`,
        x,
        y,
        w: 1,
        h,
        minH,
        minW: 1
      }
    };
  }

  getEditQuery() {
    return this.editQuery || this.queryBuckets[0].firstQuery;
  }

  deserialize(data) {
    if (!data) {
      return data;
    }

    data = super.deserialize(data);
    const {
      dashboard_id,
      parametric_overrides,
      filters,
      saved_filters,
      dashboard_navigation_options: navOptions
    } = data;

    let { dashboard } = data;
    if (!dashboard) {
      dashboard = $dashboards.collection.get(dashboard_id);
    }

    // add parametric_overrides.operator if it doesn't exist
    if (dashboard && dashboard.isParametric && parametric_overrides && !parametric_overrides.operator) {
      const {
        dictionary: { queryFilters }
      } = $dictionary;
      parametric_overrides.operator = queryFilters.parametricOperators[dashboard.parametricField.type];
    }

    if (saved_filters && saved_filters.length > 0) {
      data.filters = injectLegacySavedFilters(saved_filters, filters);
      delete data.saved_filters;
    }

    if (navOptions && navOptions.filters === 'custom') {
      const { filters: customFilters, saved_filters: navSavedFilters } = navOptions.custom;

      if (navSavedFilters && navSavedFilters.length > 0) {
        navOptions.custom.filters = injectLegacySavedFilters(navSavedFilters, customFilters);
        delete navOptions.custom.saved_filters;
      }
    }

    if (navOptions && !navOptions.nesting) {
      navOptions.nesting = {
        drillDownButtonText: 'Drill Down'
      };
    }

    const DashboardPanelDataView = dataviewTypes[data.panel_type] || DataViewModel;
    const { viewModel } = data;
    this.dataview = new DashboardPanelDataView(viewModel || undefined);
    return data;
  }

  translatePlaceholders(title, parametric, parametricField, parametricOverrides = {}) {
    const valueRe = /{{filter_value}}/g;
    const labelRe = /{{filter_dimension}}/g;

    const filterField = parametricOverrides && parametricOverrides.filterField;

    let label = '';
    let value = '';
    if (parametric) {
      const filterLabel = $dictionary.flatFilterLabels.find((filter) => filter.value === filterField);
      if (filterLabel) {
        label = filterLabel.label;
      }
      value = parametricField.value;
    }

    return title.replace(valueRe, value).replace(labelRe, label);
  }

  /**
   * For the current query associated with this dashboard item (from preview or what's saved), determine whether
   * its results can be translated into a filter for the given parametric_field.
   *
   * Examples:
   *   (query: { metric: ['kt_src_as_group', 'site'] }, parametric: 'as_number') => true
   *   (query: { metric: ['dst_route_prefix_len'] }, parametric: 'ip') => false
   *
   * @param parametric_field
   * @returns {boolean}
   */
  canMapToParametricKey(parametric_field) {
    const query = this.getEditQuery().serialize();

    const { metricToFilterParsers } = $dictionary.get('queryFilters');
    const { filterFields } =
      $dictionary.parametricDashboardFilterOptions.find((option) => option.type === parametric_field.type) || {};

    if (!filterFields || query.metric.length === 0 || query.generatorMode) {
      return false;
    }

    return query.metric.some((metric) => {
      const metricParsers = metricToFilterParsers[metric] || [];

      // Because you can only have one parametric field, the metric cannot translate into two fields
      if (metricParsers.length > 1) {
        return false;
      }

      // If there is exactly one filter mapping, then see if it's one of the acceptable fields
      if (metricParsers.length === 1) {
        return metricParsers.find((metricParser) => filterFields.includes(metricParser.field)) !== undefined;
      }

      // Otherwise there's no mapping and it's a simple 'includes'
      return filterFields.includes(metric);
    });
  }

  getParametricKeyFromModel(model, parametric_field, queryBucketOverride) {
    const dictionaryOption = $dictionary.parametricDashboardFilterOptions.find(
      (option) => option.type === parametric_field.type
    );

    if (!dictionaryOption) {
      return undefined;
    }

    let matchedFilter;
    const filtersObj = getModelFilters({
      model,
      bucket: queryBucketOverride || this.queryBuckets[0],
      filtersAcc: getDefaultFiltersObject()
    });

    if (filtersObj.filterGroups && filtersObj.filterGroups.length > 0) {
      let filterGroup = filtersObj.filterGroups[0]; // use the first filter group

      if (
        (!filterGroup.filters || filterGroup.filters.length === 0) &&
        filterGroup.filterGroups &&
        filterGroup.filterGroups.length > 0
      ) {
        [filterGroup] = filterGroup.filterGroups; // group has no filters but has a nested filter group
      }

      matchedFilter =
        filterGroup.filters &&
        filterGroup.filters.find((filter) => dictionaryOption.filterFields.includes(filter.filterField));
    }

    return matchedFilter ? matchedFilter.filterValue : undefined;
  }
}

export default DashboardItemModel;
