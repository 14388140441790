import { observable, action } from 'mobx';

import { showInfoToast } from 'core/components';
import { api, Socket, getPortalVersion } from 'core/util';

class MessagesStore {
  messages = observable.array([], { deep: false });

  newVersionToastVisible = false;

  timerId;

  socket;

  @action
  initialize() {
    this.fetchMessages();

    // Check for new messages every 1 minute
    this.timerId = setInterval(() => this.fetchHealthCheck(), 60000);

    this.socket = new Socket({
      outType: 'portalMessages',
      inType: 'portalMessages',
      frequency: 60,
      delaySend: true,
      listenToBroadcast: true,
      onSuccess: this.onPollMessageResponse,
      onError(err) {
        console.warn('Received Portal Messages Socket Error', err);
      },
      onReconnect: () => {
        this.socket.send();
      }
    });
  }

  destroy() {
    if (this.timerId) {
      clearInterval(this.timerId);
    }
  }

  @action
  fetchMessages() {
    api.get('/api/ui/messages', { showErrorToast: false }).then((response) => {
      if (response && response.length > 0) {
        this.messages.push(...response);
      }
      this.socket.send();
    });
  }

  @action
  fetchHealthCheck() {
    getPortalVersion().then(({ hasOutdatedBundle }) => {
      if (hasOutdatedBundle && !this.newVersionToastVisible) {
        showInfoToast('A new version of Kentik is available.', {
          title: 'Update Available!',
          timeout: 0,
          action: {
            onClick: () => window.location.reload(true),
            text: 'Reload'
          },
          onDismiss: this.handleNewVersionToastClose
        });

        this.newVersionToastVisible = true;
      }
    });
  }

  @action
  onPollMessageResponse = (data) => {
    if (this.store.$auth.activeUser.email_service && data && data.length > 0) {
      this.messages.push(...data);
    }
  };

  handleNewVersionToastClose = () => {
    this.newVersionToastVisible = false;
  };

  @action
  dismissMessages = () => {
    this.messages.clear();
  };
}

export default new MessagesStore();
