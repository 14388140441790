import { action, computed, observable } from 'mobx';

import { api } from 'core/util';

import $auth from 'app/stores/$auth';
import RbacRoleCollection from './RbacRoleCollection';
import RbacRoleUsersCollection from './RbacRoleUsersCollection';
import RbacRoleSetCollection from './RbacRoleSetCollection';

class RbacStore {
  collection = new RbacRoleCollection();

  roleSetCollection = new RbacRoleSetCollection();

  @observable
  selectedRbacRoleUsersCollection = undefined;

  @observable
  hoveredRbacRole = undefined;

  @observable
  hoveredRbacPermission = undefined;

  @observable
  globalRbacPermissions = { groups: [] };

  @observable
  flatPermissions = new Set();

  @observable
  loading = false;

  initialize() {
    setTimeout(() => Promise.all([this.collection.fetch(), this.fetchGlobalRbacPermissions()]), 0);
  }

  @action
  setSelectedRbacRoleUsersCollection(roleId) {
    if (!roleId || roleId === null) {
      this.selectedRbacRoleUsersCollection = undefined;
      return;
    }

    this.selectedRbacRoleUsersCollection = new RbacRoleUsersCollection(roleId);
    this.selectedRbacRoleUsersCollection.fetch();
  }

  @action
  setLoading(isLoading = true) {
    this.loading = isLoading;
  }

  @action
  setHoveredRbacRole(hoveredRbacRole) {
    this.hoveredRbacRole = hoveredRbacRole;
  }

  @action
  setHoveredRbacPermission(hoveredRbacPermission) {
    this.hoveredRbacPermission = hoveredRbacPermission;
  }

  @action
  setGlobalRbacPermissions(data) {
    this.globalRbacPermissions = data;
  }

  @computed
  get hasSuperAdmin() {
    return (
      this.collection
        .find((role) => role.get('role_name') === 'Super Administrators' && !role.get('company_id'))
        ?.get('count') > 0
    );
  }

  getPermissionNameFromKey(key) {
    let name;

    this.globalRbacPermissions.groups.find((group) => {
      const matchedPermission = group.permissions.find((permission) => permission.permission === key);
      if (matchedPermission) {
        name = `${group.name} > ${matchedPermission.display}`;
        return true;
      }
      return false;
    });

    return name;
  }

  fetchUserRbacPermissions(userId) {
    return api.get(`/api/ui/rbac/user/permissions/${userId}`);
  }

  fetchGlobalRbacPermissions() {
    this.setLoading(true);

    return api.get('/api/ui/rbac/permissions').then((data) => {
      /**
       *   {
       *     groups: [
       *       {
       *         name: "ABC Things",
       *         permissions:[
       *           { permission: "x.y::z", display: "Allows users to ABC" }
       *         ]
       *       }
       *     ]
       *   }
       * */
      this.setGlobalRbacPermissions(data);
      this.setLoading(false);
      this.flatPermissions = new Set(data.groups.flatMap((group) => group.permissions.map((perm) => perm.permission)));
      return data;
    });
  }

  addUserToRole(user_id, role_id) {
    this.setLoading(true);

    return api.put(`/api/ui/rbac/roles/${role_id}/assignUser`, { body: { user_id } }).then(() => {
      this.setLoading(false);
    });
  }

  removeUserFromRole(user_id, role_id) {
    this.setLoading(true);

    return api.del(`/api/ui/rbac/roles/${role_id}/removeUser`, { body: { user_id } }).then(() => {
      this.setLoading(false);
    });
  }

  bulkAddUsersToRole(users = [], role_id) {
    this.setLoading(true);

    return api.put(`/api/ui/rbac/roles/${role_id}/assignUserBulk`, { body: { users } }).then(() => {
      this.setLoading(false);
    });
  }

  bulkAddUsersToRoleSet(users = [], role_set_id) {
    this.setLoading(true);

    return api.put(`/api/ui/rbac/role-sets/${role_set_id}/assignUserBulk`, { body: { users } }).then(() => {
      this.setLoading(false);
    });
  }

  bulkRemoveUsersFromRole(users = [], role_id) {
    this.setLoading(true);

    return api.del(`/api/ui/rbac/roles/${role_id}/removeUserBulk`, { body: { users } }).then(() => {
      this.setLoading(false);
    });
  }

  bulkRemoveUsersFromRoleSet(users = [], role_set_id) {
    this.setLoading(true);

    return api.put(`/api/ui/rbac/role-sets/${role_set_id}/removeUserBulk`, { body: { users } }).then(() => {
      this.setLoading(false);
    });
  }

  addPermissionToRole(role_id, permission) {
    this.setLoading(true);

    return api.put(`/api/ui/rbac/roles/${role_id}/addPermission`, { body: { permission } }).then(() => {
      this.setLoading(false);
    });
  }

  removePermissionFromRole(role_id, permission) {
    this.setLoading(true);

    return api.put(`/api/ui/rbac/roles/${role_id}/removePermission`, { body: { permission } }).then(() => {
      this.setLoading(false);
    });
  }

  editRole(role_id, { role_name, role_description, permissions, labels }) {
    this.setLoading(true);

    return api
      .put(`/api/ui/rbac/roles/${role_id}`, {
        body: { role_name, role_description, permissions, label_metadata: labels || {} }
      })
      .then(() => {
        this.setLoading(false);
      });
  }

  createRole({ role_name, role_description, permissions, labels }) {
    this.setLoading(true);
    return api
      .post('/api/ui/rbac/roles', { body: { role_name, role_description, permissions, label_metadata: labels || {} } })
      .then((model) => {
        this.setLoading(false);
        this.collection.add(model);
        return model;
      });
  }

  // @computed
  get managedRoles() {
    if (!this.collection.hasFetched) {
      return [];
    }
    return this.collection.filter((role) => !role.get('company_id'), { immutable: true });
  }

  // @computed
  get customRoles() {
    if (!this.collection.hasFetched) {
      return [];
    }
    return this.collection.filter((role) => role.get('company_id'), { immutable: true });
  }

  @computed
  get roleOptions() {
    if (!this.collection.hasFetched) {
      return [];
    }
    return this.collection.map((role) => ({ value: role.id, label: role.get('role_name') }));
  }

  getRoleModel({ roleId, force = false }) {
    if (force || !this.collection.hasFetched) {
      return this.collection.fetch({ force }).then(() => this.collection.get(roleId));
    }
    return Promise.resolve(this.collection.modelById[roleId]);
  }

  getRoleSetModel({ roleSetId, force = false }) {
    if (force || !this.roleSetCollection.hasFetched) {
      return this.roleSetCollection.fetch({ force }).then(() => this.roleSetCollection.get(roleSetId));
    }
    return Promise.resolve(this.roleSetCollection.modelById[roleSetId]);
  }

  navigateToRole = (roleId) => {
    this.history.replace(`/v4/settings/rbac/roleDetails/${roleId}`);
  };

  redirectToRoleSet = (roleSetId) => {
    this.history.replace(`/v4/settings/rbac/roleSetDetails/${roleSetId}`);
  };

  navigateToRbacRoles = () => {
    this.history.push('/v4/settings/rbac/roles');
  };

  navigateToRbacRoleSets = () => {
    this.history.push('/v4/settings/rbac/role-sets');
  };

  async fetchRoleSetSelectOptions() {
    if (!this.roleSetCollection.hasFetched) {
      return this.roleSetCollection.fetch().then(() =>
        this.roleSetCollection.map((roleSet) => ({
          value: roleSet.id.toString(),
          label: roleSet.get('name')
        }))
      );
    }

    return Promise.resolve(
      this.roleSetCollection.map((roleSet) => ({
        value: roleSet.id.toString(),
        label: roleSet.get('name')
      }))
    );
  }

  /**
   * For a given set of RBAC roles, checks whether you have all roles without any restrictions associated.
   * @param permissions {import('@kentik/ui-shared/rbac/constants').RBACString[]}
   * @returns {boolean}
   */
  hasUnrestrictedPermissions(permissions) {
    const userRbacPermissions = $auth.getActiveUserProperty('rbacPermissions') || [];
    const userRbacMetadata = $auth.getActiveUserProperty('label_metadata') || {};
    return permissions.every(
      (permission) =>
        // do not trigger permission restrictions when there's a feature flag (or typo)
        !this.flatPermissions.has(permission) ||
        // otherwise we check to ensure that user has the permission and does not have label restrictions associated
        (userRbacPermissions.includes(permission) && !userRbacMetadata[permission])
    );
  }
}

const $rbac = new RbacStore();

export default $rbac;
