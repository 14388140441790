import Collection from 'core/model/Collection';

import NetworkHealthTestModel from './NetworkHealthTestModel';

class NetworkHealthAgentCollection extends Collection {
  get url() {
    return '/api/ui/network-availability/agent';
  }

  get model() {
    return NetworkHealthTestModel;
  }

  get asnCount() {
    const asns = new Set();
    this.models.forEach((model) => asns.add(model.get('asn').id));
    return asns.size;
  }

  get countryCount() {
    const countries = new Set();
    this.models.forEach((model) => countries.add(model.get('geo').country.code));
    return countries.size;
  }

  get cityCount() {
    const cities = new Set();
    this.models.forEach((model) => cities.add(model.get('geo').city.id));
    return cities.size;
  }
}

export default NetworkHealthAgentCollection;
