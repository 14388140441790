import { action, computed, observable } from 'mobx';

import Model from 'core/model/Model';
import $lookups from 'app/stores/$lookups';
import $devices from 'app/stores/device/$devices';
import { SITE_TYPES } from 'app/util/constants';

class Site extends Model {
  @observable
  countryName;

  get urlRoot() {
    return '/api/ui/sites';
  }

  get sortValues() {
    return {
      country: (model) => model.countryName
    };
  }

  @computed
  get siteLocation() {
    const location = [];
    ['address', 'city', 'region', 'postal', 'countryName'].forEach((field) => {
      const value = this[field] || this.get(field);
      if (value) {
        location.push(value);
      }
    });
    return location.join(', ');
  }

  @computed
  get type() {
    const { type } = this.get('metadata');
    return SITE_TYPES[type] || 'Other';
  }

  @computed
  get devices() {
    const id = this.get('id');
    return $devices.activeDeviceSummaries.filter((d) => d.site_id === id);
  }

  @computed
  get numDevices() {
    return this.devices && this.devices.length > 1 ? `${this.devices.length} Devices` : '1 Device';
  }

  @computed
  get hasCloudDevices() {
    return this.devices.some((device) => !!device.cloud_export_id);
  }

  @computed
  get siteType() {
    const metadata = this.get('metadata');
    const siteTypes = {
      dataCenter: 'Data Center',
      cloud: 'Cloud',
      branch: 'Branch/Building',
      connectivity: 'Connectivity PoP',
      customer: 'Customer/Partner',
      other: 'Other'
    };

    return (metadata && metadata.type && siteTypes[metadata.type]) || 'Site';
  }

  save(attributes = {}, options) {
    if (attributes.title) {
      attributes.title = attributes.title.trim();
    }

    const onSuccess = (success) => {
      this.doLookups();
      return success;
    };

    return super.save({ ...attributes }, options).then(onSuccess);
  }

  @action
  doLookups() {
    const country = this.get('country');

    if (country) {
      $lookups.countries(country).then(
        action((countries) => {
          const countryOption = countries.find((option) => option.value === country);
          this.countryName = countryOption ? countryOption.label : country;
          this.setLastUpdated();
        })
      );
    } else {
      this.countryName = '';
      this.setLastUpdated();
    }
  }

  set(data) {
    super.set(data);
    this.doLookups();
  }
}

export default Site;
