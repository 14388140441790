import api from 'core/util/api';
import { action, observable } from 'mobx';

// data is the nested tree data, but we don't need to send it all.
function cleanupFpaResultForLLM(
  obj,
  keysToRemove = ['filters', 'color', 'values', 'dimensionsRaw', 'dimensions', 'level']
) {
  if (Array.isArray(obj)) {
    obj.forEach((item) => cleanupFpaResultForLLM(item, keysToRemove));
  } else if (typeof obj === 'object' && obj !== null) {
    for (const key in obj) {
      if (keysToRemove.includes(key)) {
        delete obj[key];
      } else {
        cleanupFpaResultForLLM(obj[key], keysToRemove);
      }
    }
  }
  return obj;
}

class FpaStore {
  @observable
  isRunningFrequentPatternAnalysis = false;

  @action
  async getFrequentPatternAnalysisSummary(data, additionalContext = undefined) {
    const cleanData = cleanupFpaResultForLLM(data.children);

    const request = {
      body: { data: cleanData, additionalContext }
    };

    return api.post('/api/ui/fpa/frequentPatternAnalysisSummary', request).then((response) => response);
  }
}

export default new FpaStore();
