import { action } from 'mobx';
import Collection from 'core/model/Collection';
import CloudConnectivityReport from './CloudConnectivityReport';

export default class CloudConnectivityReportCollection extends Collection {
  get url() {
    return '/api/ui/cloudConnectivityReport';
  }

  @action
  async fetchByCloud(cloud) {
    return super.fetch({ query: { cloud } });
  }

  get model() {
    return CloudConnectivityReport;
  }

  get defaultSortState() {
    return {
      field: 'id',
      direction: 'desc'
    };
  }

  get removalConfirmPopoverProps() {
    return {
      confirmText: 'Remove Pathfinder Report',
      confirmBodyContent: `Are you sure you want to remove ${this.get('name')}?`
    };
  }
}
