import { action, computed, observable } from 'mobx';
import moment from 'moment';
import { AGENT_MATRIX_TEST_TYPES } from 'shared/synthetics/constants';
import { TEST_TYPES } from 'app/util/constants';
import { processPreviewTestResults } from 'app/views/synthetics/utils/syntheticsUtils';
import { enrichTracerouteLookups } from './utils';
import TestResultsState from './TestResultsState';

export default class PreviewTestResultsState extends TestResultsState {
  get defaults() {
    return {
      ...super.defaults,
      previewPollingTestResults: false, // indicates we are still attempting to load preview test results
      previewPollingTraceResults: false, // indicates we are still attempting to load preview trace results
      previewPollTestResultTimeout: null, // timer for fetching preview test results
      previewPollTraceResultTimeout: null, // timer for fetching preview trace results
      previewPollCountTestResults: 0, // keeps count of the number of polls we've placed for preview test results --- we max out at 10
      previewPollCountTraceResults: 0, // keeps count of the number of polls we've placed for preview trace results --- we max out at 10
      testResultsTaskCount: 0, // keeps count of the number of tasks that have fulfilled for preview test results --- when all tasks have data (or the max attempt acheived), polling is stopped
      traceResultsTaskCount: 0 // keeps count of the number of traces that have fulfilled for preview trace results --- when all traces have data (or the max attempt acheived), polling is stopped
    };
  }

  @observable
  previewPollingTestResults = this.defaults.previewPollingTestResults;

  @observable
  previewPollingTraceResults = this.defaults.previewPollingTraceResults;

  previewPollTestResultTimeout = this.defaults.previewPollTestResultTimeout;

  previewPollTraceResultTimeout = this.defaults.previewPollTraceResultTimeout;

  previewPollCountTestResults = this.defaults.previewPollCountTestResults;

  previewPollCountTraceResults = this.defaults.previewPollCountTraceResults;

  testResultsTaskCount = this.defaults.testResultsTaskCount;

  traceResultsTaskCount = this.defaults.traceResultsTaskCount;

  @computed
  get results() {
    const { resultTime, resultsTs, loading, previewPollingTestResults } = this;

    if (loading && !previewPollingTestResults) {
      return null;
    }

    return resultsTs[resultTime] || null;
  }

  @computed
  get mesh() {
    const { results, hasResults } = this;
    const mesh = [];
    const { start_time: eDate } = this.previewDateObj();

    if (hasResults && results.mesh) {
      const rowKeys = Object.keys(results.mesh);
      rowKeys.sort((a, b) => results.mesh[a].name?.localeCompare(results.mesh[b].name));

      for (let i = 0; i < rowKeys.length; i += 1) {
        const row = results.mesh[rowKeys[i]];
        const columnKeys = Object.keys(row.columns);
        columnKeys.sort((a, b) => row.columns[a].name?.localeCompare(row.columns[b].name));
        const columns = [];

        for (let j = 0; j < columnKeys.length; j += 1) {
          const column = row.columns[columnKeys[j]];
          let meshHealth = Object.values(column.health);

          // since we are polling for results in test preview, we can never have a guarantee of which timestamps will have results
          // in this case, we'll check the health for the given column, take the first that has results, then write it to the selected timestamp
          const relevantHealth = meshHealth.find((h) => +h.time >= eDate);

          if (relevantHealth) {
            meshHealth = [{ ...relevantHealth, time: this.resultTime.toString() }];
          }

          columns.push({ ...column, health: meshHealth });
        }

        mesh.push({ ...row, columns });
      }

      return mesh;
    }
    return undefined;
  }

  // used during test preview, this determines the expected number of traces we can expect to receive for the test
  // the expected count will only use currently online agents to formulate the count --- this helps prevent excess requests for traces that we know will never arrive
  @computed
  get expectedTraceCount() {
    const { test } = this;
    const { $syn } = this.store;
    const testConfig = test.get('config');
    // only count the agents that are online, otherwise we're guaranteed to cycle through all 10 preview trace count attempts for no reason
    const onlineAgents = (testConfig.agents || []).filter((agentId) => {
      const agentModel = $syn.agents.get(agentId);
      return agentModel?.status?.offline === false;
    });
    const agentCount = onlineAgents.length;
    const target = testConfig.target?.value;
    let targetCount = 0;

    if (typeof target === 'string') {
      targetCount = target.split(',').length;
    } else if (Array.isArray(target)) {
      targetCount = target.length;
    }

    let expectedCount = 0;

    if (agentCount) {
      expectedCount = AGENT_MATRIX_TEST_TYPES.includes(test.get('test_type'))
        ? agentCount * (agentCount - 1)
        : agentCount * targetCount;
    }

    return expectedCount;
  }

  configure({ startDate, endDate, lookbackSeconds, history, ...displayOptions } = {}) {
    const { test } = this;
    const { hasPing } = test;
    const isDns = test.get('test_type') === TEST_TYPES.DNS;

    if (!this.init) {
      Object.entries(displayOptions).forEach(([key, value]) => {
        if (this.displayOptions[key] !== undefined) {
          this.displayOptions[key] = value;
        }
      });

      this.displayOptions.allowMesh = !isDns;
      this.displayOptions.allowSankey = !isDns;
      this.displayOptions.allowMap = !isDns || !this.store.$app.isExport;
      this.displayOptions.allowPath = hasPing;
      this.displayOptions.allowHealthTimeline = false;
      this.displayOptions.allowBgp = false;
      this.displayOptions.allowAgentDetails = false;
      this.displayOptions.allowSettings = false;
      this.displayOptions.allowShare = false;
      this.displayOptions.allowExport = false;
      this.displayOptions.allowSummary = false;
      this.history = history;

      this.setupPreviewPolling();
    }
  }

  @action
  setupPreviewPolling() {
    const { allowPath } = this.displayOptions;
    const delay = moment(this.test.get('edate')).unix() < moment().unix() - 30 ? 0 : 30000;

    this.newRequestID();

    // when we fire up preview polling, the test results are in a loading state
    // and we also have a loading state specific to preview (previewPollingTestResults)
    // this preview-specific loading state will display as a spinner in the applicable tab
    this.loadingResults = true;
    this.previewPollingTestResults = true;

    // start polling for test results
    this.previewPollTestResultTimeout = setTimeout(() => {
      this.previewPollTestResults();
    }, delay);

    if (allowPath) {
      // same loading state logic as above
      this.loadingTraceResults = true;
      this.previewPollingTraceResults = true;

      // start polling for trace results
      this.previewPollTraceResultTimeout = setTimeout(() => {
        this.previewPollTraceResults();
      }, delay);
    }
  }

  // preview query window will start from the test's edit date
  previewDateObj() {
    return {
      start_time: moment(this.test.get('edate')).startOf('minute').unix(),
      end_time: moment().add(1, 'minute').startOf('minute').unix()
    };
  }

  previewPollTestResults() {
    this.fetchPreviewTestResults(this.previewDateObj());
  }

  previewPollTraceResults() {
    this.fetchTraceResults(this.previewDateObj());
  }

  keepPreviewPollingTestResults() {
    this.keepPolling({
      timeoutKey: 'previewPollTestResultTimeout',
      pollingKey: 'previewPollingTestResults',
      loadingKey: 'loadingResults',
      countKey: 'previewPollCountTestResults',
      fnKey: 'previewPollTestResults',
      shouldInitOnFail: true
    });
  }

  keepPreviewPollingTraceResults() {
    this.keepPolling({
      timeoutKey: 'previewPollTraceResultTimeout',
      pollingKey: 'previewPollingTraceResults',
      loadingKey: 'loadingTraceResults',
      countKey: 'previewPollCountTraceResults',
      fnKey: 'previewPollTraceResults'
    });
  }

  @action
  keepPolling({ timeoutKey, pollingKey, loadingKey, countKey, fnKey, shouldInitOnFail = false }) {
    this[countKey] += 1;

    if (this[countKey] < 10) {
      this[timeoutKey] = setTimeout(() => {
        this[fnKey]();
      }, 10000);
    } else {
      this[pollingKey] = false;
      this[loadingKey] = false;

      if (shouldInitOnFail) {
        this.init = true;
      }
    }
  }

  @action
  fetchPreviewTestResults({ start_time, end_time }) {
    const { $syn } = this.store;
    const { requestId, test } = this;

    this.loadingResults = true;

    $syn.requests.fetchTestResults({ start_time, end_time, ids: [test.id] }, { preset: false }).then(
      action((resp) => {
        // check if request is relevant before storing response
        if (requestId === this.requestId) {
          const results = resp?.tests_health?.[test.id] || null;
          const tsKeys = Object.keys(results?.health_ts || {});

          if (results && tsKeys.length > 0) {
            // process the results, filling in data for tasks where present
            const { taskCount, expectedTaskCount, previewResults, ts } = processPreviewTestResults({
              $syn,
              results,
              eDate: start_time
            });

            if (taskCount !== this.testResultsTaskCount) {
              // keep a count of the completed tasks --- the main use for this is to help re-render meshes when applicable
              this.testResultsTaskCount = taskCount;
              this.init = true;

              // set the results and selected timestamp
              this.resultsTs[ts] = previewResults;
              this.resultTime = ts;
              // helps satisfy our requirements for determining a loaded state
              this.timestamps = [ts.toString()];

              // we can now disable the main loading state
              // this will allow results to show
              // those results will be partial but we're allowed to watch as polling fills them in
              this.loadingResults = false;
            }

            if (taskCount < expectedTaskCount) {
              // after we process the results, we get a count of the number of tasks that have been fulfilled out of those expected to be filled
              // if we have more tasks to complete, continue polling for new data to fill in
              this.keepPreviewPollingTestResults();
            } else {
              // the expected task count and fulfilled task count match
              // we have complete data and can now disable the preview loading state
              this.previewPollingTestResults = false;
            }
          } else {
            // no data yet, keep polling
            this.keepPreviewPollingTestResults();
          }
        }
      })
    );
  }

  @action
  fetchTraceResults(dateObj) {
    const { $syn } = this.store;
    const { requestId, test } = this;
    const { id, isPreset } = test;
    const { allowPath } = this.displayOptions;

    if (allowPath) {
      this.loadingTraceResults = true;

      $syn.requests.fetchTestTraceroutee(id, { ...dateObj, id, preview: true }, { preset: isPreset }).then(
        action((traceResults) => {
          // check if request is relevant before storing response
          if (requestId === this.requestId) {
            const hasTraceResults = this.checkForTraceResults(traceResults);

            if (hasTraceResults) {
              const { combinations: traceCount } = traceResults;
              const { trace_ts = {} } = traceResults?.results || {};
              const tsKeys = Object.keys(trace_ts);
              const timeMs = +tsKeys[tsKeys.length - 1] * 1000;

              traceResults.lookups = enrichTracerouteLookups(traceResults.lookups, $syn);

              if (traceCount !== this.traceResultsTaskCount) {
                // similar logic here as preview test results
                // we keep a count of completed traces, store our first batch of results and selected timestamp,
                // and disable loading so we can see results fill in when polling
                this.traceResultsTaskCount = traceCount;
                this.traceResults = traceResults;
                this.traceResultTimeMs = timeMs;
                this.loadingTraceResults = false;
                this.hasTraceResults = hasTraceResults;
              }

              if (traceCount < this.expectedTraceCount) {
                // we still haven't met our expected trace count, keep polling
                this.keepPreviewPollingTraceResults();
              } else {
                // we have all the trace data that we expect, disable the preview loading state
                this.previewPollingTraceResults = false;
              }
            } else {
              // no data yet, keep polling
              this.keepPreviewPollingTraceResults();
            }
          }
        })
      );
    }
  }
}
