import { action, computed } from 'mobx';

import api from 'core/util/api';
import { modelFinder } from 'app/stores/util/modelFinder';
import SiteModel from './SiteModel';
import SiteCollection from './SiteCollection';

export class SiteStore {
  collection = new SiteCollection();

  initialize() {
    setTimeout(() => this.collection.fetch(), 0);
  }

  navigateToSite = (siteId) => {
    this.history.push(`/v4/core/quick-views/sites/${siteId}`);
  };

  createSite = (siteAttributes, options) => {
    const site = this.collection.forge();
    return site.save(siteAttributes, options);
  };

  /**
   * This is only private to make test mocks easier.
   * @returns {function(name: string): import('./SiteModel').default | undefined}
   * @private
   */
  @computed
  get _byName() {
    return modelFinder(this.collection, 'title');
  }

  /**
   * @returns {function(name: string): import('./SiteModel').default | undefined}
   */
  byName(name) {
    return this._byName(name);
  }

  @action
  fetchSite = (siteId) => {
    const site = new SiteModel({ id: siteId });

    return site.fetch().then(() => site);
  };

  @computed
  get noSitesConfigured() {
    return this.collection.unfilteredSize === 0;
  }

  @computed
  get numSites() {
    return this.collection.unfilteredSize;
  }

  getSiteOption = (model) => ({
    id: model.id,
    value: model.id,
    label: model.get('title'),
    devices: model.devices,
    activeDevices: model.devices.filter((device) => device.device_status === 'V'),
    numDevices: model.devices.length
  });

  @computed
  get siteOptions() {
    if (!this.collection.hasFetched) {
      return [];
    }

    return this.collection.map(this.getSiteOption).sort((a, b) => a.label.localeCompare(b.label));
  }

  @computed
  get siteOptionsMap() {
    const hashMap = {};

    this.collection.models.forEach((model) => {
      hashMap[model.id] = this.getSiteOption(model);
    });

    return hashMap;
  }

  // Some customers have a lot of devices. If you only need labels then this map will be much faster
  @computed
  get siteLabelsMap() {
    const hashMap = {};

    this.collection.models.forEach((model) => {
      hashMap[model.id] = {
        id: model.id,
        value: model.id,
        label: model.get('title')
      };
    });

    return hashMap;
  }

  @computed
  get siteTitleOptions() {
    if (!this.collection.hasFetched) {
      return [];
    }

    return this.collection.map((site) => ({
      id: site.id,
      value: site.get('title'),
      label: site.get('title')
    }));
  }

  @computed
  get sitesGeoMap() {
    return this.collection.reduce((acc, model) => {
      const { id, lat, lon, city, country, region } = model.get();

      acc[id] = {
        city: {
          latitude: lat,
          longitude: lon,
          name: city
        },
        country: {
          code: country,
          name: model.countryName
        },
        region: {
          name: region
        }
      };

      return acc;
    }, {});
  }

  @computed
  get sitesTitleMap() {
    return this.collection.reduce((acc, model) => {
      const { id, title } = model.get();
      acc[title] = id;
      return acc;
    }, {});
  }

  exportPdf = (search = '') => {
    const date = new Date().toISOString().slice(0, 10).replace(/-/g, '');
    this.store.$exports.fetchExport({
      path: '/api/ui/export/exportPage',
      type: 'pdf',
      fileName: `export-sites-${date}`,
      exportOptions: { location: `/v4/export/settings/sites${search}` }
    });
  };

  exportCsv = (data) => {
    const path = '/api/ui/sites/csv';
    const date = new Date().toISOString().slice(0, 10).replace(/-/g, '');
    const fileName = `export-sites-${date}`;
    const type = 'csv';
    const options = { path, fileName, type };

    this.store.$exports.addLoadingExport(options);
    return api.post(path, { data, rawResponse: true }).then((response) => {
      this.store.$exports.clearLoadingExport(options);
      this.store.$exports.addPayload(response.text, options);
    });
  };
}

export default new SiteStore();
