import Collection from 'core/model/Collection';
import { action, computed, observable } from 'mobx';
import $auth from 'app/stores/$auth';
import $notifications from 'app/stores/notifications/$notifications';
import Text from 'core/components/Text';
import React from 'react';
import { POLICY_APPLICATIONS } from '@kentik/ui-shared/alerting/constants';
import NmsPolicyModel from './nms/NmsPolicyModel';
import PolicyModel from './PolicyModel';

class AllPolicyCollection extends Collection {
  @observable.ref
  ruleIdToPolicyId = {};

  // wrappedPolicyCollections: Array<{ collection: PolicyCollection, permissions: string }>
  constructor(data, options = { threeWaySort: true, wrappedPolicyCollections: [] }) {
    super(data, options);

    this.policyCollections = options.wrappedPolicyCollections || [];
  }

  get wrappedPolicyCollections() {
    return this.policyCollections
      .map(({ collection, permission }) => {
        if (permission) {
          return $auth.hasPermission(permission, { overrideForSudo: false }) ? collection : null;
        }

        return collection;
      })
      .filter(Boolean);
  }

  get defaultSortState() {
    return {
      field: 'name'
    };
  }

  @computed({ keepAlive: true })
  get enabled() {
    return this.unfiltered.filter((model) => model.enabled);
  }

  get enabledCount() {
    return this.enabled.length;
  }

  @computed
  get policyOptions() {
    return this.unfiltered.map((policy) => ({
      value: policy.id,
      policy_name: policy.get('name'),
      application: policy.application,
      applicationLabel: policy.applicationLabel,
      label: (
        <>
          {policy.get('name')}{' '}
          <Text fontWeight="300" muted>
            ({policy.id})
          </Text>
        </>
      ),
      policy_description: policy.get('description'),
      status: policy.status
    }));
  }

  getPolicyByRuleId = (ruleId) => {
    const policyId = this.ruleIdToPolicyId[ruleId];
    return policyId && this.get(policyId);
  };

  getModelByName = (name) => this.unfiltered.find((model) => model.get('name') === name);

  /**
   * Creates a new model instance with the given attributes.
   * We need to override the base Collection behavior here as there could be more than one underlying ModelClass.
   */
  build = (attributes, options = {}) => {
    const { select = false, deserialize = true } = options;
    const ModelClass = attributes?.application === POLICY_APPLICATIONS.NMS ? NmsPolicyModel : PolicyModel;
    const model = new ModelClass();

    model.store = this.store;
    model.collection = this;
    model.set(deserialize ? model.deserialize(attributes) : attributes);

    if (select) {
      this.selected = model;
    }

    return model;
  };

  @action
  async fetch(options = {}) {
    const { preserveSelection } = options;

    // only fetch if not the most recent or if forced
    if (
      !options.force &&
      ((this.requestStatus === 'fetching' && this.fetchPromise) ||
        this.lastFetched > Date.now() - this.minFetchInterval)
    ) {
      return this.fetchPromise;
    }

    this.lastFetched = Date.now();
    this.requestStatus = 'fetching';

    if (!preserveSelection) {
      this.clearSelection();
    }

    // make sure notifications are up to date before deserializing policies
    this.fetchPromise = $notifications.collection.fetch({ force: options.force }).then(() =>
      Promise.all(this.wrappedPolicyCollections.map((collection) => collection.fetch(options)))
        .then(() => {
          // preserve inner models for each collection
          const models = this.wrappedPolicyCollections.map((collection) => collection.models).flat();

          models.forEach((model) => {
            const { ruleId } = model;

            if (ruleId) {
              this.ruleIdToPolicyId[ruleId] = model.id;
            }
          });

          this.set(models);
          this.setLastUpdated();
          this.setRequestStatus(null);
        })
        .catch((error) => {
          this.error = error;
          this.models.clear();
          this.hashModelsById();
          this.fetchPromise = null;

          throw error;
        })
        .finally(() => {
          this.hasFetched = true;
          this.requestStatus = null;
        })
    );

    return this.fetchPromise;
  }
}

export default AllPolicyCollection;
