import { computed } from 'mobx';
import Collection from 'core/model/Collection';

class ExporterCompartmentCollection extends Collection {
  @computed
  get totalModels() {
    const totalChildrenModels = this.get()
      .map((model) => {
        const childrenCollection = model.get('childrenCollection', new ExporterCompartmentCollection());
        return childrenCollection.totalModels;
      })
      .flat();

    return [...this.models, ...totalChildrenModels];
  }

  startFetchRequest() {
    this.setRequestStatus('fetching');
  }

  completeFetchRequest() {
    this.setRequestStatus(null);
    this.hasFetched = true;
  }
}

export default ExporterCompartmentCollection;
