import $notifications from 'app/stores/notifications/$notifications';
import { SEVERITY_MAP } from '@kentik/ui-shared/alerting/constants';
import {
  CLEAR_CONDITION_TYPES,
  CONDITION_OPERATOR_STATE_CHANGE_TYPES,
  CONDITION_OPERATOR_SINGLE_STATE_TYPES,
  CONDITION_TYPE,
  TARGET_TYPES,
  CONDITION_GROUP_CONNECTOR_TYPES
} from 'app/forms/config/nms/constants';
import { NMS_POLICY_CONDITION_CONNECTORS } from '@kentik/ui-shared/nms/policies.js';
import filterSerialization from '../../policySerializations/policyFiltersSerialization';

const defaults = {
  level: {
    severity: SEVERITY_MAP.MAJOR.ALERT_MANAGER,
    clear: {
      clear_when_not_activated: {
        clear_when_not_activated: true
      }
    },
    match_aggregate_threshold: {
      single: {
        match_single: true
      }
    }
  }
};

/**
 * This function strips out any properties specified by `propertiesToDelete` from a nested structure.
 * If no `propertiesToDelete` are specified, it will by default remove any `uiProperties` properties.
 * @param {object} objToSerialize
 * @param {string[]} [propertiesToDelete]
 * @returns object
 */
const defaultSerializer = (objToSerialize, propertiesToDelete = ['uiProperties']) => {
  if (typeof objToSerialize !== 'object' || objToSerialize === null) {
    return objToSerialize;
  }

  if (Array.isArray(objToSerialize)) {
    return objToSerialize.map((item) => defaultSerializer(item, propertiesToDelete));
  }

  propertiesToDelete.forEach((prop) => {
    delete objToSerialize[prop];
  });

  Object.keys(objToSerialize).forEach((key) => {
    const maybeObjectValue = objToSerialize[key];

    if (typeof maybeObjectValue === 'object' && maybeObjectValue !== null) {
      defaultSerializer(maybeObjectValue, propertiesToDelete);
    }
  });

  return objToSerialize;
};

const undefOrNull = (val) => val === undefined || val === null;

function transformToMinutes(duration = '') {
  const match = duration ? `${duration}`.match(/^(\d+)(h|m|s)$/) : [];
  const timeWindow = parseInt(match?.[1]) || 0;
  const shortTimeUnit = match?.[2] || 'm';

  // it's seconds - convert to minutes
  if (shortTimeUnit === 's') {
    return timeWindow / 60;
  }

  // it's already minutes
  if (shortTimeUnit === 'm') {
    return timeWindow;
  }

  // it's hours - convert to minutes
  if (shortTimeUnit === 'h') {
    return timeWindow * 60;
  }

  return duration;
}

const getLevelClearConditionType = (level) => {
  if (level.clear_when_not_activated?.clear_when_not_activated) {
    return CLEAR_CONDITION_TYPES.CLEAR_WHEN_NOT_ACTIVATED;
  }

  if (level.clear_manual?.clear_manual) {
    return CLEAR_CONDITION_TYPES.CLEAR_MANUAL;
  }

  if (level.conditional) {
    return CLEAR_CONDITION_TYPES.CONDITIONAL;
  }

  // If none of the clear condition options are specified, fall back to default
  return CLEAR_CONDITION_TYPES.CLEAR_WHEN_NOT_ACTIVATED;
};

const serializeCondition = (condition) => {
  if (!condition) {
    return {};
  }

  const {
    uiProperties: { conditionType, operator, stateChangeToOperator }
  } = condition;

  const serialized = {
    ...condition
  };

  // Make sure we only keep one of the three possible condition variants
  if (conditionType === CONDITION_TYPE.THRESHOLD) {
    serialized.threshold.operator = operator;

    delete serialized.state_in_condition;
    delete serialized.state_change;

    return serialized;
  }

  if (conditionType === CONDITION_TYPE.STATE_CHANGE) {
    serialized.state_change.from.not = operator === CONDITION_OPERATOR_STATE_CHANGE_TYPES.WAS_NOT;
    serialized.state_change.to.not = stateChangeToOperator === CONDITION_OPERATOR_SINGLE_STATE_TYPES.IS_NOT;

    delete serialized.state_in_condition;
    delete serialized.threshold;

    return serialized;
  }

  if (conditionType === CONDITION_TYPE.STATE_IN) {
    serialized.state_in_condition.not = operator === CONDITION_OPERATOR_SINGLE_STATE_TYPES.IS_NOT;

    delete serialized.state_change;
    delete serialized.threshold;

    return serialized;
  }

  // We should never get here :)
  return serialized;
};

const serializeConditionConnector = (connectorType) => {
  // We use this to serialize condition connectors for both condition roots and condition groups.
  // It's ok to check against condition group values here because condition root connector types are a
  // subset of condition group connector types.
  if (connectorType === CONDITION_GROUP_CONNECTOR_TYPES.ALL) {
    return {
      connector: NMS_POLICY_CONDITION_CONNECTORS.ALL,
      not: false
    };
  }

  // For condition roots, we should never hit this condition as NONE is not a valid value for condition roots.
  // There is no 'not' in the condition root so NONE is impossible.
  // For condition groups only
  if (connectorType === CONDITION_GROUP_CONNECTOR_TYPES.NONE) {
    return { connector: NMS_POLICY_CONDITION_CONNECTORS.ALL, not: true };
  }

  // If not ALL or NONE, assume ANY
  return {
    connector: NMS_POLICY_CONDITION_CONNECTORS.ANY,
    not: false
  };
};

const serializeConditionGroup = (deserializedConditionGroup) => {
  const conditionGroup = deserializedConditionGroup?.conditionGroupFields;
  if (!conditionGroup) {
    return {};
  }

  const serialized = {
    ...conditionGroup,
    uiProperties: conditionGroup.uiProperties || {},
    condition_groups: conditionGroup.condition_groups?.map(serializeConditionGroup),
    conditions: conditionGroup.conditions?.map(serializeCondition)
  };
  const {
    uiProperties: { connectorType }
  } = serialized;

  const { connector, not } = serializeConditionConnector(connectorType);
  serialized.connector = connector;
  serialized.not = not;

  return serialized;
};

const serializeConditionRoot = (conditionRoot) => {
  const serialized = { ...conditionRoot };
  serialized.connector = serializeConditionConnector(serialized.uiProperties?.connectorType)?.connector;
  serialized.condition_groups = serialized.condition_groups?.map(serializeConditionGroup) ?? [];

  return serialized;
};

/**
 * Transform view model of a policy level to its API model.
 * Both have the same underlying type, but defaults and interpretation may differ.
 * Here, we provide defaults for any fields that are either not shown to the user or not filled out.
 * @param {import('@kentik/ui-shared/nms/policies.js').ApiNmsPolicyLevel} level
 * @returns import('@kentik/ui-shared/nms/policies.js').ApiNmsPolicyLevel
 */
const serializePolicyLevel = (level = {}) => {
  let serialized = {
    ...level
  };

  if (!serialized.activate) {
    serialized.activate = {};
  }

  if (!serialized.activate.condition_groups?.length) {
    serialized.activate.condition_groups = [
      {
        conditions: []
      }
    ];
  }

  serialized.activate = serializeConditionRoot(serialized.activate);

  // These three are mutually exclusive
  if (serialized.uiProperties.clearConditionType === CLEAR_CONDITION_TYPES.CONDITIONAL) {
    delete serialized.clear_when_not_activated;
    delete serialized.clear_manual;
  } else if (serialized.uiProperties.clearConditionType === CLEAR_CONDITION_TYPES.CLEAR_WHEN_NOT_ACTIVATED) {
    delete serialized.conditional;
    delete serialized.clear_manual;
    serialized.clear_when_not_activated = {
      clear_when_not_activated: true
    };
  } else if (serialized.uiProperties.clearConditionType === CLEAR_CONDITION_TYPES.CLEAR_MANUAL) {
    delete serialized.conditional;
    delete serialized.clear_when_not_activated;
    serialized.clear_manual = {
      clear_manual: true
    };
  }

  // If none of the clear condition options are specified, fall back to default
  if (
    undefOrNull(serialized.conditional) &&
    undefOrNull(serialized.clear_when_not_activated) &&
    undefOrNull(serialized.clear_manual)
  ) {
    serialized = {
      ...serialized,
      ...defaults.level.clear
    };
  }

  // If none of the match aggregate threshold options are specified, fall back to default
  if (
    // single object is missing, OR it is present but its match_single required property is missing
    (!serialized.single || (serialized.single && undefOrNull(serialized.single.match_single))) &&
    //
    (!serialized.sum || (serialized.sum && undefOrNull(serialized.sum.threshold)))
  ) {
    serialized = {
      ...serialized,
      ...defaults.level.match_aggregate_threshold
    };
  }

  return serialized;
};

const serializeTargetAndScope = (policy) => {
  const { target, scope } = policy;
  const serializedTarget = { ...target };

  if (serializedTarget.uiProperties?.targetType === TARGET_TYPES.CUSTOM) {
    delete serializedTarget.entity_type;

    serializedTarget.custom = {
      dimensions: target.custom?.dimensions ?? []
    };
  } else {
    delete serializedTarget.custom;
  }

  return {
    target: serializedTarget,
    scope: filterSerialization.serializeFilterGroup(scope, true)
  };
};

const deserializeTargetAndScope = (policy) => {
  const { target, scope } = policy;

  const deserializedScope = scope ? filterSerialization.deserializeFilterGroup(scope, true) : {};

  const isCustomTarget = !target?.entity_type && target?.custom?.dimensions?.length > 0;

  return {
    target: {
      ...target,
      entity_type: isCustomTarget ? TARGET_TYPES.CUSTOM : target?.entity_type,
      uiProperties: {
        targetType: isCustomTarget ? TARGET_TYPES.CUSTOM : TARGET_TYPES.ENTITY
      }
    },
    scope: deserializedScope
  };
};

const deserializeCondition = (condition) => {
  const deserialized = { ...condition };

  deserialized.uiProperties = {};

  if (condition.threshold) {
    return {
      ...condition,
      uiProperties: {
        conditionType: CONDITION_TYPE.THRESHOLD,
        operator: condition.threshold.operator
      }
    };
  }

  if (condition.state_change) {
    return {
      ...condition,
      uiProperties: {
        conditionType: CONDITION_TYPE.STATE_CHANGE,
        operator: condition.state_change.from?.not
          ? CONDITION_OPERATOR_STATE_CHANGE_TYPES.WAS_NOT
          : CONDITION_OPERATOR_STATE_CHANGE_TYPES.WAS,
        stateChangeToOperator: condition.state_change.to?.not
          ? CONDITION_OPERATOR_SINGLE_STATE_TYPES.IS_NOT
          : CONDITION_OPERATOR_SINGLE_STATE_TYPES.IS
      }
    };
  }

  return {
    ...condition,
    uiProperties: {
      conditionType: CONDITION_TYPE.STATE_IN,
      operator: condition.state_in_condition?.not
        ? CONDITION_OPERATOR_SINGLE_STATE_TYPES.IS_NOT
        : CONDITION_OPERATOR_SINGLE_STATE_TYPES.IS
    }
  };
};

const deserializeConditionConnector = (serializedConnectorValue, not = false) => {
  // We use this to deserialize condition connectors for both condition roots and condition groups.
  // It's ok to return condition group values here because condition root connector types are a
  // subset of condition group connector types.
  if (serializedConnectorValue === NMS_POLICY_CONDITION_CONNECTORS.ALL && !not) {
    // connector: all, not: false = all
    return CONDITION_GROUP_CONNECTOR_TYPES.ALL;
  }

  // We should not hit this condition for condition roots (no 'not' in condition root)
  if (serializedConnectorValue === NMS_POLICY_CONDITION_CONNECTORS.ALL && not) {
    // connector: all, not: true = none
    return CONDITION_GROUP_CONNECTOR_TYPES.NONE;
  }

  // This assumes that anything that isn't one of
  // - connector: all, not: true = none
  // - connector: all, not: false = all
  // is going to be connector: any, not: false = any
  // Note that this will override a combination of connector: any, not: true and interpret it as ANY, because we do not support this combination in the UI
  return CONDITION_GROUP_CONNECTOR_TYPES.ANY;
};

const deserializeConditionGroup = (conditionGroup) => {
  if (!conditionGroup) {
    return {};
  }

  const deserialized = {
    ...conditionGroup,
    conditions: conditionGroup.conditions?.map(deserializeCondition) ?? [],
    condition_groups: conditionGroup.condition_groups?.map(deserializeConditionGroup) ?? [],
    uiProperties: {
      connectorType: deserializeConditionConnector(conditionGroup.connector, conditionGroup.not)
    }
  };

  return {
    conditionGroupFields: deserialized
  };
};

const deserializeConditionRoot = (conditionRoot) => {
  if (!conditionRoot) {
    return conditionRoot;
  }

  const deserialized = {
    ...conditionRoot,
    condition_groups: conditionRoot?.condition_groups?.map(deserializeConditionGroup) ?? [],
    uiProperties: {
      connectorType: deserializeConditionConnector(conditionRoot.connector)
    }
  };

  return deserialized;
};

const deserializeLevel = (level) => {
  if (!level) {
    return level;
  }

  return {
    ...level,
    settings: { acknowledge_required: level.settings?.acknowledge_required ?? false },
    activate: deserializeConditionRoot(level?.activate)
  };
};

const deserializeLevels = (levels = []) =>
  levels.map((level) => {
    const clearConditionType = getLevelClearConditionType(level);

    return {
      ...deserializeLevel(level),
      uiProperties: {
        enabled: true,
        clearConditionType,
        clearManualToggle: clearConditionType === CLEAR_CONDITION_TYPES.CLEAR_MANUAL
      }
    };
  });

/**
 * Transform API model to view model.
 * Both have the same underlying type, but defaults and interpretation may differ.
 * @param {import('@kentik/ui-shared/nms/policies.js').ApiNmsPolicy} apiPolicy
 * @returns import('@kentik/ui-shared/nms/policies.js').ApiNmsPolicy
 */
const deserialize = (apiPolicy) => {
  if (!apiPolicy) {
    return {};
  }

  return {
    ...apiPolicy,
    settings: {
      ...(apiPolicy.settings || {}),
      activation_delay: transformToMinutes(apiPolicy.settings?.activation_delay),
      clearance_delay: transformToMinutes(apiPolicy.settings?.clearance_delay)
    },
    ...deserializeTargetAndScope(apiPolicy),
    levels: $notifications.addNotificationsToNmsPolicyLevels(deserializeLevels(apiPolicy.levels), apiPolicy)
  };
};

/**
 * Transform view model to API model.
 * Both have the same underlying type, but defaults and interpretation may differ.
 * @param {import('@kentik/ui-shared/nms/policies.js').ApiNmsPolicy} policyModel
 * @returns import('@kentik/ui-shared/nms/policies.js').ApiNmsPolicy
 */
const serialize = (policyModel) => {
  if (!policyModel) {
    return {};
  }

  return defaultSerializer({
    ...policyModel,
    ...serializeTargetAndScope(policyModel),
    levels: (policyModel.levels || []).map(serializePolicyLevel)
  });
};

export { serialize, deserialize, defaultSerializer, deserializeConditionRoot };
