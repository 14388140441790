import AgentCollection from './AgentCollection';
import AgentModel from './AgentModel';

class WorkerAgentCollection extends AgentCollection {
  get model() {
    return AgentModel;
  }

  get url() {
    return '/api/ui/sudo/synthetics/agents/cluster_workers';
  }

  deserialize(data) {
    const agents = [];
    Object.values(data).forEach((cluster) => {
      cluster.forEach((worker) => {
        agents.push(worker);
      });
    });

    return agents;
  }
}

export default WorkerAgentCollection;
