import PagedCollection from 'core/model/PagedCollection';
import PopulatorModel from './PopulatorModel';

class PopulatorCollection extends PagedCollection {
  get jsonRoot() {
    return 'populators';
  }

  get queuedFetchKey() {
    return 'PopulatorCollection';
  }

  get defaultSortState() {
    return {
      field: 'value',
      direction: 'desc'
    };
  }

  get url() {
    const dimension = this.parent;
    return `/api/ui/customdimensions/${dimension.id}/populators`;
  }

  get urlPaths() {
    const dimension = this.parent;
    return {
      pagedFetch: `/api/ui/customdimensions/${dimension.id}/populators/search`
    };
  }

  get model() {
    return PopulatorModel;
  }
}

export default PopulatorCollection;
