import Model from 'core/model/Model';
import { computed } from 'mobx';

export default class PlanModel extends Model {
  @computed
  get numDevices() {
    return this.get('devices')?.length;
  }

  get urlRoot() {
    return '/api/ui/sudo/plans';
  }

  deserialize(data) {
    if (
      data &&
      data.metadata &&
      (data.metadata.type === 'cloud' || data.metadata.type === 'flowpak' || data.metadata.type === 'universalpak')
    ) {
      if (data.max_fps % 10 === 5) {
        data.max_fps -= 5;
        data.metadata.isPak = true;
      }
    }
    return super.deserialize(data);
  }

  serialize(data) {
    if (
      data &&
      data.metadata &&
      (data.metadata.type === 'cloud' || data.metadata.type === 'flowpak' || data.metadata.type === 'universalpak')
    ) {
      if (data.metadata.isPak === true) {
        data.max_fps += 5;
      }
    }
    return super.serialize(data);
  }

  get omitDuringSerialize() {
    return ['planHistory'];
  }

  @computed
  get endDate() {
    if (this.get('isHistory')) {
      return this.get('edate');
    }
    return (!this.get('active') && this.get('edate')) || null;
  }
}
