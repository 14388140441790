import { observable } from 'mobx';
import Collection from 'core/model/Collection';
import AutoAckModel from './AutoAckModel';

class AutoAckCollection extends Collection {
  get url() {
    return '/api/ui/alertingManager/alarms/ack/auto';
  }

  get model() {
    return AutoAckModel;
  }

  get fetchMethod() {
    return 'post';
  }

  @observable.ref
  totalCount = 0;

  @observable.ref
  serverFilter = {
    pagination: {
      limit: 1000,
      offset: 0
    }
  };

  async fetch() {
    return super.fetch({ data: this.serverFilter, fetchUrl: '/api/ui/alertingManager/alarms/ack/auto/list' });
  }

  deserialize(data = {}) {
    const { auto_acks, pagination } = data;
    this.totalCount = pagination?.total_count;

    return super.deserialize(auto_acks);
  }
}

export default AutoAckCollection;
