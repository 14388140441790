import RuleState from 'app/stores/interface/RuleState';
import { computed } from 'mobx';

export class RuleTestState extends RuleState {
  // TODO this is inaccurate, only used by totalPercentClassified in ruleTestState and should be deleted
  @computed
  get totalInterfaces() {
    return this.evaluationResults.reduce((sum, { matchInfo = {} }) => sum + (matchInfo.interfaceCount || 0), 0);
  }

  // TODO this inaccurate, only used in calculating ruleTestState and should be deleted
  @computed
  get totalClassified() {
    return this.evaluationResults.reduce(
      (sum, { matchInfo = {} }) => sum + (matchInfo.interfacesClassifiedCount || 0),
      0
    );
  }

  // TODO this inaccurate, only used in calculating ruleTestState and should be deleted
  @computed
  get totalPercentClassified() {
    return this.totalInterfaces === 0 ? 0 : this.totalClassified / this.totalInterfaces;
  }
}
