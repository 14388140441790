import api from 'core/util/api';
import { showErrorToast } from 'core/components/toast';
import { ALERT_MANAGER_APPLICATIONS } from 'shared/synthetics/constants';

// plan
async function getSynthPlan() {
  return api.get('/api/ui/synthetics/plan');
}

async function getCreditUsage() {
  return api.get('/api/ui/synthetics/credit-usage');
}

async function getTestCreditBurnRates() {
  return api.get('/api/ui/synthetics/credit-usage/test-rates');
}

// agents
async function fetchAgentByChallenge(data) {
  return api.post('/api/ui/synthetics/agents/challenge', { data });
}

async function getAgentDetails(id) {
  return api.get(`/api/ui/synthetics/agents/${id}`);
}

async function fetchAgentHealth(data) {
  return api.post('/api/ui/synthetics/agents/stats/sync', { data });
}

async function migrateAgentTests(data) {
  return api.post('/api/ui/sudo/synthetics/agents/migrate', { data });
}

async function updateConfigAgentOrTarget({ old_agent, new_agent }) {
  return api.post('/api/ui/sudo/synthetics/agents/update', { data: { old_agent, new_agent } });
}

async function fetchAgentContext(id) {
  return api.get(`/api/ui/sudo/synthetics/agents/${id}/settings`);
}

async function updateAgentContext(id, data) {
  return api.put(`/api/ui/sudo/synthetics/agents/${id}/settings`, { data });
}

async function updateAgentClusters(id, data) {
  return api.put(`/api/ui/sudo/synthetics/agents/${id}/cluster_workers`, { data });
}

// tests
async function getTestHealth() {
  return api.post('/api/ui/synthetics/tests/health');
}

async function fetchTestResults(data, { preset }) {
  return api.post(`/api/ui/synthetics/${preset ? 'presets/' : ''}tests/syngest-results`, { data });
}

async function fetchTestResultsTimestamps(data, { preset }) {
  return api.post(`/api/ui/synthetics/${preset ? 'presets/' : ''}tests/timestamps`, { data });
}

async function fetchTestResultsByTimestamp(data, { preset }) {
  return api.post(`/api/ui/synthetics/${preset ? 'presets/' : ''}tests/results-timestamp`, { data });
}

async function fetchTestAvailabilityResults(data, { preset }) {
  return api.post(`/api/ui/synthetics/${preset ? 'presets/' : ''}tests/syngest-results/availability`, { data });
}

async function fetchAggregateTestResults(data, { preset }) {
  return api.post(`/api/ui/synthetics/${preset ? 'presets/' : ''}tests/syngest-results/aggregate`, { data });
}

async function fetchTestTraceroutes(id, data, { preset }) {
  return api.post(`/api/ui/synthetics/${preset ? 'presets/' : ''}tests/results/${id}/trace`, { data });
}

async function fetchTestTraceroutee(id, data, { preset }) {
  return api.post(`/api/ui/synthetics/${preset ? 'presets/' : ''}tests/results/${id}/tracee`, { data });
}

async function fetchTestErrorDetails(data) {
  return api.post('/api/ui/synthetics/tests/errors', { data });
}

async function fetchTestsPerDay(lookback_seconds) {
  return api.post(`/api/ui/synthetics/tests/testsPerDay/?lookback=${lookback_seconds}`);
}

async function fetchS3Object({ objectKey }) {
  return api.get(`/api/ui/synthetics/tests/results/object/${objectKey}`);
}

async function updateTestStatus(id, data) {
  return api.post(`/api/ui/synthetics/tests/${id}/test-status`, { data });
}

async function getTestAlarms(body) {
  return api.post('/api/ui/synthetics/tests/alarms', { body });
}

// alarms
async function fetchAlarms({ body }) {
  return api.post('/api/ui/alerting/alarms', { body });
}

async function fetchAlertManagerAlarms({ filter = {}, ...options } = {}) {
  const nextFilter = { applications: [ALERT_MANAGER_APPLICATIONS.synthetics], ...filter };
  const nextOptions = { includeInitialContext: false, ...options };

  return api.post('/api/ui/alertingManager/alarms', {
    data: { filter: nextFilter, ...nextOptions }
  });
}

async function fetchAlertManagerRules(data) {
  return api.post('/api/ui/alertingManager/rules/list', { data });
}

async function fetchAlertManagerSuppressions(data) {
  return api.post('/api/ui/alertingManager/suppressions/list', { data });
}

async function fetchAlertManagerTestSuppressions(data = {}) {
  const { testId } = data;
  return api.get(`/api/ui/synthetics/test-suppressions${testId ? `/${testId}` : ''}`);
}

async function fetchAlertManagerSuppression(id) {
  return api.get(`/api/ui/alertingManager/suppressions/${id}`);
}

async function createAlertManagerSuppression(data) {
  return api.post('/api/ui/alertingManager/suppressions', { data });
}

async function updateAlertManagerSuppression(data) {
  return api.put(`/api/ui/alertingManager/suppressions/${data.id}`, { data });
}

async function deleteAlertManagerSuppression(id) {
  return api.del(`/api/ui/alertingManager/suppressions/${id}`);
}

async function getIncidentLog(data) {
  return api.post('/api/ui/alertingManager/incidentLog', { data }).then((response) => {
    const { success, alarms, error, totalAlarms } = response;

    if (success === false) {
      showErrorToast(error);
    }

    return { alarms, totalAlarms };
  });
}

export default {
  getSynthPlan,
  getCreditUsage,
  getTestCreditBurnRates,
  fetchAgentByChallenge,
  getAgentDetails,
  fetchAgentHealth,
  migrateAgentTests,
  updateConfigAgentOrTarget,
  fetchAgentContext,
  updateAgentContext,
  getTestHealth,
  getTestAlarms,
  fetchTestResults,
  fetchTestResultsTimestamps,
  fetchTestResultsByTimestamp,
  fetchTestTraceroutes,
  fetchTestTraceroutee,
  fetchTestErrorDetails,
  fetchTestsPerDay,
  fetchS3Object,
  updateTestStatus,
  fetchAlarms,
  fetchTestAvailabilityResults,
  fetchAggregateTestResults,
  fetchAlertManagerAlarms,
  fetchAlertManagerRules,
  fetchAlertManagerSuppressions,
  fetchAlertManagerTestSuppressions,
  fetchAlertManagerSuppression,
  createAlertManagerSuppression,
  updateAlertManagerSuppression,
  deleteAlertManagerSuppression,
  getIncidentLog,
  updateAgentClusters
};
