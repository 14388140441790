import Collection from 'core/model/Collection';

import CredentialModel from './CredentialModel';

class CredentialCollection extends Collection {
  get minFetchInterval() {
    return 60000; // 1 minute
  }

  get url() {
    return '/api/ui/credential';
  }

  get urlPaths() {
    return {
      fetch: '/api/ui/credential/grouped'
    };
  }

  get model() {
    return CredentialModel;
  }

  get defaultSortState() {
    return {
      field: 'name',
      direction: 'asc'
    };
  }
}

export default CredentialCollection;
