import moment from 'moment';
import { computed } from 'mobx';

import $auth from 'app/stores/$auth';
import Collection from 'core/model/Collection';
import MitigationPlatformModel from './MitigationPlatformModel';

class MitigationPlatformCollection extends Collection {
  get url() {
    return '/api/ui/mitigations/platforms';
  }

  get model() {
    return MitigationPlatformModel;
  }

  getPlatformMethod = ({ methodID, platformID }) =>
    this.platformMethods.find(
      (platformMethod) => platformMethod.method.id === methodID && platformMethod.platform.id === platformID
    );

  @computed
  get platformMethods() {
    const threshold = moment.utc().subtract(2, 'minutes');
    const options = [];
    const isMit2bgpEnabled = $auth.hasPermission('alerts.mit2bgp.enabled', { overrideForSudo: false });

    this.models.forEach((platformModel) => {
      platformModel.get('mitigationMethods').models.forEach((methodModel) => {
        const disabled =
          moment.utc(platformModel.get('lastEditTime')).isAfter(threshold) ||
          moment.utc(methodModel.get('lastEditTime')).isAfter(threshold);

        if (isMit2bgpEnabled || methodModel.type !== 'mit2bgp') {
          options.push({
            label: methodModel.get('name'),
            value: methodModel.id,
            platform: platformModel,
            method: methodModel,
            disabled
          });
        }
      });
    });

    return options;
  }

  getPolicyOptions(mitigations = []) {
    const options = [];

    this.models.forEach((platformModel) => {
      platformModel.get('mitigationMethods').models.forEach((methodModel) => {
        const platformID = platformModel.get('id');
        const methodID = methodModel.get('id');
        const platformName = platformModel.get('name');
        const methodName = methodModel.get('name');
        const selected = mitigations.find((m) => m.platformID === platformID && m.methodID === methodID);
        const type = methodModel.get('type');

        options.push({
          key: `${platformID}-${methodID}`,
          label: `${platformName} - ${methodName}`,
          value: `${platformID}-${methodID}`,
          methodID,
          platformID,
          methodName,
          platformName,
          type,
          selected
        });
      });
    });

    return options;
  }

  getPolicyOption(mitigation) {
    return this.getPolicyOptions().find(
      (option) =>
        option.methodID === mitigation.methodID.getValue() && option.platformID === mitigation.platformID.getValue()
    );
  }
}

export default MitigationPlatformCollection;
