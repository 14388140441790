import Collection from 'core/model/Collection';
import PackageModel from './PackageModel';

class PackageCollection extends Collection {
  get defaultSortState() {
    return {
      field: 'is_default',
      direction: 'desc'
    };
  }

  get secondarySort() {
    return {
      field: 'name',
      direction: 'asc'
    };
  }

  get url() {
    return '/api/ui/mkp-settings/package';
  }

  get model() {
    return PackageModel;
  }
}

export default PackageCollection;
