import { Model, Collection } from 'core/model';
import { action, observable } from 'mobx';
import api from 'core/util/api';

function groupTags({ paths = [] }) {
  const results = {};
  Object.keys(paths).forEach((path) => {
    const methods = paths[path];
    Object.keys(methods).forEach((method) => {
      const meta = methods[method];
      meta.tags.forEach((tag) => {
        if (!results[tag]) {
          results[tag] = [];
        }
        results[tag].push({
          path,
          method,
          ...meta
        });
      });
    });
  });
  return results;
}

class SwaggerModel extends Model {
  @observable
  tags = {};

  processData(response) {
    const groups = groupTags(response);
    Object.keys(groups).forEach((g) => {
      this.tags[g] = new Collection(groups[g], { hasFetched: true });
    });
  }

  @action
  fetchSwaggerJSON() {
    const promise = api.get(this.get('url'));
    return promise.then(
      (success) => {
        this.processData(success);
        this.set('swaggerJSON', success);
        this.setRequestStatus(null);
        return success;
      },
      (error) => {
        this.error = { label: 'fetching', body: error };
        this.requestStatus = null;
        throw error;
      }
    );
  }
}

export default SwaggerModel;
