import React from 'react';
import { computed, observable } from 'mobx';

import $notifications from 'app/stores/notifications/$notifications';

import Text from 'core/components/Text';
import Collection from 'core/model/Collection';
import PolicyModel from './PolicyModel';

class PolicyCollection extends Collection {
  @observable.ref
  ruleIdToPolicyId = {};

  constructor(data, options = { threeWaySort: true }) {
    super(data, options);
  }

  @computed({ keepAlive: true })
  get enabled() {
    return this.unfiltered.filter((model) => model.enabled);
  }

  get enabledCount() {
    return this.enabled.length;
  }

  get enabledDdosCount() {
    const ddosPolicies = this.enabled.filter((model) => model.isDdosPolicy);
    return ddosPolicies.length;
  }

  get url() {
    return '/api/ui/alerting/policies';
  }

  get model() {
    return PolicyModel;
  }

  get defaultSortState() {
    return {
      field: 'name'
    };
  }

  @computed
  get policyOptions() {
    return this.unfiltered.map((policy) => ({
      value: policy.id,
      policy_name: policy.get('name'),
      application: policy.application,
      applicationLabel: policy.applicationLabel,
      label: (
        <>
          {policy.get('name')}{' '}
          <Text fontWeight="300" muted>
            ({policy.id})
          </Text>
        </>
      ),
      policy_description: policy.get('description'),
      status: policy.get('status')
    }));
  }

  getPolicyByRuleId = (ruleId) => {
    const policyId = this.ruleIdToPolicyId[ruleId];
    return policyId && this.get(policyId);
  };

  getModelByName = (name) => this.unfiltered.find((model) => model.get('name') === name);

  deserialize = (data) => {
    const policies = Array.isArray(data) ? data : [];

    policies.forEach((policy) => {
      policy.thresholds = $notifications.addNotificationsToThresholds(policy.thresholds, policy);
      const ruleId = policy?.activationSettings?.alertManagerConfig?.ruleId;
      if (ruleId) {
        this.ruleIdToPolicyId[ruleId] = policy.id;
      }
    });

    return policies;
  };

  async fetch(...args) {
    // Fetch notification channels first to prevent a race condition where the notification channels
    // are not available during deserialization of the policy collection.
    // Don't try Promise.all, it won't work.

    return $notifications.collection
      .fetch({ force: args.length > 0 ? args[0]?.force : false })
      .then(() => super.fetch(...args));
  }
}

export default PolicyCollection;
