import BaseModel from 'core/model/Model';

const defaults = {
  spoof_restrictions: []
};

export default class SpoofRestrictionsModel extends BaseModel {
  get defaults() {
    return defaults;
  }
}
