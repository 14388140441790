import Collection from 'core/model/Collection';

import CredentialModel from './CredentialModel';

class CredentialCollection extends Collection {
  get url() {
    return '/api/ui/credential';
  }

  get model() {
    return CredentialModel;
  }

  get defaultSortState() {
    return {
      field: 'name',
      direction: 'asc'
    };
  }
}

export default CredentialCollection;
