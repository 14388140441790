import Model from 'core/model/Model';

class Customer extends Model {
  get defaults() {
    return {
      interfaceGroup: { interfaces: [] }
    };
  }

  get urlRoot() {
    return '/api/ui/customers';
  }
}

export default Customer;
