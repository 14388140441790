import Model from 'core/model/Model';
import { computed } from 'mobx';

import { ReactComponent as ContainerIcon } from 'app/assets/recon/icon-container.svg';
import { ReactComponent as ModuleIcon } from 'app/assets/recon/icon-module.svg';
import { ReactComponent as SensorIcon } from 'app/assets/recon/icon-sensor.svg';
import { ReactComponent as FanIcon } from 'app/assets/recon/icon-fan.svg';
import { ReactComponent as CpuIcon } from 'app/assets/recon/icon-cpu.svg';
import { ReactComponent as PortIcon } from 'app/assets/recon/icon-port.svg';
import { ReactComponent as ChassisIcon } from 'app/assets/recon/icon-chassis.svg';
import { ReactComponent as PowerSupplyIcon } from 'app/assets/recon/icon-power-supply.svg';

export const displayTypes = {
  SENSOR: 'Sensor',
  MODULE: 'Module',
  CONTAINER: 'Container',
  FAN: 'Fan',
  CPU: 'CPU',
  PORT: 'Port',
  CHASSIS: 'Chassis',
  POWER_SUPPLY: 'Power Supply',
  BACKPLANE: 'Backplane',
  STACK: 'Stack',
  OTHER: 'Other'
};

const displayTypeIcons = {
  CONTAINER: ContainerIcon,
  MODULE: ModuleIcon,
  SENSOR: SensorIcon,
  FAN: FanIcon,
  CPU: CpuIcon,
  PORT: PortIcon,
  CHASSIS: ChassisIcon,
  POWER_SUPPLY: PowerSupplyIcon
};

class MetricComponentModel extends Model {
  get urlRoot() {
    return '/api/ui/recon/components';
  }

  get title() {
    return this.get('name');
  }

  get subType() {
    return 'Component';
  }

  @computed
  get deviceId() {
    return this.get('device').id;
  }

  @computed
  get deviceVendor() {
    return this.get('device').get('vendor');
  }

  @computed
  get parentComponent() {
    const parentIndex = parseInt(this.get('parent'));
    return this.collection.models.find((m) => parseInt(m.get('index')) === parentIndex);
  }

  @computed
  get isCiscoDevice() {
    if (!this.deviceVendor) {
      return false;
    }

    return this.deviceVendor.includes('Cisco');
  }

  @computed
  get typeUpperCase() {
    return this.get('type')?.toUpperCase();
  }

  @computed
  get showCpuChart() {
    return ['CONTAINER', 'BACKPLANE', 'MODULE', 'OTHER'].includes(this.typeUpperCase);
  }

  @computed
  get showMemoryChart() {
    return ['CONTAINER', 'MODULE', 'OTHER'].includes(this.typeUpperCase);
  }

  @computed
  get showTemperatureChart() {
    return !this.isCiscoDevice;
  }

  // pretty names for Component `type`
  @computed
  get displayType() {
    return displayTypes[this.typeUpperCase] || this.get('type');
  }

  @computed
  get typeIcon() {
    return displayTypeIcons[this.typeUpperCase] || 'circle';
  }

  @computed
  get operStatus() {
    const map = {
      1: 'Up',
      2: 'Down',
      3: 'Disabled'
    };

    const status = this.get('oper-status');

    // sometimes oper-status will return `null`, which is just 'Unknown'
    return map[status] || 'Unknown';
  }

  // filters the query down to the specific component index and device
  getQueryFilters(type = 'cpu') {
    let index = 'index';

    // because CISCO makes a stupid MIB -- dusan
    if (type === 'memory' && this.isCiscoDevice) {
      index = 'index';
    }

    return {
      connector: 'All',
      filterGroups: [
        {
          name: '',
          named: false,
          connector: 'All',
          not: false,
          autoAdded: '',
          filters: [
            {
              filterField: 'km_device_id',
              operator: '=',
              filterValue: `${this.deviceId}`
            },
            {
              filterField: index,
              operator: '=',
              filterValue: this.get('index')
            }
          ]
        }
      ]
    };
  }

  @computed
  get queryCards() {
    return [
      {
        title: 'CPU Utilization',
        query: this.cpuUtilizationQuery
      },
      {
        title: 'Memory Utilization',
        query: this.memoryUtilizationQuery
      },
      {
        title: 'Temperature',
        query: this.temperatureQuery
      }
    ];
  }

  @computed
  get cpuUtilizationQuery() {
    return {
      use_kmetrics: true,
      show_overlay: false,
      show_total_overlay: false,
      kmetrics: {
        measurement: '/components/cpu/utilization',
        dimensions: ['index'],
        metrics: [
          {
            name: 'instant',
            type: 'gauge'
          }
        ],
        range: {
          lookback: 'PT86400S'
        },
        window: {
          size: 0,
          fn: {
            instant: 'avg'
          }
        },
        rollups: {
          avg_instant: {
            metric: 'instant',
            aggregate: 'avg'
          },
          max_instant: {
            metric: 'instant',
            aggregate: 'max'
          },
          last_instant: {
            metric: 'instant',
            aggregate: 'last'
          }
        },
        sort: [
          {
            name: 'avg_instant',
            direction: 'desc'
          },
          {
            name: 'max_instant',
            direction: 'desc'
          },
          {
            name: 'last_instant',
            direction: 'desc'
          }
        ],
        limit: 5000,
        includeTimeseries: 10,
        filters: this.getQueryFilters(),
        rollupFilters: {
          connector: 'All',
          filterGroups: [
            {
              name: '',
              named: false,
              connector: 'All',
              not: false,
              autoAdded: '',
              filters: [
                {
                  filterField: 'last_instant',
                  metric: 'instant',
                  aggregate: 'last',
                  operator: '>',
                  filterValue: '0'
                }
              ],
              saved_filters: [],
              filterGroups: []
            }
          ]
        },
        viz: {
          type: 'area'
        }
      }
    };
  }

  @computed
  get memoryUtilizationQuery() {
    return {
      use_kmetrics: true,
      show_overlay: false,
      show_total_overlay: false,
      kmetrics: {
        measurement: '/components/memory',
        dimensions: ['index', 'name'],
        metrics: [
          {
            name: 'utilization',
            type: 'gauge'
          }
        ],
        range: {
          lookback: 'PT86400S'
        },
        window: {
          size: 0,
          fn: {
            utilization: 'avg'
          }
        },
        rollups: {
          last_utilization: {
            metric: 'utilization',
            aggregate: 'last'
          }
        },
        sort: [
          {
            name: 'last_utilization',
            direction: 'desc'
          }
        ],
        limit: 1000,
        includeTimeseries: 10,
        filters: this.getQueryFilters('memory'),
        viz: {
          type: 'area'
        }
      }
    };
  }

  @computed
  get temperatureQuery() {
    return {
      use_kmetrics: true,
      show_overlay: false,
      show_total_overlay: false,
      kmetrics: {
        measurement: '/components/temperature',
        dimensions: ['index'],
        metrics: [
          {
            name: 'instant',
            type: 'gauge'
          }
        ],
        range: {
          lookback: 'PT86400S'
        },
        window: {
          size: 0,
          fn: {
            instant: 'avg'
          }
        },
        rollups: {
          avg_instant: {
            metric: 'instant',
            aggregate: 'avg'
          },
          max_instant: {
            metric: 'instant',
            aggregate: 'max'
          },
          last_instant: {
            metric: 'instant',
            aggregate: 'last'
          }
        },
        sort: [
          {
            name: 'avg_instant',
            direction: 'desc'
          },
          {
            name: 'max_instant',
            direction: 'desc'
          },
          {
            name: 'last_instant',
            direction: 'desc'
          }
        ],
        limit: 5000,
        includeTimeseries: 10,
        filters: this.getQueryFilters(),
        rollupFilters: {
          connector: 'All',
          filterGroups: [
            {
              name: '',
              named: false,
              connector: 'All',
              not: false,
              autoAdded: '',
              filters: [
                {
                  filterField: 'last_instant',
                  metric: 'instant',
                  aggregate: 'last',
                  operator: '>',
                  filterValue: '0'
                }
              ],
              saved_filters: [],
              filterGroups: []
            }
          ]
        },
        viz: {
          type: 'area'
        }
      }
    };
  }
}

export default MetricComponentModel;
