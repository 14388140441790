import { computed } from 'mobx';

import Model from 'core/model/Model';
import $dictionary from 'app/stores/$dictionary';

class InterfaceSettings extends Model {
  get defaults() {
    return { settings: $dictionary.get('interfaceClassification.settingsDefaults') };
  }

  get url() {
    return '/api/ui/interfaces/rules/settings';
  }

  /**
   * Override the isNew check because this particular model doesn't return with an `id`, but instead `company_id`.
   */
  @computed
  get isNew() {
    return false;
  }

  get jsonRoot() {
    return 'settings';
  }
}

export default InterfaceSettings;
