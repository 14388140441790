import { action } from 'mobx';
import Collection from 'core/model/Collection';
import DashboardItemModel from './DashboardItemModel';

class DashboardItemCollection extends Collection {
  constructor(data, options) {
    super(data, options);
    this.dashboard = options.dashboard;
  }

  @action
  create(attributes) {
    const item = this.build(attributes);
    return item.save({ dashboard_id: this.dashboard.id });
  }

  get url() {
    return '/api/ui/dashboards/items';
  }

  get model() {
    return DashboardItemModel;
  }
}

export default DashboardItemCollection;
