import { computed } from 'mobx';
import Collection from 'core/model/Collection';
import MetricComponentModel from './MetricComponentModel';

class MetricComponentsCollection extends Collection {
  get baseSort() {
    return {
      field: 'parent_position',
      direction: 'asc'
    };
  }

  get defaultSortState() {
    return {
      field: 'name',
      direction: 'asc'
    };
  }

  get secondarySort() {
    return {
      field: 'parent_position',
      direction: 'asc'
    };
  }

  get defaultGroupBy() {
    return 'type';
  }

  get url() {
    return '/api/ui/recon/components';
  }

  get model() {
    return MetricComponentModel;
  }

  get isTreeGroupBy() {
    return this.groupBy === 'parent';
  }

  buildTree(parentIndex) {
    return this.models
      .filter((m) => m.get('parent') + 0 === parentIndex)
      .map((m) => {
        m.children = this.buildTree(m.get('index'));
        return m;
      });
  }

  @computed
  get parentIndexMap() {
    const map = {};
    const models = this.get();
    for (let i = 0; i < models.length; i += 1) {
      const m = models[i];
      const parentIndex = m.get('parent') + 0;
      map[parentIndex] = map[parentIndex] || [];
      map[parentIndex].push(m);
    }
    return map;
  }

  buildList(parentIndex, collapsedKeys, currDepth = 0) {
    // Look at non-filtered list so we show parents with visible children
    const models = this.parentIndexMap[parentIndex] || [];
    const result = [];
    for (let i = 0; i < models.length; i += 1) {
      const m = models[i];
      m.treeDepth = currDepth + 1;

      // Get the children first
      const children = this.buildList(m.get('index'), collapsedKeys, currDepth + 1);

      // Whether or not we show the children, we need to know if it has children!
      m.hasChildren = children.length > 0;

      // Include the model if in the filtered list or has visible children
      if (this.models.includes(m) || children.length > 0) {
        result.push(m);

        // Don't show children if collapsed, but parent should still be visible
        if (!collapsedKeys?.has(m.get('index'))) {
          result.push(...children);
        }
      }
    }
    return result;
  }

  @computed
  get groupedData() {
    if (this.isTreeGroupBy) {
      return this.buildList(0);
    }

    return super.groupedData;
  }

  getGroupedData(collapsedKeys) {
    return this.buildList(0, collapsedKeys);
  }

  get maxTreeDepth() {
    const list = this.groupedData;
    let maxDepth = 1;
    list.forEach((m) => {
      if (maxDepth < m.treeDepth) {
        maxDepth = m.treeDepth;
      }
    });
    return maxDepth;
  }
}

export default MetricComponentsCollection;
