import { action, isObservableObject } from 'mobx';
import Model from 'core/model/Model';
import { showErrorToast, showSuccessToast } from 'core/components/toast';

export const AS_GROUP_DIMENSIONS = [
  { name: 'kt_src_as_group', direction: 'src', displayName: 'Source AS Group' },
  { name: 'kt_dst_as_group', direction: 'dst', displayName: 'Destination AS Group' }
];

class AsGroupModel extends Model {
  get urlRoot() {
    return '';
  }

  get defaults() {
    return {
      name: '',
      asNumbers: []
    };
  }

  get removalConfirmText() {
    return {
      title: 'Remove Group',
      text: `Are you sure you want to remove ${this.get('name')}?`
    };
  }

  get sortValues() {
    return {
      asNumbers: () => this.asNumberCount
    };
  }

  get asNumberCount() {
    return (this.get('asNumbers') || []).reduce((count, val) => {
      const range = val.match(/(\d+)-(\d+)/);
      if (range) {
        return count + (range[2] - range[1]);
      }
      return count + 1;
    }, 0);
  }

  @action
  destroy = async () => {
    const value = this.get('name');

    this.requestStatus = 'destroying';
    this.requestProgress = 0;

    await Promise.all(
      AS_GROUP_DIMENSIONS.map(({ name, direction }) => {
        const idField = `${direction}Id`;
        const populator = this.collection.dimensions[name].populators.find({ id: this.get(idField) });
        return populator.destroy({ toast: false });
      })
    )
      .catch(() => {
        this.setRequestStatus(null);
        showErrorToast('Error removing Group');
        throw new Error('Error removing Group');
      })
      .then(
        action(() => {
          this.setRequestStatus(null);
          this.collection.remove(this.id);
          showSuccessToast(`Group ${value} removed successfully`, {
            title: 'Group Removed'
          });
        })
      );
  };

  @action
  save = async (attributes) => {
    const { isNew } = this;
    const value = attributes.name || this.get('value');

    this.requestStatus = isNew ? 'creating' : 'updating';
    this.requestProgress = 0;

    const updatePopulators = AS_GROUP_DIMENSIONS.map(({ name, direction }) => {
      let populator;
      const idField = `${direction}Id`;

      if (!isNew) {
        populator = this.collection.dimensions[name].populators.find({
          id: this.get(idField)
        });
      } else {
        populator = this.collection.dimensions[name].populators.forge();
      }

      return populator.save(
        {
          value,
          direction,
          asn: attributes.asNumbers
        },
        {
          toast: false
        }
      );
    });

    const [src, dst] = await Promise.all(updatePopulators).catch(() => {
      this.setRequestStatus(null);
      showErrorToast('Error saving Group');
      throw new Error('Error saving Group');
    });

    const groupData = AsGroupModel.getData(src, dst);

    if (isNew) {
      const group = this.collection.build(groupData);
      this.collection.add(group);

      // set selected after dialog has closed
      setTimeout(
        action(() => {
          this.collection.selected = group;
        })
      );
    } else {
      this.collection.find({ id: groupData.id }).set(groupData);
    }

    this.setRequestStatus(null);
    showSuccessToast(`Group ${value} saved successfully`, {
      title: isNew ? 'Group Added' : 'Group Updated'
    });
  };

  addASN(value) {
    const attributes = this.get();
    const ASNs = Array.isArray(value) ? value : value.split(/,\s*/);
    attributes.asNumbers = attributes.asNumbers.concat(ASNs).sort((a, b) => Number(a) - Number(b));
    return this.save(attributes);
  }

  removeASN(asn) {
    const attributes = this.get();
    attributes.asNumbers = attributes.asNumbers.filter((val) => val !== asn);
    return this.save(attributes);
  }

  static getData(src, dst) {
    const { asn, value: name } = isObservableObject(src) ? src.toJS() : src;
    const id = `${src.id}-${dst.id}`;

    return {
      id,
      srcId: src.id,
      dstId: dst.id,
      name,
      asNumbers: asn || []
    };
  }
}

export default AsGroupModel;
