import { showErrorToast, showSuccessToast } from 'core/components';

import { observable } from 'mobx';
import { api } from 'core/util';
import EmbeddedCacheInterfaceCollection from '../interface/EmbeddedCacheInterfaceCollection';
import CdnCollection from './CdnCollection';
import EmbeddedCacheCollection from './EmbeddedCacheCollection';
import * as queries from './queries';

const { getCdnFilters } = queries;

class CdnStore {
  collection = new CdnCollection();

  embeddedCacheInterfaces = new EmbeddedCacheInterfaceCollection([], { sortState: { field: 'interface_ip' } });

  customEmbeddedCaches = new EmbeddedCacheCollection();

  @observable.shallow
  embeddedCacheOptions = [];

  queries = queries;

  moduleName = 'cdn';

  get filters() {
    return getCdnFilters({ moduleName: this.moduleName });
  }

  navigateToCdn = (id) => {
    this.history.push(`/v4/service-provider/cdn/${id}`);
  };

  getModuleConfig = () => this.store.$moduleConfig.fetchModuleConfig(this.moduleName);

  setModuleConfig = (newSettings) =>
    this.getModuleConfig()
      .then((existingSettings) =>
        this.store.$moduleConfig.saveModuleConfig({
          module: this.moduleName,
          settings: { ...existingSettings, ...newSettings }
        })
      )
      .then(
        (success) => {
          showSuccessToast('Configuration saved.');
          return success;
        },
        (error) => {
          console.error('moduleConfig save error', error);
          showErrorToast('Error saving configuration.');
          return error;
        }
      );

  async fetchEmbeddedCacheInterfaces() {
    if (this.embeddedCacheInterfaces.lastFetched > Date.now() - this.embeddedCacheInterfaces.minFetchInterval) {
      return this.embeddedCacheInterfaces;
    }

    return this.embeddedCacheInterfaces.fetch();
  }

  async fetchEmbeddedCacheOptions() {
    if (this.embeddedCacheOptions.length > 0) {
      return this.embeddedCacheOptions;
    }

    return api.get('/api/ui/cdn/embedded-cache/options').then((cdns) => {
      this.embeddedCacheOptions = cdns
        .map(({ name }) => ({ value: name, label: name }))
        .sort((a, b) => a.value.localeCompare(b.value));
      return this.embeddedCacheOptions;
    });
  }
}

export default new CdnStore();
