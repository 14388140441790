import React from 'react';
import { computed } from 'mobx';
import moment from 'moment';

import { Flex, Tag, Text } from 'core/components';
import Collection from 'core/model/Collection';
import { intlCurrency } from 'app/util/utils';
import CostSummaryModel from './CostSummaryModel';

class CostSummariesCollection extends Collection {
  get model() {
    return CostSummaryModel;
  }

  get defaultSortState() {
    return {
      field: 'month',
      direction: 'asc'
    };
  }

  formatOption({ month, cost, currency }) {
    return {
      value: month,
      label: (
        <Flex flex={1} justifyContent="space-between">
          <Text>{moment(month).format('MMM YYYY')}</Text>
          <Tag round small minimal>
            {intlCurrency(cost, currency)}
          </Tag>
        </Flex>
      )
    };
  }

  @computed
  get summaryMonthOptions() {
    return this.models
      .filter((model) => moment(model.get('month')).isSameOrBefore(moment(), 'month'))
      .map((model) =>
        this.formatOption({
          month: model.get('month'),
          cost: model.get('cost'),
          currency: model.get('currency')
        })
      );
  }

  getMonthOptionsByEntity(entityType /* providers, connTypes, sites */, id) {
    return this.models
      .filter((model) => moment(model.get('month')).isSameOrBefore(moment(), 'month'))
      .map((model) => {
        const entity = model.get(entityType) ? model.get(entityType)[id] : undefined;

        return this.formatOption({
          month: model.get('month'),
          cost: entity?.cost || 0,
          currency: entity?.currency || model.get('currency') || undefined,
          disabled: !entity
        });
      });
  }
}

export default CostSummariesCollection;
