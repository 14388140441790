import { buildFilterGroup } from 'core/util/filters';

import getBaseQuery from './getBaseQuery';
import getQueryFilterGroups from './getQueryFilterGroups';

export default function asnBehindHorizonQuery(queryFilters, asn) {
  return {
    ...getBaseQuery({ metric: ['AS_dst'], queryFilters, asn }),
    viz_type: 'horizon',
    sync_extents: true,
    aggregateTypes: ['p95th_bits_per_sec', 'p95th_dst_route_prefix', 'p95th_dst_ip', 'avg_bytes_per_dst_ip'],
    aggregateThresholds: {
      p95th_bits_per_sec: 0,
      p95th_dst_ip: 0,
      p95th_dst_route_prefix: 0,
      avg_bytes_per_dst_ip: 0
    },
    depth: 125,
    units: ['bytes', 'unique_dst_route_prefix', 'unique_dst_ip', 'bytes_per_dst_ip'],
    outsort: 'p95th_bits_per_sec',
    topx: 20,
    aggregates: [
      {
        value: 'p95th_bits_per_sec',
        column: 'f_sum_both_bytes',
        fn: 'percentile',
        label: 'Bits/s Sampled at Ingress + Egress 95th Percentile',
        rank: 95,
        unit: 'bytes',
        group: 'Bits/s Sampled at Ingress + Egress',
        origLabel: '95th Percentile',
        sample_rate: 1,
        raw: true,
        name: 'p95th_bits_per_sec'
      },
      {
        value: 'p95th_dst_route_prefix',
        column: 'f_hll(inet_dst_route_prefix,0.0001)',
        fn: 'percentile',
        rank: 95,
        label: 'Unique Route Prefixes Destination 95th Percentile',
        unit: 'unique_dst_route_prefix',
        group: 'Unique Route Prefixes Destination',
        origLabel: '95th Percentile',
        sample_rate: 1,
        name: 'p95th_dst_route_prefix'
      },
      {
        value: 'p95th_dst_ip',
        column: 'f_hll(inet_dst_addr,0.0001)',
        fn: 'percentile',
        rank: 95,
        label: 'Destination IPs Unique Count 95th Percentile',
        unit: 'unique_dst_ip',
        group: 'Destination IPs Unique Count',
        origLabel: '95th Percentile',
        sample_rate: 1,
        name: 'p95th_dst_ip'
      },
      {
        value: 'avg_bytes_per_dst_ip',
        column: 'bytes_per_dst_ip',
        fn: 'average',
        label: 'Destination IPs Bitrate Per IP Average',
        unit: 'bytes_per_dst_ip',
        group: 'Destination IPs Bitrate Per IP',
        origLabel: 'Average',
        sample_rate: 1,
        name: 'avg_bytes_per_dst_ip'
      }
    ],
    filters: {
      connector: 'All',
      filterGroups: [
        buildFilterGroup({
          filters: [
            { filterField: 'dst_bgp_aspath', operator: '~', filterValue: `_${asn}_` },
            { filterField: 'dst_as', operator: '<>', filterValue: asn }
          ]
        }),
        ...getQueryFilterGroups(queryFilters, 'dst')
      ]
    }
  };
}
