import { computed } from 'mobx';
import { orderBy } from 'lodash';

import Collection from 'core/model/Collection';
import $auth from 'app/stores/$auth';
import User from './UserModel';

class UserCollection extends Collection {
  isAdmin = false;

  companyId = null;

  constructor(data, options) {
    super(data, options);

    const { admin, companyId } = options || {};
    if (admin) {
      this.isAdmin = true;
      this.companyId = companyId;
    }
  }

  get url() {
    if (this.isAdmin) {
      return `/api/ui/sudo/user/${this.companyId}`;
    }
    return '/api/ui/user';
  }

  get model() {
    return User;
  }

  get defaultSortState() {
    return {
      field: 'user_email',
      direction: 'asc'
    };
  }

  get defaultPresetFilter() {
    return {
      fn: (model) => !model.get('user_group_id')
    };
  }

  get filterFieldWhitelist() {
    return new Set(['id', 'user_full_name', 'user_email', 'userLevel']);
  }

  async fetch(options = {}) {
    if ($auth.isDemoUser) {
      return Promise.resolve();
    }

    return super.fetch(options);
  }

  @computed
  get userSelectOptions() {
    const options = this.models.map((user) => {
      const { id, user_full_name, user_email, user_group_id } = user.toJS();
      return {
        user_email,
        user_full_name,
        value: id,
        label: `${user_full_name} (${user_email})`,
        user_group_id,
        // I believe on the front, userLevel are strings like "Administrator" converted in
        // $dictionary.dictionary.userLevels but this might be considered confusing
        role: user.userLevel,
        user_level: user.get('user_level'),
        model: user
      };
    });

    return orderBy(options, ['user_group_id', 'user_level', 'user_name'], ['desc', 'desc', 'asc']);
  }
}

export default UserCollection;
