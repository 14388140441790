import { computed } from 'mobx';
import Model from 'core/model/Model';
import $dashboards from 'app/stores/dashboard/$dashboards';
import { days, delivery_period_options } from 'app/util/subscription';
import { exportContentMap } from 'app/util/export';
import moment from 'moment';

const weekOptions = [
  { value: 0, label: 'Sunday' },
  { value: 1, label: 'Monday' },
  { value: 2, label: 'Tuesday' },
  { value: 3, label: 'Wednesday' },
  { value: 4, label: 'Thursday' },
  { value: 5, label: 'Friday' },
  { value: 6, label: 'Saturday' }
];

const dayOptions = Array.from({ length: 28 }, (e, i) => ({ value: i + 1, label: i + 1 }));

function getDaySuffix(day) {
  if (day % 10 === 1 && day !== 11) {
    return 'st';
  }
  if (day % 10 === 2 && day !== 12) {
    return 'nd';
  }
  if (day % 10 === 3 && day !== 13) {
    return 'rd';
  }

  return 'th';
}

class Subscription extends Model {
  get defaults() {
    return {
      delivery_period: 'week',
      lookback_days: 7,
      lookback_period: 'days',
      recurrence_ordinal: 1,
      parametric_fields: [],
      parametric_value: ''
    };
  }

  deserialize(data) {
    if (data && data.content_type === 'dashboard') {
      const dashboard = $dashboards.collection.get(data.content_id);

      if (dashboard) {
        data.parametric_value_type = dashboard.get('parametric_value_type');
        data.parametric_value_options = dashboard.get('parametric_value_options');

        if (data.group_key) {
          Object.assign(data, JSON.parse(data.group_key));
          if (data.parametric_fields && data.parametric_fields.length) {
            data.parametric_value = data.parametric_fields[0].value;
          }
        }
      }
    }

    if (data && data.content_type === 'alerts') {
      const filters = (data.content_metadata?.values || [])[0];

      if (filters) {
        const { startDate, endDate, ...restFilters } = filters;

        restFilters.startDate = moment(startDate);
        restFilters.endDate = moment(endDate);
        // Prefer lookback if we have it
        if (restFilters.lookback) {
          restFilters.startDate = moment().subtract(restFilters.lookback, 'seconds');
          restFilters.endDate = moment();
        }

        data.content_metadata.values[0] = restFilters;
      }
    }

    return data;
  }

  get omitDuringSerialize() {
    return [
      'date',
      'last_run',
      'subtitle',
      'time',
      'parametric_value',
      'parametric_value_type',
      'parametric_value_options'
    ];
  }

  get urlRoot() {
    return '/api/ui/subscriptions/';
  }

  @computed
  get allRecipients() {
    const recipients = this.get('recipients') && this.get('recipients').split(',');
    const cc = this.get('content_metadata.cc') && this.get('content_metadata.cc').split(',');
    const bcc = this.get('content_metadata.bcc') && this.get('content_metadata.bcc').split(',');
    return [...(recipients || []), ...(cc || []), ...(bcc || [])].map((r) => r.trim());
  }

  get messages() {
    return {
      create: `Subscription "${this.get('title')}" was added successfully`,
      update: `Subscription "${this.get('title')}" was updated successfully`,
      destroy: `Subscription "${this.get('title')}" was removed successfully`
    };
  }

  get removalConfirmText() {
    return {
      title: 'Remove Subscription',
      text: `Are you sure you want to remove ${this.get('title')}?`
    };
  }

  @computed
  get deliveryFrequency() {
    const deliveryPeriod = this.get('delivery_period');
    const deliveryPeriodDisplay = delivery_period_options[deliveryPeriod];
    const recurrence = this.get('recurrence_ordinal');

    if (deliveryPeriod === 'week') {
      return `${deliveryPeriodDisplay} every ${days[recurrence]}`;
    }
    if (deliveryPeriod === 'month') {
      return `${deliveryPeriodDisplay} on the ${recurrence}${getDaySuffix(recurrence)}`;
    }

    return deliveryPeriodDisplay;
  }

  @computed
  get lookbackDays() {
    const value = parseInt(this.get('lookback_days'));
    if (value === 1) {
      return '1 Day';
    }
    return value === 7 ? '1 Week' : `${value} Days`;
  }

  @computed
  get isMultiSelectable() {
    const multiSelectableContentTypes = [
      'capacityplans',
      'costproviders',
      'costconnectivitytypes',
      'costsites',
      'costsitemarkets'
    ];
    return multiSelectableContentTypes.includes(this.get('content_type'));
  }

  @computed
  get isSummary() {
    const summaryContentTypes = Object.entries(exportContentMap)
      .filter(([, { isSummary }]) => isSummary)
      .map(([key]) => key);
    return summaryContentTypes.includes(this.get('content_type'));
  }

  recurrenceOrdinalOptions(period) {
    if (period === 'week') {
      return weekOptions;
    }
    return dayOptions;
  }
}

export default Subscription;
