import Collection from 'core/model/Collection';
import DeckModel from './DeckModel';

class DeckCollection extends Collection {
  get url() {
    return '/api/ui/decks';
  }

  get model() {
    return DeckModel;
  }
}

export default DeckCollection;
