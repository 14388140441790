import { action, computed, observable } from 'mobx';
import { orderBy } from 'lodash';
import { api } from 'core/util';
import { Statuses } from 'shared/agents/constants';
import KentikAgentCollection from './KentikAgentCollection';

class KentikAgentStore {
  @observable
  loading = false;

  agentLookups = {};

  // get all agents (incl unregistered)
  collection = new KentikAgentCollection({ registered: true, unregistered: true });

  // For the Deployment path.  Not loaded initially
  unregisteredCollection = new KentikAgentCollection({ registered: false, unregistered: true });

  @action
  setLoading(isLoading = true) {
    this.loading = isLoading;
  }

  fetchDownAgents() {
    return api.get('/api/ui/kentik-agent/agents/down-count');
  }

  @computed
  get findLongestHearbeat() {
    let longestHearbeat = 30;
    if (this.collection?.length > 0) {
      this.collection.forEach((item) => {
        if (parseInt(item?.heartBeatPeriod) > longestHearbeat) {
          longestHearbeat = parseInt(item.heartBeatPeriod);
        }
      });
    }
    return longestHearbeat;
  }

  @computed
  get kentikAgentOptions() {
    const options = this.collection.map((agent) => ({
      value: agent.get('agent_id'),
      label: agent.get('display_name') || agent.get('host_name'),
      disabled: agent.get('status') !== Statuses.UP,
      ...agent.get()
    }));

    return orderBy(options, ['cdate'], ['desc']);
  }

  @computed
  get authorizedKentikAgentOptions() {
    return this.kentikAgentOptions.filter((m) => m.status !== Statuses.NOT_AUTHORIZED);
  }

  @computed
  get availableRunningKentikAgentOptions() {
    return this.kentikAgentOptions.filter((m) => m.status === Statuses.UP);
  }

  @action
  startPollingForCapabilityReadiness = (agentId, capabilityKey) =>
    new Promise((resolve, reject) => {
      const interval = setInterval(() => {
        this.collection
          .fetch({ force: true })
          .then(() => {
            const agent = this.collection.findByAgentId(agentId);
            const capabilities = agent.get('capabilities');
            const cap = capabilities.find((capability) => capability.name === capabilityKey);

            if (cap && cap.status === Statuses.UP) {
              resolve(agent);
              clearInterval(interval);
            } else {
              console.warn(`Waiting for ${capabilityKey} to be ready...`);
            }
          })
          .catch((err) => {
            console.error(`Error occurred during fetching agents: ${err}`);
            reject(err);
          });
      }, 1000);
    });

  // This updates the local data using some response data but does not alter anything on the server
  updateCapabilitiesWoFetch(agentId, capabilityKey, enable) {
    const agent = this.collection.findByAgentId(agentId);
    if (agent) {
      const capabilities = agent.get('capabilities');
      const cap = capabilities.find((capability) => capability.name === capabilityKey);
      if (cap) {
        if (enable) {
          // Concern: sometimes when you enable a capability, its actually Statuses.DOWN
          // but we won't know that until the unregistered call establishes that
          cap.status = Statuses.RUNNING;
        } else {
          cap.status = Statuses.DISABLED;
        }
      } else if (enable) {
        // need to add a temp node bc we just installed
        agent.set('capabilities', [...capabilities, { name: capabilityKey, status: Statuses.RUNNING }]);
      }
    }
  }

  getNextRefreshFromHeartbeat(agentId) {
    const agent = this.collection.findByAgentId(agentId);
    return agent ? parseInt(agent.get('last_seen')) + parseInt(agent.get('heartBeatPeriod')) : null;
  }

  fetchDevicesForAgent(agentId) {
    return api.get(`/api/ui/kentik-agent/agents/devices/${encodeURIComponent(agentId)}`);
  }

  // //////// All V2 API calls:
  // Assumes an AUTHORIZED agent
  getKentikAgentDetails(agentId) {
    this.setLoading(true);
    return api.get(`/api/ui/kentik-agent/agent/${encodeURIComponent(agentId)}`).then((detailsResponse) => {
      this.setLoading(false);
      return detailsResponse;
    });
  }

  updateCapability(agentId, capabilityKey, enabled = true) {
    this.setLoading(true);

    return api
      .put(
        `/api/ui/kentik-agent/agents/capabilities/${encodeURIComponent(agentId)}/${encodeURIComponent(capabilityKey)}`,
        {
          body: {
            enabled,
            // To be sure this is not an install
            initial: false
          }
        }
      )
      .then((res) => {
        this.setLoading(false);
        return res;
      });
  }

  authorizeAgent(installId) {
    this.setLoading(true);

    return api
      .post(`/api/ui/kentik-agent/agents/authorize/${encodeURIComponent(installId)}`, { body: {} })
      .then((responseObject) => {
        this.setLoading(false);
        if (responseObject.success === false) {
          throw new Error('Error registering Agent');
        }
        return responseObject.agent_id;
      });
  }

  installCapabilityAndEnable(agentId, capabilityKey) {
    this.setLoading(true);

    return api
      .put(
        `/api/ui/kentik-agent/agents/installcapability/${encodeURIComponent(agentId)}/${encodeURIComponent(
          capabilityKey
        )}`,
        {
          body: {
            enabled: true,
            // this is the initial install call, configs might be written depending on the capability
            initial: true
          }
        }
      )
      .then((responseObject) => {
        this.setLoading(false);
        return responseObject;
      });
  }

  // Assumes an AUTHORIZED agent
  getAgentCapabilities(agentId) {
    this.setLoading(true);
    return api
      .get(`/api/ui/kentik-agent/agents/capabilities/${encodeURIComponent(agentId)}`)
      .then((capabilitiesDetailsResponse) => {
        this.setLoading(false);
        return capabilitiesDetailsResponse;
      });
  }

  getAgentCapabilityConfig(agentId, capabilityKey, configId) {
    this.setLoading(true);
    return api
      .get(
        `/api/ui/kentik-agent/agents/capabilitiesconfig/${encodeURIComponent(agentId)}/${encodeURIComponent(
          capabilityKey
        )}?configName=${encodeURIComponent(configId)}`
      )
      .then((capabilitiesConfigResponse) => {
        this.setLoading(false);
        return capabilitiesConfigResponse;
      });
  }

  updateAgentCapabilityConfig(agentId, capabilityKey, updateParams, configId) {
    this.setLoading(true);
    return api
      .put(
        `/api/ui/kentik-agent/agents/capabilitiesconfig/${encodeURIComponent(agentId)}/${encodeURIComponent(
          capabilityKey
        )}?configName=${encodeURIComponent(configId)}`,
        {
          body: { updateParams }
        }
      )
      .then((capabilitiesConfigResponse) => {
        this.setLoading(false);
        return capabilitiesConfigResponse;
      });
  }

  updateMetadata(agentId, options) {
    this.setLoading(true);
    let reloadSites = false;

    // name, description, site
    const { display_name, description, site, closest_device_id } = options;

    if (site.lat) {
      reloadSites = true;
    }

    return api
      .put(`/api/ui/kentik-agent/agents/${encodeURIComponent(agentId)}`, {
        body: { name: display_name, description, site, closest_device_id }
      })
      .then((res) => {
        this.setLoading(false);
        if (reloadSites) {
          return this.store.$sites.collection.fetch({ force: true }).then(() => res);
        }
        return res;
      });
  }

  restartAgentCapability(agentId, capabilityKey) {
    this.setLoading(true);
    return api
      .post(
        `/api/ui/kentik-agent/agents/restartcapability/${encodeURIComponent(agentId)}/${encodeURIComponent(
          capabilityKey
        )}`
      )
      .then(() => {
        this.setLoading(false);
      });
  }

  lookupAgents(agentIds = []) {
    const lookupIds = [];
    const cachedResults = agentIds.reduce((acc, agentId) => {
      const cachedResult = this.agentLookups[agentId];
      if (cachedResult) {
        acc.push(cachedResult);
      } else {
        lookupIds.push(agentId);
      }
      return acc;
    }, []);

    if (!lookupIds.length) {
      return Promise.resolve().then(() => cachedResults);
    }
    return api.post('/api/ui/kentik-agent/agents/lookup', { body: { agent_ids: lookupIds } }).then((agents) => {
      agents.forEach((agent) => {
        this.agentLookups[agent.agent_id] = agent;
      });
      return [...cachedResults, ...agents];
    });
  }
}

export default new KentikAgentStore();
