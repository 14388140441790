function getBaseQuery() {
  return {
    metric: [],
    viz_type: 'table',
    aggregateTypes: ['p95th_bits_per_sec'],
    lookback_seconds: 86400,
    all_devices: true,
    show_overlay: false,
    show_total_overlay: false,
    customAsGroups: false
  };
}

function buildInterfaceFilterGroups(interfaces, deviceIdField = 'i_device_id', snmpIdField = 'input_port') {
  return interfaces.map((intf) => ({
    name: '',
    named: false,
    connector: 'All',
    not: false,
    autoAdded: '',
    filters: [
      {
        filterField: deviceIdField,
        operator: '=',
        filterValue: intf.device_id
      },
      {
        filterField: snmpIdField,
        operator: '=',
        filterValue: intf.snmp_id
      }
    ],
    saved_filters: [],
    filterGroups: []
  }));
}

export function getQuery({ metric, interfaces, isSrc }) {
  return {
    ...getBaseQuery(),
    metric,
    filters: {
      connector: 'All',
      filterGroups: [
        {
          connector: 'Any',
          filterGroups: buildInterfaceFilterGroups(interfaces, 'i_device_id', isSrc ? 'input_port' : 'output_port')
        }
      ]
    }
  };
}
