import { computed } from 'mobx';
import CloudMapModel from './CloudMapModel';

export default class AzureMapModel extends CloudMapModel {
  /*
    returns a multidimensional array used for searching azure tags on the model

    [
      ['environment', 'production'],
      ['account', 'finance'],
      ...
    ]
  */
  @computed
  get searchTags() {
    const tags = this.get('tags') || {};
    return Object.keys(tags).map((key) => [key, tags[key]]);
  }
}
