import { action } from 'mobx';

import api from 'core/util/api';
import Collection from 'core/model/Collection';

import RuleModel from './RuleModel';

class RuleCollection extends Collection {
  get url() {
    return '/api/ui/interfaces/rules';
  }

  get model() {
    return RuleModel;
  }

  @action
  updateRuleInterfaceMatchData(ruleInterfaceMatches) {
    const keys = Object.keys(ruleInterfaceMatches);

    if (keys.length > 0) {
      keys.forEach((key) => {
        const rule = this.get(key);
        if (rule) {
          rule.set(ruleInterfaceMatches[key]);
        }
      });
    } else {
      this.each((rule) =>
        rule.set({
          interfacesClassified: [],
          interfacesMatched: []
        })
      );
    }
  }

  @action
  getRuleMatchResults = () => api.get(`${this.url}/results`);
}

export default RuleCollection;
