import { computed } from 'mobx';
import { getAddressString } from 'app/util/geocode';
import { Box, Text } from 'core/components';
import React from 'react';
import $sites from './$sites';

export const siteOptionsWithAddress = computed(() => {
  if (!$sites.collection.hasFetched) {
    $sites.collection.fetch();
    return [];
  }

  return $sites.collection
    .map((site) => ({
      id: site.id,
      value: site.id,
      name: site.get('title'),
      filterLabel: `${site.get('title')},${getAddressString({
        address: site.get('address'),
        city: site.get('city'),
        country: site.get('country'),
        region: site.get('region'),
        postal: site.get('postal')
      })}`,
      label: (
        <Box>
          <Text as="div">{site.get('title')}</Text>
          <Text small muted>
            {getAddressString({
              address: site.get('address'),
              city: site.get('city'),
              country: site.get('country'),
              region: site.get('region'),
              postal: site.get('postal')
            })}
          </Text>
        </Box>
      )
    }))
    .sort((a, b) => a.name.localeCompare(b.name));
});
