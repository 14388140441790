import PolicyModel from '../PolicyModel';
import { transformFormValuesToEventPolicy } from '../policyUtils';

class EventPolicyModel extends PolicyModel {
  get urlRoot() {
    return '/api/ui/alerting/policies/kevent';
  }

  buildPolicyFromFormValues = (formValues = {}, passedOverrides) => {
    if (this.isNew) {
      return transformFormValuesToEventPolicy(formValues);
    }

    return transformFormValuesToEventPolicy(formValues, passedOverrides || this.get());
  };
}

export default EventPolicyModel;
