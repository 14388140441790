import Model from 'core/model/Model';

export default class MkpSettingsModel extends Model {
  get defaults() {
    return {
      config: {}
    };
  }

  get urlRoot() {
    return '/api/ui/mkp';
  }

  deserialize(data) {
    data.id = data.company_id;
    return super.deserialize(data);
  }

  serialize(data) {
    return super.serialize({ ...data, id: undefined });
  }
}
