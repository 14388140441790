import { computed, observable, action } from 'mobx';
import moment from 'moment';

import Model from 'core/model/Model';
import { Socket } from 'core/util';
import { DEFAULT_DATETIME_FORMAT, getQueryTimeInterval } from 'core/util/dateUtils';
import { mergeFilterGroups, getQueryMapFromAggregateFilterQuery } from 'core/util/filters';

import $app from 'app/stores/$app';
import $auth from 'app/stores/$auth';
import $dataviews from 'app/stores/$dataviews';
import $dictionary from 'app/stores/$dictionary';
import { tagQueryBucket } from 'app/util/tagging/tagEngine';
import { encodeQuery } from 'app/util/sharedLink';

import { getTotalTrafficOverlay, getHistoricalOverlay } from './QueryModel';
import QueryCollection from './QueryCollection';
import QueryResultsCollection from './QueryResultsCollection';

function mergeTimeseriesData(row, existingRawData) {
  if (row.rawData) {
    Object.keys(row.rawData).forEach((flowDataUnit) => {
      const { flow } = row.rawData[flowDataUnit];
      const { flow: existingFlow } = existingRawData || {};

      // We're going to ignore timestamps and assume they're close enough to not matter and that there's no gaps, etc.
      if (existingFlow) {
        row.rawData[flowDataUnit] = flow.map((flowPoint, idx) => [
          flowPoint[0],
          flowPoint[1] + existingFlow[idx] ? existingFlow[idx][1] : 0,
          flowPoint[2]
        ]);
      }
    });
  }
}

class QueryBucketModel extends Model {
  queryResults = new QueryResultsCollection();

  queries = new QueryCollection();

  subscribedQueries = [];

  encodedQueries = [];

  overlayQueries = new QueryCollection();

  @observable
  loading = true;

  @observable
  loadedCount = 0;

  @observable
  loadingCount = 0;

  @observable
  error = null;

  sockets = [];

  socketIdHistory = [];

  get url() {
    return '';
  }

  get defaults() {
    return {
      name: 'Default',
      sampleRateFactor: 1
    };
  }

  @computed
  get firstQuery() {
    return this.queries.size ? this.queries.at(0) : null;
  }

  @computed
  get hasQueries() {
    return this.queries.size || this.overlayQueries.size;
  }

  @computed
  get isLiveQuery() {
    return !!(this.updateFrequency || this.firstQuery?.get('kmetrics.streamingUpdate'));
  }

  @computed
  get updateFrequency() {
    return this.firstQuery && this.firstQuery.get('update_frequency');
  }

  set updateFrequency(frequency) {
    this.firstQuery.set({ update_frequency: frequency });
  }

  @action
  setLoading(loading) {
    this.loading = loading;
  }

  @computed
  get fullyLoaded() {
    return this.loadedCount > 0 && this.loadedCount >= this.loadingCount;
  }

  @computed
  get label() {
    if (!this.get('isPreviousPeriod') && this.collection?.hasPeriodOverPeriod) {
      return 'Current Period';
    }

    return this.get('name');
  }

  get shortLabel() {
    return this.label.replace('Period', '').trim();
  }

  get filterDimensionCsv() {
    const aggregates = this.firstQuery.get('aggregates');
    const filterDimensionName = this.firstQuery.get('filterDimensionName');

    const { units: unitsDict } = $dictionary.dictionary;

    const rows = [];
    const header = [];

    header.push(`"${filterDimensionName}"`);

    aggregates.forEach((aggregate) => {
      if (aggregate.origLabel) {
        header.push(`"${aggregate.origLabel} ${unitsDict[aggregate.unit]}"`);
      } else if (aggregate.label) {
        header.push(`"${aggregate.label} ${unitsDict[aggregate.unit]}"`);
      }
    });

    rows.push(header);

    this.queryResults.nonOverlayRows.forEach((row) => {
      const values = [];

      values.push(`"${row.get('name')}"`);

      aggregates.forEach((aggregate) => {
        if (aggregate.origLabel || aggregate.label) {
          values.push(row.get(aggregate.value));
        }
      });

      rows.push(values);
    });

    return rows.map((row) => row.join(',')).join('\r\n');
  }

  get filterDimensionRawCsv() {
    const aggregates = this.firstQuery.get('aggregates');
    const outsort = this.firstQuery.get('outsort');
    const secondaryOutsort = this.firstQuery.get('secondaryOutsort');
    const filterDimensionName = this.firstQuery.get('filterDimensionName');

    const { outsortUnit, secondaryOutsortUnit } = this.firstQuery;
    const { countColumns, units: unitsDict } = $dictionary.dictionary;

    const outsortDataKey = aggregates.reduce((value, aggregate) => {
      const { column, name, raw } = aggregate;
      if (name === outsort && raw) {
        let newColName = column.replace('f_sum_', '').replace('bytes', 'bits').replace('trautocount', 'flows');
        if (!aggregate.is_count && countColumns.indexOf(column) === -1) {
          newColName += '_per_sec';
        }
        return newColName;
      }
      return value;
    }, '');

    let secondaryOutsortDataKey = null;
    if (secondaryOutsort) {
      secondaryOutsortDataKey = aggregates.reduce((value, aggregate) => {
        const { column, name, raw } = aggregate;
        if (name === secondaryOutsort && raw) {
          let newColName = column.replace('f_sum_', '').replace('bytes', 'bits').replace('trautocount', 'flows');
          if (!aggregate.is_count && countColumns.indexOf(column) === -1) {
            newColName += '_per_sec';
          }
          return newColName;
        }
        return value;
      }, '');
    }

    const models = this.queryResults.getRawDataRows();
    const rows = [];
    const header = [];

    header.push(`"${filterDimensionName}"`);

    header.push('timestamp');
    header.push(`"${unitsDict[outsortUnit]}"`);
    if (secondaryOutsort) {
      header.push(`"${unitsDict[secondaryOutsortUnit]}"`);
    }
    rows.push(header);

    models.forEach((row) => {
      const rawData = row.get('rawData');
      if (rawData && rawData[outsortDataKey] && rawData[outsortDataKey].flow) {
        const metricValues = [];

        metricValues.push(`"${row.get('name')}"`);

        rawData[outsortDataKey].flow.forEach((timeRow, index) => {
          const values = [].concat(metricValues);
          values.push(timeRow[0]); // timestamp
          values.push(timeRow[1]); // value for outsort unit
          if (
            secondaryOutsortDataKey &&
            rawData[secondaryOutsortDataKey] &&
            rawData[secondaryOutsortDataKey].flow &&
            rawData[secondaryOutsortDataKey].flow[index]
          ) {
            // value for secondary outsort unit (abs here because sometimes they're negative for graphing purposes)
            values.push(Math.abs(rawData[secondaryOutsortDataKey].flow[index][1]));
          }
          rows.push(values);
        });
      }
    });

    return rows.map((row) => row.join(',')).join('\r\n');
  }

  get csv() {
    const { aggregates, metric: metrics } = this.firstQuery.get();
    const { chartTypesValidations, metricColumns, units: unitsDict } = $dictionary.dictionary;

    const rows = [];
    const header = [];
    const lastDatapoints = [];

    metrics.forEach((metric) => {
      header.push(`"${chartTypesValidations[metric] || metric}"`);
    });

    aggregates.forEach((aggregate) => {
      if (aggregate.origLabel) {
        header.push(`"${aggregate.origLabel} ${unitsDict[aggregate.unit]}"`);
      } else if (aggregate.label) {
        header.push(`"${aggregate.label} ${unitsDict[aggregate.unit]}"`);
      }
      if (aggregate.raw && !aggregate.value.includes('agg_total')) {
        lastDatapoints.push({
          valueKey: `${aggregate.column}__k_last`,
          label: `"Last Datapoint ${unitsDict[aggregate.unit]}"`
        });
      }
    });

    lastDatapoints.forEach((agg) => {
      header.push(agg.label);
    });

    rows.push(header);

    this.queryResults.nonOverlayRows.forEach((row) => {
      const values = [];
      metrics.forEach((metric) => {
        values.push(`"${row.get(metricColumns[metric] || metric)}"`);
      });

      aggregates.forEach((aggregate) => {
        if (aggregate.origLabel || aggregate.label) {
          values.push(row.get(aggregate.value));
        }
      });

      lastDatapoints.forEach((agg) => {
        values.push(row.get(agg.valueKey));
      });

      rows.push(values);
    });

    return rows.map((row) => row.join(',')).join('\r\n');
  }

  get rawCsv() {
    const aggregates = this.firstQuery.get('aggregates');
    const metrics = this.firstQuery.get('metric');
    const outsort = this.firstQuery.get('outsort');
    const secondaryOutsort = this.firstQuery.get('secondaryOutsort');

    const { outsortUnit, secondaryOutsortUnit } = this.firstQuery;
    const { chartTypesValidations, countColumns, metricColumns, units: unitsDict } = $dictionary.dictionary;

    const outsortDataKey = aggregates.reduce((value, aggregate) => {
      const { column, name, raw } = aggregate;
      if (name === outsort && raw) {
        let newColName = column.replace('f_sum_', '').replace('bytes', 'bits').replace('trautocount', 'flows');
        if (!aggregate.is_count && countColumns.indexOf(column) === -1) {
          newColName += '_per_sec';
        }
        return newColName;
      }
      return value;
    }, '');

    let secondaryOutsortDataKey = null;
    if (secondaryOutsort) {
      secondaryOutsortDataKey = aggregates.reduce((value, aggregate) => {
        const { column, name, raw } = aggregate;
        if (name === secondaryOutsort && raw) {
          let newColName = column.replace('f_sum_', '').replace('bytes', 'bits').replace('trautocount', 'flows');
          if (!aggregate.is_count && countColumns.indexOf(column) === -1) {
            newColName += '_per_sec';
          }
          return newColName;
        }
        return value;
      }, '');
    }

    const models = this.queryResults.getRawDataRows();
    const rows = [];
    const header = [];

    metrics.forEach((metric) => {
      header.push(`"${chartTypesValidations[metric] || metric}"`);
    });
    header.push('timestamp');
    header.push(`"${unitsDict[outsortUnit]}"`);
    if (secondaryOutsort) {
      header.push(`"${unitsDict[secondaryOutsortUnit]}"`);
    }
    rows.push(header);

    models.forEach((row) => {
      const rawData = row.get('rawData');
      if (rawData && rawData[outsortDataKey] && rawData[outsortDataKey].flow) {
        const metricValues = [];
        metrics.forEach((metric) => {
          metricValues.push(`"${row.get(metricColumns[metric] || metric)}"`);
        });

        rawData[outsortDataKey].flow.forEach((timeRow, index) => {
          const values = [].concat(metricValues);
          values.push(timeRow[0]); // timestamp
          values.push(timeRow[1]); // value for outsort unit
          if (
            secondaryOutsortDataKey &&
            rawData[secondaryOutsortDataKey] &&
            rawData[secondaryOutsortDataKey].flow &&
            rawData[secondaryOutsortDataKey].flow[index]
          ) {
            // value for secondary outsort unit (abs here because sometimes they're negative for graphing purposes)
            values.push(Math.abs(rawData[secondaryOutsortDataKey].flow[index][1]));
          }
          rows.push(values);
        });
      }
    });

    return rows.map((row) => row.join(',')).join('\r\n');
  }

  subscribeSocket(query, isOverlay = false, overlayIndex) {
    const target_company_id = query?.target_company_id || query?.[0]?.target_company_id; // Used for cross-company fetches, only on export.

    if ($app.debugModeEnabled) {
      if (Array.isArray(query)) {
        query.forEach((q) => (q.debug_info = $app.debugModeInfo));
      } else {
        query.debug_info = $app.debugModeInfo;
      }
    }

    this.loadingCount += 1;

    const socket = new Socket({
      outType: 'subscribe',
      inType: 'queryResults',
      delaySend: true,
      frequency: this.updateFrequency,
      onSuccess: action((rawData) => {
        // keep track of these over time for Kentik DevTools extensions
        this.socketIdHistory.push(socket.id);

        this.error = null;
        let data = [];

        if (query.aggregateFiltersEnabled === true) {
          const [aggregate] = query.aggregateTypes;

          rawData.forEach((origRow) => {
            // With multiple metrics, we have to merge them with each other on the fly AND with existing rows
            // With a single metric, we just have to worry about existing rows (from prior responses)
            if (query.metric.length > 1) {
              query.metric.forEach((metric, index, metrics) => {
                const row = { ...origRow };
                row[query.descriptor] = row[aggregate];
                const keyMetric = metric.startsWith('kt_')
                  ? metric.replace(/^kt_(src|dst)_as_group$/, 'i_$1_as_name')
                  : $dictionary.get(`metricColumns.${metric}`);
                const key = row[keyMetric];
                const label = query.aggregateFiltersDimensionLabel;

                // if we're on a secondary dimension and it matches the primary, then don't double count it
                // The "key" won't equal the metric value with AS Groups, hence the extra check
                if (index > 0 && (key === origRow[metrics[0]] || row[metric] === origRow[metrics[0]])) {
                  return;
                }

                if (row.lookup && row.lookup !== row.key) {
                  row[`${label}_raw`] = row[`${keyMetric}_raw`];
                  row[label.toLowerCase()] = row[keyMetric.toLowerCase()];
                  row.lookup = row[keyMetric.toLowerCase()];
                }

                row.key = key;
                row.keyMetric = keyMetric;
                row[label] = key;
                row[query.descriptor] = row[aggregate];

                // If there's a row already matching in this dataset, we just augment it and move on
                const matchingRow = data.find((dataRow) => dataRow.key === key);
                if (matchingRow) {
                  matchingRow[aggregate] += row[aggregate];
                  matchingRow[query.descriptor] += row[aggregate];
                  mergeTimeseriesData(row, matchingRow.rawData);

                  return;
                }

                // We want the aggregate (i.e. the outsort) to be the cumulative value of each filter
                // because otherwise won't make any sense. Do this by adding them up.
                const existingRow = this.queryResults.find({ isOverlay, key });
                if (existingRow) {
                  mergeTimeseriesData(row, existingRow.get('rawData'));

                  existingRow.set({
                    [query.descriptor]: row[aggregate] + (existingRow.get(query.descriptor) || 0),
                    [aggregate]: row[aggregate] + existingRow.get(aggregate),
                    rawData: row.rawData || existingRow.get('rawData')
                  });
                } else {
                  data.push(row);
                }
              });
            } else {
              const row = { ...origRow };
              row[query.descriptor] = row[aggregate];
              const keyMetric = query.metric[0].startsWith('kt_')
                ? query.metric[0].replace(/^kt_(src|dst)_as_group$/, 'i_$1_as_name')
                : $dictionary.get(`metricColumns.${query.metric[0]}`);
              const key = row[keyMetric];
              const label = query.aggregateFiltersDimensionLabel || query.metric[0];

              if (row.lookup && row.lookup !== row.key) {
                row[`${label}_raw`] = row[`${keyMetric}_raw`];
                row[label.toLowerCase()] = row[keyMetric.toLowerCase()];
                row.lookup = row[keyMetric.toLowerCase()];
              }

              row.key = key;
              row.keyMetric = keyMetric;
              row[label] = key;
              row[query.descriptor] = row[aggregate];

              // We want the aggregate (i.e. the outsort) to be the cumulative value of each filter
              // because otherwise won't make any sense. Do this by adding them up.
              const existingRow = this.queryResults.get().find((model) => model.get('key') === key);
              if (existingRow) {
                mergeTimeseriesData(row, existingRow.get('rawData'));

                existingRow.set({
                  [query.descriptor]: row[aggregate],
                  [aggregate]: row[aggregate] + existingRow.get(aggregate),
                  rawData: row.rawData || existingRow.get('rawData')
                });
              } else {
                data.push(row);
              }
            }
          });
        } else {
          data = rawData;
        }

        if (Array.isArray(query) && query.some((q) => q.filterDimensionsEnabled && q.filterDimensionSort === true)) {
          data.forEach((row) => {
            const fbdQuery = query.find((q) => q.descriptor === row.key);
            row.filterDimensionIndex = fbdQuery.filterDimensionIndex;
          });
        }

        // this function gets called every time we receive results from the server.
        // we do an upsert operation for overlays, but a wipe and replace for regular rows.
        // this is to avoid having to do diffs for row removal.
        if (isOverlay) {
          data.forEach((row) => {
            const existingRow = this.queryResults.find({ isOverlay, key: row.key });

            if (existingRow) {
              existingRow.set(row);
            } else {
              this.queryResults.add(Object.assign({ isOverlay, overlayIndex }, row));
            }
            this.queryResults.sort();
          });
        } else {
          this.queryResults.requestStatus = null;

          if (!query.aggregateFiltersEnabled && this.queryResults.nonOverlayRows.length) {
            this.queryResults.overwrite(data);
          } else {
            this.queryResults.add(data);
          }
          this.queryResults.sort();

          // All non-overlay queries for a bucket come back at once, but overlays are done with additional
          // subscribeSocket call. Only bracket on non-overlay results to prevent bracketing multiple times.
          this.bracketResults();

          this.loading = false;
        }
        this.loadedCount += 1;

        this.collection.lastUpdated = Date.now();
      }),
      onError: action((err) => {
        console.warn('Error received from query engine', err);
        this.error = (err && err.text) || 'Error occurred during your query';
        this.loading = false;
        this.collection.lastUpdated = Date.now();
      }),
      onReconnect: () => {
        if (socket.outType === 'unsubscribe') {
          return undefined;
        }
        socket.setOutType('resubscribe');
        socket.setInType('resubscribe');
        socket.setFrequency('once');

        return socket.send({}, (success) => {
          if (!success) {
            // resubscribe was unsuccessful, do a full refresh instead
            // we are banking on only one non-overlay socket here.
            if (!isOverlay && (!this.loadedCount || this.isLiveQuery)) {
              this.refresh();
            }
          }
        });
      }
    });

    this.subscribedQueries.push(query);
    const encodedQuery = encodeQuery(query);
    this.encodedQueries.push(encodedQuery);

    socket.setPayload({
      query,
      hash: this.collection?.hash,
      target_company_id: $app.isExport && $auth.hasSudo ? target_company_id : undefined
    });
    socket.send();

    this.sockets.push(socket);
  }

  unsubscribeSocket(socket) {
    socket.setOutType('unsubscribe');
    socket.setPayload();
    socket.send();
    socket.cancelAll();
  }

  @action
  bracketResults({ options = {} } = {}) {
    if (this.queries.isEmpty() || this.queryResults.isEmpty()) {
      return;
    }

    tagQueryBucket({ queryBucket: this, options });
  }

  cloneForBracketing() {
    const clone = new QueryBucketModel();

    function attributesToCollection(attributes, collection) {
      const attributeClones = JSON.parse(JSON.stringify(attributes));
      attributeClones.reduce((accumulatorCollection, attribs) => {
        accumulatorCollection.add(accumulatorCollection.build(attribs));
        return accumulatorCollection;
      }, collection);
    }

    attributesToCollection(this.queries.toJS(), clone.queries);
    attributesToCollection(this.overlayQueries.toJS(), clone.overlayQueries);
    attributesToCollection(this.queryResults.toJS(), clone.queryResults);

    return clone;
  }

  getOverlays() {
    if (!this.firstQuery) {
      return [];
    }
    const filterDimensionsEnabled = this.firstQuery.get('filterDimensionsEnabled');
    const metric = this.firstQuery.get('metric');
    const show_overlay = this.firstQuery.get('show_overlay');
    const show_total_overlay = this.firstQuery.get('show_total_overlay');

    // if there are no overlay queries in this bucket, we auto subscribe overlays based on user input.
    const overlayModels = [];
    const { showTotalTrafficOverlay } = $dataviews.getConfig(this.firstQuery.get('viz_type'));

    if (this.overlayQueries.isEmpty()) {
      if (show_total_overlay && showTotalTrafficOverlay && (!metric.includes('Traffic') || filterDimensionsEnabled)) {
        overlayModels.push(getTotalTrafficOverlay(this.firstQuery));
      }
      if (show_overlay) {
        overlayModels.push(getHistoricalOverlay(this.firstQuery));
      }
    } else {
      overlayModels.push(...this.overlayQueries.models.toJS());
    }

    return overlayModels.map((overlay) => overlay.serialize());
  }

  breakdownFilterDimensionsQuery(queries, overlays) {
    if (!this.firstQuery) {
      return queries;
    }
    const { filters, filterDimensions, filterDimensionOther } = this.firstQuery.serialize();

    if (filterDimensions && filterDimensions.filterGroups && filterDimensions.filterGroups.length) {
      queries = [];
      filterDimensions.filterGroups.forEach((filterGroup, idx) => {
        const queryToAdd = this.firstQuery.serialize(); // call this every time to get the deepClone
        queryToAdd.filterDimensionsEnabled = false;
        queryToAdd.metric = ['Traffic'];
        queryToAdd.descriptor = filterGroup.name;
        queryToAdd.filters = mergeFilterGroups(filters, { filterGroups: [filterGroup] });
        queryToAdd.filterDimensionIndex = overlays.length + idx;

        queries.push(queryToAdd);
      });

      if (filterDimensionOther) {
        const filterGroup = {
          name: 'Other',
          connector: 'Any',
          filterGroups: filterDimensions.filterGroups,
          not: true
        };

        const queryToAdd = this.firstQuery.serialize(); // call this every time to get the deepClone

        queryToAdd.filterDimensionsEnabled = false;
        queryToAdd.metric = ['Traffic'];
        queryToAdd.descriptor = filterGroup.name;
        queryToAdd.filters = mergeFilterGroups(filters, filterGroup);
        queryToAdd.filterDimensionIndex = overlays.length + filterDimensions.filterGroups.length;

        queries.push(queryToAdd);
      }
    }
    return queries;
  }

  @action
  subscribe() {
    if (this.queries.isEmpty()) {
      return;
    }

    this.loading = true;
    this.loadingCount = 0;
    this.loadedCount = 0;

    this.queryResults.reset();
    this.queryResults.setRequestStatus('fetching');

    const outsort = this.firstQuery.get('outsort');
    const isPreviousPeriod = this.firstQuery.get('isPreviousPeriod');
    const filterDimensionsEnabled = this.firstQuery.get('filterDimensionsEnabled');
    const filterDimensionSort = this.firstQuery.get('filterDimensionSort');
    const aggregateFiltersEnabled = this.firstQuery.get('aggregateFiltersEnabled');

    this.queryResults.bucket = this;
    this.queryResults.prefixableFieldUnits = this.firstQuery.prefixableFieldUnits;
    this.queryResults.defaultSortField = outsort;
    if (filterDimensionSort) {
      this.queryResults.sortState = {
        field: 'filterDimensionIndex',
        direction: 'asc'
      };
    } else if (this.queryResults.sortState.field !== outsort) {
      this.queryResults.sortState = {
        field: outsort,
        direction: 'desc'
      };
    }

    let queries = this.queries.models.map((query) => query.serialize());

    const overlays = this.getOverlays();

    if (this.queries.size === 1) {
      if (filterDimensionsEnabled === true) {
        queries = this.breakdownFilterDimensionsQuery(queries, overlays);
      } else if (aggregateFiltersEnabled === true) {
        queries = [];

        Object.values(getQueryMapFromAggregateFilterQuery(this.firstQuery)).forEach((query) =>
          this.subscribeSocket(query)
        );
      }
    }

    if (isPreviousPeriod) {
      queries.concat(overlays).forEach((query) => {
        const { period_over_period_lookback, period_over_period_lookback_unit, lookback_seconds } = query;
        let starting_time;
        let ending_time;

        if (lookback_seconds) {
          const interval = getQueryTimeInterval({ lookback_seconds });
          starting_time = moment.utc().subtract(interval, 'second');
          ending_time = moment.utc();
        } else {
          starting_time = moment.utc(query.starting_time);
          ending_time = moment.utc(query.ending_time);
        }

        Object.assign(query, {
          lookback_seconds: 0,
          starting_time: starting_time
            .subtract(period_over_period_lookback, period_over_period_lookback_unit)
            .format(DEFAULT_DATETIME_FORMAT),
          ending_time: ending_time
            .subtract(period_over_period_lookback, period_over_period_lookback_unit)
            .format(DEFAULT_DATETIME_FORMAT)
        });
      });
    }

    if (queries.length > 0) {
      this.subscribeSocket(queries);
    }

    overlays.forEach((query, index) => {
      query.filterDimensionIndex = index;
      this.subscribeSocket(query, true, index);
    });
  }

  @action
  unsubscribe() {
    this.sockets.forEach((socket) => this.unsubscribeSocket(socket));
    this.sockets = [];
  }

  @action
  refresh() {
    this.unsubscribe();
    this.subscribe();
  }

  @action
  applyToEachQuery(overrides) {
    this.queries.each((query) => query.set(overrides));
    this.overlayQueries.each((query) => query.set(overrides));
  }
}

export default QueryBucketModel;
