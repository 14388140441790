import { action, computed, observable } from 'mobx';
import { omit } from 'lodash';

import { showErrorToast, showSuccessToast } from 'core/components/toast';
import Model from 'core/model/Model';
import { api, safelyParseJSON, Socket } from 'core/util';
import { savedViewHasDependencyFailures } from 'app/components/dataviews/dependencies/ReportDependencyChecker';
import $app from 'app/stores/$app';
import $auth from 'app/stores/$auth';
import $setup from 'app/stores/$setup';
import $dictionary from 'app/stores/$dictionary';
import $labels from 'app/stores/label/$labels';
import $recentlyViewed from 'app/stores/recentlyViewed/$recentlyViewed';
import $users from 'app/stores/user/$users';

export default class SavedView extends Model {
  get defaults() {
    return {
      preset: false,
      share_level: $auth.isAdministrator ? 'company' : 'user',
      shared: $auth.isAdministrator,
      saved_query_id: undefined,

      // defines whether or not this view is from Data Explorer or Metrics Explorer
      // eventually we will merge these when UDE comes around.
      view_type: 'explorer',
      type: 'saved-view'
    };
  }

  // this won't be populated until the SQL is requested
  @observable
  rawSql = '';

  constructor(attributes = {}) {
    // allow for empty model to be created for save form.
    const query =
      attributes.savedQuery && attributes.savedQuery.value ? safelyParseJSON(attributes.savedQuery.value) : '';
    super(
      Object.assign({}, attributes, {
        query
      })
    );
  }

  get urlRoot() {
    return '/api/ui/saved-views';
  }

  serialize(data) {
    const serialized = { ...data };
    if (serialized.category_id === '') {
      serialized.category_id = null;
    }

    return super.serialize(serialized);
  }

  @action
  fetchViewSQL = () => {
    this.getNewQueryBuilderQuery('realtime', this.get('query'));
  };

  @action
  onRemove = () => {
    if (this.collection) {
      this.collection.clearSelection();
    }
    this.destroy();
  };

  get messages() {
    return {
      create: `View ${this.get('view_name')} was added successfully`,
      update: `${this.get('view_name')} was updated successfully`,
      destroy: `${this.get('view_name')} was removed successfully`,
      duplicate: `${this.get('view_name')} was duplicated successfully`
    };
  }

  get removalConfirmText() {
    return { title: 'Remove Saved View', text: `Are you sure you want to remove ${this.get('view_name')}?` };
  }

  get omitDuringSerialize() {
    return ['category'];
  }

  get name() {
    return this.get('view_name') || '';
  }

  get category() {
    return this.get('category.name') || 'Uncategorized';
  }

  get slugName() {
    return this.name.toLowerCase().replace(/\s/g, '-');
  }

  get slugFavorite() {
    return `${this.get('type')}$${this.get('id')}`;
  }

  get type() {
    return 'Saved View';
  }

  get description() {
    return this.get('view_description');
  }

  get iconName() {
    return 'timeline-area-chart';
  }

  @computed
  get labels() {
    return $labels.getLabels('saved_view', this.id);
  }

  @computed
  get labelsString() {
    return this.labels.map((label) => label.get('name')).join(', ');
  }

  @action
  save(attributes = {}, options = {}) {
    return super
      .save(attributes, options)
      .then(
        action(() => {
          this.hasDependencyFailures = savedViewHasDependencyFailures(this);
        })
      )
      .then((response) => $labels.labels.fetch({ force: true }).then(() => response));
  }

  @action
  duplicate = (options = {}) => {
    const { save = true } = options;
    const data = { ...omit(this.attributes, ['id']), view_name: this.getUniqueCopyName('view_name') };

    // Preset views must have their share_level reset to be cloned successfully (org is arbitrary choice)
    if (data.share_level === 'global') {
      data.share_level = 'company';
    }

    if (this.collection && save) {
      this.collection.requestStatus = 'Copying';
    }

    if (save) {
      return api.post(this.urlRoot, { data }).then(
        (attributes) => {
          showSuccessToast(`${this.name} was cloned successfully`);
          const view = this.collection.build(attributes);
          this.collection.add(view);
          return view;
        },
        () => {
          showErrorToast('Saved View could not be copied');
          if (this.collection) {
            this.collection.requestStatus = null;
          }
        }
      );
    }

    return this.collection.build(data);
  };

  @computed
  get navigatePath() {
    if (this.isNMS) {
      return `/v4/nms/explorer/saved-view/${this.id}`;
    }

    return `/v4/library/saved-views/${this.id}`;
  }

  @computed
  get vizType() {
    if (this.query) {
      return this.query.get('viz_type');
    }
    return safelyParseJSON(this.get('savedQuery')?.value)?.[0]?.query?.viz_type;
  }

  @computed
  get isNMS() {
    return this.get('view_type') === 'nms';
  }

  @computed
  get isFavorite() {
    return Object.keys($setup.getSettings('favorites', {})).includes(this.slugFavorite);
  }

  @computed
  get shareLevel() {
    const level = this.isPreset ? 'Preset' : 'Shared';
    return this.isUserLevel ? 'Private' : level;
  }

  @computed
  get isPreset() {
    return `${this.get('company_id')}` === `${$dictionary.get('templateDashboardsCID')}`;
  }

  @computed
  get isCompanyLevel() {
    return this.get('share_level') === 'company' && !this.isPreset;
  }

  @computed
  get isUserLevel() {
    return this.userIsAuthor && this.get('share_level') === 'user';
  }

  @computed
  get userIsAuthor() {
    return Number(this.get('user_id')) === Number($auth.getActiveUserProperty('id'));
  }

  @computed
  get author() {
    const { userIsAuthor, isPreset } = this;
    const authorModel = $users.collection.modelById[this.get('user_id')];
    const author = authorModel ? authorModel.get('user_full_name') : '';
    if (isPreset) {
      return 'Kentik';
    }
    return userIsAuthor ? 'Me' : author;
  }

  @computed
  get canEdit() {
    const allowedLabels = $auth.getActiveUserProperty('label_metadata', {})['saved_views::update'];
    const isLabelFiltered = $auth.hasRbacPermissions(['saved_views::update']) && allowedLabels;

    if (isLabelFiltered) {
      if (!this.labels.some((label) => allowedLabels.includes(label.id))) {
        return false;
      }
    }
    return (
      !$app.isSubtenant &&
      (this.userIsAuthor || // Personal
        ($auth.isAdministrator && this.isCompanyLevel) || // Company
        (this.isPreset && $auth.isPresetCompany)) // Preset
    );
  }

  @computed
  get lastViewedDate() {
    return $recentlyViewed.getLastViewedDate(this);
  }

  @computed
  get isTrending() {
    return $recentlyViewed.isTrending(this);
  }

  @computed
  get companyLastViewedDate() {
    return $recentlyViewed.getLastViewedDate(this, true);
  }

  @computed
  get lastEditedDate() {
    return this.get('edate') || this.get('cdate');
  }

  @computed
  get isSelectable() {
    return !this.isPreset && this.canEdit;
  }

  get sortValues() {
    return {
      'category.name': () => this.get('category.name') || 'zzzzzz'
    };
  }

  getQuerySocket() {
    return new Socket({
      outType: 'getQuery',
      inType: 'getQuery',
      frequency: 0,
      delaySend: true,
      onError(err) {
        console.warn('Failed to fetch SavedView query data', err);
      },
      onSuccess: action((data) => {
        this.rawSql = data;
      })
    });
  }

  getNewQueryBuilderQuery(type, query) {
    const socket = this.getQuerySocket();
    socket.setPayload({ type, query });
    socket.send();
  }
}
