import React from 'react';
import { computed } from 'mobx';
import flatMap from 'lodash/flatMap';
import get from 'lodash/get';
import minBy from 'lodash/minBy';
import startCase from 'lodash/startCase';
import uniq from 'lodash/uniq';
import moment from 'moment';
import Promise from 'bluebird';
import $auth from 'app/stores/$auth';

import { deserializeCidrDimension } from 'shared/alertingManager/utils';
import api from 'core/util/api';
import { showSuccessToast, Icon, Flex } from 'core/components';
import { buildFilterGroup, mergeFilterGroups } from 'core/util/filters';
import { DEFAULT_DATETIME_FORMAT } from 'core/util/dateUtils';
import { Collection } from 'core/model';
import { getTabPref } from 'core/util/table';
import formatMetricValue from 'app/util/formatMetricValue';
import { getMetricOption } from 'app/util/policies';
import transformPolicyFilters from 'app/util/alerting/transformPolicyFilters';
import { getQueryFilter } from 'app/stores/insight/insightUtils';
import { addFilters } from 'app/stores/query/FilterUtils';
import { ReactComponent as GlobalAgentIcon } from 'app/assets/agents/global_agent_icon.svg';

import {
  ALERT_BASELINE_REASONS,
  ALERT_MANAGER_SEVERITY_TO_STANDARD_ALERT_SEVERITY,
  ALERT_SEVERITY_COLORS,
  ALERT_SEVERITY_LABELS,
  ALERT_STATE_COLORS,
  ALERT_STATE_ICONS,
  ALERT_STATE_LABELS,
  DIMENSION_LOOKUP_PATHS,
  DIMENSION_TO_LABEL,
  POLICY_APPLICATION_LABELS,
  POLICY_APPLICATIONS,
  NMS_INTERNAL_POLICY_APPLICATION_LABEL,
  EVENT_POLICY_METRICS,
  EVENT_POLICY_TYPES
} from 'shared/alerting/constants';
import { defaultEventTypeDimensions } from 'app/forms/config/kevent/constants';

import { ALERT_DIMENSIONS_WHITELIST, ALERT_METRIC_OPTIONS } from 'app/util/constants';

import { convertThresholdFiltersToQueryFilters } from './policyUtils';

import PolicyCollection from './PolicyCollection';
import AllPolicyCollection from './AllPolicyCollection';
import PolicyLibraryCollection from './PolicyLibraryCollection';
import AlertCollection from './AlertCollection';
import AlertStatsCollection from './AlertStatsCollection';
import ddosFactorQueries from './DdosFactorQueries';
import AutoAckCollection from './AutoAckCollection';
import SilenceCollection from './SilenceCollection';
import NmsPolicyCollection from './nms/NmsPolicyCollection';
import EventPolicyCollection from './kevent/EventPolicyCollection';

const getPercentage = (base, compare) =>
  compare >= base ? Math.round(((compare - base) / base) * 100) : Math.round(((base - compare) / base) * 100);

export class AlertingStore {
  // (new) Alert Manager
  collection = new AlertCollection();

  stats = new AlertStatsCollection();

  // This collection contains all alert policies excluding NMS policies
  standardPolicyCollection = new PolicyCollection();

  // This collection contains all NMS alert policies (excluding legacy threshold and up/down policies)
  nmsPolicyCollection = new NmsPolicyCollection();

  eventPolicyCollection = new EventPolicyCollection();

  // This collection contains all policy types, both standard and nms together in one collection
  allPolicyCollection = new AllPolicyCollection([], {
    wrappedPolicyCollections: [
      // if we ever get more types, they'll go in here
      { collection: this.standardPolicyCollection },
      { collection: this.nmsPolicyCollection, permission: 'alerts.nmsNative.enabled' },
      { collection: this.eventPolicyCollection, permission: 'alerts.eventAlerting.enabled' }
    ]
  });

  // this collection contains the templates that can be used for policy creation
  policyLibraryCollection = new PolicyLibraryCollection();

  autoAckCollection = new AutoAckCollection();

  silenceCollection = new SilenceCollection();

  constructor(options = {}) {
    Object.assign(this, options);
  }

  get policyCollection() {
    // Until the NMS alerting feature is ready, we will continue to use the standardPolicyCollection
    const nmsAlertingEnabled = $auth.hasPermission('alerts.nmsNative.enabled', { overrideForSudo: false });
    // While there is a separate `alerts.eventAlerting.enabled` permission for Event policies, they are a subset of NMS policies.

    // At this time, MKP does *not* support any NMS or Event policies.
    // This condition ensures MKP tenants' alerts will load proper
    if ($auth.isSubtenantUser) {
      return this.standardPolicyCollection;
    }

    return nmsAlertingEnabled ? this.allPolicyCollection : this.standardPolicyCollection;
  }

  @computed
  get policyLimitCounts() {
    const { enabledCount, unfilteredSize } = this.policyCollection;
    const { activePolicies, totalPolicies } = this.store.$auth.getPermission('alerts.limits');

    return {
      activePolicies: enabledCount,
      totalPolicies: unfilteredSize,
      maxActivePolicies: activePolicies,
      maxTotalPolicies: totalPolicies,
      remainingActivePolicies: activePolicies - enabledCount,
      remainingTotalPolicies: totalPolicies - unfilteredSize,
      exceedsActiveLimit: activePolicies - enabledCount <= 0,
      exceedsTotalLimit: totalPolicies - unfilteredSize <= 0
    };
  }

  @computed
  get ddosTemplates() {
    return new PolicyLibraryCollection(
      this.policyLibraryCollection.get().filter((template) => template.application === 'ddos')
    );
  }

  @computed
  get cloudTemplates() {
    return new PolicyLibraryCollection(
      this.policyLibraryCollection.get().filter((template) => template.application === 'cloud')
    );
  }

  @computed
  get policyOptionsWithRules() {
    const options = this.policyCollection.generateSelectOptions({
      labelKey: 'name',
      valueKey: 'ruleId',
      sortBy: 'name',
      unfiltered: true
    });
    return options.filter((o) => !!o.value);
  }

  // Check if pattern dimension key-value pairs match candidate key-value pairs
  matchesKeyValuePattern({
    target = {},
    pattern = {},
    strict = true,
    omitFalsey = true,
    falseyValues = ['', undefined, '---']
  }) {
    const patternKeys = Object.keys(pattern);
    const matchesPattern = patternKeys.every((key) => pattern[key] === target[key]);

    // Omitting falsey entries is useful, since we do this when *creating* silences or suppressions
    const targetKeys = Object.keys(target).filter((key) => !omitFalsey || !falseyValues.includes(target[key]));
    const hasEqualKeys = patternKeys.length === targetKeys.length;

    // If strict, the key counts should match too (no excess target keys)
    return strict ? hasEqualKeys && matchesPattern : matchesPattern;
  }

  findActiveSilenceForAlert(alertModel) {
    return this.silenceCollection.unfiltered.find((silenceModel) => {
      const reqRuleMatch = silenceModel.ruleId && silenceModel.ruleId !== '';
      const reqKeyValueMatch =
        silenceModel.dimensionToKeyPart && Object.keys(silenceModel.dimensionToKeyPart).length > 0;

      // Malformed, can't be a match
      if (!reqRuleMatch && !reqKeyValueMatch) {
        return false;
      }

      // expired, can't be a match
      if (silenceModel.expired) {
        return false;
      }

      // From here on, we can assume at least one match condition is required.
      const matchesRule = silenceModel.ruleId === alertModel.ruleId;
      const matchesKeyValue = this.matchesKeyValuePattern({
        pattern: silenceModel.dimensionToKeyPart,
        target: alertModel.pattern.dimensionToKeyPart
      });

      // If one condition is not required, we know the other is. Treat non-required conditions as true.
      return (reqRuleMatch ? matchesRule : true) && (reqKeyValueMatch ? matchesKeyValue : true);
    });
  }

  fetchDebugPolicyData(options) {
    const { policyId } = options;
    const url = `/api/ui/alerting/policies/debug/${policyId}`;
    return api.get(url);
  }

  fetchDebugAlertData(alertModel, { sentBetween }) {
    const body = { ruleId: alertModel.ruleId, key: { filter: {} }, strict: false, withContext: true };
    const { dimensionToKeyPart = {} } = alertModel.pattern || {};

    Object.entries(dimensionToKeyPart).forEach(([key, value]) => {
      body.key.filter[key] = { equals: value };
    });

    if (sentBetween) {
      body.sentBetween = sentBetween;
    }

    return api
      .post('/api/ui/alertingManager/alarms/debug', { body })
      .then(({ triggers }) => (Array.isArray(triggers) ? triggers : []));
  }

  convertExplorerQueryToPolicyAttrs(query = {}) {
    const { metrics = [] } = query;
    const isEventAlertingEnabled = $auth.hasPermission('alerts.eventAlerting.enabled', { overrideForSudo: false });

    if (isEventAlertingEnabled && metrics[0] === EVENT_POLICY_METRICS.SYSLOG) {
      return this.buildEventPolicyFromExplorerQuery({
        ...query,
        eventType: EVENT_POLICY_TYPES.SYSLOG
      });
    }

    if (isEventAlertingEnabled && metrics[0] === EVENT_POLICY_METRICS.SNMP_TRAP) {
      return this.buildEventPolicyFromExplorerQuery({
        ...query,
        eventType: EVENT_POLICY_TYPES.SNMP_TRAP
      });
    }

    return this.buildTrafficPolicyFromExplorerQuery(query);
  }

  buildTrafficPolicyFromExplorerQuery({ selected_devices, filters, dimensions = [], metrics = [] }) {
    const supportedMetrics = [];
    const unsupportedMetrics = [];

    metrics.forEach((metric) => {
      const match = ALERT_METRIC_OPTIONS.find((option) => option.unit === metric);

      if (match) {
        supportedMetrics.push(match.value);
      } else {
        unsupportedMetrics.push({ value: metric, label: this.store.$dictionary.get(`units.${metric}`) || metric });
      }
    });

    const supportedDimensions = [];
    const unsupportedDimensions = [];

    dimensions.forEach((dimension) => {
      if (ALERT_DIMENSIONS_WHITELIST.has(dimension)) {
        supportedDimensions.push(dimension);
      } else {
        unsupportedDimensions.push({ label: this.getDimensionLabel(dimension), value: dimension });
      }
    });

    const policyAttrs = {
      application: POLICY_APPLICATIONS.CORE,
      selected_devices,
      silenced: false,
      metrics: supportedMetrics.slice(0, 3),
      daysToExpire: 90,
      evaluationPeriod: 120,
      filters,
      dimensions: uniq(supportedDimensions)
    };

    return {
      policyAttrs,
      unsupportedMetrics,
      unsupportedDimensions
    };
  }

  buildEventPolicyFromExplorerQuery({ dimensions = [], eventType, ...attributes }) {
    const defaultDimensions = defaultEventTypeDimensions[eventType] || [];

    const policyAttrs = {
      ...attributes,
      dimensions: uniq([...defaultDimensions, ...dimensions]),
      metrics: ['fps'],
      application: POLICY_APPLICATIONS.KEVENT,
      eventType
    };

    return { policyAttrs };
  }

  // dictionary dependency
  getRawDataKey(query, outsortOverride) {
    const aggregates = query.get('aggregates');
    const outsort = outsortOverride || query.get('outsort');
    const rawAgg = aggregates.find(
      (agg) =>
        agg.raw &&
        agg.column &&
        (agg.name === outsort || (outsort.startsWith('sum_logsum') && agg.unit === outsort.replace('sum_logsum_', '')))
    );

    let newColName = '';
    if (rawAgg && rawAgg.column) {
      newColName = rawAgg.column.replace('f_sum_', '').replace('bytes', 'bits').replace('trautocount', 'flows');
      if (
        !this.store.$dictionary.get('countColumns').includes(rawAgg.column) &&
        !this.store.$dictionary.get('countRegex').some((regex) => new RegExp(regex).test(rawAgg.column)) &&
        !rawAgg.is_count
      ) {
        newColName += '_per_sec';
      }
    }

    return newColName;
  }

  // dictionary dependency
  getRawDataKeyByUnit(unit = 'bytes', rawData) {
    const keyMap = {
      bytes: 'both_bits_per_sec',
      packets: 'both_pkts_per_sec'
    };

    const rawDataKeys = rawData ? Object.keys(rawData) : [];

    if (rawDataKeys.length === 1) {
      return rawDataKeys[0];
    }

    return keyMap[unit] || this.store.$dictionary.columnToUnitsInverse[unit] || unit;
  }

  @computed
  get isDdosConfigured() {
    const policies = this.policyCollection.unfiltered;
    return policies.length > 0 && policies.find((policy) => policy.application === POLICY_APPLICATIONS.DDOS);
  }

  getRuleIds({ includeSubpolicies, tenantIds, sortByPolicyField, sortDirection, parentRuleIds } = {}) {
    let policies = this.policyCollection.unfiltered;
    const parentPolicyIds = [];

    // maybe build a list of parent policy ids (strings)
    if (includeSubpolicies && parentRuleIds?.length > 0) {
      policies.forEach(({ ruleId, id }) => {
        if (parentRuleIds.includes(ruleId)) {
          parentPolicyIds.push(`${id}`);
        }
      });
    }

    if (includeSubpolicies) {
      const tenantPolicies = this.store.$mkp.tenants.getSubpolicies(tenantIds) || []; // these are not models!

      if (tenantIds?.length > 0) {
        // if you pass in a list if tenants then only build a list of those tenant policies
        policies = tenantPolicies;
      } else {
        // landlord policy models and tenant policies
        policies = policies.concat(tenantPolicies);
      }
    }

    // ** NOTE ** From here down, policies may now be a mix of Policy models and plain Subpolicy objects

    // when parent rules are passed, filter the policy list to only include parents and their subpolicies
    if (includeSubpolicies && parentPolicyIds.length > 0) {
      policies = policies.filter((p) => {
        const policyId = p.policy_id || p.get?.('policy_id') || p.id; // parent policy id property OR parent policy id attribute OR policy id
        return parentPolicyIds.includes(`${policyId}`); // compare string to string
      });
    }

    if (sortByPolicyField) {
      policies = policies
        .map((policy) => {
          const sortByValue = policy[sortByPolicyField] || policy.get(sortByPolicyField);

          return {
            ruleId: policy.ruleId || policy.activationSettings?.alertManagerConfig?.ruleId,
            [sortByPolicyField]: sortByValue
          };
        })
        .sort((policyA, policyB) => {
          const valueA = `${policyA[sortByPolicyField]}`;
          const valueB = `${policyB[sortByPolicyField]}`;

          return sortDirection === 'asc' ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
        });
    }

    return policies
      .map((policy) => policy.ruleId || policy.activationSettings?.alertManagerConfig?.ruleId)
      .filter((ruleId) => !!ruleId);
  }

  get isNmsEnabled() {
    return this.store.$metrics.availableMetricsCollection.size > 0 && !this.store.$app.isSubtenant;
  }

  get policyTypeOptions() {
    const options = [
      { value: POLICY_APPLICATIONS.CORE, label: POLICY_APPLICATION_LABELS.core },
      { value: POLICY_APPLICATIONS.CLOUD, label: POLICY_APPLICATION_LABELS.cloud },
      { value: POLICY_APPLICATIONS.DDOS, label: POLICY_APPLICATION_LABELS.ddos }
    ];

    // Add NMS if allowed
    if (this.isNmsEnabled) {
      options.unshift({ value: POLICY_APPLICATIONS.METRIC, label: POLICY_APPLICATION_LABELS.kmetrics });

      if ($auth.isSudoer) {
        // Sudo-only filter for native NMS policies specifically
        options.unshift({
          value: POLICY_APPLICATIONS.NMS,
          label: (
            <Flex alignItems="center" gap="4px" style={{ display: 'inline-flex' }}>
              {NMS_INTERNAL_POLICY_APPLICATION_LABEL} <Icon icon={GlobalAgentIcon} mr="4px" iconSize={20} />
            </Flex>
          )
        });
      }
    }

    return options;
  }

  @computed
  get policyTemplateOptions() {
    const templates = this.policyLibraryCollection.get();

    return templates
      .map((template) => ({
        label: template.get('name'),
        value: template.get('id')
      }))
      .sort((a, b) => {
        const aLabel = a.label.toLowerCase ? a.label.toLowerCase() : a.label;
        const bLabel = b.label.toLowerCase ? b.label.toLowerCase() : b.label;
        return aLabel > bLabel ? 1 : -1;
      });
  }

  /**
   * Clear one or more alertmanager alerts
   * @param {Model or [Model, Model, ...]} alertModels
   * @param {Boolean} toast
   * @returns Promise
   */
  clearAlarms(alertModels, toast = true) {
    const models = Array.isArray(alertModels) ? alertModels : [alertModels];
    const activeModels = models.filter((model) => model.isActive);
    const activeIds = activeModels.map((alertModel) => alertModel.id);

    if (activeIds.length === 0) {
      return Promise.resolve([]);
    }

    return api.post('/api/ui/alertingManager/alarms/clear', { data: { alertIds: activeIds } }).then(() => {
      activeModels.forEach((model) => model.setCleared());
      if (toast && models.length > 0) {
        const toastMessage = models.length === 1 ? 'Alert Cleared.' : `${activeIds.length} Alerts Cleared.`;
        showSuccessToast(toastMessage);
      }
      return alertModels;
    });
  }

  // Auto acknowledgements, suppressions, and silences all use the same basic data shape for a model
  buildActionModelFromAlert = (models, options = {}) => {
    const { collection, userId, startTime, endTime, comment } = options;

    // Collection is required
    if (!collection) {
      return false;
    }

    const alertModels = Array.isArray(models) ? models : [models];
    const baseSettings = {
      userId: userId || this.store.$auth.activeUser.id,
      startTime: startTime || null, // Let API set start time to avoid request getting rejected
      endTime: endTime || moment.utc().add(3, 'hours').toISOString()
    };

    if (comment) {
      baseSettings.comment = comment;
    }

    return alertModels.map((alertModel) => {
      const { pattern } = alertModel;
      const { ruleId, dimensionToKeyPart } = pattern || {};
      const data = { ...baseSettings, ruleId, key: { value: dimensionToKeyPart } };
      return collection.build(data, { deserialize: false });
    });
  };

  buildAutoAcksFromAlerts = (models, options = {}) =>
    this.buildActionModelFromAlert(models, { collection: this.autoAckCollection, ...options });

  buildSilencesFromAlerts = (models, options = {}) =>
    this.buildActionModelFromAlert(models, { collection: this.silenceCollection, ...options });

  acknowledgeAlarm(models, options = {}) {
    const { comment, autoAck, silence, toast = true } = options;
    const ACK_PATH = '/api/ui/alertingManager/alarms/ack';
    const alertModels = Array.isArray(models) ? models : [models];

    if (alertModels.length === 0) {
      return Promise.resolve();
    }

    const data = { alertIds: alertModels.map((alert) => alert.id) };

    if (typeof comment === 'string' && comment.trim() !== '') {
      data.comment = comment;
    }

    // Process the manual acks
    return api
      .post(ACK_PATH, { data })
      .then(() => {
        alertModels.forEach((alertModel) => alertModel.setAcknowledged());
        if (toast) {
          const toastMessage =
            alertModels.length === 1
              ? 'Alert acknowledged successfully'
              : `${alertModels.length} Alerts acknowledged successfully`;
          showSuccessToast(toastMessage);
        }
      })
      .then(() => {
        // Process auto acks, if any
        if (!autoAck) {
          return Promise.resolve();
        }
        const autoAckModels = this.buildAutoAcksFromAlerts(alertModels, {
          endTime: autoAck.endTime,
          comment: data.comment
        });
        return Promise.all(autoAckModels.map((autoAckModel) => autoAckModel.save({}, { toast: false })));
      })
      .then(() => {
        // Process silences, if any
        if (!silence) {
          return Promise.resolve();
        }
        const silenceModels = this.buildSilencesFromAlerts(alertModels, { endTime: silence.endTime });
        return Promise.all(silenceModels.map((silenceModel) => silenceModel.save({}, { toast: false }))).then(() => {
          const toastMessage =
            alertModels.length === 1
              ? 'Alert silence successfully created. It will take effect in a few minutes.'
              : `${alertModels.length} alert silences successfully created. It will take effect in a few minutes.`;
          showSuccessToast(toastMessage);
        });
      });
  }

  unacknowledgeAlarm(alertModels = [], toast = true) {
    const alerts = Array.isArray(alertModels) ? alertModels : [alertModels];
    const alertIds = alerts.map((alert) => alert.id);
    return api.post('/api/ui/alertingManager/alarms/unack', { data: { alertIds } }).then(() => {
      alerts.forEach((alertModel) => {
        alertModel.setUnacknowledged();
      });

      if (toast && alerts.length > 0) {
        const toastMessage =
          alerts.length === 1 ? 'Alert Acknowledgement Removed.' : `${alerts.length} Alert Acknowledgements Removed.`;
        showSuccessToast(toastMessage);
      }

      return alerts;
    });
  }

  attachNotificationsToPolicies(policies) {
    const data = { policies: policies.map((policy) => policy.serialize()) };

    return api.post('/api/ui/notifications/policyMappings', { data });
  }

  // valid filters are: startDate, endDate, lookback, includeSubpolicies, applications
  getSummaryTotals(filters) {
    return api.post('/api/ui/alerting/summary-totals', { data: filters });
  }

  // valid filters are: startDate, endDate, lookback, applications
  loadOverviewData(filters) {
    return api.post('/api/ui/alerting/overview', { data: { ...filters, ...filters.selectedTime } }).then((data) => {
      const policyStatsCollection = new Collection(data.policies);
      policyStatsCollection.sort('triggerCount', 'desc');

      return {
        ...data,
        policies: policyStatsCollection
      };
    });
  }

  getAlertById(id) {
    return api.get(`/api/ui/alertingManager/alarms/${id}`).then(({ alarm }) => alarm);
  }

  calculateEventEndTime({ endTime, threshold }) {
    const inactivityTimeUntilClear = parseInt(get(threshold, 'activationSettings.inactivityTimeUntilClear', 0)) || 0;

    if (!endTime) {
      return null;
    }

    return moment(endTime).subtract(inactivityTimeUntilClear, 'seconds').valueOf();
  }

  getSeverityLabel(severity) {
    const key = ALERT_MANAGER_SEVERITY_TO_STANDARD_ALERT_SEVERITY[severity] || severity;
    return ALERT_SEVERITY_LABELS[key] || 'Unknown';
  }

  getSeverityColor(severity) {
    const key = ALERT_MANAGER_SEVERITY_TO_STANDARD_ALERT_SEVERITY[severity] || severity;
    return ALERT_SEVERITY_COLORS[key];
  }

  getStateDisplay(state) {
    return ALERT_STATE_LABELS[state] || null;
  }

  getStateColor(state) {
    return ALERT_STATE_COLORS[state];
  }

  getStateIcon(state) {
    return ALERT_STATE_ICONS[state] || null;
  }

  getBaselineDescription(baselineTrigger) {
    return ALERT_BASELINE_REASONS[baselineTrigger]?.message || null;
  }

  // note - we may come here from $insights and be passed an alarm object that is flat (ie not a model)
  lookupValue(alarm, dimension, value, model) {
    const dimensionToKeyDetail = alarm.dimensionToKeyDetail || model?.dimensionToKeyDetail || {};

    if (DIMENSION_LOOKUP_PATHS[dimension]) {
      const path = DIMENSION_LOOKUP_PATHS[dimension];
      const lookupValue = get(dimensionToKeyDetail, `${dimension}.${path}`);

      if (lookupValue) {
        switch (path) {
          case 'asn.description':
            return `AS ${value} (${lookupValue})`;

          case 'interface.snmpDescription':
            return `${lookupValue} (${value})`;

          default:
            return lookupValue;
        }
      }
    }

    return this.store.$insights.lookupDisplayName(dimension, value);
  }

  getMetrics(alert) {
    let policyMetrics = [];

    if (alert.policyObject) {
      policyMetrics = get(alert.policyObject, 'metrics', []);
    } else if (alert.get) {
      policyMetrics = alert.get('metrics');
    }

    const metricToValue = alert.metricToValue || alert.get('metricToValue') || {};

    let metrics = policyMetrics.filter((m) => metricToValue[m] !== undefined);
    if (metrics.length === 0) {
      metrics = Object.keys(metricToValue);
    }
    return metrics;
  }

  hasMissingValue(alertModel, onMetric) {
    if (!alertModel.threshold) {
      return false;
    }

    const { conditions = [] } = alertModel.threshold;

    return conditions.some((condition) => {
      const { type, direction, metric = alertModel.primaryMetric } = condition;

      return type === 'keyNotInTop' && direction === 'historyToCurrent' && metric === onMetric;
    });
  }

  // note - we may come here from $insights and be passed an alarm object that is flat (ie not a model)
  getDimension(alarm, dimension, value = this.getDimensionValue(alarm, dimension)) {
    if (value === undefined) {
      return null;
    }

    const lookup = this.lookupValue(alarm, dimension, value);
    let label = this.getDimensionLabel(dimension);

    if (dimension === 'Traffic') {
      label = 'Traffic';
    }

    if (alarm.isMetricPolicy) {
      label = alarm.policyMeasurement?.storage?.Dimensions?.[dimension]?.Label || dimension;
    }

    return {
      key: dimension,
      label,
      value,
      lookup,
      formatted: `${label}: ${lookup}`,
      toString() {
        return this.formatted;
      }
    };
  }

  // note - we may come here from $insights and be passed an alarm object that is flat (ie not a model)
  getDimensionValue(alarm, dimension) {
    const dimensionToKeyPart = (alarm.get ? alarm.get('dimensionToKeyPart') : alarm.dimensionToKeyPart) || {};

    if (alarm.isMetricPolicy) {
      return get(alarm, `reconDimensions.${dimension}`);
    }

    if (this.store.$dictionary.get('cidrMetrics').includes(dimension)) {
      const keyPart = Object.keys(dimensionToKeyPart).find((key) => key.startsWith(dimension));
      return dimensionToKeyPart[keyPart];
    }

    return dimensionToKeyPart[dimension];
  }

  getBaseline(alertModel) {
    const { baselineValue, baselineSource, metricToValue } = alertModel;

    if (!baselineValue && !baselineSource) {
      return null;
    }

    const value = !this.hasMissingValue(alertModel, alertModel.primaryMetric)
      ? metricToValue[alertModel.primaryMetric]
      : null;
    const { value: formattedValue, metric: formattedMetric } = formatMetricValue(
      baselineValue,
      alertModel.primaryMetric
    );

    return {
      metric: alertModel.primaryMetric,
      value: baselineValue,
      over: Number.isFinite(value) ? value >= baselineValue : null,
      percentage: Number.isFinite(value) ? getPercentage(baselineValue, value) : null,
      formatted: `${formattedValue} ${formattedMetric}`,
      formattedValue,
      formattedMetric,
      toString() {
        return this.formatted;
      }
    };
  }

  getRawCapacity(alarm) {
    const { conditions = [] } = alarm.threshold || {};
    if (!conditions.some(({ type }) => `${type}`.toLowerCase().includes('capacity'))) {
      return 0;
    }

    return (
      (get(alarm, 'dimensionToKeyDetail.InterfaceID_src.interface.snmpSpeedMbps') ||
        get(alarm, 'dimensionToKeyDetail.InterfaceID_dst.interface.snmpSpeedMbps') ||
        get(alarm, 'dimensionToKeyDetail.ktappprotocol__snmp__output_port.interface.snmpSpeedMbps') ||
        0) * 1000000
    );
  }

  // note - we may come here from $insights and be passed an alarm object that is flat (ie not a model)
  getCapacity(alarm) {
    const capacity = this.getRawCapacity(alarm);
    if (capacity === 0) {
      return undefined;
    }

    const metric = 'bits';
    const { value: formattedValue, metric: formattedMetric, formatted } = formatMetricValue(capacity, metric);

    const value = get(alarm, `metricToValue.${metric}`);

    return {
      metric,
      value: capacity,
      percentage: Math.round((value / capacity) * 100),
      formatted,
      formattedValue,
      formattedMetric,
      toString() {
        return this.formatted;
      }
    };
  }

  getThresholds(alarm) {
    if (!alarm.threshold) {
      return [];
    }

    const baseline = alarm?.get ? alarm.get('baselineValue') : alarm.baseline;
    const metrics = this.getMetrics(alarm);
    const defaultMetric = metrics[0] || 'bits';
    const { conditions = [] } = alarm.threshold;
    const capacity = this.getRawCapacity(alarm);
    const metricToValue = alarm?.get ? alarm.get('metricToValue') : alarm.metricToValue;

    return conditions
      .map((condition) => {
        const {
          type,
          operator,
          comparisonValue = 0,
          comparisonValueFactor = 1,
          direction = 'currentToHistory',
          metric = defaultMetric,
          fallbackSettings = {},
          ratioSettings
        } = condition;
        const currentValue = metricToValue[metric];
        const baselineValue = baseline || Number(fallbackSettings?.value) || 0;
        let value;
        let threshold;
        let ratio = {};

        switch (type) {
          case 'static':
            if (metric !== defaultMetric && direction === 'historyToCurrent') {
              return null;
            }
            value = direction === 'currentToHistory' ? currentValue : baselineValue;
            threshold = comparisonValue * comparisonValueFactor;
            break;

          case 'interfaceCapacity':
            if (metric !== defaultMetric && direction === 'historyToCurrent') {
              return null;
            }
            value = direction === 'currentToHistory' ? metricToValue.bits || currentValue : baselineValue;
            threshold = capacity - comparisonValue * 1000000;
            break;

          case 'interfaceCapacityPercent':
            if (metric !== defaultMetric && direction === 'historyToCurrent') {
              return null;
            }
            value = direction === 'currentToHistory' ? metricToValue.bits || currentValue : baselineValue;
            threshold = (capacity * comparisonValue) / 100;
            break;

          case 'keyNotInTop':
            break;

          case 'baseline':
            if (metric !== defaultMetric) {
              return null;
            }
            value = direction === 'currentToHistory' ? currentValue : baselineValue;
            threshold = (direction === 'currentToHistory' ? baselineValue : currentValue) + comparisonValue;
            break;

          case 'baselinePercent':
            if (metric !== defaultMetric) {
              return null;
            }
            value = direction === 'currentToHistory' ? currentValue : baselineValue;
            threshold = ((direction === 'currentToHistory' ? baselineValue : currentValue) * comparisonValue) / 100;
            break;

          case 'ratio': {
            const {
              lhsMetricPosition = 0, // These default 0s are required to solve api sometimes not returning 0 values
              rhsMetricPosition = 0,
              lhsMetricProportion,
              rhsMetricProportion,
              margin = 0
            } = ratioSettings;
            const lhMetric = metrics[lhsMetricPosition];
            const rhMetric = metrics[rhsMetricPosition];
            const lhValue = metricToValue[lhMetric];
            const rhValue = metricToValue[rhMetric];
            const lhFormatted = formatMetricValue(lhValue, lhMetric);
            const rhFormatted = formatMetricValue(rhValue, rhMetric);
            const alarmCondition = `${lhsMetricProportion + margin}:${rhsMetricProportion}`;
            // the reverse calculation determines whether this is a bi-directional condition that triggered and
            // left right values need to be reversed to make sense. It does so by comparing the ratio of the
            // actual metric values to the lh and rh side proportions ratio.
            const reverse = lhValue / rhValue < (lhsMetricProportion + margin) / rhsMetricProportion;
            const actualValue = `${lhFormatted.formatted} : ${rhFormatted.formatted}`;
            const reverseValue = `${rhFormatted.formatted} : ${lhFormatted.formatted}`;

            ratio = {
              lhMetric: reverse ? rhMetric : lhMetric,
              rhMetric: reverse ? lhMetric : rhMetric,
              alarmCondition,
              actualValue: reverse ? reverseValue : actualValue
            };
            break;
          }
          default:
            return null;
        }

        const { value: formattedValue, metric: formattedMetric } = formatMetricValue(threshold, metric);
        const { value: formattedOtherValue, metric: formattedOtherMetric } = formatMetricValue(value, metric);
        const over = value >= threshold;
        const percentage = getPercentage(threshold, value) || 0;
        const diff = Math.abs(value - threshold);
        const { value: formattedDiffValue, metric: formattedDiffMetric } = formatMetricValue(diff, metric);

        return {
          type,
          direction,
          operator,
          metric,
          value: threshold,
          otherValue: value,
          diff,
          rawComparisonValue: comparisonValue,
          over,
          percentage,
          signedPercentage: (over ? 1 : -1) * percentage,
          formatted: `${formattedValue} ${formattedMetric}`,
          formattedValue,
          formattedMetric,
          formattedOther: `${formattedOtherValue} ${formattedOtherMetric}`,
          formattedOtherValue,
          formattedOtherMetric,
          formattedDiff: `${formattedDiffValue} ${formattedDiffMetric}`,
          formattedDiffValue,
          formattedDiffMetric,
          toString() {
            return this.formatted;
          },
          ratio
        };
      })
      .filter((threshold) => Boolean(threshold));
  }

  getThreshold(alarm, metric) {
    metric = metric || this.getMetrics(alarm)[0] || 'bits';

    // If legacy alert, we need to make sure we're deriving the metric properly
    const thresholds = this.getThresholds(alarm).filter(
      (threshold) => threshold.metric === metric && Number.isFinite(threshold.value)
    );

    return minBy(thresholds, (threshold) => threshold.percentage);
  }

  checkInterfaces() {
    return api.get('/api/ui/ddos/precheck').then(({ interfaceCount, classifiedCount, externalInterfaceCount }) => ({
      externalInterfaceCount,
      percentClassified: Math.round((classifiedCount / interfaceCount) * 100)
    }));
  }

  checkTrafficHistory() {
    return api
      .post('/api/ui/ddos/traffic-history')
      .then(({ results }) => results.reduce((acc, result) => acc + result.in_bytes + result.out_bytes, 0));
  }

  getPolicyAlarmCount(ruleId, lookback = 604800) {
    return api.get(`/api/ui/alertingManager/alarms/count?ruleId=${ruleId}&lookback=${lookback}`);
  }

  defaultExportColumns() {
    const columns = [
      'status',
      'severity',
      'application',
      'vector',
      'primaryDimension',
      'metric',
      'alarmId',
      'startTime',
      'duration'
    ];

    if (this.store.$mkp.tenants.tenantAlertingOptions.length > 0) {
      columns.splice(4, 0, 'tenant');
    }

    return columns;
  }

  getExportColumns(showTenantAlerts) {
    return [
      {
        label: 'Alert ID',
        name: 'id',
        id: 'id'
      },
      {
        label: 'Alert State',
        name: 'stateLabel',
        id: 'stateLabel'
      },

      {
        label: 'Severity',
        name: 'severity',
        id: 'severity'
      },
      {
        label: 'Type',
        name: 'application',
        id: 'application'
      },

      {
        label: 'Policy',
        name: 'policyName',
        id: 'policyName'
      },

      {
        label: 'Policy ID',
        name: 'policyID',
        id: 'policyID'
      },

      !showTenantAlerts || this.store.$mkp.tenants.tenantAlertingOptions.length === 0
        ? undefined
        : {
            label: 'Tenant',
            name: 'tenant',
            id: 'tenant'
          },

      {
        label: 'Dimensions',
        name: 'primaryDimension',
        id: 'primaryDimension'
      },

      {
        label: 'Metric',
        name: 'metric',
        id: 'metric'
      },

      {
        label: 'Mitigation ID',
        name: 'mitigationID',
        id: 'mitigationID'
      },

      {
        label: 'Duration',
        name: 'duration',
        id: 'duration'
      },

      {
        label: 'Time',
        name: 'startTime',
        id: 'startTime'
      }
    ].filter(Boolean);
  }

  exportCSV(exportSettings, showTenantAlerts) {
    // the rows selected (current, 200, 500 etc.) and the columns selected (current, or all)
    const { columnsSelected, rowsSelected } = exportSettings;

    // get the filter as it will be needed to get the same results for n number of rows
    const currentFilters = this.collection.getServerFilter();

    // get the currently visible column configuration as it's needed if the user wants only current configured columns
    const { columns, visibleColumns } = getTabPref(
      'alertManagerAlertingTable',
      this.getExportColumns(showTenantAlerts),
      this.defaultExportColumns
    );

    const fileName = this.store.$exports.getExportFileName(`alerts-summary-${Date.now()}`);

    const path = '/api/ui/alertingManager/alarms/export-csv';
    const type = 'csv';
    const options = { path, fileName, type };

    this.store.$exports.addLoadingExport(options);

    // post to get data in raw, txt format containing csv
    return api
      .post(path, {
        data: {
          sortBy: this.collection.getServerSortBy(),
          // send current filters
          currentFilters,
          // send the currently visible columns -- if no settings were found we just use the available ones
          currentColumns: visibleColumns.length > 0 ? visibleColumns : columns,
          // send available columns (if the user wants them all to be present)
          availableColumns: columns,
          // current number of rows loaded
          currentRowCount: this.collection.size,
          // selected option (all or current)
          columns: columnsSelected,
          // selected option (current, 200, 500 etc.)
          rows: rowsSelected
        },
        rawResponse: true
      })
      .then((response) => {
        this.store.$exports.clearLoadingExport(options);
        this.store.$exports.addPayload(response.text, options);
      });
  }

  getPolicyComparisonQuery(policyForm) {
    const {
      selected_devices,
      dimensions,
      metrics,
      selectedMeasurement,
      filters: policyFilters,
      cidr = 32,
      cidr6 = 128
    } = policyForm.getValues();
    const filters = buildFilterGroup(policyFilters);

    const query = {
      minsPolling: 1,
      forceMinsPolling: true,
      reAggInterval: 3600,
      reAggFn: 'max',
      depth: 10,
      cidr,
      cidr6,
      filters,
      lookback_seconds: 86400 * 3,
      metric: dimensions,
      show_total_overlay: false,
      show_overlay: false,
      viz_type: 'line',
      outsort: 'max_bits_per_sec',
      ...selected_devices
    };

    return metrics.map((metric) => {
      const metricOption = getMetricOption(metric, selectedMeasurement);
      const legacyUnits = this.store.$dictionary.get(`unitsLegacy.${metricOption.unit}`);

      return {
        ...query,
        query_title: `${metricOption.label} Condition`,
        units: legacyUnits ? metricOption.unit : undefined,
        aggregateTypes: legacyUnits || [metricOption.value]
      };
    });
  }

  getDashboardQuery(alarm) {
    const alarmBody = alarm.get();
    const dimensionToKeyPart = alarmBody.dimensionToKeyPart || alarmBody.key?.value || {};
    const { policyObject, startTime, eventEndTime } = alarm;
    const endTimeMoment = eventEndTime
      ? moment.utc(eventEndTime).add(30, 'minutes').format(DEFAULT_DATETIME_FORMAT)
      : moment.utc().format(DEFAULT_DATETIME_FORMAT);

    const alarmFilters = {
      connector: 'All',
      filterGroups: [
        buildFilterGroup({
          filters: Object.keys(dimensionToKeyPart).map((key) => getQueryFilter(key, dimensionToKeyPart[key]))
        })
      ]
    };

    const query = {
      ...policyObject.selected_devices,
      filters: transformPolicyFilters(mergeFilterGroups(alarmFilters, policyObject.filters)),
      lookback_seconds: 0,
      starting_time: moment.utc(startTime).subtract(30, 'minutes').format(DEFAULT_DATETIME_FORMAT),
      ending_time: endTimeMoment
    };

    return query;
  }

  getEventAlertQuery(eventAlertModel) {
    const overrides = {};

    // Only overrides policy filters wih thresholdFilters if alert has them
    if (eventAlertModel.threshold?.filters) {
      const convertedThresholdFilters = convertThresholdFiltersToQueryFilters(eventAlertModel.threshold.filters);
      overrides.filters = mergeFilterGroups(convertedThresholdFilters, eventAlertModel.policyObject.filters);
    }

    if (eventAlertModel.isSyslogEventPolicy) {
      overrides.metrics = [EVENT_POLICY_METRICS.SYSLOG];
    }

    if (eventAlertModel.isSnmpTrapEventPolicy) {
      overrides.metrics = [EVENT_POLICY_METRICS.SNMP_TRAP];
    }

    return this.getAlertQuery(eventAlertModel, overrides);
  }

  getAlertQuery(alertModel, overrides = {}) {
    const policy = alertModel.policyObject;
    const dimensions = overrides.dimensions || alertModel.get('dimensions') || policy.dimensions;
    const endTimeMoment = alertModel.eventEndTime
      ? moment.utc(alertModel.eventEndTime).add(30, 'minutes').format(DEFAULT_DATETIME_FORMAT)
      : moment.utc().format(DEFAULT_DATETIME_FORMAT);

    let metrics = overrides.metrics || alertModel.sortedMetrics || policy.metrics;
    metrics = metrics.map((metric) => {
      if (this.store.$dictionary.get(`unitsLegacy.${metric}`)) {
        return metric;
      }

      // if needed, translate alert metrics into the query units defined in ALERT_METRIC_OPTIONS (src/app/util/constants.js)
      const metricOption = getMetricOption(metric, undefined);
      return metricOption.unit;
    });

    const query = {
      ...this.getDashboardQuery(alertModel),
      metric: dimensions,
      aggregateTypes: uniq(flatMap(metrics, (metric) => this.store.$dictionary.get(`unitsLegacy.${metric}`) || [])),
      outsort:
        this.store.$dictionary.get(`unitsLegacy.${metrics[0]}[0]`) || this.store.$dictionary.get(`units.${metrics[0]}`),
      secondaryOutsort:
        metrics.length > 1
          ? this.store.$dictionary.get(`unitsLegacy.${metrics[1]}[0]`) ||
            this.store.$dictionary.get(`units.${metrics[1]}`)
          : null,
      viz_type: metrics.length > 1 ? 'line' : 'stackedArea',
      ending_time: endTimeMoment
    };

    if (overrides.filters) {
      query.filters = overrides.filters;
    }

    // adjust DE interval for policies with < 10 min windows (basically all of them)
    const evaluationPeriod = Number.isInteger(policy.evaluationPeriod)
      ? policy.evaluationPeriod
      : parseInt(policy.evaluationPeriod.replace('s', ''), 10);
    if (evaluationPeriod < 600) {
      query.forceMinsPolling = true;
      query.minsPolling = Math.ceil(evaluationPeriod / 60);

      // adjust for SNMP policies (minimum window is 5 min)
      if (dimensions.some((dimension) => dimension.includes('__snmp__'))) {
        query.minsPolling = 5;
        query.reAggInterval = 'auto';
        query.reAggFn = 'none';
      }
    }

    return query;
  }

  // type can be various ddos factors related values, found under queries
  getDdosFactorsQuery(alertModel, type) {
    const baseQuery = ddosFactorQueries[type];
    const { startTime, endTime, policyObject } = alertModel;
    const { dimensionToKeyPart } = alertModel.get();

    // const deviceName = this.getDeviceNameFromAlertModel(alertModel);

    const query = {
      ...baseQuery,
      // all_devices: !deviceName,
      // device_name: deviceName,
      ...policyObject.selected_devices,
      // lookback_seconds needs to be set to 0 otherwise the start and end times will be ignored
      lookback_seconds: 0,
      starting_time: moment.utc(startTime).subtract(30, 'minutes').format(DEFAULT_DATETIME_FORMAT),
      ending_time: moment.utc(endTime).format(DEFAULT_DATETIME_FORMAT),
      filters: {
        connector: 'All',
        filterGroups: []
      }
    };

    const filters = Object.keys(dimensionToKeyPart).map((key) => getQueryFilter(key, dimensionToKeyPart[key]));

    if (filters.length > 0) {
      addFilters(query, filters, 'All', [], true);
    }

    if (policyObject && policyObject.filters) {
      query.filters = mergeFilterGroups(query.filters, transformPolicyFilters(policyObject.filters));
    }

    return query;
  }

  getMetricsThresholdChartQuery = ({ metrics, measurement, dimensionToValue, startTime, endTime, lookbackSeconds }) => {
    const dimensions = Object.keys(dimensionToValue);
    const filters = {
      connector: 'All',
      filterGroups: [
        {
          name: '',
          named: false,
          connector: 'All',
          not: false,
          autoAdded: '',
          filters: dimensions.map((dimension) => {
            const value = dimensionToValue[dimension];
            return {
              filterField: dimension,
              metric: '',
              aggregate: '',
              operator: '=',
              filterValue: typeof value === 'string' ? `$_kntq$${value}$_kntq$` : value
            };
          }),
          saved_filters: [],
          filterGroups: []
        }
      ]
    };

    const queryOptions = {
      selectedDimensions: dimensions,
      selectedSortOrder: 'desc',
      selectedLimitCount: 10,
      selectedAggFunc: 'avg',
      selectedWindowSize: measurement === '/protocols/bgp/neighbors/prefixes' ? 300 : 0,
      selectedTransformation: 'none',
      selectedRollupsLimit: 10,
      selectedRollupsAggFunc: ['min', 'avg', 'max', 'last'],
      selectedVisualization: 'area',
      filters
    };

    if (lookbackSeconds >= 0) {
      queryOptions.lookback_seconds = lookbackSeconds;
    } else {
      // Negative numbers represent +/- time margins on an event.
      const bufferInSeconds = Math.abs(lookbackSeconds);
      queryOptions.starting_time = moment
        .utc(startTime)
        .subtract(bufferInSeconds, 'seconds')
        .format(DEFAULT_DATETIME_FORMAT);
      queryOptions.ending_time = moment.utc().format(DEFAULT_DATETIME_FORMAT);
      if (endTime) {
        const end = moment.utc(endTime).add(bufferInSeconds, 'seconds');
        const now = moment.utc();

        queryOptions.ending_time = (end > now ? now : end).format(DEFAULT_DATETIME_FORMAT);
      }
    }

    return this.store.$metrics.getFullMetricsQuery({ metrics, measurement, queryOptions });
  };

  getDimensionLabel(dimension) {
    return (
      DIMENSION_TO_LABEL[dimension] ||
      this.store.$dictionary.get(
        `chartTypesValidations.${deserializeCidrDimension(dimension, this.store.$dictionary.get('cidrMetrics'))}`
      ) ||
      startCase(dimension)
    );
  }

  fetchAllPolicies(...args) {
    return this.policyCollection.fetch(...args);
  }
}

export default new AlertingStore();
