import { action, computed, observable, reaction } from 'mobx';

import Collection from 'core/model/Collection';
import api from 'core/util/api';
import { METRICS_INTERFACE_OPER_STATUS_MAP } from 'app/stores/metrics/metricsConstants';
import $query from 'app/stores/query/$query';
import {
  ALL_INTERFACES_BITRATE_QUERY,
  ALL_INTERFACES_ERRORS_DISCARDS_QUERY,
  ALL_INTERFACES_FLOW_SNMP_QUERY,
  ALL_INTERFACES_UTILIZATION_QUERY
} from 'app/views/metrics/queries';
import { merge } from 'lodash';
import { interfaceKeyRegex } from 'app/components/dataviews/views/legend/legendUtils';
import InterfaceModel from './InterfaceModel';

class InterfaceCollection extends Collection {
  // used for calculating interface classification rule matching
  @observable
  activeRule = undefined;

  @observable
  fetchedFlow = false;

  @observable.ref
  filterOptions;

  counts = observable.map();

  constructor(data = [], options = {}) {
    super(data, options);
    reaction(
      () => this.unfiltered.length,
      () => this.calculateInterfaceCounts()
    );
  }

  serverSortableFields = [
    'id',
    'adminStatus',
    'operStatus',
    'interface_description',
    'snmp_id',
    'snmp_alias',
    'deviceName',
    'siteName',
    'connectivity_type',
    'network_boundary',
    'provider',
    'snmp_speed',
    'physicalAddress',
    'snmp_type',
    'interface_ip',
    'mtu',
    'ix_id'
  ];

  serverSortFn() {
    return this.fetch({ ...this.lastFetchOptions, force: true });
  }

  get urlPaths() {
    return {
      fetch: '/api/ui/interfaces',
      update: `/api/ui/devices/${this.get('device_id')}/interfaces/${this.id}`
    };
  }

  get model() {
    return InterfaceModel;
  }

  get queuedFetchKey() {
    return `InterfaceCollection.${this.parent ? this.parent.id : this.id}`;
  }

  hashModelsById = () => {
    const modelHash = {};
    this.get().forEach((model) => {
      if (model && model.id) {
        modelHash[model.id] = model;
        modelHash[`${model.get('device_id')}|${model.get('snmp_id')}`] = model;
      }
    });
    this.modelById = modelHash;
  };

  @computed
  get presetGroups() {
    return [
      {
        name: 'device_name',
        label: 'Device'
      }
    ];
  }

  useAsyncAdd = true;

  get filterFieldWhitelist() {
    return new Set([
      'connectivity_type',
      'snmp_id',
      'interface_description',
      'snmp_alias',
      'interface_ip',
      'network_boundary',
      'boundaryASNs'
    ]);
  }

  get presetFilters() {
    return [
      {
        label: 'With Flow',
        fn: (model) => model.flowStatus !== 'None',
        disabled: !this.fetchedFlow
      },
      {
        label: 'SNMP but No Flow',
        fn: (model) =>
          (model.flowInbound.snmpMbps > 0 && model.flowInbound.flowMbps === 0) ||
          (model.flowOutbound.snmpMbps > 0 && model.flowOutbound.flowMbps === 0),
        disabled: !this.fetchedFlow
      },
      {
        label: 'Manually Added',
        fn: (model) => model.isManuallyAdded
      },
      {
        label: 'Classified',
        fn: (model) => model.get('connectivity_type') && model.get('network_boundary')
      },
      {
        label: 'Unclassified',
        fn: (model) => !model.get('connectivity_type') && !model.get('network_boundary')
      },
      {
        label: 'Manually Overridden',
        fn: (model) => model.hasOverriddenFields
      }
    ];
  }

  @computed
  get activeRuleDisplay() {
    return this.activeRule && this.activeRule.readableDisplay;
  }

  @computed
  get interfacesWithFlow() {
    return this.unfiltered.filter((model) => model.flowStatus !== 'None');
  }

  @computed
  get downInterfaces() {
    return this.unfiltered.filter(
      (model) => METRICS_INTERFACE_OPER_STATUS_MAP[model.get('interfaceKvs.ifOperStatus')] === 'down'
    );
  }

  deviceFilter(filterField = 'km_device_id') {
    if (this.parent) {
      return this.parent.deviceFilter(filterField);
    }

    return undefined;
  }

  @action
  calculateInterfaceCounts() {
    this.presetFilters.forEach(({ label, fn, disabled }) => {
      const count = disabled ? 'N/A' : this.unfiltered.filter(fn).length;

      this.counts.set(label, count);
      this.filter();
    });
  }

  @action
  showFlowRowsOnly = () => {
    this.filter((model) => model.flowStatus !== 'None');
  };

  getFetchOptions(options = {}) {
    const fetchOptions = { ...options, fetchUrl: '/api/ui/interfaces/search', force: true };
    this.lastFetchOptions = options;
    this.fetchedFlow = false;

    fetchOptions.query ??= {};
    fetchOptions.query.device_id ??= this.parent ? this.parent.id : undefined;
    fetchOptions.query.sortBy = this.getServerSortBy();

    return fetchOptions;
  }

  @action
  async fetch(options = {}) {
    return super.fetch(this.getFetchOptions(options)).then(() => {
      const { devicesInResultSet } = this.filterOptions;

      if (devicesInResultSet && devicesInResultSet.length) {
        this.fetchInterfaceFlow({ id: devicesInResultSet });
      }

      this.setMetrics();
    });
  }

  @action
  async queuedFetch(options = {}) {
    return super.queuedFetch(this.getFetchOptions(options)).then(() => {
      const { devicesInResultSet } = this.filterOptions;

      if (devicesInResultSet && devicesInResultSet.length) {
        this.fetchInterfaceFlow({ id: devicesInResultSet });
      }

      this.setMetrics();
    });
  }

  async refetch() {
    return this.fetch(this.lastFetchOptions);
  }

  async fetchMetrics() {
    const utilizationQuery = merge({ kmetrics: { filters: this.deviceFilter() } }, ALL_INTERFACES_UTILIZATION_QUERY);
    const bitrateQuery = merge({ kmetrics: { filters: this.deviceFilter() } }, ALL_INTERFACES_BITRATE_QUERY);
    const errorsQuery = merge({ kmetrics: { filters: this.deviceFilter() } }, ALL_INTERFACES_ERRORS_DISCARDS_QUERY);
    const flowSnmpQuery = merge(
      { all_devices: false, device_name: [this.parent.get('device_name')] },
      ALL_INTERFACES_FLOW_SNMP_QUERY
    );
    return Promise.all([
      $query.runQuery(utilizationQuery),
      $query.runQuery(bitrateQuery),
      $query.runQuery(errorsQuery),
      $query.runQuery(flowSnmpQuery)
    ]).then(
      ([
        { results: utilizationResults },
        { results: bitrateResults },
        { results: errorsResults },
        { results: flowSnmpResults }
      ]) => {
        this.metricQueryData = {};

        // Turn into a hash for quick lookups
        utilizationResults
          .toJS()
          .forEach(({ ifindex, device_name, IfInUtilization, IfOutUtilization, metrics, timeseries }) => {
            const key = `${ifindex}|${device_name}`;
            const inUtilIdx = metrics.indexOf('in-utilization');
            const outUtilIdx = metrics.indexOf('out-utilization');

            this.metricQueryData[key] ??= {};
            Object.assign(this.metricQueryData[key], {
              IfInUtilization,
              IfOutUtilization,
              IfInUtilizationData: timeseries?.map(([, ...metricValues]) => metricValues[inUtilIdx]),
              IfOutUtilizationData: timeseries?.map(([, ...metricValues]) => metricValues[outUtilIdx])
            });
          });
        bitrateResults.toJS().forEach(({ ifindex, device_name, IfInBitRate, IfOutBitRate }) => {
          const key = `${ifindex}|${device_name}`;
          this.metricQueryData[key] ??= {};
          Object.assign(this.metricQueryData[key], { IfInBitRate, IfOutBitRate });
        });
        errorsResults
          .toJS()
          .forEach(({ ifindex, device_name, IfInErrors, IfOutErrors, IfInDiscards, IfOutDiscards }) => {
            const key = `${ifindex}|${device_name}`;
            this.metricQueryData[key] ??= {};
            Object.assign(this.metricQueryData[key], { IfInErrors, IfOutErrors, IfInDiscards, IfOutDiscards });
          });
        // fallback - only fill in interfaces that were missed by the NMS queries
        flowSnmpResults
          .toJS()
          .forEach(
            ({
              ktappprotocol__snmp__i_device_name: device_name,
              ktappprotocol__snmp__output_port: intf,
              avg_ktappprotocol__snmp__INT05: IfInUtilization,
              avg_ktappprotocol__snmp__INT06: IfOutUtilization,
              avg_ktappprotocol__snmp__INT00: IfInErrors,
              avg_ktappprotocol__snmp__INT0: IfOutErrors,
              avg_ktappprotocol__snmp__INT64_04: IfInDiscards,
              avg_ktappprotocol__snmp__INT64_05: IfOutDiscards,
              rawData
            }) => {
              const ifindex = interfaceKeyRegex.exec(intf)?.groups.snmp_id;
              const key = `${ifindex}|${device_name}`;
              if (ifindex && !this.metricQueryData[key]) {
                this.metricQueryData[key] = {
                  IfInUtilization,
                  IfOutUtilization,
                  IfInUtilizationData: rawData?.f_avg_int05.flow.map(([, value]) => value),
                  IfOutUtilizationData: rawData?.f_avg_int06.flow.map(([, value]) => value),
                  IfInErrors,
                  IfOutErrors,
                  IfInDiscards,
                  IfOutDiscards
                };
              }
            }
          );

        this.setMetrics();
      }
    );
  }

  setMetrics = () => {
    if (!this.metricQueryData) {
      return;
    }

    this.models.forEach((model) => {
      const interfaceKey = `${model.get('snmp_id')}|${model.deviceName}`;
      const resultsMatch = this.metricQueryData[interfaceKey];

      if (resultsMatch) {
        model.set(resultsMatch);
      }
    });
  };

  @action
  fetchInterfaceFlow = (device) => {
    const body = { device_id: device.id };
    const inbound = api.post('/api/ui/lookups/interfaces/inbound_flow', { data: body });
    const outbound = api.post('/api/ui/lookups/interfaces/outbound_flow', { data: body });
    const snmp = api.post('/api/ui/lookups/interfaces/snmp', { data: body });

    return Promise.all([inbound, outbound, snmp]).then(
      action((response) => {
        const data = response.map((flow) => flow.rows);
        const snmpValues = data[2];
        if (!snmpValues.length) {
          console.warn('No SNMP information was retrieved for: ', body);
        }

        /**
         * data[0, 1] are the 2 respective inbound and outbound flow data. To avoid iteration
         * unnecessarily, we can zip them together and set both properties at once by matching
         * `input_port` and `output_port`
         */

        let mergedInboundOutboundFlow = data[0].map((v) => {
          const outboundFlow = data[1].find(
            (flow) => flow.output_port === v.input_port && flow.i_device_id === v.i_device_id
          );
          if (outboundFlow) {
            return { ...v, ...outboundFlow };
          }
          return v;
        });
        // make sure we include outbound-only interfaces too.
        const outboundOnlyFlow = data[1].filter(
          ({ output_port, i_device_id }) =>
            !data[0].find((flow) => flow.input_port === output_port && flow.i_device_id === i_device_id)
        );
        if (outboundOnlyFlow.length) {
          mergedInboundOutboundFlow = [...mergedInboundOutboundFlow, ...outboundOnlyFlow];
        }

        this.requestStatus = null;
        this.fetchedFlow = true;

        this.updateInterfacesFlow(mergedInboundOutboundFlow, snmpValues);

        this.calculateInterfaceCounts();

        this.filter();
        this.setLastUpdated();
      })
    );
  };

  @action
  updateInterfacesFlow(flowResults, snmpResults) {
    flowResults.forEach((row) => {
      const { input_port, output_port, i_device_id } = row;

      const iface = this.modelById[`${i_device_id}|${input_port}`] || this.modelById[`${i_device_id}|${output_port}`];

      if (iface) {
        iface.set(row);
      }
    });

    snmpResults.forEach((row) => {
      const iface = this.modelById[`${row.i_device_id}|${row.input_port}`];
      if (iface) {
        iface.set({ snmpFlow: row });
      }
    });
  }

  processData(response) {
    if (Array.isArray(response)) {
      return super.processData(response);
    }

    this.filterOptions = response.options;
    this.totalCount = response.total_count;
    return super.processData(response.results);
  }
}

export default InterfaceCollection;
