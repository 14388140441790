import { computed } from 'mobx';
import { some as _some } from 'lodash';
import CredentialCollection from 'app/stores/credential/CredentialCollection';
import GroupedCredentialCollection from 'app/stores/credential/GroupedCredentialCollection';

export class CredentialStore {
  collection = new CredentialCollection();

  groupedCollection = new GroupedCredentialCollection();

  @computed
  get options() {
    return this.groupedCollection.get().map((model) => ({ label: model.get('name'), value: model.get('id') }));
  }

  @computed
  get synthTestOptions() {
    return this.groupedCollection
      .get()
      .filter((model) => {
        const consumers = model.get('consumers');
        return Array.isArray(consumers) && consumers.length && _some(consumers, ['type', 'synth']);
      })
      .map((model) => ({ label: model.get('name'), value: model.get('id') }));
  }

  getCredentialOptionsByTypes(types) {
    return this.groupedCollection
      .get()
      .filter((model) => types.includes(model.get('type')))
      .map((cred) => ({ label: cred.get('name'), value: cred.get('name'), type: cred.get('type'), ...cred.get() }));
  }

  getConsumerTestIds({ credentialIds = [] }) {
    const testIds = this.groupedCollection
      .get()
      .filter((model) => credentialIds.includes(model.get('id')))
      .map((model) => {
        const consumers = model.get('consumers');
        return consumers.length
          ? consumers.map(({ consumer }) => {
              const { test_id } = consumer;
              return test_id;
            })
          : [];
      });
    return testIds.flat();
  }
}

export default new CredentialStore();
