import { action, toJS } from 'mobx';
import uuid from 'uuid';
import { showSuccessToast, showErrorToast } from 'core/components';
import Model from 'core/model/Model';
import $moduleConfig from 'app/stores/moduleConfig/$moduleConfig';

function getNextId(models) {
  const newId = uuid.v4();
  if (models.find((model) => model.id === newId)) {
    return getNextId(models);
  }
  return newId;
}

class ModuleConfigModel extends Model {
  get moduleName() {
    if (this.collection) {
      return this.collection.moduleName;
    }

    throw new Error('moduleName getter not implemented');
  }

  get collectionProperty() {
    if (this.collection) {
      return this.collection.collectionProperty;
    }

    throw new Error('collectionProperty getter not implemented');
  }

  @action
  setInterfaceGroup(interfaceGroup) {
    this.interfaceGroup = interfaceGroup;
    this.interfaceGroup.moduleConfigModel = this;
    this.set('interfaceGroupId', interfaceGroup.id);
  }

  async destroy(options = {}) {
    return this.destroyModel(options);
  }

  @action
  destroyModel = async (options = {}) => {
    const { toast = true, remove = true } = options;
    return $moduleConfig.fetchModuleConfig(this.moduleName, { force: true }).then((settings) => {
      if (!settings[this.collectionProperty]) {
        settings[this.collectionProperty] = [];
      }
      const collectionData = settings[this.collectionProperty];

      const modelIndex = collectionData.findIndex((val) => val.id === this.id);
      if (modelIndex > -1) {
        collectionData.splice(modelIndex, 1);
        return $moduleConfig.saveModuleConfig({ module: this.moduleName, settings }).then(
          action((success) => {
            if (toast) {
              showSuccessToast(this.messages.destroy, {
                title: this.showToastTitles ? 'Removed' : undefined
              });
            }

            if (this.collection) {
              if (remove) {
                this.collection.remove([this.id]);
              }

              this.collection.clearSelection();
            }

            this.requestStatus = null;

            return success;
          }),
          action((error) => {
            console.error('destroyModel error', error);
            if (options.optimistic && this.collection) {
              this.collection.add([toJS(this.attributes)]);
            }
            this.error = { label: 'destroying', body: error };
            this.requestStatus = null;
            showErrorToast('Error Removing');

            return error;
          })
        );
      }
      return undefined;
    });
  };

  @action
  async save(attributes = {}, options = {}) {
    const { isNew } = this;
    const label = isNew ? 'creating' : 'updating';
    const { clearSelection = true, toast = true } = options;

    this.requestStatus = label;
    this.requestProgress = 0;

    const data = this.serialize({ ...this.get(), ...attributes });
    const originalAttributes = this.get();

    this.set(attributes);

    return $moduleConfig
      .fetchModuleConfig(this.moduleName, { force: true })
      .then((settings) => {
        if (!settings[this.collectionProperty]) {
          settings[this.collectionProperty] = [];
        }
        const collectionData = settings[this.collectionProperty];

        if (isNew) {
          const id = getNextId(collectionData);
          this.set('id', id);
          collectionData.push({ ...data, id });
        } else {
          const id = this.get('id');
          const planIdx = collectionData.findIndex((p) => p.id === id);
          collectionData[planIdx] = data;
        }

        return $moduleConfig.saveModuleConfig({ module: this.moduleName, settings });
      })
      .then(() => {
        if (this.collection && clearSelection) {
          this.collection.clearSelection();
        }

        if (toast) {
          const message = isNew ? this.messages.create : this.messages.update;
          const title = isNew ? 'Created' : 'Updated';
          showSuccessToast(message, {
            title: this.showToastTitles ? title : undefined
          });
        }

        if (this.collection) {
          this.collection.setLastUpdated();
        }

        this.setLastUpdated();

        this.requestStatus = null;
      })
      .catch((error) => {
        this.set(originalAttributes);
        this.error = { label };
        this.requestStatus = null;
        throw error;
      });
  }
}

export default ModuleConfigModel;
