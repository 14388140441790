import { action, observable } from 'mobx';
import { isEmpty, difference } from 'lodash';
import AbstractDataViewModel from 'app/stores/query/AbstractDataViewModel';
import { getQueriesForHash } from 'app/stores/query/urlHash';
import $syn from 'app/stores/synthetics/$syn';

export default class SyntheticsTestDataViewModel extends AbstractDataViewModel {
  @observable
  query = {};

  @observable
  updateFrequency = null;

  @observable
  lastRefresh = Date.now();

  listeners = {};

  get isFlowVisualization() {
    return false;
  }

  get viewType() {
    return 'syntheticsTest';
  }

  get viewShowTitleLink() {
    return ![
      'availability',
      'aggregate-agent',
      'aggregate-label',
      'gauge-min',
      'gauge-max',
      'gauge-avg',
      'grid-group'
    ].includes(this.query?.synth_test_display);
  }

  get matchingTestsByLabelIds() {
    const { synth_test_labels } = this.query;

    // on refresh synth_test_labels is set to null, so we need to check for that
    let selectedLabelIds = [];
    if (synth_test_labels) {
      selectedLabelIds = [...synth_test_labels];
    }

    return $syn.tests
      .get()
      .filter((m) => {
        if (!isEmpty(m.labels)) {
          const labelIds = m.labels.map((labelModel) => labelModel.get('id'));
          return difference(selectedLabelIds, labelIds).length === 0;
        }
        return false;
      })
      .map((testModel) => testModel.get('id'));
  }

  @action
  setTitle = (title) => {
    this.dvTitle = title;
  };

  @action
  refresh(saved_query_id) {
    const result = this.initializeHashWithOverrides(saved_query_id);
    // Call the listeners for the 'refresh' event
    if (this.listeners.refresh) {
      this.listeners.refresh.forEach((callback) => callback());
    }

    this.lastRefresh = Date.now();

    return result;
  }

  @action
  initializeHashWithOverrides = async (hash, overrides = {}) => {
    this.loading = true;
    this.hash = hash;
    return getQueriesForHash(hash).then((data) => {
      const [query] = data;
      Object.assign(query, overrides, { lastUpdated: Date.now() });
      this.query = query;
      this.setFullyLoaded();
      return query;
    });
  };

  @action
  setUpdateFrequency = (frequency) => {
    this.updateFrequency = frequency;
  };

  // Add listeners
  on(event, callback) {
    if (!this.listeners[event]) {
      this.listeners[event] = [];
    }
    this.listeners[event].push(callback);
  }

  // Remove listeners
  off(event, callback) {
    if (this.listeners[event]) {
      this.listeners[event] = this.listeners[event].filter((cb) => cb !== callback);
    }
  }
}
