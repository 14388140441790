import Model from 'core/model/Model';
import { computed, observable } from 'mobx';
import { kebabcase } from 'lodash';
import $capacity from 'app/stores/capacityPlan/$capacity';
import CapacityPlanInterfaceGroupModel from './CapacityPlanInterfaceGroupModel';

class CapacityPlanModel extends Model {
  get urlRoot() {
    return '/api/ui/capacity';
  }

  get url() {
    if (this.isNew) {
      return this.urlRoot;
    }

    const healthyInterfacesOnly = this.get('healthyInterfacesOnly') === false ? 0 : 1;
    return `${this.urlRoot}/${this.get('id')}?healthyInterfacesOnly=${healthyInterfacesOnly}`;
  }

  @observable.ref
  interfaceGroup = new CapacityPlanInterfaceGroupModel();

  @observable
  hasFetchedInsights = false;

  @observable
  runoutSeverityLevel = null;

  @observable
  utilizationSeverityLevel = null;

  get omitDuringSerialize() {
    return [
      'capacity',
      'runout',
      'severeInterfaces',
      'criticalInterfaces',
      'warningInterfaces',
      'runoutCriticalInterfaces',
      'runoutWarningInterfaces',
      'utilizationCriticalInterfaces',
      'utilizationWarningInterfaces',
      'util',
      'interfaceGroup'
    ];
  }

  get slug() {
    return kebabcase(this.get('name'));
  }

  get moduleName() {
    return 'capacity';
  }

  get collectionProperty() {
    return 'capacityPlans';
  }

  async fetch(...args) {
    return super.fetch(...args).then(() => {
      this.interfaceGroup = new CapacityPlanInterfaceGroupModel(this.get('interfaceGroup'));
      this.interfaceGroup.interfaceCollection.set(this.interfaceGroup.get('interfaces'));
    });
  }

  getSortValue(field) {
    if (field === 'name') {
      return this.get('name').toLowerCase();
    }
    if (field === 'severity') {
      const { interfacesCritical, interfacesWarning } = this.get('severitySummary');

      // Order should typically be:
      // - by most types of severity
      // - then by most critical interfaces
      // - then by most warning interfaces
      // Plans with tons of critical interfaces may rise to the top.

      return (
        this.runoutSeverity.weight +
        this.utilizationSeverity.weight +
        interfacesCritical * 0.01 +
        interfacesWarning * 0.0001
      );
    }
    if (field === 'highestUtilization') {
      const value = this.get('util')?.highest;
      return Number.isFinite(value) ? value : 0;
    }
    if (field === 'earliestRunout') {
      const value = this.get('runout')?.earliest;
      return Number.isFinite(value) ? value : Number.MAX_SAFE_INTEGER;
    }
    return super.getSortValue(field);
  }

  @computed
  get planSeverity() {
    return $capacity.severityTypes[this.get('severity') || 'healthy'];
  }

  @computed
  get runoutSeverity() {
    return $capacity.severityTypes[this.get('runout')?.severity || 'healthy'];
  }

  @computed
  get utilizationSeverity() {
    return $capacity.severityTypes[this.get('util')?.severity || 'healthy'];
  }

  serialize(data) {
    const { thresholds } = data;

    thresholds.runout.warning = Math.abs(thresholds.runout.warningDisplay - 6);
    thresholds.runout.critical = Math.abs(thresholds.runout.criticalDisplay - 6);
    return super.serialize(data);
  }
}

export default CapacityPlanModel;
