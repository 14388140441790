import DeckFactory from 'app/stores/decks/DeckFactory';

export default function awsDeckFactory() {
  const { widgets, layout, createWidget, newRow } = new DeckFactory('i_cloud');

  // first row
  createWidget({
    name: 'awsInventory',
    h: 7,
    w: 6,
    smallH: 4
  });
  createWidget({
    name: 'cloudTrafficOverview',
    config: { cloudFilter: 'AWS' },
    h: 7,
    w: 6,
    smallH: 4
  });
  newRow(7);

  // next row
  createWidget({
    name: 'vpcByTransitGateway',
    h: 6,
    w: 4,
    smallH: 4
  });
  createWidget({
    name: 'gatewayTraffic',
    config: { cloudType: 'aws' },
    h: 6,
    w: 4,
    smallH: 4
  });
  createWidget({
    name: 'awsRejectActions',
    config: { dst: 'vpc', src: 'vpc', metric: 'avg_pkts_per_sec', trf_profile: 'cloud internal' },
    h: 6,
    w: 4,
    smallH: 4
  });
  newRow(6);

  // next row
  createWidget({
    name: 'cloudTrafficProfile',
    config: { cloud_type: 'aws', cloud_region: 'All Regions' },
    h: 4,
    w: 3,
    smallH: 3
  });
  createWidget({
    name: 'cloudFlow',
    config: { cloudFilter: 'AWS' },
    h: 4,
    w: 3,
    smallH: 3
  });

  return {
    widgets,
    layout
  };
}
