import { action } from 'mobx';

import { showInfoToast } from 'core/components';
import { Socket } from 'core/util';
import JourneyCollection from 'app/views/core/journeys/JourneyCollection';

class JourneysStore {
  collection = new JourneyCollection();

  socket = null;

  @action
  initialize() {
    Notification.requestPermission();

    this.socket = new Socket({
      outType: 'journeysNotifications',
      inType: 'journeysNotifications',
      frequency: 60,
      delaySend: true,
      listenToBroadcast: true,
      onSuccess: this.onJourneyNotification,
      onError(err) {
        console.warn('Received Journeys Notifications Socket Error', err);
      },
      onReconnect: () => {
        this.socket.send();
      }
    });

    this.socket.send();
  }

  onJourneyNotification = (data) => {
    console.info('Received Journeys Notification', data);

    let notification;

    const [journey] = this.collection.add({ id: data.id });

    const onClick = () => {
      const { id } = data;

      this.history.push(`/v4/core/journeys/${id}`);
      if (notification) {
        notification.close();
      }
    };

    journey.fetch().then(() => {
      if (Notification.permission === 'granted') {
        console.info('Sending browser notification');
        notification = new Notification(data.title, {
          icon: 'https://images.ctfassets.net/6yom6slo28h2/33eo83sVM9DGq3WCFA5Uh5/70df4c8ad0906763e6a4d174cef5652c/kentik-ai-badge.png',
          body: data.description
        });

        notification.onclick = onClick;
      } else {
        console.info('Showing info toast');
        showInfoToast(data.description, { title: data.title, action: { onClick, text: 'Launch Journey' } });
      }
    });
  };
}

export default new JourneysStore();
