import moment from 'moment';
import Collection from 'core/model/Collection';
import TypeModel from './TypeModel';

class TypeCollection extends Collection {
  get url() {
    return '/api/ui/ott/type';
  }

  get model() {
    return TypeModel;
  }

  get defaultSortState() {
    return {
      field: 'name',
      direction: 'asc'
    };
  }

  get filterFieldWhitelist() {
    return new Set(['id', 'name']);
  }

  get lastUpdate() {
    return this.get().reduce((d, i) => Math.max(d, moment(i.get('edate')).valueOf()), 0);
  }
}

export default TypeCollection;
