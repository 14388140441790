import { observable } from 'mobx';

import ModuleConfigModel from 'app/stores/moduleConfig/ModuleConfigModel';

class InterfaceClassModel extends ModuleConfigModel {
  @observable.ref
  interfaceGroup;

  get defaults() {
    return {
      connectivityType: 'none',
      networkBoundary: 'external'
    };
  }

  get interfaceGroupDetails() {
    return {
      name: `${this.get('connectivityType')}-${this.get('networkBoundary')}`,
      type: 'ic'
    };
  }
}

export default InterfaceClassModel;
