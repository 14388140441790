import { action, observable } from 'mobx';

import { showSuccessToast } from 'core/components/toast';
import api from 'core/util/api';
import Collection from 'core/model/Collection';
import SpoofRestrictionsModel from './sudo/SpoofRestrictionsModel';
import AccessControlModel from './AccessControlModel';
import AddOnCollection from './addOn/AddOnCollection';

class CompanySettingsStore {
  @observable
  company_max_fps;

  @observable
  edition;

  @observable
  tags;

  @observable
  flow_ips;

  @observable
  port;

  acls = new AccessControlModel();

  @observable
  saving = {};

  @observable
  error = null;

  @observable
  loadedAcls = false;

  @observable.ref
  assetCounts = null;

  @observable
  dns = null;

  dnsCollection = new Collection();

  addOns = new AddOnCollection({});

  @observable
  addOnsLastLoaded;

  deviceLabelRegexDialogOpen = false;

  @observable
  deviceLabelRegexEnabled = null;

  @observable
  currency = null;

  @observable
  enableRemoteWalks = true;

  @observable
  enableKentikAi = false;

  @observable
  cdnAggregates = [];

  @observable
  spoof_restrictions = new SpoofRestrictionsModel();

  @observable
  trafficCostsCredits = {};

  @action
  initialize() {
    return this.load();
  }

  @action
  load() {
    return Promise.all([
      api
        .get('/api/ui/companySettings')
        .then(
          ({
            company_max_fps,
            flow_ips,
            access_control,
            port,
            edition,
            tags,
            currency,
            enableRemoteWalks,
            enableKentikAi,
            cdnAggregates,
            spoof_restrictions = [],
            trafficCostsCredits
          }) => {
            this.company_max_fps = company_max_fps;
            this.flow_ips = flow_ips;
            this.port = port;
            this.edition = edition || 'custom';
            this.tags = tags;
            this.currency = currency;
            this.enableRemoteWalks = enableRemoteWalks;
            this.enableKentikAi = enableKentikAi;
            this.cdnAggregates = cdnAggregates;
            this.spoof_restrictions.set({ spoof_restrictions });
            this.trafficCostsCredits = trafficCostsCredits;
            return access_control;
          }
        ),
      this.store.$moduleConfig.fetchModuleConfig('acl')
    ]).then(([access_control, acl]) => {
      const { neverSavedBefore, settings } = AccessControlModel.fromPayload(access_control, acl);

      if (neverSavedBefore) {
        this.writeACL(settings);
      }

      this.acls.set(settings);
      this.loadedAcls = true;
    });
  }

  writeACL(settings) {
    return this.store.$moduleConfig.saveModuleConfig({
      module: 'acl',
      settings
    });
  }

  @action
  saveAcls = (type, access_control) => {
    this.saving[type] = true;

    const payload = this.acls.set(access_control).toPayload();

    return api.put('/api/ui/companySettings', { data: { access_control: payload } }).then(
      () => {
        this.acls.set(access_control);
        this.saving[type] = false;
        showSuccessToast('Access controls saved successfully');

        this.writeACL(this.acls.get());
      },
      () => {
        this.saving[type] = false;
      }
    );
  };

  @action
  loadAssetCounts() {
    api.get('/api/ui/companySettings/assetCounts').then((assetCounts) => {
      this.assetCounts = assetCounts;
    });
  }

  @action
  loadDns() {
    return api.get('/api/ui/companySettings/dns').then((dns) => {
      this.dnsCollection.set(dns);
    });
  }

  @action
  reverseLookupIp = async (ip) => api.post('/api/ui/companySettings/dns/verify', { data: { ip } });

  fetchAccountTeam() {
    return api.get('/api/ui/companySettings/accountTeam');
  }

  contactAccountTeam(values, contactMember) {
    return api.post('/api/ui/companySettings/accountTeam/contact', { data: { ...values, contactMember } });
  }

  @action
  saveDns(actionText) {
    this.saving.dns = true;
    api.post('/api/ui/companySettings/dns', { data: { customDNSServers: this.dnsCollection.toJS() } }).then(
      (dns) => {
        this.dnsCollection.set(dns);
        this.saving.dns = false;
        showSuccessToast(`Custom DNS ${actionText} successfully`);
      },
      () => {
        // reset dns info on fail.
        this.loadDns();
        this.saving.dns = false;
      }
    );
  }

  @action
  createDnsEntry() {
    return this.saveDns('created');
  }

  @action
  updateDnsEntry() {
    return this.saveDns('updated');
  }

  @action
  deleteDnsEntry() {
    return this.saveDns('deleted');
  }

  @action
  setDeviceLabelRegexDialogOpen = (open) => {
    this.deviceLabelRegexDialogOpen = open;
  };

  @action
  setEnableKentikAi = (enabled) =>
    api.post('/api/ui/companySettings/enableKentikAi', { data: { enabled } }).then(() => {
      showSuccessToast(`Kentik AI ${enabled ? 'enabled' : 'disabled'}`);
      this.enableKentikAi = enabled;
    });

  @action
  setAllowRemoteSnmpWalks = (enabled) =>
    api.post('/api/ui/companySettings/enableRemoteWalks', { data: { enabled } }).then(() => {
      showSuccessToast(`Remote SNMP walks ${enabled ? 'enabled' : 'disabled'}`);
      this.enableRemoteWalks = enabled;
    });

  @action
  setDeviceNameAsRegex = (enabled) =>
    api.post('/api/ui/companySettings/deviceNameAsRegex', { data: { enabled } }).then(() => {
      this.deviceLabelRegexEnabled = enabled;
      this.setDeviceLabelRegexDialogOpen(false);
    });

  @action
  setCompanyCurrency = (currency) =>
    api.post('/api/ui/companySettings/currency', { data: { currency } }).then(() => {
      this.currency = currency;
      showSuccessToast('Company currency saved successfully');
    });

  @action
  setCdnAggregates = (cdnAggregates) =>
    api.post('/api/ui/companySettings/cdnAggregates', { data: { cdnAggregates } }).then(() => {
      this.cdnAggregates = cdnAggregates;
      showSuccessToast('CDNs to display on landing page were updated successfully');
    });

  @action
  updateSpoofSettings = (spoof_restrictions) =>
    api.put('/api/ui/companySettings/spoofSettings', { data: { spoof_restrictions } }).then(() => {
      this.spoof_restrictions.set({ spoof_restrictions });
      showSuccessToast('Spoof settings saved successfully');
    });
}

export default new CompanySettingsStore();
