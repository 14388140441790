import { action, computed, observable } from 'mobx';
import Model from 'core/model/Model';
import { getIPSortValue } from 'core/util/sortUtils';
import { timezone } from 'core/util/dateUtils';
import $dictionary from 'app/stores/$dictionary';
import $devices from 'app/stores/device/$devices';
import DeviceModel from 'app/stores/device/DeviceModel';
import QueryModel from 'app/stores/query/QueryModel';
import { greekPrefix } from 'core/util/greekPrefixing';
import { greekIt } from 'app/util/utils';
import {
  METRICS_INTERFACE_ADMIN_STATUS_MAP,
  METRICS_INTERFACE_OPER_STATUS_MAP
} from 'app/stores/metrics/metricsConstants';

const LIMIT_PERCENTAGE = 20;
const LIMIT_MBPS = 50;

const overridableFields = [
  'snmp_id',
  'interface_description',
  'snmp_alias',
  'snmp_speed',
  'interface_ip',
  'interface_ip_netmask',
  'network_boundary',
  'connectivity_type',
  'provider'
];

function getErrorPercentageAndPercentageOver(flowMbps, snmpMbps) {
  let percentage;
  let isAboveThresholds = false;

  if (flowMbps !== undefined && snmpMbps !== undefined && flowMbps > 0) {
    percentage = Math.round(Math.abs(((flowMbps - snmpMbps) / flowMbps) * 100));
  }

  const condition1 = flowMbps >= 1 && snmpMbps >= 1;
  const condition2 = flowMbps >= LIMIT_MBPS || snmpMbps >= LIMIT_MBPS;
  const condition3 = percentage !== undefined && percentage >= LIMIT_PERCENTAGE;

  if (condition1 && condition2 && condition3) {
    isAboveThresholds = true;
  }

  return { percentage, isAboveThresholds };
}

class InterfaceModel extends Model {
  @observable.ref
  metricsInterface = null;

  get defaults() {
    return {
      device: new DeviceModel(),
      secondary_ips: [],
      snmpFlow: {
        outbound: {
          value: 0
        },
        inbound: {
          value: 0
        }
      }
    };
  }

  get urlRoot() {
    return `/api/ui/devices/${this.get('device_id')}/interfaces`;
  }

  get urlPaths() {
    return {
      fetch: '/api/ui/interfaces/details',
      update: `/api/ui/devices/${this.get('device_id')}/interfaces/${this.id}`
    };
  }

  get sortValues() {
    return {
      flowInboundValue: (model) =>
        (parseFloat(model.flowInbound.flowMbps) || 0) + (parseFloat(model.flowInbound.snmpMbps) || 0),
      flowOutboundValue: (model) =>
        (parseFloat(model.flowOutbound.flowMbps) || 0) + (parseFloat(model.flowOutbound.snmpMbps) || 0),
      snmp_speed: (model) => parseInt(model.get('snmp_speed')),
      snmp_id: (model) => parseInt(model.get('snmp_id')),
      ruleStatus: (model) => model.isClassifiedByActiveRule,
      deviceName: ({ deviceName }) => (deviceName ? deviceName.toLowerCase() : 'zzzzzz'),
      siteName: ({ siteName }) => (siteName ? siteName.toLowerCase() : 'zzzzzz'),
      interface_ip: (model) => getIPSortValue(model.get('interface_ip'))
    };
  }

  serialize(data) {
    const {
      interface_description,
      snmp_alias,
      snmp_speed,
      snmp_id,
      interface_ip,
      interface_ip_netmask,
      connectivity_type,
      network_boundary,
      provider,
      interfaceKvs,
      ix_id,
      top_nexthop_asns
    } = data;

    const attributes = {
      interface_description,
      snmp_speed,
      snmp_id,
      snmp_alias,
      interface_ip,
      interface_ip_netmask,
      connectivity_type,
      network_boundary,
      provider,
      interfaceKvs,
      ix_id,
      top_nexthop_asns
    };

    return super.serialize(attributes);
  }

  deserialize(data = {}) {
    const { device, secondary_ips, ...rest } = data;

    return {
      secondary_ips: secondary_ips || [],
      device: new DeviceModel(device),
      ...rest
    };
  }

  save(attributes = {}) {
    return super.save(attributes).then((success) => {
      if (this.collection) {
        this.collection.calculateInterfaceCounts();
      }
      return success;
    });
  }

  @action
  resetField = (name) => {
    this.save({ [name]: this.get(`initial_${name}`) });
  };

  getQueryModel = () => {
    const filterValue = this.get('snmp_id');
    const filters_obj = {
      connector: 'Any',
      filterGroups: [
        {
          connector: 'Any',
          filters: [{ filterField: 'input_port', operator: '=', filterValue }]
        }
      ]
    };

    let device_name = this.get('device_name');
    if (!device_name) {
      device_name = $devices.getDeviceNames(this.get('device_id'));
    }

    const queryModel = QueryModel.create({
      device_name,
      filters_obj,
      lookback_seconds: 86400,
      metric: ['Traffic'],
      aggregateTypes: ['max_bits_per_sec'],
      query_title: 'Last 24h by Max Bits per second',
      mirror: true,
      time_format: timezone.value,
      viz_type: 'line'
    });

    return queryModel.serialize();
  };

  get subType() {
    return 'Interface';
  }

  /**
   * Merges the RuleCollection information with our Interfaces `rules_matched` information.
   * Allows us to display richer Rule match information in the table.
   */
  get matchingRule() {
    if (this.ruleMatchInfo && this.collection && this.collection.ruleCollection) {
      const collection = this.collection.ruleCollection;
      return collection.get(this.ruleMatchInfo.id);
    }

    return null;
  }

  get ruleMatchInfo() {
    const rules_matched = this.get('rules_matched') || [];
    const didClassifiedRules = rules_matched.filter((matchInfo) => matchInfo.didClassify);
    const kmrRule = didClassifiedRules.find((matchInfo) => matchInfo.rank === -1 && matchInfo.id !== -1);
    return kmrRule || didClassifiedRules?.[0];
  }

  @computed
  get isMatchedByActiveRule() {
    const { activeRule } = this.collection;
    const rules_matched = this.get('rules_matched') || [];

    return !!(activeRule && rules_matched.some((rule) => rule.id === activeRule.id));
  }

  @computed
  get isClassifiedByActiveRule() {
    const { activeRule } = this.collection;
    return !!(this.ruleMatchInfo && activeRule && this.ruleMatchInfo.id === activeRule.id);
  }

  @computed
  get deviceName() {
    const device_id = this.get('device_id');
    const deviceSummary = $devices.deviceSummariesById[device_id];
    return deviceSummary ? deviceSummary.device_name : null;
  }

  @computed
  get siteName() {
    const device_id = this.get('device_id');
    const deviceSummary = $devices.deviceSummariesById[device_id];
    return deviceSummary ? deviceSummary.title : null;
  }

  @computed
  get siteId() {
    const device_id = this.get('device_id');
    const deviceSummary = $devices.deviceSummariesById[device_id];
    return deviceSummary ? deviceSummary.site_id : null;
  }

  @computed
  get networkBoundary() {
    return $dictionary.get(`interfaceClassification.networkBoundaryTypes.${this.get('network_boundary')}`) || 'None';
  }

  @computed
  get connectivityType() {
    return $dictionary.get(`interfaceClassification.connectivityTypes.${this.get('connectivity_type')}`) || 'None';
  }

  @computed
  get snmpType() {
    return $dictionary.get('snmpMetadata.snmpType')[this.get('snmp_type')];
  }

  @computed
  get isUnclassified() {
    return !this.get('network_boundary') && !this.get('connectivity_type');
  }

  @computed
  get capacity() {
    return parseInt(this.get('snmp_speed')) * 1000000;
  }

  @computed
  get capacityLabel() {
    if (Number.isNaN(this.get('snmp_speed')) || this.get('snmp_speed') === undefined) {
      return 'Unknown';
    }

    const snmp_speed = parseInt(this.get('snmp_speed'));

    if (snmp_speed >= 1000) {
      return `${snmp_speed / 1000}Gbits/s`;
    }

    return `${snmp_speed}Mbits/s`;
  }

  @computed
  get boundaryASNs() {
    const nexthopAsns = this.get('top_nexthop_asns');
    return nexthopAsns && Array.isArray(nexthopAsns) && nexthopAsns.map((boundary) => boundary.Asn).toString();
  }

  @computed
  get hasOverriddenFields() {
    return this.overriddenFields.length > 0;
  }

  @computed
  get overriddenFields() {
    return overridableFields.reduce((agg, field) => {
      if (this.has(`initial_${field}`) && this.get(`initial_${field}`) !== this.get(field)) {
        agg.push(field);
      }
      return agg;
    }, []);
  }

  @computed
  get isManuallyAdded() {
    const initial_snmp_id = this.get('initial_snmp_id');
    return !initial_snmp_id;
  }

  @computed
  get bitRatePrefix() {
    const inValue = this.get('IfInBitRate') || 0;
    const outValue = this.get('IfOutBitRate') || 0;
    return greekPrefix([inValue, outValue], 1);
  }

  @computed
  get inBitRateDisplay() {
    const value = this.get('IfInBitRate');
    if (value === null || value === undefined) {
      return '--';
    }
    const { displayFull } = greekIt(value, { forcePrefix: this.bitRatePrefix, suffix: '', fix: 2 });
    return displayFull;
  }

  @computed
  get outBitRateDisplay() {
    const value = this.get('IfOutBitRate');
    if (value === null || value === undefined) {
      return '--';
    }
    const { displayFull } = greekIt(value, { forcePrefix: this.bitRatePrefix, suffix: '', fix: 2 });
    return displayFull;
  }

  @computed
  get adminStatus() {
    return METRICS_INTERFACE_ADMIN_STATUS_MAP[this.get('interfaceKvs.ifAdminStatus')];
  }

  @computed
  get operStatus() {
    return METRICS_INTERFACE_OPER_STATUS_MAP[this.get('interfaceKvs.ifOperStatus')];
  }

  @computed
  get physicalAddress() {
    const address = this.get('interfaceKvs.ifPhysAddress');
    if (address) {
      return address.replace('0x', '').match(/../g).join(':');
    }
    return null;
  }

  @computed
  get logical() {
    const connectorPresent = this.get('interfaceKvs.ifConnectorPresent');
    if (connectorPresent) {
      return connectorPresent === 'logical' ? 'True' : 'False';
    }
    return 'Unknown';
  }

  @computed
  get flowStatus() {
    const inFlow = this.get('in_mbps');
    const outFlow = this.get('out_mbps');

    if (!inFlow && !outFlow) {
      return 'None';
    }

    return inFlow ? 'Ingress' : 'Egress';
  }

  @computed
  get inboundErrorPercentage() {
    const in_mbps = this.get('in_mbps');
    const snmpFlow = this.get('snmpFlow');
    const nmsBitrate = this.get('IfInBitRate') / 1_000_000;

    return getErrorPercentageAndPercentageOver(in_mbps, snmpFlow.inmbps || nmsBitrate);
  }

  @computed
  get outboundErrorPercentage() {
    const out_mbps = this.get('out_mbps');
    const snmpFlow = this.get('snmpFlow');
    const nmsBitrate = this.get('IfOutBitRate') / 1_000_000;

    return getErrorPercentageAndPercentageOver(out_mbps, snmpFlow.outmbps || nmsBitrate);
  }

  @computed
  get utilization() {
    const usage = Math.max(parseInt(this.get('ingress')), parseInt(this.get('egress')));
    const capacity = parseInt(this.get('snmp_speed')) * 1000000;

    if (Number.isNaN(usage) || Number.isNaN(capacity) || capacity === 0) {
      return 0;
    }

    return usage / capacity;
  }

  @computed
  get flowInbound() {
    const flowMbps = this.get('in_mbps') || 0;
    const snmpMbps = this.get('snmpFlow.inmbps') || this.get('IfInBitRate') / 1_000_000 || 0;
    const values = [flowMbps, snmpMbps].filter(Boolean).map((value) => value * 1_000_000);

    return {
      error: this.inboundErrorPercentage,
      prefix: greekPrefix(values, 1),
      flowMbps,
      snmpMbps
    };
  }

  @computed
  get flowOutbound() {
    const flowMbps = this.get('out_mbps') || 0;
    const snmpMbps = this.get('snmpFlow.outmbps') || this.get('IfOutBitRate') / 1_000_000 || 0;
    const values = [flowMbps, snmpMbps].filter(Boolean).map((value) => value * 1_000_000);

    return {
      error: this.outboundErrorPercentage,
      prefix: greekPrefix(values, 1),
      flowMbps,
      snmpMbps
    };
  }

  @computed
  get flowInboundValue() {
    return `${this.flowInbound.flowMbps}${this.flowInbound.snmpMbps}`;
  }

  @computed
  get flowOutboundValue() {
    return `${this.flowOutbound.flowMbps}${this.flowOutbound.snmpMbps}`;
  }

  @computed
  get discardsTotal() {
    return this.get('IfInDiscards') + this.get('IfOutDiscards');
  }

  @computed
  get errorsTotal() {
    return this.get('IfInErrors') + this.get('IfOutErrors');
  }

  @computed
  get embeddedCacheEnabled() {
    return this.get('interfaceKvs.disableEmbeddedCache') !== '1';
  }

  set embeddedCacheEnabled(enabled) {
    this.set('interfaceKvs', { ...this.get('interfaceKvs'), disableEmbeddedCache: !enabled ? '1' : '0' });
  }

  get messages() {
    return {
      create: `Interface ${this.get('interface_description')} was added successfully`,
      update: `Interface ${this.get('interface_description')} was updated successfully`,
      destroy: `Interface ${this.get('interface_description')} was removed successfully`
    };
  }
}

export default InterfaceModel;
