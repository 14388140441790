import { buildFilterGroup } from 'core/util/filters';

function getSankeyMetric(peeringConfig) {
  const sankey_boundary_asn = peeringConfig.get('sankey_boundary_asn');
  if (sankey_boundary_asn) {
    return 'src_nexthop_asn';
  }
  return 'i_src_provider_classification';
}

export default function discoverPeersSankeyQuery(peeringConfig) {
  const { sites, countries, siteCountries, excluded_asns, interface_type, traffic_profile, topn, min_traffic } =
    peeringConfig.toJS();
  const direction = traffic_profile === 'Inbound' ? 'src' : 'dst';

  const hops = peeringConfig.get('hops') || [];
  const query = {
    all_devices: true,
    metric:
      traffic_profile === 'Inbound'
        ? ['AS_src', getSankeyMetric(peeringConfig), 'i_src_connect_type_name', 'i_device_site_name']
        : [
            'i_device_site_name',
            'i_dst_connect_type_name',
            'dst_nexthop_asn',
            hops.includes('dst_second_asn') && 'dst_second_asn',
            hops.includes('dst_third_asn') && 'dst_third_asn',
            'AS_dst'
          ].filter(Boolean),
    aggregateTypes: ['avg_bits_per_sec'],
    aggregateThresholds: {
      avg_bits_per_sec: min_traffic * 1000000
    },
    show_total_overlay: false,
    show_overlay: false,
    lookback_seconds: 259200, // limit to 3 day
    fastData: 'Fast',
    topx: topn,
    depth: topn,
    filters: {
      connector: 'All',
      filterGroups: [
        buildFilterGroup({
          filters: [
            { filterField: `i_${direction}_network_bndry_name`, operator: '=', filterValue: 'external' },
            { filterField: `${direction}_as`, operator: '<>', rightFilterField: `${direction}_nexthop_as` }
          ]
        })
      ]
    },
    viz_type: 'sankey'
  };

  if (interface_type && interface_type.length > 0) {
    const if_type = Array.isArray(interface_type) ? interface_type : [interface_type];

    query.filters.filterGroups.push(
      buildFilterGroup({
        connector: 'Any',
        filters: if_type.map((type) => ({
          filterField: `i_${direction}_connect_type_name`,
          operator: '=',
          filterValue: type
        }))
      })
    );
  }

  if (sites && sites.length > 0) {
    query.filters.filterGroups.push(
      buildFilterGroup({
        connector: 'Any',
        filters: sites.map((site) => ({
          filterField: 'i_device_site_name',
          operator: '=',
          filterValue: site
        }))
      })
    );
  }

  if (countries && countries.length > 0) {
    query.filters.filterGroups.push(
      buildFilterGroup({
        connector: 'Any',
        filters: countries.map((country) => ({
          filterField: 'src|dst_geo',
          operator: '=',
          filterValue: country
        }))
      })
    );
  }

  if (siteCountries && siteCountries.length > 0) {
    query.filters.filterGroups.push(
      buildFilterGroup({
        connector: 'Any',
        filters: siteCountries.map((country) => ({
          filterField: 'i_device_site_country',
          operator: '=',
          filterValue: country
        }))
      })
    );
  }

  if (excluded_asns && excluded_asns.length > 0) {
    query.filters.filterGroups.push(
      buildFilterGroup({
        filters: excluded_asns.map((asn) => ({
          filterField: `${direction}_as`,
          operator: '<>',
          filterValue: asn
        }))
      })
    );
  }

  return query;
}
