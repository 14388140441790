import { cloneDeep, defaultsDeep } from 'lodash';

import prospectInboundOutboundQuery from 'app/views/serviceProvider/leads/queries/prospectInboundOutboundQuery';
import leadGenerationInboundOutboundQuery from 'app/views/serviceProvider/leads/queries/leadGenerationInboundOutboundQuery';
import leadGenerationInboundOutboundAggregateQuery from 'app/views/serviceProvider/leads/queries/leadGenerationInboundOutboundAggregateQuery';
import cloudInboundOutboundQuery from 'app/views/core/cloud/queries/cloudInboundOutboundQuery';
import cloudInboundOutboundAggregateQuery from 'app/views/core/cloud/queries/cloudInboundOutboundAggregateQuery';

import { reaction } from 'mobx';
import { showErrorToast } from 'core/components';
import { addFilters, removeFilters } from 'app/stores/query/FilterUtils';
import inboundOutboundInternalQuery from './baseQueries/inboundOutboundInternalQuery';
import inboundOutboundInternalAggregateQuery from './baseQueries/inboundOutboundInternalAggregateQuery';
import interfaceIngressEgressQuery from './baseQueries/interfaceIngressEgressQuery';
import interfaceIngressEgressAggregateQuery from './baseQueries/interfaceIngressEgressAggregateQuery';
import topSitesQuery from './baseQueries/topSitesQuery';
import topASQuery from './baseQueries/topASQuery';
import trafficProfileQuery from './baseQueries/trafficProfileQuery';
import internalEndpoints from './baseQueries/internalEndpoints';
import externalEndpoints from './baseQueries/externalEndpoints';
import mkpInboundOutboundQuery from './baseQueries/mkp/inboundOutboundQuery';
import mkpInboundOutboundAggregateQuery from './baseQueries/mkp/inboundOutboundAggregateQuery';
import QueryModel from './QueryModel';
import DataViewModel from './DataViewModel';

export class QueryStore {
  constructor() {
    if (!sessionStorage) {
      console.warn('Session storage disabled, client-side caching will not be possible.');
    }
  }

  queries = {
    inboundOutboundInternalQuery,
    inboundOutboundInternalAggregateQuery,
    inboundQuery: removeFilters(['internal', 'outbound'], inboundOutboundInternalQuery),
    inboundAggregateQuery: removeFilters(['internal', 'outbound'], inboundOutboundInternalAggregateQuery),
    interfaceIngressEgressAggregateQuery,
    interfaceIngressEgressQuery,
    topSitesQuery,
    topASQuery,
    trafficProfileQuery,
    internalEndpoints,
    externalEndpoints,
    leadGenerationInboundOutboundQuery,
    leadGenerationInboundOutboundAggregateQuery,
    prospectInboundOutboundQuery,
    cloudInboundOutboundQuery,
    cloudInboundOutboundAggregateQuery,
    'mkp.inboundOutboundQuery': mkpInboundOutboundQuery,
    'mkp.inboundOutboundAggregateQuery': mkpInboundOutboundAggregateQuery
  };

  getMaxAgeByLookback(lookback) {
    if (lookback <= 3600) {
      return lookback * 100; // one tenth
    }

    if (lookback <= 86400) {
      return 900000; // 15 minutes
    }

    return 3600000; // one hour
  }

  getQueryKey(query) {
    return JSON.stringify(query);
  }

  cacheQueryResults(key, results) {
    if (!window.sessionStorage) {
      return;
    }

    try {
      window.sessionStorage.setItem(
        key,
        JSON.stringify({
          time: Date.now(),
          results: results.toJS ? results.toJS() : results
        })
      );
    } catch (e) {
      console.warn('Error caching query results locally', e);
    }
  }

  getCachedQueryResults(key, maxAge = 300000) {
    if (!window.sessionStorage) {
      return null;
    }

    const cachedResults = window.sessionStorage.getItem(key);

    if (cachedResults) {
      const { time, ...results } = JSON.parse(cachedResults);

      if (maxAge <= 0 || Date.now() < time + maxAge) {
        return results;
      }
    }

    return null;
  }

  /**
   * I will return you a query that you can mutate all you want - if you ask
   * for a query I know about.
   *
   * @param name
   * @param overrides
   * @returns {null|*}
   */
  get(name, overrides) {
    const query = this.queries[name];
    if (!query) {
      console.warn(`Query ${name} not found!!!`);
      return null;
    }

    const result = defaultsDeep({}, overrides || {}, cloneDeep(query));

    // Force a better (faster) default for Fast vs. Full Data
    if (!result.fastData && result.lookback_seconds) {
      result.fastData =
        result.lookback_seconds > 0 && (result.lookback_seconds < 60 || result.lookback_seconds >= 86400)
          ? 'Fast'
          : 'Full';
    }

    return this.removeOther(result);
  }

  promoteFbdFilterGroup(query, groupName1, groupName2) {
    // broken out like this to keep external getFilterDimensionGroupFilters and addFilters api the same.
    const filters = this.getFilterDimensionGroupFilters(query, groupName1, groupName2);
    const filtersGroups = this.getFilterDimensionGroupFilterGroups(query, groupName1, groupName2);
    const connector = this.getFilterDimensionGroupConnector(query, groupName1, groupName2);
    addFilters(query, filters, connector, filtersGroups);
  }

  getFilterDimensionGroupFilters(query, groupName1, groupName2) {
    const { filterGroups } = query.filterDimensions;
    const { filters } = filterGroups.find((group) => group.name === groupName1 || group.name === groupName2);

    if (!filters) {
      console.warn('Could not find filters', groupName1, groupName2);
      return [];
    }

    return filters;
  }

  getFilterDimensionGroupFilterGroups(query, groupName1, groupName2) {
    const { filterGroups } = query.filterDimensions;
    const { filterGroups: ftlrGrps } = filterGroups.find(
      (group) => group.name === groupName1 || group.name === groupName2
    );

    if (!ftlrGrps) {
      console.warn('Could not find filterGroups', groupName1, groupName2);
      return [];
    }

    return ftlrGrps;
  }

  getFilterDimensionGroupConnector(query, groupName1, groupName2) {
    const { filterGroups } = query.filterDimensions;
    const { connector } = filterGroups.find((group) => group.name === groupName1 || group.name === groupName2);
    return connector || 'All';
  }

  removeOther(query) {
    if (!this.store.$auth.getActiveUserProperty('showNetworkExplorerOther', false)) {
      if (query.filterDimensionsEnabled) {
        query = removeFilters(['other'], query);
      }

      // Always filter out 'other' traffic whether it's FBD or not
      addFilters(query, [
        {
          filterField: 'simple_trf_prof',
          operator: '<>',
          filterValue: 'other'
        }
      ]);
    }

    return query;
  }

  runQuery(query, allowCache, hideToast) {
    if (query) {
      return new Promise((resolve, reject) => {
        const key = this.getQueryKey(query);
        const queryModel = QueryModel.create(query);
        const dataview = new DataViewModel();
        const maxAge = this.getMaxAgeByLookback(query.lookback_seconds);
        const cachedResults = this.getCachedQueryResults(key, maxAge);

        if (allowCache && cachedResults) {
          dataview.loadCachedResults(queryModel, cachedResults);
        } else {
          const serializedQuery = queryModel.serialize();
          dataview.setQuery(serializedQuery);
        }

        const loadedDisposer = reaction(
          () => dataview.loadedCount,
          (loadedCount) => {
            if (loadedCount > 0) {
              const [bucket] = dataview.queryBuckets.activeBuckets;
              const { queryResults: results } = bucket;
              const { fullyLoaded } = dataview.queryBuckets;

              // Single query key may have overlays (multiple async results), ONLY cache once after fully loaded.
              if (fullyLoaded && allowCache) {
                // keep cache call async so heavy lifting doesn't interfere with data render
                setTimeout(() => {
                  this.cacheQueryResults(key, dataview.queryBuckets.activeBucketResults);
                }, 500);
              }

              resolve({ results, dataview, query, queryModel, fullyLoaded });
              loadedDisposer();
            }
          }
        );

        const errorDisposer = reaction(
          () => {
            if (dataview.errorMessage.length > 0) {
              return dataview.errorMessage;
            }

            return false;
          },
          (errorMessage) => {
            if (errorMessage) {
              errorMessage = Array.isArray(errorMessage) ? errorMessage.join('. ') : errorMessage;
              if (!hideToast) {
                showErrorToast(errorMessage || 'An unknown error has occurred when running your query');
              }
              reject(errorMessage);
              errorDisposer();
            }
          }
        );

        return dataview;
      });
    }

    return null;
  }

  // TODO: add APIs to add a filter, merge filter groups, other common/hard things
}

export default new QueryStore();
