import Collection from 'core/model/Collection';
import AddOn from './AddOn';

class AddOnCollection extends Collection {
  companyId;

  constructor({ plans = [], companyId }) {
    super(plans);
    this.companyId = companyId;
  }

  get url() {
    return `/api/ui/sudo/addons/${this.companyId}`;
  }

  get model() {
    return AddOn;
  }
}

export default AddOnCollection;
