import DeckFactory from 'app/stores/decks/DeckFactory';

export default function azureDeckFactory() {
  const { widgets, layout, createWidget, newRow } = new DeckFactory('i_cloud');

  // first row
  createWidget({
    name: 'azureInventory',
    h: 7,
    w: 6,
    smallH: 4
  });
  createWidget({
    name: 'cloudTrafficOverview',
    config: { cloudFilter: 'Azure' },
    h: 7,
    w: 6,
    smallH: 4
  });
  newRow(7);

  // next row
  createWidget({
    name: 'gatewayTraffic',
    config: { cloudType: 'azure', title: 'Azure Gateway Traffic' },
    h: 6,
    w: 4,
    smallH: 4
  });
  createWidget({
    name: 'cloudTrafficProfile',
    config: { cloud_type: 'azure', cloud_region: 'All Regions' },
    h: 4,
    w: 3,
    smallH: 3
  });
  createWidget({
    name: 'cloudFlow',
    config: { cloudFilter: 'Azure' },
    h: 4,
    w: 3,
    smallH: 3
  });

  return {
    widgets,
    layout
  };
}
