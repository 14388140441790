import { action, computed, observable, reaction } from 'mobx';
import { flatten, get, merge } from 'lodash';
import api from 'core/util/api';
import QueryBucketCollection from 'app/stores/query/QueryBucketCollection';
import QueryModel from 'app/stores/query/QueryModel';
import { showErrorToast, showSuccessToast } from 'core/components/toast';

class TopologyStore {
  @observable.shallow
  topologyData;

  queryBuckets = new QueryBucketCollection();

  qryDevices = {};

  flowDataDisposers = {};

  @observable.shallow
  linkPoints;

  @observable.shallow
  nodeInfo;

  @observable.shallow
  flow;

  @observable.shallow
  groupInfo;

  @observable
  selectedDeviceName;

  @observable.shallow
  selectedSites = [];

  @observable.shallow
  selectedProviders = [];

  @observable.shallow
  selectedInterfaces = [];

  @observable
  showProviders;

  @observable
  showUnlinked;

  @observable
  loading = false;

  type;

  @observable
  level;

  highlightColor = 'magenta';

  linkDelim = '||';

  constructor() {
    this.initialize();
  }

  @action
  initialize(props = {}) {
    const {
      selectedDeviceName,
      selectedSites = [],
      selectedProviders = [],
      selectedInterfaces = [],
      showProviders,
      showUnlinked,
      type,
      level
    } = props;

    this.topologyData = { links: {}, nodes: {}, messages: [] };
    this.linkPoints = {};
    this.groupInfo = {};
    this.nodeInfo = {};
    this.flow = {};

    this.selectedDeviceName = selectedDeviceName && selectedDeviceName;
    this.selectedSites = selectedSites;
    this.selectedProviders = selectedProviders;
    this.selectedInterfaces = selectedInterfaces;
    this.showProviders = showProviders;
    this.showUnlinked = !!showUnlinked;
    this.level = level || 'device';
    this.type = type || level;
  }

  @action
  setShowProviders = (type = false) => {
    this.showProviders = type; // false or string
    return this.showProviders;
  };

  @action
  setShowUnlinked = (value) => {
    if (value === undefined) {
      this.showUnlinked = !this.showUnlinked;
    } else {
      this.showUnlinked = value;
    }

    return this.showUnlinked;
  };

  @action
  setLevel = () => {
    this.level = this.level === 'site' ? 'device' : 'site';

    if (this.level === 'site') {
      this.setShowUnlinked(true);
    }

    return this.level;
  };

  @action
  setSelectedSites = (selectedSites) => {
    this.selectedSites = selectedSites;
    return this.selectedSites;
  };

  @action
  setSelectedProviders = (selectedProviders) => {
    this.selectedProviders = selectedProviders;
    return this.selectedProviders;
  };

  @action
  setSelectedDeviceName = (selectedDeviceName) => {
    this.selectedDeviceName = selectedDeviceName;
    return this.selectedDeviceName;
  };

  @computed
  get legend() {
    const legend = {};
    const allFlow = flatten(Object.values(this.flow));

    allFlow.forEach((flowItem) => {
      if (!legend[flowItem.key]) {
        legend[flowItem.key] = { key: flowItem.key, total: 0 };
      }

      legend[flowItem.key].total += flowItem.value;
    });

    return Object.values(legend)
      .sort((a, b) => b.total - a.total)
      .map((item) => item.key);
  }

  @computed
  get hasChanges() {
    return Object.keys(this.nodeInfo).some((key) => {
      const nodeInfo = this.nodeInfo[key];
      const { nodes } = this.topologyData;
      return nodes[key] && (nodeInfo.fx !== nodes[key].fx || nodeInfo.fy !== nodes[key].fy);
    });
  }

  @computed
  get mayNeedReload() {
    return (
      JSON.stringify(this.selectedSites) !== JSON.stringify(get(this.topologyData, 'options.selectedSites')) ||
      JSON.stringify(this.selectedProviders) !== JSON.stringify(get(this.topologyData, 'options.selectedProviders')) ||
      JSON.stringify(this.selectedInterfaces) !==
        JSON.stringify(get(this.topologyData, 'options.selectedInterfaces')) ||
      this.selectedDeviceName !== get(this.topologyData, 'options.selectedDeviceName') ||
      this.showProviders !== get(this.topologyData, 'options.showProviders') ||
      this.showUnlinked !== get(this.topologyData, 'options.showUnlinked') ||
      this.level !== get(this.topologyData, 'options.level')
    );
  }

  @computed
  get hasProviders() {
    return Object.values(this.topologyData.links).some((link) => link.type === 'provider');
  }

  @computed
  get hasUnlinked() {
    return Object.values(this.topologyData.nodes).some((node) => node.type === 'unlinked');
  }

  @computed
  get hasDevices() {
    return Object.values(this.topologyData.links).some((link) => link.type === 'device');
  }

  @computed
  get hasBeenSaved() {
    return !!get(this.topologyData, 'topology.id');
  }

  navigateToSiteTopo = (siteId) => {
    this.history.push(`/v4/kentik-map/logical/sites/${siteId}`);
  };

  navigateToDeviceTopo = (deviceName) => {
    this.history.push(`/v4/kentik-map/logical/devices/${deviceName}`);
  };

  navigateToProviderTopo = (provider) => {
    this.history.push(`/v4/kentik-map/logical/provider/${provider}`);
  };

  navigateToCustomerTopo = (customer) => {
    this.history.push(`/v4/kentik-map/logical/customer/${customer}`);
  };

  navigateToExplorer = (id, dir) => {
    const qryDevices = this.buildQryDevices([this.getLinkOrGroupLink(id)], dir === 'in' ? 'source' : 'target');
    const queries = this.getQueries(qryDevices);
    const query = dir === 'in' ? queries.inputPort : queries.outputPort || queries.inputPort;

    this.store.$explorer.navigateToExplorer({
      ...query,
      topx: 40
    });
  };

  navigateToProvider = (provider) => {
    this.history.push(`/v4/core/quick-views/provider/${provider}`);
  };

  navigateToConnectivityType = (type) => {
    this.history.push(`/v4/core/quick-views/connectivity-type/${type}`);
  };

  @action
  setLinkPoint = (linkId, point) => {
    const linkPoint = this.linkPoints[linkId] || {};
    const x1 = Math.round(point.x1);
    const y1 = Math.round(point.y1);
    const x2 = Math.round(point.x2);
    const y2 = Math.round(point.y2);

    if (linkPoint.x1 !== x1 || linkPoint.y1 !== y1 || linkPoint.x2 !== x2 || linkPoint.y2 !== y2) {
      this.linkPoints[linkId] = {
        x1,
        y1,
        x2,
        y2
      };
    }
  };

  getLinkPoint = (linkId) => this.linkPoints[linkId] || { x1: 0, y1: 0, x2: 0, y2: 0 };

  getLinkSpeed = (linkId) => {
    const link = this.getLinkOrGroupLink(linkId);
    let sourceSpeed = 0;
    let targetSpeed = 0;
    let value = 0;
    let highest = 'Source';
    let isAggregated = false;

    if (link) {
      const sourceConnections = flatten(Object.values(link.sourceConnectionPoints));
      const targetConnections = flatten(Object.values(link.targetConnectionPoints));
      sourceSpeed = sourceConnections.reduce((total, ifc) => total + ifc.snmp_speed, 0);
      targetSpeed = targetConnections.reduce((total, ifc) => total + ifc.snmp_speed, 0);

      if (sourceSpeed >= targetSpeed) {
        value = sourceSpeed;
        highest = 'Source';
        isAggregated = sourceConnections.length > 1;
      } else {
        value = targetSpeed;
        highest = 'Target';
        isAggregated = targetConnections.length > 1;
      }
    }

    return {
      sourceSpeed,
      targetSpeed,
      value,
      highest,
      isAggregated
    };
  };

  @computed
  get links() {
    const { links, nodes } = this.topologyData;
    const newLinks = [];

    Object.keys(links).forEach((key) => {
      const link = links[key];
      const source = nodes[link.source];
      const target = nodes[link.target];

      // filter out providers
      if (
        (!this.showProviders && link.type === 'provider') ||
        (this.showProviders === 'name' && link.type === 'provider' && link.isCtProvider) ||
        (this.showProviders === 'connectivity' && link.type === 'provider' && !link.isCtProvider)
      ) {
        return;
      }

      // filter out links not for this provider/customer
      if (
        this.showProviders === 'name' &&
        this.selectedProviders.length > 0 &&
        !this.selectedProviders.includes(source.name) &&
        !this.selectedProviders.includes(target.name)
      ) {
        return;
      }

      // filter out links not for this site
      if (
        this.selectedSites.length > 0 &&
        !this.selectedSites.includes(source.siteId) &&
        !this.selectedSites.includes(target.siteId)
      ) {
        return;
      }

      // filter out links not for this device
      if (
        this.selectedDeviceName &&
        source.name !== this.selectedDeviceName &&
        target.name !== this.selectedDeviceName
      ) {
        return;
      }

      if (this.level === 'device' && this.level !== link.level) {
        return;
      }

      if (this.level === 'site' && this.level !== link.level) {
        return;
      }

      newLinks.push(link);
    });

    return newLinks.reduce((accum, link) => ({ ...accum, [link.id]: link }), {});
  }

  @computed
  get nodes() {
    const { nodes } = this.topologyData;

    const newNodes = Object.keys(this.links).reduce((accum, key) => {
      const link = this.links[key];

      accum[link.source] = { ...nodes[link.source] };
      accum[link.target] = { ...nodes[link.target] };

      return accum;
    }, {});

    if (this.showUnlinked) {
      Object.keys(nodes).forEach((key) => {
        const node = nodes[key];

        // filter out unlinked site nodes that are not for this site
        if (node.siteId && this.selectedSites.length > 0 && !this.selectedSites.includes(node.siteId)) {
          return;
        }

        if ((this.level === 'device' && node.type === 'unlinked') || (this.level === 'site' && node.type === 'site')) {
          newNodes[node.id] = { ...node };
        }
      });
    }

    return newNodes;
  }

  @action
  setNodeInfo = (node) => {
    this.nodeInfo[node.id] = {
      ...this.nodeInfo[node.id],
      ...node,
      fx: Math.round(node.fx),
      fy: Math.round(node.fy)
    };
  };

  @computed
  get groups() {
    const groups = {};

    if (this.level !== 'device') {
      return groups;
    }

    Object.keys(this.nodes).forEach((key) => {
      const node = this.nodes[key];
      const groupId = node.siteId;
      const info = this.groupInfo[groupId] || {};
      const groupName = node.group;
      const groupNode = this.topologyData.nodes[groupId];

      if (node.group) {
        groups[groupId] = {
          id: groupId,
          name: groupName,
          type: 'site',
          description: groupNode && groupNode.description,
          collapsed: info.collapsed || false,
          centroid: info.centroid,
          polygon: info.polygon
        };
      }
    });

    return groups;
  }

  @computed
  get hasGroups() {
    return Object.keys(this.groups).length > 0;
  }

  getGroup = (groupId) => this.groups[groupId];

  @action
  setGroupInfo = (group) => {
    this.groupInfo[group.id] = {
      ...this.groupInfo[group.id],
      ...group
    };
  };

  @action
  fixGroupPositions = () => {
    Object.values(this.groupInfo).forEach((group) => {
      const nodeInfo = this.nodeInfo[group.id];
      const node = this.nodes[group.id] || {};
      const centroid = node.fx ? [node.fx, node.fy] : group.centroid;

      if (!nodeInfo || !nodeInfo.fx) {
        this.setNodeInfo({ id: group.id, fx: centroid[0], fy: centroid[1] });
      }
    });
  };

  getNodeFlowTotal = (node) => {
    const links = node.isCtProvider
      ? Object.keys(this.links).filter((linkId) => this.links[linkId].target === node.id)
      : node.links;

    const results = {
      ingress: [],
      egress: []
    };

    if (links) {
      links.forEach((linkId) => {
        const flipDirection = node.id.toString() === linkId.split(this.linkDelim)[1];
        results.ingress = results.ingress.concat(this.getFlowData(this.links[linkId], flipDirection ? 'out' : 'in'));
        results.egress = results.egress.concat(this.getFlowData(this.links[linkId], flipDirection ? 'in' : 'out'));
      });
    }

    results.ingress = this.mergeFlow(results.ingress);
    results.egress = this.mergeFlow(results.egress);

    return results;
  };

  getGroupFromNode = (nodeId) => {
    const node = this.nodes[nodeId];

    if (node && node.group) {
      return this.groups[node.siteId];
    }

    return undefined;
  };

  getNodesFromGroup = (groupId) => Object.keys(this.nodes).filter((key) => this.nodes[key].siteId === groupId);

  @action
  setFlow = (flow) => {
    Object.keys(flow).forEach((flowId) => {
      this.flow[flowId] = flow[flowId];
    });
  };

  hasFlowDataKey = (flowData, key) => flowData.some((data) => data.key === key);

  mergeFlow = (flow) =>
    flow.reduce((accum, flowItem) => {
      const idx = accum.findIndex((accumItem) => flowItem.key === accumItem.key);

      if (idx > -1) {
        accum[idx].value = parseFloat((accum[idx].value + flowItem.value).toFixed(2));
      } else {
        accum.push({ ...flowItem });
      }

      return accum;
    }, []);

  getFlowData = (link = {}, dir = 'out') => {
    const { source, target } = link;
    const linkName = dir === 'in' ? `${source}${this.linkDelim}${target}` : `${target}${this.linkDelim}${source}`;

    if (this.flow && this.flow[linkName]) {
      return this.mergeFlow(this.flow[linkName]);
    }

    // merge data for all device links
    if ((link.isGroup || link.isCtProvider) && link.deviceLinks) {
      const data = link.deviceLinks.reduce((accum, key) => {
        const deviceLink = this.topologyData.links[key] || {
          source: key.split(this.linkDelim)[0],
          target: key.split(this.linkDelim)[1]
        };
        const flowData = this.getFlowData(deviceLink, dir);
        if (flowData) {
          accum = accum.concat(flowData);
        }
        return accum;
      }, []);

      return this.mergeFlow(data);
    }

    return [];
  };

  reverseLinkKey = (key) => key.split(this.linkDelim).reverse().join(this.linkDelim);

  hasFlow = (node) => {
    let hasFlow = false;

    if (!node.links || node.links.length === 0) {
      return false;
    }

    node.links.forEach((key) => {
      const link = this.topologyData.links[key];
      const flowOut = this.getFlowData(link);
      const flowIn = this.getFlowData(link, 'in');
      hasFlow = hasFlow || flowOut.length > 0 || flowIn.length > 0;
    });

    return hasFlow;
  };

  getLinkOrGroupLink = (linkId) => this.links[linkId] || this.groupLinks[linkId];

  @computed
  get groupLinks() {
    const { links } = this.topologyData;
    const groupLinks = {};

    if (this.level !== 'device') {
      return groupLinks;
    }

    Object.keys(links).forEach((key) => {
      const link = links[key];
      if (link.level === 'site' && this.groups[link.source] && this.groups[link.target]) {
        groupLinks[key] = { ...link };
      }
    });

    return groupLinks;
  }

  addQryDevice = (qryDevices, key, connectionPoints, useOutputPort) => {
    if (!qryDevices[key] && connectionPoints && connectionPoints.length > 0) {
      return {
        ...qryDevices,
        [key]: {
          key,
          deviceName: connectionPoints[0].device_name,
          deviceInterfaces: connectionPoints.map((point) => point.snmp_id),
          useOutputPort
        }
      };
    }

    return qryDevices;
  };

  @action
  buildQryDevices = (links, connection = 'both') => {
    let qryDevices = {};

    links.forEach((link) => {
      const { type, deviceLinks, sourceConnectionPoints, targetConnectionPoints } = link;

      deviceLinks.forEach((key) => {
        const useOutputPort = type === 'provider' || type === 'backbone';

        if (connection === 'both' || connection === 'source') {
          qryDevices = this.addQryDevice(qryDevices, key, sourceConnectionPoints[key], false);
        }

        if (connection === 'both' || connection === 'target') {
          qryDevices = this.addQryDevice(
            qryDevices,
            this.reverseLinkKey(key),
            useOutputPort ? sourceConnectionPoints[key] : targetConnectionPoints[key],
            useOutputPort
          );
        }
      });
    });

    return qryDevices;
  };

  @action
  buildQueryBuckets = () => {
    const queries = this.getQueries();
    const qryDevices = Object.values(this.qryDevices);
    const snmpMatcher = /\((\d+)\)$/;

    Object.keys(queries).forEach((key) => {
      const [bucket] = this.queryBuckets.add({ name: key });
      const query = queries[key];

      bucket.queries.add(QueryModel.create(query).serialize());

      this.flowDataDisposers[key] = reaction(
        () => !bucket.loading,
        () => {
          bucket.queryResults.each((model) => {
            const port = model.get('output_port') || model.get('input_port') || '';
            const snmpMatches = port.match(snmpMatcher);
            const snmp_id = snmpMatches && snmpMatches.length === 2 ? snmpMatches[1] : '';
            const qryDevice = qryDevices.find(
              (device) =>
                device.deviceName === model.get('i_device_name') &&
                device.useOutputPort === model.has('output_port') &&
                device.deviceInterfaces.includes(snmp_id)
            );

            if (qryDevice) {
              this.setFlow({
                [qryDevice.key]: [
                  ...(this.flow[qryDevice.key] || []),
                  {
                    key: model.get('i_protocol_name'),
                    value: model.get('p95th_bits_per_sec')
                  }
                ]
              });
            }
          });
        }
      );
    });
  };

  getQueries(devices = this.qryDevices) {
    const outputPortDevices = Object.values(devices).filter((device) => device.useOutputPort);
    const inputPortDevices = Object.values(devices).filter((device) => !device.useOutputPort);

    const hasOutputPort = outputPortDevices.length > 0;
    const hasInputPort = inputPortDevices.length > 0;
    const queries = {};

    const baseQuery = {
      viz_type: 'table',
      show_overlay: false,
      show_total_overlay: false,
      customAsGroups: false,
      metric: ['i_device_id', 'Proto'],
      aggregateTypes: ['p95th_bits_per_sec'],
      topx: 0,
      all_devices: false,
      lookback_seconds: 3600,
      depth: 350
    };

    if (hasOutputPort) {
      queries.outputPort = {
        ...baseQuery,
        metric: [...baseQuery.metric, 'InterfaceID_dst'],
        device_name: outputPortDevices.map((device) => device.deviceName),
        filters: {
          connector: 'Any',
          filterGroups: outputPortDevices.map(({ deviceName, deviceInterfaces }) =>
            this.getDeviceInterfacesFilterGroup(deviceName, deviceInterfaces, true)
          )
        }
      };
    }

    if (hasInputPort) {
      queries.inputPort = {
        ...baseQuery,
        metric: [...baseQuery.metric, 'InterfaceID_src'],
        device_name: inputPortDevices.map((device) => device.deviceName),
        filters: {
          connector: 'Any',
          filterGroups: inputPortDevices.map(({ deviceName, deviceInterfaces }) =>
            this.getDeviceInterfacesFilterGroup(deviceName, deviceInterfaces, false)
          )
        }
      };
    }

    return queries;
  }

  getDeviceInterfacesFilterGroup(deviceName, deviceInterfaces, useOutputPort) {
    return {
      name: '',
      named: false,
      connector: 'All',
      not: false,
      autoAdded: true,
      saved_filters: [],
      filters: [
        {
          filterField: 'i_device_name',
          operator: '=',
          filterValue: deviceName
        }
      ],
      filterGroups: [
        {
          name: '',
          named: false,
          connector: 'Any',
          not: false,
          autoAdded: true,
          filters: deviceInterfaces.map((snmp_id) => ({
            filterField: useOutputPort ? 'output_port' : 'input_port',
            operator: '=',
            filterValue: snmp_id
          }))
        }
      ]
    };
  }

  @action
  reload = () => {
    this.getTopologyData(
      {
        selectedSites: this.selectedSites,
        selectedProviders: this.selectedProviders,
        selectedInterfaces: this.selectedInterfaces,
        selectedDeviceName: this.selectedDeviceName,
        showProviders: this.showProviders,
        showUnlinked: this.showUnlinked,
        type: this.type,
        level: this.level
      },
      true
    );
  };

  @action
  saveTopology = async () =>
    api
      .post('/api/ui/topology/save', {
        body: {
          topo: {
            name: 'Topology',
            type: this.type,
            settings: {
              selectedDeviceName: this.selectedDeviceName,
              selectedSites: this.selectedSites,
              selectedProviders: this.selectedProviders,
              selectedInterfaces: this.selectedInterfaces,
              showProviders: this.showProviders,
              showUnlinked: this.showUnlinked
            }
          },
          nodes: this.nodeInfo
        }
      })
      .then((result) => {
        const { id, nodes } = result;
        this.topologyData.id = id;
        merge(this.topologyData.nodes, nodes);
        showSuccessToast('Saved successfully', { title: 'Saved' });
      });

  @action
  setTopologyData = (topologyData) => {
    if (topologyData.messages.length > 0) {
      topologyData.messages.forEach((message) => {
        console.warn(message);
        showErrorToast(message);
      });

      showErrorToast('Consider limiting your view by site');
    }

    this.loading = false;
    this.topologyData = topologyData;
    this.showProviders = topologyData.options.showProviders;
    this.showUnlinked = topologyData.options.showUnlinked;
    this.level = topologyData.options.level;
    this.selectedSites = topologyData.options.selectedSites || [];
    this.selectedProviders = topologyData.options.selectedProviders || [];

    this.queryBuckets = new QueryBucketCollection();
    this.flowDataDisposers = {};
    this.qryDevices = this.buildQryDevices(Object.values(topologyData.links));
    this.buildQueryBuckets();
    this.queryBuckets.each((bucket) => bucket.subscribe());

    return this.topologyData;
  };

  @action
  getTopologyData = async (props = {}, reload = false) => {
    const { nodes } = this.topologyData;
    const {
      showProviders,
      showUnlinked,
      selectedDeviceName,
      selectedSites = [],
      selectedProviders = [],
      selectedInterfaces = []
    } = props;

    // avoid reloading if possible
    if (
      !reload &&
      Object.keys(nodes).length > 0 &&
      this.showProviders === showProviders &&
      this.showUnlinked === showUnlinked &&
      this.selectedDeviceName === selectedDeviceName &&
      this.selectedProviders.toString() === selectedProviders.toString() &&
      this.selectedSites.toString() === selectedSites.toString() &&
      this.selectedInterfaces.map((iface) => `${iface.device_id}|${iface.snmp_id}`).toString() ===
        selectedInterfaces.map((iface) => `${iface.device_id}|${iface.snmp_id}`).toString()
    ) {
      return this.topologyData;
    }

    this.initialize(props);
    this.loading = true;

    return api.post('/api/ui/topology/topo', { body: { options: props } }).then((res) => this.setTopologyData(res));
  };
}

export default new TopologyStore();
