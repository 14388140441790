import Collection from 'core/model/Collection';
import SiteMarketModel from './SiteMarketModel.js';

class SiteMarketCollection extends Collection {
  get url() {
    return '/api/ui/siteMarkets';
  }

  get model() {
    return SiteMarketModel;
  }

  get defaultSortState() {
    return {
      field: 'name',
      direction: 'asc'
    };
  }

  get filterFieldWhitelist() {
    return new Set(['id', 'name']);
  }
}

export default SiteMarketCollection;
