import { buildFilterGroup } from 'core/util/filters';

export default function getQueryFilterGroups(queryFilters, direction) {
  const filterDirection = direction || 'src|dst';

  const { sites, selectedCountries, selectedCities, selectedRegions, selectedBGPCommunities } = queryFilters.get([
    'sites',
    'selectedCountries',
    'selectedCities',
    'selectedRegions',
    'selectedBGPCommunities'
  ]);

  const filterGroups = [];
  if (sites && sites.length) {
    filterGroups.push(
      buildFilterGroup({
        connector: 'Any',
        filters: sites.map((site) => ({
          filterField: 'i_device_site_name',
          filterValue: site,
          operator: '='
        }))
      })
    );
  }

  if (selectedCountries && selectedCountries.length) {
    filterGroups.push(
      buildFilterGroup({
        connector: 'Any',
        filters: selectedCountries.map((geo) => ({
          filterField: `${filterDirection}_geo`,
          filterValue: geo,
          operator: '='
        }))
      })
    );
  }

  if (selectedRegions && selectedRegions.length) {
    filterGroups.push(
      buildFilterGroup({
        connector: 'Any',
        filters: selectedRegions.map((region) => ({
          filterField: `${filterDirection}_geo_region`,
          filterValue: region,
          operator: '='
        }))
      })
    );
  }

  if (selectedCities && selectedCities.length) {
    filterGroups.push(
      buildFilterGroup({
        connector: 'Any',
        filters: selectedCities.map((city) => ({
          filterField: `${filterDirection}_geo_city`,
          filterValue: city,
          operator: '='
        }))
      })
    );
  }

  if (selectedBGPCommunities && selectedBGPCommunities.length) {
    filterGroups.push(
      buildFilterGroup({
        connector: 'Any',
        filters: selectedBGPCommunities.map((bgpCommunity) => ({
          filterField: `${filterDirection}_bgp_community`,
          filterValue: bgpCommunity,
          operator: '='
        }))
      })
    );
  }
  return filterGroups;
}
