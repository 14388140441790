import Model from 'core/model/Model';

class TypeModel extends Model {
  get defaults() {
    return {
      settings: {}
    };
  }

  get urlRoot() {
    return '/api/ui/ott/type';
  }
}

export default TypeModel;
