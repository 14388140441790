import { get } from 'lodash';
import { computed } from 'mobx';
import {
  DEFAULT_PALETTES,
  DEFAULT_QUALITATIVE_PALETTES,
  defaultDarkLabelColor,
  defaultDarkOverlayColor,
  defaultDarkPrimaryOverlayColor,
  defaultLabelColor,
  defaultOverlayColor,
  defaultPrimaryOverlayColor,
  demoEnvironmentColor,
  tabbedChartColors,
  tabbedChartOverlay
} from './Colors';

class ColorStore {
  @computed
  get defaultVisualizationSettings() {
    return {
      standard: {
        palette: this.store.$app.isSharedLink ? 'colorsafe3' : 'default',
        qualitative: 'default',
        primaryOverlay: defaultPrimaryOverlayColor,
        overlay: defaultOverlayColor,
        labels: defaultLabelColor
      },
      dark: {
        palette: 'neutralHue',
        qualitative: 'default',
        primaryOverlay: defaultDarkPrimaryOverlayColor,
        overlay: defaultDarkOverlayColor,
        labels: defaultDarkLabelColor
      }
    };
  }

  @computed
  get visualizationPrefs() {
    const { $auth } = this.store;
    const { visualizations } = $auth.userSettings;

    return visualizations;
  }

  @computed
  get chartColors() {
    const { $app } = this.store;
    return $app.darkThemeEnabled ? this.darkColors : this.lightColors;
  }

  @computed
  get lightColors() {
    const customPalette = get(this.visualizationPrefs, 'standard.paletteCustom', []);
    const palette = get(this.visualizationPrefs, 'standard.palette', 'default');

    return customPalette.length > 0 ? customPalette : DEFAULT_PALETTES[palette];
  }

  @computed
  get darkColors() {
    const customPalette = get(this.visualizationPrefs, 'dark.paletteCustom', []);
    const palette = get(this.visualizationPrefs, 'dark.palette', 'leGreg');

    return customPalette.length > 0 ? customPalette : DEFAULT_PALETTES[palette];
  }

  @computed
  get primaryOverlayColor() {
    const { $app } = this.store;
    const color = get(this.visualizationPrefs, 'standard.primaryOverlay', defaultPrimaryOverlayColor);
    const darkColor = get(this.visualizationPrefs, 'dark.primaryOverlay', defaultDarkPrimaryOverlayColor);

    if ($app.darkThemeEnabled) {
      return darkColor;
    }

    return color;
  }

  @computed
  get overlayColor() {
    const { $app } = this.store;
    const color = get(this.visualizationPrefs, 'standard.overlay', defaultOverlayColor);
    const darkColor = get(this.visualizationPrefs, 'dark.overlay', defaultDarkOverlayColor);

    if ($app.darkThemeEnabled) {
      return darkColor;
    }

    return color;
  }

  @computed
  get qualitativeColors() {
    const { $app } = this.store;
    const palette = get(this.visualizationPrefs, 'standard.qualitative', 'default');
    const darkPalette = get(this.visualizationPrefs, 'dark.qualitative', 'leGreg');

    const customPalette = get(this.visualizationPrefs, 'standard.qualitativeCustom', []);
    const customDarkPalette = get(this.visualizationPrefs, 'dark.qualitativeCustom', []);

    if ($app.darkThemeEnabled) {
      return customDarkPalette.length > 0 ? customDarkPalette : DEFAULT_QUALITATIVE_PALETTES[darkPalette];
    }

    return customPalette.length > 0 ? customPalette : DEFAULT_QUALITATIVE_PALETTES[palette];
  }

  @computed
  get chartLabelColor() {
    const { $app } = this.store;
    const color = get(this.visualizationPrefs, 'standard.labels', defaultLabelColor);
    const darkColor = get(this.visualizationPrefs, 'dark.labels', defaultDarkLabelColor);

    if ($app.darkThemeEnabled) {
      return darkColor;
    }

    return color;
  }

  getTabColorIndex(tabName) {
    const tabNameLower = tabName.toLowerCase();

    if (tabNameLower === 'internal') {
      return 0;
    }
    if (tabNameLower === 'inbound' || tabNameLower === 'ingress') {
      return 1;
    }
    if (tabNameLower === 'outbound' || tabNameLower === 'egress') {
      return 2;
    }
    if (tabNameLower === 'through') {
      return 3;
    }
    if (tabNameLower === 'other') {
      return 4;
    }
    return undefined;
  }

  getTabColor(tabName) {
    const tabNameLower = tabName.toLowerCase();

    if (tabNameLower === 'internal') {
      return tabbedChartColors[0];
    }
    if (tabNameLower === 'inbound' || tabNameLower === 'ingress') {
      return tabbedChartColors[1];
    }
    if (tabNameLower === 'outbound' || tabNameLower === 'egress') {
      return tabbedChartColors[2];
    }
    if (tabNameLower === 'through') {
      return tabbedChartColors[3];
    }
    if (tabNameLower === 'other') {
      return tabbedChartColors[4];
    }
    if (tabNameLower === 'total') {
      return tabbedChartOverlay;
    }
    return null;
  }

  getQualitativeTabColor(tabName) {
    const tabNameLower = tabName.toLowerCase();
    if (tabNameLower === 'total') {
      return this.qualitativeTabbedChartColors.primaryOverlay;
    }
    const colorIndex = this.getTabColorIndex(tabName);
    return this.qualitativeTabbedChartColors.paletteCustom[colorIndex > 0 ? colorIndex - 1 : colorIndex];
  }

  getAllTabbedChartColors(tabName = 'Total', tabNames, hasGroupBys) {
    let paletteCustom = tabbedChartColors;
    const primaryOverlay = this.getTabColor(tabName);
    const tabNameLower = tabName.toLowerCase();

    if (tabNameLower === 'internal') {
      paletteCustom = hasGroupBys ? DEFAULT_PALETTES.purpleHue2 : [primaryOverlay];
    } else if (tabNameLower === 'inbound' || tabNameLower === 'ingress') {
      paletteCustom = hasGroupBys ? DEFAULT_PALETTES.orangeHue : [primaryOverlay];
    } else if (tabNameLower === 'outbound' || tabNameLower === 'egress') {
      paletteCustom = hasGroupBys ? DEFAULT_PALETTES.greenHue : [primaryOverlay];
    } else if (tabNameLower === 'through') {
      paletteCustom = hasGroupBys ? DEFAULT_PALETTES.blueHue : [primaryOverlay];
    } else if (tabNameLower === 'other') {
      paletteCustom = hasGroupBys ? DEFAULT_PALETTES.pinkHue : [primaryOverlay];
    } else if (tabNames) {
      paletteCustom = tabNames.map((name) => this.getTabColor(name));
    }

    return {
      dark: {
        paletteCustom,
        primaryOverlay
      },
      standard: {
        paletteCustom,
        primaryOverlay
      }
    };
  }

  getDemoEnvironmentColor() {
    return demoEnvironmentColor;
  }

  @computed
  get qualitativeTabbedChartColors() {
    const { $app } = this.store;
    return this.allQualitativeTabbedChartColors[$app.darkThemeEnabled ? 'dark' : 'standard'];
  }

  get allQualitativeTabbedChartColors() {
    return {
      dark: {
        paletteCustom: this.qualitativeColors,
        primaryOverlay: tabbedChartOverlay
      },
      standard: {
        paletteCustom: this.qualitativeColors,
        primaryOverlay: tabbedChartOverlay
      }
    };
  }

  getDefaultPaletteColor(color) {
    return DEFAULT_PALETTES[color];
  }

  getDefaultQualitativePaletteColor(color) {
    return DEFAULT_QUALITATIVE_PALETTES[color];
  }
}

export default new ColorStore();
