import { Collection } from 'core/model';
import { escapeRegExp } from 'lodash';
import SuppressionModel from './SuppressionModel';

export default class SuppressionCollection extends Collection {
  get url() {
    return '/api/ui/alerting/suppressions';
  }

  get jsonRoot() {
    return 'suppressions';
  }

  get model() {
    return SuppressionModel;
  }

  get presetFilters() {
    return [
      {
        label: 'Active',
        fn: (model) => !model.expired
      },
      {
        label: 'Expired',
        fn: (model) => model.expired
      }
    ];
  }

  get defaultSortState() {
    return {
      field: 'creationTime',
      direction: 'desc'
    };
  }

  filter(searchValue) {
    let customFilter;
    if (searchValue !== undefined) {
      const regex = new RegExp(escapeRegExp(searchValue), 'i');
      customFilter = (model) => {
        // allow search matches against comments and dimension value/key/label
        const commentsMatch = regex.test(model.get('comment'));
        let dimensionsMatch = false;

        const dimensions = model.get('dimensionToKeyPart');
        if (dimensions) {
          let valueMatch = false;
          let keyMatch = false;
          let labelMatch = false;
          Object.entries(dimensions).forEach(([key, value]) => {
            valueMatch = valueMatch || regex.test(value);
            keyMatch = keyMatch || regex.test(key);
            const dimension = this.store.$dictionary.dimensionLabelsMap[key];
            if (dimension?.label) {
              labelMatch = labelMatch || regex.test(dimension.label);
            }
          });
          dimensionsMatch = valueMatch || keyMatch || labelMatch;
        }
        return commentsMatch || dimensionsMatch;
      };
    }
    return super.filter(searchValue, { customFilter });
  }
}
