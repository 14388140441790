import { Model } from 'core/model';
import { omit } from 'lodash';
import { observable } from 'mobx';
import InterfaceGroupModel from '../interfaceGroup/InterfaceGroupModel';

export default class MonitoringTemplateModel extends Model {
  @observable.ref
  interfaceGroup = new InterfaceGroupModel();

  get urlRoot() {
    return '/api/ui/recon/monitoringTemplates';
  }

  get omitDuringSerialize() {
    return ['simple', 'advanced', 'device_count', 'user_full_name', '_interfaceGroup'];
  }

  get isPreset() {
    return this.get('preset') > 0;
  }

  duplicate = (options = {}) => {
    const { isNew = false, save = false, deserialize = true } = options;
    const attributes = {
      ...omit(this.get(), ['id']),
      name: isNew ? '' : `Copy of ${this.get('name')}`,
      description: '',
      preset: 0
    };

    if (!this.collection) {
      return new this.constructor(attributes);
    }

    const dupe = this.collection.build(attributes, { deserialize });

    if (save) {
      dupe.save(attributes);
    }

    return dupe;
  };

  deserialize(data) {
    const { interface_group_id } = data;
    const device_count = Number.parseInt(data.device_count) || 0;
    this.interfaceGroup.set('id', interface_group_id);
    return { ...data, device_count };
  }

  serialize(data) {
    const saveInterfaceGroup = !!data._interfaceGroup && (this.interfaceGroup.hasFilters || !this.interfaceGroup.isNew);
    const interfaceGroup = saveInterfaceGroup ? this.interfaceGroup.serialize(this.interfaceGroup.get()) : null;
    return super.serialize({ ...data, interfaceGroup });
  }
}
