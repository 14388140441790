import { computed, action } from 'mobx';
import Model from 'core/model/Model';
import { getStartAndEndFromLookback, timezone } from 'core/util/dateUtils';

export default class HybridSettingsModel extends Model {
  get defaults() {
    return {
      showInterSiteTraffic: false,
      showDefaultNetworks: false,
      interSiteTraffic: null,
      connectionType: 'all',
      sidebarQueryOverrides: {
        lookback_seconds: 86400,
        time_format: timezone.value
      },
      searchTerm: '',
      savedSearches: [],
      azureSubscriptionIds: [],
      cloudProviders: ['aws', 'azure']
    };
  }

  @computed
  get queryTimeOptions() {
    const queryOverrides = this.get('sidebarQueryOverrides');

    if (queryOverrides) {
      const { filters, ...timeOptions } = queryOverrides;
      return timeOptions;
    }

    return {};
  }

  @computed
  get sidebarSettingsWithTimeRange() {
    const sidebarSettings = this.get();
    let start = '';
    let end = '';

    if (sidebarSettings && sidebarSettings.sidebarQueryOverrides) {
      const { starting_time, ending_time, lookback_seconds } = sidebarSettings.sidebarQueryOverrides;

      if (lookback_seconds) {
        const { start: startMoment, end: endMoment } = getStartAndEndFromLookback(lookback_seconds, 0);
        start = startMoment.toISOString();
        end = endMoment.toISOString();
      } else if (starting_time && ending_time) {
        start = timezone.momentFn(starting_time).toISOString();
        end = timezone.momentFn(ending_time).toISOString();
      }
    }

    return { ...sidebarSettings, timeRange: { start, end } };
  }

  @action
  setTrafficType(trafficType) {
    if (trafficType !== 'interfaces') {
      // enable intersite traffic
      this.set('showInterSiteTraffic', true);
      this.set('interSiteTraffic', trafficType);
    } else {
      // disable intersite traffic
      this.set('showInterSiteTraffic', false);

      // reset the layer visibility to all layers
      this.set('connectionType', 'all');
    }

    this.set('trafficType', trafficType);
  }
}
