import React from 'react';

import { get } from 'lodash';
import { IoMdGlobe } from 'react-icons/io';
import { FiActivity, FiTrendingDown, FiTrendingUp, FiHeart, FiTarget, FiSliders, FiShield } from 'react-icons/fi';
import {
  MdWarning,
  MdArrowUpward,
  MdArrowDownward,
  MdSwapVerticalCircle,
  MdSettingsApplications,
  MdError,
  MdCompareArrows
} from 'react-icons/md';

import { formatBytesGreek } from 'core/util/greekPrefixing';
import { greekIt, formatNumber, zeroToText } from 'app/util/utils';
import $alerting from 'app/stores/alerting/$alerting';
import $insights from 'app/stores/insight/$insights';
import { getCapacityMetric, getComparisonData, getPercentage } from 'app/stores/insight/insightUtils';
import { INSIGHT_SEVERITY_COLORS, INSIGHT_SEVERITY_LABELS } from 'app/stores/insight/insightConstants';

import Icon from 'core/components/Icon';
import Link from 'core/components/Link';
import Lookup from 'core/components/Lookup';
import Text from 'core/components/Text';
import Box from 'core/components/Box';
import ApplicationLink from 'app/components/links/ApplicationLink';
import AsnLink from 'app/components/links/AsnLink';
import CdnLink from 'app/components/links/CdnLink';
import DeviceLink from 'app/components/links/DeviceLink';
import DimensionToLink from 'app/components/links/DimensionToLink';
import InsightDimensionToLink from 'app/views/insights/components/InsightDimensionToLink';
import FlowsThisWeekOverLastWeek from 'app/components/insights/visualizations/FlowsThisWeekOverLastWeek';
import InterfaceLink from 'app/components/links/InterfaceLink';
import IpLink from 'app/components/links/IpLink';
import SiteLink from 'app/components/links/SiteLink';

const INSIGHT_ICON_SIZE = 38;

const iconMap = {
  IoMdGlobe,
  FiActivity,
  FiTrendingDown,
  FiTrendingUp,
  FiHeart,
  FiTarget,
  FiSliders,
  FiShield,
  MdWarning,
  MdArrowUpward,
  MdArrowDownward,
  MdSwapVerticalCircle,
  MdSettingsApplications,
  MdError,
  MdCompareArrows,
  'symbol-circle': 'symbol-circle',
  unknown: 'help'
};

export const getIcon =
  (name) =>
  ({ severity }) => {
    const color = severity ? INSIGHT_SEVERITY_COLORS[severity] : 'muted';

    // Only blueprint icon names plus certain react-icons names are allowed
    if (!name || ((name.startsWith('Io') || name.startsWith('Fi') || name.startsWith('Md')) && !iconMap[name])) {
      name = 'help';
    }

    return <Icon icon={iconMap[name] || name || iconMap.unknown} iconSize={INSIGHT_ICON_SIZE} color={color} />;
  };

function listComparisonKeys(keys, dimension) {
  return keys.map(({ dimensionToValue }, idx) => (
    <Text key={dimensionToValue[dimension]}>
      <DimensionToLink dimension={dimension} dimensionToValue={dimensionToValue}>
        <Lookup lookup={dimension} value={dimensionToValue[dimension]} />
      </DimensionToLink>
      {idx < keys.length - 1 && keys.length > 2 ? ', ' : ''}
      {idx === keys.length - 2 ? ' and ' : ''}
    </Text>
  ));
}

const getComparisonDescriptionFn = (dimension, type) => (insight) => {
  const { newKeys, oldKeys, dropPercentage, dropKey, rankChangeKey } = getComparisonData(insight);
  // eslint-disable-next-line react/destructuring-assignment
  const { numKeysHighValueChange, maxAvgValueChange, thresholds = {} } = insight.comparison;
  const {
    maxAvgValueChange: maxAvgValueChangeThreshold = 0.75,
    nKeysHighValueChange: numKeysHighValueChangeThreshold = 4
  } = thresholds;

  let hasMentionedWeek = false;
  const needMentionWeek = () => {
    const need = !hasMentionedWeek;
    hasMentionedWeek = true;
    return need;
  };

  const fragment = (
    <>
      {newKeys.length > 0 && (
        <>
          {listComparisonKeys(newKeys, dimension)} are new top {type}
          {needMentionWeek() && ' this week'}.{' '}
        </>
      )}
      {oldKeys.length > 0 && (
        <>
          {listComparisonKeys(oldKeys, dimension)} are no longer top {type}
          {needMentionWeek() && ' this week'}.{' '}
        </>
      )}
      {dropKey && (
        <>
          <DimensionToLink dimension={dimension} dimensionToValue={dropKey.dimensionToValue}>
            <Lookup lookup={dimension} value={dropKey.dimensionToValue[dimension]} />
          </DimensionToLink>{' '}
          had a <strong>{Math.abs((dropPercentage * 100).toFixed(1))}%</strong> decrease in traffic
          {needMentionWeek() && ' since last week'}.{' '}
        </>
      )}
      {maxAvgValueChange > maxAvgValueChangeThreshold && (
        <>
          The average traffic for {type} has increased by <strong>{(maxAvgValueChange * 100).toFixed(1)}%</strong>
          {needMentionWeek() && ' since last week'}.{' '}
        </>
      )}
      {numKeysHighValueChange > numKeysHighValueChangeThreshold && (
        <>
          {needMentionWeek() && 'Since last week, '}
          {numKeysHighValueChange} {type} have had large increases in traffic.
        </>
      )}
      {rankChangeKey && (
        <>
          <DimensionToLink dimension={dimension} dimensionToValue={rankChangeKey.dimensionToValue}>
            <Lookup lookup={dimension} value={rankChangeKey.dimensionToValue[dimension]} />
          </DimensionToLink>{' '}
          {Number(rankChangeKey.rankNew) < Number(rankChangeKey.rankOld) ? 'rose' : 'fell'}{' '}
          {Math.abs(rankChangeKey.rankNew - rankChangeKey.rankOld)} places in the top {type}
          {needMentionWeek() && ' this week'}.
        </>
      )}
    </>
  );
  return fragment.props.children.some((child) => !!child) ? fragment : null;
};

function getFactorsInterfaceUtilization(direction) {
  return {
    description: (insight) => {
      const { factors, insightName, dimensionToValue } = insight;

      // Usually there is only 1 explainingFactor, in the future we can support multiples in a nicer way.
      const valueChange = get(factors, 'event.valueChange.bps', 0);
      const { device_id, snmp_id } = dimensionToValue;
      const factorDimensionToValue = get(factors, 'explainingFactors[0].dimensionToValue');
      const explaingFactorDimensions = Object.keys(factorDimensionToValue);

      const explaingFactors = explaingFactorDimensions.map((key) => (
        <Box as="span" key={key} pr={1}>
          <Text fontWeight="bold">{$insights.getDimensionLabel(key, insightName)}</Text>: {factorDimensionToValue[key]}
        </Box>
      ));

      return (
        <>
          We detected a{' '}
          <Text fontWeight="bold">
            {Math.abs((valueChange * 100).toFixed(1))}% {direction === 'drop' ? ' decrease ' : ' increase '}
          </Text>{' '}
          in bandwidth on{' '}
          <InsightDimensionToLink model={insight} dimension="snmp_id" value={snmp_id}>
            {$insights.lookupDisplayName('snmp_id', `${device_id}:${snmp_id}`)}
          </InsightDimensionToLink>{' '}
          and found the causes were {explaingFactors}
        </>
      );
    }
  };
}

const INSIGHT_TYPE_OVERRIDES = {
  'factors.interface.utilization.spike': getFactorsInterfaceUtilization('spike'),
  'factors.interface.utilization.drop': getFactorsInterfaceUtilization('drop'),

  'core.networkHealth.deviceTrafficIncrease': {
    description: ({ alerting, endTime }) => {
      const { baseline, metricToValue, dimensionToKeyDetail } = alerting;
      const deviceName = get(dimensionToKeyDetail, 'i_device_id.device.name');
      const siteId = get(dimensionToKeyDetail, 'i_device_site_name.site.id');
      const siteName = get(dimensionToKeyDetail, 'i_device_site_name.site.name');
      const increase = Math.round(((metricToValue.bits - baseline) / baseline) * 100);

      return (
        <>
          <DeviceLink name={deviceName} />{' '}
          {siteName && (
            <>
              in site <SiteLink siteId={siteId}>{siteName}</SiteLink>
            </>
          )}{' '}
          {endTime ? 'received' : 'is receiving'}{' '}
          {Number.isFinite(increase) ? <Text fontWeight="bold">{increase}% more</Text> : 'more'} traffic than usual at
          this time of day.
        </>
      );
    }
  },

  'core.networkHealth.deviceTrafficDrop': {
    description: ({ alerting, endTime }) => {
      const { baseline, metricToValue, dimensionToKeyDetail } = alerting;
      const deviceName = get(dimensionToKeyDetail, 'i_device_id.device.name');
      const siteId = get(dimensionToKeyDetail, 'i_device_site_name.site.id');
      const siteName = get(dimensionToKeyDetail, 'i_device_site_name.site.name');
      const decrease = Math.round(((baseline - metricToValue.bits) / baseline) * 100);

      return (
        <>
          <DeviceLink name={deviceName} />{' '}
          {siteName && (
            <>
              in site <SiteLink siteId={siteId}>{siteName}</SiteLink>
            </>
          )}{' '}
          {endTime ? 'received' : 'is receiving'} <Text fontWeight="bold">{decrease}% less</Text> traffic than usual at
          this time of day.
        </>
      );
    },
    isValid: ({ alerting }) => alerting && alerting.baseline > 0
  },

  'core.networkHealth.deviceLoopbackUnusualProtocol': {
    description: ({ alerting, endTime }) => {
      const { dimensionToKeyPart, dimensionToKeyDetail } = alerting;
      const { Proto, Port_dst } = dimensionToKeyPart;
      const protocolName = $insights.lookupDisplayName('Proto', Proto);
      const deviceName = get(dimensionToKeyDetail, 'i_device_id.device.name');

      return (
        <>
          An unusual protocol <Text fontWeight="bold">{protocolName}</Text> {endTime ? 'was' : 'is'} communicating on{' '}
          <DeviceLink name={deviceName} /> loopback interface, <Text fontWeight="bold">port {Port_dst}</Text>. This
          protocol is not in the top protocols we track.
        </>
      );
    }
  },

  'core.trafficAnalytics.transitLinkInbound': {
    description: ({ alerting, endTime }) => {
      const { dimensionToKeyDetail, metricToValue } = alerting;
      const asNumber = get(dimensionToKeyDetail, 'src_nexthop_asn.asn.asn', 0);
      const asDescription = get(dimensionToKeyDetail, 'src_nexthop_asn.asn.description');
      const { snmpID, snmpDescription } = get(dimensionToKeyDetail, 'InterfaceID_src.interface', {});
      const device_name = get(dimensionToKeyDetail, 'i_device_id.device.name');
      const capacity = (get(dimensionToKeyDetail, 'InterfaceID_src.interface.snmpSpeedMbps') || 0) * 1000000;
      const capacityInGbps = Math.round(capacity / 1000000000);
      const utilization = Math.round((metricToValue.bits / capacity) * 100);

      return (
        <>
          The peering link to{' '}
          <AsnLink asn={asNumber} nextHop>
            AS {asNumber} ({asDescription})
          </AsnLink>{' '}
          (Interface:{' '}
          <InterfaceLink snmp_id={snmpID} interface_description={snmpDescription} device_name={device_name} /> on{' '}
          <DeviceLink name={device_name} />) {endTime ? 'was' : 'is'} reaching a critical threshold at{' '}
          <Text fontWeight="bold">{utilization}%</Text> of its total capacity ({capacityInGbps}Gbps).
        </>
      );
    }
  },

  'core.trafficAnalytics.peeringLinkOutbound': {
    description: ({ alerting, endTime }) => {
      const { dimensionToKeyDetail, metricToValue } = alerting;
      const asNumber = get(dimensionToKeyDetail, 'dst_nexthop_asn.asn.asn', 0);
      const asDescription = get(dimensionToKeyDetail, 'dst_nexthop_asn.asn.description');
      const { snmpID, snmpDescription } = get(dimensionToKeyDetail, 'InterfaceID_dst.interface', {});
      const device_name = get(dimensionToKeyDetail, 'i_device_id.device.name');
      const capacity = (get(dimensionToKeyDetail, 'InterfaceID_dst.interface.snmpSpeedMbps') || 0) * 1000000;
      const capacityInGbps = Math.round(capacity / 1000000000);
      const utilization = Math.round((metricToValue.bits / capacity) * 100);

      return (
        <>
          The peering link to{' '}
          <AsnLink asn={asNumber} nextHop>
            AS {asNumber} ({asDescription})
          </AsnLink>{' '}
          (Interface:{' '}
          <InterfaceLink snmp_id={snmpID} interface_description={snmpDescription} device_name={device_name} /> on{' '}
          <DeviceLink name={device_name} />) {endTime ? 'was' : 'is'} reaching a critical threshold at{' '}
          <Text fontWeight="bold">{utilization}%</Text> of its total capacity ({capacityInGbps}Gbps).
        </>
      );
    }
  },

  'core.trafficAnalytics.peeringLinkInbound': {
    description: ({ alerting, endTime }) => {
      const { dimensionToKeyDetail, metricToValue } = alerting;
      const asNumber = get(dimensionToKeyDetail, 'src_nexthop_asn.asn.asn', 0);
      const asDescription = get(dimensionToKeyDetail, 'src_nexthop_asn.asn.description');
      const { snmpID, snmpDescription } = get(dimensionToKeyDetail, 'InterfaceID_src.interface', {});
      const device_name = get(dimensionToKeyDetail, 'i_device_id.device.name');
      const capacity = (get(dimensionToKeyDetail, 'InterfaceID_src.interface.snmpSpeedMbps') || 0) * 1000000;
      const capacityInGbps = Math.round(capacity / 1000000000);
      const utilization = Math.round((metricToValue.bits / capacity) * 100);

      return (
        <>
          The transit link to{' '}
          <AsnLink asn={asNumber} nextHop>
            AS {asNumber} ({asDescription})
          </AsnLink>{' '}
          (Interface:{' '}
          <InterfaceLink snmp_id={snmpID} interface_description={snmpDescription} device_name={device_name} /> on{' '}
          <DeviceLink name={device_name} />) {endTime ? 'was' : 'is'} reaching a critical threshold at{' '}
          <Text fontWeight="bold">{utilization}%</Text> of its total capacity ({capacityInGbps}Gbps).
        </>
      );
    }
  },

  'core.trafficAnalytics.transitLinkOutbound': {
    description: ({ alerting, endTime }) => {
      const { dimensionToKeyDetail, metricToValue } = alerting;
      const asNumber = get(dimensionToKeyDetail, 'dst_nexthop_asn.asn.asn', 0);
      const asDescription = get(dimensionToKeyDetail, 'dst_nexthop_asn.asn.description');
      const { snmpID, snmpDescription } = get(dimensionToKeyDetail, 'InterfaceID_dst.interface', {});
      const device_name = get(dimensionToKeyDetail, 'i_device_id.device.name');
      const capacity = (get(dimensionToKeyDetail, 'InterfaceID_dst.interface.snmpSpeedMbps') || 0) * 1000000;
      const capacityInGbps = Math.round(capacity / 1000000000);
      const utilization = Math.round((metricToValue.bits / capacity) * 100);

      return (
        <>
          The transit link to{' '}
          <AsnLink asn={asNumber} nextHop>
            AS {asNumber} ({asDescription})
          </AsnLink>{' '}
          (Interface:{' '}
          <InterfaceLink snmp_id={snmpID} interface_description={snmpDescription} device_name={device_name} /> on{' '}
          <DeviceLink name={device_name} />) {endTime ? 'was' : 'is'} reaching a critical threshold at{' '}
          <Text fontWeight="bold">{utilization}%</Text> of its total capacity ({capacityInGbps}Gbps).
        </>
      );
    }
  },

  'core.trafficAnalytics.backboneLinkInbound': {
    description: ({ alerting, endTime }) => {
      const { dimensionToKeyDetail, metricToValue } = alerting;
      const { snmpID, snmpDescription } = get(dimensionToKeyDetail, 'InterfaceID_src.interface', {});
      const device_name = get(dimensionToKeyDetail, 'i_device_id.device.name');
      const capacity = (get(dimensionToKeyDetail, 'InterfaceID_src.interface.snmpSpeedMbps') || 0) * 1000000;
      const capacityInGbps = Math.round(capacity / 1000000000);
      const utilization = Math.round((metricToValue.bits / capacity) * 100);

      return (
        <>
          The backbone link (Interface:{' '}
          <InterfaceLink snmp_id={snmpID} interface_description={snmpDescription} device_name={device_name} /> on{' '}
          <DeviceLink name={device_name} />) {endTime ? 'was' : 'is'} reaching a critical threshold at{' '}
          <Text fontWeight="bold">{utilization}%</Text> of its total capacity ({capacityInGbps}Gbps).
        </>
      );
    }
  },

  'core.trafficAnalytics.backboneLinkOutbound': {
    description: ({ alerting, endTime }) => {
      const { dimensionToKeyDetail, metricToValue } = alerting;
      const { snmpID, snmpDescription } = get(dimensionToKeyDetail, 'InterfaceID_dst.interface', {});
      const device_name = get(dimensionToKeyDetail, 'i_device_id.device.name');
      const capacity = (get(dimensionToKeyDetail, 'InterfaceID_dst.interface.snmpSpeedMbps') || 0) * 1000000;
      const capacityInGbps = Math.round(capacity / 1000000000);
      const utilization = Math.round((metricToValue.bits / capacity) * 100);

      return (
        <>
          The backbone link (Interface:{' '}
          <InterfaceLink snmp_id={snmpID} interface_description={snmpDescription} device_name={device_name} /> on{' '}
          <DeviceLink name={device_name} />) {endTime ? 'was' : 'is'} reaching a critical threshold at{' '}
          <Text fontWeight="bold">{utilization}%</Text> of its total capacity ({capacityInGbps}Gbps).
        </>
      );
    }
  },

  'core.trafficTrends.topASNs': {
    description: ({ dimensionToValue, metricToValue }) => {
      const asNumber = get(dimensionToValue, 'dst_as') || 0;
      const asDescription = $insights.lookupDisplayName('dst_as', asNumber);
      const bits = formatBytesGreek(metricToValue.bytes, 'B');

      return (
        <>
          <AsnLink asn={asNumber}>{asDescription}</AsnLink> was a top destination on your network yesterday. Your
          network sent a combined total of <Text fontWeight="bold">{bits}</Text> to this network.
        </>
      );
    }
  },

  'core.trafficTrends.countryShiftSrc': {
    description: ({ alerting, endTime }) => {
      const Geography_src = get(alerting, 'dimensionToKeyPart.Geography_src');
      const geographyName = $insights.lookupDisplayName('Geography_src', Geography_src);

      return (
        <>
          There {endTime ? 'was' : 'is'} a new entry into your top-5 source countries:{' '}
          <Text fontWeight="bold">{geographyName}</Text>
        </>
      );
    }
  },

  'core.trafficTrends.countryShiftDst': {
    description: ({ alerting, endTime }) => {
      const Geography_dst = get(alerting, 'dimensionToKeyPart.Geography_dst');
      const geographyName = $insights.lookupDisplayName('Geography_dst', Geography_dst);

      return (
        <>
          There {endTime ? 'was' : 'is'} a new entry into your top-5 destination countries:{' '}
          <Text fontWeight="bold">{geographyName}</Text>
        </>
      );
    }
  },

  'defense.threatActivity.deviceUnusualIPMgmtPort': {
    description: ({ alerting, endTime }) => {
      const { snmpID, snmpDescription } = get(alerting, 'dimensionToKeyDetail.InterfaceID_dst.interface', {});
      const deviceName = get(alerting, 'dimensionToKeyDetail.i_device_id.device.name');
      const Port_dst = get(alerting, 'dimensionToKeyPart.Port_dst');

      return (
        <>
          There {endTime ? 'was' : 'is'} an unusual IP attempting to connect to{' '}
          <InterfaceLink snmp_id={snmpID} interface_description={snmpDescription} device_name={deviceName} /> on{' '}
          <DeviceLink name={deviceName} /> over tcp port <Text fontWeight="bold">{Port_dst}</Text>.
        </>
      );
    }
  },

  'defense.threatActivity.internalToInternalDstPorts': {
    description: ({ alerting, metricToValue, endTime }) => {
      const { dimensionToKeyPart } = alerting;
      const { IP_src, IP_dst } = dimensionToKeyPart;

      return (
        <>
          The Source/Dest IP pair <IpLink ip_address={IP_src} /> / <IpLink ip_address={IP_dst} />{' '}
          {endTime ? 'was' : 'is'} sending abnormally high traffic to port{' '}
          <Text fontWeight="bold">{metricToValue.unique_dst_port}</Text>.
        </>
      );
    }
  },

  'defense.threatActivity.internalToDstIPs': {
    description: ({ alerting, metricToValue }) => {
      const { dimensionToKeyPart, baseline } = alerting;
      const { IP_src } = dimensionToKeyPart;

      return (
        <>
          Your internal IP <IpLink ip_address={IP_src} /> has connected to{' '}
          <Text fontWeight="bold">{metricToValue.unique_dst_ip}</Text> IP addresses in the last hour. Normally it
          connects to fewer than <Text fontWeight="bold">{baseline}</Text>.
        </>
      );
    }
  },

  'defense.threatActivity.internalLateral': {
    description: ({ alerting, metricToValue, endTime }) => {
      const { dimensionToKeyPart, baseline } = alerting;
      const baselineBits = formatBytesGreek(baseline, 'bps');
      const bits = formatBytesGreek(metricToValue.bits, 'bps');
      const { IP_src } = dimensionToKeyPart;

      return (
        <>
          Your host <IpLink ip_address={IP_src} /> normally sends less than{' '}
          <Text fontWeight="bold">{baselineBits}</Text> of internal traffic, but {endTime ? 'was' : 'is'} sending{' '}
          <Text fontWeight="bold">
            {bits} to {metricToValue.unique_dst_ip}
          </Text>{' '}
          different internal IP addresses.
        </>
      );
    }
  },

  'interconnection.threatActivity.botnetsOutbound': {
    description: ({ dimensionToValue, metricToValue }) => {
      const { inet_src_addr } = dimensionToValue;
      const { dest_ips } = metricToValue;
      const bps = formatBytesGreek(metricToValue.bps, 'bits/s');

      return (
        <>
          Botnets from <strong>{inet_src_addr}</strong> are sending {bps} of traffic to {dest_ips} IP
          {dest_ips > 1 ? 's' : ''}.
        </>
      );
    }
  },

  'core.networkHealth.deviceNoOrLowFlow': {
    description: ({ alerting, metricToValue, endTime }) => {
      const { dimensionToKeyDetail } = alerting;
      const deviceName = get(dimensionToKeyDetail, 'i_device_id.device.name');
      const siteId = get(dimensionToKeyDetail, 'i_device_site_name.site.id');
      const siteName = get(dimensionToKeyDetail, 'i_device_site_name.site.name');

      return (
        <>
          Device <DeviceLink name={deviceName} />{' '}
          {siteName && (
            <>
              in site <SiteLink siteId={siteId}>{siteName}</SiteLink>
            </>
          )}{' '}
          {endTime ? 'was' : 'is'} not sending any flow. Typically we’d expect to see{' '}
          <Text fontWeight="bold">{(Math.round(metricToValue.fps * 100) / 100).toFixed(2)} s flows per second</Text> at
          this time.
        </>
      );
    }
  },

  'core.networkHealth.tcpResetsSrcTotal': {
    description: ({ alerting, metricToValue, endTime }) => {
      const { baseline } = alerting;
      const baselinePps = formatBytesGreek(baseline, 'pps');
      const pps = formatBytesGreek(metricToValue.packets, 'pps');
      return (
        <>
          Your network {endTime ? 'saw' : 'is seeing'} <Text fontWeight="bold">{pps}</Text> TCP RST packets, while it
          typically sees <Text fontWeight="bold">{baselinePps}</Text>.
        </>
      );
    }
  },

  'interconnection.peeringAnalytics.pniSrcAS': {
    description: ({ dimensionToValue, metricToValue }) => {
      const asNumber = get(dimensionToValue, 'src_as') || 0;
      const asDescription = $insights.lookupDisplayName('src_as', asNumber);
      const totalBytes = formatBytesGreek(metricToValue.bytes, 'B');

      return (
        <>
          Your network received more than {totalBytes} of data from <AsnLink asn={asNumber}>{asDescription}</AsnLink>{' '}
          last month using your paid transit connections.
        </>
      );
    }
  },

  'interconnection.peeringAnalytics.pniDstAS': {
    description: ({ dimensionToValue, metricToValue }) => {
      const asNumber = get(dimensionToValue, 'dst_as') || 0;
      const asDescription = $insights.lookupDisplayName('dst_as', asNumber);
      const totalBytes = formatBytesGreek(metricToValue.bytes, 'B');

      return (
        <>
          Your network sent more than <Text fontWeight="bold">{totalBytes}</Text> of data to{' '}
          <AsnLink asn={asNumber}>{asDescription}</AsnLink> last month using your paid transit connections.
        </>
      );
    }
  },

  'interconnection.peeringAnalytics.lowInterfaceLastMonth': {
    description: ({ dimensionToValue, metricToValue }) => {
      const { device_id, snmp_id } = dimensionToValue;
      const snmpDescription = get($insights.lookupInterface(`${device_id}:${snmp_id}`), 'snmpDescription', '-');
      const deviceName = get($insights.lookupDevice(device_id), 'name', '-');
      const avgBps = formatBytesGreek(metricToValue.average_bps, 'bits/s');

      return (
        <>
          <InterfaceLink snmp_id={snmp_id} interface_description={snmpDescription} device_name={deviceName} /> on{' '}
          <DeviceLink name={deviceName} /> had low traffic last month with an average of <strong>{avgBps}</strong>.
        </>
      );
    }
  },

  'cdn.embedded_cache_programs': {
    description: ({ dimensionToValue, metricToValue }) => {
      const { cdn, url } = dimensionToValue;
      const bps = formatBytesGreek(metricToValue.peak_bps, 'bits/s');
      return (
        <>
          You have a peak of {bps} of traffic from <CdnLink cdn={cdn} />, but you don’t seem to have any of their
          caches. You may be able to participate in their{' '}
          <Link to={url} blank>
            embedded cache program
          </Link>
          .
        </>
      );
    }
  },

  'interconnection.costs.ixPeeringBytesYesterday': {
    description: ({ metricToValue }) => {
      const totalBytes = formatBytesGreek(metricToValue.bytes, 'B');
      return <>You sent and received {totalBytes} via IX and peering links yesterday.</>;
    }
  },

  'interconnection.costs.ixPeeringBpsDayOverDay': {
    description: ({ metricToValue }) => {
      const percent = Math.abs(Math.round(metricToValue.bps_day_over_day * 100));
      const diff = metricToValue.bps_this_day - metricToValue.bps_last_day;
      const diffDisplay = (diff >= 0 ? '+' : '-') + formatBytesGreek(Math.abs(diff), 'bits/s');
      return (
        <>
          You sent and received{' '}
          <Text fontWeight="bold">
            {percent}% {diff >= 0 ? 'more' : 'less'} IX and peering link traffic ({diffDisplay})
          </Text>{' '}
          today compared to yesterday.
        </>
      );
    }
  },

  'interconnection.costs.bpsDayOverDay': {
    hideTimestamp: true,
    description: ({ metricToValue }) => {
      const percent = Math.abs(Math.round(metricToValue.bps_day_over_day * 100));
      const diff = metricToValue.bps_this_day - metricToValue.bps_last_day;
      const diffDisplay = (diff >= 0 ? '+' : '-') + formatBytesGreek(Math.abs(diff), 'bits/s');
      return (
        <>
          You sent and received{' '}
          <Text fontWeight="bold">
            {percent}% {diff >= 0 ? 'more' : 'less'} traffic ({diffDisplay})
          </Text>{' '}
          today compared to yesterday.
        </>
      );
    }
  },

  'interconnection.costs.ipv6BpsWeekOverWeek': {
    description: ({ metricToValue }) => {
      const percent = Math.abs(Math.round(metricToValue.bps_week_over_week * 100));
      const diff = metricToValue.bps_this_week - metricToValue.bps_last_week;
      const diffDisplay = (diff >= 0 ? '+' : '-') + formatBytesGreek(Math.abs(diff), 'bits/s');
      return (
        <>
          You sent and received{' '}
          <Text fontWeight="bold">
            {percent}% {diff >= 0 ? 'more' : 'less'} IPv6 traffic ({diffDisplay})
          </Text>{' '}
          this week compared to last week.
        </>
      );
    }
  },

  'interconnection.costs.ixPeeringBytesToday': {
    description: ({ metricToValue }) => {
      const totalBytes = formatBytesGreek(metricToValue.bytes, 'B');
      return <>You sent and received {totalBytes} via IX and peering links today.</>;
    }
  },

  'core.networkHealth.flowsThisWeek': {
    description: ({ metricToValue }) => {
      const { displayFull } = greekIt(metricToValue.flows, { fix: 2, suffix: 'flows' });
      return (
        <>
          Your network sent <Text fontWeight="bold">{displayFull}</Text> to Kentik this week.
        </>
      );
    }
  },

  'core.networkHealth.flowsThisWeekOverLastWeek': {
    visualization: FlowsThisWeekOverLastWeek,
    description: ({ metricToValue }) => {
      const percent = Math.abs(Math.round(metricToValue.flows_week_over_week * 100));
      const diff = metricToValue.flows_this_week - metricToValue.flows_last_week;
      const diffDisplay = (diff >= 0 ? '+' : '-') + formatNumber(Math.abs(diff), { fix: 0 }).display;
      return (
        <>
          Your network sent{' '}
          <Text fontWeight="bold">
            {percent}% {diff >= 0 ? 'more' : 'less'} flows ({diffDisplay})
          </Text>{' '}
          this week compared to last week.
        </>
      );
    }
  },

  'core.networkHealth.flowsLastWeek': {
    label: 'Flows Last Week',
    family: 'Network Health',
    icon: <Icon icon={FiHeart} iconSize={INSIGHT_ICON_SIZE} color="danger" />,
    description: ({ metricToValue }) => {
      const { displayFull } = greekIt(metricToValue.flows, { fix: 2, suffix: 'flows' });
      return (
        <>
          Your network sent <Text fontWeight="bold">{displayFull}</Text> to Kentik last week.
        </>
      );
    }
  },

  'core.trafficAnalytics.appNewTop5': {
    description: ({ dimensionToValue, metricToValue, endTime }) => {
      const application = get(dimensionToValue, 'application');
      const bits = formatBytesGreek(metricToValue.bits, 'bps');
      return (
        <>
          There {endTime ? 'was' : 'is'} an unusual entrant to your top five applications:{' '}
          <ApplicationLink application={application}>{application}</ApplicationLink> ({' '}
          <Text fontWeight="bold">{bits}</Text> ).
        </>
      );
    }
  },

  'core.trafficAnalytics.appHighInternal': {
    description: ({ dimensionToValue, metricToValue, endTime }) => {
      const application = get(dimensionToValue, 'application');
      const bits = formatBytesGreek(metricToValue.bits, 'bps');
      return (
        <>
          Your network {endTime ? 'sent' : 'is sending'} an unusually high ( <Text fontWeight="bold">{bits}</Text> )
          amount of internal <ApplicationLink application={application}>{application}</ApplicationLink> traffic.
        </>
      );
    }
  },

  'core.trafficAnalytics.appHighInbound': {
    description: ({ dimensionToValue, metricToValue, endTime }) => {
      const application = get(dimensionToValue, 'application') || 'unknown';
      const bits = formatBytesGreek(metricToValue.bits, 'bps');
      return (
        <>
          Your network {endTime ? 'received' : 'is receiving'} an unusually high ( <Text fontWeight="bold">{bits}</Text>{' '}
          ) amount of outbound <ApplicationLink application={application}>{application}</ApplicationLink> traffic.
        </>
      );
    }
  },

  'operate.capacity.capacity': {
    detailsLink: ({ dimensionToValue: { device_id, snmp_id } }) => {
      const snmpDescription = get($insights.lookupInterface(`${device_id}:${snmp_id}`), 'snmpDescription', '-');
      const deviceName = get($insights.lookupDevice(device_id), 'name', '-');

      return (
        <InterfaceLink as="button" snmp_id={snmp_id} interface_description={snmpDescription} device_name={deviceName}>
          View Details
        </InterfaceLink>
      );
    },
    description: (insight) => {
      const { dimensionToValue, severity } = insight;
      const { device_id, snmp_id } = dimensionToValue;
      const utilization = getCapacityMetric(insight);
      const value = Math.round(utilization.value * 100);
      const { direction } = utilization;
      const snmpDescription = get($insights.lookupInterface(`${device_id}:${snmp_id}`), 'snmpDescription', '-');
      const deviceName = get($insights.lookupDevice(device_id), 'name', '-');
      const severityColor = INSIGHT_SEVERITY_COLORS[severity];
      const severityDisplay = INSIGHT_SEVERITY_LABELS[severity];

      return (
        <>
          <InterfaceLink snmp_id={snmp_id} interface_description={snmpDescription} device_name={deviceName} /> on{' '}
          <DeviceLink name={deviceName} /> is at <strong>{value}%</strong> (
          <Text color={severityColor}>{severityDisplay}</Text>) of {direction}bound capacity.
        </>
      );
    }
  },

  'operate.capacity.runout': {
    detailsLink: ({ dimensionToValue: { device_id, snmp_id } }) => {
      const snmpDescription = get($insights.lookupInterface(`${device_id}:${snmp_id}`), 'snmpDescription', '-');
      const deviceName = get($insights.lookupDevice(device_id), 'name', '-');

      return (
        <InterfaceLink as="button" snmp_id={snmp_id} interface_description={snmpDescription} device_name={deviceName}>
          View Details
        </InterfaceLink>
      );
    },
    description: (insight) => {
      const { dimensionToValue } = insight;
      const { device_id, snmp_id } = dimensionToValue;
      const runout = getCapacityMetric(insight);
      const value = Math.ceil(runout.value);
      const unit = runout.unit + (value !== 1 ? 's' : '');
      const { direction } = runout;
      const snmpDescription = get($insights.lookupInterface(`${device_id}:${snmp_id}`), 'snmpDescription', '-');
      const deviceName = get($insights.lookupDevice(device_id), 'name', '-');

      return (
        <>
          <InterfaceLink snmp_id={snmp_id} interface_description={snmpDescription} device_name={deviceName} /> on{' '}
          <DeviceLink name={deviceName} /> is within{' '}
          <strong>
            {value} {unit}
          </strong>{' '}
          of running out of {direction}bound capacity.
        </>
      );
    },
    isValid: (insight) => getCapacityMetric(insight)
  },

  'core.trafficAnalytics.appHighOutbound': {
    description: ({ dimensionToValue, metricToValue, endTime }) => {
      const application = get(dimensionToValue, 'application') || 'unknown';
      const bits = formatBytesGreek(metricToValue.bits, 'bps');
      return (
        <>
          Your network {endTime ? 'sent' : 'is sending'} an unusually high ( <Text fontWeight="bold">{bits}</Text> )
          amount of outbound {application} traffic.
        </>
      );
    }
  },

  'comparison.application.dst': {
    description: getComparisonDescriptionFn('application', 'outbound applications')
  },

  'comparison.application.src': {
    description: getComparisonDescriptionFn('application', 'inbound applications')
  },

  'comparison.asns.dst': {
    description: getComparisonDescriptionFn('dst_as', 'outbound ASNs')
  },

  'comparison.asns.src': {
    description: getComparisonDescriptionFn('src_as', 'inbound ASNs')
  },

  'comparison.city.dst': {
    description: getComparisonDescriptionFn('dst_geo_city', 'outbound cities')
  },

  'comparison.city.src': {
    description: getComparisonDescriptionFn('src_geo_city', 'inbound cities')
  },

  'comparison.geo.dst': {
    description: getComparisonDescriptionFn('dst_geo', 'outbound countries')
  },

  'comparison.geo.src': {
    description: getComparisonDescriptionFn('src_geo', 'inbound countries')
  },

  'comparison.protocol.dst': {
    description: getComparisonDescriptionFn('protocol', 'outbound protocols')
  },

  'comparison.protocol.src': {
    description: getComparisonDescriptionFn('protocol', 'inbound protocols')
  },

  'comparison.region.dst': {
    description: getComparisonDescriptionFn('dst_geo_region', 'outbound regions')
  },

  'comparison.region.src': {
    description: getComparisonDescriptionFn('src_geo_region', 'inbound regions')
  },

  'operate.site.bpsOutboundWeekOverWeek': {
    description: ({ dimensionToValue: { site }, metricToValue }) => {
      const { bps_last_week, bps_this_week } = metricToValue;
      const { displayFull: lastWeekBps } = greekIt(bps_last_week, { fix: 4, suffix: 'bits/s' });
      const { displayFull: thisWeekBps } = greekIt(bps_this_week, { fix: 4, suffix: 'bits/s' });
      const change = bps_this_week > bps_last_week ? 'increase' : 'decrease';
      const percentage = getPercentage(bps_last_week, bps_this_week);

      return (
        <>
          <Text fontWeight="bold">{site}</Text> had <Text fontWeight="bold">{thisWeekBps}</Text> outbound traffic this
          week, a {Math.abs((percentage * 100).toFixed(1))}% {change} compared to last week{' '}
          <Text color="muted">({lastWeekBps})</Text>.
        </>
      );
    }
  },

  'operate.site.bpsInboundWeekOverWeek': {
    description: ({ dimensionToValue: { site }, metricToValue }) => {
      const { bps_last_week, bps_this_week } = metricToValue;
      const { displayFull: lastWeekBps } = greekIt(bps_last_week, { fix: 4, suffix: 'bits/s' });
      const { displayFull: thisWeekBps } = greekIt(bps_this_week, { fix: 4, suffix: 'bits/s' });
      const change = bps_this_week > bps_last_week ? 'increase' : 'decrease';
      const percentage = getPercentage(bps_last_week, bps_this_week);

      return (
        <>
          <Text fontWeight="bold">{site}</Text> had <Text fontWeight="bold">{thisWeekBps}</Text> inbound traffic this
          week, a {Math.abs((percentage * 100).toFixed(1))}% {change} compared to last week{' '}
          <Text color="muted">({lastWeekBps})</Text>.
        </>
      );
    }
  },

  'test.synthetics': {
    description: ({ dimensionToValue, metricToValue }) => {
      const test_target = get(dimensionToValue, 'test_target');
      const agent_alias = get(dimensionToValue, 'agent_alias');
      const problem_packet_loss_severity = get(metricToValue, 'problem_packet_loss_severity');

      const last_average_packet_loss_percent = get(metricToValue, 'last_average_packet_loss_percent');
      const new_average_packet_loss_percent = get(metricToValue, 'new_average_packet_loss_percent');

      const last_average_latency_without_zero = get(metricToValue, 'last_average_latency_without_zero');
      const new_average_latency_without_zero = get(metricToValue, 'new_average_latency_without_zero');

      // packet loss performance problem
      if (problem_packet_loss_severity > 0) {
        const percentage = getPercentage(new_average_packet_loss_percent, last_average_packet_loss_percent);

        return (
          <>
            <Text fontWeight="bold">{agent_alias}</Text> is experiencing a {Math.abs((percentage * 100).toFixed(1))}%
            increase in Packet Loss{test_target ? ' to ' : '.'}
            {test_target && <Text fontWeight="bold">{test_target}.</Text>}
          </>
        );
      }

      // otherwise latency performance problem
      const percentage = getPercentage(last_average_latency_without_zero, new_average_latency_without_zero);

      return (
        <>
          <Text fontWeight="bold">{agent_alias}</Text> is experiencing a {Math.abs((percentage * 100).toFixed(1))}%
          increase in Latency to <Text fontWeight="bold">{test_target}</Text>. (
          {zeroToText(last_average_latency_without_zero, 3)}ms to {zeroToText(new_average_latency_without_zero, 3)}ms)
        </>
      );
    }
  },

  // returns an insight definition for `custom.insight` types, null otherwise
  custom(insight) {
    const { insightName = '', alerting, severity } = insight.get ? insight.get() : insight;

    if (!alerting || !insightName.startsWith('custom.insight')) {
      return null;
    }

    const { dimensionToKeyDetail, dimensionToKeyPart, policy = {} } = alerting;
    const dimension = policy.dimensions ? policy.dimensions[0] : {};
    const key = $alerting.getDimension(alerting, dimension);
    const linkProps = { dimensionToKeyDetail, dimensionToKeyPart, dimension };
    const icon = 'notifications';
    const insightType = 'custom';

    return {
      status: 'enabled',
      label: policy.name,
      family: 'Custom Insights',
      icon: <Icon icon={icon} iconSize={INSIGHT_ICON_SIZE} color={INSIGHT_SEVERITY_COLORS[severity]} />,
      description: () => (
        <>
          <Text as="div" mb="2px">
            A {insightType} insight has met a{' '}
            <Text color={INSIGHT_SEVERITY_COLORS[severity]}>{INSIGHT_SEVERITY_LABELS[severity].toLowerCase()}</Text>{' '}
            threshold:
          </Text>
          <Text as="div" ellipsis title={policy.name}>
            <strong>Policy Name:</strong> {policy.name}
          </Text>
          {key && (
            <Text as="div" ellipsis title={key.lookup}>
              <strong>{key.label}:</strong> <DimensionToLink {...linkProps}>{key.lookup}</DimensionToLink>
            </Text>
          )}
        </>
      )
    };
  }
};

export default INSIGHT_TYPE_OVERRIDES;
