import { computed } from 'mobx';
import moment from 'moment';

import Model from 'core/model/Model';
import { DEFAULT_DATETIME_FORMAT } from 'core/util/dateUtils';

class SilenceModel extends Model {
  get urlRoot() {
    return '/api/ui/alertingManager/alarms/silence';
  }

  @computed
  get dimensionToKeyPart() {
    return this.get('key.value', {});
  }

  get ruleId() {
    return this.get('ruleId');
  }

  get messages() {
    return {
      create: 'Alert silence successfully created. It will take effect in a few minutes.',
      update: 'Alert silence updated successfully',
      destroy: 'Alert silence removed successfully',
      duplicate: 'Alert silence duplicated successfully'
    };
  }

  get expired() {
    const expirationTime = this.get('endTime');
    return !expirationTime ? false : moment(expirationTime).isBefore(moment());
  }

  get expirationDetails() {
    const expirationTime = this.get('endTime');
    if (!expirationTime) {
      return { expirationDay: null, expired: false, duration: null };
    }
    const momentDate = moment(expirationTime);
    return {
      expirationDay: momentDate.format(DEFAULT_DATETIME_FORMAT),
      expired: this.expired,
      duration: moment.duration(momentDate.diff(moment())).humanize()
    };
  }

  get startTime() {
    const startTime = this.get('startTime');
    return moment(startTime).format(DEFAULT_DATETIME_FORMAT);
  }

  get startDetails() {
    const startTimeMoment = moment(this.get('startTime'));
    return {
      started: startTimeMoment.isSameOrBefore(moment()),
      futureStart: startTimeMoment.isAfter(moment()),
      diff: moment.duration(startTimeMoment.diff(moment())).humanize()
    };
  }

  get measurementDimension() {
    const dimensions = Object.keys(this.dimensionToKeyPart);
    return dimensions.find((dimension) => dimension.endsWith('km_measurement_name'));
  }

  get measurement() {
    return this.dimensionToKeyPart[this.measurementDimension];
  }

  // Unwrap data from 'silence' object
  deserialize = (data = {}) => {
    const serializedData = data.silence || data;
    const { cdate, edate, end_time, id, key, rule_id, start_time, user_id } = serializedData;

    // API takes camelCase but returns snake_case,
    // so we convert snake => camel on deserialize,
    // and only use camelCase in the UI and model.

    const deserializedData = {
      id,
      key,
      ruleId: rule_id,
      startTime: start_time,
      endTime: end_time,
      userId: user_id,
      cdate,
      edate
    };

    return super.deserialize(deserializedData);
  };

  // Wrap data in 'silence' object before saving
  serialize = (data = {}) => {
    const dataToSerialize = { silence: data.silence || data };
    return super.serialize(dataToSerialize);
  };
}

export default SilenceModel;
