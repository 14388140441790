import { action, computed, observable, toJS } from 'mobx';
import { get, groupBy, isEqual } from 'lodash';
import Collection from 'core/model/Collection';
import { timeIntervalsIntersect } from 'core/util/utils';
import InsightModel from './InsightModel';
import { validateInsight } from './insightUtils';

export const ignoreInsightNamePrefixes = ['test', 'operate.capacity', 'custom.insight.V4 Synth'];

class InsightsCollection extends Collection {
  @observable.ref
  serverFilter = { lookback: 24 };

  @observable.ref
  totals = {};

  constructor({ $insights, data = [], fetchOptions, ...otherOptions }) {
    super(data, { threeWaySort: true, ...otherOptions });

    this.$insights = $insights;

    this.fetchOptions = {
      limit: 1000,
      isInteresting: 'interestingOnly',
      ignoreInsightNamePrefixes,
      groupLimit: -1,
      ...fetchOptions
    };

    Object.assign(this, otherOptions || {});
    this.resetState();
  }

  get url() {
    return '/api/ui/insights';
  }

  get fetchMethod() {
    return 'post';
  }

  get queuedFetchKey() {
    return this.id;
  }

  get defaultSortState() {
    return { field: 'creationTime', direction: 'desc' };
  }

  get secondarySort() {
    return { field: 'startTime', direction: 'desc' };
  }

  get model() {
    return InsightModel;
  }

  get length() {
    return this.size;
  }

  get filterFieldWhitelist() {
    return new Set(['id', 'family', 'label', 'plainDescription']);
  }

  get presetFilters() {
    return this.includeDisabled
      ? []
      : [
          {
            label: 'Status',
            fn: (model) => model.definition && model.definition.status === 'enabled',
            default: true
          }
        ];
  }

  /**
   * @returns {{ family: String, models: InsightModel[] }[]} sorted by number of models, decending
   */
  @computed
  get families() {
    const familyGroups = groupBy(this.get(), (model) => model.family) || [];
    return Object.entries(familyGroups)
      .map(([family, models]) => ({ family, models }))
      .sort((a, b) => b.models.length - a.models.length);
  }

  get startTime() {
    return this.lastUpdated - this.serverFilter.lookback * 60 * 60 * 1000;
  }

  get endTime() {
    return this.lastUpdated;
  }

  @action
  setServerFilter(filter) {
    if (!isEqual(filter, toJS(this.serverFilter))) {
      // this.serverFilter = { ...filter, highlighted: filter.flaggedByCompany };
      this.serverFilter = { ...filter };
      return this.queuedFetch();
    }

    return this.fetch();
  }

  getServerFilter() {
    const {
      showHistorical,
      lookback,
      dimensionValues = [],
      insightType,
      states = [],
      ...filter
    } = toJS(this.serverFilter);

    Object.entries(filter).forEach(([field, value]) => {
      if (!value || value.length === 0) {
        delete filter[field];
      }
    });

    if (showHistorical && lookback) {
      if (filter.alerting) {
        filter.alerting.creationTimeDuration = `${lookback}s`;
      } else {
        filter.creationTimeDuration = `${lookback}s`;
      }
    }

    const validStates = ['alarm', 'ackReq', 'clear'];
    const validatedStates = (states || []).filter((state) => validStates.includes(state));
    if (validatedStates.length > 0 && insightType !== 'kentik') {
      filter.states = validatedStates;
      filter.insightNamePrefixes = ['custom'];
    }

    if ((filter.states || []).length === 0 && (filter.insightNames || []).length === 0) {
      if (insightType === 'kentik') {
        filter.ignoreInsightNamePrefixes = [...ignoreInsightNamePrefixes, 'custom'];
      } else if (insightType === 'custom') {
        filter.insightNamePrefixes = ['custom'];
      }
    }

    if (dimensionValues.length > 0) {
      filter.dimensionToValue = dimensionValues.reduce(
        (map, { filterField, filterValue }) =>
          Object.assign(map, { [this.$insights.getKdeDimension(filterField)]: String(filterValue) }),
        {}
      );
    }

    if (filter.families) {
      filter.insightNames = (filter.insightNames || []).concat(
        Object.values(this.$insights.insightTypes)
          .filter((type) => filter.families.includes(type.family))
          .map((type) => type.insightName)
      );
    }

    // translate non-kde dimensions to kde
    if (filter.dimensions) {
      filter.dimensions = filter.dimensions.map((name) => this.$insights.getKdeDimension(name));
    }

    filter.pastOrPresent = showHistorical ? 'both' : 'presentOnly';

    return filter;
  }

  @action
  clearFilters() {
    this.serverFilter = {};
    super.clearFilters();
    this.queuedFetch();
  }

  @computed
  get hasFilter() {
    return (
      Object.keys(this.serverFilter).length > 0 ||
      !!this.filterState ||
      !!this.activePresetFilter ||
      this.discreteFilters.length > 0
    );
  }

  @action
  group(value) {
    this.groupBy = value
      ? {
          value,
          groupByFn: (model) => model[value] || model.get(value) || 'None'
        }
      : null;
  }

  @action
  setTimeFilter(min, max) {
    this.resetTimeFilter();
    this.setDiscreteFilters([
      ...this.discreteFilters,
      {
        type: 'time',
        fn: (model) => {
          const startTime = Date.parse(model.get('startTime'));
          const endTime = model.get('endTime') ? Date.parse(model.get('endTime')) : this.lastUpdated;
          return timeIntervalsIntersect([min, max], [startTime, endTime]);
        }
      }
    ]);
  }

  resetTimeFilter() {
    this.removeDiscreteFilter('time');
  }

  setLookback(lookback) {
    this.serverFilter.lookback = lookback;
    return this.queuedFetch();
  }

  async fetch(options = {}) {
    const { data = {} } = options;
    return super.fetch({
      ...options,
      data: {
        ...this.fetchOptions,
        ...this.getServerFilter(),
        ...data
      }
    });
  }

  async queuedFetch(options = {}) {
    const { data = {} } = options;
    this.lastFetched = Date.now();
    return super.queuedFetch({
      ...options,
      data: {
        ...this.fetchOptions,
        ...this.getServerFilter(),
        ...data
      }
    });
  }

  deserialize(rawData) {
    const data = Array.isArray(rawData) ? { insights: rawData } : rawData;
    const { policyIDToDetails = {}, thresholdIDToDetails = {}, totals = {}, count } = data;

    const insights = (data.insights || [])
      .map((insight) => ({
        ...insight,
        alerting: insight.alerting
          ? {
              ...insight.alerting,
              policy: get(policyIDToDetails, `${insight.alerting.policyID}.policy`),
              threshold: thresholdIDToDetails[insight.alerting.thresholdID]
            }
          : undefined
      }))
      .filter((insight) => validateInsight(insight));

    this.totalCount = count;

    this.$insights.mergeLookups(data);

    Object.entries(totals).forEach(([field, counts]) => {
      if (this.serverFilter[field] && this.totals[field]) {
        this.serverFilter[field].forEach((key) => {
          this.totals[field][key] = counts[key] || 0;
        });
      } else {
        this.totals[field] = counts;
      }
    });

    return super.deserialize(insights);
  }
}

export default InsightsCollection;
