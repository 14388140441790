import DeviceCollection from 'app/stores/device/DeviceCollection';

class NonCloudDeviceCollection extends DeviceCollection {
  fetch(options = {}) {
    const { query = {} } = options;
    const { limit } = query;
    return super.fetch({ query: { filterCloud: true, limit } });
  }
}

export default NonCloudDeviceCollection;
