import { get, without } from 'lodash';
import api from 'core/util/api';

import { getDeserializedDimensionToValue, validateInsight } from 'app/stores/insight/insightUtils';
import { ignoreInsightNamePrefixes } from 'app/stores/insight/InsightsCollection';

const defaultFetchInsightsParams = {
  ignoreInsightNamePrefixes,
  isInteresting: 'interestingOnly'
};

const defaultTimeFilterParams = {
  creationTimeDuration: '24h'
};

export function fetchInsights(params = {}, excludeInvalid = true) {
  const finalParams = {
    ...defaultFetchInsightsParams,
    ...params
  };

  // if no (creation/start/end) time filter is specified, apply a default
  if (Object.keys(finalParams).filter((key) => key.includes('Time')).length === 0) {
    Object.assign(finalParams, defaultTimeFilterParams);
  }

  return api.post('/api/ui/insights', { body: finalParams, showErrorToast: false }).then((res) => {
    const { policyIDToDetails = {}, thresholdIDToDetails = {}, insights = [] } = res;

    insights.forEach((insight) => {
      if (insight.alerting) {
        insight.alerting.policy = get(policyIDToDetails, `${insight.alerting.policyID}.policy`);
        insight.alerting.threshold = thresholdIDToDetails[insight.alerting.thresholdID];

        insight.dimensionToValue = getDeserializedDimensionToValue(insight.dimensionToValue);
        insight.alerting.dimensionToKeyPart = getDeserializedDimensionToValue(insight.alerting.dimensionToKeyPart);
      }
    });

    const validInsights = excludeInvalid ? insights.filter((insight) => validateInsight(insight)) : insights;

    return { res, insights: validInsights };
  });
}

export function fetchInsightHistory(insightName, options) {
  const params = {
    insightNames: [insightName],
    limit: 10000,
    groupLimit: -1,
    creationTimeDuration: '720h',
    pastOrPresent: 'both',
    ignoreInsightNamePrefixes: without(defaultFetchInsightsParams.ignoreInsightNamePrefixes, 'test'),
    ...options
  };

  return fetchInsights(params);
}

export function fetchDimensionHistory({ family, dimension, value }) {
  const params = {
    families: [family],
    dimensionToValue: { [dimension]: value },
    limit: 10000,
    groupLimit: -1,
    creationTimeDuration: '168h',
    pastOrPresent: 'both'
  };

  return fetchInsights(params);
}

export async function fetchDeviceInsights(deviceName) {
  if (!deviceName) {
    return fetchInsights({ relatedEntities: ['device'] });
  }

  const device = this.store.$devices.deviceSummariesByName[deviceName];

  return Promise.resolve().then(() => {
    if (device) {
      return fetchInsights({ relatedEntityToValue: { device: device.id } });
    }

    return [];
  });
}

export function fetchAsnInsights(asn) {
  if (!asn) {
    return fetchInsights({ relatedEntities: ['as'] });
  }

  return fetchInsights({ relatedEntityToValue: { as: `${asn}` } });
}

export function fetchIpInsights(ip) {
  if (!ip) {
    return fetchInsights({ relatedEntities: ['ip'] });
  }

  return fetchInsights({ relatedEntityToValue: { ip: `${ip}` } });
}

export function fetchNetworkBoundaryInsights(networkBoundary) {
  const params = networkBoundary
    ? { relatedEntityToValue: { network_boundary: `${networkBoundary}` } }
    : { relatedEntities: ['network_boundary'] };
  return fetchInsights({ ...params });
}

export function fetchConnectivityTypeInsights(connType) {
  let params;

  // aggregate view
  if (!connType) {
    return fetchInsights({
      relatedEntities: ['connectivity_type']
    });
  }

  if (connType === 'free_pni') {
    params = {
      insightNames: ['interconnection.costs.ixPeeringBytesToday', 'interconnection.costs.ixPeeringBytesYesterday']
    };
  } else if (connType === 'paid_pni') {
    params = {
      insightNames: [
        'interconnection.costs.ixPeeringBytesToday',
        'interconnection.costs.ixPeeringBytesYesterday',
        'interconnection.peeringAnalytics.pniDstAS',
        'interconnection.peeringAnalytics.pniSrcAS'
      ]
    };
  } else if (connType === 'ix') {
    params = {
      insightNames: [
        'interconnection.costs.ixPeeringBytesToday',
        'interconnection.costs.ixPeeringBytesYesterday',
        'interconnection.peeringAnalytics.lowInterfaceLastMonth'
      ]
    };
  }

  return fetchInsights({ ...params });
}

export function fetchInetFamilyInsights(inetFamily) {
  const params = inetFamily ? { DimensionToValue: { inet_family: inetFamily } } : { relatedEntities: ['inet_family'] };
  return fetchInsights({ ...params });
}

export function fetchAsPathInsights(aspath) {
  const params = aspath
    ? { relatedEntityToValues: { as: { values: aspath.split(/\s+/) } } }
    : { relatedEntities: ['as'] };
  return fetchInsights({ ...params });
}

export function fetchProtocolInsights(protocol) {
  const params = protocol ? { DimensionToValue: { Proto: `${protocol}` } } : { relatedEntities: ['protocol'] };
  return fetchInsights({ ...params });
}

export function fetchRoutePrefixInsights() {
  const params = { insightName: 'core.trafficTrends.topASNs' };
  return fetchInsights(params);
}

export function fetchServiceInsights(insightParams) {
  const params = insightParams
    ? { DimensionToValue: { Proto: insightParams.protocol, Port_dst: insightParams.port } }
    : { insightName: 'core.networkHealth.deviceLoopbackUnusualProtocol' };
  return fetchInsights({ ...params });
}

export function fetchSiteInsights(siteId) {
  const { $sites } = this.store;

  if (!siteId) {
    return fetchInsights({
      relatedEntities: ['site']
    });
  }

  return $sites.collection.fetch().then(() => {
    const site = $sites.collection.get(siteId);

    if (site) {
      return fetchInsights({ relatedEntityToValue: { site: site.get('title') } });
    }

    return [];
  });
}

export function fetchCountryInsights(country) {
  const params = country ? { relatedEntityToValue: { country } } : { relatedEntities: ['country'] };
  return fetchInsights({ ...params });
}

export function fetchRegionInsights(region) {
  const params = region ? { relatedEntityToValue: { region } } : { relatedEntities: ['region'] };
  return fetchInsights({ ...params });
}

export function fetchCityInsights(city) {
  const params = city ? { relatedEntityToValue: { city } } : { relatedEntities: ['city'] };
  return fetchInsights({ ...params });
}

export function fetchApplicationInsights(application) {
  const params = application ? { relatedEntityToValue: { application } } : { relatedEntities: ['application'] };
  return fetchInsights({ ...params });
}

export function fetchPacketSizeInsights() {
  const params = { insightName: 'core.networkHealth.tcpResetsSrcTotal' };
  return fetchInsights(params);
}

export function fetchTotalFlowsIngested() {
  return fetchInsights({ insightName: 'core.networkHealth.flowsDayOverDay' }).then((res) => {
    const {
      metricToValue: { flows_last_day, flows_this_day }
    } = res[0];
    return { today: flows_this_day, yesterday: flows_last_day };
  });
}

export async function fetchInterfaceInsights(deviceName, snmpId) {
  if (!deviceName) {
    return fetchInsights({ relatedEntities: ['interface'] });
  }

  const device = this.store.$devices.deviceSummariesByName[deviceName];

  if (!device) {
    return { res: [], insights: [] };
  }

  return fetchInsights({
    ignoreInsightNamePrefixes: without(defaultFetchInsightsParams.ignoreInsightNamePrefixes, 'operate.capacity'),
    relatedEntityToValue: { device: device.id, interface: snmpId }
  });
}

export function fetchCostInsights(options) {
  return fetchInsights({ insightName: 'test.cost', ...options }, false);
}

export function fetchPeeringInsights() {
  const params = {
    insightNames: [
      'interconnection.costs.ixPeeringBytesYesterday',
      'interconnection.costs.ixPeeringBytesToday',
      'interconnection.costs.ixPeeringBytesTodayOverYesterday',
      'interconnection.peeringAnalytics.pniSrcAS',
      'interconnection.peeringAnalytics.pniDstAS'
    ]
  };
  return fetchInsights(params);
}

export function fetchInsightsForSyntheticTest(test_id, agent_id, options = { creationTimeDuration: '24h' }) {
  const dimensionToValue = { test_id: `${test_id}` };
  if (agent_id) {
    dimensionToValue.agent_id = `${agent_id}`;
  }

  const params = {
    insightNames: ['test.synthetics', 'test.synthetics.latency.hopcount'],
    ignoreInsightNamePrefixes: without(defaultFetchInsightsParams.ignoreInsightNamePrefixes, 'test'),
    pastOrPresent: 'both',
    isInteresting: 'interestingOnly',
    minimumSeverity: 'minor',
    ...options,

    // need to use this format: https://github.com/kentik/ui-app/issues/4482#issuecomment-686828472
    dimensionToValueDNF: [{ dimensionToValue }]
  };

  return fetchInsights(params);
}

export function fetchNetworkExplorerInsights() {
  return fetchInsights({
    groupLimit: -1,
    excludeUserDefined: true
  });
}

export function fetchCapacityPlanningInsights() {
  // TODO - update to fetch all capacity planning insights instead of specific one by name
  return fetchInsights({
    insightName: 'capacity.errors.maxinterfacesinplan',
    maxEndTimeOnly: true,
    isInteresting: 'interesting_unspecified'
  });
}
