import { assignWith, flatMap, get, invert, merge, startCase, uniq } from 'lodash';
import moment from 'moment';

import { adjustByGreekPrefix } from 'core/util/greekPrefixing';
import api from 'core/util/api';
import { DEFAULT_DATETIME_FORMAT } from 'core/util/dateUtils';
import mergeFilterGroups from 'core/util/filters/mergeFilterGroups';
import buildFilterGroup from 'core/util/filters/buildFilterGroup';
import transformPolicyFilters from 'app/util/alerting/transformPolicyFilters';
import { greekIt, zeroToText } from 'app/util/utils';
import DataViewModel from 'app/stores/query/DataViewModel';
import { addFilters } from 'app/stores/query/FilterUtils';
import InsightsCollection from './InsightsCollection';
import InsightVotesCollection from './InsightVotesCollection';
import InsightDefinitionCollection from './InsightDefinitionCollection';
import insightQueries from './insightQueries';
import * as fetchInsights from './fetchInsights';
import {
  getComparisonData,
  getComparisonsPrefix,
  getDimensionKeys,
  getMetrics,
  getQueryDimensionFilters
} from './insightUtils';

const simpleQueryTypes = [
  'core.networkHealth.deviceNoOrLowFlow',
  'core.networkHealth.deviceTrafficIncrease',
  'core.networkHealth.deviceTrafficDrop',
  'core.trafficAnalytics.peeringLinkInbound',
  'core.trafficAnalytics.peeringLinkOutbound',
  'defense.threatActivity.internalLateral'
];

// TODO why are these outside the store? Contact Elod if you know plz
const policyLookups = {};
const displayNameLookups = {};
const deviceLookups = {};
const interfaceLookups = {};

const dimensionToLabel = {
  cdn: 'CDN',
  i_src_cdn: 'CDN',
  src_cdn: 'CDN',
  src_as: 'Src AS Number',
  dst_as: 'Dest AS Number',
  src_geo: 'Src Country',
  dst_geo: 'Dest Country',
  inet_dst_addr: 'Dest IP Address',
  inet_src_addr: 'Src IP Address',
  device_id: 'Device',
  snmp_id: 'Interface',
  agent_alias: 'Agent',
  agent_cloud_region: 'Region',
  agent_cloud_provider: 'Cloud',
  agent_asn: 'ASN',
  agent_ip: 'IP Address',
  test_id: 'Test ID',
  test_name: 'Test Name',
  test_type: 'Test Type',
  test_target: 'Test Target',
  dst_inet_addr: 'Tested IP',
  ktsubtype__aws_subnet__str17: 'Gateway ID',
  ktsubtype__aws_subnet__str19: 'Gateway',
  capacity_plan_id: 'Capacity plan'
};

const displayNamePaths = {
  Geography_src: 'country.displayNames',
  Geography_dst: 'country.displayNames',
  Proto: 'protocol.displayNames',
  src_as: 'as.displayNames',
  dst_as: 'as.displayNames'
};

const geoCityAndRegionDimensions = ['src_geo_region', 'dst_geo_region', 'dst_geo_city', 'src_geo_city'];

export class InsightStore {
  constructor(options = {}) {
    Object.keys(fetchInsights).forEach((key) => {
      this[key] = (...args) => fetchInsights[key].call(this, ...args).then(this.processInsights);
    });

    Object.assign(this, options);
  }

  collection = new InsightsCollection({ $insights: this, fetchOptions: { excludeUserDefined: true } });

  votesCollection = new InsightVotesCollection({ $insights: this });

  definitionCollection = new InsightDefinitionCollection();

  metricColumnOverrides = {
    dst_geo: 'Geography_dst',
    src_geo: 'Geography_src',
    dst_as: 'AS_dst',
    src_as: 'AS_src'
  };

  metricColumnsInverse = undefined;

  filterColumnOverrides = {
    cdn: 'src_cdn',
    i_src_cdn: 'src_cdn',
    site: 'i_device_site_name',
    output_port: 'InterfaceID_dst',
    input_port: 'InterfaceID_src',
    i_dst_provider_classification: 'i_dst_provider_classification',
    i_dst_network_bndry_name: 'i_dst_network_bndry_name',
    i_dst_connect_type_name: 'i_dst_connect_type_name',
    dst_route_prefix_len: 'inet_dst_route_prefix',
    src_route_prefix_len: 'inet_src_route_prefix'
  };

  filterColumnOverridesInverse = invert(this.filterColumnOverrides);

  queryColumnOverrides = {
    'interconnection.peeringAnalytics.lowInterfaceLastMonth': {
      snmp_id: 'input|output_port'
    },
    'operate.capacity.capacity': {
      snmp_id: 'ktappprotocol__snmp__output_port'
    },
    'operate.capacity.runout': {
      snmp_id: 'ktappprotocol__snmp__output_port'
    }
  };

  queryColumnOverridesInverse = {
    'input|output_port': 'snmp_id',
    ktappprotocol__snmp__output_port: 'snmp_id'
  };

  queryUnitOverrides = {
    bits: 'bytes',
    bits_in: 'in_bytes',
    bits_out: 'out_bytes',
    packets_in: 'in_packets',
    packets_out: 'out_packets',
    ktsubtype__kappa__APPL_LATENCY_MS: 'appl_latency',
    unique_src_region: 'unique_src_geo_region',
    unique_dst_region: 'unique_dst_geo_region',
    unique_src_city: 'unique_src_geo_city',
    unique_dst_city: 'unique_dst_geo_city',
    retransmits_in: 'retransmits_out',
    perc_retransmits_in: 'perc_retransmits_out'
  };

  initialize() {
    this.definitionCollection.fetch();
  }

  getMetricColumn = (dimension, value) => this.store.$dictionary.getMetricColumn(dimension, value);

  // getKdeDimension and getNonKdeDimension are used for translating dimensions to and from the insights and factors apis
  getKdeDimension = (dimension) => {
    // check insight query overrides first
    if (this.queryColumnOverridesInverse[dimension]) {
      return this.queryColumnOverridesInverse[dimension];
    }

    // check metric column overrides
    if (this.metricColumnOverridesInverse[dimension]) {
      return this.metricColumnOverridesInverse[dimension];
    }

    // check dictionary last
    return this.getMetricColumn(dimension);
  };

  getQueryMetric = (dimension, insightName) => {
    if (this.metricColumnOverrides[dimension]) {
      return this.metricColumnOverrides[dimension];
    }

    return this.getNonKdeDimension(dimension, insightName);
  };

  // jim, your mission, should you choose to accept it, is to translate this insight dimension to a non-kde dimension. good luck.
  getNonKdeDimension = (dimension, insightName) => {
    if (!this.metricColumnsInverse) {
      this.metricColumnsInverse = invert(this.store.$dictionary.dictionary.metricColumns);
    }

    // don't translate these? https://github.com/kentik/ui-app/issues/1243
    if (dimension.includes('__snmp__')) {
      return dimension;
    }

    // check insight query overrides first
    if (insightName && this.queryColumnOverrides[insightName] && this.queryColumnOverrides[insightName][dimension]) {
      return this.queryColumnOverrides[insightName][dimension];
    }

    // check metric column overrides
    if (this.filterColumnOverrides[dimension]) {
      return this.filterColumnOverrides[dimension];
    }

    // check dictionary metric columns (inverse)
    if (this.metricColumnsInverse[dimension] && dimension !== 'i_device_id') {
      return this.metricColumnsInverse[dimension];
    }

    // check dictionary metric columns
    if (dimension !== 'i_device_id') {
      return this.getMetricColumn(dimension);
    }

    return dimension;
  };

  getDimensionLabel(dimension, insightName) {
    let dimensionName = dimension;

    if (insightName) {
      dimensionName = this.getNonKdeDimension(dimension, insightName);
    }

    return (
      dimensionToLabel[dimensionName] ||
      this.store.$dictionary.get(`chartTypesValidations.${dimensionName}`) ||
      startCase(dimensionName)
    );
  }

  getDimensionToValueText(dimensionToValue, insightName) {
    const keys = Object.keys(dimensionToValue);

    return keys.map((key) => `${this.getDimensionLabel(key, insightName)} ${dimensionToValue[key]}`).join(', ');
  }

  calculateEventEndTime({ endTime, threshold }) {
    const inactivityTimeUntilClear = parseInt(get(threshold, 'activationSettings.inactivityTimeUntilClear', 0)) || 0;

    if (!endTime) {
      return null;
    }

    return moment(endTime).subtract(inactivityTimeUntilClear, 'seconds').valueOf();
  }

  getAlertEndTimeMoment(endTime, attackModel, insight) {
    const alertingSource = insight && insight.dataSourceType === 'alerting';
    const stateChanges = (attackModel && attackModel.get?.('stateChanges')) || [];
    const mostRecentStateIsAckReq =
      stateChanges && stateChanges.length > 0 ? stateChanges[0].state === 'ackReq' : false;

    const eventEndTime = this.calculateEventEndTime({ endTime, threshold: get(insight, 'alerting.threshold') });

    let endTimeMoment = eventEndTime ? moment.utc(eventEndTime) : null;

    if (!endTimeMoment) {
      endTimeMoment =
        mostRecentStateIsAckReq && !endTime
          ? // means we only want the chart to go until the ack req state is associated to the attack, otherwise spikes
            // might not even be visible over a much larger time-span
            // basically, we should graph the event and not include the gap between event and acknowledge
            moment.utc(stateChanges[0].time).format(DEFAULT_DATETIME_FORMAT)
          : moment.utc(endTime).format(DEFAULT_DATETIME_FORMAT);
    }

    if (endTimeMoment && endTimeMoment.add) {
      endTimeMoment = endTimeMoment.add(alertingSource ? 30 : 0, 'minutes');
    }

    return endTimeMoment;
  }

  getInsightQuery(insightModel, attackModel) {
    const insight = insightModel.get ? insightModel.get() : { ...insightModel };

    if (insight.dataExplorerQuery) {
      insight.dataExplorerQuery.target_company_id = insight.companyID;
      return insight.dataExplorerQuery;
    }

    const { dataSourceType, insightName, startTime, endTime, alerting = {}, companyID } = insight;
    const policy = alerting.policy || this.lookupPolicy(alerting.policyID);

    const { dimensionToKeyPart = {} } = alerting;
    const deviceName = this.getDeviceName(insight);
    const metrics = getMetrics(insight)
      .map((metric) => (metric in this.queryUnitOverrides ? this.queryUnitOverrides[metric] : metric))
      .filter((metric) => this.store.$dictionary.get(`unitsLegacy.${metric}`));

    const endTimeMoment = this.getAlertEndTimeMoment(endTime, attackModel, insight);

    const query = this.store.$query.get('inboundOutboundInternalQuery', {
      source: 'insight',
      aggregateTypes: uniq(flatMap(metrics, (metric) => this.store.$dictionary.get(`unitsLegacy.${metric}`) || [])),
      outsort: this.store.$dictionary.get(`unitsLegacy.${metrics[0]}[0]`),
      secondaryOutsort: metrics.length > 1 ? this.store.$dictionary.get(`unitsLegacy.${metrics[1]}[0]`) : null,
      viz_type: metrics.length > 1 ? 'line' : 'stackedArea',
      all_devices: !deviceName,
      device_name: deviceName,
      filterDimensionsEnabled:
        !simpleQueryTypes.includes(insightName) && insightName && !insightName.startsWith('custom.insight'),
      lookback_seconds: 0,
      starting_time: moment
        .utc(startTime)
        .subtract(dataSourceType === 'alerting' ? 30 : 0, 'minutes')
        .format(DEFAULT_DATETIME_FORMAT),
      ending_time: endTimeMoment,
      fastData: metrics.includes('fps') ? 'Full' : 'Auto',
      target_company_id: companyID,
      show_overlay: true,
      show_total_overlay: false
    });

    // remove filters from inboundOutboundInternalQuery
    delete query.filters;

    const filters = getQueryDimensionFilters(dimensionToKeyPart);

    if (filters.length > 0) {
      addFilters(query, filters);
    }

    if (insightQueries[insightName]) {
      const { filters: insightFilterObj, ...queryOverrides } = insightQueries[insightName];

      if (insightFilterObj) {
        const { filters: insightFilters, connector = 'All' } =
          typeof insightFilterObj === 'function' ? insightFilterObj.call(this, insight) : insightFilterObj;
        addFilters(query, insightFilters, connector);
      }

      assignWith(query, queryOverrides, (a, b) => {
        if (typeof b === 'function') {
          return b.call(this, insight);
        }

        return undefined;
      });
    }

    if (policy && policy.filters) {
      query.filters = mergeFilterGroups(query.filters, transformPolicyFilters(policy.filters));

      if (insight.insightName === 'core.networkHealth.deviceNoOrLowFlow') {
        // don't want the filters that are on the policy
        // TODO update the actual Policy in the db
        query.filters.filterGroups = [];
      }

      // adjust DE interval for policies with < 10 min windows (basically all of them)
      const evaluationPeriod = parseInt(policy.evaluationPeriod.replace('s', ''), 10);
      if (evaluationPeriod < 600) {
        query.forceMinsPolling = true;

        // if less than 5 min -- use 1 min period
        // if between 5-10 min use 5 min period in DE.
        if (evaluationPeriod < 300) {
          query.minsPolling = 1;
        } else {
          query.minsPolling = 5;
        }

        // Less than 1 min policies - you basically set the reAggInterval to 15/20/30 and reAggFn to sum
        if (evaluationPeriod < 60) {
          query.reAggInterval = evaluationPeriod;
          query.reAggFn = 'sum';
        }

        // adjust for SNMP policies (minimum window is 5 min)
        if (policy.dimensions.some((dimension) => dimension.includes('__snmp__'))) {
          query.minsPolling = 5;
          query.reAggInterval = 'auto';
          query.reAggFn = 'none';
        }
      }
    }

    return query;
  }

  getSnmpVsFlowDataViewModel(insightModel, options) {
    const { isIngress = false } = options || {};
    const insight = insightModel.get ? insightModel.get() : { ...insightModel };
    const { startTime, endTime, dimensionToValue = {}, companyID } = insight;
    const { snmp_id, ingress_detection_context, egress_detection_context } = dimensionToValue;

    if ((isIngress && ingress_detection_context === 0) || (!isIngress && egress_detection_context === 0)) {
      return null;
    }

    const deviceName = this.getDeviceName(insight);
    const extraMinutes = 15;

    const start = moment.utc(startTime).subtract(extraMinutes, 'minutes');
    start.subtract(start.minute() % 5, 'minutes');

    const snmpDimension = isIngress ? 'avg_ktappprotocol__snmp__INT64_00' : 'avg_ktappprotocol__snmp__INT64_02';
    const snmpFilterDimension = isIngress ? 'ktappprotocol__snmp__input_port' : 'ktappprotocol__snmp__output_port';
    const flowDimension = 'avg_bits_per_sec';
    const flowFilterDimension = isIngress ? 'input_port' : 'output_port';

    const queries = [
      {
        target_company_id: companyID,
        bucket: 'Left +Y Axis',
        sync_axes: true,
        aggregateTypes: [snmpDimension],
        all_devices: false,
        device_name: deviceName,
        fastData: 'Full',
        filters: {
          connector: 'All',
          filterGroups: [
            buildFilterGroup({
              filters: [
                {
                  filterField: snmpFilterDimension,
                  filterValue: snmp_id,
                  operator: '='
                }
              ]
            })
          ]
        },
        lookback_seconds: 0,
        metric: ['Traffic'],
        outsort: snmpDimension,
        show_overlay: false,
        show_total_overlay: false,
        starting_time: start.format(DEFAULT_DATETIME_FORMAT),
        ending_time: moment.utc(endTime).add(extraMinutes, 'minutes').format(DEFAULT_DATETIME_FORMAT),
        viz_type: 'line'
      }
    ];

    queries.push({
      ...queries[0],
      bucket: 'Right +Y Axis',
      aggregateTypes: [flowDimension],
      filters: {
        connector: 'All',
        filterGroups: [
          buildFilterGroup({
            filters: [
              {
                filterField: flowFilterDimension,
                filterValue: snmp_id,
                operator: '='
              }
            ]
          })
        ]
      },
      outsort: flowDimension
    });

    const dataview = new DataViewModel();
    dataview.setQueries(queries);

    return dataview;
  }

  getSnmpQuery(insightModel) {
    const insight = insightModel.get ? insightModel.get() : { ...insightModel };
    const { startTime, endTime, dimensionToValue = {}, companyID } = insight;
    const { snmp_id } = dimensionToValue;
    const deviceName = this.getDeviceName(insight);
    const extraMinutes = 15;

    const start = moment.utc(startTime).subtract(extraMinutes, 'minutes');
    start.subtract(start.minute() % 5, 'minutes');

    const query = {
      target_company_id: companyID,
      show_overlay: false,
      show_total_overlay: false,
      fastData: 'Auto',
      viz_type: 'stackedArea',
      all_devices: !deviceName,
      device_name: deviceName,
      aggregateTypes: ['avg_ktappprotocol__snmp__INT64_00'],
      dimension: ['i_device_id', 'InterfaceID_src'],
      lookback_seconds: 0,
      minsPolling: 5,
      forceMinsPolling: true,
      metric: [
        'ktappprotocol__snmp__i_device_site_name',
        'ktappprotocol__snmp__i_device_name',
        'ktappprotocol__snmp__output_port',
        'ktappprotocol__snmp__i_dst_connect_type_name',
        'ktappprotocol__snmp__i_dst_network_bndry_name',
        'ktappprotocol__snmp__i_dst_provider_classification'
      ],

      starting_time: start.format(DEFAULT_DATETIME_FORMAT),

      ending_time: moment.utc(endTime).add(extraMinutes, 'minutes').format(DEFAULT_DATETIME_FORMAT),

      filters: {
        connector: 'All',
        filterGroups: [
          buildFilterGroup({
            filters: [
              {
                filterField: 'ktappprotocol__snmp__output_port',
                operator: '=',
                filterValue: snmp_id
              }
            ]
          })
        ]
      }
    };

    return query;
  }

  getDeviceName(insight) {
    const deviceName = get(insight, 'alerting.dimensionToKeyDetail.i_device_id.device.name');
    if (deviceName) {
      return deviceName;
    }

    const deviceId = get(insight, 'dimensionToValue.device_id');
    if (deviceId) {
      const device = this.store.$devices.deviceSummariesById[deviceId];
      if (device) {
        return device.device_name;
      }
    }

    return null;
  }

  getDeviceNameFromAttackModel(attackModel) {
    const deviceName = get(attackModel, 'dimensionToKeyDetail.i_device_id.device.name');

    if (deviceName) {
      return deviceName;
    }

    const deviceId = get(attackModel, 'dimensionToValue.device_id');

    if (deviceId) {
      const device = this.store.$devices.deviceSummariesById[deviceId];

      if (device) {
        return device.device_name;
      }
    }

    return null;
  }

  getCapacity(insight) {
    const { insightName, interfaceIDToDetail, dimensionToValue = {} } = insight.get ? insight.get() : insight;
    const { device_id, snmp_id } = dimensionToValue;
    let capacity = get(insight, 'metricToValue.capacity_bps');

    if (insight.alerting) {
      return this.store.$alerting.getCapacity(insight.alerting);
    }

    if (insightName.includes('factors.interface.utilization')) {
      capacity = (get(interfaceIDToDetail, `${device_id}:${snmp_id}.snmpSpeedMbps`) || 0) * 1000000;
    }

    if (!capacity) {
      return null;
    }

    const { display: formattedValue, unitsLabel: formattedMetric } = greekIt(capacity, { fix: 2, suffix: 'bps' });

    return {
      metric: 'bits',
      value: capacity,
      formatted: `${formattedValue} ${formattedMetric}`,
      formattedValue,
      formattedMetric,
      toString() {
        return this.formatted;
      }
    };
  }

  getDimension(insight, dimension, value = this.getDimensionValue(insight, dimension)) {
    if (insight.alerting) {
      return this.store.$alerting.getDimension(insight.alerting, dimension, value);
    }

    const lookup = Array.isArray(value)
      ? value.map((v) => this.lookupValue(insight, dimension, v))
      : this.lookupValue(insight, dimension, value);
    const label = this.getDimensionLabel(dimension);

    return {
      key: dimension,
      label,
      value,
      lookup,
      formatted: `${label}: ${lookup}`,
      toString() {
        return this.formatted;
      }
    };
  }

  getDimensionValue(insight, dimension) {
    if (dimension === 'snmp_id') {
      const deviceId = get(insight, 'dimensionToValue.device_id');
      const snmpId = get(insight, 'dimensionToValue.snmp_id');
      return `${deviceId}:${snmpId}`;
    }

    if (dimension === 'InterfaceID_src' || dimension === 'InterfaceID_dst') {
      const deviceId = get(insight, 'dimensionToValue.i_device_id');
      const snmpId = get(insight, `dimensionToValue.${dimension}`);
      return `${deviceId}:${snmpId}`;
    }

    if (insight.alerting) {
      return this.store.$alerting.getDimensionValue(insight.alerting, dimension);
    }

    if (insight.comparison) {
      const { interestingKeys } = getComparisonData(insight);

      if (geoCityAndRegionDimensions.includes(dimension)) {
        return interestingKeys.map(({ dimensionToValue }) => dimensionToValue);
      }
      return interestingKeys.map(({ dimensionToValue }) => dimensionToValue[dimension]);
    }

    return get(insight, `dimensionToValue.${dimension}`, 0);
  }

  getComparisons(insight, keys = get(insight, 'comparison.newTopKeys', [])) {
    const thresholds = insight.comparison.thresholds || {};
    const { valueChange: valueHighThreshold = 0.5, maxValueDropAnyKey: valueLowThreshold = -0.95 } = thresholds;
    const { interestingKeys } = getComparisonData(insight);
    const prefix = getComparisonsPrefix(insight);
    const dimensionKeys = getDimensionKeys(insight);

    return keys.map((key) => {
      const dimensions = dimensionKeys.map((dimension) =>
        this.getDimension(insight, dimension, key.dimensionToValue[dimension])
      );

      const newBps = get(key, 'metricToValueNew.bps', 0);
      const oldBps = get(key, 'metricToValueOld.bps', 0);

      const formattedNewBps = newBps
        ? `${zeroToText(adjustByGreekPrefix(newBps, prefix), { fix: 1 })} ${prefix}bits/s`
        : null;
      const formattedOldBps = oldBps
        ? `${zeroToText(adjustByGreekPrefix(oldBps, prefix), { fix: 1 })} ${prefix}bits/s`
        : null;

      const percentage = newBps && oldBps ? (newBps - oldBps) / oldBps : null;
      const hasValueChange = percentage > valueHighThreshold || percentage < valueLowThreshold;
      const valueChangeFactor =
        percentage > 0 ? Math.min(percentage / valueHighThreshold, 1) : Math.min(percentage / valueLowThreshold, 1);
      const isInteresting = interestingKeys.some(
        ({ rankOld, rankNew }) => key.rankOld === rankOld && key.rankNew === rankNew
      );

      return {
        ...key,
        dimensions,
        formattedNewBps,
        formattedOldBps,
        percentage,
        isInteresting,
        hasValueChange,
        valueChangeFactor
      };
    });
  }

  lookupValue(insight, dimension, value) {
    if (insight.alerting && !deviceLookups[value] && !interfaceLookups[value]) {
      return this.store.$alerting.lookupValue(insight.alerting, dimension, value);
    }

    return this.lookupDisplayName(dimension, value);
  }

  mergeLookups({ entityTypeToDisplayNames = {}, deviceIDToDetail = {}, interfaceIDToDetail = {} }) {
    merge(displayNameLookups, entityTypeToDisplayNames);
    merge(deviceLookups, deviceIDToDetail);
    merge(interfaceLookups, interfaceIDToDetail);
  }

  lookupPolicy(id) {
    return get(policyLookups, `${id}.policy`);
  }

  lookupDevice(id) {
    return deviceLookups[id];
  }

  lookupInterface(id) {
    return interfaceLookups[id];
  }

  lookupDisplayName(dimension, value) {
    if (deviceLookups[value]) {
      return deviceLookups[value].name;
    }

    if (interfaceLookups[value]) {
      const { snmpID, snmpDescription, snmpAlias } = interfaceLookups[value];
      return `${snmpDescription}${snmpAlias ? ` – ${snmpAlias}` : ''} (${snmpID})`;
    }

    if (displayNamePaths[dimension]) {
      const path = displayNamePaths[dimension];
      const lookupValue = get(displayNameLookups, `${path}.${value}`);

      if (lookupValue) {
        switch (path) {
          case 'as.displayNames':
            return `AS ${value} (${lookupValue})`;

          default:
            return lookupValue;
        }
      }

      switch (path) {
        case 'as.displayNames':
          return `AS ${value}`;

        default:
          return value;
      }
    }

    if (geoCityAndRegionDimensions.includes(dimension)) {
      return value[dimension];
    }

    const { dictionary } = this.store.$dictionary;
    if (dictionary.queryFilters.options[dimension]) {
      return dictionary.queryFilters.options[dimension][value] || value;
    }

    return value;
  }

  get insightTypes() {
    return this.definitionCollection.insightTypes;
  }

  get typesList() {
    return this.definitionCollection.allInsightTypesList;
  }

  getType = (model) => {
    const { insightName } = model.get ? model.get() : model;

    if (insightName && insightName.startsWith('custom.insight')) {
      return this.insightTypes.custom(model);
    }

    return this.insightTypes[insightName] || this.insightTypes.custom(model);
  };

  getFamilies = () => this.definitionCollection.insightFamilies.map((family) => ({ label: family, value: family }));

  getPolicyNames = () =>
    this.definitionCollection.customInsightTypesList.map((insightDefinition) => ({
      label: insightDefinition.label,
      value: insightDefinition.insightName
    }));

  getInsightNames = () =>
    this.definitionCollection.kentikInsightTypesList
      .map((insightDefinition) => ({
        label: insightDefinition.label,
        value: insightDefinition.insightName
      }))
      .sort((a, b) => (a.label || '').localeCompare(b.label));

  getSilences() {
    return api.get('/api/ui/insights/silences').then(({ silences = [] }) => silences);
  }

  silenceInsight(data) {
    return api.post('/api/ui/insights/silences', { data }).then(({ silences = [] }) => silences);
  }

  unsilenceInsight(data) {
    return api.del('/api/ui/insights/silences', { data }).then(({ silences = [] }) => silences);
  }

  flagInsight(insightID, model) {
    return api
      .post('/api/ui/insights/flags', {
        data: {
          flag: {
            insightID
          }
        }
      })
      .then(() => {
        if (model) {
          model.set('flagged', true);
        }
        return true;
      });
  }

  unflagInsight(insightID, model) {
    return api
      .del('/api/ui/insights/flags', {
        data: {
          flag: {
            insightID
          }
        }
      })
      .then(() => {
        if (model) {
          model.set('flagged', false);
        }
        return true;
      });
  }

  voteInsight({ insight, isUpvote, reason }) {
    return api
      .post('/api/ui/insights/votes', {
        data: {
          insightID: insight.insightID,
          score: isUpvote ? 1 : -1,
          reason
        }
      })
      .then((res) => {
        insight.voteScore = res.vote.score;
        insight.reason = reason;
        return res;
      })
      .catch(() => {
        // don't tell user about the error, just update locally
        insight.voteScore += isUpvote ? 1 : -1;
        insight.reason = reason;
      });
  }

  /**
   * Execute insight, swallows any exceptions
   * @param name - Name of insight to execute
   * @param options - Options obj
   *  body - [Optional]Req body to send if default needs to be overridden.
   *  showErrorToast - [Optional, default false]
   * @returns {Promise<any|Function>}
   */
  async executeInsight(name, options = {}) {
    return api
      .post(`/api/ui/insights/execute/${name}`, {
        body: options.body || { save: true },
        showErrorToast: options.showErrorToast || false
      })
      .catch((err) => {
        console.warn(`Error executing insight ${name}`, err);
      });
  }

  processInsights = ({ insights, res }) => {
    const { policyIDToDetails = {} } = res;

    Object.keys(policyIDToDetails).forEach((id) => {
      policyLookups[id] = policyIDToDetails[id];
    });

    this.mergeLookups(res);

    return {
      ...res,
      insights
    };
  };
}

export default new InsightStore();
