/**
 * @template T
 * @param collection {import('core/model/Collection').default}
 * @param param {string}
 * @returns {function(key: string): T | undefined}
 */
export const modelFinder = (collection, param) => {
  const map = collection.reduce((acc, curr) => {
    if (curr.get(param)) {
      acc.set(curr.get(param), curr);
    }
    return acc;
  }, new Map());
  return (name) => map.get(name);
};
