// Metric model represents a measurement and its associated metrics and dimensions.
import { computed } from 'mobx';
import Model from 'core/model/Model';

class MetricModel extends Model {
  get urlRoot() {
    return '/api/ui/recon/metrics';
  }

  get label() {
    return this.get('measurement');
  }

  get storage() {
    return this.get('storage');
  }

  metricDetails = (metric) => this.get(`storage.Metrics.${metric}`);

  metricLabel = (metric, useDefault = true) => this.get(`storage.Metrics.${metric}.Label`, useDefault ? metric : null);

  // returns an Object with the ENUM values as keys and the labels as values.
  // If useDefault is true, and the value is not found in the Values map, the value is returned.
  metricValueLabel = (metric, value, useDefault = true) =>
    this.get(`storage.Metrics.${metric}.Values.${value}`, useDefault ? value : null);

  dimensionLabel = (dimension, useDefault = true) =>
    this.get(`storage.Dimensions.${dimension}.Label`, useDefault ? dimension : null);

  anyLabel = (key) => this.metricLabel(key, false) || this.dimensionLabel(key, false) || key;

  @computed
  get dimensionColumnMap() {
    const map = new Map();
    Object.entries(this.storage.Dimensions).forEach(([dimension, props]) => {
      const { Column } = props;
      props.name = dimension;
      map[Column] = props;
    });
    return map;
  }

  @computed
  get metricColumnMap() {
    const map = new Map();
    Object.entries(this.storage.Metrics).forEach(([metric, props]) => {
      const { Column } = props;
      props.name = metric;
      map[Column] = props;
    });
    return map;
  }

  @computed
  get mergedMetricsDimensions() {
    return {
      ...this.metricColumnMap,
      ...this.dimensionColumnMap,
      km_measurement_name: { name: 'km_measurement_name', Column: 'km_measurement_name', Label: 'Measurement' },
      km_device_id: { name: 'km_device_id', Column: 'km_device_id', Label: 'NMS Device ID' }
    };
  }

  get defaults() {
    return {
      id: '',
      measurement: '',
      ver: '',
      storage: {
        Dimensions: [],
        Metrics: []
      }
    };
  }
}

export default MetricModel;
