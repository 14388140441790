import { action, computed, observable } from 'mobx';
import { uniqueId } from 'lodash';
import moment from 'moment';
import { getHealthTimeline } from 'shared/synthetics/utils';
import { getTimelinebounds } from 'app/views/synthetics/utils/syntheticsUtils';
import { processTestResults } from './utils';

class TestResultsStateByTimestamp {
  get defaults() {
    return {
      test: null,
      loadingAlarmResults: false,
      hasAlarmResults: false,
      alarmResults: null,
      timestamps: [],
      loadingTimestamps: false,
      loadingResults: false,
      resultsTs: {},
      resultTime: null,
      aggregation: null
    };
  }

  test = this.defaults.test;

  requestId = uniqueId('tr_');

  @observable
  loadingAlarmResults = this.defaults.loadingAlarmResults;

  @observable
  hasAlarmResults = this.defaults.hasAlarmResults;

  @observable.ref
  alarmResults = this.defaults.alarmResults;

  @observable
  loadingTimestamps = this.defaults.loadingTimestamps;

  @observable.ref
  timestamps = this.defaults.timestamps;

  resultsTs = this.defaults.resultsTs;

  @observable
  loadingResults = false;

  @observable
  resultTime = this.defaults.resultTime;

  @observable
  aggregation = this.defaults.aggregation;

  meshState = {};

  constructor({ store, test }) {
    this.store = store;
    this.test = test;
  }

  newRequestID() {
    this.requestId = uniqueId('tr_');
  }

  fetch({ startDate, endDate }) {
    this.newRequestID();
    this.fetchResultsTimelines({ start_time: startDate, end_time: endDate });
    this.fetchAlarmResults({
      start: moment.utc(startDate * 1000).toISOString(),
      end: moment.utc(endDate * 1000).toISOString()
    });
  }

  @action
  fetchResultsTimelines(dateObj) {
    const { $syn } = this.store;
    const { requestId, test } = this;
    const { isPreset } = test;
    this.loadingTimestamps = true;
    this.timestamps = [];
    this.resultsTs = {};
    this.resultTime = null;
    this.aggregation = null;

    if (!this.init) {
      this.init = true;
    }

    $syn.requests.fetchTestResultsTimestamps({ ...dateObj, test_id: `${test.id}` }, { preset: isPreset }).then(
      action(({ timestamps, aggregation }) => {
        // check if request is relevant before storing response
        if (requestId === this.requestId) {
          const hasTimestamps = !!timestamps.length;
          const ts = hasTimestamps ? timestamps[timestamps.length - 1] : null;

          this.timestamps = hasTimestamps ? timestamps : [];
          this.resultTime = +ts;
          this.loadingTimestamps = false;
          this.aggregation = aggregation;

          if (hasTimestamps) {
            this.fetchResultsByTs(ts, aggregation);
          }
        }
      })
    );
  }

  @action
  fetchAlarmResults(dateObj) {
    const { $syn } = this.store;
    const { requestId, test } = this;

    this.alarmResults = null;
    this.hasAlarmResults = null;
    this.loadingAlarmResults = true;
    return $syn.requests.getTestAlarms({ ...dateObj, ids: [test.id] }).then(
      action((resp) => {
        // check if request is relevant before storing response
        if (requestId === this.requestId) {
          this.alarmResults = resp;
          this.hasAlarmResults = !!resp?.alarms.length;
          this.loadingAlarmResults = false;
        }
      })
    );
  }

  @action
  fetchResultsByTs(ts, aggregation) {
    const { $syn } = this.store;
    const { requestId, test } = this;
    const { isPreset } = test;

    if (!this.resultsTs[ts]) {
      this.loadingResults = true;

      $syn.requests
        .fetchTestResultsByTimestamp({ timestamp: ts, aggregation, test_id: `${test.id}` }, { preset: isPreset })
        .then(
          action((resp) => {
            // check if request is relevant before storing response
            if (requestId === this.requestId) {
              this.resultsTs[ts] = resp?.tests_health || null;

              if (this.resultTime === +ts) {
                this.loadingResults = false;
              }
            }
          })
        );
    }
  }

  @action
  onChangeResultTimeMs(resultTimeMs) {
    this.resultTime = resultTimeMs / 1000;

    this.fetchResultsByTs(this.resultTime, this.aggregation);
  }

  @computed
  get resultTimeMs() {
    const { resultTime } = this;

    return resultTime ? resultTime * 1000 : null;
  }

  @computed
  get healthTimeline() {
    const { alarmResults, timestamps } = this;

    if (timestamps.length > 0) {
      return getHealthTimeline({
        alarms: alarmResults?.alarms || [],
        timestamps
      });
    }

    return [];
  }

  @computed
  get results() {
    const { resultTime, resultsTs, loadingResults, loadingTimestamps, loadingAlarmResults } = this;

    if (loadingResults || loadingTimestamps || loadingAlarmResults) {
      return null;
    }

    return resultsTs[resultTime] || null;
  }

  @computed
  get hasResults() {
    const { results } = this;

    if (results) {
      const { health_ts = {}, tasks = {} } = results;
      const tsKeys = Object.keys(health_ts);
      const taskKeys = Object.keys(tasks);
      return tsKeys.length > 0 && taskKeys.length > 0;
    }

    return false;
  }

  @computed
  get mesh() {
    const { results, hasResults } = this;
    const mesh = [];
    if (hasResults && results.mesh) {
      const rowKeys = Object.keys(results.mesh);
      rowKeys.sort((a, b) => results.mesh[a].name?.localeCompare(results.mesh[b].name));

      for (let i = 0; i < rowKeys.length; i += 1) {
        const row = results.mesh[rowKeys[i]];
        const columnKeys = Object.keys(row.columns);
        columnKeys.sort((a, b) => row.columns[a].name?.localeCompare(row.columns[b].name));
        const columns = [];

        for (let j = 0; j < columnKeys.length; j += 1) {
          const column = row.columns[columnKeys[j]];
          columns.push({ ...column, health: Object.values(column.health) });
        }

        mesh.push({ ...row, columns });
      }

      return mesh;
    }
    return undefined;
  }

  @computed
  get agentHealthTs() {
    const { test, results, hasResults } = this;

    if (hasResults) {
      return processTestResults(test, results);
    }

    return null;
  }

  @computed
  get resultsKeys() {
    const { results } = this;

    return {
      agentKeys: Object.keys(results?.agents || {}),
      taskKeys: Object.keys(results?.tasks || {}),
      healthTsKeys: Object.keys(results?.health_ts || {}),
      healthAggKeys: Object.keys(results?.health_agg || {})
    };
  }

  @computed
  get agentTaskConfig() {
    const { results, resultsKeys, hasResults } = this;
    const config = {};

    if (hasResults) {
      const { agents } = results;
      const { agentKeys } = resultsKeys;

      for (let i = 0; i < agentKeys.length; i++) {
        const agentKey = agentKeys[i];
        const agent = agents[agentKeys[i]];

        if (agent.targets || agent.hostnames) {
          config[agentKey] = {};
        }

        if (agent.targets) {
          config[agentKey].targets = agent.targets;
        }

        if (agent.hostnames) {
          config[agentKey] = { ...config[agentKey], ...agent.hostnames };
        }
      }
    }

    return config;
  }

  @computed
  get latestHealth() {
    const { results, resultsKeys, hasResults } = this;
    const { healthTsKeys } = resultsKeys;

    if (hasResults) {
      return results.health_ts[healthTsKeys[healthTsKeys.length - 1]];
    }

    return null;
  }

  @computed
  get timelineBounds() {
    const { timestamps } = this;
    if (timestamps?.length) {
      const { xMin, xMax, xAxisMin, xAxisMax, delta } = getTimelinebounds(timestamps);

      return { xMin, xMax, xAxisMin, xAxisMax, delta };
    }

    return {};
  }

  @computed
  get isAggregated() {
    return !!this.aggregation;
  }
}

export default TestResultsStateByTimestamp;
