import Model from 'core/model/Model';

class Cdn extends Model {
  get defaults() {
    return {
      metadata: {}
    };
  }

  get urlRoot() {
    return '/api/ui/cdn';
  }
}

export default Cdn;
