import { computed } from 'mobx';
import Model from 'core/model/Model';
import $cost from 'app/stores/cost/$cost';
import CostGroupModel from './CostGroupModel';

class CostItemModel extends Model {
  // cost group from mn_cost_group_history at the time of calculation
  @computed
  get costGroup() {
    const costHistory = $cost.costHistory.get(this.get('costHistoryIds')[0]);
    const { costGroup } = costHistory?.get('metadata') || {};
    return new CostGroupModel(costGroup);
  }

  // connectivity type from mn_cost_group_history otherwise mn_cost_group
  get connType() {
    return this.costGroup.get('type') || this.costGroupCurrent?.get('type');
  }

  @computed
  get provider() {
    // Since costGroup can return a bogus Model, we need to protect against it not having a provider_id
    const providerId = this.costGroup.get('provider_id');
    if (providerId) {
      return $cost.providers.get(providerId);
    }
    return null;
  }

  // cost group from mn_cost_group
  @computed
  get costGroupCurrent() {
    // Since costGroup can return a bogus Model, we need to protect against it not having an id
    // As well as this causing provider to be nullable now
    const costGroupId = this.costGroup.get('id');
    if (costGroupId) {
      return this.provider?.costGroups?.get(costGroupId);
    }
    return null;
  }

  @computed
  get daysRemaining() {
    return this.costGroup.daysRemaining;
  }
}

export default CostItemModel;
