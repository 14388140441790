import DeckFactory from 'app/stores/decks/DeckFactory';

export default function reconDeckFactory() {
  const deck = new DeckFactory('i_recon');
  const { widgets, layout, createWidget, newRow } = deck;

  const widgetSizing = { w: 4, h: 6, smallH: 4 };

  createWidget({
    name: 'hmbs',
    config: { title: 'Health Monitoring Breakdown', groupBy: 'none', interval: 60000 },
    w: 8,
    h: 6,
    smallH: 6
  });

  createWidget({
    name: 'reconDevicesAvailability',
    config: { title: 'Device Availability', lookback: 7 },
    ...widgetSizing,
    smallH: 6
  });

  newRow(6);

  createWidget({
    name: 'trafficOverview',
    ...widgetSizing,
    w: 6,
    h: 6,
    smallH: 6
  });

  createWidget({
    name: 'activeAlertsWidget',
    config: { groupBy: 'policyName' },
    ...widgetSizing,
    w: 6,
    smallH: 6
  });

  newRow(6);

  createWidget({
    name: 'reconInterfaceUtilizationWidget',
    ...widgetSizing,
    w: 6,
    h: 6
  });

  createWidget({
    name: 'reconCpuWidget',
    ...widgetSizing,
    w: 3
  });

  createWidget({
    name: 'reconMemWidget',
    ...widgetSizing,
    w: 3
  });

  return {
    widgets,
    layout
  };
}
