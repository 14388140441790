import Collection from 'core/model/Collection';
import CostHistoryModel from './CostHistoryModel';

class CostHistoryCollection extends Collection {
  get model() {
    return CostHistoryModel;
  }

  get url() {
    return '/api/ui/cost/history';
  }

  get defaultSortState() {
    return {
      field: 'start_date',
      direction: 'asc'
    };
  }

  get minFetchInterval() {
    return 60 * 60 * 1000;
  }

  populateCostGroups(providersCollection) {
    this.models.forEach((model) => {
      const interfaces = Object.values(model.get('metadata.results.interfaces') || []);
      const costGroupId = model.get('cost_group_id');

      model.costGroup.set({
        interfaces: interfaces.map((intf) => {
          const costGroup = providersCollection.costGroupsByIdMap[costGroupId];

          if (costGroup) {
            const costGroupIntf =
              costGroup.interfaces.find(
                // eslint-disable-next-line eqeqeq
                (cgIntf) => cgIntf.device_id == intf.device_id && cgIntf.snmp_id == intf.snmp_id
              ) || {};

            intf = { ...costGroupIntf, ...intf };
          }

          return intf;
        })
      });
    });
  }

  deserialize(data) {
    this.summaries = data.summaries;
    return data.history.map((historyData) => ({
      ...historyData,
      costGroup: historyData.end_date
        ? // use the historical cost group for older periods
          historyData.metadata.costGroup
        : // use current cost group for the current period but grab the provider object out of the meta group
          { ...historyData.costGroup, provider: historyData.metadata.costGroup.provider },
      interfaces: historyData.metadata.results.interfaces
    }));
  }
}

export default CostHistoryCollection;
