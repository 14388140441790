import { action, computed } from 'mobx';
import React from 'react';
import semver from 'semver';
import { capitalize, get, isEmpty } from 'lodash';

import { Flex, Text } from 'core/components';
import Model from 'core/model/Model';
import BrowserAgentIconUrl, { ReactComponent as BrowserAgentIcon } from 'app/assets/agents/browser_agent_icon.svg';
import GlobalAgentIconUrl, { ReactComponent as GlobalAgentIcon } from 'app/assets/agents/global_agent_icon.svg';
import PrivateAgentIconUrl, { ReactComponent as PrivateAgentIcon } from 'app/assets/agents/private_agent_icon.svg';
import { ReactComponent as AgentStatusNoUpgradeIcon } from 'app/assets/agents/agent-status-ok.svg';
import { ReactComponent as AgentStatusUpgradeAvailableIcon } from 'app/assets/agents/agent-status-upgrade.svg';
import { ReactComponent as AgentStatusWarningIcon } from 'app/assets/agents/agent-status-warning.svg';
import { ReactComponent as AgentStatusCriticalIcon } from 'app/assets/agents/agent-status-critical.svg';
import browserAgentIconPng from 'app/assets/agents/browser_agent_icon.png';
import globalAgentIconPng from 'app/assets/agents/global_agent_icon.png';
import privateAgentIconPng from 'app/assets/agents/private_agent_icon.png';
import { CLOUD_PROVIDERS } from 'app/util/constants';
import { AGENT_CAPABILITIES, AGENT_STATUSES, AGENT_TYPES } from 'shared/synthetics/constants';
import { isIpV4Valid, isIpV6Valid } from 'core/util/ip';

const MIN_OFFLINE_THRESHOLD = 4;
const UNKNOWN = 'Unknown';

const checkIPVersion = (ip) => ({
  isV4: isIpV4Valid(ip),
  isV6: isIpV6Valid(ip)
});

class AgentModel extends Model {
  get urlRoot() {
    return '/api/ui/synthetics/agents';
  }

  deserialize(data) {
    const { ip, local_ip, agent_family, metadata = {}, site = {} } = data;
    const privateIPv4 = get(metadata, 'private_ipv4_addresses[0].value');
    const privateIPv6 = get(metadata, 'private_ipv6_addresses[0].value');
    const publicIPv4 = get(metadata, 'public_ipv4_addresses[0].value');
    const publicIPv6 = get(metadata, 'public_ipv6_addresses[0].value');
    const showBothIPs = agent_family === 'DUAL';
    const showIPv4 = showBothIPs || agent_family === 'v4';
    const showIPv6 = showBothIPs || agent_family === 'v6';
    const ipVersion = checkIPVersion(ip);
    const localIPVersion = checkIPVersion(local_ip);
    const siteCountryCode = get(site, 'country');
    const siteRegion = get(site, 'region');

    if (!data.metadata) {
      data.metadata = {};
    }

    if (showIPv4) {
      if (!privateIPv4) {
        data.metadata.private_ipv4_addresses = [{ value: localIPVersion.isV4 ? local_ip : '' }];
      }

      if (!publicIPv4) {
        data.metadata.public_ipv4_addresses = [{ value: ipVersion.isV4 ? ip : '' }];
      }
    }

    if (showIPv6) {
      if (!privateIPv6) {
        data.metadata.private_ipv6_addresses = [{ value: localIPVersion.isV6 ? local_ip : '' }];
      }

      if (!publicIPv6) {
        data.metadata.public_ipv6_addresses = [{ value: ipVersion.isV6 ? ip : '' }];
      }
    }

    // Set proper location data
    // Uses site location data if it exists
    // Sometimes data.country is a country code
    this.countryLookup(!isEmpty(siteCountryCode) ? siteCountryCode : data.country).then((countryName) =>
      this.set('location_country', countryName)
    );

    data.location_region = siteRegion ?? data.region;

    if (data.agent_type === AGENT_TYPES.PRIVATE) {
      data.agent_alert_rule_status = !(!data.agent_alert_rule_status || data.agent_alert_rule_status === 'D');

      if (!data.agent_alert_rule_config) {
        data.agent_alert_rule_config = {
          thresholds: [
            {
              thresholdSeconds: 300,
              thresholdType: 'minutes'
            }
          ],
          alertingRuleId: ''
        };
      }

      if (!data.agent_alert_rule_config.thresholds[0].thresholdType) {
        data.agent_alert_rule_config.thresholds[0].thresholdType = 'minutes';
      }

      if (data.agent_alert_rule_config.thresholds[0].thresholdType === 'minutes') {
        data.agent_alert_rule_config.thresholds[0].thresholdSeconds /= 60;
      }
    }

    return data;
  }

  serialize(data) {
    if (data.agent_type === AGENT_TYPES.PRIVATE) {
      if (data.agent_alert_rule_config.thresholds[0].thresholdSeconds) {
        data.agent_alert_rule_config.thresholds[0].thresholdSeconds =
          +data.agent_alert_rule_config.thresholds[0].thresholdSeconds;
      }

      if (data.agent_alert_rule_config.thresholds[0].thresholdType === 'minutes') {
        data.agent_alert_rule_config.thresholds[0].thresholdSeconds *= 60;
      }

      data.agent_alert_rule_status = data.agent_alert_rule_status ? 'A' : 'D';
      data.agent_alert_rule_config.thresholds[0].severity = 'SEVERITY_CRITICAL';
    }

    return data;
  }

  @action
  async save(attributes = {}, options = {}) {
    const { sudoMode, ...baseSaveOptions } = options;
    if (sudoMode) {
      baseSaveOptions.url = `/api/ui/sudo/synthetics/agents/${this.id}`;
    }

    return super.save(attributes, baseSaveOptions).then((results) => {
      // refetch agents
      this.store.$syn.agents.fetch({ force: true });

      return results;
    });
  }

  @action
  async destroy(options = {}) {
    // only sudo user can delete global agents, attempting global agent delete on non-sudo api will fail (as designed).
    if (this.get('agent_type') === 'global') {
      options.url = `/api/ui/sudo/synthetics/agents/${this.id}`;
    }
    return super.destroy(options);
  }

  @action
  async getAgentDetails(id) {
    const { $syn } = this.store;
    return $syn.requests.getAgentDetails(id);
  }

  @action
  async migrateAgentTests({ old_agent, new_agent, replace_geo = false }) {
    const { $syn } = this.store;
    return $syn.requests.migrateAgentTests({ old_agent, new_agent, replace_geo });
  }

  @action
  async countryLookup(countryCode) {
    const { $lookups } = this.store;
    return $lookups.countries(countryCode).then((countries) => {
      const countryOption = countries.find((option) => option.value === countryCode);
      return countryOption ? countryOption.label : countryCode;
    });
  }

  @action
  setNotificationChannels() {
    const alert = this.get('agent_alert_rule_config', {});

    if (alert?.alertingRuleId) {
      const agentChannels = Object.keys(
        this.store.$notifications.collection.selectorChannels.alertmanRule?.[alert?.alertingRuleId] || {}
      );

      this.set('notificationChannels', agentChannels);
    }
  }

  @action
  fetchAgentContext() {
    const { $syn } = this.store;
    return $syn.requests.fetchAgentContext(this.id).then((context) => {
      this.set('agent_context', context);
    });
  }

  @computed
  get labels() {
    return this.store.$labels.getLabels('synth_agent', this.id);
  }

  @computed
  get hasSite() {
    return Boolean(this.get('site.lat') || this.get('site.lon'));
  }

  @computed
  get isActive() {
    return this.get('agent_status') === AGENT_STATUSES.OK;
  }

  // TODO: deprecate browserAgents filtering #7660
  @computed
  get isBrowser() {
    const metadata = this.get('metadata');
    return this.get('agent_type') === 'global' && metadata?.capabilities?.includes(AGENT_CAPABILITIES.WEB);
  }

  @computed
  get isGlobal() {
    const metadata = this.get('metadata');
    return this.get('agent_type') === 'global' && !metadata?.capabilities?.includes(AGENT_CAPABILITIES.WEB);
  }

  @computed
  get icon() {
    if (this.isBrowser) {
      return BrowserAgentIcon;
    }
    return this.isGlobal ? GlobalAgentIcon : PrivateAgentIcon;
  }

  @computed
  get iconPng() {
    if (this.isBrowser) {
      return browserAgentIconPng;
    }
    return this.isGlobal ? globalAgentIconPng : privateAgentIconPng;
  }

  @computed
  get iconSize() {
    return this.isGlobal ? 12 : 18;
  }

  @computed
  get iconUrl() {
    if (this.isBrowser) {
      return BrowserAgentIconUrl;
    }
    return this.get('agent_type') === 'global' ? GlobalAgentIconUrl : PrivateAgentIconUrl;
  }

  @computed
  get label() {
    return this.get('agent_alias') || `Agent ${this.id}`;
  }

  @computed
  get longLabel() {
    const title = this.get('agent_alias') || 'Unnamed';
    const site = this.get('site');
    const siteType = this.isGlobal ? 'Global Agent' : `Private Agent${site ? ` in ${site.title}` : ''}`;
    return `${title} (${siteType})`;
  }

  @computed
  get siteDisplayName() {
    const title = this.get('site.title');
    if (title) {
      return title;
    }
    const siteId = this.get('site_id');
    return siteId !== '0' ? siteId : '---';
  }

  @computed
  get hasSiteIps() {
    const type = this.get('agent_type');
    if (type === AGENT_TYPES.PRIVATE) {
      const metadata = this.get('site.metadata');
      if (metadata && metadata.classification) {
        return Object.values(metadata.classification).some((val) => val);
      }
    }
    return false;
  }

  @computed
  get numTests() {
    return (this.get('tests') || []).length;
  }

  @computed
  get isUpdateAvailable() {
    return this.isAgentUpdateAvailable(this.get('agent_version'));
  }

  @computed
  get semVersion() {
    return semver.valid(semver.coerce(this.get('agent_version'))) || '0.0.0';
  }

  @computed
  get asFormatted() {
    return `${this.get('as_name')} (${this.get('asn')})`;
  }

  @computed
  get isSyngest() {
    const metadata = this.get('metadata');
    return metadata?.capabilities?.includes(AGENT_CAPABILITIES.SYNGEST);
  }

  @computed
  get locationString() {
    const { city, region, country } = this.get();
    const citySite = this.get('site.city');
    const regionSite = this.get('site.region');
    const countrySite = this.get('site.country');
    const siteGeoString = [citySite, regionSite, countrySite].filter((item) => !!item);
    const geoString = [city, region, country].filter((item) => !!item);
    if (this.hasSite && siteGeoString.length > 0) {
      return siteGeoString.join(', ');
    }
    if (geoString.length > 0) {
      return geoString.join(', ');
    }
    return null;
  }

  @computed
  get city() {
    // Check for non-zero site id (deserialize defaults site to empty object so can't just check presence),
    // if site present, use site value, else fall back to agent value. Default to Unknown if falsy value.
    return (this.get('site.id') ? this.get('site.city') : this.get('city')) || UNKNOWN;
  }

  @computed
  get region() {
    // same logic as city
    return (this.get('site.id') ? this.get('site.region') : this.get('region')) || UNKNOWN;
  }

  @computed
  get country() {
    // deserialize already handles country site vs agent fallback
    // differently and does lookup,so need to modify logic a bit to
    // ensure not mixing and matching site/agent values
    return (this.get('site.id') && !this.get('site.country') ? UNKNOWN : this.get('location_country')) || UNKNOWN;
  }

  @computed
  get geoCoords() {
    const lat = this.get('lat');
    const lon = this.get('long');
    return this.hasSite ? { lat: this.get('site.lat'), lon: this.get('site.lon') } : { lat, lon };
  }

  @computed
  get locationInfo() {
    const { cloud_provider, cloud_region } = this.get('metadata') || {};
    if (cloud_provider) {
      return (
        <Flex alignItems="center">
          {CLOUD_PROVIDERS.byId(cloud_provider).logo}
          <Text as="div" ml="4px">
            {cloud_region}
          </Text>
        </Flex>
      );
    }

    if (this.get('agent_type') === 'global') {
      return this.asFormatted;
    }

    // In the future, it'd be better to use our current 'private' icon for sites
    const site_name = this.get('site.title');
    return !site_name || site_name === '---' ? this.asFormatted : site_name;
  }

  @computed
  get cloudRegion() {
    const { cloud_provider, cloud_region } = this.get('metadata') || {};

    if (cloud_provider) {
      const cloudRegions = this.store.$dictionary.getCloudMetadataKeyToNameMap(cloud_provider);
      if (cloudRegions[cloud_region]) {
        return `${cloud_region} (${cloudRegions[cloud_region]})`;
      }
    }

    return '---';
  }

  @computed
  get status() {
    const agent_status = this.get('agent_status');
    const last_seen = this.get('last_seen');
    const agent_type = this.get('agent_type');
    const health = this.get('health');

    let icon;
    let color;
    let status;
    let offline = false;

    // Global agent status only shown to sudoers (when not spoofed!), wait to show status until have stats
    if (agent_type === 'global' && !this.store.$auth.hasSudo) {
      return null;
    }

    const minutesOffline = last_seen ? Math.round((this.lastAgentFetch / 1000 - last_seen) / 60) : undefined;
    const offlineExceedsThreshold = typeof minutesOffline !== 'undefined' && minutesOffline > MIN_OFFLINE_THRESHOLD;

    // Only show minutesOffline message below if it's over MIN_OFFLINE_THRESHOLD, could be in
    // non-healthy state for some other reason, (failed tests, etc. It's determined by backend at this point)
    if (agent_status === AGENT_STATUSES.D) {
      color = 'danger';
      status = 'Deleted';
    } else if (agent_status === AGENT_STATUSES.WAIT) {
      status = 'Pending';
    } else if (health === 'critical') {
      status = offlineExceedsThreshold ? `Offline for ${minutesOffline} minutes` : `${capitalize(health)}`;
      color = 'danger';
      icon = AgentStatusCriticalIcon;
    } else if (health === 'warning') {
      status = offlineExceedsThreshold ? `Offline for ${minutesOffline} minutes` : `${capitalize(health)}`;
      color = 'warning';
      icon = AgentStatusWarningIcon;
    } else if (health === 'healthy') {
      if (this.isUpdateAvailable) {
        status = 'Update Available';
        icon = AgentStatusUpgradeAvailableIcon;
      } else {
        status = 'Active';
        icon = AgentStatusNoUpgradeIcon;
      }
    } else {
      offline = true;
      status = 'Offline'; // previously "Unknown"
      color = 'danger'; // previously "warning"
      icon = AgentStatusCriticalIcon; // previously AgentStatusWarningIcon
    }

    return { offline: offline || offlineExceedsThreshold, color, icon, text: `Agent Status: ${status}` };
  }

  @computed
  get ips() {
    const ipList = []
      .concat(
        this.get('ip'),
        this.get('local_ip'),
        this.get('metadata.private_ipv4_addresses', []),
        this.get('metadata.private_ipv6_addresses', []),
        this.get('metadata.public_ipv4_addresses', []),
        this.get('metadata.public_ipv6_addresses', [])
      )
      .map((ip) => {
        if (typeof ip === 'string') {
          return ip;
        }

        return ip?.value;
      })
      .filter((ip) => !!ip); // ips in metadata are defined in a 'value' property that can be blank so filter them out

    return Array.from(new Set(ipList));
  }

  @computed
  get locationInfoValue() {
    const { cloud_provider, cloud_region } = this.get('metadata') || {};
    if (cloud_provider) {
      return cloud_region;
    }

    if (this.get('agent_type') === 'global') {
      return this.asFormatted;
    }

    // In the future, it'd be better to use our current 'private' icon for sites
    const site_name = this.get('site.title');
    return !site_name || site_name === '---' ? this.asFormatted : site_name;
  }

  // used when searching collections for agent data
  @computed
  get filterValue() {
    const { cloud_provider } = this.get('metadata');
    return `${this.label} ${this.locationInfoValue} ${cloud_provider}`;
  }

  getSortValue(field) {
    if (field === 'semVersion') {
      // hacky way to make semver string sort work for collections
      return this.semVersion
        .split('.')
        .map((verNum) => +verNum + 100000)
        .join('.');
    }

    if (field === 'locationInfo') {
      return (this.locationInfoValue || '').toLowerCase();
    }

    return super.getSortValue(field);
  }

  isAgentUpdateAvailable(agentVersion) {
    const { $syn } = this.store;
    const agentSemver = semver.valid(semver.coerce(agentVersion));
    return agentSemver ? semver.lt(agentSemver, $syn.plan.latestAgentVersion) : false;
  }

  get canEdit() {
    const { $auth } = this.store;
    return !!(
      $auth.hasRbacPermissions(['synthetics.agent::update']) &&
      ($auth.hasSudo || $auth.isSpoofed || this.get('agent_type') === AGENT_TYPES.PRIVATE)
    );
  }

  get canDelete() {
    const { $auth } = this.store;
    return !!(
      $auth.hasRbacPermissions(['synthetics.agent::delete']) &&
      ($auth.hasSudo || $auth.isSpoofed || this.get('agent_type') === AGENT_TYPES.PRIVATE)
    );
  }
}

export default AgentModel;
