import $auth from 'app/stores/$auth';
import Collection from 'core/model/Collection';
import { computed } from 'mobx';
import RbacRoleModel from './RbacRoleModel';

export default class RbacRoleCollection extends Collection {
  groupBy = 'company_id';

  get defaultSortState() {
    return {
      field: 'company_id',
      direction: 'desc'
    };
  }

  get url() {
    return '/api/ui/rbac/roles';
  }

  get model() {
    return RbacRoleModel;
  }

  @computed
  get permissionsWithLabels() {
    return this.reduce((acc, role) => {
      role.get('permissions').forEach((permission) => {
        if (!acc[permission]) {
          acc[permission] = [];
        }
        acc[permission].push({
          role_id: role.id,
          role_name: role.get('role_name'),
          permission,
          label_metadata: role.get('label_metadata')[permission]
        });
      });
      return acc;
    }, {});
  }

  async fetch(options) {
    if (!$auth.hasRbacPermissions(['rbac.role::read'])) {
      this.lastFetched = Date.now();
      this.hasFetched = true;

      return Promise.resolve();
    }

    return super.fetch(options);
  }
}
