import React from 'react';
import moment from 'moment';
import Model from 'core/model/Model';
import { formatDate } from 'core/util/dateUtils';
import $devices from 'app/stores/device/$devices';
import $kproxyAgents from 'app/stores/kproxyAgent/$kproxyAgents';

// Found in https://github.com/kentik/chf/tags
const versionMap = {
  d9d0625d99348f002a7cee7dbe93458012dd7c61: '7.50', // Wed, 05 Feb 2025
  '30326df8c3aaea90c1d04d3c85e3f73ccf9ddc2c': '7.49', // Fri, 10 Jan 2025
  d12218520e8b772c7c703cf8905952cd8fe45049: '7.48', // Mon, 23 Sep 2024
  e98c6e2bb1ec675ef07ad1672bc27f79ffcadbaa: '7.47', // Thu, 20 Jun 2024
  '7cf99320adcf64b4d690eb48a7c06a5f3f644dfc': '7.46', // Tue, 06 Jun 2024
  cea5cac0256e10d5635169d7e275b4be766dbaa4: '7.45', // Mon, 20 May 2024
  '5da1f9cc4c1bf78a3856a528f747a76e27e35e44': '7.44', // Tue, 13 Feb 2024
  a6c083e1b5a7669c6de2dc4ceabfe38f05313174: '7.43', // Fri, 09 Feb 2024
  e12760287688b3b5fd706d740d0ee9648aa6e427: '7.42', // Tue, 02 Jan 2024
  dd6cf3125f93df146c2f6d8539e40b49b21119a7: '7.41', // Thu, 19 Oct 2023
  deedb7cada6fe9e062e0b073c11aa003901e2168: '7.40', // Thu, 17 Aug 2023
  '7e6d5714052b5ff536bc4a6716925c8bf61c9668': '7.39', // Wed, 26 Jul 2023
  c03087b8d6e506a80febe02150893fb8eebb25b4: '7.38', // Mon, 13 Feb 2023
  '7bceccd84c3e5cd268ae49f4aa247e1d0ed24e63': '7.37', // Thu, 10 Nov 2022
  '3bb2dc88f141eb2121bf8c4c1dd32b1a12a1d1e7': '7.36', // Thu, 25 Aug 2022
  d130af1ee2b2b012e978c8a8da6cc00e55c500cf: '7.35', // Wed, 3 Aug 2022
  cf546ed24afbb0349ecc4b181de402db5fddd556: '7.34', // Fri, 29 Jul 2022
  '520ae188047f57795728056c79099ce406083760': '7.33', // Wed, 11 May 2022
  '9d3aadde2ce3616aeb664d59a132cfcc3724d39a': '7.32', // Fri, 4 Mar 2022
  '70363dfb51ba28de129d803a53c7e49130ee9bee': '7.31', // Thu, 9 Sep 2021
  ddfe0dd5b30574783e2883e92adf822a704bb905: '7.30', // Mon, 30 Aug 2021
  '7490cb0507ba3a28a08a72fcd374d864cbc523ea': '7.29', // Tue, 12 Jan 2021
  dfd3c34bc551a0064bef2644928d5649596d4e3e: '7.28', // Tue, 15 Dec 2020
  ae4d20a3ffbd95b320b275271aa50419adc14e0c: '7.27', // Fri, 20 Nov 2020
  c7f723ed85fa1fb912e14bf6d12cdffaea6407fc: '7.26', // Wed, 28 Oct 2020
  f2497c737fe7fd32f2f4566c8c64a49544a0552a: '7.25', // Wed, 9 Sep 2020
  bf0769173736564966ba5a47e707cecacc3aae6f: '7.24', // Thu, 3 Sep 2020
  '1d53fd3d74c4d5d880387214d9e1d647f893d08d': '7.22', // Fri, 31 Jul 2020
  '878ce2d512aaad9890b799ab61b048f53bb8f7c1': '7.21', // Thu, 28 May 2020
  '069c35e82356bc64d06b1f5470a18cdae081cb47': '7.20', // Thu, 21 May 2020
  '44cc94009d0e4f03e2a9a9e70a80af2f708be7f8': '7.19', // Thu, 30 Apr 2020
  '0ab7fb1d5e04264a9cf141b4728e6fcf356b99bb': '7.18', // Fri, 17 Apr 2020
  ba30c176a8b83fdf4533648820091d96879f3773: '7.17', // Sun, 12 Apr 2020
  '3e3ce061aa1666de539094a43f0cf86825e82d4d': '7.16', // Thu, 9 Apr 2020
  '1.0.200408.522.67b814b944.master': '7.14', // Wed, 8 Apr 2020
  'dirty-eb9a47b2b1fb6b33cc57c722772075538448c2a0': '7.11', // Fri, 13 Mar 2020
  '46dd56cd6cbbf7eecb0ef6c28fe4f9763f755b19': '7.10', // Wed, 29 Jan 2020
  '3ea1cdd706e3df94a836347158107040bfaea106': '7.9', // Fri, 20 Dec 2019
  '573f197ce208caefd9b827200f7f56475cb637a1': '7.8', // Tue, 26 Nov 2019
  '96d91ca7dc88c11f569a5871cb4d7cfaaccaaf5d': '7.7', // Fri, 22 Nov 2019
  '0845eb8731b256daf3cb4adccb12fb9b37712c59': '7.6', // Thu, 24 Oct 2019
  a2ea87408375e7c891e086fb2c62dd3c04c96b2f: '7.5' // Thu, 3 Oct 2019
};

export default class KproxyAgentModel extends Model {
  constructor(attributes) {
    super(attributes || {});

    if (attributes && !attributes.user_id) {
      $kproxyAgents.fetchKproxyUser().then((user) => {
        this.set('user_id', user.id);
      });
    }
  }

  get urlRoot() {
    return '/api/ui/kproxyAgent';
  }

  get defaults() {
    return {
      agent_version: '',
      devices: []
    };
  }

  defaultName = 'Auto Generated';

  get messages() {
    return {
      create: `kproxy Agent ${this.get('agent_name')} was updated successfully`,
      update: `kproxy Agent ${this.get('agent_name')} was updated successfully`,
      destroy: `kproxy Agent ${this.get('agent_name')} was removed successfully`
    };
  }

  get removalConfirmText() {
    return {
      title: 'Remove kproxy',
      text: (
        <p>
          Are you sure you want to remove <strong>{this.get('agent_name')}?</strong> Note: This only removes the
          registration and not the kproxy itself.
        </p>
      )
    };
  }

  get siteName() {
    const site = this.get('site');
    return site ? site.title : '';
  }

  get deviceNames() {
    const devices = this.get('devices') || [];
    return devices.map((device) => device.device_name);
  }

  get agentUserName() {
    const agentUser = this.get('agentUser');
    return agentUser ? agentUser.user_full_name : 'Unknown';
  }

  get agentUserEmail() {
    const agentUser = this.get('agentUser');
    return agentUser ? agentUser.user_email : 'Unknown';
  }

  get needsUpdate() {
    return !this.get('agent_private_ip') || !this.get('site_id') || this.get('agent_name') === this.defaultName;
  }

  getVersionInfo(agent_version, agent_built_on) {
    if (agent_version && agent_built_on) {
      return versionMap[agent_version] || `${agent_version.substr(0, 7)} ${formatDate(agent_built_on, 'YYYY-MMM')}`;
    }

    return 'Unknown';
  }

  getHealth(last_access, agent_ip) {
    const minutesAgo = moment.utc().diff(last_access, 'minutes');

    if (!agent_ip) {
      return 'Pending';
    }

    if (minutesAgo <= 30) {
      return 'Healthy';
    }

    if (minutesAgo <= 45) {
      return 'Warning';
    }

    return 'Critical';
  }

  deserialize(data = {}) {
    const { agent_ip, agent_private_ip, agent_built_on, last_access, site_id } = data;
    let { agent_name, agent_version, devices } = data;

    agent_name = agent_name || this.defaultName;
    agent_version = agent_version || '';
    devices = devices || [];

    const version = this.getVersionInfo(agent_version, agent_built_on);
    const health = this.getHealth(last_access, agent_ip);
    const title = `${agent_name} ${agent_ip || ''}${agent_private_ip ? ` (${agent_private_ip})` : ''}${
      version === 'Unknown' ? '' : ` v${version}`
    }`;

    return {
      ...data,
      agent_name,
      agent_version,
      devices: $devices.deviceSummaries.filter((d) => devices.includes(d.id)),
      site_id: parseInt(site_id, 10),
      title,
      version,
      health
    };
  }
}
