import { computed, observable } from 'mobx';

import { pick, uniq } from 'lodash';
import Collection from 'core/model/Collection';
import { isInSubnet as isInSubnetTest } from 'core/util/ip';
import { addCustomPropertiesToEntity, getCustomProperties } from 'shared/util/map';
import ip from 'app/forms/validations/ip';

import CloudMapModel from './CloudMapModel';

class CloudMapCollection extends Collection {
  // entity types that we want to be hydrated from models
  // these are the entity types that will participate in search
  collectionManagedEntityTypes = [];

  // paths to data in an entity that we want classified as a searchable id in the map
  searchableIdPaths = [];

  // paths to data in an entity that we want classified as a searchable cidr in the map
  searchableCIDRPaths = [];

  @observable.ref
  initialTopology = { Hierarchy: {}, Entities: {}, Links: [], traffic: [] };

  get model() {
    return CloudMapModel;
  }

  @computed
  get hasDefaultNetworks() {
    return false;
  }

  /*
    An inherited collection will take the filterState string and expect a comma-delimited string of search terms
    From this list of search terms, logic should be run on each to determine which group that id belongs to

    These are then used to form discrete filters in the 'filter' method override
  */
  @computed
  get filterStateGroups() {
    return {
      accountIds: [],
      ids: [],
      cidrs: [],
      tags: []
    };
  }

  /*
    This is generally what the UI is driven from.
    At a minimum it will contain a copy of the initial topology response
  */
  @computed
  get topology() {
    return Object.assign({}, this.initialTopology);
  }

  /*
    determine whether the entity type is managed by the collection (should be hydrated by a model)
    if it's not managed by the collection, we can hydrate it from the topology metadata
    it's important to hydrate certain entity types from collection models so we can have them participate in searching
  */
  isEntityTypeCollectionManaged(entityType) {
    return this.collectionManagedEntityTypes.includes(entityType);
  }

  // used during filtering that helps determine whether a search term should be placed in the 'cidr' group
  isValidIP(value) {
    return ip.fn(value);
  }

  // used during filtering along with isValidIP above
  isInSubnet(target, cidrSubnet) {
    return isInSubnetTest(target, cidrSubnet);
  }

  /**
   * @param {string|string[]} entityTypes
   * @returns {Object[]}
   */
  getEntities(entityTypes) {
    const { Entities } = this.initialTopology;
    const types = Array.isArray(entityTypes) ? entityTypes : [entityTypes];
    return types.flatMap((type) => Object.values(Entities[type] || {}));
  }

  // Queries the Entities reference set of data for an entity details
  getEntity({ entityType, entityId }) {
    const { Entities } = this.initialTopology;
    const referenceEntity = Object.assign({}, Entities[entityType]?.[entityId]);

    if (entityType === 'locations') {
      // locations are a special case where we know we don't want to send back their 'actual' id
      // this id contains subscriptionIds in the uri but we are fine with just using the name as a key
      const { id, ...restLocation } = referenceEntity;
      return restLocation;
    }

    return referenceEntity;
  }

  get Entities() {
    return this.initialTopology?.Entities ?? {};
  }

  getEntitiesByType(entityType) {
    return Object.values(this.Entities[entityType] ?? {});
  }

  get entityTypesToIgnoreInHydration() {
    return [];
  }

  // takes an entity, usually a fragment of an entity from the hierarchy and merges its full details from either a matching model, or the Entities reference data
  hydrateEntity({ entity, entityType, entityId }) {
    if (this.isEntityTypeCollectionManaged(entityType)) {
      const foundModel = this.models.find((model) => model.id === entityId);

      return foundModel
        ? addCustomPropertiesToEntity({ entity: foundModel.get(), customProperties: { entityType } })
        : null;
    }

    return Object.assign(
      {},
      entity,
      addCustomPropertiesToEntity({
        entity: this.getEntity({ entityType, entityId }),
        customProperties: { entityType }
      })
    );
  }

  /*
    The entity will come in with an 'id' property and optional children of the form:

    {
      id: <PARENT_ENTITY_ID>,
      <CHILD_ENTITY>: [
        { id: <CHILD_ENTITY_ID_1> },
        { id: <CHILD_ENTITY_ID_2> }
        ...
      ]
    }

    This function will recurse over this and hydrate any of the entities it finds
  */
  hydrateHierarchy({ entity, entityType, as = 'list' }) {
    // initialize a fully hydrated entity
    const hydratedEntity = this.hydrateEntity({ entity, entityType, entityId: entity.id });

    if (hydratedEntity) {
      // determine the child entities we need to hydrate (everything that's not the id of the parent)
      const { id, ...childEntities } = entity;

      // get the list of child types
      const childEntityTypes = Object.keys(childEntities);

      // hydrate the children down the tree
      return childEntityTypes.reduce((acc, childEntityType) => {
        if (this.entityTypesToIgnoreInHydration?.includes(childEntityType)) {
          return acc;
        }
        const children = childEntities[childEntityType];

        if (!Array.isArray(children)) {
          // pass the children through, there's nothing to hydrate
          return {
            ...acc,
            [childEntityType]: children
          };
        }

        if (as === 'map') {
          // aws currently wants to use maps
          return {
            ...acc,
            [childEntityType]: children.reduce((childAcc, childEntity) => {
              const hydratedChild = this.hydrateHierarchy({ entity: childEntity, entityType: childEntityType, as });

              return {
                ...childAcc,
                [childEntity.id]: hydratedChild
              };
            }, {})
          };
        }

        // default moving forward is lists, this is what azure uses
        return {
          ...acc,
          [childEntityType]: children
            .map((childEntity) => this.hydrateHierarchy({ entity: childEntity, entityType: childEntityType, as }))
            .filter((child) => child !== null)
        };
      }, hydratedEntity);
    }

    return null;
  }

  // expands a link segment into a list of segments, using the optional fallbackNodes
  // fallback nodes are used to enable drawing link paths when the direct target isn't rendered
  // the most prominent place where this happens is in search when the view is filtered
  expandLinkSegmentItem(item) {
    const { id, type, fallbackNodes = [] } = item;

    if (fallbackNodes.length > 0) {
      return fallbackNodes.map((fb) => ({ id: fb.value, type: fb.type })).concat({ id, type });
    }

    return [{ id, type }];
  }

  // helper that takes in a map of children, keyed by entity type and flattens them into a single list
  flattenChildren({ childEntities }) {
    return Object.keys(childEntities).reduce((acc, childEntityType) => {
      if (Array.isArray(childEntities[childEntityType])) {
        return acc.concat(
          childEntities[childEntityType].reduce((childAcc, child) => {
            const { id, ...nextChildEntities } = child;
            let nextChildren = [];

            if (Object.keys(nextChildEntities).length > 0) {
              nextChildren = this.flattenChildren({ childEntities: nextChildEntities });
            }

            return childAcc.concat({ ...child, entityType: childEntityType }, nextChildren);
          }, [])
        );
      }

      return acc;
    }, []);
  }

  // gets the searchable data from the children of an entity
  // this is so when we search for child items such as a NAT gateway inside a virtual network, the virtual network is returned in the search
  getSearchableData({ items, type = 'id', clean = false }) {
    return items.reduce((data, { id: entityId, entityType }) => {
      const entity = this.getEntity({ entityId, entityType });
      const searchableData = entity ? [...data, ...this.getSearchableDataFromEntity({ entity, type })] : data;

      if (clean === true) {
        return this.cleanSearchableData(searchableData);
      }

      return searchableData;
    }, []);
  }

  cleanSearchableData(data = []) {
    return uniq(data).filter((item) => item !== undefined && item !== '0.0.0.0/0');
  }

  getSearchableCIDRList() {
    // eslint-disable-next-line no-console
    console.warn('The searchable CIDR post-processor is not implemented in the cloud map collection');
    return [];
  }

  /*
    supports two types of searchable data to be harvested from an entity (id, cidr)
    id data is just strings and can be passed through as a list
    cidr data isn't as simple and requires cloud provider-specific post-processing
  */
  getSearchableDataFromEntity({ entity, type = 'id', clean = false }) {
    const pickPaths = type === 'id' ? this.searchableIdPaths : this.searchableCIDRPaths;
    const data = pick(entity, pickPaths);
    const searchableData = type === 'cidr' ? this.getSearchableCIDRList(data) : Object.values(data);

    if (clean === true) {
      return this.cleanSearchableData(searchableData);
    }

    // no special handling required, just pass on as a list of ids
    return searchableData;
  }

  addSearchableDataFromHierarchyToModels({ entities, models }) {
    // add searchable data found from the hierarchy
    entities.forEach((entity) => {
      // get the current entity id and child entities
      const { id, entityType, ...childEntities } = entity;

      // get a single list of the entity's children
      const children = this.flattenChildren({ childEntities });
      // check if the entity in the hierarchy is a model in the collection
      const entityModel = models[id];

      if (children.length > 0) {
        if (entityModel) {
          const customProperties = getCustomProperties(entityModel);

          // if the hierarchy entity is a model in the collection, we want to harvest the child searchable ids
          models[id] = addCustomPropertiesToEntity({
            entity: entityModel,
            customProperties: {
              searchableIds: [
                ...(customProperties.searchableIds || []),
                ...this.getSearchableData({ items: children, type: 'id' })
              ],
              searchableCIDRs: [
                ...(customProperties.searchableCIDRs || []),
                ...this.getSearchableData({ items: children, type: 'cidr' })
              ]
            }
          });
        } else {
          // otherwise, continue on and process the rest of the hierarchy
          this.addSearchableDataFromHierarchyToModels({ entities: children, models });
        }
      }
    });

    return models;
  }

  // decorates a list of models using a nested list of entities from the hierarchy
  addSearchableDataToModels({ entities, models }) {
    // make a map from the models and initialize the searchable ids list with properties from itself
    let modelsById = models.reduce((acc, model) => {
      if (model) {
        return {
          ...acc,
          [model.id]: addCustomPropertiesToEntity({
            entity: model,
            customProperties: {
              searchableIds: this.getSearchableDataFromEntity({ entity: model, type: 'id' }),
              searchableCIDRs: this.getSearchableDataFromEntity({ entity: model, type: 'cidr' })
            }
          })
        };
      }

      return acc;
    }, {});

    // decorate the models with searchable data discovered from the hierarchy and links
    modelsById = this.addSearchableDataFromHierarchyToModels({ entities, models: modelsById });

    // return a list of models with clean, unique searchable ids and cidrs
    return Object.values(modelsById).map((model) => {
      const customProperties = getCustomProperties(model);

      return addCustomPropertiesToEntity({
        entity: model,
        customProperties: {
          searchableIds: this.cleanSearchableData(customProperties.searchableIds || []),
          searchableCIDRs: this.cleanSearchableData(customProperties.searchableCIDRs || [])
        }
      });
    });
  }

  /*
    Returns a complete list of all Entities in the reference map for the given type 'entityType', decorating it with:

    - an optionally overridden id using the 'entityId' arg
    - an 'entityType' which directly relates the model to an entity type
  */
  getActiveEntities({ entityType, entityId = 'id', allowUnlinked = false }) {
    const { Entities } = this.initialTopology;
    const entityTypeList = Object.values(Entities[entityType] || {});
    const entities = entityTypeList.map((node) => Object.assign({}, node, { id: node[entityId], entityType }));

    if (allowUnlinked) {
      return entities;
    }

    return entities.filter(this.filterToLinked(entityType));
  }

  // returns a filter function that can test for ids that are represented in the map
  filterToLinked(type) {
    const { Links } = this.initialTopology;
    const ids = new Set();

    Links.forEach((segments) => {
      segments.forEach(({ source, target }) => {
        if (source.type === type) {
          ids.add(source.id);
        }

        if (target.type === type) {
          ids.add(target.id);
        }
      });
    });

    return (node) => ids.has(node.id);
  }

  // return a list of models by entity type such as 'vnets'
  getModelsByEntityType(entityType) {
    return this.models.filter((model) => model.get('entityType') === entityType).map((model) => model.get());
  }

  // return a list of models matched from a list of ids
  getMatchingModels(ids) {
    if (ids) {
      return ids.reduce((acc, id) => {
        const matchedModel = this.models.find((m) => m.id === id);

        if (matchedModel) {
          return acc.concat(matchedModel.get());
        }

        return acc;
      }, []);
    }

    return [];
  }
}

export default CloudMapCollection;
