import { action, computed, observable, toJS } from 'mobx';
import { get, set, sortBy } from 'lodash';

import { showErrorToast, showSuccessToast } from 'core/components/toast';
import api from 'core/util/api';
import safelyParseJSON from 'core/util/safelyParseJson';
import getPortalVersion from 'core/util/getPortalVersion';

export class AppStore {
  renderPromise = Promise.resolve();

  routeHistory = [];

  @observable
  helpText = false;

  @observable
  helpButtonSource = false;

  @observable
  showHelp = false;

  @observable
  searchIsOpen = false;

  @observable
  showMainNav = true;

  @observable
  themeSetting = 'auto';

  @observable
  darkThemeEnabled = false;

  @observable
  reportIssuePopoverOpen = false;

  @observable
  reportIssuePopoverProps = {};

  @observable
  sendingIssueRequest = false;

  @observable
  isExport = false;

  @observable
  isSubtenant = false;

  @observable
  isSharedLink = false;

  @observable
  debugModeOpen = false;

  @observable
  debugModeEnabled = false;

  @observable
  debugModeInfo = null;

  @observable
  showFullScreen = false;

  @observable
  unreadProductUpdates = 0;

  @observable
  version;

  @observable
  productArea;

  @observable
  isReleaseCandidate = false;

  constructor() {
    if (window.localStorage) {
      this.showFullScreen = safelyParseJSON(localStorage.getItem('showFullScreen')) || false;
    }
  }

  initialize() {
    getPortalVersion().then(({ latestVersion }) => {
      if (latestVersion) {
        const { uiapp } = latestVersion;

        this.isReleaseCandidate = uiapp.release?.isReleaseCandidate;
        this.version = uiapp.release?.version;
      }
    });

    // need to references $auth.userSettings.setup where the initial value loads from /whoami or /login
    // can't turn darkThemeEnabled into @computed referencing $setup.settings value.
    // Seems to cause $setup store problem.
    if (!this.store.$auth.isSpoofed) {
      this.darkThemeEnabled = this.store.$auth.userSettings.setup?.darkThemeEnabled;
      if (window.localStorage) {
        window.localStorage.setItem('darkThemeEnabled', this.store.$auth.userSettings.setup?.darkThemeEnabled);
      }
    } else {
      this.darkThemeEnabled = safelyParseJSON(localStorage.getItem('darkThemeEnabled')) || false;
    }

    if (this.store.$auth.hasSudo || this.store.$auth.isSpoofed) {
      window.toJS = toJS;
    }
  }

  @action
  destroy() {
    // Clean up when completed
    this.darkThemeEnabled = false;
    this.showFullScreen = false;
  }

  get isNext() {
    return window.location.href.startsWith('https://next');
  }

  renderSync(fn) {
    this.renderPromise = this.renderPromise.then(
      () =>
        new Promise((resolve) => {
          setTimeout(() => {
            try {
              fn();
            } catch (e) {
              console.warn('Error occurred in renderSync', e, e && e.message, e && e.stack);
              resolve();
            }
            setTimeout(resolve, this.isExport ? 10 : 300);
          }, 0);
        })
    );
  }

  openReleaseNotes = () => {
    window.open(
      `https://github.com/kentik/ui-app/releases/tag/v${this.version}${this.isReleaseCandidate ? '-rc' : ''}`,
      '_blank'
    );
  };

  @action
  openHelp = (buttonSource) => {
    this.showHelp = true;
    this.helpButtonSource = buttonSource;
  };

  @action
  toggleSearch = (method) => {
    const isOpening = !this.searchIsOpen;
    if (isOpening) {
      this.store.$auth.track('Global Search Summon', {
        method
      });
    }
    this.searchIsOpen = isOpening;
  };

  @action
  showSearch = () => {
    this.searchIsOpen = true;
  };

  @action
  hideSearch = () => {
    this.searchIsOpen = false;
  };

  @action
  loadHelp = (text) => {
    this.helpText = text;
  };

  @action
  closeHelp = () => {
    this.showHelp = false;
  };

  @action
  toggleFullScreen = () => {
    this.showFullScreen = !this.showFullScreen;
    if (window.localStorage) {
      localStorage.setItem('showFullScreen', this.showFullScreen);
    }
  };

  @action
  setReportIssuePopoverPropsAndOpen = (mergeProps) => {
    this.reportIssuePopoverProps = Object.assign({}, this.reportIssuePopoverProps, mergeProps);
    this.toggleReportIssuePopover(true);
  };

  @action
  toggleReportIssuePopover = (forceState) => {
    this.sendingIssueRequest = false;
    this.reportIssuePopoverOpen = forceState !== undefined ? forceState : !this.reportIssuePopoverOpen;
    if (!this.reportIssuePopoverOpen) {
      this.reportIssuePopoverProps = {};
    }
  };

  @action
  submitReportIssue = (data, isOnboardingHelp = false) => {
    this.sendingIssueRequest = true;
    return api
      .post('/api/ui/support/issue', { data })
      .then((res) => {
        this.sendingIssueRequest = false;
        if (!isOnboardingHelp) {
          this.toggleReportIssuePopover();
          showSuccessToast('Feedback was submitted successfully. Thanks!');
        }
        return res;
      })
      .catch((err) => {
        this.sendingIssueRequest = false;
        if (!isOnboardingHelp) {
          showErrorToast('Something went wrong sending your feedback. Please try again.');
        }
        return err;
      });
  };

  @action
  toggleDarkTheme = (opts = {}) => {
    const { persist = true, forceTheme = false } = opts;
    if (!forceTheme) {
      this.darkThemeEnabled = !this.darkThemeEnabled;
    } else {
      if (this.store.$auth.activeUser) {
        this.store.$auth.activeUser.themeSetting = forceTheme;
      }

      if (forceTheme === 'auto') {
        this.darkThemeEnabled = this.systemDarkThemeEnabled;
      } else if (forceTheme === 'light') {
        this.darkThemeEnabled = false;
      } else if (forceTheme === 'dark') {
        this.darkThemeEnabled = true;
      }
    }
    this.store.$setup.settings.darkThemeEnabled = this.darkThemeEnabled;

    // save dark theme setting to userSetting setup.
    if (persist && !this.store.$auth.isSpoofed) {
      this.store.$setup.updateSettings({ darkThemeEnabled: this.darkThemeEnabled });
      const themeColor = this.darkThemeEnabled ? 'dark' : 'light';
      this.store.$users.updateUserProfile({ themeSetting: forceTheme || themeColor }, { toast: false });

      if (window.localStorage) {
        window.localStorage.setItem('darkThemeEnabled', this.darkThemeEnabled);
      }
    }
  };

  @action
  setIsExport(isExport) {
    this.isExport = isExport;
  }

  @action
  setIsSubtenant(isSubtenant) {
    this.isSubtenant = isSubtenant;
  }

  @action
  setIsSharedLink(isSharedLink) {
    this.isSharedLink = isSharedLink;
  }

  // simple way to set values through an action.
  @action
  set = (key, value) => {
    set(this, key, value);
  };

  @action
  toggleLoginInterstitial = () => {
    this.loginInterstitialOpen = !this.loginInterstitialOpen;
  };

  @action
  setDebugModeOpen = (open) => {
    this.debugModeOpen = open;
  };

  @action
  toggleDebugMode = () => {
    if (this.debugModeEnabled) {
      this.debugModeEnabled = false;
    } else {
      this.setDebugModeOpen(true);
    }
  };

  @action
  enableDebugMode = ({ info }) => {
    this.debugModeInfo = info;
    this.debugModeEnabled = true;
    this.setDebugModeOpen(false);
  };

  setPageTitle(pageTitle, docTitle) {
    const appName = get(this.store.$auth.openConfig, 'subtenancy.config.branding.appName') || 'Kentik v4';
    const title = docTitle || pageTitle || appName;
    document.title = title === appName ? title : `${title} · ${appName}`;
  }

  @computed
  get systemDarkThemeEnabled() {
    return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
  }

  @computed
  get isDarkTheme() {
    return this.store.$auth.activeUser?.themeSetting === 'auto' ? this.systemDarkThemeEnabled : this.darkThemeEnabled;
  }

  @computed
  get appName() {
    const { subtenancy } = this.store.$auth.openConfig;

    if (subtenancy) {
      return get(subtenancy, 'config.branding.appName') || 'My Kentik Portal';
    }

    return 'Kentik';
  }

  /*
   * Custom views are dashboards and saved views merged together and sorted
   */
  @computed
  get customViews() {
    const dashboards = this.store.$dashboards.collection.get().filter((dashboard) => !dashboard.isPreset);
    const savedViews = this.store.$savedViews.collection.get().filter((item) => !item.isPreset);
    const customViews = [...dashboards, ...savedViews].map((view) => {
      const shareLevel = view.get('share_level');

      // dashboard 'org' and saved view 'company' are 'shared views' share levels in the app
      if (shareLevel === 'org' || shareLevel === 'company') {
        view.appShareLevel = 'shared';
      }

      // dashboard 'self' and saved view 'user' are 'my views' share levels in the app
      if (shareLevel === 'self' || shareLevel === 'user') {
        view.appShareLevel = 'my';
      }

      return view;
    });

    return sortBy(customViews, (view) => view.get('dash_title') || view.get('view_name'));
  }
}

export default new AppStore();
