import { action, computed, observable } from 'mobx';
import api from 'core/util/api';

class SearchStore {
  @observable searchLoading = false;

  @observable.ref results = undefined;

  @observable queryString = '';

  formatQueryString(queryString) {
    return encodeURIComponent(queryString.replace(/\s+/g, ' '));
  }

  flattenResults(results) {
    const newResults = [];

    Object.keys(results).forEach((key) => {
      if (Array.isArray(results[key])) {
        if (key === 'costGroups' && this.store.$cost.canViewCosts) {
          newResults.push(
            ...results[key].map((resultRow) => ({
              category: key,
              ...resultRow
            }))
          );
        } else {
          newResults.push(
            ...results[key].map((resultRow) => ({
              category: key,
              ...resultRow
            }))
          );
        }
      }
    });

    return newResults;
  }

  @computed
  get totalResults() {
    const items = this.results ? Object.keys(this.results) : [];

    return items.reduce(
      (total, item) => total + (Array.isArray(this.results[item]) ? this.results[item].length : 0),
      0
    );
  }

  getSortedResults(results) {
    return this.flattenResults(results).sort((rowA, rowB) => (rowA.score < rowB.score ? 1 : -1));
  }

  @action
  query(queryString) {
    this.searchLoading = true;
    this.queryString = queryString;

    return api.get(`/api/ui/search?q=${this.formatQueryString(queryString)}`).then(
      (response) => {
        this.searchLoading = false;
        this.results = response;

        return response;
      },
      () => {
        this.searchLoading = false;
        this.results = {};
      }
    );
  }
}

export default new SearchStore();
