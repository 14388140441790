import { orderBy, get } from 'lodash';

import api from 'core/util/api';

const filterableFields = ['device_name', 'interface_description', 'site_name', 'snmp_alias', 'snmp_id'];

class TrafficStore {
  interfaceUtilization;

  getDeviceInterface(device_name, snmp_id) {
    const data = [{ device_name, snmp_id }];
    return api.post('/api/ui/lookups/deviceInterfaces', { data });
  }

  augmentInterface(data, siteMap) {
    const siteMatch = siteMap[get(data, 'dimensionToValue.device_id')];

    const { snmpIfHCInOctetsP95bps, inUtilizationP95, snmpIfHCOutOctetsP95bps, outUtilizationP95 } = get(
      data,
      'metricToValue',
      {}
    );
    const augmentData = {
      bps_in: snmpIfHCInOctetsP95bps,
      util_in: inUtilizationP95,
      bps_out: snmpIfHCOutOctetsP95bps,
      util_out: outUtilizationP95,
      site_name: siteMatch ? siteMatch.get('title') : ''
    };

    return Object.assign({}, data, augmentData);
  }

  augmentInterfaces(interfaces, sites, options) {
    const { asMap } = options || {};
    const siteMap = sites.reduce((acc, site) => {
      site.devices.forEach((device) => {
        acc[device.id] = site;
      });
      return acc;
    }, {});

    interfaces = !Array.isArray(interfaces) ? Object.values(interfaces) : interfaces;
    if (asMap) {
      return interfaces.reduce((acc, data) => {
        const key = `${get(data, 'dimensionToValue.device_id')}|${get(data, 'dimensionToValue.snmp_id')}`;
        acc[key] = this.augmentInterface(data, siteMap);
        return acc;
      }, {});
    }
    return interfaces.map((data) => this.augmentInterface(data, siteMap));
  }

  async getInterfaceUtilizationData(options) {
    const { asMap, filter, limit } = options || {};
    const { $sites } = this.store;

    return (
      this.interfaceUtilization
        ? Promise.resolve([this.interfaceUtilization])
        : Promise.all([
            api.get(`/api/ui/traffic-eng/interfaceUtilizationData${limit ? `?limit=${limit}` : ''}`),
            $sites.collection.fetch()
          ])
    ).then(([interfaces]) => {
      const sites = $sites.collection.models;
      this.interfaceUtilization = this.augmentInterfaces(interfaces, sites, { asMap });
      if (filter) {
        const filterLower = filter.toLowerCase();
        return this.interfaceUtilization.filter((intf) =>
          filterableFields.some((fieldName) => intf[fieldName].toString().toLowerCase().includes(filterLower))
        );
      }
      return this.interfaceUtilization;
    });
  }

  /**
   * @deprecated Use getInterfaceUtilizationData for any new code
   */
  async getInterfaceUtilization(filter) {
    const { $sites } = this.store;

    if (!this.interfaceUtilization) {
      // ideally we'd like to get site_name as part of the interface utilization response
      // but for now, load the sites collection and patch in
      return $sites.collection.fetch().then(() => {
        const sites = $sites.collection.models;
        return api.get('/api/ui/traffic-eng/interfaceUtilization').then((interfaceUtilization) => {
          this.interfaceUtilization = orderBy(interfaceUtilization, ['util_out'], ['desc']).map((data) => {
            const siteMatch = sites.find((site) => {
              const siteDevices = site.devices;
              return siteDevices.find((device) => device.id === data.device_id);
            });

            if (siteMatch) {
              return {
                ...data,
                site_name: siteMatch.get('title')
              };
            }

            return data;
          });

          return this.interfaceUtilization;
        });
      });
    }

    if (filter) {
      const filterLower = filter.toLowerCase();
      return this.interfaceUtilization.filter((intf) =>
        filterableFields.some((fieldName) => intf[fieldName].toString().toLowerCase().includes(filterLower))
      );
    }

    return this.interfaceUtilization;
  }

  destroy() {
    this.interfaceUtilization = undefined;
  }
}

export default new TrafficStore();
