import Collection from 'core/model/Collection';
import CostSnapshotModel from './CostSnapshotModel';

class CostSnapshotsCollection extends Collection {
  get model() {
    return CostSnapshotModel;
  }

  get url() {
    return '/api/ui/cost/snapshot';
  }

  get defaultSortState() {
    return {
      field: 'cost',
      direction: 'desc'
    };
  }
}

export default CostSnapshotsCollection;
