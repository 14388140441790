import { computed } from 'mobx';
import { SYN_CONSTANTS } from 'shared/synthetics/constants';
import { isDev } from 'core/util/env';
import requests from './requests';
import AgentCollection from './AgentCollection';
import WorkerAgentCollection from './WorkerAgentCollection';
import TestCollection from './TestCollection';
import CommunityTestCollection from './CommunityTestCollection';
import PlanModel from './PlanModel';

class SynStore {
  requests = requests;

  agents = null;

  tests = null;

  communityPerformanceTests = null;

  plan = null;

  workerAgents = null;

  initialize() {
    const { $app, $auth } = this.store;
    this.agents = new AgentCollection([], { store: this.store });
    this.tests = new TestCollection([], { store: this.store });
    this.communityPerformanceTests = new CommunityTestCollection([], { store: this.store });
    this.plan = new PlanModel({ store: this.store });
    this.workerAgents = new WorkerAgentCollection([], { store: this.store });

    // Emburse
    if ($auth.activeUser?.company_id === '180629') {
      SYN_CONSTANTS.httpExpiryMaxMs = 120e3; // 2 mins
    }

    if ($app.isSharedLink || $auth.getActiveUserProperty('company.company_status') === 'V') {
      return this.tests.fetch();
    }

    return Promise.resolve();
  }

  @computed
  get presetsUiEnabled() {
    return (
      isDev ||
      ['US-SaaS', 'EU-SaaS', 'EMEA-SaaS', 'OurPrem1', 'LOCALVMPortal'].includes(this.store.$dictionary.get('envName'))
    );
  }

  // determine whether we are in an environment that can support bgp whether that be route viewer or bgp tests
  @computed
  get bgpEnabled() {
    return (
      isDev ||
      ['US-SaaS', 'OurPrem1', 'LOCALVMPortal'].includes(this.store.$dictionary.get('envName')) ||
      this.bgpProxyEnabled
    );
  }

  @computed
  get bgpProxyEnabled() {
    return this.store.$dictionary.get('enableSynthBgpProxy');
  }

  // determines whether we have the environment suitable for bgp AND the permission (feature flag) to use it
  @computed
  get bgpFeatureEnabled() {
    return this.bgpEnabled && this.store.$auth.hasPermission('synthetics.bgpTests.enabled', { overrideForSudo: false });
  }
}

export default new SynStore();
