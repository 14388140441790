import Model from 'core/model/Model';

class ChannelModel extends Model {
  get urlRoot() {
    return '/api/ui/notifications/supported';
  }

  get id() {
    return this.has('channelType') ? this.get('channelType') : this.optimisticId;
  }

  get(attribute) {
    if (attribute === 'id') {
      return this.id;
    }

    return super.get(attribute);
  }

  deserialize(data = {}) {
    if (data.channelType === 'custom-webhook') {
      data.requiredFields.splice(1, 0, {
        path: 'sendingConfig.customHeaders'
      });
    }

    return super.deserialize(data);
  }
}

export default ChannelModel;
