import PolicyCollection from '../PolicyCollection';
import EventPolicyModel from './EventPolicyModel';

class EventPolicyCollection extends PolicyCollection {
  get url() {
    return '/api/ui/alerting/policies/kevent';
  }

  get model() {
    return EventPolicyModel;
  }
}

export default EventPolicyCollection;
