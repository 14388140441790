import { action, observable, toJS } from 'mobx';
import { isEqual } from 'lodash';
import Collection from 'core/model/Collection';
import api from 'core/util/api';
import $query from 'app/stores/query/$query';
import {
  ALL_INTERFACES_BITRATE_QUERY,
  ALL_INTERFACES_ERRORS_DISCARDS_QUERY,
  ALL_INTERFACES_UTILIZATION_QUERY
} from 'app/views/metrics/queries';
import MetricInterfaceModel from './MetricInterfaceModel';

const LIMIT = 1000;

class MetricInterfaceCollection extends Collection {
  constructor(data = [], options = {}) {
    super(data, { ...options });
    Object.assign(this, options);
  }

  serverSortableFields = [
    'id',
    'admin_status',
    'oper_status',
    'name',
    'description',
    'device',
    'speed',
    'index',
    'ipv4addresses',
    'ipv6addresses',
    'physical_address',
    'type'
  ];

  @observable.ref
  serverFilter = { limit: LIMIT };

  @observable.ref
  totalCount = 0;

  @observable.ref
  errorsDiscardQueryData;

  @observable.ref
  utilizationQueryData;

  get defaultSortState() {
    return {
      field: 'name',
      direction: 'asc'
    };
  }

  get updateInterval() {
    return 3000; // 3s
  }

  get secondarySort() {
    return this.defaultSortState;
  }

  get url() {
    return '/api/ui/recon/interfaces';
  }

  get queuedFetchKey() {
    return this.id;
  }

  get fetchMethod() {
    return 'post';
  }

  get model() {
    return MetricInterfaceModel;
  }

  @action
  clearFilters() {
    this.serverFilter = { limit: LIMIT };
    super.clearFilters();
    this.queuedFetch();
  }

  async fetch(options = {}) {
    return super.fetch(this.getFetchOptions(options)).then(() => {
      this.setUtilizations();
      this.setErrorsDiscards();
    });
  }

  async queuedFetch(options = {}) {
    return super.queuedFetch(this.getFetchOptions(options)).then(() => {
      this.setUtilizations();
      this.setErrorsDiscards();
    });
  }

  getFetchOptions(options = {}) {
    const { data = {} } = options;
    const sortBy = this.getServerSortBy();

    return {
      ...options,
      data: {
        sortBy: sortBy || undefined,
        ...this.getServerFilter(),
        ...data
      }
    };
  }

  @action
  async fetchUtilizations() {
    return Promise.all([
      $query.runQuery(this.parent?.interfacesUtilizationQuery || ALL_INTERFACES_UTILIZATION_QUERY),
      $query.runQuery(this.parent?.interfacesBitrateQuery || ALL_INTERFACES_BITRATE_QUERY)
    ]).then(([{ results: utilizationResults }, { results: bitrateResults }]) => {
      this.utilizationQueryData = {};

      // Turn into a hash for quick lookups
      utilizationResults.toJS().forEach(({ ifindex, device_name, IfInUtilization, IfOutUtilization }) => {
        const key = `${ifindex}|${device_name}`;
        this.utilizationQueryData[key] ??= {};
        Object.assign(this.utilizationQueryData[key], { IfInUtilization, IfOutUtilization });
      });
      bitrateResults.toJS().forEach(({ ifindex, device_name, IfInBitRate, IfOutBitRate }) => {
        const key = `${ifindex}|${device_name}`;
        this.utilizationQueryData[key] ??= {};
        Object.assign(this.utilizationQueryData[key], { IfInBitRate, IfOutBitRate });
      });

      this.setUtilizations();
    });
  }

  setUtilizations = () => {
    if (!this.utilizationQueryData) {
      return;
    }

    this.models.forEach((model) => {
      const interfaceKey = `${model.get('index')}|${model.get('device.name')}`;
      const resultsMatch = this.utilizationQueryData[interfaceKey];

      if (resultsMatch) {
        model.set(resultsMatch);
      }
    });
  };

  @action
  async fetchErrorsDiscards() {
    return $query
      .runQuery(this.parent ? this.parent.errorsDiscardsQuery : ALL_INTERFACES_ERRORS_DISCARDS_QUERY)
      .then(({ results }) => {
        this.errorsDiscardQueryData = {};
        const rawResults = results.toJS();

        rawResults.forEach(({ ifindex, device_name, IfInErrors, IfOutErrors, IfInDiscards, IfOutDiscards }) => {
          this.errorsDiscardQueryData[`${ifindex}|${device_name}`] = {
            IfInErrors,
            IfOutErrors,
            IfInDiscards,
            IfOutDiscards
          };
        });

        this.setErrorsDiscards();
      });
  }

  setErrorsDiscards = () => {
    if (!this.errorsDiscardQueryData) {
      return;
    }

    this.models.forEach((model) => {
      const interfaceKey = `${model.get('index')}|${model.get('device.name')}`;
      const resultsMatch = this.errorsDiscardQueryData[interfaceKey];

      if (resultsMatch) {
        model.set(resultsMatch);
      }
    });
  };

  @action
  fetchUpdatedOnly = () => {
    if (this.requestStatus || !this.lastFetched) {
      // skip when already fetching or hasn't been fetched yet
      return Promise.resolve();
    }

    this.setRequestStatus('fetchingMore');
    const updated = new Date(this.lastFetched).toISOString();

    return api.post('/api/ui/recon/interfaces', this.getFetchOptions({ data: { updated } })).then(
      action(({ models }) => {
        models.forEach((result) => {
          const intf = this.get(result.id);
          if (intf) {
            // since `Model.replace` doesn't do what we want, iterate over the keys in the result and do it manually
            Object.keys(result).forEach((key) => {
              intf.set(key, result[key]);
            });
          } else {
            this.add(result);
          }
        });

        // wait half a second to set the request status to null so that the loading indicator doesn't flash
        setTimeout(() => {
          this.setRequestStatus(null);
        }, 500);

        if (models.length > 0) {
          // TODO: I didn't test this case, but I think we need to actually fetch again in this case
          this.setErrorsDiscards();
          this.setUtilizations();
        }

        this.setLastFetched();
      })
    );
  };

  getServerFilter() {
    const serverFilter = toJS(this.serverFilter);
    if (this.parent?.id) {
      serverFilter.device_id = [this.parent?.id];
    }
    return toJS(this.serverFilter);
  }

  @action
  setServerFilter(filter) {
    if (isEqual(filter, toJS(this.serverFilter))) {
      return Promise.resolve();
    }

    this.serverFilter = { ...filter, limit: LIMIT };
    return this.queuedFetch();
  }

  deserialize(data = {}) {
    const { models, metadata = {} } = data;
    this.totalCount = metadata.totalCount;

    return super.deserialize(models);
  }
}

export default MetricInterfaceCollection;
