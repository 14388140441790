import Collection from 'core/model/Collection';
import { POLICY_APPLICATIONS } from 'shared/alerting/constants';
import PolicyLibraryModel from './PolicyLibraryModel';
import $metrics from '../metrics/$metrics';

// TODO NMS POLICY - if we get templates for nms application, this will need to be updated
class PolicyLibraryCollection extends Collection {
  get url() {
    return '/api/ui/alerting/policy-templates';
  }

  get model() {
    return PolicyLibraryModel;
  }

  deserialize(templates) {
    const filteredTemplates = templates.filter(
      // Exclude NMS templates if there are no available metrics
      (template) => $metrics.availableMetricsCollection.size > 0 || template.application !== POLICY_APPLICATIONS.METRIC
    );

    return super.deserialize(filteredTemplates);
  }
}

export default PolicyLibraryCollection;
