import { api } from 'core/util';

class LoggingStore {
  dataviewEvent(type, startTime, dataview) {
    const requestIDs = dataview?.queryBuckets?.map((bucket) => bucket.sockets.map((socket) => socket.id))?.flat() ?? [];
    if (requestIDs.length > 0) {
      this.log(`dataview_${type}`, { requestIDs, startTime });
    }
  }

  log(event, logData, level = 'INFO') {
    api.post('/api/ui/logging', { data: { event, logData, level } }).catch((response) => {
      console.warn('Error in UI logging process: ', response);
    });
  }
}

export default new LoggingStore();
