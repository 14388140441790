import Model from 'core/model/Model';
import api from 'core/util/api';

import { showSuccessToast } from 'core/components/toast';

class CostGroupModel extends Model {
  get urlRoot() {
    return '/api/ui/cost/snapshot';
  }

  setMonthly(enable) {
    return api
      .post(`${this.url}/monthly`, {
        data: {
          enable
        }
      })
      .then((snapshot) => {
        showSuccessToast(`Snapshot monthly tracking ${enable ? 'enabled' : 'disabled'}`);
        this.set(snapshot);
        return this;
      });
  }
}

export default CostGroupModel;
