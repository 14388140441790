import Collection from 'core/model/Collection';
import CompanyNoteModel from 'app/stores/sudo/CompanyNoteModel';

export default class CompanyNoteCollection extends Collection {
  get model() {
    return CompanyNoteModel;
  }

  get url() {
    return '/api/ui/sudo/notes';
  }
}
