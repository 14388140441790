import $auth from 'app/stores/$auth';
import { Collection } from 'core/model';
import RbacRoleSetModel from './RbacRoleSetModel';

export default class RbacRoleSetCollection extends Collection {
  get defaultSortState() {
    return {
      field: 'name',
      direction: 'asc'
    };
  }

  get url() {
    return '/api/ui/rbac/role-sets';
  }

  get model() {
    return RbacRoleSetModel;
  }

  async fetch(options) {
    if (!$auth.hasRbacPermissions(['rbac.role::read'])) {
      this.lastFetched = Date.now();
      this.hasFetched = true;

      return Promise.resolve();
    }

    return super.fetch(options);
  }
}
