import { computed, observable } from 'mobx';
import { flatten, uniq } from 'lodash';

import Collection from 'core/model/Collection';

import DeviceModel from './DeviceModel';

class DeviceCollection extends Collection {
  @observable.ref
  memoryUtilQueryData = [];

  @observable.ref
  cpuUtilQueryData = [];

  @observable.ref
  temperatureQueryData = [];

  @observable.ref
  trafficQueryData = [];

  get defaultSortState() {
    return {
      field: 'device_name',
      direction: 'asc'
    };
  }

  get secondarySort() {
    return this.defaultSortState;
  }

  get url() {
    return '/api/ui/devices';
  }

  get model() {
    return DeviceModel;
  }

  useAsyncAdd = true;

  get presetGroups() {
    return [
      {
        name: 'site_name',
        label: 'Site',
        sortBy: 'site_name',
        icon: 'map-marker'
      },
      {
        name: 'plan_id',
        label: 'Plan',
        icon: 'card_membership',
        materialIcon: true
      },
      {
        name: 'deviceType',
        label: 'Type',
        icon: 'dev-router-plain'
      },
      {
        name: 'appliedLabels',
        label: 'Label',
        sortBy: 'appliedLabels',
        icon: 'tag'
      }
    ];
  }

  getFilterValues(model) {
    return [
      'id',
      'device_name',
      'site_name',
      'flowType',
      'deviceType',
      'sending_ips',
      'appliedLabels',
      'device_manufacturer',
      'flowSource'
    ].map((name) => model[name] || model.get(name));
  }

  @computed
  get percentUnlabeled() {
    return this.isRequestActive('fetching') ? 0 : Math.floor((this.unlabeledDevices.length / this.size) * 100);
  }

  @computed
  get unlabeledDevices() {
    return this.models.filter((device) => !device.hasLabels);
  }

  @computed
  get deviceIdNameHash() {
    const hash = {};
    this.models.forEach((device) => {
      hash[device.id] = device.get('device_name');
    });
    return hash;
  }

  @computed
  get deviceNameIdHash() {
    const hash = {};
    this.models.forEach((device) => {
      hash[device.get('device_name')] = device.id;
    });
    return hash;
  }

  // returns a unique array of label names for the selected models
  @computed
  get selectedLabelNames() {
    if (!this.selected || !this.selected.map) {
      return [];
    }

    const labels = flatten(this.selected.map((m) => m.get('labels').map((l) => l.name)));
    return uniq(labels);
  }

  getLinkedDeviceModel(model) {
    if (model.get('device_bgp_type') === 'other_device') {
      return this.models.find((item) => item.get('id') === model.get('use_bgp_device_id').toString());
    }

    return null;
  }

  setMemoryUtilQueryData = () => {
    this.memoryUtilQueryData.forEach((result) => {
      const device = this.get(result.km_device_id);
      if (device && device.get('memory_util') !== result.last_memory_util) {
        device.set('memory_util', result.last_memory_util);
      }
      if (device && device.get('memory_util_timeseries') !== result.sparklineData) {
        device.set('memory_util_timeseries', result.sparklineData);
      }
    });
  };

  setCpuUtilQueryData = () => {
    this.cpuUtilQueryData.forEach((result) => {
      const device = this.get(result.km_device_id);
      if (device && device.get('cpu_util') !== result.last_cpu_util) {
        device.set('cpu_util', result.last_cpu_util);
      }
      if (device && device.get('cpu_util_timeseries') !== result.sparklineData) {
        device.set('cpu_util_timeseries', result.sparklineData);
      }
    });
  };

  setTemperatureQueryData = () => {
    this.temperatureQueryData.forEach((result) => {
      const device = this.get(result.km_device_id);
      if (device) {
        device.set('temperature', {
          avg_instant: result.avg_instant,
          max_instant: result.max_instant,
          last_instant: result.last_instant
        });
      }
      if (device && device.get('temperature_timeseries') !== result.sparklineData) {
        device.set('temperature_timeseries', result.sparklineData);
      }
    });
  };

  setTrafficQueryData = () => {
    const { deviceNameIdHash } = this;
    this.trafficQueryData.forEach((result) => {
      const id = deviceNameIdHash[result.i_device_name];
      const device = id ? this.get(id) : null;
      if (device && device.get('traffic') !== result.f_sum_both_bytes__k_last) {
        device.set('traffic', result.f_sum_both_bytes__k_last);
      }
      if (device && device.get('traffic_timeseries') !== result.sparklineData) {
        device.set('traffic_timeseries', result.sparklineData);
      }
    });
  };
}

export default DeviceCollection;
