import { buildFilterGroup } from 'core/util/filters';

import getQueryFilterGroups from './getQueryFilterGroups';

export default function getBaseQuery({ queryFilters, asn, metric = 'Traffic', direction, useAsPathFilter = true }) {
  const filterDirection = direction || 'src|dst';
  const lookback = queryFilters.get('lookback');

  return {
    all_devices: true,
    aggregateTypes: ['avg_bits_per_sec'],
    show_total_overlay: false,
    show_overlay: false,
    lookback_seconds: lookback * 86400,
    metric,
    viz_type: 'line',
    filters: {
      connector: 'All',
      filterGroups: [
        buildFilterGroup({
          filters: [
            {
              filterField: `${filterDirection}_${useAsPathFilter ? 'bgp_aspath' : 'as'}`,
              filterValue: asn,
              operator: useAsPathFilter ? '~' : '='
            },
            {
              filterField: `i_${filterDirection}_network_bndry_name`,
              operator: '=',
              filterValue: 'external'
            }
          ]
        }),
        ...getQueryFilterGroups(queryFilters, direction)
      ]
    }
  };
}
