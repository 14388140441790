import Collection from 'core/model/Collection';
import CustomerModel from './CustomerModel';

class CustomerCollection extends Collection {
  get url() {
    return '/api/ui/customers';
  }

  get model() {
    return CustomerModel;
  }
}

export default CustomerCollection;
