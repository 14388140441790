import { observable, action, computed, toJS } from 'mobx';
import { isEqual, max } from 'lodash';
import Collection from 'core/model/Collection';
import api from 'core/util/api';
import MetricDeviceModel from './MetricDeviceModel';

const LIMIT = 10000;

class MetricDeviceCollection extends Collection {
  @observable.ref
  serverFilter = { limit: LIMIT };

  @observable.ref
  memoryUtilQueryData = [];

  @observable.ref
  cpuUtilQueryData = [];

  @observable.ref
  temperatureQueryData = [];

  @observable.ref
  totalCount = 0;

  serverSortableFields = [
    'id',
    'status',
    'name',
    'labels',
    'ip_address',
    'location',
    'site.title',
    'sys_object_id',
    'system_description',
    'vendor',
    'model',
    'product_name',
    'os_name',
    'os_version',
    'serial_number',
    'monitoring_template_id'
  ];

  get queuedFetchKey() {
    return this.id;
  }

  get defaultSortState() {
    return {
      field: 'name',
      direction: 'asc'
    };
  }

  get updateInterval() {
    return 3000; // 3s
  }

  get secondarySort() {
    return this.defaultSortState;
  }

  get url() {
    return '/api/ui/recon/devices';
  }

  get fetchMethod() {
    return 'post';
  }

  get model() {
    return MetricDeviceModel;
  }

  @action
  clearFilters() {
    this.serverFilter = { limit: LIMIT };
    super.clearFilters();
    this.queuedFetch();
  }

  setMemoryUtilQueryData = () => {
    this.memoryUtilQueryData.forEach((result) => {
      const device = this.get(result.km_device_id);
      if (device && device.get('memory_util') !== result.last_memory_util) {
        device.set('memory_util', result.last_memory_util);
      }
      if (device && device.get('memory_util_timeseries') !== result.sparklineData) {
        device.set('memory_util_timeseries', result.sparklineData);
      }
    });
  };

  setCpuUtilQueryData = () => {
    this.cpuUtilQueryData.forEach((result) => {
      const device = this.get(result.km_device_id);
      if (device && device.get('cpu_util') !== result.last_cpu_util) {
        device.set('cpu_util', result.last_cpu_util);
      }
      if (device && device.get('cpu_util_timeseries') !== result.sparklineData) {
        device.set('cpu_util_timeseries', result.sparklineData);
      }
    });
  };

  setTemperatureQueryData = () => {
    this.temperatureQueryData.forEach((result) => {
      const device = this.get(result.km_device_id);
      if (device) {
        device.set('temperature', {
          avg_instant: result.avg_instant,
          max_instant: result.max_instant,
          last_instant: result.last_instant
        });
      }
      if (device && device.get('temperature_timeseries') !== result.sparklineData) {
        device.set('temperature_timeseries', result.sparklineData);
      }
    });
  };

  async fetch(options = {}) {
    return super.fetch(this.getFetchOptions(options));
  }

  async queuedFetch(options = {}) {
    return super.queuedFetch(this.getFetchOptions(options));
  }

  getFetchOptions(options = {}) {
    const { data = {} } = options;
    const sortBy = this.getServerSortBy();

    return {
      ...options,
      data: {
        sortBy: sortBy || undefined,
        ...this.getServerFilter(),
        ...data
      }
    };
  }

  getServerFilter() {
    return toJS(this.serverFilter);
  }

  @action
  setServerFilter(filter) {
    if (isEqual(filter, toJS(this.serverFilter))) {
      return Promise.resolve();
    }

    this.serverFilter = { ...filter, limit: LIMIT };

    return this.queuedFetch().then(() => {
      this.setCpuUtilQueryData();
      this.setMemoryUtilQueryData();
    });
  }

  @action
  fetchUpdatedOnly = () => {
    if (this.requestStatus || !this.lastFetched) {
      // skip when already fetching or hasn't been fetched yet
      return Promise.resolve();
    }

    this.setRequestStatus('fetchingMore');
    const updated = new Date(this.lastFetched).toISOString();

    return api.post('/api/ui/recon/devices', this.getFetchOptions({ data: { updated } })).then(
      action(({ models }) => {
        models.forEach((result) => {
          const device = this.get(result.id);
          if (device) {
            // since `Model.replace` doesn't do what we want, iterate over the keys in the result and do it manually
            Object.keys(result).forEach((key) => {
              device.set(key, result[key]);
            });
          } else {
            this.add(result);
          }
        });

        // wait half a second to set the request status to null so that the loading indicator doesn't flash
        setTimeout(() => {
          this.setRequestStatus(null);
        }, 500);

        const latestEdate = max(models.map((m) => m.edate));
        this.setLastFetched(Date.parse(latestEdate) + 1 || this.lastFetched);
      })
    );
  };

  @computed get idToNameMap() {
    const map = {};

    this.models.forEach((model) => {
      map[model.id] = model.get('name');
    });

    return map;
  }

  deserialize(data = {}) {
    const { models, metadata = {} } = data;
    if (metadata.totalCount) {
      this.totalCount = metadata.totalCount;
    }
    return super.deserialize(models);
  }
}

export default MetricDeviceCollection;
