import { computed } from 'mobx';
import { FiUserPlus, FiCompass } from 'react-icons/fi';
import { MdCompareArrows } from 'react-icons/md';
import { GiRadarSweep } from 'react-icons/gi';

import Model from 'core/model/Model';
import $auth from 'app/stores/$auth';
import $devices from 'app/stores/device/$devices';
import $metrics from 'app/stores/metrics/$metrics';
import $syn from 'app/stores/synthetics/$syn';
import { CLOUD_PROVIDERS } from 'app/util/constants';

const baseSetupTasks = {
  invite: {
    text: 'Invite your team',
    icon: FiUserPlus,
    url: '/v4/setup/invite'
  },
  device: {
    text: 'Connect a router',
    icon: MdCompareArrows,
    url: '/v4/setup/device'
  },
  nms: {
    text: 'Setup NMS',
    icon: 'shapes',
    url: '/v4/setup/nms'
  },
  'network-class': {
    text: 'Define Network Boundaries',
    icon: FiCompass,
    url: '/v4/setup/network-class'
  },
  'synth-test': {
    text: 'Start Performance Monitoring',
    icon: GiRadarSweep,
    url: '/v4/setup/synth-test'
  },
  'synth-agent': {
    text: 'Deploy a Synthetic Agent',
    icon: GiRadarSweep,
    url: '/v4/setup/synth-agent'
  },
  oci: {
    text: 'Monitor OCI Cloud',
    icon: CLOUD_PROVIDERS.OCI.logo,
    url: `/v4/setup/clouds/v1/${CLOUD_PROVIDERS.OCI.id}`
  },
  aws: {
    text: 'Monitor AWS Cloud',
    icon: CLOUD_PROVIDERS.AWS.logo,
    url: `/v4/setup/clouds/v1/${CLOUD_PROVIDERS.AWS.id}`
  },
  gcp: {
    text: 'Monitor Google Cloud',
    icon: CLOUD_PROVIDERS.GCP.logo,
    url: `/v4/setup/clouds/v1/${CLOUD_PROVIDERS.GCE.id}`
  },
  azure: {
    text: 'Monitor Azure Cloud',
    icon: CLOUD_PROVIDERS.AZURE.logo,
    url: `/v4/setup/clouds/v1/${CLOUD_PROVIDERS.AZURE.id}`
  }
};

class SetupTaskModel extends Model {
  get urlRoot() {
    return '/api/ui/setup/tasks';
  }

  @computed
  get title() {
    const task = baseSetupTasks[this.get('type')];
    return task && task.text;
  }

  @computed
  get icon() {
    const task = baseSetupTasks[this.get('type')];
    return task && task.icon;
  }

  @computed
  get navigationUrl() {
    const task = baseSetupTasks[this.get('type')];
    return task && task.url;
  }

  @computed
  get completed() {
    const taskType = this.get('type');

    return (
      this.get('completed') ||
      (taskType === 'device' && $devices.nonCloudDeviceCount > 0 && $devices.nonNMSDeviceCount > 0) ||
      (taskType === 'nms' && $metrics.deviceCollection.size > 0) ||
      (taskType === 'synth-test' && !!$syn.tests.unfilteredSize) ||
      (taskType === 'network-class' && $auth.getActiveUserProperty('company.network_classification_established')) ||
      (taskType === 'invite' && $auth.getActiveUserProperty('company.userCount') > 1) ||
      (taskType === 'aws' && $devices.hasAWSDevice) ||
      (taskType === 'gcp' && $devices.hasGCEDevice) ||
      (taskType === 'oci' && $devices.hasOCIDevice) ||
      (taskType === 'azure' && $devices.hasAzureDevice)
    );
  }
}

export default SetupTaskModel;
