import { isEqual, omit } from 'lodash';
import { FILTER_CONNECTOR_ALL, FILTER_CONNECTOR_ANY } from 'app/stores/query/constants';

/**
 * I will take a query and add filters to it, merged said filters with existing filters
 *
 * @param query - A serialized query, not a QueryModel
 * @param filters - an array of filters, not a filters_obj
 * @param [connector] {string}
 * @param [filterGroups] {string[]}
 * @param [avoidDuplicates] {boolean} - indicates whether or not duplicate filters are allowed when considering to add new ones
 */
export const addFilters = (query, filters, connector = FILTER_CONNECTOR_ALL, filterGroups = [], avoidDuplicates) => {
  if (!query.filters) {
    query.filters = {
      connector: FILTER_CONNECTOR_ALL,
      filterGroups: []
    };
  }

  if (query.filters.connector === FILTER_CONNECTOR_ANY) {
    query.filters.filterGroups = [
      {
        connector: FILTER_CONNECTOR_ANY,
        filterGroups: [...query.filters.filterGroups],
        filters: []
      }
    ];
    query.filters.connector = FILTER_CONNECTOR_ALL;
  }

  if (
    avoidDuplicates &&
    query.filters.filterGroups.find((filterGroup) =>
      isEqual(omit(filterGroup, ['name', 'named', 'autoAdded']), {
        connector,
        filters,
        not: false,
        filterGroups,
        saved_filters: []
      })
    )
  ) {
    // sometimes when a query is re-run (switching in-between tabs, for instance - ddos factors)
    // query dimension filters can end up re-added as duplicates .. we don't want that.
    return;
  }

  query.filters.filterGroups.push({
    connector,
    filters,
    not: false,
    named: false,
    name: '',
    filterGroups,
    saved_filters: [],
    autoAdded: true
  });
};

export const removeFilters = (filters, query) => {
  if (query.aggregateFilters) {
    return {
      ...query,
      aggregateFilters: query.aggregateFilters.filter((item) => !filters.includes(item.name.toLowerCase()))
    };
  }

  if (query.filterDimensions && query.filterDimensions.filterGroups) {
    return {
      ...query,
      filterDimensions: {
        ...query.filterDimensions,
        filterGroups: query.filterDimensions.filterGroups.filter((item) => !filters.includes(item.name.toLowerCase()))
      }
    };
  }

  return query;
};
