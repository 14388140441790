import Model from 'core/model/Model';

class CommentModel extends Model {
  get urlRoot() {
    return '/api/ui/comments';
  }

  updateUserInfo(activeUser) {
    this.set('user', {
      id: activeUser.id,
      user_name: activeUser.user_name,
      user_full_name: activeUser.user_full_name,
      user_email: activeUser.user_email
    });
  }

  deserialize(data = {}) {
    const { entity_type, entity_id, company_id, user_id, ...rest } = data;

    return {
      ...rest,
      entityId: entity_id,
      entityType: entity_type,
      companyId: company_id,
      userId: user_id
    };
  }

  serialize(data) {
    const { entityId, entityType, userId, companyId, ...rest } = data;

    return {
      ...rest,
      entity_type: entityType,
      entity_id: entityId,
      user_id: userId,
      company_id: companyId
    };
  }
}

export default CommentModel;
