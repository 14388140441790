import { computed, observable } from 'mobx';
import { escapeRegExp } from 'lodash';
import { addCustomPropertiesToEntity, getCustomProperties } from 'shared/util/map';
import { ENTITY_TYPES } from 'shared/hybrid/constants';
import { getEntityType } from 'app/views/hybrid/utils/map';

import CloudMapCollection from './CloudMapCollection';
import GCPMapModel from './GCPMapModel';

const { NETWORK, EXTERNAL_VPN_GATEWAY, ROUTER, INTERCONNECT, INTERCONNECT_ATTACHMENT, VPN_GATEWAY, VPN_TUNNEL } =
  ENTITY_TYPES.get('gcp');

class GCPCloudMapCollection extends CloudMapCollection {
  collectionManagedEntityTypes = [NETWORK];

  searchableIdPaths = ['device_id', 'device_name', 'name'];

  searchableCIDRPaths = [
    'ipCidrRange', // subnets
    'gatewayAddress' // subnets
  ];

  @observable.ref
  initialTopology = { Hierarchy: { regions: [] }, Entities: {}, Links: [], traffic: [] };

  @observable
  /** [{type, value}] */
  highLightedNodes = [];

  @observable
  isLoading = false;

  get model() {
    return GCPMapModel;
  }

  get url() {
    return '/api/ui/topology/cloud-hierarchy/gcp';
  }

  get Entities() {
    return this.initialTopology?.Entities ?? {};
  }

  /*
    A network is empty and should not be rendered in the map when:

    - there are no regions
  */
  isNetworkHierarchyEmpty(network) {
    return !network?.regions?.length;
  }

  @computed
  get hasDefaultNetworks() {
    return this.unfiltered.some((model) => getEntityType(model) === NETWORK && model.get('autoCreateSubnetworks'));
  }

  /*
    Returns a unique list of account ids
    In the gcp world, we're going to count project id as being account ids
  */
  @computed
  get accountIds() {
    return this.unfiltered.reduce((accountIds, model) => {
      const { project } = getCustomProperties(model.get());

      if (project && !accountIds.includes(project)) {
        accountIds.push(project);
      }

      return accountIds;
    }, []);
  }

  @computed
  get filterStateGroups() {
    const query = this.filterState || '';

    return query
      .split(',')
      .map((term) => term.trim())
      .reduce(
        (acc, term) => {
          if (term === '') {
            return acc;
          }

          if (this.isValidIP(term)) {
            acc.cidrs.push(term);
            return acc;
          }

          if (this.accountIds.includes(term)) {
            acc.accountIds.push(term);
          } else if (term.includes('=')) {
            acc.tags.push(term);
          } else {
            acc.ids.push(term);
          }

          return acc;
        },
        {
          accountIds: [],
          ids: [],
          cidrs: [],
          tags: []
        }
      );
  }

  // return a list of models by entity type
  getModelsByEntityType(entityType) {
    return this.models.filter((model) => getEntityType(model) === entityType).map((model) => model.get());
  }

  @computed
  get topology() {
    const { Hierarchy } = this.initialTopology;
    const platformHierarchy = {};

    platformHierarchy.networks = Hierarchy.networks
      ?.reduce(
        (networks, network) => networks.concat(this.hydrateHierarchy({ entity: network, entityType: 'networks' })),
        []
      )
      .filter((network) => !this.isNetworkHierarchyEmpty(network));

    return {
      ...this.initialTopology,
      Hierarchy: platformHierarchy,
      [EXTERNAL_VPN_GATEWAY]: this.getModelsByEntityType(EXTERNAL_VPN_GATEWAY),
      [ROUTER]: this.getModelsByEntityType(ROUTER),
      [INTERCONNECT]: this.getModelsByEntityType(INTERCONNECT),
      [INTERCONNECT_ATTACHMENT]: this.getModelsByEntityType(INTERCONNECT_ATTACHMENT),
      [VPN_GATEWAY]: this.getModelsByEntityType(VPN_GATEWAY),
      [VPN_TUNNEL]: this.getModelsByEntityType(VPN_TUNNEL)
    };
  }

  filter(query) {
    this.filterState = query || '';
    const { filterStateGroups } = this;
    const discreteFilters = Object.keys(filterStateGroups).reduce((acc, groupName) => {
      const values = filterStateGroups[groupName];

      if (values.length > 0) {
        if (groupName === 'accountIds') {
          return acc.concat({
            type: groupName,
            values,
            fn: (model) =>
              values.find((value) => {
                const { projectId } = getCustomProperties(model.get());
                return [projectId].includes(value);
              })
          });
        }

        if (groupName === 'ids') {
          return acc.concat({
            type: groupName,
            values,
            fn: (model) =>
              values.find((value) =>
                model.searchableData.ids.find((id) => new RegExp(escapeRegExp(value), 'i').test(id))
              )
          });
        }

        if (groupName === 'cidrs') {
          return acc.concat({
            type: groupName,
            values,
            fn: (model) =>
              values.find((value) => model.searchableData.cidrs.find((cidr) => this.isInSubnet(value, cidr)))
          });
        }

        if (groupName === 'tags') {
          return acc.concat({
            type: groupName,
            values,
            fn: (model) =>
              values.find((value) =>
                model.searchTags.find((tag) => {
                  const [modelTagKey, modelTagValue] = tag;
                  const [filterTagKey, filterTagValue] = value.split('=');

                  // find a complete key match and a partial value match
                  return (
                    modelTagKey === filterTagKey && new RegExp(escapeRegExp(filterTagValue), 'i').test(modelTagValue)
                  );
                })
              )
          });
        }
      }

      return acc;
    }, []);

    this.models.replace(this.get());

    if (discreteFilters.length > 0) {
      discreteFilters.forEach((filter) => {
        this.models.replace(this.models.filter((model) => filter.fn(model)));
      });
    }

    this.sort();

    return this.models;
  }

  /*
    Returns a complete list of all Entities in the reference map for the given type 'entityType', decorating it with:

    - an optionally overridden id using the 'entityId' arg
    - an 'entityType' which directly relates the model to an entity type
  */
  getActiveEntities({ entityType, entityId = 'selfLink', allowUnlinked = false }) {
    const { Entities } = this.initialTopology;
    const entityTypeList = Object.values(Entities[entityType] || {});
    const entities = entityTypeList.map((node) => {
      const entityWithNewId = Object.assign({}, node, { id: node[entityId] });
      return addCustomPropertiesToEntity({
        entity: entityWithNewId,
        // stash the entity type as a custom property
        // in the case of gcp, we use the 'selfLink' property as the entity's unique identifier in the Entities reference map
        // since gcp entities also contain their own 'id' property, this will cause problems as we'll overwrite that property here
        // in order property display the original id property (in sidebars for example), we'll preserve the original id value while still offering a fallback
        customProperties: { entityType, id: node.id || node[entityId] }
      });
    });

    if (allowUnlinked) {
      return entities;
    }

    return entities.filter(this.filterToLinked(entityType));
  }

  getSearchableCIDRList(data = {}) {
    return Object.keys(data).reduce((cidrs, itemType) => {
      const itemData = data[itemType];

      if (itemType === 'ipCidrRange' || itemType === 'gatewayAddress') {
        // subnets
        return cidrs.concat(itemData);
      }

      return cidrs;
    }, []);
  }

  deserialize(response) {
    // capture the initial topology response
    this.initialTopology = response;

    // get a comprehensive list of models for the collection
    const models = [
      { entityType: NETWORK },
      { entityType: EXTERNAL_VPN_GATEWAY },
      { entityType: ROUTER, entityId: 'device_id' },
      { entityType: INTERCONNECT },
      { entityType: INTERCONNECT_ATTACHMENT },
      { entityType: VPN_GATEWAY },
      { entityType: VPN_TUNNEL }
    ].reduce((acc, entityConfig) => acc.concat(this.getActiveEntities({ ...entityConfig, allowUnlinked: true })), []);

    const modelsWithSearchableData = this.addSearchableDataToModels({
      entities: response.Hierarchy.networks,

      models
    });

    return modelsWithSearchableData;
  }

  getLocationEntities(location, entityType) {
    return this.getActiveEntities({ entityType, allowUnlinked: true }).filter((entity) => entity.location === location);
  }

  resetHighlightedNodes() {
    this.highLightedNodes = [];
  }

  getEntityType(entity) {
    return getEntityType(entity);
  }
}

export default GCPCloudMapCollection;
