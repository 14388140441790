import { action, computed } from 'mobx';

import api from 'core/util/api';
import { POLICY_APPLICATION_LABELS } from '@kentik/ui-shared/alerting/constants';

import PackageCollection from './PackageCollection';
import TenantCollection from './TenantCollection';
import MkpSettingsModel from './MkpSettingsModel';

class MyKentikPortalStore {
  tenants = new TenantCollection();

  packages = new PackageCollection();

  settings = new MkpSettingsModel();

  @action
  async fetchAvailableViews() {
    const { $dashboards, $savedViews } = this.store;

    // TODO: do these need to be only shared assets (probably)?

    return Promise.all([$dashboards.collection.fetch(), $savedViews.collection.fetch()]).then(() =>
      $dashboards.collection.get().concat($savedViews.collection.get())
    );
  }

  @computed
  get packageOptions() {
    const options = this.packages.get().map((pkg) => ({
      label: pkg.get('name'),
      value: pkg.get('id'),
      color: pkg.get('config.color'),
      icon: pkg.get('config.icon')
    }));
    return [...options, { label: 'No Package', value: '', icon: 'cube', color: '#A2A8B7' }];
  }

  @computed
  get packageStats() {
    return {
      unused: this.packages.unfiltered.filter((tenantPackage) =>
        this.tenants.get().find((tenant) => tenant.get('template_id') === tenantPackage.id)
      ).length,
      total: this.packages.unfilteredSize,
      tenantsWithPackages: this.tenants.get().filter((tenant) => tenant.get('template_id')).length,
      tenantsWithoutPackages: this.tenants.get().filter((tenant) => !tenant.get('template_id')).length
    };
  }

  get tabs() {
    return ['general', 'users', 'views', 'data', 'policies'];
  }

  @computed
  get hasTenantSubpolicies() {
    return this.tenants.getSubpolicyRuleIds().length > 0;
  }

  countDashboardUsers(id) {
    return this.countViewUsers(id, 'dashboard');
  }

  countSavedViewUsers(id) {
    return this.countViewUsers(id, 'savedView');
  }

  countViewUsers(viewId, viewType) {
    return this.tenants
      .get()
      .filter((tenant) => tenant.get('config.assets')?.reports.filter((report) => report.type === viewType).length > 0)
      .map((tenant) =>
        tenant
          .get('config.assets')
          .reports.filter((report) => report.type === viewType)
          .map((report) => report.id)
      )
      .reduce((flat, ids) => flat.concat(ids), [])
      .filter((id) => id === viewId).length;
  }

  uploadLogo = (body) => api.put(`/api/ui/mkp/${this.settings.get('company_id')}`, { body });

  launchLandlordSession = (params) => api.post('/api/ui/mkp/landlordSpoof', { data: params });

  clearSpoofSession = (options = {}) => {
    const { reload } = options;

    return api.get('/api/ui/mkp/landlordUnspoof', {}).then(() => {
      if (reload) {
        window.location = '/v4/mkp';
      }
    });
  };

  getSelectablePolicyOptions = (
    policyModelOptions = [],
    currentPolicyId,
    selectedPolicyIds = [],
    requiredDimensions = []
  ) => {
    const enabledPolicies = [];
    const disabledPolicies = [];

    policyModelOptions.forEach((policy) => {
      // Don't show selected policies, unless it's the currently selected one (it needs to be selectable!)
      if (!selectedPolicyIds.includes(`${policy.id}`) || `${currentPolicyId}` === `${policy.id}`) {
        const isNmsPolicy = policy.applicationLabel === POLICY_APPLICATION_LABELS.kmetrics;
        const hasRequiredDimensions = requiredDimensions.every((dimension) =>
          (policy.get('dimensions') || []).includes(dimension)
        );
        const disabled = isNmsPolicy || !hasRequiredDimensions;

        let disabledReason;

        if (!hasRequiredDimensions) {
          disabledReason = 'Missing Dimensions';
        } else if (isNmsPolicy) {
          disabledReason = 'Unsupported Type (NMS)';
        }

        (disabled ? disabledPolicies : enabledPolicies).push({
          label: policy.get('name'),
          value: policy.id,
          disabled,
          disabledReason
        });
      }
    });

    // Sort alphabetically by label, with disabled options last
    return [
      ...enabledPolicies.sort((a, b) => a.label.localeCompare(b.label)),
      ...disabledPolicies.sort((a, b) => a.label.localeCompare(b.label))
    ];
  };

  getRequiredDimensionsForTenant = (config = {}) => {
    const requiredDimensions = [];

    if (
      config.devices?.device_name.length > 0 ||
      config.devices?.device_types.length > 0 ||
      config.devices?.device_labels.length > 0
    ) {
      requiredDimensions.push('i_device_id');
    }

    if (config.devices?.device_sites.length > 0) {
      requiredDimensions.push('i_device_site_name');
    }

    if (config.asn) {
      requiredDimensions.push('AS_dst');
    }

    if (config.cidr) {
      requiredDimensions.push('IP_dst');
    }

    if (config.interface_name || config.snmp_alias) {
      requiredDimensions.push('InterfaceID_dst');
    }

    requiredDimensions.push(...(config.custom_dimensions || []).map((c) => c.dimension));

    return requiredDimensions;
  };
}

export default new MyKentikPortalStore();
