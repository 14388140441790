import { action, computed, observable } from 'mobx';

import api from 'core/util/api';
import moment from 'moment/moment';
import { getMoment } from 'core/util/dateUtils';
import KproxyAgentCollection from './KproxyAgentCollection';

// maximum length of time to consider a kproxy agent sufficiently recent for display
const KPROXY_DISPLAY_INTERVAL = 60 * 60 * 1000; // one hour in ms

class KproxyAgentStore {
  collection = new KproxyAgentCollection();

  @observable
  kproxyUser;

  @computed
  get kproxyAgentOptions() {
    return this.collection
      .map((kproxyAgent) => ({
        value: kproxyAgent.get('id'),
        label: `${kproxyAgent.get('title')}`
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  /**
   * Get recent agents, optionally matching a selector against their model
   * @param whereFn {(model: Object) => boolean}
   * @returns {T[]}
   */
  getRecentAgents(whereFn = () => true) {
    const result = this.collection
      .get()
      .filter((model) => moment() - getMoment(model.get('last_access')) < KPROXY_DISPLAY_INTERVAL && whereFn(model));
    // pass through fetch status
    result.requestStatus = this.collection.requestStatus;
    return result;
  }

  getRecentAgentsForDevice(device_name) {
    return this.getRecentAgents((model) =>
      model.get('devices').some((agentDevice) => agentDevice.device_name === device_name)
    );
  }

  @computed
  get configurableKproxyAgentOptions() {
    return this.collection.unfiltered
      .filter((kproxyAgent) => kproxyAgent.get('agent_private_ip'))
      .map((kproxyAgent) => ({
        value: kproxyAgent.get('id'),
        label: `${kproxyAgent.get('title')}`
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  @action
  fetchKproxyUser = () => {
    if (this.store.$auth.isAdministrator) {
      if (this.kproxyUser) {
        return Promise.resolve(this.kproxyUser);
      }

      return api.get('/api/ui/kproxyAgent/kproxyUser').then((user) => {
        this.kproxyUser = user;
        return user;
      });
    }

    return Promise.resolve();
  };
}

export default new KproxyAgentStore();
