import { computed } from 'mobx';
import Model from 'core/model/Model';
import InterfaceGroupInterfaceCollection from './InterfaceGroupInterfaceCollection';

class InterfaceGroupModel extends Model {
  interfaceCollection = new InterfaceGroupInterfaceCollection([]);

  moduleConfigModel;

  get defaults() {
    return {
      name: '',
      type: '',
      filters: [],
      interfaces: []
    };
  }

  get urlRoot() {
    return '/api/ui/interfaceGroup';
  }

  @computed
  get hasFilters() {
    const { interfaces, filters } = this.get(['interfaces', 'filters']);
    return (interfaces && interfaces.length > 0) || (filters && filters.length > 0);
  }

  @computed
  get filterLength() {
    const { interfaces, filters } = this.get(['interfaces', 'filters']);
    return (interfaces ? interfaces.length : 0) + (filters ? filters.length : 0);
  }

  set interfaces(data) {
    this.set('interfaces', data || []);
  }

  async create(attributes = {}, options = {}) {
    return super.create(attributes, options);
  }

  async fetch(options = {}) {
    return super.fetch(options).then((result) => {
      this.interfaceCollection.set(this.get('interfaces'));
      return result;
    });
  }

  async save(attributes, options) {
    return super.save(attributes, options).then((result) => {
      if (this.moduleConfigModel) {
        this.moduleConfigModel.set('interfaceGroupId', this.id);
      }
      return result;
    });
  }

  serialize(data) {
    const { filters } = data;
    if (filters && filters.length) {
      data.filters = filters.map((filterGroup) => filterGroup.filters);
    }
    delete data.interfaces;
    return super.serialize(data);
  }
}

export default InterfaceGroupModel;
