import Model from 'core/model/Model';
import { computed } from 'mobx';

class NetworkHealthTestModel extends Model {
  get defaults() {
    return {
      type: 'global',
      status: true
    };
  }

  @computed
  get score() {
    if (this.get('availability') === 0 && this.get('weightedLatency') === 0) {
      return -1;
    }
    return Math.max(100 - this.get('availability'), this.get('weightedLatency'));
  }
}

export default NetworkHealthTestModel;
