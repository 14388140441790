import Collection from 'core/model/Collection';
import { observable } from 'mobx';
import InterfaceGroupInterfaceModel from './InterfaceGroupInterfaceModel';

class InterfaceGroupInterfaceCollection extends Collection {
  @observable.ref
  filterOptions;

  @observable.ref
  totalCount;

  get urlPaths() {
    return {
      fetch: '/api/ui/interfaces'
    };
  }

  get model() {
    return InterfaceGroupInterfaceModel;
  }

  get defaultSortState() {
    return {
      field: 'interface_description',
      direction: 'asc'
    };
  }

  processData(response) {
    if (Array.isArray(response)) {
      return super.processData(response);
    }

    this.filterOptions = response.options;
    this.totalCount = response.total_count;
    return super.processData(response.results);
  }
}

export default InterfaceGroupInterfaceCollection;
