import Model from 'core/model/Model';

class ServiceModel extends Model {
  get defaults() {
    return {
      settings: {}
    };
  }

  get urlRoot() {
    return '/api/ui/ott/service';
  }
}

export default ServiceModel;
