import Model from 'core/model/Model';
import { computed } from 'mobx';
import { uniqWith, isEqual } from 'lodash';
import PolicyModel from 'app/stores/alerting/PolicyModel';
import $notifications from 'app/stores/notifications/$notifications';

export default class PackageModel extends Model {
  get urlRoot() {
    return '/api/ui/mkp-settings/package';
  }

  get messages() {
    return {
      create: `Package ${this.get('name')} was added successfully`,
      update: `Package ${this.get('name')} was updated successfully`,
      destroy: `Package ${this.get('name')} was removed successfully`
    };
  }

  get removalConfirmText() {
    return {
      title: 'Remove Package',
      text: `Are you sure you want to remove ${this.get('name')}?`
    };
  }

  @computed
  get numViews() {
    const reports = this.get('config.assets.reports');
    return reports ? reports.length : 0;
  }

  @computed
  get numSavedViews() {
    const reports = this.get('config.assets.reports');
    return reports ? reports.filter((report) => report.type === 'savedView').length : 0;
  }

  @computed
  get numDashboards() {
    const reports = this.get('config.assets.reports');
    return reports ? reports.filter((report) => report.type === 'dashboard').length : 0;
  }

  @computed
  get numTenants() {
    const tenants = this.get('tenants');
    return tenants ? tenants.length : 0;
  }

  @computed
  get numAlertPolicies() {
    const alertPolicies = this.get('config.alerts');
    return alertPolicies ? alertPolicies.length : 0;
  }

  addView = (view) => {
    const viewEntry = { id: `${view.id}` };

    if (view.type === 'Dashboard') {
      viewEntry.type = 'dashboard';
    }

    if (view.type === 'Saved View') {
      viewEntry.type = 'savedView';
    }

    if (!view.type) {
      return Promise.resolve();
    }

    const config = this.get('config');
    config.assets = config.assets || {};
    config.assets.reports = config.assets.reports || [];
    // Prevent dupes
    config.assets.reports = uniqWith([...config.assets.reports, viewEntry], isEqual);
    this.set('config', config);
    return this.save({}, { toast: false });
  };

  hasReport = (viewOption) => {
    const assignedReports = this.get('config.assets.reports') || [];
    return assignedReports.some((report) => isEqual(report, viewOption));
  };

  get omitDuringSerialize() {
    return ['tenants'];
  }

  deserialize(data) {
    if (data?.config?.alerts) {
      // Set saved to existing alerts.
      data.config.alerts = data.config.alerts.map((alert) =>
        new PolicyModel().deserialize({
          ...alert,
          thresholds: $notifications.addNotificationsToThresholds(alert.thresholds, {})
        })
      );
    }

    return super.deserialize(data);
  }

  serialize(data) {
    const { config } = data;
    const { alerts } = config;
    // from [10] to [{ id: 10 }]
    config.alerts = alerts.map((alert) => new PolicyModel().serialize(alert));

    return super.serialize(data);
  }
}
