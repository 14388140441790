import Model from 'core/model/Model';
import { computed } from 'mobx';
import { getCustomProperties } from 'shared/util/map';

export default class CloudMapModel extends Model {
  @computed
  get searchableData() {
    const customProperties = getCustomProperties(this);

    return {
      ids: customProperties.searchableIds || [],
      cidrs: customProperties.searchableCIDRs || []
    };
  }
}
