import { observable, action } from 'mobx';
import { get } from 'lodash';

import {
  V4_LANDING,
  NEW_STUFF,
  INSIGHTS,
  WORKFLOWS,
  CORE_WORKFLOWS,
  EDGE_WORKFLOWS,
  PROTECT_WORKFLOWS,
  SERVICE_PROVIDER_WORKFLOWS,
  NETWORK_EXPLORER,
  NETWORK_MAP,
  SEARCH,
  HELP_FEEDBACK,
  OPEN_TOUR,
  OLD_STUFF,
  LIBRARY,
  EXPLORER,
  ALERTING,
  NAVMENU,
  NAVMENU_PRODUCTS,
  NAVMENU_SETTINGS
} from './landingIntroSteps';
import Tour from './Tour';

class TourStore {
  @observable
  activeTour;

  tours = {};

  initialize() {
    this.tours.landingIntro = new Tour(
      'landingIntro',
      [
        V4_LANDING,

        NEW_STUFF,
        INSIGHTS,
        WORKFLOWS,
        CORE_WORKFLOWS,
        EDGE_WORKFLOWS,
        PROTECT_WORKFLOWS,
        SERVICE_PROVIDER_WORKFLOWS,
        NETWORK_EXPLORER,
        NETWORK_MAP,
        SEARCH,
        HELP_FEEDBACK,

        OLD_STUFF,
        LIBRARY,
        EXPLORER,
        ALERTING,

        NAVMENU,
        NAVMENU_PRODUCTS,
        NAVMENU_SETTINGS,
        OPEN_TOUR
      ],
      { onComplete: this.handleComplete }
    );
  }

  @action
  start = (tourName) => {
    this.activeTour = this.tours[tourName];
    this.activeTour.start();
  };

  handleComplete = (tourName) => {
    const { $auth, $setup } = this.store;

    const completedTours = get($setup, 'settings.completedTours') || [];
    if (!completedTours.includes(tourName) && !$auth.isSpoofed) {
      $setup.updateSettings({ completedTours: completedTours.concat(tourName) });
    }
    this.activeTour = null;
  };
}

export default new TourStore();
