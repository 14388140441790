import InterfaceCollection from './InterfaceCollection';

class EmbeddedCacheInterfaceCollection extends InterfaceCollection {
  fetch() {
    return super.fetch({
      query: { connectivity_type: 'embedded_cache', interface_ip: 'set' }
    });
  }
}

export default EmbeddedCacheInterfaceCollection;
