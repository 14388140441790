import Model from 'core/model/Model';
import QueryModel from 'app/stores/query/QueryModel';

export default class RawFlowQueryModel extends Model {
  get defaults() {
    return {
      all_selected: false,
      device_name: '',
      endDateTime: new Date(),
      startDateTime: null,
      ...QueryModel.create().serialize()
    };
  }
}
