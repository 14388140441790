import { observable, action } from 'mobx';

export default class SidebarModel {
  @observable
  collapsed = false;

  editingSections = observable.map();

  // when collapsed, keep state of which section is open
  @observable
  activeOpenSection = '';

  collapsible = false;

  synchronizedToggle = false;

  directToggleSections = [];

  constructor(options = {}) {
    const { sections, name, directToggleSections, synchronizedToggle, collapsible, collapsed, onCollapse } = options;

    this.sections = sections;
    this.name = name || 'sidebar';
    this.directToggleSections = directToggleSections || [];
    this.synchronizedToggle = synchronizedToggle || false;
    this.collapsed = collapsed || false;
    this.collapsible = collapsible || false;
    this.onCollapse = onCollapse || false;

    sections.forEach((section) => this.editingSections.set(section, false));
  }

  @action
  toggleEditingSection = (section) => {
    const currentToggle = this.editingSections.get(section);
    if (this.synchronizedToggle && !this.directToggleSections.includes(section) && !this.collapsed) {
      this.setEditingSections(!currentToggle);
    } else if (this.collapsed) {
      // in collapsed mode, only allow one section open at a time, so close everything first.
      this.setEditingSections(false);
      this.editingSections.set(section, !currentToggle);
    } else {
      this.editingSections.set(section, !currentToggle);
    }
  };

  @action
  setEditingSections = (isEditing) => {
    this.editingSections.forEach((value, section) => {
      if (!this.directToggleSections.includes(section)) {
        this.editingSections.set(section, isEditing);
      }
    });
  };

  // for collapsed mode, toggle open/close on a section since only one
  // can be open at a time
  @action
  toggleActiveSection = (section) => {
    if (this.activeOpenSection === section) {
      this.activeOpenSection = null;
    } else {
      this.activeOpenSection = section;
    }

    // always open in Edit mode when collapsed, except for the on-off Filters section
    if (section && section !== 'filters') {
      this.editingSections.set(section, true);
    }
  };

  @action
  setCollapsed = (collapsed) => (this.collapsed = collapsed);

  @action
  handleToggleCollapsed = () => {
    this.activeOpenSection = null;
    this.collapsed = !this.collapsed;

    if (this.onCollapse) {
      this.onCollapse();
    }

    localStorage.setItem(`${this.name}SidebarCollapsed`, this.collapsed);
  };
}
