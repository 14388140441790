import { throttle } from 'lodash';
import api from 'core/util/api';

export class ApiBatcher {
  awaiting = new Set();

  fetches = new Map();

  isFetching = false;

  /**
   * Allow the construction of batched API calls which fetch data for an arbitrary set of IDs.
   *
   * @param url {string} url to hit. This will use a POST as it sends a request body for the IDs.
   * @param [batchWindow] {number} length of time (in milliseconds) to wait before firing API call.
   * @param [refetchTime] {number} length of time (in milliseconds) before we will fetch the data again.
   * @param [batchKey] {string} expected key for body array containing IDs.
   */
  constructor({ url, batchWindow = 10, refetchTime = 5 * 60 * 1000, batchKey = 'ids' }) {
    this.url = url;
    this.batchWindow = batchWindow;
    this.refetchTime = refetchTime;
    this.batchKey = batchKey;
  }

  set batchWindow(batchWindow) {
    if (this.awaiting.size) {
      // corner case: if we're changing batchWindow after initialization then we first flush out the existing queue
      this._doFetch();
    }
    // create a new throttled fetch function with the new batchWindow assigned
    this._triggerFetch = throttle(this._doFetch, batchWindow, { leading: false, trailing: true });
  }

  _doFetch = async () => {
    if (this.isFetching) {
      // avoid spamming the API too aggressively
      this._triggerFetch();
      return;
    }
    this.isFetching = true;
    const awaiting = [...this.awaiting];
    this.awaiting.clear();
    const results = await api.post(this.url, { body: { [this.batchKey]: awaiting } });
    this.isFetching = false;
    for (const id of awaiting) {
      this.fetches.get(id)?.resolver(results[id]);
    }
  };

  fetch(id) {
    const time = Date.now();
    if (!this.awaiting.has(id) && (!this.fetches.has(id) || this.fetches.get(id).time > time - this.refetchTime)) {
      let resolver;
      let rejector;
      const promise = new Promise((resolve, reject) => {
        resolver = resolve;
        rejector = reject;
      });

      this.fetches.set(id, { promise, resolver, rejector, time });
      this.awaiting.add(id);

      this._triggerFetch();
      return promise;
    }
    // if there is an existing promise then we reuse it
    return this.fetches.get(id).promise;
  }
}
