import Collection from 'core/model/Collection';
import $users from 'app/stores/user/$users';

class AuditLogCollection extends Collection {
  get url() {
    return '/api/ui/auditLog';
  }

  get defaultSortState() {
    return {
      field: 'ctime',
      direction: 'desc'
    };
  }

  getSortValue = (field, model) => {
    const value = model.get(field);
    if (field === 'user_id') {
      const user = $users.collection.get(value ?? '-');
      return `${user ? user.get('user_full_name') : value}`.toLowerCase();
    }
    return value;
  };

  fetch({ start_time, end_time, keyword }) {
    return super.fetch({ query: { start_time, end_time, keyword } });
  }

  toCsv() {
    const fields = ['source', 'api_action', 'object_type', 'api_path', 'user_id', 'ctime'];
    const rows = [];
    rows.push(fields.map((field) => `"${field}"`));
    this.models.forEach((model) => {
      const values = model.get();
      rows.push(fields.map((field) => `"${values[field]}"`));
    });
    return rows.map((row) => row.join(',')).join('\r\n');
  }
}

export default AuditLogCollection;
