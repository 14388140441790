import { Collection, Model } from 'core/model';

class WidgetModel extends Model {
  get(attribute) {
    if (!attribute) {
      return { id: this.id, ...super.get() };
    }

    return super.get(attribute);
  }
}

export default class WidgetCollection extends Collection {
  get model() {
    return WidgetModel;
  }
}
