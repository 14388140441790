import { escapeRegExp } from 'lodash';
import { observable, computed } from 'mobx';
import { ENTITY_TYPES } from 'shared/hybrid/constants';

import OciMapModel from './OciMapModel';
import CloudMapCollection from './CloudMapCollection';

const {
  REGION,
  SUBNET,
  NAT_GATEWAY,
  INTERNET_GATEWAY,
  LOCAL_PEERING_GATEWAY,
  VIRTUAL_CLOUD_NETWORK,
  DYNAMIC_ROUTING_GATEWAY,
  DYNAMIC_ROUTING_GATEWAY_ATTACHMENT
} = ENTITY_TYPES.get('oci');

class OciCloudMapCollection extends CloudMapCollection {
  collectionManagedEntityTypes = [REGION];

  @observable.ref
  initialTopology = { Hierarchy: { Regions: [] }, Entities: {}, Links: [] };

  @observable
  /** [{type, value}] */
  highLightedNodes = [];

  @observable
  isLoading = false;

  searchableIdPaths = ['device_id', 'name', 'device_name', 'location', 'id'];

  searchableCIDRPaths = ['cidrBlock'];

  get model() {
    return OciMapModel;
  }

  get url() {
    return '/api/ui/topology/cloud-hierarchy/oci';
  }

  get Entities() {
    return this.initialTopology?.Entities ?? {};
  }

  get Links() {
    return this.initialTopology?.Links ?? [];
  }

  deserialize(response) {
    // capture the initial topology response
    this.initialTopology = response;

    // get a comprehensive list of models for the collection
    const models = [
      { entityType: REGION },
      { entityType: SUBNET },
      { entityType: NAT_GATEWAY },
      { entityType: INTERNET_GATEWAY },
      { entityType: LOCAL_PEERING_GATEWAY },
      { entityType: VIRTUAL_CLOUD_NETWORK },
      { entityType: DYNAMIC_ROUTING_GATEWAY },
      { entityType: DYNAMIC_ROUTING_GATEWAY_ATTACHMENT }
    ].reduce((acc, entityConfig) => acc.concat(this.getActiveEntities({ ...entityConfig, allowUnlinked: true })), []);

    const modelsWithSearchableData = this.addSearchableDataToModels({
      entities: response.Hierarchy.Regions,
      models
    });

    return modelsWithSearchableData;
  }

  @computed
  get filterStateGroups() {
    const query = this.filterState || '';

    return query
      .split(',')
      .map((term) => term.trim())
      .reduce(
        (acc, term) => {
          if (term === '') {
            return acc;
          }

          if (this.isValidIP(term)) {
            acc.cidrs.push(term);
            return acc;
          }

          if (term.includes('ocid1.tenancy.oc1')) {
            acc.tenancyIds.push(term);
            return acc;
          }

          if (term.includes('ocid1.compartment.oc1')) {
            acc.compartmentIds.push(term);
            return acc;
          }

          acc.ids.push(term);

          return acc;
        },
        {
          tenancyIds: [],
          compartmentIds: [],
          ids: [],
          cidrs: []
        }
      );
  }

  filter(query) {
    this.filterState = query || '';
    const { filterStateGroups } = this;

    const discreteFilters = Object.keys(filterStateGroups).reduce((acc, groupName) => {
      const values = filterStateGroups[groupName];
      if (values.length > 0) {
        if (groupName === 'tenancyIds') {
          return acc.concat({
            type: groupName,
            values,
            fn: (model) => values.find((value) => model.searchableData.tenancyIds.find((id) => id.includes(value)))
          });
        }

        if (groupName === 'compartmentIds') {
          return acc.concat({
            type: groupName,
            values,
            fn: (model) => values.find((value) => model.searchableData.compartmentIds.find((id) => id.includes(value)))
          });
        }

        if (groupName === 'ids') {
          return acc.concat({
            type: groupName,
            values,
            fn: (model) =>
              values.find((value) =>
                model.searchableData.ids.find((id) => new RegExp(escapeRegExp(value), 'i').test(id))
              )
          });
        }

        if (groupName === 'cidrs') {
          return acc.concat({
            type: groupName,
            values,
            fn: (model) =>
              values.find((value) => model.searchableData.cidrs.find((cidr) => this.isInSubnet(value, cidr)))
          });
        }
      }

      return acc;
    }, []);

    this.models.replace(this.get());

    if (discreteFilters.length > 0) {
      discreteFilters.forEach((filter) => {
        this.models.replace(this.models.filter((model) => filter.fn(model)));
      });
    }

    this.sort();

    return this.models;
  }

  isRegionHierarchyEmpty(region) {
    return !region?.[VIRTUAL_CLOUD_NETWORK]?.length && !region?.[SUBNET]?.length;
  }

  @computed
  get topology() {
    const { Hierarchy } = this.initialTopology;
    const platformHierarchy = {};

    platformHierarchy.Regions = Hierarchy.Regions.map((region) =>
      this.hydrateHierarchy({ entity: region, entityType: REGION })
    ).filter((network) => !this.isRegionHierarchyEmpty(network));

    return {
      ...this.initialTopology,
      Hierarchy: platformHierarchy
    };
  }

  getSearchableCIDRList(data = {}) {
    return Object.values(data).flat();
  }

  findEntityById(id) {
    for (const entityType in this.Entities) {
      if (this.Entities[entityType][id]) {
        return this.Entities[entityType][id];
      }
    }

    console.warn(`unable to locate ${id} in topology entities.`);
    return null;
  }

  findEntityByName(name, entityType = null) {
    if (entityType) {
      return Object.values(this.Entities[entityType]).find((entity) => entity.displayName === name) ?? null;
    }

    for (const loopEntityType in this.Entities) {
      if (this.Entities[loopEntityType]) {
        const entity = Object.values(this.Entities[loopEntityType]).find(
          (entityTypeEntity) => entityTypeEntity.displayName === name
        );

        if (entity) {
          return entity;
        }
      }
    }

    console.warn(`unable to locate entity with displayName ${name} in topology entities.`);
    return null;
  }
}

export default OciCloudMapCollection;
