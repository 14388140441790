import Model from 'core/model/Model';
import { computed } from 'mobx';
import QueryModel from 'app/stores/query/QueryModel';
import { arrayToCommaSeparatedString, commaSeparatedStringToArray } from 'app/util/utils';

class CustomApplication extends Model {
  get urlRoot() {
    return '/api/ui/custom-applications';
  }

  get defaults() {
    return {
      name: '',
      description: '',
      ip_range: [],
      protocol: [],
      port: [],
      asn: []
    };
  }

  get messages() {
    return {
      create: `Custom application ${this.get('name')} was successfully added.`,
      update: `Custom application ${this.get('name')} was successfully updated.`,
      destroy: `Custom application ${this.get('name')} was successfully removed.`
    };
  }

  get removalConfirmText() {
    return {
      title: 'Remove Custom Application',
      text: `Are you sure you want to remove ${this.get('name')}?`
    };
  }

  @computed
  get query() {
    const queryModel = QueryModel.create({
      all_devices: true,
      metric: ['application'],
      filters: {
        connector: 'All',
        filterGroups: [
          {
            connector: 'All',
            filters: [
              {
                filterField: 'application',
                operator: '=',
                filterValue: this.get('name')
              }
            ],
            filterGroups: [],
            saved_filters: [],
            autoAdded: false,
            not: false
          }
        ]
      }
    });

    return queryModel.serialize();
  }

  serialize(data) {
    const { ip_range, protocol, port, asn } = data;

    return super.serialize({
      ...data,
      ip_range: arrayToCommaSeparatedString(ip_range, false),
      protocol: arrayToCommaSeparatedString(protocol, false),
      port: arrayToCommaSeparatedString(port, false),
      asn: arrayToCommaSeparatedString(asn, false)
    });
  }

  deserialize(data = {}) {
    const { ip_range, protocol, port, asn } = data;

    return super.deserialize({
      ...data,
      ip_range: commaSeparatedStringToArray(ip_range),
      protocol: commaSeparatedStringToArray(protocol),
      port: commaSeparatedStringToArray(port),
      asn: commaSeparatedStringToArray(asn)
    });
  }
}

export default CustomApplication;
