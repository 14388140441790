import DeckFactory from 'app/stores/decks/DeckFactory';

export default function gcpDeckFactory() {
  const { widgets, layout, createWidget, newRow } = new DeckFactory('i_cloud');

  // first row
  createWidget({
    name: 'cloudTrafficOverview',
    config: { cloudFilter: 'GCP' },
    h: 7,
    w: 6,
    smallH: 4
  });
  createWidget({
    name: 'cloudTrafficProfile',
    config: { cloud_type: 'gcp', cloud_region: 'All Regions' },
    h: 4,
    w: 3,
    smallH: 3
  });
  createWidget({
    name: 'cloudFlow',
    config: { cloudFilter: 'GCP' },
    h: 4,
    w: 3,
    smallH: 3
  });
  newRow(7);

  return {
    widgets,
    layout
  };
}
