import { computed } from 'mobx';
import Collection from 'core/model/Collection';
import SetupTaskModel from './SetupTaskModel';
import $auth from '../$auth';

class SetupTaskCollection extends Collection {
  get url() {
    return '/api/ui/setup/tasks';
  }

  get model() {
    return SetupTaskModel;
  }

  get presetFilters() {
    return [
      {
        label: 'Open',
        fn: (model) => !model.completed,
        default: true
      },
      {
        label: 'Complete',
        fn: (model) => model.completed
      }
    ];
  }

  @computed
  get percentComplete() {
    const completed = this.get().reduce((acc, model) => acc + (model.completed ? 1 : 0), 0);
    return Math.round((completed / this.unfilteredSize) * 100);
  }

  findTaskByType(type) {
    return this.get().find((model) => model.get('type') === type);
  }

  deserialize(data) {
    let tasks = data;

    if (!$auth.hasPermission('recon.enabled')) {
      tasks = data.filter((task) => task.type !== 'nms');
    }

    return super.deserialize(tasks);
  }
}

export default SetupTaskCollection;
