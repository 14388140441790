import Collection from 'core/model/Collection';

import NetworkHealthTestModel from './NetworkHealthTestModel';

class NetworkHealthTestCollection extends Collection {
  get defaultSortState() {
    return {
      field: 'company_id',
      direction: 'asc'
    };
  }

  get secondarySort() {
    return {
      field: 'score',
      direction: 'desc'
    };
  }

  get url() {
    return '/api/ui/network-availability/test';
  }

  get model() {
    return NetworkHealthTestModel;
  }
}

export default NetworkHealthTestCollection;
