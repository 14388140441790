import $app from 'app/stores/$app';

export const InternalFilters = {
  filters: [
    {
      filterField: 'simple_trf_prof',
      operator: '=',
      filterValue: 'internal'
    }
  ]
};

export const InternalFilterGroups = {
  filterGroups: []
};

export const InboundFilters = {
  filters: [
    {
      filterField: 'simple_trf_prof',
      operator: '=',
      filterValue: $app.isSubtenant ? 'outbound' : 'inbound'
    }
  ]
};

export const InboundFilterGroups = {
  filterGroups: []
};

export const OutboundFilters = {
  filters: [
    {
      filterField: 'simple_trf_prof',
      operator: '=',
      filterValue: $app.isSubtenant ? 'inbound' : 'outbound'
    }
  ]
};

export const OutboundFilterGroups = {
  filterGroups: []
};

export const ThroughFilters = {
  filters: [
    {
      filterField: 'simple_trf_prof',
      operator: '=',
      filterValue: 'through'
    }
  ]
};

export const ThroughFilterGroups = {
  filterGroups: []
};

export const OtherFilters = {
  filters: [
    {
      filterField: 'simple_trf_prof',
      operator: '=',
      filterValue: 'other'
    }
  ]
};

export const OtherFilterGroups = {
  filterGroups: []
};

const filterDimensionGroups = [
  {
    name: 'Internal',
    named: true,
    connector: 'All',
    not: false,
    autoAdded: '',
    saved_filters: [],
    ...InternalFilters,
    ...InternalFilterGroups
  },
  {
    name: 'Inbound',
    named: true,
    connector: 'All',
    not: false,
    autoAdded: '',
    saved_filters: [],
    ...InboundFilters,
    ...InboundFilterGroups
  },
  {
    name: 'Outbound',
    named: true,
    connector: 'All',
    not: false,
    autoAdded: '',
    saved_filters: [],
    ...OutboundFilters,
    ...OutboundFilterGroups
  },
  {
    name: 'Through',
    named: true,
    connector: 'All',
    not: false,
    autoAdded: '',
    saved_filters: [],
    ...ThroughFilters,
    ...ThroughFilterGroups
  },
  {
    name: 'Other',
    named: true,
    connector: 'All',
    not: false,
    autoAdded: '',
    saved_filters: [],
    ...OtherFilters,
    ...OtherFilterGroups
  }
];

export default {
  metric: ['Traffic'],
  aggregateTypes: ['p95th_bits_per_sec'],
  lookback_seconds: 86400,
  all_devices: true,
  show_overlay: false,
  show_total_overlay: true,
  filterDimensionsEnabled: true,
  filterDimensionName: 'Total',
  filterDimensionOther: false,
  filterDimensionSort: true,
  filterDimensions: {
    filterGroups: $app.isSubtenant ? filterDimensionGroups.slice(1, 2) : filterDimensionGroups
  }
};
