import Collection from 'core/model/Collection';
import Subscription from './Subscription';

class SubscriptionCollection extends Collection {
  get url() {
    return '/api/ui/subscriptions/';
  }

  get model() {
    return Subscription;
  }

  get filterFieldWhitelist() {
    return new Set(['title', 'recipients']);
  }
}

export default SubscriptionCollection;
