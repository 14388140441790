export default class DeckFactory {
  widgets = [];

  layout = { lg: [], sm: [] };

  layoutX = 0;

  layoutY = 0;

  smallY = 0;

  idx = 1;

  idPrefix = 'i_generic';

  constructor(idPrefix) {
    this.idPrefix = idPrefix;
  }

  // sets layout values to add further widgets to a new row. Pass a height value matching the tallest widget in the
  // previous row to align new row perfectly
  newRow = (h) => {
    this.layoutX = 0;
    this.layoutY += h;
  };

  createWidget = ({ name, config, h, w, smallH, smallW, idPrefix }) => {
    const { idx, layoutX, layoutY, smallY } = this;
    const id = `${idPrefix || this.idPrefix}_${idx}`;
    this.widgets.push({
      id,
      widgetName: name,
      config
    });
    this.layout.lg.push({ i: id, x: layoutX, y: layoutY, h, w });
    this.layout.sm.push({ i: id, x: 0, y: smallY, w: smallW || 1, h: smallH, maxW: w });
    this.idx += 1;
    this.layoutX += w;
    this.smallY += smallH;
  };
}
