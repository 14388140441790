import { computed } from 'mobx';
import Model from 'core/model/Model';
import { get, isEmpty } from 'lodash';

import { TASK_TYPES } from 'shared/synthetics/constants';
import { safelyParseJSON } from 'core/util';
import { convertDnsHealthDataToArray } from 'app/views/synthetics/utils/syntheticsUtils';

class DnsResultModel extends Model {
  @computed
  get agent() {
    return this.get('agent_alias') || (this.get('agent_id') ? this.get('agent_id').toString() : '');
  }

  @computed
  get target() {
    return this.get('server');
  }

  getSortValue(field) {
    if (field === 'health') {
      const sort = ['critical', 'warning', 'healthy'];
      return sort.indexOf(this.get('overall_health'));
    }

    if (field === 'agent') {
      return this.agent.toLowerCase();
    }

    return super.getSortValue(field);
  }

  deserialize(data) {
    const { agent, health, overall_health, server, ...rest } = data;
    let { pingHealth } = data;
    const agent_alias = agent ? agent.get('agent_alias') : 'unknown';
    const agent_id = get(agent, 'id');
    const agent_type = agent ? agent.get('agent_type') : undefined;
    const task_type = get(health, 'task_type');
    const parsedHealthData = safelyParseJSON(get(health, 'data'));
    // use to mask everything that's not dns.

    if (!pingHealth) {
      pingHealth = {};
    }

    const hasDnsHealth = task_type === TASK_TYPES.DNS;
    const hasPingHealth =
      task_type === TASK_TYPES.PING ||
      task_type === TASK_TYPES.KNOCK ||
      pingHealth?.task_type === TASK_TYPES.PING ||
      pingHealth?.task_type === TASK_TYPES.KNOCK;
    const hasError = task_type === TASK_TYPES.ERROR;
    // Agent Error
    let agent_error;
    if (hasError && !isEmpty(parsedHealthData)) {
      const [{ code, message }] = parsedHealthData;
      agent_error = { code, message };
    }

    // DNS Status Health now comes from the Other Healths
    let dns_status_health;
    if (get(health, 'other_healths')?.dns_status_code_health) {
      dns_status_health = get(health, 'other_healths').dns_status_code_health;
    } else {
      dns_status_health = hasDnsHealth ? get(health, 'status_health') : undefined;
    }

    return {
      id: `${agent_id}-${server || this.optimisticId}`, // fallback to the system-generated id to guarantee id uniqueness
      agentModel: agent,
      agent_composite_id: `${agent_alias}-${agent_id}`,
      agent_id,
      agent_alias,
      agent_type,
      avg_latency: hasPingHealth ? pingHealth.avg_latency : undefined,
      latency_health: hasPingHealth ? pingHealth.latency_health : undefined,
      avg_jitter: hasPingHealth ? pingHealth.avg_jitter : undefined,
      jitter_health: hasPingHealth ? pingHealth.jitter_health : undefined,
      packet_loss: hasPingHealth ? pingHealth.packet_loss : undefined,
      packet_loss_health: hasPingHealth ? pingHealth.packet_loss_health : undefined,
      dns_avg_latency: hasDnsHealth ? health.avg_latency : undefined,
      dns_latency_health: hasDnsHealth ? health.latency_health : undefined,
      task_type: !isEmpty(pingHealth) ? pingHealth.task_type : task_type,
      server,
      dns_data: hasDnsHealth ? convertDnsHealthDataToArray(health) : undefined,
      dns_status: hasDnsHealth ? get(health, 'status') : undefined,
      dns_status_health,
      other_healths: hasDnsHealth ? get(health, 'other_healths') : undefined,
      overall_health,
      agent_error,
      ...rest
    };
  }
}

export default DnsResultModel;
