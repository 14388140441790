import { action, computed, observable } from 'mobx';
import { countBy, orderBy } from 'lodash';
import moment from 'moment';

import Collection from 'core/model/Collection';
import { getStartAndEndFromLookback } from 'core/util/dateUtils';
import { agentMatrixTestTypes } from 'app/util/constants';
import { getTestTypeLabel, getWorstHealth } from 'app/views/synthetics/utils/syntheticsUtils';
import { RECENTLY_ADDED_DAYS, TEST_TYPES, AGENT_MATRIX_TEST_TYPES } from 'shared/synthetics/constants';
import TestModel from './TestModel';

class TestCollection extends Collection {
  get url() {
    return '/api/ui/synthetics/tests';
  }

  get model() {
    return TestModel;
  }

  get defaultSortState() {
    return { field: 'display_name', direction: 'asc' };
  }

  get secondarySort() {
    return { field: 'display_name', direction: 'asc' };
  }

  @observable
  healthLoaded = false;

  @observable
  healthLoading = false;

  @observable
  latestResultsLoading = false;

  @observable
  incidentLogTestAlarmsLoading = false;

  @observable
  incidentLogTestAlarmsLoaded = false;

  // async fetchAggregateTestResults(ids, options = {}) {
  //   const { $syn } = this.store;
  //   const { startDate, endDate, preset = false, group_by = 'agent', task_type } = options;
  //   const data = { ids, group_by, task_type, start_time: startDate, end_time: endDate };
  //   return $syn.requests.fetchAggregateTestResults(data, { preset })
  // }

  // async fetchBGPMonitorStatus(test_id, options = {}) {
  //   const { synTestTypeBGP } = this.prepLookupsByTestPolicies([test_id]);
  //   const { policies } = synTestTypeBGP;
  //   const endDate = getStartAndEnd(options)[1] * 1000;
  //   const start = moment
  //     .utc(endDate)
  //     .subtract(60, 'seconds')
  //     .toISOString();
  //   const end = moment.utc(endDate).toISOString();

  //   return $syn.alarms.load({ policies: policies.map(({ id }) => id), start, end, isBgp: true }).then(alarms =>
  //     get(findCurrentHealthFromAlarms(alarms), test_id, 'healthy')
  //   );
  // }

  async fetchS3Object({ objectKey }) {
    const { $syn } = this.store;

    if (objectKey) {
      return $syn.requests.fetchS3Object({ objectKey }).catch(() => null);
    }

    return Promise.resolve(null);
  }

  @action
  async loadTestHealth() {
    const { $syn } = this.store;

    if (!this.healthLoading) {
      this.healthLoading = true;
      $syn.requests.getTestHealth().then((health) => {
        const ids = this.get().map(({ id }) => id);
        for (let i = 0; i < ids.length; i += 1) {
          const id = ids[i];
          const test = this.get(id);
          const { alarm_health, bgp_alarm_health } = health[id] || {};

          test.set({
            health: getWorstHealth(alarm_health, bgp_alarm_health),
            healthLoaded: true
          });
        }
        this.lastUpdated = Date.now();
        // If collection has sortState field of any of the updated health related fields, re-sort to stay in sync.
        const { field } = this.sortState;
        if (['healthStatusRaw', 'health'].includes(field)) {
          this.sort();
        }

        this.healthLoaded = true;
        this.healthLoading = false;

        this.loadTestErrorDetails(ids);
      });
    }
  }

  @action
  async loadLatestTestResults(ids) {
    const { $syn } = this.store;
    const { start, end } = getStartAndEndFromLookback(60, 120);

    if (!this.latestResultsLoading) {
      this.latestResultsLoading = true;
      $syn.requests
        .fetchTestResults({ ids, start_time: start.unix(), end_time: end.unix() }, { preset: false })
        .then(({ tests_health }) => {
          this.latestResultsLoading = false;
          this.lastUpdated = Date.now();

          for (let i = 0; i < ids.length; i += 1) {
            const id = ids[i];
            const test = this.get(id);

            if (test) {
              test.set({ latestResult: tests_health[id] });
            }
          }
        });
    }
  }

  getAlarmDatesFromDatePicker({ lookbackSeconds, startDate, endDate }) {
    if (lookbackSeconds) {
      const { start, end } = getStartAndEndFromLookback(lookbackSeconds, 120);
      return {
        start: start.toISOString(),
        end: end.toISOString()
      };
    }

    return {
      start: moment(startDate * 1000).toISOString(),
      end: moment(endDate * 1000).toISOString()
    };
  }

  @action
  async loadTestAlarms({ lookbackSeconds, startDate, endDate, ids = [] }) {
    const { $syn } = this.store;
    const opts = this.getAlarmDatesFromDatePicker({ lookbackSeconds, startDate, endDate });

    this.alarmsLoading = true;
    return $syn.requests.getTestAlarms({ ...opts, ids }).then((resp) => {
      this.alarmsLoading = false;
      this.alarmsLoaded = true;

      return resp;
    });
  }

  @action
  async loadTestErrorDetails(ids) {
    const { $syn } = this.store;
    const start_time = moment.utc().startOf('minute').subtract(3, 'minute').unix();
    const end_time = start_time + 24 * 60 * 60;
    const data = { ids, start_time, end_time };

    return $syn.requests.fetchTestErrorDetails(data).then(({ errors }) => {
      for (let i = 0; i < errors.length; i += 1) {
        const error = errors[i];
        const test = this.get(error.test_id);

        if (test) {
          test.set({ healthErrors: error.messages.map(({ message }) => message) });
        }
      }
    });
  }

  @action
  async loadIncidentLogTestAlarms({ lookbackSeconds, startDate, endDate, ids = [] }) {
    const { $syn } = this.store;
    const opts = this.getAlarmDatesFromDatePicker({ lookbackSeconds, startDate, endDate });

    this.incidentLogTestAlarmsLoading = true;

    return $syn.requests.getIncidentLog({ ...opts, ids }).then(
      action((alarms) => {
        this.incidentLogTestAlarmsLoading = false;
        this.incidentLogTestAlarmsLoaded = true;

        return { alarms };
      })
    );
  }

  @computed
  get testHealthBreakdown() {
    return countBy(this.get(), (m) => {
      const isPaused = m.get('test_status') === 'P';
      const health = m.get('health');

      if (isPaused) {
        // don't count paused tests w/active ones, even if they have health property
        return 'paused';
      }

      if (!health) {
        // a pending test has never received results
        // it has no actual test_status value, but it is active
        return m.get('results_first_valid') ? 'failing' : 'pending';
      }

      return health;
    });
  }

  @computed
  get recentlyAddedTests() {
    const currentMoment = moment();
    const models = this.get().filter(
      (model) => currentMoment.diff(moment(model.get('edate')), 'days') <= RECENTLY_ADDED_DAYS
    );

    return orderBy(models, ['edate'], ['desc']);
  }

  @computed
  get userConfiguredTestTypeOptions() {
    const optionsHash = {};
    this.get().forEach((model) => {
      const test_type = model.get('test_type');
      test_type ? (optionsHash[test_type] = getTestTypeLabel(test_type)) : (optionsHash['-'] = 'None');
    });
    const optionsArray = Object.keys(optionsHash).map((value) => ({ value, label: optionsHash[value] }));
    return orderBy(optionsArray, 'label');
  }

  @computed
  get testNamesOptions() {
    const optionsHash = {};
    this.get().forEach((model) => {
      const test_id = model.get('id');
      const test_name = model.get('display_name');
      const test_type = model.get('test_type');
      optionsHash[test_name] = { value: test_id, label: `${test_name} (${test_type})` };
    });
    const optionsArray = Object.keys(optionsHash).map((key) => ({
      value: optionsHash[key].value,
      label: optionsHash[key].label
    }));
    return optionsArray;
  }

  @computed
  get meshTests() {
    return this.get().filter((model) => agentMatrixTestTypes.includes(model.get('test_type')));
  }

  @computed
  get modelIdByRuleId() {
    const ruleHash = {};

    this.models.forEach((model) => {
      const rule = model.get('config.alerting.rule_id');
      const ktracRule = model.get('config.alerting.ktrac_rule_id');

      if (rule) {
        ruleHash[rule] = model.id;
      }

      if (ktracRule) {
        ruleHash[ktracRule] = model.id;
      }
    });

    return ruleHash;
  }

  get testsAsCsv() {
    const rows = [];
    const header = ['Test Name', 'Target', 'Tags', 'Created By', 'Last Updated', 'Agents', 'Monthly Credits', 'id'];

    rows.push(header);

    this.get().forEach((model) => {
      const values = [];

      values.push(`"${model.get('display_name')}"`);
      values.push(`"${model.get('config.target.value') ?? ''}"`);
      const labels = model?.labels?.map((label) => label.get('name'))?.join(',') ?? '';

      values.push(`"${labels}"`);
      values.push(`"${model.get('created_by.user_full_name')}"`);
      values.push(`"${model.get('edate')}"`);
      values.push(`"${model.get('config.agents.length')}"`);
      values.push(`"${model.get('creditBurnRate')}"`);
      values.push(`"${model.get('id')}"`);

      rows.push(values);
    });
    return rows.map((row) => row.join(',')).join('\r\n');
  }

  getTestByType(type, value) {
    return this.get().find((model) => {
      const test_type = model.get('test_type');
      if (test_type === type) {
        if (AGENT_MATRIX_TEST_TYPES.includes(test_type)) {
          return model.get('config.agents').filter((agent) => value.includes(agent)).length !== 0;
        }
        const configValue = model.get('config.target.value');
        if (test_type === TEST_TYPES.IP_ADDRESS) {
          return `${configValue}`.split(',').includes(value);
        }
        if (Array.isArray(configValue)) {
          return configValue.includes(value);
        }
        return configValue === value;
      }
      return false;
    });
  }
}

export default TestCollection;
