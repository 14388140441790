import Model from 'core/model/Model';
import { computed } from 'mobx';

import $dictionary from 'app/stores/$dictionary';
import { POLICY_APPLICATION_LABELS, POLICY_APPLICATIONS, POLICY_MODES } from 'shared/alerting/constants';
import policySerializations from 'app/stores/alerting/policySerializations';
import { getMetricOption } from 'app/util/policies';
import { getPolicyManageUrl } from 'app/stores/alerting/policyUtils';

class PolicyLibraryModel extends Model {
  get urlRoot() {
    return '/api/ui/alerting/policies';
  }

  get sortValues() {
    return {
      primaryDimension: () => this.primaryDimension || '',
      primaryMetric: () => this.primaryMetric || '',
      name: () => this.get('name').trim().toLowerCase()
    };
  }

  get activationMode() {
    return this.get('activationSettings.mode');
  }

  @computed
  get manageUrl() {
    return getPolicyManageUrl(this.application, false, this.isUpDownPolicy);
  }

  @computed
  get isToggleMode() {
    return this.activationMode === POLICY_MODES.TOGGLE;
  }

  @computed
  get applicationLabel() {
    return POLICY_APPLICATION_LABELS[this.application];
  }

  @computed
  get application() {
    return this.get('application') || POLICY_APPLICATIONS.CORE;
  }

  @computed
  get primaryDimension() {
    const primaryDimension = (this.get('dimensions') || [])[0];
    return $dictionary.dimensionLabelsMap[primaryDimension]?.label || primaryDimension;
  }

  @computed
  get primaryMetric() {
    const primaryMetric = (this.get('metrics') || [])[0];
    return getMetricOption(primaryMetric, this.get('selectedMeasurement')).label;
  }

  @computed
  get isMetricPolicy() {
    return this.get('application') === POLICY_APPLICATIONS.METRIC;
  }

  @computed
  get isUpDownPolicy() {
    return this.isMetricPolicy && this.isToggleMode;
  }

  removeThresholdsWithNoConditions() {
    const thresholds = this.get('thresholds') || [];
    const newThresholds = thresholds.filter((threshold) => threshold.conditions.length > 0);
    this.set('thresholds', newThresholds);
  }

  getPolicyCloneDataFromTemplate(overrides = {}) {
    // TODO strip ids on top and sub entities, get it ready for cloning
    const attributes = this.get();

    delete attributes.id;
    delete attributes.companyID;
    delete attributes.creationTime;
    attributes.minTrafficValue = 0;

    attributes.activationSettings = attributes.activationSettings || {};
    attributes.activationSettings.disableLegacyFlow = true;
    delete attributes.activationSettings.alertManagerConfig;

    attributes.deviceSelector = attributes.deviceSelector || {};
    attributes.deviceSelector.labels = attributes.deviceSelector.labels || [];
    attributes.deviceSelector.names = attributes.deviceSelector.names || [];
    attributes.deviceSelector.sites = attributes.deviceSelector.sites || [];
    attributes.deviceSelector.synTest = null;

    delete attributes.lastEditTime;
    delete attributes.silentModeExpireDate;
    attributes.status = 'active';
    delete attributes.userID;

    attributes.thresholds.forEach((threshold) => {
      delete threshold.policyID;
      delete threshold.thresholdID;
    });

    return { ...attributes, ...overrides };
  }

  deserialize = (data) => policySerializations.deserializeAll(data);
}

export default PolicyLibraryModel;
