import Model from 'core/model/Model';
import { getIPSortValue } from 'core/util/sortUtils';

class BgpResultModel extends Model {
  deserialize(data) {
    return data;
  }

  get sortValues() {
    return {
      prefix: (model) => getIPSortValue(model.prefix)
    };
  }
}

export default BgpResultModel;
