import api from 'core/util/api';
import {
  checkChannelForConjunction,
  findNotificationsByPolicy as utilFindNotificationsByPolicy,
  findNotificationsByPolicyDescriptor as utilFindNotificationsByPolicyDescriptor
} from 'shared/notifications/notificationUtilities';

import SupportedChannelsCollection from './SupportedChannelsCollection';
import NotificationsCollection from './NotificationsCollection';
import NotificationModel from './NotificationModel';

export class NotificationsStore {
  collection = new NotificationsCollection();

  supportedChannels = new SupportedChannelsCollection();

  userChannel = new NotificationModel();

  // these channel types support OAUTH2.0 tokens, if users can configure integration for them
  oAuthSupportedChannelTypes = ['custom-webhook'];

  constructor(options = {}) {
    Object.assign(this, options);
  }

  initialize() {
    const { $app } = this.store;
    if (!$app.isSubtenant) {
      this.fetchUserChannel();
    }
  }

  get usedByOptions() {
    return [
      { value: 'policies', label: 'Policies' },
      { value: 'mitigationMethod', label: 'Mitigation Methods' },
      { value: 'insights', label: 'Insights' },
      { value: 'insightFamilies', label: 'Insight Families' },
      { value: 'subpolicies', label: 'Tenant policies' },
      { value: 'synthTest', label: 'Synthetics Tests' }
    ];
  }

  channelTypeSupportOAuth(channelType) {
    return this.oAuthSupportedChannelTypes.includes(channelType);
  }

  addNotificationsToThresholds(thresholds = [], policy = {}) {
    const ruleId = policy.activationSettings?.alertManagerConfig?.ruleId;

    return thresholds.map((threshold) => {
      const channelIds = this.findNotificationsByPolicyDescriptor(threshold, ruleId).map(({ id }) => id);

      return {
        ...threshold,
        notificationChannels: channelIds
      };
    });
  }

  addNotificationsToNmsPolicyLevels(levels = [], policy = {}) {
    const ruleId = policy.rule_id;

    return levels.map((level) => {
      const channelIds = this.findNotificationsByPolicyDescriptor(level, ruleId).map(({ id }) => id);

      return {
        ...level,
        notificationChannels: channelIds
      };
    });
  }

  // find notifications that use this specific threshold or level (policy concepts)
  findNotificationsByPolicyDescriptor = (descriptor, ruleId) =>
    utilFindNotificationsByPolicyDescriptor({ descriptor, ruleId, channels: this.collection.get() });

  // find all notifications that use any threshold on this policy
  findNotificationsByPolicy = (policy) => utilFindNotificationsByPolicy({ policy, channels: this.collection.get() });

  // find notifications that use this mitigation method
  findNotificationsByMitigationMethod = (mitigationMethodID) =>
    this.collection.get().filter((channel) =>
      checkChannelForConjunction(channel, {
        selectors: [{ lhs: 'mitigationMethod', op: '=', rhs: `${mitigationMethodID}` }]
      })
    );

  fetchUserChannel = () =>
    api.get('/api/ui/notifications/user').then((response) => {
      if (response.channel) {
        this.userChannel.set(this.userChannel.deserialize(response.channel));
      }
    });

  subscribeInsight = ({ subscribe = true, subscribeFamily = true, model }) =>
    api
      .post('/api/ui/notifications/subscribeInsight', {
        data: {
          subscribe,
          subscribeFamily,
          insightName: model.get('insightName'),
          insightFamily: model.family
        }
      })
      .then(() => this.fetchUserChannel());

  previewChannel = (channelID) =>
    api.get(`/api/ui/notifications/preview/${channelID}`).then((response) => {
      if (response.error) {
        return {
          content: '',
          error: response.error
        };
      }
      try {
        const parsed = JSON.parse(response.payload);

        return {
          content: JSON.stringify(parsed, null, '  '),
          error: null
        };
      } catch (e) {
        return {
          content: (response && response.payload) || '',
          error: null
        };
      }
    });

  testChannel = (channelID) => api.get(`/api/ui/notifications/test/${channelID}`);
}

export default new NotificationsStore();
