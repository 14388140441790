import Model from 'core/model/Model';

export default class Category extends Model {
  get urlRoot() {
    return '/api/ui/reports/categories';
  }

  get messages() {
    return {
      create: `Category "${this.get('name')}" was added successfully`,
      update: `Category "${this.get('name')}" was updated successfully`,
      destroy: `Category "${this.get('name')}" was removed successfully. Any child views were moved to "Uncategorized".`
    };
  }

  get sortValues() {
    return { name: () => this.get('name').toLowerCase() };
  }

  get omitDuringSerialize() {
    return ['preset'];
  }

  get name() {
    return this.get('name');
  }

  get isPreset() {
    return this.get('preset');
  }

  deserialize(data = {}) {
    data.preset = data.preset === undefined ? data.company_id === null : data.preset;
    return super.deserialize(data);
  }
}
