import { buildFilterGroup } from 'core/util/filters';

import getBaseQuery from './getBaseQuery';
import getQueryFilterGroups from './getQueryFilterGroups';

export default function uniqueAsnsQuery(queryFilters, asn) {
  return {
    ...getBaseQuery({ direction: 'dst', queryFilters, asn }),
    filters: {
      connector: 'All',
      filterGroups: [
        buildFilterGroup({
          filters: [
            { filterField: 'dst_bgp_aspath', filterValue: `_${asn}_`, operator: '~' },
            { filterField: 'dst_as', operator: '<>', filterValue: asn },
            { filterField: 'i_dst_network_bndry_name', operator: '=', filterValue: 'external' }
          ]
        }),
        ...getQueryFilterGroups(queryFilters, 'dst')
      ]
    },
    topx: 8,
    depth: 75,
    viz_type: 'gauge',
    units: ['unique_dst_as', 'bytes', 'packets'],
    aggregateTypes: ['avg_dst_as', 'p95th_dst_as', 'max_dst_as', 'p95th_bits_per_sec', 'p95th_pkts_per_sec'],
    aggregateThresholds: {
      p95th_bits_per_sec: 0,
      avg_dst_as: 0,
      p95th_dst_as: 0,
      max_dst_as: 0,
      p95th_pkts_per_sec: 0
    },
    aggregates: [
      {
        value: 'avg_dst_as',
        column: 'f_hll(dst_as.agg0,0.0001)',
        fn: 'average',
        label: 'Unique ASNs Destination Average',
        unit: 'unique_dst_as',
        group: 'Unique ASNs Destination',
        origLabel: 'Average',
        sample_rate: 1,
        raw: true,
        name: 'avg_dst_as'
      },
      {
        value: 'p95th_dst_as',
        column: 'f_hll(dst_as.agg0,0.0001)',
        fn: 'percentile',
        rank: 95,
        label: 'Unique ASNs Destination 95th Percentile',
        unit: 'unique_dst_as',
        group: 'Unique ASNs Destination',
        origLabel: '95th Percentile',
        sample_rate: 1,
        name: 'p95th_dst_as'
      },
      {
        value: 'max_dst_as',
        column: 'f_hll(dst_as.agg0,0.0001)',
        fn: 'max',
        label: 'Unique ASNs Destination Max',
        unit: 'unique_dst_as',
        group: 'Unique ASNs Destination',
        origLabel: 'Max',
        sample_rate: 1,
        name: 'max_dst_as'
      },
      {
        value: 'p95th_bits_per_sec',
        column: 'f_sum_both_bytes',
        fn: 'percentile',
        label: 'Bits/s Sampled at Ingress + Egress 95th Percentile',
        rank: 95,
        unit: 'bytes',
        group: 'Bits/s Sampled at Ingress + Egress',
        origLabel: '95th Percentile',
        sample_rate: 1,
        name: 'p95th_bits_per_sec'
      },
      {
        value: 'p95th_pkts_per_sec',
        column: 'f_sum_both_pkts',
        fn: 'percentile',
        rank: 95,
        label: 'Packets/s Sampled at Ingress + Egress 95th Percentile',
        unit: 'packets',
        group: 'Packets/s Sampled at Ingress + Egress',
        origLabel: '95th Percentile',
        sample_rate: 1,
        name: 'p95th_pkts_per_sec'
      }
    ]
  };
}
