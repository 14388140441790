import { action } from 'mobx';

import Collection from 'core/model/Collection';
import { savedViewHasDependencyFailures } from 'app/components/dataviews/dependencies/ReportDependencyChecker';

import SavedView from './SavedView';

class SavedViewCollection extends Collection {
  get url() {
    return '/api/ui/saved-views';
  }

  get model() {
    return SavedView;
  }

  get defaultGroupBy() {
    return 'category.name';
  }

  get presetFilters() {
    return [
      {
        label: 'Personal',
        fn: (model) => model.isUserLevel
      },
      {
        label: 'Company',
        fn: (model) => model.isCompanyLevel
      },
      {
        label: 'Presets',
        fn: (model) => model.isPreset
      }
    ];
  }

  get defaultSortState() {
    return {
      field: 'share_level',
      direction: 'asc'
    };
  }

  get favorites() {
    return this.unfiltered.filter((view) => view.isFavorite);
  }

  @action
  checkDependencies() {
    this.models.forEach((model) => (model.hasDependencyFailures = savedViewHasDependencyFailures(model)));
  }
}

export default SavedViewCollection;
