import { action, computed } from 'mobx';
import api from 'core/util/api';
import $auth from 'app/stores/$auth';
import LabelCollection from './LabelCollection';

export class LabelStore {
  labels = new LabelCollection();

  constructor(options = {}) {
    Object.assign(this, options);
  }

  initialize() {
    this.labels.fetch();
  }

  /**
   * Update label mappings table for type, items and label_id (optional) provided.
   * @param {String} type 'synth_agent', 'synth_test', 'device', etc.
   * @param {String} method 'INSERT' or 'DELETE' (by this.id)
   * @param {Array} items Depending on type, an arr of agent_id, test_id, etc.
   * @param {Array} [labels] Optional array of label_ids used for INSERT statements
   */
  @action
  updateItems(type, method, items, labels) {
    const data = { type, method, items, labels };
    this.labels.setRequestStatus('fetching');
    return api
      .post('/api/ui/labels/items', { data })
      .then((resp) => this.labels.processData(resp))
      .then((res) => {
        this.labels.setLastUpdated();
        this.labels.setRequestStatus(null);
        return res;
      });
  }

  /**
   * @param itemType i.e. 'synth_agent', 'synth_test, 'device', 'policy', 'dashboard', 'saved_view'
   * @param [itemId] optional; filter further by id
   */
  getLabels(itemType, itemId) {
    return this.labels.get().filter((m) => {
      const items = m.get('items');
      return !!items.find((i) => {
        if (itemId) {
          return `${i.item_id}` === `${itemId}` && i.item_type === itemType;
        }
        return i.item_type === itemType;
      });
    });
  }

  getLabelsById(labelIds) {
    return this.labels.get().filter((m) => labelIds.includes(m.id));
  }

  @computed
  get options() {
    return this.getLabelOptions(this.companyLabels.models);
  }

  @computed
  get companyLabels() {
    return new LabelCollection(
      this.labels.get().filter((label) => label.get('company_id') === $auth.getActiveUserProperty('company_id'))
    );
  }

  getLabelOptions(labels, includeId = false) {
    const options = labels.map((label) => {
      const labelOption = label.option || {
        value: label.id,
        label: label.name,
        bg: label.color,
        color: label.color
      };

      // in case there's an option defined on the label, but we still want the id
      if (!labelOption.id && includeId) {
        labelOption.id = label.id;
      }
      labelOption.isPreset = label.isPreset;

      return labelOption;
    });

    return options.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
  }

  getLabelOptionsFromIds(labelIds) {
    return this.getLabelOptions(
      this.labels.get().filter((label) => labelIds.includes(label.id)),
      true
    );
  }

  getLabelsFromModelOrLocation(model, location) {
    return (model?.labels?.length > 0 ? model.labels : location?.state?.labels) || [];
  }

  labelMultiSelectModelFilter(modelLabels, filterLabelIds) {
    const modelLabelIds = modelLabels.map((label) => label.id);
    const filterLabelIdsLen = filterLabelIds.length;
    if (filterLabelIdsLen !== 0) {
      for (let i = 0; i < filterLabelIdsLen; i += 1) {
        if (modelLabelIds.includes(filterLabelIds[i])) {
          return true;
        }
      }
    }
    return false;
  }
}

export default new LabelStore();
