import { observable, action, computed } from 'mobx';

import Model from 'core/model/Model';
import $dictionary from 'app/stores/$dictionary';
import $interfaceClass from 'app/stores/interface/$interfaceClass';

class RuleModel extends Model {
  // tracks whether or not a rule has been evaluated at least once.
  @observable
  isTested = false;

  get defaults() {
    return {
      rank: 0,
      enabled: true,
      auto_classify: true,
      match_clause: {
        match_attribute: 'snmp_alias',
        match_operand: 'contains',
        pattern: ''
      },
      action_set: {
        set_network_boundary: 'auto',
        set_connectivity_type: 'other'
      },
      interfacesClassified: [],
      interfacesMatched: []
    };
  }

  get urlRoot() {
    return '/api/ui/interfaces/rules';
  }

  get omitDuringSerialize() {
    return ['num_matches'];
  }

  get messages() {
    return {
      create: 'Rule was added successfully',
      update: 'Rule was updated successfully',
      destroy: 'Rule was removed successfully'
    };
  }

  @computed
  get readableDisplay() {
    const { match_operand, match_attribute, pattern } = this.get('match_clause');
    return {
      attribute: $dictionary.get(`interfaceClassification.matchAttributes.${match_attribute}`),
      operand: $dictionary.get(`interfaceClassification.operands.${match_operand}`),
      pattern
    };
  }

  @computed
  get isPublicOrPrivateIP() {
    const { match_operand } = this.get('match_clause');
    return match_operand === 'is_public_ip' || match_operand === 'is_private_ip' || match_operand === 'no_ip';
  }

  @computed
  get translated_network_boundary() {
    const { set_connectivity_type, set_network_boundary } = this.get('action_set');

    if (set_network_boundary === 'auto') {
      return $interfaceClass.getDefaultNetworkBoundary(set_connectivity_type);
    }

    return set_network_boundary;
  }

  @computed
  get action_string() {
    const { set_connectivity_type } = this.get('action_set');

    return {
      set_connectivity_type: $dictionary.get(`interfaceClassification.connectivityTypes.${set_connectivity_type}`),
      set_network_boundary: $dictionary.get(
        `interfaceClassification.networkBoundaryTypes.${this.translated_network_boundary}`
      )
    };
  }

  @computed
  get match_attribute() {
    return $dictionary.get(`interfaceClassification.matchAttributes.${this.get('match_clause').match_attribute}`);
  }

  @computed
  get match_operand() {
    const operand = this.get('match_clause').match_operand;
    return operand && $dictionary.get(`interfaceClassification.operands.${operand}`);
  }

  @action
  toggleEnabled = () => {
    this.save({ enabled: !this.get('enabled') }, { toast: false });
  };
}

export default RuleModel;
