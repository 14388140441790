import $metrics from 'app/stores/metrics/$metrics';
import $devices from 'app/stores/device/$devices';

/**
 * @param [device_name] {string}
 * @param [device_ip] {string}
 * @param [mac_address] {string}
 * @returns {DeviceModel | undefined}
 */
export const getDeviceFromMetricsQueryModel = ({ device_name, device_ip, mac_address }) => {
  const name = $metrics.getMetricsDeviceNameFromQueryModel({ device_name, device_ip, mac_address });
  return $devices.byName(name);
};
