import DeckFactory from 'app/stores/decks/DeckFactory';

export default function cloudDeckFactory({ store }) {
  const { $devices } = store;
  const deck = new DeckFactory('i_cloud');
  const { widgets, layout, createWidget, newRow } = deck;

  const hasAws = $devices.hasAWSDevice;
  const hasGcp = $devices.hasGCEDevice;
  const hasOci = $devices.hasOCIDevice;
  const hasAzure = $devices.hasAzureDevice;

  // first row
  if (hasAws) {
    createWidget({
      name: 'awsInventory',
      h: 7,
      w: hasAzure ? 6 : 4,
      smallH: 4
    });
  }
  if (hasAzure) {
    createWidget({
      name: 'azureInventory',
      h: 7,
      w: hasAws ? 6 : 4,
      smallH: 4
    });
  }

  if (hasOci) {
    createWidget({
      name: 'ociInventory',
      h: 7,
      w: hasAws ? 6 : 4,
      smallH: 4
    });
  }
  if (hasAws && hasAzure) {
    newRow(7);
    createWidget({
      name: 'cloudTrafficOverview',
      h: 7,
      w: 8,
      smallH: 4
    });
    createWidget({
      name: 'insightsOverview',
      config: {
        creationTimeDuration: '168h',
        families: ['Hybrid Cloud']
      },
      h: 7,
      w: 4,
      smallH: 4
    });
  } else {
    createWidget({
      name: 'cloudTrafficOverview',
      h: 7,
      w: deck.layoutX > 0 ? 5 : 8,
      smallH: 4
    });
    createWidget({
      name: 'insightsOverview',
      config: {
        creationTimeDuration: '168h',
        families: ['Hybrid Cloud']
      },
      h: 7,
      w: 12 - deck.layoutX,
      smallH: 4
    });
  }
  newRow(7);

  // next row
  if (hasAws) {
    createWidget({
      name: 'vpcByTransitGateway',
      h: 6,
      w: 4,
      smallH: 4
    });
    createWidget({
      name: 'gatewayTraffic',
      h: 6,
      w: 4,
      smallH: 4
    });
    createWidget({
      name: 'awsRejectActions',
      config: { dst: 'vpc', src: 'vpc', metric: 'avg_pkts_per_sec', trf_profile: 'cloud internal' },
      h: 6,
      w: 4,
      smallH: 4
    });
    newRow(6);
  }

  // next row
  if (hasAzure) {
    createWidget({
      name: 'gatewayTraffic',
      config: { cloudType: 'azure', title: 'Azure Gateway Traffic' },
      h: 6,
      w: 4,
      smallH: 4
    });
  }

  if (hasAws) {
    createWidget({
      name: 'cloudTrafficProfile',
      config: { cloud_type: 'aws', cloud_region: 'All Regions' },
      h: 4,
      w: 3,
      smallH: 3
    });
  }

  if (hasAzure) {
    createWidget({
      name: 'cloudTrafficProfile',
      config: { cloud_type: 'azure', cloud_region: 'All Regions' },
      h: 4,
      w: 3,
      smallH: 3
    });
  }

  if (deck.layoutX > 9) {
    newRow(hasAzure ? 6 : 4);
  }

  if (hasGcp) {
    createWidget({
      name: 'cloudTrafficProfile',
      config: { cloud_type: 'gcp', cloud_region: 'All Regions' },
      h: 4,
      w: 3,
      smallH: 3
    });
  }

  if (deck.layoutX > 9) {
    newRow(hasAzure ? 6 : 4);
  }

  if (hasAws || hasAzure || hasGcp) {
    createWidget({
      name: 'cloudFlow',
      h: 4,
      w: deck.layoutX === 0 ? 6 : 3,
      smallH: 3
    });
  }

  return {
    widgets,
    layout
  };
}
