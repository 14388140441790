import { action, computed, observable, toJS } from 'mobx';
import { isEqual } from 'lodash';

import Collection from 'core/model/Collection';
import { STATUS_TO_STATES } from 'app/stores/mitigations/mitigationsConstants';
import ActiveMitigationModel from './ActiveMitigationModel';

export default class ActiveMitigationCollection extends Collection {
  @observable.ref
  serverFilter = {
    lookback: 86400,
    states: ['Waiting', 'Failed', 'Active'].flatMap((status) => STATUS_TO_STATES[status])
  };

  @observable.ref
  totalCount = 0;

  @observable.ref
  totals = {};

  get url() {
    return '/api/ui/mitigations/activeMitigations';
  }

  get fetchMethod() {
    return 'post';
  }

  get model() {
    return ActiveMitigationModel;
  }

  get secondarySort() {
    return { field: 'start', direction: 'desc' };
  }

  get defaultSortState() {
    return {
      field: 'isActive',
      direction: 'desc'
    };
  }

  get queuedFetchKey() {
    return this.id;
  }

  @computed
  get activeCount() {
    return this.models.filter((model) => model.get('isActive') === true).length;
  }

  @computed
  get activeDDoSMitigationsCount() {
    return this.models.filter((model) => model.triggeredByDDoSPolicy).length;
  }

  @computed
  get platformMethodOptions() {
    const platforms = {};
    const methods = {};

    this.each((mitigationModel) => {
      platforms[mitigationModel.get('platformID')] = mitigationModel.get('platformName');
      methods[mitigationModel.get('methodID')] = mitigationModel.get('methodName');
    });

    return {
      platformOptions: Object.keys(platforms).map((key) => ({ value: key, label: platforms[key] })),
      methodOptions: Object.keys(methods).map((key) => ({ value: key, label: methods[key] }))
    };
  }

  @action
  setServerFilter(filter, fetchOptions) {
    if (!isEqual(filter, toJS(this.serverFilter))) {
      this.serverFilter = { ...filter };

      return this.queuedFetch(fetchOptions);
    }

    return this.fetch(fetchOptions);
  }

  getServerFilter() {
    const { mitigations, alarms, keyPartsSearch, exactSearch, ...filter } = toJS(this.serverFilter);

    Object.entries(filter).forEach(([field, value]) => {
      if (!value || value.length === 0) {
        delete filter[field];
      }
    });

    filter.mitigations = mitigations ? [mitigations] : undefined;
    filter.alarms = alarms ? [alarms] : undefined;

    filter.keyPartsPartial = undefined;
    filter.keyPartsExact = undefined;

    if (keyPartsSearch) {
      filter[exactSearch ? 'keyPartsExact' : 'keyPartsPartial'] = [keyPartsSearch];
    }

    return filter;
  }

  @action
  clearFilters() {
    this.serverFilter = {};
    super.clearFilters();
    this.queuedFetch();
  }

  @computed
  get countsByStatus() {
    return this.models.reduce((acc, model) => {
      if (!acc[model.status]) {
        acc[model.status] = 1;
      } else {
        acc[model.status] += 1;
      }

      return acc;
    }, {});
  }

  @computed
  get hasFilter() {
    return (
      Object.keys(this.serverFilter).length > 0 ||
      !!this.filterState ||
      !!this.activePresetFilter ||
      this.discreteFilters.length > 0
    );
  }

  async fetch(options = {}) {
    const { data = {} } = options;
    return super.fetch({
      ...options,
      data: {
        ...this.getServerFilter(),
        ...data
      }
    });
  }

  async queuedFetch(options = {}) {
    const { data = {} } = options;

    this.lastFetched = Date.now();

    return super.queuedFetch({
      ...options,
      data: {
        ...this.getServerFilter(),
        ...data
      }
    });
  }
}
