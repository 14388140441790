import Model from 'core/model/Model';
import InsightTypeOverrides, { getIcon } from './insightTypes';

class InsightDefinition extends Model {
  get defaults() {
    return {
      insightName: '',
      label: '',
      icon: 'unknown'
    };
  }

  get urlRoot() {
    return '/api/ui/insights/definitions';
  }

  deserialize = (data) => {
    const override = InsightTypeOverrides[data.insightName] || {};
    const icon = data.icon ? getIcon(data.icon) : override.icon;
    const description = override.description || data.description;
    const unknown = '(unknown insight)';
    let { family } = data;

    if (data.insightName.startsWith('custom.insight')) {
      family = 'Custom Insights';
    }

    return {
      ...this.defaults,
      label: data.insightDisplayName || data.insightName || unknown,
      ...override,
      ...data,
      icon: icon || getIcon(),
      description,
      family
    };
  };
}

export default InsightDefinition;
