import Collection from 'core/model/Collection';
import KproxyAgentModel from './KproxyAgentModel';

class KproxyAgentCollection extends Collection {
  get defaultSortState() {
    return {
      field: 'last_access',
      direction: 'desc'
    };
  }

  get url() {
    return '/api/ui/kproxyAgent';
  }

  get model() {
    return KproxyAgentModel;
  }

  get minFetchInterval() {
    return 30000;
  }

  get presetFilters() {
    return [
      {
        label: 'Healthy',
        fn: (model) => model.get('health') === 'Healthy'
      },
      {
        label: 'Warning',
        fn: (model) => model.get('health') === 'Warning'
      },
      {
        label: 'Critical',
        fn: (model) => model.get('health') === 'Critical'
      },
      {
        label: 'Pending',
        fn: (model) => model.get('health') === 'Pending'
      }
    ];
  }

  get filterFieldWhitelist() {
    return new Set([
      'agent_built_on',
      'agent_description',
      'agent_ip',
      'agent_name',
      'agent_private_ip',
      'agent_version',
      'health',
      'last_access',
      'version',
      'siteName',
      'deviceNames',
      'agentUserName',
      'agentUserEmail'
    ]);
  }
}

export default KproxyAgentCollection;
