import Model from 'core/model/Model';

class ServiceHistoryModel extends Model {
  get defaults() {
    return {
      interfaces: []
    };
  }

  get urlRoot() {
    return '/api/ui/ott/service-history';
  }
}

export default ServiceHistoryModel;
