export const InboundFilters = {
  filters: [
    {
      filterField: 'simple_trf_prof',
      operator: '=',
      filterValue: 'outbound'
    }
  ]
};

export const InboundFilterGroups = {
  filterGroups: []
};

export const OutboundFilters = {
  filters: [
    {
      filterField: 'simple_trf_prof',
      operator: '=',
      filterValue: 'inbound'
    }
  ]
};

export const OutboundFilterGroups = {
  filterGroups: []
};

const filterDimensionGroups = [
  {
    name: 'Inbound',
    named: true,
    connector: 'All',
    not: false,
    autoAdded: '',
    saved_filters: [],
    ...InboundFilters,
    ...InboundFilterGroups
  },
  {
    name: 'Outbound',
    named: true,
    connector: 'All',
    not: false,
    autoAdded: '',
    saved_filters: [],
    ...OutboundFilters,
    ...OutboundFilterGroups
  }
];

export default {
  metric: ['Traffic'],
  aggregateTypes: ['p95th_bits_per_sec'],
  lookback_seconds: 86400,
  all_devices: true,
  show_overlay: false,
  show_total_overlay: true,
  filterDimensionsEnabled: true,
  filterDimensionName: 'Total',
  filterDimensionOther: false,
  filterDimensionSort: true,
  filterDimensions: {
    filterGroups: filterDimensionGroups
  }
};
