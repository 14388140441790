import Model from 'core/model/Model';
import $auth from 'app/stores/$auth';

class ManualMitigation extends Model {
  constructor({ $mitigations, ...attributes }) {
    super(attributes);
    this.$mitigations = $mitigations;
  }

  get urlRoot() {
    return '/api/ui/mitigations/manual-mitigate';
  }

  get defaults() {
    return {
      pairingID: null,
      action: 'start_mitigation',
      alertKey: '',
      comment: '',
      autoStopTTL: 0
    };
  }

  getManualMitigationData = (data) => {
    const adjustedData = {};
    const dummyIp = '127.42.42.42';
    const [platformID, methodID] = data.pairingID.split('-');
    const platformMethod = this.$mitigations.platformsCollection.getPlatformMethod({ platformID, methodID });

    if (platformMethod) {
      const neitherSourceNorDestInferred =
        platformMethod.method.type === 'flowspec' &&
        !platformMethod.method.details.destIpCidr?.infer &&
        !platformMethod.method.details.sourceIpCidr?.infer;

      adjustedData.platformID = platformID;
      adjustedData.methodID = methodID;

      // Flowspec IP/CIDRs have been configured in the mitigation method and are not being Inferred.
      // Send in the dummy IP to the API instead of prompting the user to enter it.
      if (neitherSourceNorDestInferred) {
        adjustedData.alertKey = dummyIp;
      }
    }

    return adjustedData;
  };

  serialize(data) {
    const dataToSerialize = {
      action: data.action,
      srcPort: data.srcPort,
      dstPort: data.dstPort,
      alertKey: data.alertKey,
      comment: `[${$auth.activeUser.user_name} manually started] ${data.comment}`,
      autoStopTTL: data.autoStopTTL,
      ...this.getManualMitigationData(data)
    };

    if (data.tenantID) {
      dataToSerialize.applicationMetadata = {
        type: 'subtenant',
        tenantID: data.tenantID
      };
    }

    return super.serialize(dataToSerialize);
  }
}

export default ManualMitigation;
