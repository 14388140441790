import StackedAreaView, { config as stackedAreaConfig } from 'app/components/dataviews/views/StackedArea';
import LineView, { config as lineConfig } from 'app/components/dataviews/views/Line';
import StackedAreaHundredPercentView, {
  config as stackedAreaHundredPercentConfig
} from 'app/components/dataviews/views/StackedAreaHundredPercent';
import StackedColumnView, { config as stackedColumnConfig } from 'app/components/dataviews/views/StackedColumn';
import BarView, { config as barConfig } from 'app/components/dataviews/views/Bar';
import PieView, { config as pieConfig } from 'app/components/dataviews/views/Pie';
import SankeyView, { config as sankeyConfig } from 'app/components/dataviews/views/Sankey';
import SunburstView, { config as sunburstConfig } from 'app/components/dataviews/views/Sunburst';
import MatrixView, { config as matrixConfig } from 'app/components/dataviews/views/Matrix';
import GaugeView, { config as gaugeConfig } from 'app/components/dataviews/views/Gauge';
import LegendTableView, { config as legendTableConfig } from 'app/components/dataviews/views/legend/Legend';
import HorizonView, { config as horizonConfig } from 'app/components/dataviews/views/Horizon';
import GeoHeatMapView, { config as geoHeatMapConfig } from 'app/components/dataviews/views/geoHeatMap';
import RawFlow, { config as rawFlowConfig } from 'app/components/dataviews/views/RawFlow/RawFlow';
import GeneratorView, { config as generatorConfig } from 'app/components/dataviews/views/GeneratorView';
import MetricsExplorerPanel from 'app/components/dataviews/views/metrics/MetricsExplorerPanel';

const categories = ['time', 'comparison', 'hierarchical', 'heatmap', 'tables'];
const categoryTitles = ['Time-Based', 'Comparative', 'Hierarchical', 'Density-Based', 'Table'];

const viewTypes = {
  stackedArea: {
    category: 'time',
    label: 'Stacked Area Chart',
    icon: 'timeline-area-chart',
    component: StackedAreaView,
    config: stackedAreaConfig,
    supportsPeriodOverPeriod: true
  },
  stackedAreaDeep: {
    category: 'time',
    label: 'Stacked Area Chart',
    icon: 'timeline-area-chart',
    component: StackedAreaView,
    config: stackedAreaConfig,
    hideFromViewMenu: true
  },
  stackedAreaHundredPercent: {
    category: 'time',
    label: '100% Stacked Area Chart',
    icon: 'full-stacked-chart',
    component: StackedAreaHundredPercentView,
    config: stackedAreaHundredPercentConfig
  },
  line: {
    category: 'time',
    label: 'Line Chart',
    icon: 'timeline-line-chart',
    component: LineView,
    config: lineConfig,
    supportsPeriodOverPeriod: true
  },
  stackedBar: {
    category: 'time',
    label: 'Stacked Column Chart',
    icon: 'stacked-chart',
    component: StackedColumnView,
    config: stackedColumnConfig,
    supportsPeriodOverPeriod: true
  },
  horizon: {
    category: 'time',
    label: 'Horizon Chart',
    icon: 'align-justify',
    component: HorizonView,
    config: horizonConfig
  },
  bar: {
    category: 'comparison',
    label: 'Bar Chart',
    icon: 'horizontal-bar-chart',
    component: BarView,
    config: barConfig
  },
  pie: {
    category: 'comparison',
    label: 'Pie Chart',
    icon: 'doughnut-chart',
    component: PieView,
    config: pieConfig
  },
  sankey: {
    category: 'hierarchical',
    label: 'Sankey',
    icon: 'flows',
    component: SankeyView,
    config: sankeyConfig
  },
  sunburst: {
    category: 'hierarchical',
    label: 'Sunburst',
    icon: 'flash',
    component: SunburstView,
    config: sunburstConfig
  },
  matrix: {
    category: 'heatmap',
    label: 'Matrix',
    icon: 'heat-grid',
    component: MatrixView,
    config: matrixConfig,
    hideInMkp: true
  },
  gauge: {
    category: 'heatmap',
    label: 'Gauge',
    icon: 'selection',
    component: GaugeView,
    config: gaugeConfig
  },
  table: {
    category: 'tables',
    label: 'Table',
    icon: 'th-list',
    component: LegendTableView,
    config: legendTableConfig
  },
  geoHeatMap: {
    category: 'heatmap',
    label: 'Geo HeatMap',
    icon: 'globe',
    isMap: true,
    component: GeoHeatMapView,
    config: geoHeatMapConfig
  },
  rawFlow: {
    label: 'Raw Flow',
    icon: 'globe',
    component: RawFlow,
    config: rawFlowConfig,
    hideFromViewMenu: true
  },
  generator: {
    label: '',
    icon: 'grid-view',
    component: GeneratorView,
    config: generatorConfig,
    hideFromViewMenu: true
  },
  metrics: {
    label: 'Metrics',
    component: MetricsExplorerPanel,
    config: {},
    hideFromViewMenu: true
  }
};

class DataViewStore {
  renderedDataviews = [];

  register(dataview) {
    this.renderedDataviews.push(dataview);
  }

  unregister(dataview) {
    this.renderedDataviews.splice(
      this.renderedDataviews.findIndex((dv) => dv === dataview),
      1
    );
  }

  getDataviewComponentByDomId(domId) {
    return this.renderedDataviews.find((component) => domId === component?.el?.id);
  }

  hasViewModel(type) {
    return !!viewTypes[type];
  }

  getOption(type) {
    return viewTypes[type];
  }

  getConfig(type) {
    return viewTypes[type] && viewTypes[type].config;
  }

  getIcon(type) {
    return viewTypes[type] && viewTypes[type].icon;
  }

  getViewDisplayLabel(type) {
    return viewTypes[type] && viewTypes[type].label;
  }

  getComponent(type) {
    return viewTypes[type].component;
  }

  get categories() {
    return categories;
  }

  get categoryTitles() {
    return categoryTitles;
  }

  get viewTypeOptions() {
    return Object.keys(viewTypes).map((viz_type) => ({
      value: viz_type,
      ...viewTypes[viz_type],
      category: categoryTitles[categories.indexOf(viewTypes[viz_type].category)]
    }));
  }

  getViewType(type) {
    return this.viewTypeOptions.find((view) => view.value === type);
  }

  /*
    Identifies whether swapping from one view type to another is allowed without modifying a query
  */
  isViewTypeCompatible = (fromViewType, toViewType, query) => {
    const outsort = query.get('outsort');
    const metric = query.get('metric');
    const isAggTotal = outsort.startsWith('agg_total');
    const metricCount = metric.length;
    const baseCompatibleTypes = ['bar', 'pie', 'sankey', 'sunburst', 'gauge', 'table'];
    const additionalCompatibleTypes = ['stackedArea', 'stackedAreaHundredPercent', 'line', 'stackedBar', 'horizon'];
    const allTypes = [...baseCompatibleTypes, ...additionalCompatibleTypes];
    const compatMap = {
      stackedArea: allTypes,
      stackedAreaHundredPercent: allTypes,
      line: allTypes,
      stackedBar: isAggTotal ? baseCompatibleTypes : allTypes,
      horizon: isAggTotal ? baseCompatibleTypes : allTypes,
      bar: isAggTotal ? baseCompatibleTypes : allTypes,
      pie: isAggTotal ? baseCompatibleTypes : allTypes,
      gauge: isAggTotal ? baseCompatibleTypes : allTypes,
      table: isAggTotal ? baseCompatibleTypes : allTypes,
      sunburst: isAggTotal && metricCount > 1 ? baseCompatibleTypes : allTypes,
      sankey: isAggTotal && metricCount > 1 ? baseCompatibleTypes : allTypes
    };
    const toTypes = compatMap[fromViewType];

    return toTypes && toTypes.includes(toViewType);
  };
}

export default new DataViewStore();
