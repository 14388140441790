import buildFilterGroup from 'core/util/filters/buildFilterGroup';
import { getOutboundFilters } from 'app/components/filters/simple/simpleFilterUtils';
import $moduleConfig from '../moduleConfig/$moduleConfig';

const baseFilters = {
  noCacheToCache: {
    filterField: 'dst_cdn',
    operator: '=',
    filterValue: ''
  },

  noEmptyCdn: {
    filterField: 'src_cdn',
    operator: '<>',
    filterValue: ''
  },

  emptyCdn: {
    filterField: 'src_cdn',
    operator: '=',
    filterValue: ''
  }
};

export function getCdnFilters({ moduleName = 'cdn' } = {}) {
  const filters = $moduleConfig.get(moduleName, 'filters');

  if (filters?.filterGroups?.length > 0) {
    return filters;
  }

  return {
    connector: 'All',
    filterGroups: [
      buildFilterGroup({
        connector: 'Any',
        filters: [
          {
            filterField: 'i_src_connect_type_name',
            operator: '=',
            filterValue: 'embedded_cache'
          },
          {
            filterField: 'i_src_network_bndry_name',
            operator: '=',
            filterValue: 'external'
          }
        ]
      })
    ]
  };
}

export function getCdnFilterGroup({ moduleName = 'cdn' } = {}) {
  return buildFilterGroup({ name: 'cdns', ...getCdnFilters({ moduleName }) });
}

const getBaseQuery = ({ lookback_seconds = 604800, aggregateType = 'max_bits_per_sec' }) => ({
  all_devices: false,
  device_types: ['router', 'kprobe', 'kappa'],
  show_overlay: false,
  show_total_overlay: false,
  aggregateTypes: [aggregateType],
  lookback_seconds,
  from_to_lookback: lookback_seconds,
  topx: 100,
  depth: 100
});

const getBaseAggQuery = ({ filters = [], lookback_seconds, aggregateType }) => ({
  ...getBaseQuery({ lookback_seconds, aggregateType }),
  topx: 8,

  filters: {
    connector: 'All',
    filterGroups: [
      getCdnFilterGroup(),
      buildFilterGroup({
        filters: getOutboundFilters(filters).concat([baseFilters.noCacheToCache, baseFilters.noEmptyCdn])
      })
    ]
  }
});

const getBaseDetailQuery = ({ cdn, filters = [], lookback_seconds, aggregateType }) => ({
  ...getBaseQuery({ lookback_seconds, aggregateType }),
  topx: 8,

  filters: {
    connector: 'All',
    filterGroups: [
      getCdnFilterGroup(),
      buildFilterGroup({
        filters: getOutboundFilters(filters).concat([
          {
            filterField: 'src_cdn',
            operator: '=',
            filterValue: cdn
          }
        ])
      })
    ]
  }
});

export const getTrafficQuery = ({ filters = [], lookback_seconds }) => ({
  ...getBaseAggQuery({ filters, lookback_seconds }),
  show_total_overlay: true,
  metric: ['src_cdn'],
  aggregateTypes: ['max_bits_per_sec', 'max_dst_ip', 'max_bytes_per_dst_ip'],
  outsort: 'max_bits_per_sec',
  topx: 100,
  depth: 100,
  viz_type: 'table'
});

export const getConnectivityQuery = ({ filters = [], lookback_seconds, aggregateType }) => ({
  ...getBaseAggQuery({ filters, lookback_seconds, aggregateType }),
  viz_type: 'bar',
  metric: ['i_src_connect_type_name']
});

export const getConnectivityQueryForCdn = ({
  cdn = '',
  filters = [],
  lookback_seconds,
  noCache = true,
  cacheFill = true
}) => ({
  ...getBaseAggQuery({ filters, lookback_seconds }),
  show_total_overlay: true,
  aggregateTypes: ['max_bits_per_sec', 'max_dst_ip', 'max_bytes_per_dst_ip'],
  metric: ['i_src_connect_type_name', 'dst_cdn'],

  filters: {
    connector: 'All',
    filterGroups: [
      getCdnFilterGroup(),
      buildFilterGroup({
        filters: getOutboundFilters(filters).concat([
          {
            filterField: 'src_cdn',
            operator: '=',
            filterValue: cdn
          }
        ])
      }),
      buildFilterGroup({
        connector: 'Any',
        filters: [
          ...(noCache ? [baseFilters.noCacheToCache] : []),
          ...(cacheFill
            ? [
                {
                  filterField: 'dst_cdn',
                  operator: '=',
                  filterValue: cdn
                }
              ]
            : [])
        ]
      })
    ]
  }
});

export const getCdnQuery = ({ filters = [], lookback_seconds, aggregateType }) => ({
  ...getBaseAggQuery({ filters, lookback_seconds, aggregateType }),
  metric: ['src_cdn']
});

export const getNonCdnQuery = ({ filters = [], lookback_seconds, aggregateType }) => ({
  ...getBaseQuery({ filters, lookback_seconds, aggregateType }),
  filters: {
    connector: 'All',
    filterGroups: [
      buildFilterGroup({
        filters: getOutboundFilters(filters).concat([
          baseFilters.noCacheToCache,
          baseFilters.emptyCdn,
          {
            filterField: 'i_src_network_bndry_name',
            operator: '=',
            filterValue: 'external'
          }
        ])
      })
    ]
  }
});

export const getCdnToSiteQuery = ({ cdn, filters = [], lookback_seconds, aggregateType }) => ({
  ...getBaseDetailQuery({ cdn, filters, lookback_seconds, aggregateType }),
  metric: ['i_src_connect_type_name', 'i_src_provider_classification', 'i_device_site_name', 'src_cdn', 'AS_src'],
  viz_type: 'sankey',
  topx: 20
});

export const getSiteConnectivityQuery = ({ cdn, filters = [], lookback_seconds }) => ({
  ...getBaseDetailQuery({ cdn, filters, lookback_seconds }),
  aggregateTypes: ['avg_bits_per_sec', 'avg_dst_ip', 'avg_bytes_per_dst_ip'],
  outsort: 'avg_bits_per_sec',
  metric: ['i_src_connect_type_name', 'i_src_provider_classification', 'i_device_site_name'],
  topx: 16
});

export const getOttServiceQuery = ({ cdn, filters = [], lookback_seconds, aggregateType }) => ({
  ...getBaseQuery({ lookback_seconds, aggregateType }),
  metric: ['service_type', 'service_name'],
  topx: 16,
  viz_type: 'stackedArea',

  filters: {
    connector: 'All',
    filterGroups: [
      getCdnFilterGroup(),
      buildFilterGroup({
        filters: getOutboundFilters(filters).concat([
          {
            filterField: 'src_cdn',
            operator: '=',
            filterValue: cdn
          }
        ])
      })
    ]
  }
});

export const getOttServiceTableQuery = ({ cdn, filters = [], lookback_seconds, aggregateType }) => ({
  ...getOttServiceQuery({ cdn, filters, lookback_seconds, aggregateType }),
  metric: ['service_name', 'service_type', 'service_provider'],
  aggregateTypes: [aggregateType, 'max_dst_ip', 'max_bytes_per_dst_ip'],
  outsort: aggregateType,
  topx: 8,
  depth: 75
});

export const getPerformanceQuery = ({ cdn, metric, filters = [], lookback_seconds, aggregateType }) => {
  const query = {
    ...getBaseAggQuery({
      filters: filters.concat({
        filterField: 'src_cdn',
        operator: '=',
        filterValue: cdn
      }),
      lookback_seconds,
      aggregateType
    }),

    fastData: 'Full',
    topx: 100,
    depth: 350,
    viz_type: 'line',
    metric,

    aggregateTypes: [aggregateType]
  };

  return query;
};
