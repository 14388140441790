import { get, isEmpty } from 'lodash';

import { TASK_TYPES } from 'shared/synthetics/constants';
import { safelyParseJSON } from 'core/util';
import { getWorstHealth } from 'app/views/synthetics/utils/syntheticsUtils';
import PingResultCollection from 'app/stores/synthetics/PingResultCollection';
import DnsResultCollection from 'app/stores/synthetics/DnsResultCollection';
/* eslint-disable no-loop-func */

export function getTimestamp(results, resultTimeMs) {
  // it's important to NOT configure a resultTimeMs for things like SynthDashboardGridGroup
  // because we can't guarantee that each of the test's result timestamps will line up.
  // by NOT sending in a resultTimeMs, we are allowing to skip down to the logic that gets
  // the most recent timestamp from each individual test's results
  if (resultTimeMs) {
    return resultTimeMs / 1000;
  }

  const healthTsKeys = Object.keys(results.health_ts).sort();
  return healthTsKeys[healthTsKeys.length - 1];
}

function isEqualStrings(arg1, arg2) {
  return `${arg1}`.toLowerCase() === `${arg2}`.toLowerCase();
}

function getDnsResultsForTimeSlice({ results: { health_ts, tasks, book }, timestamp: ts, agentTaskConfig, $syn }) {
  const agentIds = Object.keys(agentTaskConfig);
  const results = [];

  for (let i = 0; i < agentIds.length; i++) {
    const agentId = agentIds[i];
    const agent = $syn.agents.get(agentId);
    const agentTargets = agentTaskConfig[agentId].targets;

    if (!agentTargets.length) {
      results.push({
        agent,
        overall_health: 'failing',
        server: '---',
        noQueryData: true
      });
    } else {
      agentTargets.forEach((target) => {
        let dnsHealth = null;
        let dnssecHealth = null;
        let pingHealth = null;
        Object.entries(health_ts[ts]?.tasks || {}).forEach(([taskId, { agents }]) => {
          const task = tasks[taskId];
          const { dns, dns_sec, ping, knock } = task.task;
          const health = agents[agentId];

          if (isEqualStrings(dns?.resolver, target)) {
            dnsHealth = health;
          }

          if (dns_sec) {
            dnssecHealth = health;
          }

          if (isEqualStrings(ping?.target, target) || isEqualStrings(knock?.target, target)) {
            pingHealth = health;
          }
        });

        results.push({
          agent,
          health: dnsHealth,
          healthDnssec: dnssecHealth,
          pingHealth,
          overall_health:
            pingHealth && dnsHealth
              ? getWorstHealth(get(pingHealth, 'overall_health.health'), get(dnsHealth, 'overall_health.health'))
              : get(pingHealth || dnsHealth, 'overall_health.health'),
          server: target,
          isAggregated: !!book?.aggregationData?.isAggregated
        });
      });
    }
  }
  return { results };
}

function getHttpResultsForTimeSlice({ results: { health_ts, tasks, book }, timestamp: ts, agentTaskConfig, $syn }) {
  const agentIds = Object.keys(agentTaskConfig);
  const results = [];

  for (let i = 0; i < agentIds.length; i++) {
    const agentId = agentIds[i];
    const agent = $syn.agents.get(agentId);
    const agentTargets = agentTaskConfig[agentId].targets;

    if (!agentTargets.length) {
      results.push({
        agent,
        overall_health: 'failing',
        pingTarget: '---'
      });
    } else {
      let pingHealth = null;
      let httpHealth = null;
      let transactionSummary = null;
      Object.entries(health_ts[ts].tasks).forEach(([taskId, { agents }]) => {
        const task = tasks[taskId];
        const isHttpTask = !!task.task.http || !!task.task.page_load || !!task.task.transaction;
        if ((isHttpTask && !httpHealth) || (!isHttpTask && !pingHealth)) {
          const health = agents[agentId];

          if (health) {
            if (isHttpTask) {
              httpHealth = health;
            } else {
              pingHealth = health;
            }

            if (task.task.transaction) {
              transactionSummary = { screenshots: 0, hars: 0 };
              if (!isEmpty(health)) {
                // Check if health.data is a string, parse it if it is, otherwise use it directly
                const data = typeof health.data === 'string' ? safelyParseJSON(health.data) : health.data;

                if (!isEmpty(data) && !isEmpty(get(data, '[0].s3_data'))) {
                  const s3_data = data?.[0].s3_data;
                  const { screenshot, har } = s3_data;
                  transactionSummary.screenshots += screenshot?.length || 0;
                  transactionSummary.hars += har?.length || 0;
                }
              }
            }
          }
        }
      });

      const overall_health =
        pingHealth && httpHealth
          ? getWorstHealth(get(pingHealth, 'overall_health.health'), get(httpHealth, 'overall_health.health'))
          : get(pingHealth || httpHealth, 'overall_health.health');

      results.push({
        agent,
        pingHealth,
        httpHealth,
        overall_health,
        isAggregated: !!book?.aggregationData?.isAggregated,
        ...{ transaction_summary: transactionSummary || {} }
      });
    }
  }

  return { results };
}

function getPingResultsForTimeSlice({
  test,
  results: { flow, health_ts, tasks, agents: resultsAgents, book },
  timestamp: ts,
  agentTaskConfig,
  $syn
}) {
  const agentIds = Object.keys(agentTaskConfig);
  const hasFlow = !!flow?.length;
  const results = [];

  for (let i = 0; i < agentIds.length; i += 1) {
    const agentId = agentIds[i];
    const agent = $syn.agents.get(agentId);
    const agentTargets = agentTaskConfig[agentId].targets;
    const status = resultsAgents[agentId];

    if (!agentTargets.length) {
      results.push({
        agent,
        overall_health: 'failing',
        pingTarget: '---'
      });
    } else {
      let iterator = [{ targets: agentTargets }];
      let taskTarget;
      // on hostname tests, the dst_ip does not match target and we might have multiple targets...
      // this lets us map target IPs to the respective hostname to ensure we get metrics from the right task below
      if (test.isHostname) {
        const { targets, ...hostnameTaskConfig } = agentTaskConfig[agentId];
        const hostnames = Object.keys(hostnameTaskConfig);
        iterator = hostnames.map((hostname) => ({ hostname, ...agentTaskConfig[agentId][hostname] }));
      }
      for (let j = 0; j < iterator.length; j += 1) {
        const { hostname, targets } = iterator[j];
        // target is IP address (not task.task.target)
        if (test.isHostname) {
          taskTarget = hostname;
        }
        targets.forEach((target) => {
          if (!test.isHostname) {
            taskTarget = target;
          }
          let pingHealth = null;
          let pingTask = null;
          let throughputTask = null;
          // todo: we might get a ts from a derived from a different dataset, which isn't presnt in this data
          // ex: syngest currently doesn't align with bgp and trace and synback
          Object.entries(health_ts[ts]?.tasks || {}).forEach(([taskId, { agents }]) => {
            const task = tasks[taskId];
            const config = task.task.ping || task.task.knock;
            if (
              pingHealth === null &&
              ((test.isHostname && config && config.target === hostname) ||
                (!test.isHostname && config && isEqualStrings(config.target, target)))
            ) {
              const health = agents[agentId];
              if (isEqualStrings(health?.dst_ip, target)) {
                pingHealth = health;
              }
              pingTask = task;
            }

            if (throughputTask === null && task.task.throughput) {
              throughputTask = { task, taskHealth: health_ts[ts]?.tasks[taskId]?.agents[agentId] };
            }
          });

          let connectivityType = '';
          let provider = '';
          let flowTs = [];
          if (hasFlow) {
            let site = null;
            if (agent.get('agent_type') === 'private' && agent.get('site.title')) {
              site = agent.get('site.title');
            }
            const flowData = flow.find(
              (d) =>
                isEqualStrings((d.inet_dst_addr || d.inet_src_addr).split('/')[0], target) &&
                (!site || site === d.i_device_site_name)
            );
            if (flowData && flowData.rawData) {
              flowTs = flowData.rawData.both_bits_per_sec.flow;
              connectivityType = flowData.i_dst_connect_type_name || flowData.i_src_connect_type_name || '';
              provider = flowData.i_dst_provider_classification || flowData.i_src_provider_classification || '';
            }
          }
          const flowRecord = flowTs.find((rec) => `${rec[0] / 1000}` === ts);

          // Filter out flow-based test targets without health record for this timestamp
          // NOTE: No health record means target has changed temporarily
          // TODO: #20383 remove ip-agent mapping (target_agent field should go back to single agent_id)
          if ((test.isFlowBased && pingHealth) || !test.isFlowBased) {
            for (const ta of pingTask?.target_agent || [null]) {
              results.push({
                agent,
                taskTarget: taskTarget || null,
                target_agent: ta,
                pingHealth,
                throughputTask,
                overall_health:
                  get(pingHealth, 'overall_health.health') || (!!agent && status === 'offline' && 'critical'),
                flow: flowRecord,
                connectivityType,
                provider,
                pingTarget: target,
                isAggregated: !!book?.aggregationData?.isAggregated
              });
            }
          }
        });
      }
    }
  }

  return { hasFlow, results };
}

// eslint-disable-next-line func-names
export default function ({ test, results, agentTaskConfig, $syn, resultTimeMs }) {
  // In the case we load multiple tests and havent selected a specific test results,
  // we need to find the results for the test we are looking at
  if (
    Object.hasOwn(results, 'health') &&
    Array.isArray(results.health) &&
    results.health.length > 0 &&
    !agentTaskConfig
  ) {
    for (const health of results.health) {
      if (health.test_id === test.id) {
        results = health;
        agentTaskConfig = health.agentTaskConfig;
        break;
      }
    }
  }

  const timestamp = getTimestamp(results, resultTimeMs);
  const agentIds = Object.keys(agentTaskConfig);
  const { hasPing } = test;

  let isMultiTarget = false;
  let CollectionType = PingResultCollection;
  let resultsFn = getPingResultsForTimeSlice;
  if (test.isDns) {
    resultsFn = getDnsResultsForTimeSlice;
    CollectionType = DnsResultCollection;
  }
  if (test.isUrlDefined) {
    resultsFn = getHttpResultsForTimeSlice;
  } else {
    isMultiTarget = agentIds.some((id) => agentTaskConfig[id].targets.length > 1);
  }

  const { results: collectData, hasFlow = false } =
    agentIds.length === 0 ? { results: [] } : resultsFn({ test, results, timestamp, agentTaskConfig, $syn });
  const resultsCollection = new CollectionType(collectData);
  const unhealthyCollection = new CollectionType(
    collectData.filter(
      (r) =>
        ['warning', 'critical'].includes(r.overall_health) ||
        r?.httpHealth?.task_type === TASK_TYPES.ERROR ||
        r?.pingHealth?.task_type === TASK_TYPES.ERROR ||
        r?.health?.task_type === TASK_TYPES.ERROR
    )
  );

  if (isMultiTarget) {
    resultsCollection.group('agent_composite_id');
    unhealthyCollection.group('agent_composite_id');
  }

  const hasAgentError = collectData.some(
    (r) => r?.httpHealth?.agent_error?.code || r?.health?.task_type === TASK_TYPES.ERROR
  );

  return { hasAgentError, hasFlow, hasPing, isMultiTarget, resultsCollection, unhealthyCollection, timestamp };
}
