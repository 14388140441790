import Model from 'core/model/Model';
import { computed } from 'mobx';
import $dictionary from 'app/stores/$dictionary';

function pluralize(word, count) {
  if (count === 1) {
    return word;
  }

  if (word.endsWith('y')) {
    return `${word.slice(0, -1)}ies`;
  }

  return `${word}s`;
}

class LabelModel extends Model {
  get urlRoot() {
    return '/api/ui/labels';
  }

  get messages() {
    return {
      create: 'Label was added successfully',
      update: 'Label was edited successfully',
      destroy: `Label "${this.get('name')}" was removed successfully`
    };
  }

  get removalConfirmText() {
    return {
      title: 'Remove Label',
      text: `Are you sure you want to remove ${this.get('name')}?`
    };
  }

  @computed
  get numItems() {
    return this.get('items').length;
  }

  @computed
  get option() {
    return {
      value: this.get('id'),
      label: this.get('name'),
      bg: this.get('color'),
      color: '#ffffff'
    };
  }

  @computed
  get numItemsByType() {
    const usage = this.get('items').reduce(
      (acc, item) => {
        acc.types[item.item_type] += 1;
        return acc;
      },
      { types: { device: 0, synth_agent: 0, synth_test: 0, policy: 0, dashboard: 0, saved_view: 0 } }
    );

    const { device, synth_agent, synth_test, policy, dashboard, saved_view } = usage.types;
    const text = [];
    if (device > 0) {
      text.push(`${device} ${pluralize('Device', device)}`);
    }
    if (synth_agent > 0) {
      text.push(`${synth_agent} ${pluralize('Agent', synth_agent)}`);
    }
    if (synth_test > 0) {
      text.push(`${synth_test} ${pluralize('Test', synth_test)}`);
    }
    if (policy > 0) {
      text.push(`${policy} ${pluralize('Policy', policy)}`);
    }
    if (dashboard > 0) {
      text.push(`${dashboard} ${pluralize('Dashboard', dashboard)}`);
    }
    if (saved_view > 0) {
      text.push(`${saved_view} ${pluralize('Saved View', saved_view)}`);
    }

    usage.text = text.join(', ');
    return usage;
  }

  @computed
  get isPreset() {
    return this.get('company_id').toString() === $dictionary.dictionary.templateDashboardsCID.toString();
  }

  getSortValue(field) {
    if (field === 'name') {
      return this.get('name').toLowerCase();
    }

    return super.getSortValue(field);
  }
}

export default LabelModel;
