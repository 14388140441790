import { computed } from 'mobx';
import { get, isEmpty, isObject } from 'lodash';
import Model from 'core/model/Model';
import { getHttpStages, getStatusMessage } from 'app/views/synthetics/utils/syntheticsUtils';
import { safelyParseJSON } from 'core/util';

class PingResultModel extends Model {
  @computed
  get agent() {
    return this.get('agent_alias') || (this.get('agent_id') ? this.get('agent_id').toString() : '');
  }

  @computed
  get target() {
    return this.get('target_agent_alias') || this.get('pingTarget');
  }

  getSortValue(field) {
    if (field === 'health') {
      const sort = ['critical', 'warning', 'healthy'];
      return sort.indexOf(this.get('overall_health'));
    }

    if (field === 'agent') {
      return this.agent.toLowerCase();
    }

    return super.getSortValue(field);
  }

  deserialize(data) {
    const {
      agent,
      connectivityType,
      flow,
      overall_health,
      pingTarget,
      taskTarget,
      provider,
      target_agent,
      transaction_summary,
      isAggregated
    } = data;
    let { pingHealth, httpHealth, throughputTask } = data;
    if (!pingHealth) {
      pingHealth = {};
    }
    if (!httpHealth) {
      httpHealth = {};
    }
    if (!throughputTask) {
      throughputTask = {};
    }

    if (typeof httpHealth.data === 'string') {
      httpHealth.data = safelyParseJSON(httpHealth.data);
    }
    const agent_alias = agent ? agent.get('agent_alias') : 'unknown';
    const agent_id = get(agent, 'id');
    const agent_type = agent ? agent.get('agent_type') : undefined;

    if (!isEmpty(httpHealth.data)) {
      const httpHealthMetrics = httpHealth.data[0];
      if (!isEmpty(httpHealthMetrics) && isObject(httpHealthMetrics)) {
        const {
          domComplete,
          duration,
          https_validity,
          https_expiry_timestamp,
          css_selector_status,
          code,
          message,
          statusMessage,
          statusEncoding
        } = httpHealthMetrics;

        if (duration) {
          const isPageLoad = !!domComplete; // assume test.isPageLoad if domComplete is in metrics
          let last = 0;
          getHttpStages(isPageLoad).forEach((item) => {
            const curr = httpHealthMetrics[item.key];
            httpHealth[item.stagesKey] = curr - last;
            last = curr;
          });
        }
        httpHealth.css_selector_status = css_selector_status;

        // Agent Error
        httpHealth.agent_error = code && code !== 609 && { code, message };

        // Code 609 = Certificate Error
        httpHealth.https_validity = code === 609 ? { code, message } : https_validity;
        httpHealth.https_expiry_timestamp = https_expiry_timestamp;

        // Transaction Tests Error Reasons
        httpHealth.statusMessage = getStatusMessage(statusMessage, statusEncoding);
      }
    }

    let http_status_health;
    if (httpHealth?.other_healths?.http_status_code_health) {
      http_status_health = httpHealth.other_healths.http_status_code_health;
    } else {
      http_status_health = httpHealth.jitter_health;
    }

    const otherHealths = throughputTask.taskHealth ? throughputTask.taskHealth.other_healths : httpHealth.other_healths;

    const idTarget = pingTarget !== '::' ? pingTarget : taskTarget;
    return {
      id: `${agent_id}-${target_agent || idTarget || this.optimisticId}`, // fallback to the system-generated id to guarantee id uniqueness
      agentModel: agent,
      agent_composite_id: `${agent_alias}-${agent_id}`,
      agent_id,
      agent_provider: `${agent_id}-${provider}`,
      agent_alias,
      agent_type,
      connectivityType,
      flow,
      avg_ttlb: httpHealth.avg_latency,
      ttlb_health: httpHealth.latency_health,
      css_selector_status: httpHealth.css_selector_status,
      http_size: httpHealth.size,
      http_status: httpHealth.status,
      http_status_health,
      http_navigation_time: httpHealth.navigation_time,
      http_connect_time: httpHealth.connect_time,
      http_domain_lookup_time: httpHealth.domain_lookup_time,
      http_response_time: httpHealth.response_time,
      http_dom_processing_time: httpHealth.dom_processing_time,
      https_validity: httpHealth.https_validity,
      https_expiry_timestamp: httpHealth.https_expiry_timestamp,
      avg_latency: pingHealth.avg_latency,
      latency_health: pingHealth.latency_health,
      jitter_health: pingHealth.jitter_health,
      other_healths: otherHealths,
      avg_throughput_bandwidth: throughputTask.taskHealth?.avg_throughput_bandwidth,
      throughputTask,
      avg_jitter: pingHealth.avg_jitter,
      packet_loss: pingHealth.packet_loss,
      packet_loss_health: pingHealth.packet_loss_health,
      overall_health,
      pingTarget,
      taskTarget,
      provider,
      target_agent_alias: target_agent ? target_agent.agent_alias : undefined,
      target_agent,
      transaction_summary,
      statusMessage: httpHealth.statusMessage,
      task_type: !isEmpty(pingHealth) ? pingHealth.task_type : httpHealth.task_type,
      agent_error: httpHealth.agent_error,
      isAggregated
    };
  }
}

export default PingResultModel;
