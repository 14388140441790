import { action, computed } from 'mobx';

import Model from 'core/model/Model';
import { isIpPublic } from 'app/forms/validations/ipPublic';
import validateIP from 'app/forms/validations/ip';

class NetworkClassificationModel extends Model {
  get urlRoot() {
    return '/api/ui/networkClassification';
  }

  get showToastTitles() {
    return false;
  }

  get messages() {
    return {
      create: 'Updated network classification settings successfully',
      update: 'Updated network classification settings successfully'
    };
  }

  @computed
  get internalIPs() {
    const ips = this.get('internal_ips');
    const ipList = (ips && ips.split(',')) || [];

    return ipList.map((ip) => ip.trim());
  }

  @computed
  get internalASNs() {
    const asns = this.get('internal_asns');
    const asnList = (asns && asns.split(',')) || [];

    return asnList.map((asn) => parseInt(asn.trim()));
  }
}

class NetworkClassificationStore {
  model = new NetworkClassificationModel();

  @computed
  get isEstablished() {
    const ips = this.model.get('internal_ips') || '';
    const asns = this.model.get('internal_asns') || '';

    return ips.length > 0 || asns.length > 0;
  }

  @action
  updateNetworkClass(values) {
    return this.model.save(values, { toast: false }).then(
      action(() => {
        const { $setup } = this.store;
        $setup.hasNetworkClassEstablished = null;
      })
    );
  }

  getIPClassification(ip, asn) {
    const { internalIPs, internalASNs } = this.model;
    const autoIncludePrivateRanges = this.model.get('use_private_subnets') === true;
    const isInternal =
      internalIPs.find((internalIP) => internalIP.startsWith(ip)) ||
      (asn !== undefined && internalASNs.find((internalASN) => internalASN === asn));
    const isPublicIp = isIpPublic(ip);

    // marked as internal if either:
    //   - it was explicitly added to the internal ip list
    //   - it's not a public ip and the setting to automatically include private ip ranges is enabled
    if (isInternal || (autoIncludePrivateRanges && !isPublicIp)) {
      return 'Internal';
    }

    // marked as external if it's a public ip and was not explicitly added to the internal ip list
    if (!isInternal && isPublicIp) {
      return 'External';
    }

    return 'Unclassified';
  }

  checkForChanges(ips, asns) {
    const { internalIPs, internalASNs } = this.model;
    const toReturn = {};

    if (ips) {
      const inputIPs = ips.split(',').reduce((acc, curr) => {
        if (typeof curr === 'string') {
          const ip = curr.trim();

          if (ip && validateIP.fn(ip)) {
            acc.push(ip);
          }
        }

        return acc;
      }, []);

      toReturn.internal_ips = {
        remove: internalIPs.filter((internalIP) => !inputIPs.find((inputIP) => inputIP === internalIP)),
        add: inputIPs.filter((inputIP) => !internalIPs.find((internalIP) => internalIP === inputIP))
      };
    }

    if (asns) {
      const inputASNs = asns.split(',').reduce((acc, curr) => {
        if (typeof curr === 'string') {
          const asn = curr.trim();

          if (asn) {
            acc.push(asn);
          }
        }
        return acc;
      }, []);

      toReturn.internal_asns = {
        remove: internalASNs.filter(
          (internalASN) => !inputASNs.find((inputASN) => inputASN === internalASN.toString())
        ),
        add: inputASNs.filter((inputASN) => !internalASNs.find((internalASN) => internalASN.toString() === inputASN))
      };
    }

    return toReturn;
  }
}

export default new NetworkClassificationStore();
