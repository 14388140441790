import Collection from 'core/model/Collection';
import CostItemModel from './CostItemModel';

class CostItemCollection extends Collection {
  get model() {
    return CostItemModel;
  }
}

export default CostItemCollection;
