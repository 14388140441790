import { action, computed, observable } from 'mobx';

import { Socket } from 'core/util';
import $auth from 'app/stores/$auth';

class RuleState {
  @observable
  rule;

  @observable
  complete = false;

  @observable
  evaluating = false;

  @observable
  devicesCompleted = 0;

  @observable
  devicesRemaining = 0;

  @observable.shallow
  evaluationResults = [];

  @observable
  ruleMatchCount = 0;

  deviceMatches = {};

  ruleInterfaceMatches = {};

  deviceCollection;

  ruleCollection;

  settings;

  onSuccess;

  onError;

  socket;

  constructor(options) {
    const { onSuccess, onError, ruleCollection, deviceCollection, settings } = options;
    this.onSuccess = onSuccess;
    this.onError = onError;
    this.ruleCollection = ruleCollection;
    this.deviceCollection = deviceCollection;
    this.settings = settings;

    this.reset();

    this.socket = new Socket({
      outType: 'classifyInterfaces',
      inType: 'classification',
      frequency: 30,
      delaySend: true,
      onSuccess: this.handleData,
      onError: this.handleData
    });
  }

  @action
  reset() {
    this.rule = null;

    this.complete = false;
    this.evaluating = false;
    this.devicesCompleted = 0;
    this.devicesRemaining = 0;
    this.ruleMatchCount = 0;
    this.evaluationResults.clear();

    this.ruleInterfaceMatches = {};
    this.deviceMatches = {};
  }

  @action
  send(params) {
    this.evaluating = true;
    this.complete = false;
    this.evaluationResults.clear();
    this.deviceMatches = {};
    this.ruleInterfaceMatches = {};

    this.socket.send(params);
  }

  addEvaluationResult(data) {
    this.evaluationResults.push(data);

    // set up device match info
    const { device_id, matchInfo = {}, rules = [], interfaces = [] } = data;
    if (device_id !== undefined) {
      const { interfaceCount = 0, interfacesClassifiedCount = 0, ruleCount = 0, rulesUsed = 0 } = matchInfo;

      this.deviceMatches[device_id] = {
        interfaceCount,
        interfacesClassifiedCount,
        ruleCount,
        rulesUsed,
        percentMatched: interfaceCount > 0 ? interfacesClassifiedCount / interfaceCount : 0,
        rules,
        interfaces
      };
    }

    let classifiedCount = 0;

    // set up rule interface match info
    rules.forEach((rule) => {
      const { id, interfacesMatched, interfacesClassified } = rule;

      if (!this.ruleInterfaceMatches[id]) {
        this.ruleInterfaceMatches[id] = {
          interfacesMatched: [],
          interfacesClassified: []
        };
      }

      const matchedRule = this.ruleInterfaceMatches[id];
      matchedRule.interfacesMatched.push(...interfacesMatched);
      matchedRule.interfacesClassified.push(...interfacesClassified);
      // only include rule-based matches in classification count
      if (id !== -1) {
        classifiedCount += interfacesClassified.length;
      }
    });
    this.ruleMatchCount += classifiedCount;
  }

  @action
  setEvaluationComplete(complete) {
    this.complete = complete;
  }

  /**
   *  Comes in 3 parts: `totalDevices` is the first response, followed by a bunch of devices
   *  and then followed by `complete: true`
   */
  @action
  handleData = (data) => {
    const { totalDevices } = this;
    let { devicesCompleted, devicesRemaining } = this;

    let complete = false;
    this.ruleMatchCount = 0;

    // after the test is complete, update the DeviceCollection with the results.
    if (data.complete) {
      complete = true;
      devicesCompleted = totalDevices;
      devicesRemaining = 0;
      $auth.verifyAuth().then(
        action(() => {
          this.evaluating = false;
        })
      );
      this.deviceCollection.sort();
    } else if (data.totalDevices) {
      devicesRemaining = totalDevices;
      devicesCompleted = 0;
    } else if (Object.keys(data).length > 0) {
      this.addEvaluationResult(data);
      if (this.deviceCollection.get(data.device_id)) {
        devicesCompleted += 1;
        devicesRemaining -= 1;
      }
    }

    this.complete = complete;
    this.devicesCompleted = devicesCompleted;
    this.devicesRemaining = devicesRemaining;

    this.deviceCollection.updateDeviceInterfaceMatchData(this.deviceMatches);

    if (this.ruleCollection) {
      this.ruleCollection.updateRuleInterfaceMatchData(this.ruleInterfaceMatches);
    }

    if (this.onSuccess) {
      this.onSuccess(data);
    }
  };

  @computed
  get totalDevices() {
    return this.deviceCollection.unfiltered.filter((i) => i.get('device_status') === 'V').length;
  }

  @computed
  get totalDevicesClassified() {
    return Math.min(this.evaluationResults.length, this.totalDevices);
  }
}

export default RuleState;
