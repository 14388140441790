export const InboundTrafficFilters = {
  filters: [
    { filterField: 'i_src_connect_type_name', operator: '=', filterValue: 'free_pni' },
    { filterField: 'i_src_connect_type_name', operator: '=', filterValue: 'paid_pni' },
    { filterField: 'i_src_connect_type_name', operator: '=', filterValue: 'transit' }
  ]
};

export const OutboundTrafficFilters = {
  filters: [
    { filterField: 'i_dst_connect_type_name', operator: '=', filterValue: 'free_pni' },
    { filterField: 'i_dst_connect_type_name', operator: '=', filterValue: 'paid_pni' },
    { filterField: 'i_dst_connect_type_name', operator: '=', filterValue: 'transit' }
  ]
};

export default {
  metric: ['Traffic'],
  aggregateTypes: ['p95th_bits_per_sec'],
  lookback_seconds: 86400,
  all_devices: true,
  show_overlay: false,
  show_total_overlay: true,
  filterDimensionsEnabled: true,
  filterDimensionName: 'Total',
  filterDimensionOther: false,
  filterDimensionSort: true,
  filterDimensions: {
    connector: 'Any',
    filterGroups: [
      {
        name: 'Inbound',
        named: true,
        connector: 'Any',
        not: false,
        autoAdded: '',
        saved_filters: [],
        filterGroups: [],
        ...InboundTrafficFilters
      },
      {
        name: 'Outbound',
        named: true,
        connector: 'Any',
        not: false,
        autoAdded: '',
        saved_filters: [],
        filterGroups: [],
        ...OutboundTrafficFilters
      }
    ]
  }
};
