import { computed } from 'mobx';
import { METRICS_ENTITY_TYPE_LABEL_MAP } from 'app/stores/metrics/metricsConstants';
import Collection from 'core/model/Collection';
import MetricModel from './MetricModel';

class MetricsCollection extends Collection {
  get defaultSortState() {
    return {
      field: 'measurement',
      direction: 'asc'
    };
  }

  get secondarySort() {
    return this.defaultSortState;
  }

  get url() {
    return '/api/ui/recon/metrics';
  }

  get model() {
    return MetricModel;
  }

  @computed
  get entityTypes() {
    return this.unfiltered.reduce((data, model) => {
      const { measurement, storage, id } = model.get();
      const entityType = storage.EntityType;

      // Construct entity types; used in Native NMS policies
      if (entityType && measurement && METRICS_ENTITY_TYPE_LABEL_MAP[entityType]) {
        data[entityType] = data[entityType] || {
          name: entityType,
          value: entityType,
          label: METRICS_ENTITY_TYPE_LABEL_MAP[entityType] || entityType,
          measurements: {}
        };

        // Options for entity measurement selector
        data[entityType].measurements[measurement] = data[entityType].measurements[measurement] || {
          id,
          name: measurement,
          dimensions: []
        };

        // Options for entity dimension filters
        data[entityType].measurements[measurement].dimensions = [
          ...(data[entityType].measurements[measurement].dimensions || []),
          ...Object.keys(storage.Dimensions).map((dimension) => ({
            label: storage.Dimensions[dimension]?.Label || dimension,
            measurement,
            value: `${measurement},${dimension}`
          }))
        ];
      }

      return data;
    }, {});
  }

  deserialize(data) {
    data.forEach((item) => {
      if (item.storage.Dimensions.device_id) {
        item.storage.Dimensions.i_device_site_name = {
          Type: 'string',
          Label: 'Site',
          Column: 'i_device_site_name'
        };
      }
    });

    return super.deserialize(data);
  }

  useAsyncAdd = true;
}

export default MetricsCollection;
