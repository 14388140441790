import Validator from 'validatorjs';
import { memoize } from 'lodash';

import $clouds from 'app/stores/clouds/$clouds';
import OciExportConfigFields from 'app/views/setup/tasks/cloud_v2/CloudExportConfigWizard/components/oci/OciExportConfigFields';

import { trimTransform } from '../utils';

export const OCI_CLOUD_EXPORT_CONFIG_STEP = 'oci-export-configuration';
export const OCI_CLOUD_PROVIDER_CONFIG_STEP = 'oci-provider-configuration';
export const OCI_CLOUD_VERIFICATION_STEP = 'oci-verification';
export const OCI_CLOUD_FINAL_STEP = 'oci-final-steps';

export const OCI_EXPORT_ADDITIONAL_STEPS = [
  {
    id: OCI_CLOUD_EXPORT_CONFIG_STEP,
    title: 'Kentik Export OCI Configuration',
    component: OciExportConfigFields,
    registerValidators: (form) => {
      Validator.registerAsync(
        'bucket_permissions',
        (value, args, attribute, passes) => {
          const { properties } = form.getValues();

          const { oci_bucket_namespace_name, oci_bucket_name, oci_service_connector_ocid } = properties;

          const hasNecessaryValues = oci_bucket_namespace_name && oci_bucket_name && oci_service_connector_ocid;

          if (!hasNecessaryValues) {
            passes();
            return;
          }

          $clouds
            .validateOCIBucketAccess(form.getValues())
            .then((response) => {
              const { success, message } = response;
              if (!success) {
                passes(false, message);
                return;
              }

              passes(true);
            })
            .catch((error) => {
              console.error('Unable to execute validate OCI Bucket access', error);
              passes(true);
            });
        },
        'Unable to validate bucket permissions'
      );
    },
    fieldsToRemove: ($cloudExportWizard) => {
      if ($cloudExportWizard.isFlowLogCollectionEnabled) {
        return [];
      }

      return [
        'properties.oci_bucket_namespace_name',
        'properties.oci_bucket_name',
        'properties.oci_service_connector_ocid'
      ].filter((optionalField) => $cloudExportWizard.formHasField(optionalField));
    },
    generateFields: memoize(
      ($cloudExportWizard) => {
        const optionalFields = {};
        if ($cloudExportWizard.isFlowLogCollectionEnabled) {
          Object.assign(optionalFields, {
            'properties.oci_bucket_namespace_name': {
              label: 'Bucket Namespace',
              rules: 'required|bucket_permissions',
              transform: {
                out: trimTransform
              }
            },
            'properties.oci_bucket_name': {
              label: 'Bucket Name',
              rules: 'required|bucket_permissions',
              transform: {
                out: trimTransform
              }
            },
            'properties.oci_service_connector_ocid': {
              label: 'Service Connector OCID',
              rules: 'required',
              transform: {
                out: trimTransform
              }
            },
            'properties.oci_flow_object_name_prefix': {
              label: 'Flow Object Name Prefix',
              transform: {
                out: trimTransform
              }
            }
          });
        }

        // flow logs checkbox is select
        return {
          'properties.oci_user_id': {
            label: 'User ID',
            rules: 'required',
            transform: {
              out: trimTransform
            }
          },
          'properties.oci_tenancy_id': {
            label: 'Tenancy ID',
            rules: 'required',
            transform: {
              out: trimTransform
            }
          },
          'properties.oci_compartment_id': {
            defaultValue: [],
            label: 'Compartment ID',
            transform: {
              out: trimTransform
            }
          },
          'properties.oci_default_region': {
            defaultValue: 'us-ashburn-1',
            rules: 'required',
            label: 'OCI Default Region',
            options: $cloudExportWizard.getFilteredOptionsByCloudProvider(),
            transform: {
              out: trimTransform
            }
          },
          ...optionalFields
        };
      },
      ($cloudExportWizard) =>
        `${$cloudExportWizard.selectedCloudProvider}-${+$cloudExportWizard.isFlowLogCollectionEnabled}`
    )
  }
];
