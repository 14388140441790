import Collection from 'core/model/Collection';
import PlanModel from 'app/stores/sudo/PlanModel';

export default class PlanCollection extends Collection {
  companyId;

  constructor({ plans = [], companyId }) {
    super(plans);
    this.companyId = companyId;
  }

  get model() {
    return PlanModel;
  }

  get defaultSortState() {
    return { field: 'active', direction: 'desc' };
  }

  get url() {
    return '/api/ui/sudo/plans';
  }

  get urlPaths() {
    return {
      destroy: `/api/ui/sudo/plans/${this.companyId}`
    };
  }
}
