import Model from 'core/model/Model';
import { computed } from 'mobx';
import moment from 'moment';
import { uniqWith, isEqual } from 'lodash';
import $notifications from 'app/stores/notifications/$notifications';
import transformConfigToFilters from 'app/util/mkp/transformConfigToFilters';

import PolicyCollection from 'app/stores/alerting/PolicyCollection';
import PolicyModel from 'app/stores/alerting/PolicyModel';
import UserCollection from '../user/UserCollection';

// TODO NMS POLICY - if we ever get subpolicies, this will need to be updated
export default class TenantModel extends Model {
  get urlRoot() {
    return '/api/ui/mkp-settings/tenant';
  }

  get defaults() {
    return {
      type: 'subtenant',
      enabled: true
    };
  }

  // Only show tenant users
  users = new UserCollection([], {
    activePresetFilter: {
      fn: (model) => model.get('user_group_id')
    }
  });

  subpolicies = new PolicyCollection([]);

  deserialize(data) {
    if (!data) {
      return data;
    }

    const { config, users, template } = data;

    if (users && Array.isArray(users)) {
      this.users.set(users);
    }

    data.template_id = undefined;
    data.template = [];

    if (template && template.length) {
      const [activeTemplate] = template;
      data.template = activeTemplate;
      data.template_id = activeTemplate.id;
    }

    config.alerts = config.alerts.map((alert) =>
      new PolicyModel().deserialize({
        ...alert,
        name: `Subpolicy ${alert.subpolicy_id} for policy ${alert.policy_id} (Tenant ${data.id})`,
        thresholds: $notifications.addNotificationsToThresholds(alert.thresholds, alert)
      })
    );

    return super.deserialize({ ...data, config });
  }

  serialize(data) {
    const { config } = data;

    const serializedPolicies = config.alerts
      .filter((policy) => !policy.is_template)
      .map((policy) => {
        const serializedPolicy = new PolicyModel().serialize(policy);
        const serializedThresholds = serializedPolicy.thresholds.map((threshold) => ({
          ...threshold,
          mitigations: threshold.mitigations.map(({ id, ...mitigation }) => ({
            ...mitigation,
            applicationMetadata: {
              type: 'subtenant',
              tenantID: `${this.id}`
            }
          }))
        }));
        return { ...serializedPolicy, thresholds: serializedThresholds };
      });

    const newConfig = {
      ...config,
      filters: transformConfigToFilters(config, { name: data?.name }),
      alerts: serializedPolicies
    };

    return super.serialize({
      ...data,
      users: this.isNew ? this.users.serialize() : data.users,
      config: newConfig
    });
  }

  @computed
  get lastLogin() {
    return this.users.reduce((acc, model) => Math.max(acc, moment(model.get('last_login') || 0).valueOf()), 0);
  }

  @computed
  get numUsers() {
    return this.users.size;
  }

  addView = (view) => {
    const viewEntry = { id: `${view.id}` };

    if (view.type === 'Dashboard') {
      viewEntry.type = 'dashboard';
    }

    if (view.type === 'Saved View') {
      viewEntry.type = 'savedView';
    }

    if (!view.type) {
      return Promise.resolve();
    }

    const config = this.get('config');
    config.assets = config.assets || {};
    config.assets.reports = config.assets.reports || [];
    // Prevent dupes
    config.assets.reports = uniqWith([...config.assets.reports, viewEntry], isEqual);
    this.set('config', config);
    return this.save({}, { toast: false });
  };

  hasReport = (viewOption) => {
    const assignedReports = this.get('config.assets.reports') || [];
    return assignedReports.some((report) => isEqual(report, viewOption));
  };

  @computed
  get subpolicyToParentMap() {
    const alerts = this.get('config.alerts') || [];
    return Object.assign({}, ...alerts.map((alert) => ({ [`${alert.subpolicy_id}`]: `${alert.policy_id}` })));
  }

  @computed
  get numViews() {
    const reportSet = new Set();
    (this.get('config.assets.reports') || []).forEach((report) => {
      if (report.type !== 'report') {
        reportSet.add(`${report.id}|${report.type.toLowerCase()}`);
      }
    });
    (this.get('template.config.assets.reports') || []).forEach((report) => {
      if (report.type !== 'report') {
        reportSet.add(`${report.id}|${report.type.toLowerCase()}`);
      }
    });
    return reportSet.size;
  }

  @computed
  get numAlertPolicies() {
    const alertPolicies = this.get('config.alerts') || [];
    return alertPolicies.length;
  }

  @computed
  get numMitigations() {
    let mitigationCount = 0;
    const alertPolicies = this.get('config.alerts') || [];
    alertPolicies.forEach((policy) => {
      if (!policy?.thresholds?.length) {
        return;
      }
      (policy.thresholds || []).forEach((threshold) => {
        mitigationCount += (threshold.mitigations || []).length;
      });
    });
    return mitigationCount;
  }

  @computed
  get subpolicyIDs() {
    const alertPolicies = this.get('config.alerts') || [];
    const subpolicies = [];
    alertPolicies.forEach((policy) => {
      if (policy.subpolicy_id) {
        subpolicies.push(`${policy.subpolicy_id}`);
      }
    });
    return subpolicies;
  }
}
