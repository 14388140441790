import Collection from 'core/model/Collection';
import MetricISISAdjacencyModel from './MetricISISAdjacencyModel';

class MetricISISAdjacencyCollection extends Collection {
  get defaultSortState() {
    return {
      field: 'adjacency-state',
      direction: 'asc'
    };
  }

  get model() {
    return MetricISISAdjacencyModel;
  }
}

export default MetricISISAdjacencyCollection;
