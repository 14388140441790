import { observable, action, computed } from 'mobx';
import { Socket } from 'core/util';

class BgpStore {
  requests = {
    specificPrefixes: {},
    eventsTS: {},
    pathChangesTS: {},
    visibilityTS: {},
    eventsTable: {},
    pathGraph: {}
  };

  @observable
  specificPrefixesLoaded = 0;

  @observable
  specificPrefixesLoadingCount = 0;

  @observable
  specificPrefixesParsed = false;

  @observable
  eventsTSLoaded = 0;

  @observable
  eventsTSLoadingCount = 0;

  @observable
  eventsTSParsed = false;

  @observable
  eventsTableLoaded = 0;

  @observable
  eventsTableLoadingCount = 0;

  @observable
  eventsTableParsed = false;

  @observable
  visibilityTSLoaded = 0;

  @observable
  visibilityTSLoadingCount = 0;

  @observable
  visibilityTSParsed = false;

  @observable
  pathChangesTSLoaded = 0;

  @observable
  pathChangesTSLoadingCount = 0;

  @observable
  pathChangesTSParsed = false;

  @observable
  pathGraphLoaded = 0;

  @observable
  pathGraphLoadingCount = 0;

  @observable
  pathGraphParsed = false;

  initializeRequest(params) {
    const { type, ...rest } = params;

    if (this.requests[type]) {
      const request = this.requests[type];

      if (!request.socket) {
        let processFn;

        if (type === 'specificPrefixes') {
          processFn = 'setSpecificPrefixesLoad';
        } else if (type === 'eventsTS') {
          processFn = 'setEventsTSLoad';
        } else if (type === 'eventsTable') {
          processFn = 'setEventsTableLoad';
        } else if (type === 'visibilityTS') {
          processFn = 'setVisibilityTSLoad';
        } else if (type === 'pathChangesTS') {
          processFn = 'setPathChangesTSLoad';
        } else if (type === 'pathGraph') {
          processFn = 'setPathGraphLoad';
        }

        request.socket = this.subscribe(type);
        request.lastUpdated = null;
        request.processFn = processFn;
        request.type = type;
      }

      request.params = { ...rest };
      request.data = null;
      request.error = null;

      this[request.processFn]({ loadingCount: 0, loaded: -1, parsed: false });
    }
  }

  getPayload(config = {}, formConfig = {}) {
    const { check_rpki = true, routeviewer, include_covered } = config;

    return {
      prefix:
        formConfig.specificPrefix ||
        formConfig.prefix ||
        config.prefix
          .split(', ')
          .map((p) => p.trim())
          .join(','),
      origin: config.origin,
      asn: formConfig.asn,
      check_rpki,
      routeviewer,
      include_covered: formConfig.specificPrefix ? false : include_covered,
      upstream: config.upstream,
      ...formConfig.graphFormValues
    };
  }

  requestData(params) {
    const { type } = params;

    this.initializeRequest(params);
    this.sendRequest(type);
  }

  sendRequest(type) {
    const request = this.requests[type];

    if (request && request.data === null) {
      const { req, startDate: from, endDate: until } = request.params;

      if (request.socket) {
        request.socket.setID();
        request.socket.standBy = false;
        request.socket.listening = false;
        request.socket.setPayload({ req: { ...req, from, until }, type });
        request.socket.send();
      }
    }
  }

  subscribe(type) {
    const socket = new Socket({
      outType: 'bgp',
      inType: 'bgpIn',
      delaySend: true,
      frequency: 'heartbeat',
      onSuccess: action((rawData) => {
        const request = this.requests[type];

        if (request && socket && !socket.standBy && type === rawData.type) {
          this[request.processFn](rawData);

          if (rawData.results) {
            request.data = rawData.results;
            request.lastUpdated = Date.now();

            this.standBy(request);
          }
        }
      }),
      onError: action((err) => {
        const request = this.requests[type] || {};

        request.error = (err && err.text) || 'Error occurred during your bgp';
        request.lastUpdated = Date.now();
        console.warn('Error received from bgp', err);
      }),
      onReconnect: () => {
        if (socket && !socket.standBy) {
          this.unsubscribe(type);
          socket.setFrequency('once');

          socket.send({}, (success) => {
            if (success) {
              socket.setFrequency('heartbeat');
              this.sendRequest(type);
            }
          });
        }
      }
    });

    return socket;
  }

  unsubscribeRequest(request) {
    const { socket } = request;

    socket.setPayload({ cancel: true });
    socket.send();

    this.standBy(request);
  }

  unsubscribe(type) {
    const request = this.requests[type];
    request.data = null;

    if (request.socket) {
      this.unsubscribeRequest(request);
    }

    if (request.processFn) {
      this[request.processFn]({ loadingCount: 0, loaded: -1, parsed: false });
    }
  }

  standBy(request) {
    const { socket } = request;

    socket.standBy = true;
    socket.listening = false;
    socket.cancelChannel();
  }

  // determine if any bgp requests are currently loading
  // used for determining a final loading state in exports
  @computed
  get isLoading() {
    const requestTypes = Object.keys(this.requests);

    return requestTypes
      .map((requestType) => {
        // each request type will have a 'Loaded', 'LoadingCount', and 'Parsed' state, all stored in individual observables
        const loaded = this[`${requestType}Loaded`];
        const loadingCount = this[`${requestType}LoadingCount`];
        const parsed = this[`${requestType}Parsed`];

        // a request is in a loading state when either a request is loading results
        // or we have something loaded that isn't parsed yet
        const requestLoading = loaded < loadingCount;
        const requestParsing = loaded && !parsed;

        return requestLoading || requestParsing;
      })
      .some((requestLoading) => !!requestLoading);
  }

  @action
  setSpecificPrefixesLoad({ loadingCount, loaded, parsed }) {
    if (loaded || loaded === 0) {
      this.specificPrefixesLoaded += 1;
    }

    if (loaded === -1) {
      this.specificPrefixesLoaded = 0;
    }

    if (loadingCount) {
      this.specificPrefixesLoadingCount = loadingCount;
    }

    if (parsed || parsed === false) {
      this.specificPrefixesParsed = parsed;
    }
  }

  @action
  setEventsTSLoad({ loadingCount, loaded, parsed }) {
    if (loaded || loaded === 0) {
      this.eventsTSLoaded += 1;
    }

    if (loaded === -1) {
      this.eventsTSLoaded = 0;
    }

    if (loadingCount) {
      this.eventsTSLoadingCount = loadingCount;
    }

    if (parsed || parsed === false) {
      this.eventsTSParsed = parsed;
    }
  }

  @action
  setEventsTableLoad({ loadingCount, loaded, parsed }) {
    if (loaded || loaded === 0) {
      this.eventsTableLoaded += 1;
    }

    if (loaded === -1) {
      this.eventsTableLoaded = 0;
    }

    if (loadingCount) {
      this.eventsTableLoadingCount = loadingCount;
    }

    if (parsed || parsed === false) {
      this.eventsTableParsed = parsed;
    }
  }

  @action
  setVisibilityTSLoad({ loadingCount, loaded, parsed }) {
    if (loaded || loaded === 0) {
      this.visibilityTSLoaded += 1;
    }

    if (loaded === -1) {
      this.visibilityTSLoaded = 0;
    }

    if (loadingCount) {
      this.visibilityTSLoadingCount = loadingCount;
    }

    if (parsed || parsed === false) {
      this.visibilityTSParsed = parsed;
    }
  }

  @action
  setPathChangesTSLoad({ loadingCount, loaded, parsed }) {
    if (loaded || loaded === 0) {
      this.pathChangesTSLoaded += 1;
    }

    if (loaded === -1) {
      this.pathChangesTSLoaded = 0;
    }

    if (loadingCount) {
      this.pathChangesTSLoadingCount = loadingCount;
    }

    if (parsed || parsed === false) {
      this.pathChangesTSParsed = parsed;
    }
  }

  @action
  setPathGraphLoad({ loadingCount, loaded, parsed }) {
    if (loaded || loaded === 0) {
      this.pathGraphLoaded += 1;
    }

    if (loaded === -1) {
      this.pathGraphLoaded = 0;
    }

    if (loadingCount) {
      this.pathGraphLoadingCount = loadingCount;
    }

    if (parsed || parsed === false) {
      this.pathGraphParsed = parsed;
    }
  }
}

export default new BgpStore();
