import React from 'react';
import Model from 'core/model/Model';
import { action, computed } from 'mobx';
import { startsWith as _startsWith } from 'lodash';
import $dictionary from 'app/stores/$dictionary';
import api from 'core/util/api';

import {
  AWS_AUTO_GENERATED_PREFIX,
  AZURE_AUTO_GENERATED_PREFIX,
  OCI_AUTO_GENERATED_PREFIX,
  GCP_AUTO_GENERATED_PREFIX
} from 'shared/customDimensions/constants';
import PopulatorCollection from './PopulatorCollection';

const DIMENSIONS_SYSTEM_KEYWORDS = [
  AWS_AUTO_GENERATED_PREFIX,
  OCI_AUTO_GENERATED_PREFIX,
  AZURE_AUTO_GENERATED_PREFIX,
  GCP_AUTO_GENERATED_PREFIX,
  'src_',
  'dst_'
];

export default class CustomDimensionModel extends Model {
  populators = new PopulatorCollection([], { parent: this });

  constructor(attributes = {}) {
    super(attributes);

    if (attributes.populators) {
      this.populators.set(attributes.populators);
    }
  }

  get defaults() {
    return {
      type: 'string'
    };
  }

  get urlRoot() {
    return '/api/ui/customdimensions';
  }

  get omitDuringSerialize() {
    return ['populatorCount', 'populators'];
  }

  get messages() {
    return {
      create: `Dimension ${this.get('display_name')} was added successfully`,
      update: `Dimension ${this.get('display_name')} was updated successfully`,
      destroy: `Dimension ${this.get('display_name')} was removed successfully`
    };
  }

  get removalConfirmText() {
    return {
      title: 'Remove Dimension',
      text: (
        <p>
          Are you sure you want to remove <strong>{this.get('display_name')}?</strong> This action is not reversible.
        </p>
      )
    };
  }

  destroy() {
    return super.destroy().then(() => $dictionary.initialize());
  }

  static fetchByName(name, options = {}) {
    return api.get(`/api/ui/customdimensions/name/${name}`, options).then((response) => {
      if (response && response.length > 0) {
        return new CustomDimensionModel(response[0]);
      }
      return null;
    });
  }

  get isAutoGeneratedAws() {
    return _startsWith(this.get('name'), AWS_AUTO_GENERATED_PREFIX);
  }

  get isAutoGeneratedAzure() {
    return _startsWith(this.get('name'), AZURE_AUTO_GENERATED_PREFIX);
  }

  get isAutoGeneratedOci() {
    return _startsWith(this.get('name'), OCI_AUTO_GENERATED_PREFIX);
  }

  get isAutoGeneratedGcp() {
    return _startsWith(this.get('name'), GCP_AUTO_GENERATED_PREFIX);
  }

  get isAutoGenerated() {
    return this.isAutoGeneratedAws || this.isAutoGeneratedAzure || this.isAutoGeneratedOci || this.isAutoGeneratedGcp;
  }

  get populatorCloudProvider() {
    let cloud_provider = 'aws';

    if (this.isAutoGeneratedAzure) {
      cloud_provider = 'azure';
    }

    if (this.isAutoGeneratedOci) {
      cloud_provider = 'oci';
    }

    if (this.isAutoGeneratedGcp) {
      cloud_provider = 'gcp';
    }

    return cloud_provider;
  }

  get populatorByProvider() {
    if (this.isAutoGeneratedAzure) {
      return 'Azure Automatic';
    }

    if (this.isAutoGeneratedOci) {
      return 'OCI Automatic';
    }

    if (this.isAutoGeneratedAws) {
      return 'AWS Automatic';
    }

    if (this.isAutoGeneratedGcp) {
      return 'GCP Automatic';
    }

    console.warn('Unable to get populator auto generated provider - '.this.get('name'));
    return '';
  }

  get populatorType() {
    return this.isAutoGenerated ? this.populatorByProvider : 'Manually Created';
  }

  // will convert c_aws_sub_name into name
  get nameWithoutAutogeneratedPrefix() {
    if (this.isAutoGenerated) {
      let resultString = this.get('name');

      // remove system appended keywords into dimension name so we can compare it to aws tag
      DIMENSIONS_SYSTEM_KEYWORDS.forEach((keyword) => {
        resultString = resultString.replace(keyword, '');
      });

      return resultString;
    }

    return this.get('name');
  }

  get dimensionAutogeneratedEntityType() {
    return this.nameWithoutAutogeneratedPrefix.split('_')[0];
  }

  get dimensionRealName() {
    const dimensionEntityType = this.dimensionAutogeneratedEntityType;

    return this.nameWithoutAutogeneratedPrefix.replace(`${dimensionEntityType}_`, '');
  }

  @action
  async autogenerateEniPopulators(options = {}) {
    if (!this.isAutoGenerated) {
      return Promise.resolve();
    }

    if (!this.get('id')) {
      return Promise.resolve();
    }
    return api.post(`/api/ui/customdimensions/${this.get('id')}/autogenerate-populators`, options);
  }

  @action
  async fetchPopulatorsQueueData(options = {}) {
    if (!this.isAutoGenerated) {
      return Promise.resolve([]);
    }

    return api.get(`/api/ui/customdimensions/${this.get('id')}/populators/queue`, options);
  }

  @computed
  get displayName() {
    const originalName = this.get('display_name');
    const shortName = this.get('name');

    if (!this.isAutoGenerated) {
      return originalName;
    }

    const destinationString = shortName.includes('src') ? 'Source' : 'Destination';

    if (originalName.includes(destinationString)) {
      return originalName;
    }

    // we dont store Source/Destination in database, because Data Explorer appends it in ui when selected
    // and it causes names like 'Source Source AWS Subnet Name'
    return `${destinationString} ${originalName}`;
  }
}
