// Turn string into array
function serializeCommunities(communities) {
  return communities.split(',').map((community) => {
    const [asn, internal] = community.split(':');
    return { asn, internal };
  });
}

function serialize(rtbh) {
  rtbh.communitiesToAdvertise = serializeCommunities(rtbh.communitiesToAdvertise);
  return rtbh;
}

function deserialize(rtbh) {
  // Turn communities array into string: e.g., '8888:7777,6666:5555'
  const communities = rtbh.communitiesToAdvertise || [];
  if (Array.isArray(communities)) {
    rtbh.communitiesToAdvertise = communities.map(({ internal, asn }) => `${asn}:${internal}`).join(',');
  }
  return rtbh;
}

export default {
  serialize,
  deserialize
};
