import { computed } from 'mobx';
import Model from 'core/model/Model';
import { PLATFORM_TYPES } from 'app/stores/mitigations/mitigationsConstants';
import MitigationMethodCollection from './MitigationMethodCollection';

export default class MitigationPlatformModel extends Model {
  get urlRoot() {
    return '/api/ui/mitigations/platforms';
  }

  get defaults() {
    return {
      name: '',
      description: '',
      mitigationMethods: new MitigationMethodCollection(),
      methodIds: []
    };
  }

  get messages() {
    return {
      create: `Mitigation platform "${this.get('name')}" was added successfully`,
      update: `Mitigation platform "${this.get('name')}" was updated successfully`,
      destroy: `Mitigation platform "${this.get('name')}" was removed successfully`
    };
  }

  @computed
  get methods() {
    return this.get('mitigationMethods');
  }

  @computed
  get methodIds() {
    return this.methods.map((method) => method.id);
  }

  @computed
  get methodCount() {
    return this.methods.size;
  }

  @computed
  get type() {
    return this.get('type') || '';
  }

  @computed
  get typeLabel() {
    return PLATFORM_TYPES.find((option) => this.type === option.value)?.label;
  }

  @computed
  get details() {
    return this.get(this.type) || {};
  }

  @computed
  get devices() {
    return this.details ? this.details.devices : [];
  }

  serialize(data) {
    const methods = this.methods?.models || [];
    const methodIds = methods.map((model) => model.attributes.id);

    const dataToSerialize = {
      name: data.name,
      description: data.description,
      [data.type]: data[data.type],
      mitigationMethods: [...new Set(methodIds)] // dedupe IDs
    };

    // cleanup empty passwords
    if (data.type === 'radware' && data[data.type].password === '') {
      delete data[data.type].password;
    }
    if (data.type === 'cfmt' && data[data.type].apiPassword === '') {
      delete data[data.type].apiPassword;
    }
    if (data.type === 'a10' && data[data.type].password === '') {
      delete data[data.type].password;
    }

    return super.serialize(dataToSerialize);
  }

  deserialize(data) {
    const { mitigationMethods = [], ...restData } = data.platform || data;
    const type = PLATFORM_TYPES.find((option) => restData[option.value])?.value;
    const deserializedData = Object.assign(this.defaults, {
      mitigationMethods: new MitigationMethodCollection(mitigationMethods),
      type,
      ...restData
    });

    return super.deserialize(deserializedData);
  }
}
