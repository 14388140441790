import Collection from 'core/model/Collection';

import CurrencyModel from './CurrencyModel';

class CurrencyCollection extends Collection {
  get model() {
    return CurrencyModel;
  }

  get url() {
    return '/api/ui/cost/currencies';
  }

  get minFetchInterval() {
    return 60 * 60 * 1000;
  }

  get defaultSortState() {
    return {
      field: 'name',
      direction: 'asc'
    };
  }
}

export default CurrencyCollection;
