import SuppressionCollection from './SuppressionCollection';

export class SuppressionsStore {
  collection = null;

  initialize() {
    this.collection = new SuppressionCollection([], { store: this.store });

    return Promise.resolve();
  }
}

export default new SuppressionsStore();
