import { action, computed } from 'mobx';
import { orderBy } from 'lodash';

import { showErrorToast, showSuccessToast } from 'core/components';
import Model from 'core/model/Model';
import { api, deepClone } from 'core/util';
import { DASHBOARD_MODULES } from 'app/util/constants';
import { injectLegacySavedFilters } from 'core/util/filters';
import { CLOUD_TYPE_DEVICES, CLOUD_DASHBOARD_LABELS } from 'app/views/hybrid/utils/aws';
import $app from 'app/stores/$app';
import $auth from 'app/stores/$auth';
import $setup from 'app/stores/$setup';
import $dictionary from 'app/stores/$dictionary';
import $labels from 'app/stores/label/$labels';
import $recentlyViewed from 'app/stores/recentlyViewed/$recentlyViewed';
import $users from 'app/stores/user/$users';
import QueryModel from 'app/stores/query/QueryModel';

import DashboardItemCollection from './DashboardItemCollection';

export default class DashboardModel extends Model {
  static layoutDefaults = {
    breakpoints: { lg: 900, sm: 0 },
    cols: { lg: 12, sm: 1 },
    containerPadding: [24, 16],
    margin: [16, 16],
    rowHeight: 100
  };

  get defaults() {
    return {
      devices_source: 'dashboard',
      filters_source: 'dashboard',
      query: {},
      share_level: $auth.isAdministrator ? 'org' : 'self',
      time_source: 'dashboard',
      parametric: false,
      type: 'dashboard',
      liveUpdateInterval: 0,

      // We always have a default parametric field, but disable the functionality by default.
      parametric_fields: [
        { type: 'as_name', value: '', label: 'AS Name', question: 'Which AS Name would you like to see?' }
      ],
      parametric_value_type: 'freeform',
      parametric_value_options: []
    };
  }

  get url() {
    // Force dashboards (in e.g. ReportsCollection to use proper dashboard URLs)
    if (this.isNew) {
      return this.urlRoot;
    }

    return `${this.urlRoot}/${this.get('id')}`;
  }

  get urlRoot() {
    return '/api/ui/dashboards';
  }

  get omitDuringSerialize() {
    return ['items', 'liveUpdateInterval', 'device_name', 'dependents', 'dependencies', 'category'];
  }

  get name() {
    return this.get('dash_title') || '';
  }

  get category() {
    return this.get('category.name') || 'Uncategorized';
  }

  get slugName() {
    return this.name.toLowerCase().replace(/\s/g, '-');
  }

  get slugFavorite() {
    return `${this.get('type')}$${this.get('id')}`;
  }

  @computed
  get isFavorite() {
    return Object.keys($setup.getSettings('favorites', {})).includes(this.slugFavorite);
  }

  get description() {
    return this.get('description');
  }

  get type() {
    return 'Dashboard';
  }

  get featured() {
    return this.get('featured');
  }

  @computed
  get labels() {
    return $labels.getLabels('dashboard', this.id);
  }

  @computed
  get labelsString() {
    return this.labels.map((label) => label.get('name')).join(', ');
  }

  @action
  async updateLayout(layout) {
    if (this.canEdit) {
      return this.save(
        { layout: layout || Object.assign({}, this.get('layout')) },
        {
          clearSelection: false,
          toast: false,
          sendProvidedValuesOnly: true,
          preserialize: false
        }
      ).then((res) => this.set({ layout: res.layout }));
    }

    return this.set({ layout });
  }

  deserialize(data) {
    if (!data) {
      return data;
    }

    const transformedData = { ...super.deserialize(data) };
    const { query, items, id, filters, saved_filters } = transformedData;

    if (id) {
      transformedData.id = parseInt(id);
    }

    if (query) {
      transformedData.query = QueryModel.create(query).get();
    }

    if (items && Array.isArray(items)) {
      // create an easy reference to the Dashboard to make it easier for DashboardItems to self-sustain
      const dashboardItems = items.map((item) => ({ ...item, dashboard: this }));

      transformedData.items = new DashboardItemCollection(dashboardItems, { dashboard: this });
    }

    if (saved_filters && saved_filters.length > 0) {
      transformedData.filters = injectLegacySavedFilters(saved_filters, filters);
      delete transformedData.saved_filters;
    }

    return transformedData;
  }

  serialize(data) {
    const serialized = { ...data };
    const queryModel = QueryModel.create(serialized.query);
    serialized.query = queryModel.serialize();

    if (!serialized.parametric_value_options) {
      serialized.parametric_value_options = [];
    }

    // clear out values so we don't persist user entered values to the db
    if (serialized.parametric && serialized.parametric_fields.length) {
      serialized.parametric_fields.forEach((field) => delete field.value);
    }

    if (serialized.category_id === '') {
      serialized.category_id = null;
    }

    return super.serialize(serialized);
  }

  @action
  duplicate = ({ save = true }) => {
    if (save) {
      return DashboardModel.duplicate(
        this.get('id'),
        this.getUniqueCopyName('dash_title').substr(0, 50),
        this.collection
      );
    }
    const attributes = this.get();
    delete attributes.id;
    attributes.dash_title = this.getUniqueCopyName('dash_title').substr(0, 50);
    return this.collection.forge(attributes);
  };

  @action
  static duplicate(id, dash_title, collection) {
    const url = `/api/portal/dashboards/copy/${id}`;
    const data = { dash_title };

    if (collection) {
      collection.requestStatus = 'Copying';
    }

    return api.post(url, { data }).then(
      (attrs) => {
        showSuccessToast(`Successfully created copy: ${attrs.dash_title}`);

        return collection.fetch().then(() => collection.get(attrs.id));
      },
      () => {
        showErrorToast('Dashboard copy could not be created.');
        if (collection) {
          collection.requestStatus = null;
        }
      }
    );
  }

  save(attributes = {}, options = {}) {
    if ($auth.isPresetCompany || !this.isPreset) {
      const { parametric, parametric_fields } = attributes;
      let parametricFields = [];

      if (parametric) {
        // Make a copy of the parametric fields as they'll be mutated when serialized
        // When serialized, the 'value' field is removed to prevent saving in the db
        parametricFields = parametric_fields.map((f) => ({ ...f }));
      }

      return super
        .save(attributes, options)
        .then(
          action((response) => {
            // Sync its changes
            if (this.collection && this.collection.updateMaps) {
              this.collection.updateMaps();
            }

            if (parametric) {
              // Reinstate the parametric fields with values so the parametric form doesn't lose state
              this.set({ parametric_fields: parametricFields });

              return {
                ...response,
                parametric_fields: parametricFields
              };
            }

            return response;
          })
        )
        .then((response) => $labels.labels.fetch({ force: true }).then(() => response));
    }

    return Promise.resolve(Object.assign({}, this.attributes, attributes));
  }

  @action
  async create(attributes = {}, options = {}) {
    const label = 'creating';
    const data = Object.assign({}, this.attributes, attributes);
    const payload = this.serialize(data);

    const { clearSelection, toast = true } = options;

    const url = this.urlRoot;
    const promise = api.post(url, { data: payload });

    this.requestStatus = label;

    return promise.then(
      (success) => {
        this.set(this.deserialize(success));

        if (this.collection) {
          this.collection.add(this);

          if (clearSelection) {
            this.collection.clearSelection();
            this.collection.sort(); // Note: it seemed to make sense not to re-apply existing filter rules
          }

          // Sync its changes
          if (this.collection.updateMaps) {
            this.collection.updateMaps();
          }
        }

        if (toast) {
          const createMessage = this.messages ? this.messages.create : 'Created Successfully';
          showSuccessToast(createMessage, { title: this.showToastTitles ? 'Created' : undefined });
        }

        this.requestStatus = null;

        return success;
      },
      (error) => {
        this.error = { label, body: error };
        this.requestStatus = null;
        throw error;
      }
    );
  }

  @action
  fetchDependentsAndDependencies() {
    const url = `/api/ui/dashboards/dependencies/${this.id}`;
    return api.get(url).then((data) => {
      this.set({ ...data });
    });
  }

  @action
  generateItemLayout() {
    const items = this.get('items');
    const currentLayout = this.get('layout');
    let updated = false;
    let layout;

    if (currentLayout === undefined || currentLayout === null) {
      layout = { lg: [], sm: [] };
      updated = true;
    } else {
      layout = deepClone(currentLayout);
    }

    // Hack way around small layout not being configurable in the UI
    // Mirror large layout
    const sorted = orderBy(layout.lg, ['y', 'x'], ['asc', 'asc']);
    const sm = [];

    sorted.forEach((item, i) => {
      const prevItem = i > 0 ? sm.find((updatedSMLayout) => updatedSMLayout.i === sorted[i - 1].i) : null;
      const y = prevItem ? prevItem.y + prevItem.h : 0;

      const smItemLayout = layout.sm.find((smLayout) => smLayout.i === item.i);

      if (smItemLayout) {
        sm.push({
          ...smItemLayout,
          x: 0,
          y
        });
      }
    });

    layout.sm = sm;

    [].concat(items.models.toJS()).forEach((item) => {
      if (!layout.lg.find((l) => l.i === `${item.get('id')}`)) {
        const itemLayout = item.layoutData;
        layout.lg.push(itemLayout.lg);
        layout.sm.push(itemLayout.sm);
        updated = true;
      }
    });

    if (updated) {
      const firstItem = layout.lg.find((l) => l.y === 0);
      if (firstItem) {
        firstItem.y = 1;
      }
    }

    return layout;
  }

  refresh() {
    this.get('items').each((item) => item.refresh());
  }

  @computed
  get updateFrequency() {
    return this.get('liveUpdateInterval');
  }

  @action
  setUpdateFrequency(liveUpdateInterval) {
    this.get('items').each((item) => item.setUpdateFrequency(liveUpdateInterval));
    this.set({ liveUpdateInterval });
  }

  @computed
  get timeRange() {
    const { lookback_seconds, starting_time, ending_time } = this.get('query');

    return {
      lookback_seconds,
      starting_time,
      ending_time
    };
  }

  /**
   * If a Dashboards parametric_type changes, all of it's Items `parametric_overrides` field must be updated.
   * This happens on the server automatically, but don't want to return (and create) an entire `items` Collection
   * when we save, so just update locally.
   */
  @action
  updateItemsParametricFilterField() {
    const { flatParametricFilterTypes, parametricOperators } = $dictionary.dictionary.queryFilters;
    const parametric_field = this.get('parametric_fields')[0];

    // and update each Item with the proper `filterField` it should override
    this.get('items').each((item) => {
      item.set({
        parametric_overrides: {
          filterField: flatParametricFilterTypes[parametric_field.type][0],
          operator: parametricOperators[parametric_field.type]
        }
      });
    });
  }

  getSerializedDataForClone() {
    return this.fetch({ query: { fullFetch: true } }).then((dashboardJSON) => {
      delete dashboardJSON.id;
      delete dashboardJSON.company_id;
      delete dashboardJSON.user_id;
      delete dashboardJSON.user_id;
      delete dashboardJSON.ctime;
      delete dashboardJSON.etime;
      delete dashboardJSON.saved_query_id;
      delete dashboardJSON.user_email;
      delete dashboardJSON.category_id;
      delete dashboardJSON.destination_dashboard;
      delete dashboardJSON.dashboard_navigation;
      delete dashboardJSON.dashboard_navigation_options;

      dashboardJSON.items.forEach((item) => {
        item.origId = item.id;
        delete item.id;
        delete item.company_id;
        delete item.dashboard_id;
        delete item.user_id;
        delete item.saved_query_id;
        delete item.ctime;
        delete item.etime;
        delete item.destination_dashboard;
        delete item.dashboard_navigation;
        delete item.dashboard_navigation_options;
        delete item.savedQuery?.id;
      });
      delete dashboardJSON.query.id;
      delete dashboardJSON.query.company_id;
      delete dashboardJSON.query.user_id;
      return dashboardJSON;
    });
  }

  // Helper to get which DashboardItemModel is selected from the Dashboards items collection
  @computed
  get selectedPanel() {
    return this.get('items') && this.get('items').selected;
  }

  /**
   * Also checks if a `layout` is created so we don't render the GridLayout with invalid props.
   */
  @computed
  get hasItems() {
    const items = this.get('items');
    const hasItems = items && items.size > 0;

    return !!hasItems;
  }

  @computed
  get sortableShareLevel() {
    const map = {
      Preset: 2,
      Shared: 1,
      Private: 0
    };

    return map[this.shareLevel];
  }

  @computed
  get shareLevel() {
    const level = this.isPreset ? 'Preset' : 'Shared';
    return this.isUserLevel ? 'Private' : level;
  }

  @computed
  get parametricField() {
    return this.get('parametric_fields') && this.get('parametric_fields')[0];
  }

  @computed
  get isUserLevel() {
    return this.userIsAuthor && this.get('share_level') === 'self';
  }

  @computed
  get userIsAuthor() {
    return Number(this.get('user_id')) === Number($auth.getActiveUserProperty('id'));
  }

  @computed
  get author() {
    const { userIsAuthor, isPreset } = this;
    const authorModel = $users.collection.modelById[this.get('user_id')];
    const author = authorModel ? authorModel.get('user_full_name') : '';
    if (isPreset) {
      return 'Kentik';
    }
    return userIsAuthor ? 'Me' : author;
  }

  @computed
  get isPreset() {
    return (
      this.get('share_level') === 'org' &&
      `${this.get('company_id')}` === `${$dictionary.dictionary.templateDashboardsCID}`
    );
  }

  @computed
  get isCompanyLevel() {
    return (
      this.get('share_level') === 'org' &&
      `${this.get('company_id')}` !== `${$dictionary.dictionary.templateDashboardsCID}`
    );
  }

  @computed
  get isModule() {
    return this.isPreset && DASHBOARD_MODULES.has(this.name);
  }

  @computed
  get navigatePath() {
    return `/v4/library/dashboards/${this.id}`;
  }

  @computed
  get canEdit() {
    const allowedLabels = $auth.getActiveUserProperty('label_metadata', {})['dashboards::update'];
    const isLabelFiltered = $auth.hasRbacPermissions(['dashboards::update']) && allowedLabels;

    if (isLabelFiltered) {
      if (!this.labels.some((label) => allowedLabels.includes(label.id))) {
        return false;
      }
    }

    return (
      !$app.isSubtenant &&
      (this.userIsAuthor || // Personal
        ($auth.isAdministrator && this.isCompanyLevel) || // Company
        (this.isPreset && $auth.isPresetCompany)) // Preset
    );
  }

  get iconName() {
    return 'control';
  }

  @computed
  get itemsSortedByLayout() {
    if (!this.hasItems) {
      return [];
    }

    // sort layout, y first, then x
    const layout = this.get('layout');
    const items = this.get('items');
    const sorted = orderBy(layout.lg, ['y', 'x'], ['asc', 'asc']);
    return sorted.map((layoutItem) => items.get(parseInt(layoutItem.i)));
  }

  @computed
  get newItemY() {
    if (!this.hasItems) {
      return 0;
    }

    const { max } = Math;
    return this.get('layout').lg.reduce((y, item) => max(y, item.y + item.h), 0);
  }

  @computed
  get isParametric() {
    return !!(this.get('parametric') && this.get('parametric_fields').length);
  }

  @computed
  get fullyLoaded() {
    const items = this.get('items');
    return this.hasItems && items.models.every((item) => item.dataview.fullyLoaded);
  }

  @computed
  get isIncompleteParametric() {
    const parametric_fields = this.get('parametric_fields');
    return this.isParametric && (parametric_fields[0].value === undefined || parametric_fields[0].value === null);
  }

  @computed
  get originalQuery() {
    return this._originalQuery || this.get('query');
  }

  @computed
  get canSave() {
    return $auth.isPresetCompany || !this.isPreset;
  }

  @computed
  get itemsWithSelectedModels() {
    const items = this.get('items');
    return this.hasItems ? items.filter((item) => item.selectedModels.length, { immutable: true }) : [];
  }

  @computed
  get lastViewedDate() {
    return $recentlyViewed.getLastViewedDate(this);
  }

  @computed
  get isTrending() {
    return $recentlyViewed.isTrending(this);
  }

  @computed
  get companyLastViewedDate() {
    return $recentlyViewed.getLastViewedDate(this, true);
  }

  @computed
  get lastEditedDate() {
    return this.get('etime') || this.get('ctime');
  }

  @computed
  get isSelectable() {
    return !this.isPreset && this.canEdit;
  }

  set query(query) {
    this._originalQuery = this.originalQuery;
    this.set('query', query);
  }

  get messages() {
    return {
      create: `Dashboard "${this.get('dash_title')}" was added successfully`,
      update: `Dashboard "${this.get('dash_title')}" was updated successfully`,
      destroy: `Dashboard "${this.get('dash_title')}" was removed successfully`,
      duplicate: `Dashboard "${this.get('dash_title')}" was duplicated successfully`
    };
  }

  get removalConfirmText() {
    return {
      title: 'Remove Dashboard',
      text: `Are you sure you want to remove ${this.get('dash_title')}?`
    };
  }

  get sortValues() {
    return {
      'category.name': () => this.get('category.name') || 'zzzzzz'
    };
  }

  @computed
  // will return clouds used for dashboard query from devices or labels added
  get dashboardCloudProviders() {
    if (this.get('query.all_devices')) {
      return [];
    }

    const selectedCloudDevices =
      this.get('query.device_name', [])
        // get only cloud specific devices
        ?.filter((deviceName) => deviceName.includes('_subnet') && CLOUD_TYPE_DEVICES.includes(deviceName))
        ?.map((deviceName) => deviceName.replace('_subnet', '')) ?? [];

    if (selectedCloudDevices.length > 0) {
      return selectedCloudDevices;
    }

    return Object.keys(CLOUD_DASHBOARD_LABELS).filter((cloudProvider) =>
      this.labels.some((label) => label.get('name') === CLOUD_DASHBOARD_LABELS[cloudProvider])
    );
  }

  @computed
  get isCloudDashboard() {
    return this.dashboardCloudProviders.length > 0;
  }
}
