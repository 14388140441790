import { computed } from 'mobx';
import { getCustomProperties } from 'shared/util/map';
import CloudMapModel from './CloudMapModel';

export default class OciMapModel extends CloudMapModel {
  @computed
  get searchableData() {
    const customProperties = getCustomProperties(this);
    const searchableData = {
      ids: customProperties.searchableIds || [],
      cidrs: customProperties.searchableCIDRs || []
    };

    const { compartmentId, tenancyId } = this.toJS();

    if (compartmentId) {
      searchableData.compartmentIds = [compartmentId];
    }

    if (tenancyId) {
      searchableData.tenancyIds = [tenancyId];
    }

    return searchableData;
  }
}
