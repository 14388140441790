import { action, computed, observable, reaction } from 'mobx';
import $clouds from 'app/stores/clouds/$clouds';
import { buildOptionsFromMap } from 'core/form/components/modalSelect/selectHelpers';
import { PROGRESS_STATES } from 'app/views/setup/tasks/cloud_v2/CloudExportConfigWizard/components/sidebar/constants';
import { isGoogleCloud } from '@kentik/ui-shared/util/map';
import { isAws, isAzure, isOci } from 'shared/customDimensions/constants';
import { defaultSamplingRate } from 'app/views/setup/tasks/cloud_v2/CloudExportConfigWizard/components/sampling/constants';
import { defaultValues } from 'app/views/setup/tasks/cloud_v2/CloudExportConfigWizard/constants';

import { showErrorToast } from 'core/components';

import {
  COMPLETE_AWS_EXPORT_STEPS,
  COMPLETE_AWS_EXPORT_STEPS_WITH_TERRAFORM,
  COMPLETE_AZURE_EXPORT_STEPS,
  COMPLETE_AZURE_EXPORT_STEPS_WITH_POWERSHELL,
  COMPLETE_GCP_EXPORT_STEPS,
  COMPLETE_OCI_EXPORT_STEPS,
  EXPORT_INITIAL_STEPS,
  PROVIDER_AND_FEATURE_SELECTION_STEP
} from './steps';

class CloudExportWizardStore {
  @observable
  currentStepId;

  @observable
  isLoading = false;

  @observable
  isStepLoading = false;

  @observable.ref
  exportWizardSteps = EXPORT_INITIAL_STEPS;

  @observable.ref
  form;

  @observable
  isVerifySuccess = false;

  @observable
  hasVerifyError = false;

  @observable
  isVerifyCallMade = false;

  @observable
  apiResults = [];

  @observable
  apiErrors = {};

  @observable
  lastSeenStepId;

  @observable
  cloudModel = null;

  @computed
  get exportStepIds() {
    return this.exportWizardSteps.map((step) => step.id);
  }

  findStepById(stepId) {
    return this.exportWizardSteps.find((step) => step.id === stepId);
  }

  @action
  changeWizardStep(stepId) {
    if (!this.exportStepIds.includes(stepId)) {
      console.warn(`Unknown export wizard step - ${stepId}`);
    }

    // add necessary form fields when changing step
    const nextWizardStep = this.findStepById(stepId);

    // add form field validators when defined
    nextWizardStep.registerValidators?.(this.form, this.cloudModel);
    if (nextWizardStep?.fields) {
      this.form.addFields(nextWizardStep.fields, [], { validate: true });
    }
    // in case fields should be generated dynamically based on current form values
    if (nextWizardStep?.generateFields) {
      const fieldsToAdd = nextWizardStep?.generateFields?.(this) ?? [];

      this.form.addFields(fieldsToAdd, [], { validate: true });
    }

    // this will calculate fields that are no longed required based on prev step selections
    if (nextWizardStep?.fieldsToRemove) {
      const fieldsToRemove = nextWizardStep?.fieldsToRemove?.(this) ?? [];
      fieldsToRemove.forEach((fieldName) => this.form.removeField(fieldName));
    }

    // set last seen step if the destination is greater than current step
    if (this.calculateStepIndex(stepId) > this.currentStepIndex) {
      this.setLastSeenStepId(stepId);
    }
    this.currentStepId = stepId;
  }

  @action
  resetStore() {
    this.form.resetFormState();
    this.exportWizardSteps = EXPORT_INITIAL_STEPS;
    this.changeWizardStep(PROVIDER_AND_FEATURE_SELECTION_STEP);
  }

  @action
  applySnapshot = (snapshot) => {
    const { currentStepId: snapshotStepId, formValues } = snapshot;
    const { cloud_provider, properties, ...restFormValues } = formValues;

    const formValuesForProperties = Object.keys(properties).reduce((carry, propertyKey) => {
      const key = `properties.${propertyKey}`;
      carry[key] = properties[propertyKey];

      return carry;
    }, {});

    Object.assign(formValuesForProperties, restFormValues);

    // set cloud form value upfront, as its used for computing (other fields will be ignored for now as they added only on step switch)
    this.form.setValue('cloud_provider', cloud_provider, { validate: true });

    // change steps first
    this.changeExportWizardStepsForCloudProvider(cloud_provider);

    // order of operations gets a little tricky here
    // changeExportWizardStepsToIncludeTerraformStep relies on computed values from this.form
    // which get set AFTER we calculate the step index
    // but step index will be wrong if there are supposed to be terraform steps
    // so we pass formValues to changeExportWizardStepsToIncludeTerraformStep for evaluation
    this.changeExportWizardStepsToIncludeTerraformStep(formValues);

    const stepIndex = this.calculateStepIndex(snapshotStepId);

    // we need to add all form fields for every previous step before setting form values
    for (let i = 0; i <= stepIndex; i++) {
      this.changeWizardStep(this.exportWizardSteps[i].id);
      this.form.setValues(formValuesForProperties);
    }

    this.currentStepId = snapshotStepId;
  };

  @action
  setSkipMetadataCollectionProperty() {
    this.form.setValue('properties.skip_metadata_collection', !this.form.getValue('metadata_collection'));
  }

  @action
  changeExportWizardStepsForCloudProvider(cloudProvider) {
    if (cloudProvider) {
      this.setCloudModel();
    }
    if (cloudProvider === 'aws') {
      this.exportWizardSteps = COMPLETE_AWS_EXPORT_STEPS;
      return;
    }
    if (cloudProvider === 'gcp') {
      this.exportWizardSteps = COMPLETE_GCP_EXPORT_STEPS;
      return;
    }
    if (cloudProvider === 'azure') {
      this.exportWizardSteps = COMPLETE_AZURE_EXPORT_STEPS;
      return;
    }
    if (cloudProvider === 'oci') {
      this.exportWizardSteps = COMPLETE_OCI_EXPORT_STEPS;
      return;
    }

    // add handling for other clouds
    this.exportWizardSteps = EXPORT_INITIAL_STEPS;
  }

  @action
  changeExportWizardStepsToIncludeTerraformStep(formValuesFromHash) {
    if (this.isSelectedCloudProviderAws) {
      const formBools = this.isFlowLogCollectionEnabled && this.useTerraform;
      const hashFormBools =
        formValuesFromHash?.properties?.enable_flow_log_collection && formValuesFromHash?.useTerraform;

      if (formBools || hashFormBools) {
        return (this.exportWizardSteps = COMPLETE_AWS_EXPORT_STEPS_WITH_TERRAFORM);
      }
      return (this.exportWizardSteps = COMPLETE_AWS_EXPORT_STEPS);
    }
    if (this.isSelectedCloudProviderAzure) {
      if (this.isFlowLogCollectionEnabled || formValuesFromHash?.properties?.enable_flow_log_collection) {
        // leave this commented out until we have terraform support for Azure
        // if (this.useTerraform || formValuesFromHash.useTerraform) {
        //   return (this.exportWizardSteps = COMPLETE_AZURE_EXPORT_STEPS_WITH_TERRAFORM);
        // }
        if (this.usePowershell || formValuesFromHash?.usePowershell) {
          return (this.exportWizardSteps = COMPLETE_AZURE_EXPORT_STEPS_WITH_POWERSHELL);
        }
      }
      return (this.exportWizardSteps = COMPLETE_AZURE_EXPORT_STEPS);
    }
    // no-op
    return this.exportWizardSteps;
  }

  @action
  setIsLoading(value) {
    this.isLoading = value;
  }

  @action
  setForm(form) {
    this.form = form;
  }

  @action
  setCloudModel() {
    const cloud_provider = this.isSelectedCloudProviderGcp ? 'gce' : this.selectedCloudProvider;
    this.cloudModel = $clouds.collection.forge({ cloud_provider }, { select: false });
  }

  @action
  setLastSeenStepId(stepId) {
    this.lastSeenStepId = stepId;
  }

  @action
  setApiResults(apiResults) {
    this.apiResults = apiResults;
  }

  @action
  setApiErrors(apiErrors) {
    this.apiErrors = apiErrors;
  }

  getStepFormFields(stepId) {
    const { fields = {}, generateFields = null } = this.findStepById(stepId);

    const fieldNames = generateFields ? Object.keys(generateFields(this)) : Object.keys(fields);

    return fieldNames.map((fieldName) => this.form.getField(fieldName)).filter((field) => field);
  }

  getVerificationFields() {
    return Object.values(this.exportWizardSteps).reduce((verificationFieldsReducer, step) => {
      if (step.id === PROVIDER_AND_FEATURE_SELECTION_STEP) {
        // skip 1st step
        return verificationFieldsReducer;
      }
      // get all remaining step fields
      const stepFormFields = this.getStepFormFields(step.id);

      if (stepFormFields.length === 0) {
        return verificationFieldsReducer;
      }

      for (let i = 0; i < stepFormFields.length; i++) {
        const formField = stepFormFields[i];
        if (formField?.name) {
          verificationFieldsReducer[formField.name] = formField;
        }
      }
      return verificationFieldsReducer;
    }, {});
  }

  assignDefaults(formValues, cloudModel) {
    cloudModel.serialize(formValues);
    const { properties: incomingFormProperties } = formValues;

    // no need to worry about azure firewall logs here or metrics collection for this logCollect var
    // those are "set and forget" properties, but for flow or cloud run there are some fields we need
    const logCollect = this.isFlowLogCollectionEnabled || this.isGcpCloudRunLogEnabled;
    let key = `${this.selectedCloudProvider}-${logCollect ? 'collect-logs' : 'metadata-only'}`;

    if (this.isSelectedCloudProviderAzure && !logCollect) {
      if (this.isFirewallLogCollectionEnabled) {
        key = 'azure-collect-fw-logs';
      }
      if (this.isMetricsCollectionEnabled) {
        key = 'azure-metrics-only';
      }
    }

    const payload = {
      ...formValues,
      ...cloudModel.defaults,
      // need to use model for cloud_provider b/c Cloud Exports table filters on gce
      // TODO need to standarize gcp/gce throughout the app.
      cloud_provider: cloudModel.get('cloud_provider')
    };

    payload.properties = Object.assign({}, defaultValues[key], cloudModel.defaults.properties, incomingFormProperties);

    if (this.isFlowLogCollectionEnabled) {
      payload.manual_setup_type = 'collect_flow_logs';

      if (!payload.properties?.log_collection_types?.includes('flow')) {
        payload.properties.log_collection_types.push('flow');
      }
    } else if (this.isGcpCloudRunLogEnabled) {
      payload.manual_setup_type = 'gcp_cloud_run';
      // if both flow and cloud run selected, api will create 2 separate exports
      // see node/services/cloudExport/createCloudExport.js
      if (!payload.properties?.log_collection_types?.includes('gcp_cloud_run')) {
        payload.properties.log_collection_types.push('gcp_cloud_run');
      }
    } else {
      // azure v1 set this as collect_flow_logs for metrics/firewall log collection only
      // TODO follow up and investigate, although
      // I don't think this is used for anything other than UI in editing page
      payload.manual_setup_type = 'collect_metadata';
    }

    return payload;
  }

  @computed
  get verificationFields() {
    return this.getVerificationFields();
  }

  @computed
  get currentStepFormFields() {
    return this.getStepFormFields(this.currentStepId);
  }

  // this is a computed prop, in case wizard steps will change based on cloud
  @computed
  get sidebarState() {
    return Object.values(this.exportWizardSteps).reduce((sidebarStateReducer, step) => {
      // get current step fields
      const stepFormFields = this.getStepFormFields(step.id);

      if (stepFormFields.length === 0) {
        const okToShowAsDone =
          step.id === `${this.selectedCloudProvider}-provider-configuration` ||
          step.id === `${this.selectedCloudProvide}-verification`;
        const isAlreadySeenStep = this.calculateStepIndex(step.id) < this.calculateStepIndex(this.lastSeenStepId);
        const isMaybeOkToShowAsDone = okToShowAsDone && isAlreadySeenStep;
        const disabledOrDoneProgressState = isMaybeOkToShowAsDone
          ? PROGRESS_STATES.DONE_OPTIONAL
          : PROGRESS_STATES.DISABLED;

        sidebarStateReducer[step.id] =
          step.id === this.currentStepId ? PROGRESS_STATES.IN_PROGRESS : disabledOrDoneProgressState;
        return sidebarStateReducer;
      }

      for (let i = 0; i < stepFormFields.length; i++) {
        const formField = stepFormFields[i];

        // if form field does not exist, we haven't rendered that step in the ui yet
        if (!formField) {
          sidebarStateReducer[step.id] = PROGRESS_STATES.DISABLED;
          return sidebarStateReducer;
        }

        // some field has error
        if (!formField.valid) {
          // mark as in progress when field is required but no value
          const isRequiredError = formField.errors.some((error) => error.includes('field is required.'));

          sidebarStateReducer[step.id] = isRequiredError ? PROGRESS_STATES.IN_PROGRESS : PROGRESS_STATES.ERROR;
          return sidebarStateReducer;
        }
      }

      // all fields are valid and exist
      sidebarStateReducer[step.id] = PROGRESS_STATES.DONE;
      return sidebarStateReducer;
    }, {});
  }

  getSidebarStateStatusByStepId(stepId) {
    if (!this.sidebarState[stepId]) {
      console.warn(`Unknown sidebar state - ${stepId}`);
      return '';
    }

    return this.sidebarState[stepId];
  }

  formHasField(fieldName) {
    return this.form.hasField(fieldName);
  }

  @computed
  get canSubmit() {
    if (this.useTerraform) {
      // terraform script automatically creates cloud export, no need to use this wizard to save
      return false;
    }
    // need to have made it to the last step with no errors before enabling submit
    return this.form.valid && this.isFinalStep;
  }

  @computed
  get currentStep() {
    return this.findStepById(this.currentStepId);
  }

  @computed
  get currentStepTitle() {
    return this.currentStep?.title;
  }

  calculateStepIndex(stepId) {
    return this.exportWizardSteps.findIndex((step) => step.id === stepId);
  }

  // used for navigation into next and prev steps
  @computed
  get currentStepIndex() {
    return this.calculateStepIndex(this.currentStepId);
  }

  @computed
  get isMetadataOnlyEnabled() {
    const {
      isFlowLogCollectionEnabled,
      isMetricsCollectionEnabled,
      isFirewallLogCollectionEnabled,
      isGcpCloudRunLogEnabled
    } = this;
    return (
      !isFlowLogCollectionEnabled &&
      !isMetricsCollectionEnabled &&
      !isFirewallLogCollectionEnabled &&
      !isGcpCloudRunLogEnabled
    );
  }

  @computed
  get isMetricsNothingElse() {
    const {
      isFlowLogCollectionEnabled,
      isMetricsCollectionEnabled,
      isFirewallLogCollectionEnabled,
      isGcpCloudRunLogEnabled
    } = this;
    return (
      isMetricsCollectionEnabled &&
      !isFlowLogCollectionEnabled &&
      !isFirewallLogCollectionEnabled &&
      !isGcpCloudRunLogEnabled
    );
  }

  @computed
  get isFlowLogCollectionEnabled() {
    return !!this.getFieldValue('properties.enable_flow_log_collection');
  }

  @computed
  get isMetricsCollectionEnabled() {
    return !!this.getFieldValue('properties.enable_metrics_collection');
  }

  @computed
  get isFirewallLogCollectionEnabled() {
    return !!this.getFieldValue('properties.enable_firewall_log_collection');
  }

  @computed
  get isGcpCloudRunLogEnabled() {
    return !!this.getFieldValue('properties.gcp_cloud_run');
  }

  @computed
  get isMetadataCollectDisabled() {
    return !!this.getFieldValue('metadata_collection');
  }

  @computed
  get isSecurityPrincipalAlreadyEnabled() {
    /*
    Given a subscription id, check for existing cloud exports with the same subscription id
    that have already enabled the NSG Flow Exporter security principal

    If we've already done that, we will skip that step with Azure and go directly to the access check using the rest of the form values
  */
    const subscriptionId = this.azureSubscriptionId;
    return !!$clouds.collection.models.find(
      (cloud) =>
        cloud.get('cloud_provider') === 'azure' &&
        cloud.get('properties.azure_subscription_id') === subscriptionId &&
        cloud.get('properties.azure_security_principal_enabled')
    );
  }

  @computed
  get observabilityFeatures() {
    const {
      isMetadataOnlyEnabled,
      isFlowLogCollectionEnabled,
      isMetricsCollectionEnabled,
      isFirewallLogCollectionEnabled,
      isGcpCloudRunLogEnabled
    } = this;
    return {
      isMetadataOnlyEnabled,
      isFlowLogCollectionEnabled,
      isMetricsCollectionEnabled,
      isFirewallLogCollectionEnabled,
      isGcpCloudRunLogEnabled
    };
  }

  @computed
  get isAllStepFieldsValid() {
    return this.currentStepFormFields.every((field) => field.valid);
  }

  // can go next when every field in current section is valid and there is another section to go to
  @computed
  get canGoNextSection() {
    return this.isAllStepFieldsValid && this.currentStepIndex < this.exportWizardSteps.length - 1;
  }

  @computed
  get isFinalStep() {
    return this.currentStepIndex === this.exportWizardSteps.length - 1;
  }

  @computed
  get canGoPrevSection() {
    return this.currentStepIndex > 0;
  }

  @computed
  get selectedCloudProvider() {
    return this.getFieldValue('cloud_provider');
  }

  @computed
  get useTerraform() {
    return this.getFieldValue('useTerraform');
  }

  @computed
  get usePowershell() {
    return this.getFieldValue('usePowershell');
  }

  @computed
  get azureSubscriptionId() {
    return this.getFieldValue('properties.azure_subscription_id');
  }

  @computed
  get isAzureSubscriptionIdValid() {
    return this.form?.getField('properties.azure_subscription_id')?.valid;
  }

  @computed
  get isSelectedCloudProviderAws() {
    return isAws(this.selectedCloudProvider);
  }

  @computed
  get isSelectedCloudProviderGcp() {
    return isGoogleCloud(this.selectedCloudProvider);
  }

  @computed
  get isSelectedCloudProviderAzure() {
    return isAzure(this.selectedCloudProvider);
  }

  @computed
  get isSelectedCloudProviderOci() {
    return isOci(this.selectedCloudProvider);
  }

  // placeholder to handle form save
  @action
  handleCompleteWizard = () => {
    if (!this.canSubmit) {
      return Promise.resolve(false);
    }
    const formValues = this.assignDefaults(this.form.getValues(), this.cloudModel);
    return Promise.resolve()
      .then(() => this.cloudModel.save(formValues).then(() => true))
      .catch((e) => {
        showErrorToast(e.message || 'An unknown error occurred attempting to save the cloud export');
        return false;
      });
  };

  @action
  goToNextSection = () => {
    const nextStepIndex = this.currentStepIndex + 1;
    const nextStep = this.exportWizardSteps[nextStepIndex];
    this.changeWizardStep(nextStep.id);
  };

  @action
  goToPrevSection = () => {
    const prevStepIndex = this.currentStepIndex - 1;
    const prevStep = this.exportWizardSteps[prevStepIndex];
    this.changeWizardStep(prevStep.id);
  };

  // generate regions options per cloud
  getFilteredOptionsByCloudProvider() {
    const cloudProviderOptions = this.store.$dictionary.getCloudMetadataKeyToNameMap(this.selectedCloudProvider);
    // filters out the aws wavelength agent regions
    return buildOptionsFromMap(cloudProviderOptions).filter((option) => !/-wl1-/.test(option.value));
  }

  getCloudPlanOptions() {
    return this.store.$setup.cloudPlanOptions(this.cloudModel.get('cloud_provider'));
  }

  getFieldValue(fieldName) {
    return this.form?.getValue(fieldName) ?? '';
  }

  @action
  onSampleChange({ sampling }) {
    this?.form.setValue('properties.sampling', sampling);
    if (!sampling) {
      this?.form.setValue('properties.sampling_rate', defaultSamplingRate);
    }
  }

  @action
  setCspSpecificFlowCollect() {
    if (this.isSelectedCloudProviderAzure) {
      this.form.setValue('properties.azure_collect_flow_logs', this.isFlowLogCollectionEnabled);
    }
    if (this.isSelectedCloudProviderOci) {
      this.form.setValue('properties.oci_collect_flow_logs', this.isFlowLogCollectionEnabled);
    }
  }

  @computed
  get storeSerializedObject() {
    return {
      currentStepId: this.currentStepId,
      formValues: this.form.getValues()
    };
  }
}

const cloudExportWizardStore = new CloudExportWizardStore();
// create a listener that will set form wizard steps based on current selected cloud
reaction(
  () => cloudExportWizardStore.selectedCloudProvider,
  (cloudProvider) => {
    cloudExportWizardStore.changeExportWizardStepsForCloudProvider(cloudProvider);
  }
);
// note: unfortunately useTerraform || isFlowLogCollectionEnabled doesn't work here hence 3 reactions
reaction(
  () => cloudExportWizardStore.useTerraform,
  () => {
    cloudExportWizardStore.changeExportWizardStepsToIncludeTerraformStep();
  }
);
reaction(
  () => cloudExportWizardStore.isFlowLogCollectionEnabled,
  () => {
    cloudExportWizardStore.setCspSpecificFlowCollect();
    cloudExportWizardStore.changeExportWizardStepsToIncludeTerraformStep();
  }
);
reaction(
  () => cloudExportWizardStore.usePowershell,
  () => {
    cloudExportWizardStore.changeExportWizardStepsToIncludeTerraformStep();
  }
);
reaction(
  () => {
    return cloudExportWizardStore.isMetadataCollectDisabled;
  },
  () => {
    cloudExportWizardStore.setSkipMetadataCollectionProperty();
  }
);

export default cloudExportWizardStore;
