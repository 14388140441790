import Model from 'core/model/Model';
import { computed } from 'mobx';
import $dictionary from 'app/stores/$dictionary';

class InterfaceGroupInterfaceModel extends Model {
  get id() {
    return `${this.get('device_id')}|${this.get('snmp_id')}`;
  }

  @computed
  get networkBoundary() {
    return $dictionary.get(`interfaceClassification.networkBoundaryTypes.${this.get('network_boundary')}`) || 'None';
  }

  @computed
  get connectivityType() {
    return $dictionary.get(`interfaceClassification.connectivityTypes.${this.get('connectivity_type')}`) || 'None';
  }

  @computed
  get capacity() {
    return parseInt(this.get('snmp_speed')) * 1000000;
  }

  @computed
  get capacityLabel() {
    const snmp_speed = parseInt(this.get('snmp_speed'));

    if (snmp_speed >= 1000) {
      return `${snmp_speed / 1000}Gbits/s`;
    }

    return `${snmp_speed}Mbits/s`;
  }
}

export default InterfaceGroupInterfaceModel;
