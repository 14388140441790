import Collection from 'core/model/Collection';
import { sum } from 'lodash';
import { computed } from 'mobx';
import { numberWithCommas } from 'app/util/utils';
import SiteModel from './SiteModel';

class SiteCollection extends Collection {
  get url() {
    return '/api/ui/sites';
  }

  get model() {
    return SiteModel;
  }

  get defaultSortState() {
    return {
      field: 'title',
      direction: 'asc'
    };
  }

  get filterFieldWhitelist() {
    return new Set(['id', 'title', 'address', 'city', 'region', 'country', 'postal', 'countryName', 'type']);
  }

  @computed
  get totalCost() {
    return sum(this.models.map((site) => site.totalCost));
  }

  @computed
  get totalCostFormatted() {
    return `$${numberWithCommas(this.totalCost)}`;
  }
}

export default SiteCollection;
