import Model from 'core/model/Model';

class SiteMarket extends Model {
  get urlRoot() {
    return '/api/ui/siteMarkets';
  }

  get omitDuringSerialize() {
    return ['site_count'];
  }
}

export default SiteMarket;
