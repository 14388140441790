export const METRICS_DEVICE_FIELD = 'km_device_id';

export const METRICS_INTERFACE_TYPES = {
  ds1: 'DS1',
  ds3: 'DS3',
  ethernetCsmacd: 'EthernetCarrierSense',
  gigabitEthernet: 'GigabitEthernet',
  hdlc: 'HDLC',
  ieee8023adLag: 'IEEE 802.3ad Link Aggregate',
  ip: 'IP',
  l2vlan: 'Layer 2 Virtual LAN',
  l3ipvlan: 'Layer 3 Virtual LAN',
  macSecControlledIF: 'MACSecControlled',
  macSecUncontrolledIF: 'MACSecUncontrolled',
  mpls: 'MPLS',
  mplsTunnel: 'MPLS Tunnel',
  opticalChannel: 'Optical Channel',
  other: 'Generic',
  pos: 'Packet over SONET/SDH Interface',
  ppp: 'PPP',
  pppMultilinkBundle: 'PPP Multilink Bundle',
  propMultiplexor: 'Proprietary Multiplexing',
  propPointToPointSerial: 'Proprietary Serial',
  propVirtual: 'Proprietary Virtual',
  softwareLoopback: 'Software Loopback',
  sonet: 'SONET',
  sonetPath: 'SONET Path',
  tunnel: 'Tunnel'
};

export const METRICS_STATUS_LABEL_MAP = {
  up: 'Up',
  down: 'Down',
  unobservable: 'Unobservable',
  possibly_down: 'Possibly Down',
  testing: 'Testing',
  unknown: 'Unknown',
  dormant: 'Dormant',
  notPresent: 'Not Present',
  lowerLayerDown: 'Lower Layer Down'
};

export const METRICS_INTERFACE_OPER_STATUS_MAP = {
  1: 'up',
  2: 'down',
  3: 'testing',
  4: 'unknown',
  5: 'dormant',
  6: 'notPresent',
  7: 'lowerLayerDown'
};

export const METRICS_INTERFACE_ADMIN_STATUS_MAP = {
  1: 'up',
  2: 'down',
  3: 'testing'
};

export const METRICS_ENTITY_TYPE_LABEL_MAP = {
  interface: 'Interface',
  device: 'Device',
  component: 'Component',
  bgpneighbor: 'BGP Neighbor',
  agent: 'Agent'
};

export const METRICS_INTERFACE_COMMON_CAPACITIES = ['100000', '40000', '10000', '2500', '1000', '100'];

export const METRICS_APP_PROTOCOL_MAP = { 5: 'snmp', 16: 'kmetrics' };
