import Model from 'core/model/Model';
import { getIPSortValue } from 'core/util/sortUtils';

class EmbeddedCacheModel extends Model {
  get urlRoot() {
    return '/api/ui/cdn/embedded-cache';
  }

  get sortValues() {
    return {
      cidr: (model) => getIPSortValue(model.get('cidr'))
    };
  }
}

export default EmbeddedCacheModel;
