import api from 'core/util/api';

import NetworkHealthTestCollection from './NetworkHealthTestCollection';
import NetworkHealthAgentCollection from './NetworkHealthAgentCollection';
import NetworkHealthTestDetailCollection from './NetworkHealthTestDetailCollection';

class NetworkHealthStore {
  collection = new NetworkHealthTestCollection();

  agents = new NetworkHealthAgentCollection();

  testDetailCollection = new NetworkHealthTestDetailCollection();

  isMonitoring(ip_address) {
    return this.collection.models.some((model) => model.get('target') === ip_address);
  }

  getAgentData(agent, test_id) {
    return api.get(`/api/ui/network-availability/agent/${agent.get('agent_id')}/test/${test_id}`).then((resp) => {
      agent.set(resp);
      return agent;
    });
  }

  fetchByIp(ip_address) {
    return api.get(`/api/ui/network-availability/test_by_ip/${ip_address}`).then((resp) => {
      if (resp.noContent) {
        return null;
      }
      return resp;
    });
  }

  enableMonitoring = (target) => {
    const newModel = this.collection.forge({ target, status: 'A' });
    return newModel.save({}, { toast: false });
  };
}

export default new NetworkHealthStore();
