import $auth from 'app/stores/$auth';
import Collection from 'core/model/Collection';
import SharedLinkModel from './SharedLinkModel';

export default class SharedLinksCollection extends Collection {
  get url() {
    return '/api/ui/shared-links';
  }

  get model() {
    return SharedLinkModel;
  }

  get defaultSortState() {
    return {
      field: 'cdate',
      direction: 'desc'
    };
  }

  async fetch(options) {
    if ($auth.hasPermission('linkSharing.disabled', { overrideForSudo: false })) {
      this.lastFetched = Date.now();
      this.hasFetched = true;

      return Promise.resolve();
    }

    return super.fetch(options);
  }
}
