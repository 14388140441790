import { action, computed } from 'mobx';

import { showSuccessToast } from 'core/components/toast';
import Model from 'core/model/Model';
import api from 'core/util/api';
import { injectLegacySavedFilters } from 'app/util/filters';
import $dictionary from 'app/stores/$dictionary';

export default class User extends Model {
  get defaults() {
    return {
      role: 'Administrator',
      user_level: 1,
      permissionOverrides: false,
      roles: []
    };
  }

  get urlRoot() {
    if (this.collection && this.collection.isAdmin) {
      return `/api/ui/sudo/user/${this.collection.companyId}`;
    }
    return '/api/ui/user';
  }

  @action
  fetchFullProfile = () =>
    api.get(`${this.urlRoot}/${this.id}`).then((response) => {
      this.set(this.deserialize(response.user));
      return this;
    });

  resetSessions = () =>
    api.post(`${this.urlRoot}/${this.id}/clearSessions`).then(() => {
      showSuccessToast('User sessions successfully reset');
      return this;
    });

  get messages() {
    return {
      create: `User ${this.get('user_email')} was added successfully`,
      update: `User ${this.get('user_email')} was updated successfully`,
      destroy: `User ${this.get('user_email')} was removed successfully`
    };
  }

  get removalConfirmText() {
    return {
      title: 'Remove User',
      text: `Are you sure you want to remove ${this.get('user_email')}?`
    };
  }

  @computed
  get userLevel() {
    const dictionaryValue = $dictionary.dictionary.userLevels[this.get('user_level')];
    return dictionaryValue.includes('Administrator')
      ? dictionaryValue.replace('Administrator', 'Admin')
      : dictionaryValue;
  }

  getSortValue(field) {
    if (field === 'last_login' && this.get('last_login') === null) {
      // 'never logged in' should be at the bottom when sorting for most recent
      return '0';
    }

    if (field === 'roles') {
      return this.get('roles')?.length || 0;
    }

    return super.getSortValue(field);
  }

  serialize(data) {
    let serialized = data;
    if (data) {
      const user_filter_json = JSON.stringify(data.filters);
      serialized = { ...data, user_name: data.username, user_filter_json };

      delete serialized.username;
      delete serialized.saved_filters; // server ignores, but let's just not send it

      // Only set user_permissions if they exist.
      if (this.get('permissionOverrides') !== true && serialized.settings) {
        delete serialized.settings.user_permissions;
      }
    }

    return super.serialize(serialized);
  }

  deserialize(inData) {
    if (!inData) {
      return inData;
    }

    const { filters, saved_filters } = inData;

    if (saved_filters && saved_filters.length > 0) {
      inData.filters = injectLegacySavedFilters(saved_filters, filters);
    }

    delete inData.saved_filters;
    return inData;
  }
}
