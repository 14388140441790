import SiteMarketCollection from './SiteMarketCollection';

export class SiteMarketStore {
  collection = new SiteMarketCollection();

  createSiteMarket = (siteMarketAttributes, options) => {
    const siteMarket = this.collection.forge();
    return siteMarket.save(siteMarketAttributes, options);
  };

  getSiteMarket = (siteMarketId) => this.collection.get(siteMarketId);
}

export default new SiteMarketStore();
