function serialize(a10) {
  // API expects mode value as a key
  a10[a10.mode] = a10[a10.mode] || {};
  delete a10.mode;
  return a10;
}

function deserialize(a10) {
  // Set A10 mode to static unless otherwise specified
  a10.mode = a10.dynamic ? 'dynamic' : 'static';

  if (a10.dynamic?.macros) {
    const { macros = [] } = a10.dynamic;
    a10.dynamic.macros = macros.map((macro) => {
      const macroType = macro.dynamic ? 'dynamic' : 'static';
      return {
        type: macroType,
        ...macro[macroType]
      };
    });
  }
  return a10;
}

export default {
  serialize,
  deserialize
};
