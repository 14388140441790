import { action, observable } from 'mobx';

import { showSuccessToast } from 'core/components/toast';
import api from 'core/util/api';
import Collection from 'core/model/Collection';

import QueryModel from 'app/stores/query/RawFlowQueryModel';
import { deviceColumn, metricColumns, timeColumns } from 'app/views/core/analytics/flowColumns';
import { getHashForQuery, getQueryForHash } from 'app/stores/query/urlHash';
import { flatten } from 'app/util/dictionaryUtils';
import moment from 'moment';

const fixedColumns = {
  ctimestamp: 150,
  flow_start_timestamp: 175,
  flow_end_timestamp: 175,
  row_number: 100,
  flow_duration: 150,
  i_start_time: 165
};

const TIMESTAMP_FIELDS = ['ctimestamp', 'flow_start_timestamp', 'flow_end_timestamp', 'flow_duration'];

class RawFlowStore {
  @observable
  isFetching = false;

  @observable
  isCanceling = false;

  @observable.ref
  queryModel;

  resultsCollection = new Collection();

  @observable
  columns = [];

  @observable
  formState;

  queryData = {};

  @action
  loadHash(query, options) {
    const { isForensic = false } = options || {};
    const path = isForensic ? 'forensic-raw-flow' : 'raw-flow';

    getHashForQuery(query).then((hash) => {
      this.setQueryData(query);
      this.history.push(`/v4/core/${path}/${hash}`);
    });
  }

  @action
  async loadQuery(hash) {
    return getQueryForHash(hash).then((query) => {
      this.setQueryData(query);
      return query;
    });
  }

  @action
  registerFormState(form) {
    this.formState = form;
  }

  @action
  fetchResults(queryData, options) {
    const { isForensic = false } = options || {};
    const url = isForensic ? '/api/ui/raw-flow/forensics' : '/api/ui/raw-flow/';

    this.resultsCollection.reset();

    const data = Object.assign({}, queryData, {
      filters: queryData.filters,
      devices: queryData.device_name
    });

    this.isFetching = true;
    const { time_format = 'utc' } = queryData;
    const startRequestTime = Date.now();
    api.post(url, { data }).then(
      (res) => {
        this.queryServiceUnavailable = false;

        this.isFetching = false;
        this.resultsCollection.set(res.results);
        this.columns = this.getTableColumns(res.columns, time_format);
        this.sql = res.sql;

        this.matchedResults = res.matched;
        this.totalResults = res.count;
        this.partialResults = res.partial || false;
        this.queryResponseTime = res.time;
        this.lastResponseTime = Date.now() - startRequestTime;

        showSuccessToast('Raw Flow Query executed successfully');
      },
      (err) => {
        if (err === 'Service unavailable') {
          this.queryServiceUnavailable = true;
        } else {
          this.queryServiceUnavailable = false;
        }

        this.isFetching = false;
        this.resultsCollection.set([]);
        this.columns = [];
        this.totalResults = 0;
        this.matchedResults = 0;
        this.partialResults = false;
        this.queryResponseTime = 0;
        this.lastResponseTime = Date.now() - startRequestTime;

        console.warn('Raw Flow received SQL error', err);
      }
    );
  }

  getTimestamp({ name, value, isLocal }) {
    if (name === 'flow_duration') {
      const duration = moment.duration(value / 1000);
      const days = duration.days();
      if (days) {
        duration.subtract(days, 'days');
      }

      return `${days ? `${days}d ` : ''}${moment.utc(duration.asMilliseconds()).format('HH:mm:ss.SSS')}`;
    }

    let timestamp;

    if (name === 'ctimestamp') {
      timestamp = moment.utc(value * 1000);
    } else if (name !== 'flow_duration') {
      timestamp = moment.utc(value / 1000);
    }

    return (isLocal ? timestamp : timestamp.local()).format(
      `YYYY-MM-DD HH:mm:ss${name !== 'ctimestamp' ? '.SSS' : ''}`
    );
  }

  @action
  cancelForensicsQuery() {
    if (!this.isCanceling) {
      this.isCanceling = true;
      api.post('/api/ui/raw-flow/forensics/cancel', {}).then(
        (res) => {
          this.queryServiceUnavailable = false;
          this.isCanceling = false;
          console.warn('Cancel response', res);
        },
        (err) => {
          this.isCanceling = false;
          console.warn('Cancel error', err);
        }
      );
    }
  }

  getCsvData(collection, columns) {
    return Promise.resolve().then(() => {
      const exportColumns = columns.filter((column) => column.name && !column.hidden);
      const csvRows = [];

      // Header row
      csvRows.push(
        exportColumns
          .map((column) => {
            const label = column.exportLabel || column.label;

            if (!label || typeof label === 'object') {
              return column.name;
            }

            return label;
          })
          .join(',')
      );

      if (collection) {
        // Data rows if using a proper collection
        collection.each((model) => {
          const csvCols = [];

          exportColumns.forEach((column) => {
            let value = model.get(column.name);

            if (column.renderer) {
              // If this thing gives us JSX, we have to throw up our hands
              const formattedValue = column.renderer({ value, model, collection, column });
              if (typeof formattedValue !== 'object') {
                value = formattedValue;
              }
            } else if (column.name === 'row_number') {
              value = parseInt(value, 10);
            }

            if (typeof value === 'string') {
              value = `"${value}"`;
            }

            csvCols.push(value);
          });

          csvRows.push(csvCols.join(','));
        });
      }

      return csvRows.join('\r\n');
    });
  }

  handleExport = (exportOptions = {}) => {
    const { urlHash, downloadRawFlow, fileNameText } = exportOptions;

    if (downloadRawFlow) {
      const collection = this.resultsCollection;
      const { columns } = this;
      if (!collection || !columns) {
        return;
      }

      const hash = Math.floor(Math.random() * 10000000000)
        .toString(16)
        .toUpperCase();

      const path = 'csv';
      const fileName = `${fileNameText}-${hash}`;
      const type = 'csv';
      const options = { path, fileName, type };

      this.getCsvData(collection, columns).then((payload) => {
        this.store.$exports.addPayload(payload, options);
      });
    } else {
      window.location = `/api/ui/raw-flow/forensics/csv/${urlHash}`;
    }
  };

  @action
  clearResults() {
    this.resultsCollection.reset();
    this.queryData = {};
    this.columns = [];
  }

  @action
  sortResults = (column) => {
    const { columns, resultsCollection } = this;
    const { name, sortDir: currentSortDir } = column;
    const sortDir = currentSortDir === 'asc' ? 'desc' : 'asc';

    this.columns = columns.map((col) => {
      if (col === column) {
        return {
          ...col,
          sorted: true,
          sortDir
        };
      }
      return {
        ...col,
        sorted: false
      };
    });

    resultsCollection.setSortState(name, sortDir);
    resultsCollection.sort(name);
  };

  @action
  openQuery(query) {
    const flowFieldsDim = new Set([
      'in_bytes',
      'in_pkts',
      'out_bytes',
      'out_pkts',
      'i_device_id',
      'Proto',
      'IP_src',
      'Port_src',
      'IP_dst',
      'Port_dst',
      ...query.get('metric')
    ]);
    this.queryModel = new QueryModel({
      order_by: 'ctimestamp',
      ...query.get(),
      flow_fields: Array.from(flowFieldsDim)
    });
    const newQuery = this.queryModel.serialize();
    this.loadHash(newQuery);
  }

  @action
  refresh() {
    this.fetchResults(this.queryData);
  }

  @action
  refreshForensicsResults() {
    this.fetchForensicResults(this.queryData);
  }

  @action
  setQueryData(values) {
    this.queryData = values;
  }

  @action
  setDashboardQueryModel(selectedQuery) {
    const serialized = selectedQuery.toJS();

    // Take all the computed fields
    serialized.device_name = serialized.all_devices ? [] : serialized.device_name;
    serialized.device_labels = serialized.all_devices ? [] : serialized.device_labels;
    serialized.device_sites = serialized.all_devices ? [] : serialized.device_sites;
    serialized.device_types = serialized.all_devices ? [] : serialized.device_types;

    if (serialized.all_devices) {
      serialized.device_name = [];
    } else if (!Array.isArray(serialized.device_name)) {
      serialized.device_name = serialized.device_name.split(',');
    }

    if (serialized.filters_obj && !serialized.filters) {
      serialized.filters = serialized.filters_obj;
      delete serialized.filters_obj;
    }

    if (serialized.saved_filters) {
      serialized.saved_filters = serialized.saved_filters.map((filter) => ({
        filter_id: filter.filter_id,
        is_not: filter.is_not || false
      }));
    }

    if (!this.queryModel) {
      this.queryModel = new QueryModel({
        all_devices: true,
        lookback_seconds: 300
      });
    }

    this.queryModel.set(serialized);
  }

  @action
  resetQueryModel(options) {
    const { isForensic = false } = options || {};
    const attributes = isForensic ? {} : { all_devices: true, lookback_seconds: 300 };

    this.queryModel = new QueryModel(attributes);
  }

  @action
  getTableColumns(columns, timeFormat) {
    const columnOptions = flatten(
      Object.assign({}, timeColumns, metricColumns, deviceColumn, this.store.$dictionary.filterFieldOptions)
    );

    return columns.map((name) => {
      let label = name;
      const option = columnOptions.find((opt) => opt.value.toLowerCase() === name.toLowerCase());
      if (option) {
        label = `${option.group !== 'none' ? option.group : ''} ${option.label}`;
      }
      const tableColumn = {
        name,
        label,
        width: fixedColumns[name] || null,
        sortDir: 'desc',
        sorted: false
      };
      if (TIMESTAMP_FIELDS.includes(name)) {
        tableColumn.renderer = ({ value }) => this.getTimestamp({ name, value, isLocal: timeFormat === 'Local' });
      }
      return tableColumn;
    });
  }
}

export default new RawFlowStore();
