import { action, observable, toJS, when, computed } from 'mobx';
import moment from 'moment';
import { get } from 'lodash';
import Model from 'core/model/Model';
import { buildFilterGroup } from 'core/util/filters';
import { timezone } from 'core/util/dateUtils';
import QueryModel from 'app/stores/query/QueryModel';
import { getModelFilters } from 'app/util/filters';
import getTenantPreviewFilters from 'app/util/mkp/getTenantPreviewFilters';
import { augmentQueryForCustomDimensions } from 'app/util/mkp/customDimensionUtils';
import applyMaxDateRangeToQuery from 'app/util/mkp/applyMaxDateRangeToQuery';
import { showErrorToast } from 'core/components/toast';
import queryString from 'query-string';

class DashboardStore {
  @observable.ref
  formState;

  @observable
  completedInitialParametricSubmit = false;

  @observable
  initialized = false;

  @observable
  isFetching = false;

  @observable
  isEditing = false;

  @observable
  isEditingProperties = false;

  @observable
  isEditingDashboardItem = false;

  @observable
  isCloningDashboardItem = false;

  @observable.ref
  dashboard;

  @observable
  renderedLastItem = false;

  selectedModels = [];

  executingItems = [];

  pendingItems = [];

  @action
  toggleEditing = (options) => {
    const { updateLayout = true } = options;
    this.isEditing = !this.isEditing;

    if (!this.isEditing && updateLayout) {
      this.dashboard.updateLayout();
    }

    setTimeout(this.reflowItems, 50);

    // Persist the editing state with this route so refresh doesn't reset it
    this.history.replace(this.history.location.pathname, {
      ...this.history.location.state,
      isEditing: this.isEditing
    });
  };

  @action
  setEditingProperties = (isEditingProperties) => {
    this.isEditingProperties = isEditingProperties;
  };

  @action
  setEditingDashboardItem = (isEditingDashboardItem) => {
    this.isEditingDashboardItem = isEditingDashboardItem;
  };

  @action
  setCloningDashboardItem = (isCloningDashboardItem) => {
    this.isCloningDashboardItem = isCloningDashboardItem;
  };

  @action
  refresh = () => this.dashboard.get('items').each((item) => item.refresh());

  @action
  reflowItems = () => this.dashboard.get('items').each((item) => item.reflow());

  @action
  export = ({ dashboard }) => {
    dashboard = dashboard || this.dashboard;

    if (dashboard.isIncompleteParametric) {
      showErrorToast('Guided Mode parameter must be applied before exporting');
      return;
    }

    const exportOptions = {
      path: `/api/ui/export/dashboards/${dashboard.id}`,
      fileName: `dashboard-${dashboard.get('dash_title').replace(/\s/g, '-').toLowerCase()}`,
      type: 'pdf',
      viewType: 'dashboard',
      viewId: dashboard.id,
      viewTitle: dashboard.get('dash_title')
    };

    exportOptions.urlParams = this?.formState ? this.formState.getValues() : dashboard.get('query');
    const parametric_fields = dashboard.get('parametric_fields');
    if (dashboard.get('parametric') && parametric_fields && parametric_fields.length) {
      exportOptions.urlParams.parametric_fields = parametric_fields;
    }
    if (exportOptions.urlParams.time_format === 'Local') {
      exportOptions.urlParams.time_format = moment().utcOffset();
    }
    this.store.$exports.fetchExport(exportOptions);
  };

  @action
  destroyDataViews = () => {
    const currentDashboardItems = this.dashboard && this.dashboard.get('items');
    if (currentDashboardItems && currentDashboardItems.models) {
      currentDashboardItems.each((item) => item.dataview.destroy());
    }
  };

  @action
  loadDashboard(options = {}) {
    const {
      dashboardId,
      dashboardModel,
      isEditing = false,
      isEditingProperties = false,
      completedInitialParametricSubmit,
      params
    } = options;

    const promise = dashboardModel ? Promise.resolve(dashboardModel) : this.fetchById(dashboardId);
    return promise.then(
      action((dashboard) => {
        this.reset();
        this.isEditing = isEditing;
        this.isEditingProperties = isEditingProperties;
        this.setCompletedInitialParametricSubmit(completedInitialParametricSubmit);
        this.prepareDashboard(dashboard, params);

        this.store.$app.setPageTitle(dashboard.get('dash_title'));
      })
    );
  }

  @action
  reset() {
    this.dashboard = null;
    this.completedInitialParametricSubmit = false;
    this.initialized = false;
    this.isFetching = false;
    this.isEditing = false;
    this.isEditingProperties = false;
    this.renderedLastItem = false;
    this.executingItems = [];
    this.pendingItems = [];
    this.selectedModels = [];
    this.destroyDataViews();
  }

  @action
  fetchById(id) {
    this.isFetching = true;
    this.initialized = false;

    // returns either an existing Dashboard from the collection, or creates a new Dashboard
    // if we landed on a direct link to the dashboard.

    const dashboardId = parseInt(id, 10);

    let dashboard = this.store.$dashboards.collection.get(dashboardId);
    if (!dashboard) {
      dashboard = this.store.$dashboards.collection.forge({ id: dashboardId });
    }

    return dashboard.fetch().then(
      action(() => {
        dashboard.generateItemLayout();
        this.isFetching = false;
        return dashboard;
      })
    );
  }

  handleDashboardSave = () => {
    const { itemsWithSelectedModels } = this.dashboard;
    const values = this.formState.getValues();
    const parametric = this.dashboard.get('parametric');
    const parametric_value_options = this.dashboard.get('parametric_value_options');
    const isPredefinedValueType = this.dashboard.get('parametric_value_type') === 'predefined';
    const lookupParametricTypes = [
      'country',
      'cdn',
      'network_boundary',
      'connectivity_type',
      'device',
      'site',
      'provider'
    ];

    if (parametric) {
      if (
        isPredefinedValueType &&
        lookupParametricTypes.indexOf(this.dashboard.get('parametric_fields')[0].type) === -1
      ) {
        this.setCompletedInitialParametricSubmit(parametric_value_options.indexOf(values.parametric_value) !== -1);
      }

      if (
        values.parametric_fields &&
        values.parametric_fields[0].type === this.dashboard.get('parametric_fields')[0].type
      ) {
        this.dashboard.set({ parametric_fields: values.parametric_fields });
      }
    }

    if (itemsWithSelectedModels.length) {
      itemsWithSelectedModels.forEach((item) => item.clearSelectedModels());
      this.setQueryFilterSelections();
    } else {
      this.propagateQuery();
    }

    this.setEditingProperties(false);
  };

  @action
  addDashboardItem = (panel_type, attributes = {}, keepDashboard = true, srcDashboardItem) => {
    const { id, dashboard, dashboard_id, panel_type: attrPanelType, panel_title, ...rest } = attributes;
    const options = {};
    if (srcDashboardItem) {
      const { viewModel } = srcDashboardItem.dataview;
      if (viewModel) {
        options.viewModel = viewModel.duplicate();
      }
      // i.e. for synth_test panels, use the query from the dataview when cloning the panel
      const { query } = srcDashboardItem.dataview;
      if (query) {
        Object.assign(rest, { query: { ...query } });
      }
    }

    const positionData = {};
    if (keepDashboard) {
      positionData.y = this.dashboard.newItemY;
    }

    this.dashboard.get('items').forge(
      {
        ...rest,
        panel_title: srcDashboardItem ? `[COPY] ${panel_title}` : undefined,
        dashboard: keepDashboard ? this.dashboard : undefined,
        dashboard_id: keepDashboard ? this.dashboard.id : undefined,
        panel_type,
        ...positionData
      },
      options
    );

    this.setEditingDashboardItem(true);
  };

  // Note: this logic depends on always fetching a fresh copy of a dashboard model
  // when loading since it mutates the collection. Any use of the query in the Dashboards
  // view will be impacted
  @action
  prepareDashboard(dashboard, params) {
    const {
      location: { state, search }
    } = this.history;

    if (dashboard.isPreset) {
      const device_types = dashboard.get('query').device_types || [];
      Object.assign(dashboard.get('query'), {
        all_devices: !device_types.length,
        device_name: [],
        device_labels: [],
        device_sites: [],
        device_types
      });
    }
    let overrides = {};

    if (state) {
      const { completedInitialParametricSubmit, initialParametricFields, ...queryOverrides } = state;
      if (completedInitialParametricSubmit && initialParametricFields && initialParametricFields.length) {
        dashboard.set({ parametric_fields: initialParametricFields });
      }
      if (queryOverrides) {
        overrides = queryOverrides;
      }
    }
    if (search) {
      let completedInitialParametricSubmit = false;
      const initialParametricFields = [];
      const guidedParams = queryString.parse(search);
      const parametricFields = dashboard.get('parametric_fields');
      parametricFields.forEach((field) => {
        const value = guidedParams[field.type];
        initialParametricFields.push({ ...field, value });
        if (value !== undefined) {
          completedInitialParametricSubmit = true;
        }
      });
      if (completedInitialParametricSubmit && initialParametricFields && initialParametricFields.length) {
        dashboard.set({ parametric_fields: initialParametricFields });
        this.setCompletedInitialParametricSubmit(true);
      }
    }

    if (!this.store.$app.isExport) {
      overrides.time_format = timezone.value;
    }

    if (params) {
      const { parametric_fields, previewingTenant, ...parsedParams } = JSON.parse(decodeURIComponent(params)) || {};
      if (Array.isArray(parametric_fields) && parametric_fields.length) {
        dashboard.set({ parametric_fields });
      }
      overrides = { ...overrides, ...parsedParams };
      this.history.replace(this.history.location.pathname, {
        ...this.history.location.state,
        previewingTenant
      });
    }

    // In case of legacy saved queries, we need to ensure all_selected is not defined.
    if (Object.keys(overrides).length) {
      overrides.all_selected = undefined;
    }

    this.dashboard = dashboard;

    // We need to always make sure there is a layout and it includes any newly added items
    dashboard.set({ layout: dashboard.generateItemLayout() });

    this.propagateQuery(overrides);

    this.initialized = true;
  }

  @action
  clearSelectedModelsFromAllItems() {
    this.dashboard.itemsWithSelectedModels.forEach((dashboardItem) => {
      dashboardItem.clearSelectedModels();
    });
  }

  clearFilterGroups(groups, filterFields) {
    return (
      Array.isArray(groups) &&
      groups.forEach((group) => {
        if (Array.isArray(group.filters)) {
          group.filters = group.filters.filter((filter) => !filterFields.includes(filter.filterField));
        }

        return group.filterGroups ? this.clearFilterGroups(group.filterGroups, filterFields) : undefined;
      })
    );
  }

  /**
   * This function assumes that the caller has merged in existing filter state
   *
   * @param query
   * @param options
   * @param options.forceQueryUpdate discards previous dashboard item state. Should be used when triggered by any
   * interaction outside direct dashboard control.
   */
  @action
  propagateQuery(query = {}, options = { forceQueryUpdate: false }) {
    const dashboardQuery = this.dashboard.get('query');

    const newQuery = Object.assign({}, dashboardQuery, query);
    const reserializedQuery = QueryModel.create(newQuery).serialize();

    if (options.forceQueryUpdate) {
      this.dashboard.itemsSortedByLayout.forEach((item) => {
        item.running = false;
      });
      this.clearSelectedModelsFromAllItems();
    }

    if (this.store.$app.isSubtenant || get(this.history.location, 'state.previewingTenant')) {
      const maxDateRange =
        this.store.$auth.getActiveUserProperty('userGroup.config.max_date_range') ||
        get(this.history.location.state, 'previewingTenant.config.max_date_range');

      if (maxDateRange) {
        applyMaxDateRangeToQuery(reserializedQuery, maxDateRange);

        // Force applying max range (ignore time_locked) since we can't do this in node for previewing
        reserializedQuery.forceMaxRange = !!get(this.history.location, 'state.previewingTenant');
      }
    }

    this.dashboard.query = reserializedQuery;

    // Note: this logic depends on always fetching a fresh copy of a dashboard model
    // when loading since it mutates the collection. Any use of the query in the Dashboards
    // view will be impacted
    if (this.dashboard.isIncompleteParametric) {
      if (query && query.filters && this.formState) {
        this.formState.setValue('filters.connector', query.filters.connector);
        this.formState.setValue('filters.filterGroups', query.filters.filterGroups);
      }

      return;
    }

    if (this.dashboard.get('parametric') && query.filters) {
      const parametric_fields = this.dashboard.get('parametric_fields');
      const [field] = parametric_fields;
      const { type: parametricFieldType } = field;

      const dictionaryOption = this.store.$dictionary.parametricDashboardFilterOptions.find(
        (option) => option.type === parametricFieldType
      );
      if (dictionaryOption) {
        const { filterFields } = dictionaryOption;
        this.clearFilterGroups(query.filters.filterGroups, filterFields);
      }
    }

    // Strip out the 'guided' part because it's no longer relevant
    const pathname = this.history.location.pathname.replace(/\/guided$/, '');
    this.history.replace(pathname, {
      ...this.history.location.state,
      completedInitialParametricSubmit: this.dashboard.isParametric,
      initialParametricFields: toJS(this.dashboard.get('parametric_fields'))
    });

    this.updateDashboardItems(this.dashboard.itemsSortedByLayout, reserializedQuery, options);

    this.dashboard.query = reserializedQuery;
  }

  runItemQueries() {
    this.executingItems.forEach((itemEntry) => {
      const { item, itemUpdateFn } = itemEntry;
      if (!item.fullyLoaded && !item.running) {
        itemUpdateFn();
        item.running = true;
        when(
          () => item.fullyLoaded,
          () => {
            item.running = false;
            const idx = this.executingItems.indexOf(itemEntry);
            this.executingItems.splice(idx, 1);
            if (this.pendingItems.length) {
              const nextItem = this.pendingItems.shift();
              this.executingItems.push(nextItem);
              this.runItemQueries();
            }
          }
        );
      }
    });
  }

  updateDashboardItems(items, reserializedQuery, options) {
    const { targetDashboardItem } = options;
    let custom_dimensions;
    if (get(this.history.location, 'state.previewingTenant')) {
      custom_dimensions = get(this.history.location.state, 'previewingTenant.config.custom_dimensions');
    }

    if (targetDashboardItem) {
      return items.find((item) => item.id === targetDashboardItem.get('id')).updateQuery(reserializedQuery);
    }

    this.executingItems = [];
    this.pendingItems = [];

    return when(
      () => this.renderedLastItem,
      () => {
        items
          .filter((item) => !!item)
          .forEach((item) => {
            if (item.fullyLoaded) {
              item.dataview.eachBucket('setLoading', [true]);
              item.dataview.resetFullyLoaded();
            }

            const itemEntry = {
              item,
              itemUpdateFn: () =>
                item.updateQuery(
                  custom_dimensions
                    ? augmentQueryForCustomDimensions(item, reserializedQuery, custom_dimensions)
                    : reserializedQuery
                )
            };

            if (
              this.executingItems.length < 8 &&
              item.component.getIsInitiallyVisibleByLayout(
                this.dashboard.get('layout').lg.find((layout) => parseInt(layout.i) === parseInt(item.id))
              )
            ) {
              this.executingItems.push(itemEntry);
            } else {
              this.pendingItems.push(itemEntry);
            }
          });

        this.runItemQueries();
      }
    );
  }

  reorderPendingItems() {
    this.pendingItems = this.pendingItems.sort((a, b) => {
      if (a.item.component.isVisible) {
        return -1;
      }
      return Math.abs(a.item.component.scrollOffset) - Math.abs(b.item.component.scrollOffset);
    });
  }

  clearSelectedModels(dashboardItem) {
    const hadSelectedModels = dashboardItem.selectedModels && dashboardItem.selectedModels.length;
    dashboardItem.clearSelectedModels();
    if (dashboardItem.get('panel_filtering') && hadSelectedModels) {
      this.setQueryFilterSelections();
    }
  }

  @action
  selectModel = (model, dashboardItem, shouldNavigate) => {
    const isNavigationEnabled = dashboardItem.get('dashboard_navigation');
    const hasDashboardDestination = isNavigationEnabled && dashboardItem.get('destination_dashboard');
    const { selectedModels } = dashboardItem;
    const key = model.get('key');
    const idx = selectedModels.findIndex((selectedModel) => selectedModel.get('key') === key);

    if (idx >= 0) {
      selectedModels.splice(idx, 1);
    } else {
      selectedModels.push(model);
    }

    if (hasDashboardDestination) {
      if (shouldNavigate) {
        const parametric = dashboardItem.get('dashboard_navigation_options.parametric');
        this.setCompletedInitialParametricSubmit(parametric !== 'prompt');

        this.store.$dashboards.navigateToNestedDashboard(this.dashboard, dashboardItem);
        return;
      }
      dashboardItem.dataview.setSelectedModels(selectedModels);
    }

    clearTimeout(this.queryDebouncer);
    if (dashboardItem.get('panel_filtering')) {
      this.dashboard
        .get('items')
        .filter((item) => item !== dashboardItem, { immutable: true })
        .forEach((item) => {
          item.dataview.eachBucket('setLoading', [true]);
        });

      this.queryDebouncer = setTimeout(() => this.setQueryFilterSelections(), 500);
    } else {
      dashboardItem.dataview.setSelectedModels(selectedModels);
    }
  };

  getGeneratorModelFilters(item, model) {
    const { formState } = this;
    const modelDataview = model.collection.dataview;
    const modelMetrics = modelDataview.queryBuckets.activeBuckets[0].firstQuery
      .get('metric')
      .filter((m) => m !== 'Traffic');

    // merge root item metrics and subpanel metrics together (excluding Total/Traffic)
    const metric = item.queryBuckets[0].firstQuery.get('metric').concat(modelMetrics);
    // get lookup values so we can merge these together
    const modelLookup = modelMetrics.length > 0 ? model.get('lookup') || model.get('key') : '';
    const lookup = modelDataview.lookup || modelDataview.key;

    // fake out getModelFilters by giving it what it needs to create proper filter sets
    return getModelFilters({
      model: new Model({ lookup: `${lookup}${modelLookup ? ' ---- ' : ''}${modelLookup}` }),
      bucket: { firstQuery: new Model({ metric, filters: {} }) },
      formState
    });
  }

  // TODO @stan need to clear this out?
  setQueryFilterSelections(options = {}) {
    const { ignoreItem } = options;
    // Clear out existing filters (created via selected models)
    const existingFilterGroups = this.formState.getValue('filters.filterGroups');
    this.formState.setValue(
      'filters.filterGroups',
      existingFilterGroups.filter((group) => group.autoAdded !== true)
    );

    const updatedItems = [];
    // Get current filter values (those not removed)
    let { filters } = this.formState.getValues();

    this.dashboard.itemsWithSelectedModels.forEach((dashboardItem) => {
      const { dataview, selectedModels } = dashboardItem;
      selectedModels.forEach((m) => {
        if (dataview.viewType === 'generator') {
          filters = this.getGeneratorModelFilters(dashboardItem, m);
        } else {
          filters = getModelFilters({
            model: m,
            bucket: dashboardItem.queryBuckets[0],
            formState: this.formState
          });
        }

        this.formState.getField('filters.filterGroups').setValue(filters.filterGroups);
        this.formState.getField('filters.connector').setValue(filters.connector);
      });
      this.dashboard
        .get('items')
        .filter((item) => item.get('id') !== dashboardItem.get('id') && item !== ignoreItem, {
          immutable: true
        })
        .forEach((targetDashboardItem) => {
          updatedItems.push(targetDashboardItem);
          this.propagateQuery({ filters }, { targetDashboardItem });
        });

      dataview.setSelectedModels(selectedModels);
    });

    this.dashboard
      .get('items')
      .filter((item) => !item.selectedModels.length && !updatedItems.includes(item) && item !== ignoreItem, {
        immutable: true
      })
      .forEach((nonSelectedModelItem) => {
        this.propagateQuery({ filters }, { targetDashboardItem: nonSelectedModelItem });
      });

    // Reset form state so no UnappliedChanges
    this.formState.init(this.formState.getValues());
  }

  setInPlaceSelections() {
    this.dashboard.get('items').each((item) => {
      if (item === this.controlPanel) {
        item.dataview.setSelectedModels(this.selectedModels);
      } else {
        item.dataview.setVisibleModels(this.selectedModels);
      }
    });
  }

  applyTenantFilter = (tenant) => {
    const currentFilters = buildFilterGroup({
      connector: this.formState.getValue('filters.connector'),
      filterGroups: this.formState.getValue('filters.filterGroups')
    });
    this.history.replace(this.history.location.pathname, {
      ...this.history.location.state,
      previewingTenant: tenant.toJS()
    });

    this.propagateQuery({ filters: getTenantPreviewFilters(tenant, currentFilters) });
  };

  @action
  setCompletedInitialParametricSubmit(completed) {
    this.completedInitialParametricSubmit = completed;
  }

  @action
  registerFormState(form) {
    this.formState = form;
  }

  @computed
  get isCloudDashboard() {
    return !!this.dashboard?.isCloudDashboard;
  }

  @computed
  get dashboardCloudProviders() {
    return this.dashboard?.dashboardCloudProviders ?? [];
  }
}

export default new DashboardStore();
