import { computed } from 'mobx';
import $auth from 'app/stores/$auth';
import Collection from 'core/model/Collection';
import MitigationMethodModel from './MitigationMethodModel';

class MitigationMethodCollection extends Collection {
  get url() {
    return '/api/ui/mitigations/methods';
  }

  get model() {
    return MitigationMethodModel;
  }

  get defaultSortState() {
    return {
      field: 'id',
      direction: 'desc'
    };
  }

  @computed
  get mitigationOptions() {
    const isMit2bgpEnabled = $auth.hasPermission('alerts.mit2bgp.enabled', { overrideForSudo: false });
    return this.generateSelectOptions({ labelKey: 'name', valueKey: 'id' }).filter(
      (option) => isMit2bgpEnabled || option.type !== 'mit2bgp'
    );
  }

  deserialize(data) {
    if (data.methods) {
      return data.methods;
    }

    return [];
  }
}

export default MitigationMethodCollection;
