import { PLATFORM_TYPES } from '../mitigationsConstants';

import mit2bgpMethodSerializations from './mit2bgpMethodSerializations';
import flowspecMethodSerializations from './flowspecMethodSerializations';
import a10MethodSerializations from './a10MethodSerializations';
import rtbhMethodSerializations from './rtbhMethodSerializations';

function serializeMethodType(data) {
  const { type } = data;

  switch (type) {
    case 'rtbh':
      return rtbhMethodSerializations.serialize(data[type]);
    case 'a10':
      return a10MethodSerializations.serialize(data[type]);
    case 'flowspec':
      return flowspecMethodSerializations.serialize(data[type]);
    case 'mit2bgp':
      return mit2bgpMethodSerializations.serialize(data[type]);
    default:
      return data[type];
  }
}

function deserializeMethodType(data, passedType) {
  // Try to Infer type if none passed
  const type = passedType || PLATFORM_TYPES.find((option) => data[option.value])?.value;

  switch (type) {
    case 'rtbh':
      return rtbhMethodSerializations.deserialize(data[type]);
    case 'a10':
      return a10MethodSerializations.deserialize(data[type]);
    case 'flowspec':
      return flowspecMethodSerializations.deserialize(data[type]);
    case 'mit2bgp':
      return mit2bgpMethodSerializations.deserialize(data[type]);
    default:
      return data[type];
  }
}

export default {
  deserializeMethodType,
  serializeMethodType
};
