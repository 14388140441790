import moment from 'moment';
import { computed } from 'mobx';
import Model from 'core/model/Model';
import $cost from 'app/stores/cost/$cost';
import CostGroupModel from './CostGroupModel';

class CostHistoryModel extends Model {
  get defaults() {
    return {
      metadata: {}
    };
  }

  @computed
  get previous() {
    const { cost_group_id, start_date } = this.get();
    const prevMonth = moment(start_date).subtract(1, 'months');

    return this.collection.models.find(
      (model) =>
        model.get('cost_group_id') === cost_group_id && moment(model.get('start_date')).isSame(prevMonth, 'day')
    );
  }

  @computed
  get sites() {
    const metadata = this.get('metadata');
    const { results = {} } = metadata;
    const { sites = {}, trafficDirection } = results;

    return Object.values(sites).map((site) => ({
      ...site,
      trafficDirection,
      currency: this.get('currency')
    }));
  }

  @computed
  get costGroupCurrent() {
    const { cost_group_id, metadata = {} } = this.get();
    const { provider_id } = metadata.costGroup;
    return $cost.providers.get(provider_id)?.costGroups?.get(cost_group_id);
  }

  deserialize(data = {}) {
    // Remove the costGroup property (this is the current costGroup) here to avoid confusion.
    // We always want to use the historical cost group that's in the metadata object
    delete data.costGroup;

    const traffic = data.metadata.results.traffic || 0;
    const trafficDirection = data.metadata.results.trafficDirection || '';

    this.costGroup = new CostGroupModel({
      ...data.metadata.costGroup,
      traffic,
      trafficDirection,
      interfaces: Object.values(data.interfaces)
    });

    return {
      ...data,
      traffic,
      trafficDirection
    };
  }
}

export default CostHistoryModel;
