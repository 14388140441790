import React from 'react';
import { computed } from 'mobx';
import { get } from 'lodash';

import Flex from 'core/components/Flex';

import api from 'core/util/api';
import CapacityPlanCollection from 'app/stores/capacityPlan/CapacityPlanCollection';
import { FaCheckCircle } from 'react-icons/fa';
import { BsExclamationTriangleFill, BsXOctagonFill } from 'react-icons/bs';

class CapacityStore {
  collection = new CapacityPlanCollection(undefined);

  @computed
  get capacityOptions() {
    return this.getCapacityOptions(this.collection.get());
  }

  getCapacityOptions(capacities) {
    return capacities.map((capacity) => ({
      value: capacity.id,
      label: (
        <Flex justifyContent="space-between" alignItems="center" flex={1}>
          {capacity.get('name')}
        </Flex>
      ),
      filterLabel: capacity.get('name')
    }));
  }

  get severityTypes() {
    return {
      healthy: { weight: 0, value: 'healthy', color: 'success', icon: FaCheckCircle, label: 'Healthy' },
      warning: { weight: 1, value: 'warning', color: 'warning', icon: BsExclamationTriangleFill, label: 'Warning' },
      critical: { weight: 2, value: 'critical', color: 'danger', icon: BsXOctagonFill, label: 'Critical' }
    };
  }

  get severityStatuses() {
    return ['Healthy', 'Warning', 'Critical'];
  }

  exportPlanPdf = (plan) => {
    const name = plan ? 'capacityplans' : 'capacitysummary';

    this.store.$exports.fetchExport({
      path: '/api/ui/export/exportPage',
      type: 'pdf',
      fileName: `export-${name}-${Date.now()}`,
      exportOptions: { location: plan?.id ? `/v4/export/core/capacity/${plan.id}` : '/v4/export/core/capacity' }
    });
  };

  exportPlanCsv = (plan) => {
    const name = plan ? 'capacityplans' : 'capacitysummary';

    const path = `/api/ui/capacity/csv/${plan?.id ? plan.id : ''}`;
    const appName =
      this.store.$app.isSubtenant && this.store.$auth.hasPermission('subtenancy.advancedMode')
        ? get(this.store.$auth.openConfig, 'subtenancy.config.branding.appName', '').replace(/\s/g, '')
        : 'kentik';
    const fileName = `${appName ? `${appName}-` : ''}export-${name}-${Date.now()}`;
    const type = 'csv';
    const options = { path, fileName, type };

    this.store.$exports.addLoadingExport(options);
    return api.get(path, { rawResponse: true }).then((response) => {
      this.store.$exports.clearLoadingExport(options);
      this.store.$exports.addPayload(response.text, options);
    });
  };

  fetchCapacityPlansOverview() {
    return api.get('/api/ui/capacity/overview');
  }

  fetchBoxAndWhisker({ deviceId, snmpId, planId }) {
    return api.get(`/api/ui/capacity/boxWhisker/${deviceId}/${snmpId}/${planId}`);
  }

  fetchPlansForInterface({ deviceId, snmpId }) {
    return api.get(`/api/ui/capacity/search/${deviceId}/${snmpId}`);
  }

  executeInsight() {
    return api.post('/api/ui/insights/execute/operate.capacity.capacity', {
      body: { save: true },
      showErrorToast: false
    });
  }
}

export default new CapacityStore();
