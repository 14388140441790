import CustomerCollection from './CustomerCollection';
import { getQuery } from './customerQueries';

class CustomerStore {
  customers = new CustomerCollection();

  getQuery({ metric, customer, isSrc }) {
    return getQuery({ metric, interfaces: customer.get('interfaceGroup').interfaces, isSrc });
  }
}

export default new CustomerStore();
