import { InboundFilters, InboundFilterGroups, OutboundFilters, OutboundFilterGroups } from './inboundOutboundQuery';

export default {
  aggregateTypes: ['p95th_bits_per_sec'],
  topx: 0,
  lookback_seconds: 86400,
  all_devices: true,
  show_overlay: false,
  show_total_overlay: false,
  aggregateFiltersEnabled: true,
  aggregateFilters: [
    {
      name: 'Inbound',
      named: true,
      connector: 'All',
      not: false,
      autoAdded: '',
      saved_filters: [],
      ...InboundFilters,
      ...InboundFilterGroups
    },
    {
      name: 'Outbound',
      named: true,
      connector: 'All',
      not: false,
      autoAdded: '',
      saved_filters: [],
      ...OutboundFilters,
      ...OutboundFilterGroups
    }
  ]
};
