import { action, computed, observable } from 'mobx';

import api from 'core/util/api';

import Collection from 'core/model/Collection';
import { RuleTestState } from 'app/stores/interface/RuleTestState';
import $auth from 'app/stores/$auth';
import InterfaceClassCollection from './interfaceClass/InterfaceClassCollection';
import InterfacesDeviceCollection from './InterfacesDeviceCollection';
import InterfaceCollection from './InterfaceCollection';
import InterfaceSettings from './InterfaceSettings';
import RuleCollection from './RuleCollection';
import RuleState from './RuleState';

const isInterfaceClassified = (intf) =>
  // any interface where connectivity_type and network_boundary have been set is classified, regardless of anything else.
  (intf.connectivity_type !== intf.initial_connectivity_type &&
    intf.network_boundary !== intf.initial_network_boundary) ||
  // otherwise we need to check whether the rules have been matched
  (intf.rules_matched && intf.rules_matched.some((rule) => rule.didClassify));

class InterfaceClassStore {
  collection = new InterfaceClassCollection();

  @observable
  isRuleEvaluationRequired = false;

  @observable
  isClassificationDataReady = false;

  @observable
  isManualClassificationDataReady = false;

  @observable
  unclassifiedInterfaceCount = 0;

  @observable
  totalInterfaceCount = 0;

  // Current Rules State (as represented on server)
  ruleState;

  ruleCollection = new RuleCollection();

  deviceCollection = new InterfacesDeviceCollection();

  // Active Rule Test State (not yet persisted stuff)
  ruleTestState;

  testResultsInterfaceCollection = new InterfaceCollection();

  testResultsDeviceCollection = new InterfacesDeviceCollection();

  settings = new InterfaceSettings();

  allUnclassifiedInterfacesCollection = new InterfaceCollection([], { groupBy: 'device_name' });

  manualDeviceCollection = new InterfacesDeviceCollection();

  @action
  initialize() {
    this.allUnclassifiedInterfacesCollection.hasFetched = true;

    this.ruleState = new RuleState({
      onSuccess: this.handleSuccess,
      deviceCollection: this.deviceCollection,
      ruleCollection: this.ruleCollection,
      settings: this.settings
    });

    this.ruleTestState = new RuleTestState({
      deviceCollection: this.testResultsDeviceCollection,
      settings: this.settings
    });
  }

  @action
  initClassificationCollections(deviceId) {
    this.isClassificationDataReady = false;

    Promise.all([this.ruleCollection.fetch(), this.settings.fetch(), this.deviceCollection.fetch()]).then(
      ([, , devices]) => {
        this.deviceCollection.filter(undefined, {
          customFilter: (model) => model.get('device_status') === 'V'
        });
        this.testResultsDeviceCollection.set(this.testResultsDeviceCollection.deserialize(devices));

        this.ruleCollection
          .getRuleMatchResults()
          .then((results) => {
            if (results.length === 0) {
              this.evaluateRules();
            } else {
              this.loadExistingRuleResults(results);
            }
            this.deviceCollection.setPresetFilter({ fn: (model) => model.get('interfaces')?.length });
            this.deviceCollection.sort();
            this.isClassificationDataReady = true;
          })
          .then(() => deviceId && this.toggleDeviceInterfaceDetails(this.deviceCollection.get(deviceId)));
      }
    );
  }

  @action
  initManualClassificationCollections = () => {
    this.isManualClassificationDataReady = false;

    return Promise.all([
      this.settings.fetch(),
      this.manualDeviceCollection.fetch(),
      api.get('/api/ui/interfaces/rules/classifiedCounts')
    ]).then(([, , classifiedCounts]) => {
      this.manualDeviceCollection.updateDeviceInterfaceMatchData(classifiedCounts);
      this.manualDeviceCollection.sort();
      this.isManualClassificationDataReady = true;
    });
  };

  @action
  setAllUnclassifiedInterfaces() {
    let unclassifiedCount = 0;
    let totalCount = 0;
    const allUnclassifiedInterfaces = this.ruleState.evaluationResults.reduce((acc, result) => {
      const { device_id, interfaces = [] } = result;
      totalCount += interfaces.length;

      // First we check for unclassified interfaces at the device level
      const device = this.deviceCollection.modelById[device_id];
      if (device && device.get('interfacesClassifiedCount') === device.get('interfaceCount')) {
        return acc;
      }

      // Then we check to determine which unclassified interfaces exist within the device
      const unmatchedInterfaces = interfaces.filter((intf) => !isInterfaceClassified(intf));
      if (!unmatchedInterfaces?.length) {
        return acc;
      }
      unclassifiedCount += unmatchedInterfaces.length;

      // add device_name for filtering and grouping (TODO rework this as it does not scale)
      const device_name = this.deviceCollection.deviceIdNameHash[result.device_id];
      // return flattened array collection, one per interface
      return acc.concat(...unmatchedInterfaces.map((intf) => ({ ...intf, device_name, device_id })));
    }, []);

    this.unclassifiedInterfaceCount = unclassifiedCount;
    this.totalInterfaceCount = totalCount;
    this.allUnclassifiedInterfacesCollection.set(allUnclassifiedInterfaces);
  }

  @action
  resetRuleStates() {
    this.isClassificationDataReady = false;
    this.ruleCollection.reset();
    this.deviceCollection.reset();
    this.testResultsDeviceCollection.reset();
    this.ruleState.reset();
  }

  @action
  resetRuleTestStates() {
    this.testResultsDeviceCollection.clearSelection();
    this.testResultsInterfaceCollection.reset();
    this.ruleTestState.reset();
  }

  @action
  loadExistingRuleResults(results) {
    results.forEach((device) => {
      if (Object.keys(device.results).length > 0) {
        this.ruleState.addEvaluationResult(device.results);
      }
    });
    this.deviceCollection.updateDeviceInterfaceMatchData(this.ruleState.deviceMatches);
    this.ruleCollection.updateRuleInterfaceMatchData(this.ruleState.ruleInterfaceMatches);
    this.setAllUnclassifiedInterfaces();

    this.ruleState.setEvaluationComplete(true);
  }

  @action
  toggleDeviceInterfaceDetails(device) {
    if (device.isSelected) {
      this.deviceCollection.clearSelection();
      const interfaceCollection = device.get('interfaceCollection');
      interfaceCollection.reset();
      interfaceCollection.ruleCollection = null;
    } else {
      const interfaceCollection = new InterfaceCollection(device.get('interfaces'));
      interfaceCollection.hasFetched = true;

      if (this.ruleTestState.rule) {
        // Need to propagate rule information to the rule collection so the resultant view will see it,
        // making sure that we don't overwrite anything (existing rules) or skip anything (IDs).
        const ruleCollection = new RuleCollection(this.ruleCollection.toJS());
        if (this.ruleTestState.rule.isNew) {
          ruleCollection.add([this.ruleTestState.rule.toJS()]);
          ruleCollection.at(ruleCollection.size - 1).optimisticId = this.ruleTestState.rule.optimisticId;
        } else {
          const existingRule = ruleCollection.get(this.ruleTestState.rule.id);
          existingRule.set(this.ruleTestState.rule.toJS());
        }

        // Set the "active" rule on the Interface collection so we can do some data gathering
        interfaceCollection.activeRule = this.ruleTestState.rule;
        interfaceCollection.ruleCollection = ruleCollection;
        interfaceCollection.sortState = { field: 'isClassifiedByActiveRule', direction: 'desc' };
        interfaceCollection.sort();
      }

      interfaceCollection.each((model) => model.set('device_id', device.get('id')));

      device.set({ interfaceCollection });
      device.select();
    }
  }

  handleSuccess = (data) => {
    if (data.complete) {
      this.setAllUnclassifiedInterfaces();
    }
  };

  @action
  evaluateRules = () => {
    this.isRuleEvaluationRequired = false;

    // each device in the collection is now evaluating results
    this.deviceCollection.models.forEach((model) => model.set({ loadingInterfaceResults: true }));

    this.ruleState.send({ persist: true });
  };

  /**
   * Tests a single rule. `values` are the new values we want to test, and have not yet
   * been persisted to `rule: BaseModel`
   */
  @action
  testRule = (rule, values) => {
    // The rule has been tested, we toggle some UI state based on this in the form.
    rule.isTested = true;

    // Take a clone of the rule and persist the temporary tested values but only if it's not new.
    // If it's new, there's no original state to maintain and it loses its optimistic ID so things don't match.
    let ruleClone = rule;
    if (!rule.isNew) {
      ruleClone = rule.duplicate({ removeId: false, save: false });
    }
    ruleClone.set(values);

    this.ruleTestState.rule = ruleClone;

    // construct our data to send to the socket, with the values we want to override.
    this.ruleTestState.send({
      persist: false,
      overrideRules: [
        {
          id: rule.id,
          enabled: true,
          ...values
        }
      ]
    });
  };

  @action
  reorderRule = (oldIndex = 0, newIndex = 0) => {
    const rule = this.ruleCollection.at(oldIndex);
    // prevent re-ordering of KMRs
    if (!rule.kentik_managed_rule && oldIndex !== newIndex) {
      this.ruleCollection.move(oldIndex, newIndex);
      const data = { id: rule.id, position_index: newIndex - 1 };
      api.post('/api/ui/interfaces/rules/reorder', { data }).then(() => this.ruleCollection.fetch({ force: true }));
      this.setRuleEvaluationRequired();
    }
  };

  addRule = (options = {}) => {
    const { isSettingRank = false, rank = this.ruleCollection.models.length } = options;
    const rule = this.ruleCollection.forge({ isSettingRank, rank });
    this.setActiveTestRule(rule);
  };

  @action
  removeRule = (rule) =>
    rule.destroy().then(() => {
      this.ruleCollection.fetch({ force: true });
      if (this.ruleCollection.size === 0) {
        this.evaluateRules();
      }
    });

  @action
  setActiveTestRule = (rule) => {
    this.ruleTestState.rule = rule.duplicate({ removeId: false, save: false });
    this.ruleTestState.evaluationResults = this.ruleState.evaluationResults.slice();

    // Set the test device breakdown with the current results (will be overwritten if/when we test the rule)
    this.testResultsDeviceCollection.updateDeviceInterfaceMatchData(this.ruleState.deviceMatches);
    this.testResultsDeviceCollection.sort();
  };

  @action
  toggleRuleEnabled = (rule) => {
    const { enabled } = rule.get();
    rule.save({ enabled: !enabled }, { toast: false });
    this.setRuleEvaluationRequired();
  };

  @action
  setRuleEvaluationRequired() {
    this.isRuleEvaluationRequired = true;
  }

  /**
   * If we're loading Devices or we're still parsing rule results.
   *
   * TODO: I think the "we're still parsing rule results" logic is wrong here
   */
  @computed
  get isLoadingDevicesInterfaces() {
    return (
      this.deviceCollection.isRequestActive('fetching') || (!this.ruleState.complete && !this.ruleState.evaluating)
    );
  }

  getDefaultNetworkBoundary(connectivity_type) {
    return this.settings.get(connectivity_type);
  }

  @action
  classifyInterfaces = (data) => api.post('/api/ui/interfaces/classify/manual', { data });

  autoClassify = ({ company_id, device_id }) =>
    api.get('/api/ui/interfaces/classify/auto', { query: { company_id, device_id } }).then((results) => {
      const devices = results.devices.map((device) => ({
        ...device,
        id: device.device_id,
        results: new Collection(device.results, { sortState: { field: 'autoClassified', direction: 'desc' } })
      }));
      return {
        ...results,
        devices: new Collection(devices, { sortState: { field: 'autoClassifiedCount', direction: 'desc' } })
      };
    });

  @computed
  get totalInterfaces() {
    if (this.totalInterfaceCount) {
      return this.totalInterfaceCount;
    }
    let total = 0;
    this.manualDeviceCollection.each((model) => {
      total += model.get('interfaceCount') || 0;
    });
    return total;
  }

  @computed
  get totalClassified() {
    if (this.totalInterfaceCount) {
      return this.totalInterfaceCount - this.unclassifiedInterfaceCount;
    }
    let total = 0;
    this.manualDeviceCollection.each((model) => {
      total += model.get('classifiedCount') || 0;
    });
    return total;
  }

  @computed
  get totalPercentClassified() {
    return this.totalInterfaces === 0 ? 0 : Math.round((this.totalClassified / this.totalInterfaces) * 100);
  }

  @computed
  get interfaceClassBelowThreshold() {
    return this.ruleState.complete && this.totalPercentClassified < $auth.interfaceClassThreshold;
  }
}

export default new InterfaceClassStore();
