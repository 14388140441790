import { action, computed } from 'mobx';
import Collection from 'core/model/Collection';
import NotificationModel from './NotificationModel';

export default class NotificationCollection extends Collection {
  constructor(data = [], options = {}) {
    super(data, options);
  }

  get minFetchInterval() {
    return 60000;
  }

  get url() {
    return '/api/ui/notifications';
  }

  get model() {
    return NotificationModel;
  }

  get defaultSortState() {
    return {
      field: 'name',
      direction: 'asc'
    };
  }

  get notificationOptions() {
    return this.map((model) => {
      const tenantID = model.get('config.userGroupID');
      const option = {
        label: model.get('name'),
        value: model.id,
        disabled: model.get('status') !== 'enabled',
        channelType: model.get('channelType'),
        userGroupId: model.get('config.userGroupID')
      };

      if (tenantID && `${tenantID}` !== '0') {
        option.tenantID = tenantID;
      }
      return option;
    }).sort((a, b) => `${a.channelType}-${a.label}`.localeCompare(`${b.channelType}-${b.label}`));
  }

  /*
    provides a lookup map of selectors and corresponding channels
    ex:

    {
      alertmanRule: {
        [RULEID]: {
          [CHANNELID]: true,
          ...
        },
        ...
      },
      ...
    }
  */
  @computed
  get selectorChannels() {
    return this.models.reduce((acc, model) => {
      const conjunctions = model.get('config.selectorConfig.conjunctions', []);

      conjunctions.forEach(({ selectors = [] }) => {
        selectors.forEach(({ lhs, rhs }) => {
          acc[lhs] = acc[lhs] || {};
          acc[lhs][rhs] = acc[lhs][rhs] || {};
          acc[lhs][rhs][model.id] = true;
        });
      });

      return acc;
    }, {});
  }

  @action
  async fetch(options = {}) {
    // Never fetch if already fetching or if too recent - unless parameters are diff
    if (!options.force && this.lastFetched > Date.now() - this.minFetchInterval) {
      return this.fetchPromise;
    }

    return super.fetch(options);
  }

  deserialize(data) {
    data = super.deserialize(data);
    const { channels = [] } = data;
    return Array.isArray(data) ? data : channels;
  }
}
