import Collection from 'core/model/Collection';

import LabelModel from './LabelModel';

class LabelCollection extends Collection {
  get url() {
    return '/api/ui/labels';
  }

  get model() {
    return LabelModel;
  }

  get defaultSortState() {
    return {
      field: 'name',
      direction: 'asc'
    };
  }
}

export default LabelCollection;
