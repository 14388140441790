import api from 'core/util/api';
import AuditLogCollection from './AuditLogCollection';

class AuditLogStore {
  collection = new AuditLogCollection();

  fetchLogDetail(query) {
    return api.get('/api/ui/auditLog/details', {
      query
    });
  }
}

export default new AuditLogStore();
