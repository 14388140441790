import React from 'react';
import { action, computed, toJS } from 'mobx';
import { orderBy, sortBy } from 'lodash';

import Flex from 'core/components/Flex';
import api from 'core/util/api';
import deepClone from 'core/util/deepClone';
import nestFilterGroup from 'core/util/filters/nestFilterGroup';
import ParametricTag from 'app/components/ParametricTag';
import ShareLevelBadge from 'app/components/ShareLevelBadge';
import CategoryCollection from 'app/stores/report/CategoryCollection';
import { DashboardCollection } from 'app/stores/dashboard/DashboardCollection';
import { getModelFilters } from 'app/util/filters';
import DashboardModel from 'app/stores/dashboard/DashboardModel';
import DashboardItemCollection from 'app/stores/dashboard/DashboardItemCollection';
import DashboardItemModel from 'app/stores/dashboard/DashboardItemModel';
import { getHashForQueries } from 'app/stores/query/urlHash';
import { showErrorToast, showSuccessToast } from 'core/components/toast';
import KentikLogo from 'app/components/KentikLogo';

export class DashboardsStore {
  collection = new DashboardCollection();

  categories = new CategoryCollection();

  constructor(options = {}) {
    Object.assign(this, options);
  }

  loadDashboard(params, state) {
    const dashboardId = params.dashboardId || this.collection.slugIdMap[params.dashboardSlug];
    const dashboardItemCollection = params.dashboardModel?.items
      ? new DashboardItemCollection(params.dashboardModel.items, {})
      : null;
    const dashboardModel = params.dashboardModel
      ? new DashboardModel({ ...params.dashboardModel, items: dashboardItemCollection })
      : null;

    if (dashboardModel) {
      dashboardItemCollection.dashboard = dashboardModel;
      dashboardItemCollection.models.forEach((item) => {
        item.set('dashboard', dashboardModel);
      });
      this.store.$dashboard.loadDashboard({ dashboardModel, ...state, params: params.params });
    } else if (dashboardId) {
      const dashboard = this.collection.find({
        id: parseInt(dashboardId)
      });

      if (dashboard) {
        if (!this.store.$app.isSharedLink) {
          this.store.$recentlyViewed.add({
            view_type: dashboard.type,
            view_id: dashboardId,
            view_name: dashboard.get('dash_title')
          });
        }

        this.collection.select(dashboard);
        this.store.$dashboard.loadDashboard({ dashboardId: dashboard.id, ...state, params: params.params });
      } else {
        this.store.$dashboard.reset();
      }
    } else {
      this.store.$dashboard.reset();
    }
  }

  getSourcePanelQuery = (firstQuery) => ({
    all_devices: firstQuery.get('all_devices'),
    device_name: firstQuery.get('device_name'),
    device_sites: firstQuery.get('device_sites'),
    device_labels: firstQuery.get('device_labels'),
    device_types: firstQuery.get('device_types'),
    lookback_seconds: firstQuery.get('lookback_seconds'),
    starting_time: firstQuery.get('starting_time'),
    ending_time: firstQuery.get('ending_time'),
    time_format: firstQuery.get('time_format'),
    filters: firstQuery.get('filters')
  });

  getDashboardNavigationHistory = (currentDashboard, currentDashboardItem, destinationDashboard) => {
    const { location } = this.history;
    const { selectedModels } = currentDashboardItem;
    const destinationDashboardHistory = {
      id: destinationDashboard.id,
      parentDashboardId: currentDashboard.id,
      selectedModels: selectedModels.map((model) => model.get('key'))
    };

    if (location.state) {
      const { dashboardNavigationHistory } = location.state;
      if (
        dashboardNavigationHistory &&
        dashboardNavigationHistory[dashboardNavigationHistory.length - 1].id === currentDashboard.id
      ) {
        return [...dashboardNavigationHistory, destinationDashboardHistory];
      }
    }

    return [{ id: currentDashboard.id }, destinationDashboardHistory];
  };

  getFlattenedCategory = (category, depth = 0) => {
    if (category.childCategories) {
      category.childCategories.forEach((cat) => (cat.depth = depth + 1));
      return [category].concat(...category.childCategories.map((val) => this.getFlattenedCategory(val, depth + 1)));
    }
    return [category];
  };

  flattenNestedCategories = (categories) =>
    categories.reduce((acc, category) => acc.concat(...this.getFlattenedCategory(category)), []);

  @computed
  get categoryOptions() {
    const categories = this.categories
      .get()
      .filter(
        (category) =>
          (!this.store.$auth.isPresetCompany && !category.get('preset')) ||
          (this.store.$auth.isPresetCompany && category.get('preset'))
      )
      .map((cat) => ({
        value: cat.id,
        label: cat.get('name'),
        iconName: cat.get('icon'),
        parent_id: cat.get('parent_id'),
        depth: 0
      }));

    const sorted = sortBy(categories, 'label');

    const nested = sorted.reduce((acc, category) => {
      if (category.parent_id) {
        const parent = categories.find((cat) => cat.value === category.parent_id);
        if (!parent.childCategories) {
          parent.childCategories = [];
        }
        parent.childCategories.push(category);
      } else {
        acc.push(category);
      }
      return acc;
    }, []);

    const options = this.flattenNestedCategories(nested);
    options.push({ value: '', label: 'Uncategorized' });

    if (this.store.$auth.isAdministrator) {
      options.unshift({
        value: 'ADD_NEW',
        label: 'New Category',
        intent: 'primary',
        iconCls: 'plus'
      });
    }
    return options;
  }

  @computed
  get labelOptions() {
    const labelSet = new Set(
      this.store.$labels.getLabels('dashboard').map((label) => {
        const { option } = label;
        if (label.isPreset) {
          option.isPreset = true;
          option.bg = '#e05420';
          option.color = '#ffffff';
        }
        return option;
      })
    );
    labelSet.add({ value: '[NONE]', label: 'None', showColor: false });
    return Array.from(labelSet).sort((a, b) => {
      if (a.value === '[NONE]') {
        return -1;
      }
      return a.label.toLowerCase().localeCompare(b.label.toLowerCase());
    });
  }

  @computed
  get ownerOptions() {
    const owners = new Set(this.collection.unfiltered.map((model) => `${model.get('user_id')}`));
    return this.store.$users.collection.userSelectOptions.filter(({ value }) => owners.has(`${value}`));
  }

  @computed
  get privacyOptions() {
    return [
      {
        description: 'Dashboards shared with other users',
        icon: 'people',
        label: 'Shared',
        value: 'Shared'
      },
      {
        description: 'Personally created dashboards',
        icon: 'eye-open',
        label: 'Private',
        value: 'Private'
      },
      {
        description: "Kentik's library of preset dashboards",
        icon: <KentikLogo onlyMark alt="Kentik" style={{ maxWidth: 16 }} />,
        label: 'Kentik Presets',
        value: 'Preset'
      }
    ];
  }

  @action
  navigateToNestedDashboard = (currentDashboard, currentDashboardItem) => {
    let destinationDashboardId = currentDashboardItem.get('destination_dashboard');

    if (this.store.$auth.isDan && Math.random() < 0.1) {
      const randIndex = Math.floor(Math.random() * this.collection.size) + 1;
      destinationDashboardId = this.collection.models.get(randIndex - 1).get('id');
    }

    const destinationDashboard = this.collection.get(destinationDashboardId);
    if (!destinationDashboard) {
      return;
    }

    const currentQuery = currentDashboard.get('query');
    const itemQuery = currentDashboardItem.queryBuckets[0].firstQuery;

    const parametric = currentDashboardItem.get('dashboard_navigation_options.parametric');
    const deviceRule = currentDashboardItem.get('dashboard_navigation_options.devices');
    const timeRangeRule = currentDashboardItem.get('dashboard_navigation_options.time_range');
    const filtersRule = currentDashboardItem.get('dashboard_navigation_options.filters');

    const parametric_fields = destinationDashboard.get('parametric_fields');

    if (destinationDashboard.get('parametric')) {
      if (parametric === 'applyFrom') {
        const currentParametricFields = currentDashboard.get('parametric_fields');
        const [field] = parametric_fields;

        destinationDashboard.set({
          parametric_fields: [Object.assign({}, field, { value: currentParametricFields[0].value })]
        });
      } else if (parametric === 'drillDown') {
        const { selectedModels } = currentDashboardItem;
        const [selectedModel] = selectedModels;
        const [field] = parametric_fields;
        const value = selectedModel && currentDashboardItem.getParametricKeyFromModel(selectedModel, field);

        if (value) {
          destinationDashboard.set({
            parametric_fields: [Object.assign({}, field, { value })]
          });
        } else {
          this.store.$dashboard.setCompletedInitialParametricSubmit(false);
          parametric_fields[0].value = undefined;
        }
      } else if (parametric === 'prompt' && parametric_fields && parametric_fields.length) {
        parametric_fields[0].value = undefined;
      }
    }

    const querySource = {
      source_dashboard: currentQuery,
      destination_dashboard: destinationDashboard.originalQuery,
      source_panel: this.getSourcePanelQuery(itemQuery),
      source_destination: currentQuery,
      custom: currentDashboardItem.get('dashboard_navigation_options.custom')
    };

    const { all_devices, device_name, device_types, device_labels, device_sites } = querySource[deviceRule];
    const { lookback_seconds, starting_time, ending_time, time_format } = querySource[timeRangeRule];
    const { filters } = querySource[filtersRule];

    let filtersObj = deepClone(filters);

    if (filtersRule === 'source_destination') {
      filtersObj = deepClone(querySource.destination_dashboard.filters);

      if (filtersObj.connector === 'Any') {
        nestFilterGroup(filtersObj);
      }

      const { filterGroups: currQueryFilterGroups } = currentQuery.filters;
      if (currQueryFilterGroups && currQueryFilterGroups.length > 0) {
        filtersObj.filterGroups.push(...currQueryFilterGroups);
      }
    }

    if (!destinationDashboard.get('parametric') && filtersObj) {
      const bucket = currentDashboardItem.queryBuckets[0];
      currentDashboardItem.selectedModels.reduce(
        (filtersAcc, model) =>
          getModelFilters({
            model,
            bucket,
            filtersAcc
          }),
        filtersObj
      );
    }

    const queryOverride = {
      all_devices,
      device_name,
      device_types,
      device_sites,
      device_labels,
      lookback_seconds,
      starting_time,
      ending_time,
      time_format,
      filters: filtersObj
    };

    this.store.$dashboard.clearSelectedModelsFromAllItems();

    this.navigateToDashboard(
      destinationDashboard,
      parametric,
      this.getDashboardNavigationHistory(currentDashboard, currentDashboardItem, destinationDashboard),
      queryOverride
    );
  };

  @action
  navigateToDashboard = (dashboard, isParametric, dashboardNavigationHistory, overrides) => {
    this.history.push(dashboard.navigatePath, {
      completedInitialParametricSubmit: isParametric,
      initialParametricFields: toJS(dashboard.get('parametric_fields')),
      dashboardNavigationHistory: toJS(dashboardNavigationHistory, { recurseEverything: true }),
      ...toJS(overrides, { recurseEverything: true })
    });
  };

  @action
  addViewToFavorites = (model) => {
    let storedFavorites = this.store.$setup.getSettings('favorites', []);
    if (!Array.isArray(storedFavorites)) {
      storedFavorites = Object.keys(storedFavorites).map((fav) => fav.replace('$', '-'));
    }
    const isFavorite = storedFavorites.includes(model.slugFavorite);
    let favorites = [];

    if (isFavorite) {
      favorites = storedFavorites.filter((fave) => fave !== model.slugFavorite);
    } else {
      favorites = [...storedFavorites, model.slugFavorite];
    }
    return this.store.$setup.updateSettings({ favorites }).then((res) => {
      showSuccessToast(`${isFavorite ? 'Removed' : 'Added'} ${model.type} ${isFavorite ? 'from' : 'to'} Favorites`);
      return res.favorites;
    });
  };

  getDashboardMetadata = (id) => {
    const { dash_title, description } =
      this.collection
        .get()
        .find((model) => `${model.get('id')}` === id)
        ?.get() || {};

    return { description, name: dash_title };
  };

  @computed
  get parametricDashboards() {
    const dashboards = this.collection.get().filter((model) => model.get('parametric'));
    return orderBy(dashboards, ['shareLevel', 'desc']);
  }

  @computed
  get nonParametricDashboards() {
    const dashboards = this.collection.get().filter((model) => !model.get('parametric'));
    return orderBy(dashboards, ['shareLevel', 'desc']);
  }

  @computed
  get groupedDashboards() {
    const models = this.collection.get();

    return (
      models && {
        self: models.filter((model) => model.isUserLevel),
        company: models.filter((model) => model.isCompanyLevel),
        preset: models.filter((model) => model.isPreset)
      }
    );
  }

  @computed
  get dashboardOptions() {
    return this.getDashboardOptions(this.collection.get());
  }

  getDashboardOptions(dashboards) {
    return dashboards.map((dashboard) => ({
      value: dashboard.id,
      label: (
        <Flex justifyContent="space-between" alignItems="center" flex={1}>
          {dashboard.get('dash_title')}
          <Flex ml={1}>
            {dashboard.get('parametric') && <ParametricTag />}
            <ShareLevelBadge item={dashboard} minimal />
          </Flex>
        </Flex>
      ),
      filterLabel: dashboard.get('dash_title')
    }));
  }

  cloneDashboardById(dashboardId) {
    return this.cloneDashboard({ dashboardId });
  }

  cloneDashboardByJSON(dashboardJSON) {
    if (dashboardJSON.category) {
      const category = this.categories.models.find((dashCategory) => dashCategory.name === dashboardJSON.category.name);
      if (category) {
        dashboardJSON.category_id = category.id;
      }
    }
    return this.cloneDashboard({ dashboardJSON });
  }

  cloneDashboard(data) {
    const url = '/api/ui/dashboards/clone';

    if (this.collection) {
      this.collection.requestStatus = 'Copying';
    }

    return api.post(url, { data }).then(
      (attrs) => {
        showSuccessToast(`Successfully created copy: ${attrs.dash_title}`);
        if (this.collection) {
          this.collection.requestStatus = null;
        }

        this.collection.add(attrs);
        return this.collection.get(attrs.id);
      },
      () => {
        showErrorToast('Dashboard copy could not be created.');
        if (this.collection) {
          this.collection.requestStatus = null;
        }
      }
    );
  }

  assetMigration(dashboard, appendTitle) {
    const importPromise = dashboard.attributes
      ? dashboard.getSerializedDataForClone().then((serialized) => JSON.stringify(serialized))
      : Promise.resolve(dashboard);

    return importPromise.then((importDashboardJSON) => {
      const { items: dashItems, ...importDashboard } = JSON.parse(importDashboardJSON);
      if (appendTitle) {
        importDashboard.dash_title += ' (Copy)';
      }

      if (importDashboard.category) {
        const category = this.categories.models.find(
          (dashCategory) => dashCategory.name === importDashboard.category.name
        );
        if (category) {
          importDashboard.category = category;
          importDashboard.category_id = category.id;
        }
      }

      const newDashboard = this.collection.forge();
      return newDashboard.save(importDashboard, { toast: false }).then((newDash) => {
        const layout = newDashboard.get('layout');

        return Promise.all(
          dashItems.map((dashItem) => {
            const { savedQuery, ...rest } = dashItem;
            const newDashItem = new DashboardItemModel({ ...rest });
            return getHashForQueries(JSON.parse(savedQuery.value), { persist: true }).then((saved_query_id) =>
              newDashItem.save({ dashboard_id: newDash.id, saved_query_id }, { toast: false }).then((savedDashItem) => {
                const lgLayoutItem = layout.lg.find((panel) => parseInt(panel.i) === parseInt(dashItem.origId));
                if (lgLayoutItem) {
                  lgLayoutItem.i = savedDashItem.id.toString();
                }

                const smLayoutItem = layout.sm.find((panel) => parseInt(panel.i) === parseInt(dashItem.origId));
                if (smLayoutItem) {
                  smLayoutItem.i = savedDashItem.id.toString();
                }
              })
            );
          })
        )
          .then(() => newDashboard.save({}, { toast: false }))
          .then((res) => {
            this.collection.fetch();
            return res;
          });
      });
    });
  }
}

const $dashboards = new DashboardsStore();

export default $dashboards;
