import moment from 'moment';
import get from 'lodash/get';
import capitalize from 'lodash/capitalize';

import { DEFAULT_DATETIME_FORMAT } from 'core/util/dateUtils';
import { getCapacityMetric } from 'app/stores/insight/insightUtils';

// Capacity
const operateCapacityCapacity = {
  filters({ dimensionToValue: { snmp_id } }) {
    return {
      filters: [{ filterField: 'ktappprotocol__snmp__output_port', operator: '=', filterValue: snmp_id }]
    };
  },
  aggregateTypes(insight) {
    const direction = capitalize(getCapacityMetric(insight).direction);
    const unit =
      this.store.$dictionary.get(`aggregates.SNMP.Bits/s ${direction}.0.unit`) || 'ktappprotocol__snmp__INT64_00';
    return this.store.$dictionary.get(`unitsLegacy.${unit}`);
  },
  filterDimensionsEnabled: false
};

// Factorial Insights
const factorsInterfaceUtilizationSpike = {
  filters({ factors, dimensionToValue: { snmp_id } }) {
    const factorDimensionToValue = get(factors, 'explainingFactors[0].dimensionToValue');
    let filters = [];

    if (factorDimensionToValue) {
      filters = Object.keys(factorDimensionToValue).map((key) => ({
        filterField: this.filterColumnOverrides[key] || key,
        operator: '=',
        filterValue: factorDimensionToValue[key]
      }));
    }

    if (snmp_id) {
      filters.push({
        filterField: 'input_port',
        operator: '=',
        filterValue: snmp_id
      });
    }

    return { filters };
  },

  show_overlay: false,
  show_total_overlay: false,
  aggregateTypes: ['avg_bits_per_sec'],
  filterDimensionsEnabled: false,
  filterDimensionSort: false,
  filterDimensions: {},
  minsPolling: 5,
  forceMinsPolling: true,

  metric({ factors, insightName }) {
    const dimensionToValue = get(factors, 'explainingFactors[0].dimensionToValue');
    return dimensionToValue ? Object.keys(dimensionToValue).map((key) => this.getQueryMetric(key, insightName)) : [];
  },

  starting_time: ({ startTime }) => {
    const start = moment.utc(startTime).subtract(15, 'minutes');
    start.subtract(start.minute() % 5, 'minutes');
    return start.format(DEFAULT_DATETIME_FORMAT);
  },

  ending_time: ({ endTime }) => moment.utc(endTime).add(15, 'minutes').format(DEFAULT_DATETIME_FORMAT)
};

const insightQueries = {
  'factors.interface.utilization.drop': factorsInterfaceUtilizationSpike,
  'factors.interface.utilization.spike': factorsInterfaceUtilizationSpike,
  'operate.capacity.capacity': operateCapacityCapacity,
  'operate.capacity.runout': operateCapacityCapacity
};

export default insightQueries;
