import { action, computed, observable } from 'mobx';
import AgentTargetTestResultsState from './AgentTargetTestResultsState';

class AgentTestResultsState {
  get defaults() {
    return {
      test: null,
      agent: null,
      loadingTraceResults: false,
      tabId: 'metrics',
      hasResults: false,
      hasTraceResults: false,
      results: null,
      traceResults: null,
      targets: observable.map({}, { deep: false })
    };
  }

  test = this.defaults.test;

  agent = this.defaults.agent;

  @observable
  tabId = this.defaults.tabId;

  @observable
  hasResults = this.defaults.hasResults;

  @observable
  hasTraceResults = this.defaults.hasTraceResults;

  @observable.ref
  results = this.defaults.results;

  @observable.ref
  traceResults = this.defaults.traceResults;

  @observable.ref
  targets = this.defaults.targets;

  @observable
  loadingTraceResults = this.defaults.loadingTraceResults;

  constructor({ store, test, agent }) {
    this.store = store;
    this.test = test;
    this.agent = agent;

    if (test.isTransaction) {
      this.tabId = 'results';
    }

    this.configure();
  }

  @action
  configure() {
    const { $exports } = this.store;
    $exports.getSettings().then(
      action(({ hashedTabId }) => {
        if (hashedTabId !== undefined) {
          this.tabId = hashedTabId;
        }
      })
    );
  }

  checkForTestResults({ overall = {} } = {}) {
    return !!Object.keys(overall).length;
  }

  checkForTraceResults(results) {
    return !!Object.keys(results?.targets || {}).length;
  }

  @action
  setTestResults(testResults, results, aggregateResults) {
    const hasResults = this.checkForTestResults(results);

    if (hasResults) {
      const targets = Object.keys(results.targets);

      this.results = results;
      for (let i = 0; i < targets.length; i += 1) {
        const target = targets[i];

        this.setupTargetResults(target);
        this.targets
          .get(target)
          .setTestResults(testResults, results.targets[target], aggregateResults?.targets[target]);
      }
    }

    this.hasResults = hasResults;
  }

  @action
  setTraceResults(traceResults, results) {
    const hasTraceResults = this.checkForTraceResults(results);

    if (hasTraceResults) {
      const targets = Object.keys(results.targets);

      this.traceResults = results;
      for (let i = 0; i < targets.length; i++) {
        const target = targets[i];

        this.setupTargetResults(target);
        this.targets.get(target).setTraceResults(traceResults, results.targets[target]);
      }
    }

    this.hasTraceResults = hasTraceResults;
  }

  @action
  setupTargetResults(target) {
    if (!this.targets.get(target)) {
      this.targets.set(
        target,
        new AgentTargetTestResultsState({
          store: this.store,
          test: this.test,
          agent: this.agent,
          target
        })
      );
    }
  }

  @action
  onPageTabChange = (id) => {
    this.tabId = id;
    if (this.test.agentResults.displayOptions.allowSettings) {
      this.store.$exports.setHash({ hashedTabId: id }, true, true); // merge and replace the querystring to just be the q hash
    }
  };

  @computed
  get allowFlowQuery() {
    const { $devices } = this.store;

    if (!$devices.hasReceivedAnyFlow) {
      return false;
    }

    let result = true;
    const { agent } = this;
    // don't do flow query if private agent site has no devices, otherwise allow.
    if (agent && agent.get('agent_type') === 'private') {
      const siteId = agent.get('site_id');
      if (siteId && siteId !== '0') {
        const { deviceSummaries } = $devices;
        result = deviceSummaries.some((ds) => ds.site_id && ds.site_id.toString() === siteId);
      }
    }
    return result;
  }
}

export default AgentTestResultsState;
