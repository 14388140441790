import Collection from 'core/model/Collection';

import NetworkHealthTestModel from './NetworkHealthTestModel';

class NetworkHealthAgentCollection extends Collection {
  testId = 1;

  get url() {
    return `/api/ui/network-availability/test/${this.testId}`;
  }

  get model() {
    return NetworkHealthTestModel;
  }
}

export default NetworkHealthAgentCollection;
