import { action, computed } from 'mobx';
import { cloneDeep, get, pick, sortBy, uniq } from 'lodash';
import moment from 'moment';
import api from 'core/util/api';
import $devices from 'app/stores/device/$devices';
import { closestLimitCountOptionValue, closestWindowSizeOptionValue } from 'app/views/metrics/utils/sidebarOptions.js';
import {
  METRICS_APP_PROTOCOL_MAP,
  METRICS_INTERFACE_OPER_STATUS_MAP,
  METRICS_INTERFACE_TYPES,
  METRICS_STATUS_LABEL_MAP
} from 'app/stores/metrics/metricsConstants';
import { getHashForObject, getObjectForHash } from 'app/stores/query/urlHash';
import { DEFAULT_METRICS_EXPLORER_QUERY } from 'app/views/metrics/queries';
import { modelFinder } from 'app/stores/util/modelFinder';
import { getNmsDimensionLabel } from 'app/util/dimensions';
import MetricsCollection from './MetricsCollection';
import MetricDeviceCollection from './MetricDeviceCollection';
import MetricInterfaceCollection from './MetricInterfaceCollection';
import MetricDeviceModel from './MetricDeviceModel';
import MetricInterfaceModel from './MetricInterfaceModel';
import MonitoringTemplatesCollection from './MonitoringTemplatesCollection';
import MetricConnectionCollection from './MetricConnectionCollection';

const includedColumnsComponents = [
  'device_name',
  'index',
  'name',
  'description',
  'parent',
  'parent-position',
  'removable',
  'type',
  'hardware-version',
  'mfg-name',
  'model',
  'original-state',
  'original-type',
  'serial-no',
  'oper-status',
  'firmware-version',
  'software-version'
];

const includedColumnsBgpNeighbors = [
  'id',
  'device_id',
  'device_name',
  'index',
  'enabled',
  'local-as',
  'transport/remote-address',
  'transport/remote-port',
  'peer-as',
  'prefix-table-index',
  'network-instance',
  'session-state',
  'timers/hold-time',
  'timers/keepalive-interval',
  'timers/connect-retry',
  'transport/local-address',
  'transport/local-port',
  'last_status_update'
];

export class MetricsStore {
  deviceCollection = new MetricDeviceCollection();

  interfaceCollection = new MetricInterfaceCollection();

  availableMetricsCollection = new MetricsCollection();

  monitoringTemplatesCollection = new MonitoringTemplatesCollection();

  @action
  initialize() {
    const { $auth, $app } = this.store;
    if ($auth.hasPermission('recon.enabled', { overrideForSudo: false })) {
      return Promise.all([!$app.isSubtenant && this.deviceCollection.fetch(), this.availableMetricsCollection.fetch()]);
    }

    return Promise.resolve();
  }

  @computed
  get entityTypeValues() {
    const { entityTypes } = this.availableMetricsCollection;
    return Object.keys(entityTypes);
  }

  @computed
  get entityTypeOptions() {
    const { entityTypes } = this.availableMetricsCollection;
    return Object.values(entityTypes).sort((a, b) => a.label.localeCompare(b.label));
  }

  getEntityTypeMeasurementOptions = (entityType) => {
    const { entityTypes } = this.availableMetricsCollection;
    const entityTypeMatch = entityTypes[entityType];

    if (!entityTypeMatch) {
      return [];
    }

    return Object.values(entityTypeMatch.measurements)
      .map(({ name }) => ({ label: name, value: name }))
      .sort((a, b) => a.label.localeCompare(b.label));
  };

  getEntityTypeDimensionOptions = (entityType, measurementKeys = [], options = {}) => {
    const { showHeadings } = options;
    const { entityTypes } = this.availableMetricsCollection;
    const entityTypeMatch = entityTypes[entityType];

    if (!entityTypeMatch || !Array.isArray(measurementKeys)) {
      return [];
    }

    const dimensionOptions = [];

    measurementKeys.forEach((measurementKey) => {
      const dimensionsForMeasurement = entityTypeMatch.measurements[measurementKey]?.dimensions || [];

      if (showHeadings) {
        const headingValue = dimensionsForMeasurement
          .map((o) => `${o.label}${o.value}`)
          .join('')
          .replaceAll(measurementKey, '');

        dimensionOptions.push({
          label: measurementKey,
          // The huge value is because we want this heading to appear whenever there are matches under it,
          // otherwise the heading will be filtered out and we lose the measurement context.
          value: `${measurementKey}-${headingValue}`,
          groupHeaderProps: {
            bg: 'transparent',
            mx: '4px',
            my: '4px',
            py: '4px',
            px: 0,
            borderBottom: 'thin',
            fontWeight: 'bold',
            borderRadius: 0
          }
        });
      }

      dimensionOptions.push(...dimensionsForMeasurement);
    });

    if (measurementKeys.length > 1) {
      return dimensionOptions.map((dimension) => ({
        ...dimension,
        label: `${dimension.label}`
      }));
    }

    return dimensionOptions;
  };

  queryToHash(query, options = {}) {
    return getHashForObject(query, null, options);
  }

  /**
   * This is only private to make test mocks easier.
   * @returns {function(ip: string): import('./MetricDeviceModel').default | undefined}
   * @private
   */
  @computed
  get _byIp() {
    return modelFinder(this.deviceCollection, 'ip_address');
  }

  byIp(ip_address) {
    return this._byIp(ip_address);
  }

  /**
   * This is only private to make test mocks easier.
   * @returns {function(macAdress: string): import('./MetricDeviceModel').default | undefined}
   * @private
   */
  @computed
  get _byMacAddress() {
    return modelFinder(this.deviceCollection, 'mac_address');
  }

  byMacAddress(mac_address) {
    return this._byMacAddress(mac_address);
  }

  /**
   * @param [device_name] {string}
   * @param [device_ip] {string}
   * @param [mac_address] {string}
   * @returns {string | undefined}
   */
  getMetricsDeviceNameFromQueryModel({ device_name, device_ip, mac_address }) {
    if (!device_name && device_ip) {
      device_name = this.byIp(device_ip)?.get('name');
    }
    if (!device_name && mac_address) {
      device_name = this.byMacAddress(mac_address)?.get('name');
    }
    return device_name;
  }

  queryTitle(query) {
    if (!query) {
      return '';
    }
    const { selectedMeasurement, selectedMetrics, selectedDimensions } = this.queryToFormValues(query);
    const measurement = this.measurementModelById(selectedMeasurement);
    const metrics = selectedMetrics.map((m) => measurement?.anyLabel(m)).join(', ');
    const dimensions = selectedDimensions.map((m) => measurement?.anyLabel(m)).join(', ');
    return `Top ${dimensions} by ${metrics} `;
  }

  hashToQuery(hash) {
    return Promise.resolve()
      .then(() => {
        if (hash) {
          return getObjectForHash(hash).then((values) => ({ values, urlHash: hash }));
        }

        const query = DEFAULT_METRICS_EXPLORER_QUERY;
        return this.queryToHash(query).then((urlHash) => ({ values: query, urlHash }));
      })
      .then(({ values, urlHash }) => {
        let { query, title } = values;

        if (values.status && values.statusCode) {
          console.warn('ME queryHash not found, using default', hash);
          query = DEFAULT_METRICS_EXPLORER_QUERY;
          values = { ...this.queryToFormValues(query), query };
        }
        // In case you're coming from a real query
        else if (!values.selectedMeasurement && values.kmetrics) {
          query = values;
          values = { ...this.queryToFormValues(query), query };
        }

        // if spoofing from another company, get new ID of measurement (if it exists)
        if (!this.measurementModelById(values.selectedMeasurement)) {
          const measurement = this.measurementModelByName(values.query.kmetrics.measurement);

          if (measurement) {
            values.selectedMeasurement = measurement.id;
          }
        }

        if (!title) {
          title = this.getAutoQueryTitle(values);
        }

        return { values: { ...values, query, title }, urlHash };
      });
  }

  getAutoQueryTitle({ selectedMeasurement, selectedMetrics, selectedDimensions }) {
    const measurement = this.measurementModelById(selectedMeasurement);
    const metrics = selectedMetrics.map((m) => measurement?.anyLabel(m)).join(', ');
    const dimensions = selectedDimensions.map((m) => measurement?.anyLabel(m)).join(', ');

    return `Top ${dimensions} by ${metrics} `;
  }

  get vizTypes() {
    return {
      line: {
        label: 'Line',
        category: 'timeSeries',
        component: '',
        icon: 'timeline-line-chart'
      },
      area: {
        label: 'Area',
        category: 'timeSeries',
        component: '',
        icon: 'timeline-area-chart'
      },
      column: {
        label: 'Column',
        category: 'agg',
        component: '',
        icon: 'grouped-bar-chart'
      },
      pie: {
        label: 'Pie',
        category: 'agg',
        component: '',
        icon: 'pie-chart'
      },
      table: {
        label: 'Table Only',
        category: 'agg',
        component: '',
        icon: 'th-list'
      }
      /* ,
      pieCount: {
        label: 'Count by value',
        category: 'countBy',
        component: '',
        icon: 'calculator'
      } */
    };
  }

  getStorageMetrics = ({ measurement, measurementModel }) => {
    const model = measurementModel || this.measurementModel(measurement);

    if (!model) {
      return null;
    }

    return model.get('storage.Metrics');
  };

  getStorageMetricLabel = ({ metric, measurement, measurementModel }) => {
    const metricsStorage = this.getStorageMetrics({ measurement, measurementModel });
    return metricsStorage?.[metric]?.Label || metric;
  };

  getMetricValueLabel = (value) => METRICS_STATUS_LABEL_MAP[value] || value;

  vizTypeOptions = (category) => {
    let vizOptions = Object.keys(this.vizTypes).map((vizType) => ({
      value: vizType,
      label: this.vizTypes[vizType].label,
      icon: this.vizTypes[vizType].icon
    }));
    if (category) {
      vizOptions = vizOptions.filter((opt) => this.vizTypes[opt.value].category === category);
    }
    return vizOptions;
  };

  @action
  fetchDevice = (deviceId) => {
    const device = new MetricDeviceModel({ id: deviceId });

    return device
      .fetch()
      .then(() => this.store.$query.runQuery(device.deviceStatusQuery))
      .then(({ results }) => {
        const rawResults = results.toJS();
        const available = rawResults[0]?.last_available;

        if (available !== undefined) {
          device.set('available', available);
        } else {
          console.warn('available missing:', available);
        }
      })
      .then(() => device);
  };

  async fetchDeviceFromCollection(deviceId) {
    let device = this.deviceCollection.get(deviceId);

    if (!device) {
      device = new MetricDeviceModel({ id: deviceId });
      this.deviceCollection.add(device);
    }

    await device.fetch();
    return device;
  }

  @action
  processTopologyDataForDevice = (device, topologyData) => {
    if (!topologyData) {
      console.warn('No topology data to process for device', device.id);
      device.connections = new MetricConnectionCollection([], { parent: device });
      device.set('connectionCount', 0);
      return device;
    }

    // combine all the connections into a single array
    const { deviceLinks, siteLinks, externalLinks } = topologyData;
    const flatConnections = [...deviceLinks, ...siteLinks, ...externalLinks]
      .map((t) => t.connections)
      .flat()
      // only l2 connections for now
      .filter((t) => t.layer === 2);

    function getInterfaceMetadata(connection, key) {
      if (!connection[key]) {
        return {};
      }

      return {
        interface_description: connection[key].interface_description,
        interface_ip: connection[key].interface_ip,
        admin_status: METRICS_INTERFACE_OPER_STATUS_MAP[parseInt(connection[key].admin_status)],
        oper_status: METRICS_INTERFACE_OPER_STATUS_MAP[parseInt(connection[key].if_oper_status)],
        snmp_alias: connection[key].snmp_alias,
        connectivity_type: connection[key].connectivity_type,
        network_boundary: connection[key].network_boundary,
        snmp_speed: connection[key].snmp_speed,
        lldp_rem_sys_name: connection[key].lldp_rem_sys_name,
        lldp_rem_port_id: connection[key].lldp_rem_port_id
      };
    }

    // we first need to determine which key is the "local" interface
    // sometimes the interface1 is the local interface, sometimes it's interface2
    let localKey = '';
    let remoteKey = '';

    // we only need the device name and interface ID for the connections
    const connections = flatConnections.map((connection) => {
      // do not care about layer and linkMetadata for now
      delete connection.layer;
      delete connection.linkMetadata;

      if (connection.interface1?.device_id === parseInt(device.id)) {
        localKey = 'interface1';
        remoteKey = 'interface2';
      } else {
        localKey = 'interface2';
        remoteKey = 'interface1';
      }

      // check if we have any overrides for the device or interface
      const device_local_override = !!connection[localKey]?.layer2_override_far_end_device;
      const interface_local_override = !!connection[localKey]?.layer2_override_far_end_snmp_id;
      const remote_display_name_override = !!connection[localKey]?.layer2_override_far_end_display_name;

      const device_remote_override = !!connection[remoteKey]?.layer2_override_far_end_device;
      const interface_remote_override = !!connection[remoteKey]?.layer2_override_far_end_snmp_id;

      const remote_display_name = connection[localKey]?.layer2_override_far_end_display_name;

      const device_local = device.id;
      const interface_local = connection[localKey]?.snmp_id;

      const device_remote = connection[remoteKey]?.device_id;
      const interface_remote = connection[remoteKey]?.snmp_id;

      return {
        id: `${device_local}-${interface_local}-${device_remote}-${interface_remote}`,

        device_local,
        device_local_override,

        interface_local,
        interface_local_override,

        device_remote,
        device_remote_override,

        interface_remote,
        interface_remote_override,

        remote_display_name,
        remote_display_name_override,

        device_local_metadata: {
          device_name: device.get('device_name') || device.get('name')
        },

        device_remote_metadata: {
          device_name: connection[remoteKey]?.device_name
        },

        // some additional metadata for the interfaces
        interface_local_metadata: getInterfaceMetadata(connection, localKey),
        interface_remote_metadata: getInterfaceMetadata(connection, remoteKey)
      };
    });

    device.connections = new MetricConnectionCollection(connections, { parent: device });
    device.set('connectionCount', connections.length);
    return device;
  };

  @action
  saveInterfaceConnection = ({
    local_device_id,
    local_interface_snmp_id,
    remote_device_id,
    remote_interface_snmp_id,
    remote_display_name,
    remove_connection = false
  }) =>
    api.post('/api/ui/topology/connection', {
      data: {
        local_device_id,
        local_interface_snmp_id,
        remote_device_id,
        remote_interface_snmp_id,
        remote_display_name,
        remove_connection
      }
    });

  @action
  fetchDeviceFilterOptions = (options = {}) =>
    api.get('/api/ui/recon/options/devices', { query: options }).then((results) => {
      const alphaSort = (a, b) => a.label.localeCompare(b.label);

      results.site_id = (results.site_id || []).sort(alphaSort);
      results.location = (results.location || []).sort(alphaSort);
      results.vendor = (results.vendor || []).sort(alphaSort);
      results.model = (results.model || []).sort(alphaSort);
      results.status = (results.status || []).map(({ value }) => ({
        value,
        label: METRICS_STATUS_LABEL_MAP[value] || value
      }));

      return results;
    });

  @action
  fetchInterfaceFilterOptions = (options = {}) =>
    api.get('/api/ui/recon/options/interfaces', { query: options }).then((results) => {
      results.oper_status = (results.oper_status || []).map(({ value }) => ({
        value,
        label: METRICS_STATUS_LABEL_MAP[value] || value
      }));

      results.admin_status = (results.admin_status || []).map(({ value }) => ({
        value,
        label: METRICS_STATUS_LABEL_MAP[value] || value
      }));

      results.type = (results.type || [])
        .map(({ value }) => ({
          value,
          label: METRICS_INTERFACE_TYPES[value] || value
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

      results.mtu = (results.mtu || []).sort((a, b) => Number(a.value) - Number(b.value));

      return results;
    });

  @action
  fetchDevicesAvailability = (options = {}) =>
    api.get('/api/ui/recon/devicesAvailability', { query: options }).then((response) => response);

  @action
  fetchDeviceBgpData(device, lookback = 'P1D') {
    device.bgpData = null;
    return api.get(`/api/ui/recon/devices/${device.id}/bgpData?lookback=${lookback}`).then((bgpData) => {
      device.bgpData = bgpData;
    });
  }

  @action
  fetchAsnsWithAsGroups = (asns) =>
    api.get('/api/ui/asns/bulk', {
      query: {
        asns
      }
    });

  @action
  fetchInterface = (interfaceIdOrModel) => {
    const intf =
      interfaceIdOrModel instanceof MetricInterfaceModel
        ? interfaceIdOrModel
        : new MetricInterfaceModel({ id: interfaceIdOrModel });

    return intf.fetch().then(() => intf);
  };

  /**
   *
   * This gets called from the Add ICMP Devices page, but re-use the same API
   * endpoint as the Discovery page.
   */
  @action
  createIcmpDevices = (configs, collection) =>
    api
      .post('/api/ui/discovery/devices', {
        body: { devices: configs }
      })
      .then((results) => {
        const hasErrors = results.some((result) => result.success === false);
        const failedDevices = [];

        if (hasErrors) {
          results.forEach((result) => {
            const device = collection.models.find((d) => d.get('address') === result.address);

            if (device) {
              device.set(result);

              if (result.success === false) {
                failedDevices.push(device);
              }
            }
          });

          collection.selected = failedDevices;

          return Promise.reject();
        }

        return this.deviceCollection
          .fetch({ force: true })
          .then(() => $devices.loadDeviceSummaries())
          .then(() => results);
      });

  fetchDeviceUptime = (deviceModel) =>
    this.store.$query.runQuery(deviceModel.uptimeQuery).then(({ results }) => {
      const [rawResults] = results.toJS();

      if (rawResults) {
        deviceModel.set('bootTime', rawResults['boot-time']);
        deviceModel.set('uptime', rawResults.uptime);
      }
    });

  getFullSelectedMeasurement(selectedMeasurement) {
    return this.availableMeasurementOptions.find(
      (measurement) => measurement.id === selectedMeasurement || measurement.measurement === selectedMeasurement
    );
  }

  /**
   * Returns a list of measurement info formatted for form options for the list of provided measurement names.
   * The returned options have the measurement name (not ID) as the option value.
   */
  getMeasurementOptionsFromMeasurementNames(measurementNames) {
    return this.availableLabelMeasurementOptions.filter((option) => measurementNames?.includes(option.value));
  }

  getAvailableMetricOptions(measurement) {
    if (!measurement) {
      return [];
    }

    const measurementModel = this.measurementModel(measurement);

    if (!measurementModel) {
      return [];
    }

    const metrics = measurementModel.get('storage.Metrics', {});

    return Object.keys(metrics)
      .map((key) => {
        const metric = metrics[key];
        const rawMetricType = metric.Type;
        const metricKind = metric.Kind || 'gauge';

        return {
          value: key,
          label: metric.Label || key,
          type: rawMetricType,
          kind: metricKind,
          unit: metric.Unit,
          values: metric.Values
        };
      })

      .sort((a, b) => a.label.localeCompare(b.label));
  }

  getAvailableDimensionOptions(measurement, { prefixDimensionsWithMeasurement = false } = {}) {
    if (!measurement) {
      return [];
    }

    const measurementModel = this.measurementModel(measurement);

    if (!measurementModel) {
      return [];
    }

    const dimensions = measurementModel.get('storage.Dimensions', {});

    return Object.keys(dimensions)
      .map((key) => {
        const dimension = dimensions[key];
        return {
          label: getNmsDimensionLabel(key, dimension),
          value: `${prefixDimensionsWithMeasurement ? `${measurementModel.get('measurement')},` : ''}${key}`,
          type: dimension?.Type
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  getAvailableDimensionFilterOptions(measurement) {
    if (Array.isArray(measurement)) {
      const dimensions = measurement.map((m) => this.getAvailableDimensionOptions(m));

      return dimensions.flat().sort((a, b) => a.label.localeCompare(b.label));
    }

    // Include dimensions that we want selectable in filters, but not as metric dimensions
    return this.getAvailableDimensionOptions(measurement).sort((a, b) => a.label.localeCompare(b.label));
  }

  metricFilterOptionsFromQuery(query) {
    const measurement = this.getFullSelectedMeasurement(query.kmetrics.measurement).id;
    const rollups = Object.values(query.kmetrics.rollups).map((r) => r.aggregate);
    const metrics = Object.values(query.kmetrics.rollups).map((r) => r.metric);
    return this.metricFilterOptions(measurement, rollups, metrics);
  }

  metricFilterOptions(measurement, rollups, metrics) {
    const allMetrics = this.getAvailableMetricOptions(measurement);
    const options = [];
    metrics.forEach((metric) => {
      rollups.forEach((agg) => {
        const fullMetric = allMetrics.find((m) => m.value === metric);
        const { label, values, type } = fullMetric;
        options.push({
          value: `${agg}_${metric}`,
          label: `${label} (${agg})`,
          type,
          values
        });
      });
    });
    return options;
  }

  // Omitting column gives you a prefix
  getAppProtocolDimension(measurementName, column = '', measurementModel) {
    const model = measurementModel || this.measurementModel(measurementName);
    let appProtocolName;

    if (model) {
      const appProtocolNum = model.get('storage.AppProtocol');
      appProtocolName = METRICS_APP_PROTOCOL_MAP[appProtocolNum];

      // If it's an uncommon case, check dictionary
      if (appProtocolNum && !appProtocolName) {
        const appProtocolDictionary = this.store.$dictionary.get('app_protocols', {});
        appProtocolName = Object.keys(appProtocolDictionary).find(
          (key) => appProtocolDictionary[key]?.id === appProtocolNum
        );
      }
    }
    return `ktappprotocol__${appProtocolName || 'kmetrics'}__${column || ''}`;
  }

  measurementModel = (measurement) =>
    // Accepts dimension name or ID.
    this.measurementModelById(measurement) || this.measurementModelByName(measurement);

  measurementModelById = (measurement) => this.availableMetricsCollection.modelById[measurement];

  measurementModelByName = (measurement) =>
    this.availableMetricsCollection.models.find((model) => model.get('measurement') === measurement);

  deviceModelByName = (deviceName) =>
    this.deviceCollection.models.find((model) => {
      const modelDeviceName = `${model.get('name')}`.toLowerCase();
      const matchDeviceName = `${deviceName}`.toLowerCase();
      return modelDeviceName === matchDeviceName;
    });

  formValuesToQuery = (formValues, options, overrides) => {
    const {
      selectedMeasurement,
      selectedMetrics,
      selectedDimensions,
      selectedMergeDimensions,
      selectedMergeAggregation,
      selectedSortOrder,
      selectedLimitCount,
      selectedAggFunc,
      selectedWindowSize,
      selectedTransformation,
      selectedRollupsLimit,
      selectedRollupsAggFunc,
      selectedVisualization,
      selectedVisualizationMetric,
      selectedVisualizationRollup,
      filters,
      rollupFilters,
      streamingUpdate,
      lookback_seconds,
      ending_time,
      starting_time,
      update_frequency
    } = formValues;

    const { allowMultiAggregate } = options || {};

    const measurement = this.measurementModelById(selectedMeasurement)?.label;

    let rangeObject = {};
    if (starting_time && ending_time) {
      rangeObject = {
        start: starting_time,
        end: ending_time,
        lookback_seconds: 0
      };
    } else if (lookback_seconds) {
      rangeObject = {
        lookback: `PT${lookback_seconds}S` // start, end
      };
    }

    const rollups = {};
    const fn = {};

    selectedMetrics.forEach((metric) => {
      fn[metric] = selectedAggFunc;
      const selectedRollupsAggFuncs = Array.isArray(selectedRollupsAggFunc)
        ? selectedRollupsAggFunc
        : [selectedRollupsAggFunc];

      (allowMultiAggregate ? selectedRollupsAggFuncs : selectedRollupsAggFuncs.slice(0, 1)).forEach((aggFunc) => {
        rollups[`${aggFunc}_${metric}`] = {
          metric,
          aggregate: aggFunc
        };
      });
    });

    // if a Table is request, we don't need timeseries data so set the limit to 0
    const includeTimeseries = selectedVisualization === 'table' ? 0 : selectedLimitCount;

    // move selectedVisualizationMetric to the top so it's sorted first
    const rollupKeys = sortBy(Object.keys(rollups), (rollup) =>
      rollups[rollup].metric === selectedVisualizationMetric ? 0 : 1
    );
    const sort = rollupKeys.map((rollup) => ({
      name: rollup,
      direction: selectedSortOrder
    }));

    const query = {
      update_frequency,
      use_kmetrics: true,
      show_overlay: false,
      show_total_overlay: false,
      kmetrics: {
        measurement,
        dimensions: selectedDimensions,

        metrics: selectedMetrics.map((metric) => ({
          name: metric,
          type: selectedTransformation === 'none' ? 'gauge' : 'counter'
        })),

        range: rangeObject,

        // sum, min, max, avg
        merge: { dimensions: selectedMergeDimensions, aggregate: selectedMergeAggregation },

        window: {
          size: selectedWindowSize,
          fn
        },
        rollups,
        sort,
        limit: selectedRollupsLimit,
        includeTimeseries,
        streamingUpdate,
        viz: {
          type: selectedVisualization,
          rollup: selectedVisualizationRollup,
          metric: selectedVisualizationMetric
        }
      }
    };

    // To get raw, non-bucketized metric results, just omit the window section from the query object.
    if (selectedAggFunc === 'none') {
      delete query.kmetrics.window;
    }

    if (!selectedMergeDimensions?.length) {
      delete query.kmetrics.merge;
    }

    // can't send in an empty filters object, so check if we have any set before add to the query
    if (filters && filters.filterGroups.length > 0) {
      query.kmetrics.filters = filters;
    } else {
      delete query.kmetrics.filters;
    }

    if (rollupFilters && rollupFilters.filterGroups.length > 0) {
      query.kmetrics.rollupFilters = rollupFilters;
    } else {
      delete query.kmetrics.rollupFilters;
    }

    if (overrides) {
      query.kmetrics = {
        ...query.kmetrics,
        ...overrides
      };
    }

    return query;
  };

  queryToFormValues = (query) => {
    const { kmetrics, time_format = this.store.$auth.userTimezone || 'UTC', update_frequency } = query;

    const {
      measurement,
      dimensions,
      filters,
      metrics,
      range,
      window: tsWindow,
      rollups,
      rollupFilters,
      sort,
      limit,
      includeTimeseries,
      streamingUpdate,
      merge,
      viz
    } = kmetrics;
    const {
      type: selectedVisualization,
      rollup: selectedVisualizationRollup,
      metric: selectedVisualizationMetric
    } = viz || {};
    const { lookback, start: starting_time, end: ending_time } = range;
    const formFields = {
      selectedMeasurement: this.measurementModelByName(measurement).id,
      selectedDimensions: dimensions,
      selectedMergeDimensions: merge?.dimensions,
      selectedMergeAggregation: merge?.aggregate,
      selectedMetrics: metrics.map((metric) => metric.name),
      selectedVisualization,
      selectedVisualizationRollup,
      selectedVisualizationMetric,
      selectedTransformation: metrics[0]?.type === 'gauge' ? 'none' : 'counter',
      selectedSortOrder: get(sort, '[0].direction', 'desc'),
      selectedRollupsLimit: closestLimitCountOptionValue(limit),
      selectedRollupsAggFunc: uniq(Object.values(rollups).map((rollup) => rollup.aggregate)),
      selectedLimitCount: closestLimitCountOptionValue(includeTimeseries),
      lookback_seconds: starting_time && ending_time ? 0 : moment.duration(lookback).asSeconds(),
      from_to_lookback: 0,
      time_format,
      starting_time,
      ending_time,
      period_over_period: '',
      period_over_period_lookback: '',
      period_over_period_lookback_unit: '',
      use_alt_timestamp_field: '',
      filters,
      rollupFilters,
      streamingUpdate,
      update_frequency
    };
    if (tsWindow?.size) {
      formFields.selectedWindowSize = closestWindowSizeOptionValue(tsWindow.size);
    }
    if (tsWindow?.fn) {
      [formFields.selectedAggFunc] = Object.values(tsWindow.fn);
    } else {
      formFields.selectedAggFunc = 'none';
    }
    return formFields;
  };

  dimensionFilterTransformer = (filters, measurement, isKdeToRecon) => {
    // Transforms recon names to KDE columns and vice versa.
    const measurementModel = this.measurementModel(measurement);
    const dimensionPrefix = this.getAppProtocolDimension(measurement);
    const dimensions = measurementModel?.get('storage.Dimensions');
    const metrics = measurementModel?.get('storage.Metrics');

    const merged = {
      ...dimensions,
      ...metrics,
      km_measurement_name: { name: 'km_measurement_name', Column: 'km_measurement_name', Label: 'Measurement' },
      km_device_id: { name: 'km_device_id', Column: 'km_device_id', Label: 'Device ID' }
    };

    // Clone filterObject because traverse mutates object.
    const filtersCopy = cloneDeep(filters);

    function traverse(object) {
      for (const key in object) {
        if (Object.prototype.hasOwnProperty.call(object, key) && object[key] && typeof object[key] === 'object') {
          traverse(object[key]);
        } else if (key === 'filterField') {
          if (isKdeToRecon && String(object[key]).startsWith(dimensionPrefix)) {
            const kdeColumn = object[key].replace(dimensionPrefix, '');
            const name = measurementModel?.mergedMetricsDimensions[kdeColumn]?.name;

            if (name) {
              object[key] = name;
            }
          } else if (!isKdeToRecon && merged[object[key]]) {
            object[key] = `${dimensionPrefix}${merged[object[key]].Column}`;
          }
        }
      }
    }

    traverse(filtersCopy);
    return filtersCopy;
  };

  dimensionFilterKdeToReconTransformer = (filters, measurement) =>
    this.dimensionFilterTransformer(filters, measurement, true);

  dimensionFilterReconToKdeTransformer = (filters, measurement) =>
    this.dimensionFilterTransformer(filters, measurement, false);

  // Get full details for a metric identified by measurement and metric IDs
  metricDetails(measurement, metric) {
    const measurementDetails = this.getFullSelectedMeasurement(measurement);
    if (!measurementDetails) {
      return null;
    }
    const metricDetails = measurementDetails.storage?.Metrics?.[metric];
    return metricDetails;
  }

  // Does the selected metric have an enumerated set of possible Values?
  metricIsEnumType = (measurement, metric) => {
    const metricDetails = this.metricDetails(measurement, metric);
    return Boolean(metricDetails?.Values);
  };

  getDimensionLabelForPolicy = (policyModel, dimension) => {
    const selectedMeasurement = policyModel.get('metricConfig')?.selectedMeasurement;

    return this.getDimensionForMeasurement(selectedMeasurement, dimension)?.Label;
  };

  getDimensionForMeasurement = (measurement, dimension) => {
    const strippedDimension = dimension.replace(this.getAppProtocolDimension(measurement), '');
    const measurementModel = this.measurementModel(measurement);
    return measurementModel?.mergedMetricsDimensions?.[strippedDimension];
  };

  getFullMetricsQuery = ({ metrics, queryOptions, measurement, allowMultiAggregate = true, deckQueryOverrides }) => {
    const {
      selectedDimensions,
      selectedMergeDimensions,
      selectedMergeAggregation,
      selectedSortOrder,
      selectedLimitCount,
      selectedAggFunc,
      selectedWindowSize,
      selectedTransformation,
      selectedRollupsLimit,
      selectedRollupsAggFunc,
      selectedVisualization,
      selectedVisualizationMetric,
      selectedVisualizationRollup,
      filters,
      rollupFilters,
      streamingUpdate,

      // time fields
      lookback_seconds,
      ending_time,
      starting_time
    } = queryOptions;

    let rangeObject = {};
    if (starting_time && ending_time) {
      rangeObject = {
        start: starting_time,
        end: ending_time,
        lookback_seconds: 0
      };
    } else if (lookback_seconds) {
      rangeObject = {
        lookback: `PT${lookback_seconds}S` // start, end
      };
    }

    const rollups = {};
    const fn = {};

    metrics.forEach((metric) => {
      fn[metric] = selectedAggFunc;
      const selectedRollupsAggFuncs = Array.isArray(selectedRollupsAggFunc)
        ? selectedRollupsAggFunc
        : [selectedRollupsAggFunc];

      (allowMultiAggregate ? selectedRollupsAggFuncs : selectedRollupsAggFuncs.slice(0, 1)).forEach((aggFunc) => {
        rollups[`${aggFunc}_${metric}`] = {
          metric,
          aggregate: aggFunc
        };
      });
    });

    // if a Table is request, we don't need timeseries data so set the limit to 0
    const includeTimeseries = selectedVisualization === 'table' ? 0 : selectedLimitCount;

    const sort = Object.keys(rollups).map((rollup) => ({
      name: rollup,
      direction: selectedSortOrder
    }));

    const query = {
      use_kmetrics: true,
      show_overlay: false,
      show_total_overlay: false,
      kmetrics: {
        measurement,
        dimensions: selectedDimensions,

        metrics: metrics.map((metric) => ({
          name: metric,
          type: selectedTransformation === 'none' ? 'gauge' : 'counter'
        })),

        range: rangeObject,

        // sum, min, max, avg
        merge: { dimensions: selectedMergeDimensions, aggregate: selectedMergeAggregation },

        window: {
          size: selectedWindowSize,
          fn
        },
        rollups,
        sort,
        limit: selectedRollupsLimit,
        includeTimeseries,
        streamingUpdate,
        viz: {
          type: selectedVisualization,
          rollup: selectedVisualizationRollup,
          metric: selectedVisualizationMetric
        }
      }
    };

    // To get raw, non-bucketized metric results, just omit the window section from the query object.
    if (selectedAggFunc === 'none') {
      delete query.kmetrics.window;
    }

    if (!selectedMergeDimensions?.length) {
      delete query.kmetrics.merge;
    }

    // can't send in an empty filters object, so check if we have any set before add to the query
    if (filters && filters.filterGroups.length > 0) {
      query.kmetrics.filters = filters;
    } else {
      delete query.kmetrics.filters;
    }

    if (rollupFilters && rollupFilters.filterGroups.length > 0) {
      query.kmetrics.rollupFilters = rollupFilters;
    } else {
      delete query.kmetrics.rollupFilters;
    }

    if (deckQueryOverrides) {
      query.kmetrics = {
        ...query.kmetrics,
        ...deckQueryOverrides
      };
    }

    return query;
  };

  @computed
  get availableMeasurementOptions() {
    return this.availableMetricsCollection.models.map((metric) => ({
      value: metric.id,
      label: `${metric.get('measurement')}`,
      ...metric.get()
    }));
  }

  @computed
  get availableLabelMeasurementOptions() {
    return this.availableMetricsCollection.models.map((metric) => ({
      value: `${metric.get('measurement')}`,
      label: `${metric.get('measurement')}`
    }));
  }

  @computed
  get availableDeviceOptions() {
    return this.deviceCollection.models.map((device) => ({
      ...device.get(),
      value: device.id,
      label: device.get('name')
    }));
  }

  @computed
  get hasCloudMeasurements() {
    return this.availableMeasurementOptions.some((o) => o.label.startsWith('/cloud'));
  }

  @computed
  get hasAgentMeasurements() {
    return this.availableMetricsCollection.some((o) => o.label.startsWith('/kentik/agent'));
  }

  @computed
  get canAccessMetricsExplorer() {
    return this.availableMetricsCollection.size > 0;
  }

  @computed
  get hasNmsDevices() {
    return this.deviceCollection.hasFetched && this.deviceCollection.unfilteredSize > 0;
  }

  getMetricOptions = (metric, measurement) => {
    const measurementModel = this.measurementModel(measurement);
    if (!metric || !measurementModel) {
      return [];
    }

    const values = measurementModel.get(`storage.Metrics.${metric}.Values`);

    if (!values) {
      return [];
    }

    return Object.entries(values).map(([key, value]) => ({
      label: this.getMetricValueLabel(value),
      value: parseInt(key, 10)
    }));
  };

  get interfaceMeasurementId() {
    return this.measurementModel('/interfaces/counters')?.id;
  }

  get deviceMeasurementId() {
    return this.measurementModel('/system')?.id;
  }

  get bgpNeighborsMeasurementId() {
    return this.measurementModel('/protocols/bgp/neighbors')?.id;
  }

  get customMeasurementId() {
    return this.measurementModel('/components')?.id;
  }

  exportDevicesCsv = (deviceCollection = this.deviceCollection) => {
    const { data } = deviceCollection.getFetchOptions();
    this.exportRemoteCsv(data, { type: 'devices' });
  };

  exportAllInterfacesCsv = () => {
    const { data } = this.interfaceCollection.getFetchOptions();
    this.exportRemoteCsv(data, { type: 'interfaces' });
  };

  exportInterfacesForDeviceCsv = (deviceModel) => {
    const { data } = deviceModel.interfaces.getFetchOptions();
    this.exportRemoteCsv(data, { name: `interfaces-${deviceModel.get('name')}`, type: 'interfaces' });
  };

  exportComponentsForDeviceCsv = (deviceModel) => {
    const data = deviceModel.components.toJS();
    this.exportCsv(data, {
      name: `components-${deviceModel.get('name')}`,
      includedColumns: includedColumnsComponents
    });
  };

  exportBgpNeighborsForDeviceCsv = (deviceModel) => {
    const data = deviceModel.neighbors.toJS();
    this.exportCsv(data, {
      name: `bgp-neighbors-${deviceModel.get('name')}`,
      includedColumns: includedColumnsBgpNeighbors
    });
  };

  exportQueryResultsCollectionTimeseriesToCsv = (resultCollection, query, fileName) => {
    const timestamp = 'timestampMillis';
    const columns = [timestamp];
    const timeMap = {};
    const dimensions = query?.kmetrics?.dimensions || [];

    // Group each series by the common timestamp
    resultCollection.models.forEach((model) => {
      const dimsum = [];
      dimensions.forEach((dimension) => {
        const value = model.get(dimension);
        dimsum.push(value);
      });
      const timeseries = model.get('timeseries');
      if (timeseries?.length > 0) {
        timeseries.forEach((ts) => {
          const [timestampMillis, ...metrics] = ts;
          metrics.forEach((metric, idx) => {
            const seriesName = dimsum.concat([model.get('metrics')[idx]]).join(',');
            const seriesValue = metric;
            if (!timeMap[timestampMillis]) {
              timeMap[timestampMillis] = {};
            }
            timeMap[timestampMillis][seriesName] = seriesValue;
          });
        });
      }
    });

    // Join timeMap into rows for .csv
    const data = Object.entries(timeMap).map(([time, values]) => {
      values.timestampMillis = time;
      return values;
    });

    this.store.$exports.exportCsv({
      fileName: this.exportFileName(fileName),
      columns: uniq(columns.concat(Object.keys(data.at(0)))),
      data
    });
  };

  exportQueryResultsCollectionToCsv = (resultCollection, query, fileName) => {
    let columns = query?.kmetrics?.dimensions || [];
    const metricColumns = Object.keys(query?.kmetrics?.rollups || {});
    columns = columns.concat(metricColumns);
    this.store.$exports.exportCsv({
      fileName: this.exportFileName(fileName),
      columns,
      data: resultCollection.toJS()
    });
  };

  exportCsv = (data, options) => {
    const { includedColumns, name, valueMap } = options;
    const exportData = data.map((fullDatum) => {
      const datum = includedColumns ? pick(fullDatum, includedColumns) : fullDatum;
      Object.entries(datum).forEach(([key, value]) => {
        const valueFunc = valueMap?.[key];
        if (valueFunc) {
          datum[key] = valueFunc(value);
        }
      });
      return datum;
    });
    this.store.$exports.exportCsv({
      fileName: this.exportFileName(name),
      data: exportData
    });
  };

  exportRemoteCsv = (data, options) => {
    const { name, type } = options;
    const path = `/api/ui/recon/${type}/csv`;
    const exportOptions = { path, fileName: this.exportFileName(name || type), type: 'csv' };

    this.store.$exports.addLoadingExport(exportOptions);
    return api.post(path, { data, rawResponse: true }).then((response) => {
      this.store.$exports.clearLoadingExport(exportOptions);
      this.store.$exports.addPayload(response.text, exportOptions);
    });
  };

  exportFileName = (name) => {
    const date = new Date().toISOString().slice(0, 10).replace(/-/g, '');
    return `nms-${name.trim()}-${date}`;
  };

  decorateFilterObj = ({ filterField, filterValue, operator = '=', metric = '', aggregate = '' }) => ({
    filterField,
    filterValue,
    metric,
    aggregate,
    operator
  });

  // convenience fn for composing the link query from Cloud Topology Metrics Sidebar widget
  // gets passed to Metrics Explorer Button
  getCloudTopologyMetricExplorerLinkQuery = ({
    selectedMeasurement,
    metricName,
    selectedVisualizationMetric,
    selectedDimensions,
    selectedFilters,
    ending_time,
    starting_time
  }) => {
    const defaultRollupFilters = {
      connector: 'All',
      filterGroups: [
        {
          name: '',
          named: false,
          connector: 'All',
          not: false,
          autoAdded: '',
          filters: [],
          saved_filters: [],
          filterGroups: []
        }
      ]
    };
    const defaultFilters = {
      connector: 'All',
      filterGroups: [
        {
          name: '',
          named: false,
          connector: 'All',
          not: false,
          autoAdded: '',
          filters: selectedFilters ?? [],
          saved_filters: [],
          filterGroups: []
        }
      ]
    };
    const defaultKmetricsValues = {
      selectedMeasurement,
      selectedMetrics: [metricName],
      selectedDimensions,
      selectedSortOrder: 'desc',
      selectedLimitCount: 20,
      selectedAggFunc: 'avg',
      selectedWindowSize: 0,
      selectedTransformation: 'none',
      selectedRollupsLimit: 100,
      selectedRollupsAggFunc: ['avg', 'max', 'min', 'last', 'p95'],
      selectedVisualization: 'line',
      selectedVisualizationRollup: `last_${metricName}`,
      filters: defaultFilters,
      rollupFilters: defaultRollupFilters,
      ending_time,
      starting_time,
      selectedVisualizationMetric
    };
    return this.formValuesToQuery(defaultKmetricsValues, { allowMultiAggregate: true });
  };
}

const metricsStore = new MetricsStore();

export default metricsStore;
