import Model from 'core/model/Model';
import $insights from 'app/stores/insight/$insights';
import { computed } from 'mobx';

class InsightVoteModel extends Model {
  constructor(options = {}) {
    super(options);

    Object.assign(this, options);
  }

  get urlRoot() {
    return '/api/ui/insights/votes';
  }

  @computed
  get insightLabel() {
    return (
      $insights.getInsightNames().find((insightName) => this.get('insightName') === insightName.value)?.label ||
      this.get('insightName')
    );
  }

  deserialize(data) {
    return data;
  }
}

export default InsightVoteModel;
