import { action, computed, observable } from 'mobx';
import { omit } from 'lodash';
import { nestFilterGroup } from 'core/util/filters';
import { showErrorToast } from 'core/components';
import { createHistogram } from 'core/util/uiMetrics';
import { DEFAULT_DATETIME_FORMAT, timezone } from 'core/util/dateUtils';
import $dictionary from 'app/stores/$dictionary';
import $dataviews from 'app/stores/$dataviews';
import moment from 'moment';
import { getHashForQueries } from './urlHash';

import QueryModel from './QueryModel';
import QueryBucketCollection from './QueryBucketCollection';

import AbstractDataViewModel from './AbstractDataViewModel';
import ExplorerQueryModel from './ExplorerQueryModel';

const { VALID_FPA_METRICS } = require('@kentik/ui-shared/fpa/constants');

export default class DataViewModel extends AbstractDataViewModel {
  queryBuckets = new QueryBucketCollection();

  histogram = createHistogram({
    name: 'DataViewModel_fetch_duration_seconds',
    description: 'DataViewModel fetch in seconds'
  });

  @observable
  hash;

  @observable
  hashSource;

  @observable
  timeOverride = null;

  @observable
  resetTimeConfig = [];

  @observable
  fullyLoaded = false;

  @observable
  formState;

  @observable
  preventQuery = false;

  // when this is true, we wait for the user to select a second time window
  @observable
  isComparisonWorkflow = false;

  onSelectFpaAnalysisOption = null;

  // tracks the time selections for the time series chart
  @observable.shallow
  timeSelections = [];

  @observable
  bucketTab = 'Left +Y Axis';

  get viewShowTitleLink() {
    return !!this.queryBuckets?.selectedQuery;
  }

  @computed
  get hasTimeReset() {
    return this.resetTimeConfig.length > 0;
  }

  @computed
  get errors() {
    return this.queryBuckets.map((bucket) => bucket.error).filter((error) => error);
  }

  @computed
  get errorMessage() {
    return Array.from(new Set(this.errors));
  }

  @computed
  get updateFrequency() {
    return this.queryBuckets.updateFrequency;
  }

  @computed
  get hasUpdateFrequency() {
    return !!this.queryBuckets.updateFrequency;
  }

  @action
  setOnSelectFpaAnalysisOption = (func) => {
    this.onSelectFpaAnalysisOption = func;
  };

  setUpdateFrequency(frequency = 0) {
    this.queryBuckets.activeBuckets.forEach((bucket) => (bucket.updateFrequency = frequency));
    this.saveAndReinitialize();
  }

  @action
  toggleComparisonWorkflow = () => {
    this.isComparisonWorkflow = !this.isComparisonWorkflow;

    // when cancelling, clear the time selections, and clear the selection rects
    if (this.isComparisonWorkflow === false) {
      this.timeSelections = [];
      this.component.clearSelectionRects();
    }
  };

  @action
  addTimeSelection(start, end) {
    const selection = { start, end };

    if (this.isComparisonWorkflow) {
      this.timeSelections = [...this.timeSelections.slice(-1), selection];
    } else {
      this.timeSelections = [selection];
    }
  }

  @action
  updateTimeSelection(index, start, end) {
    this.timeSelections[index] = { start, end };
  }

  @computed
  get viewType() {
    /* commenting and leaving for posterity; Dan figured it out on 03/20/2018
    if ($auth.isDan && Math.random() < 0.1) {
      const viewTypes = $dataviews.viewTypeOptions.filter(
        viewType =>
          viewType.value !== 'sankey' &&
          viewType.value !== 'matrix' &&
          viewType.value !== 'geoHeatMap' &&
          viewType.value !== 'alertScoreboard'
      );
      const randIndex = Math.floor(Math.random() * viewTypes.length) + 1;
      return viewTypes[randIndex - 1].value;
    } */

    return this.queryBuckets.viewType;
  }

  set viewType(viewType) {
    const overrides = { viz_type: viewType };
    // type specific logic
    // matrix
    if (viewType !== 'matrix') {
      overrides.matrixBy = [];
    }

    if (viewType === 'line') {
      overrides.show_total_overlay = false;
    }

    // table (and not table)
    if (viewType === 'table') {
      overrides.topx = 100;
      overrides.show_total_overlay = false;
    } else if (this.queryBuckets.selectedQuery.get('viz_type') === 'table') {
      overrides.topx = 8;
    }

    // general logic for show overlays
    const {
      allowsSecondaryOverlay,
      buckets,
      showTotalTrafficOverlay,
      showHistoricalOverlay,
      suppressSecondaryTopxSeparate,
      suppressBracketing,
      suppressGeneratorMode,
      timeBased
    } = $dataviews.getConfig(viewType);
    if (!allowsSecondaryOverlay) {
      overrides.secondaryOutsort = '';
    }
    if (showTotalTrafficOverlay === false) {
      overrides.show_total_overlay = false;
    }
    if (showHistoricalOverlay === false) {
      overrides.show_overlay = false;
    }
    if (suppressSecondaryTopxSeparate === true) {
      overrides.secondaryTopxSeparate = false;
    }
    if (suppressGeneratorMode === true) {
      overrides.generatorMode = false;
    }

    // don't keep bracket options for non-supported views as results will get tagged anyway. Kill-n-fill for now.
    if (suppressBracketing === true) {
      overrides.bracketOptions = null;
    }

    const oldViewType = this.queryBuckets.viewType;
    this.queryBuckets.viewType = viewType;
    this.queryBuckets.each((bucket, index) => {
      // Make sure the buckets configs line up with the new viewType!
      bucket.replace(buckets[index]);

      bucket.queries.each((query) => {
        query.set('viz_type', viewType);
      });
    });

    if (this.formState) {
      if (timeBased) {
        const aggTypesField = this.formState.getField('aggregateTypes');
        const aggTypes = aggTypesField.getValue();
        aggTypesField.setPristine(false);
        overrides.aggregateTypes = aggTypes.filter((agg) => !agg.startsWith('agg_total'));
      }
      if (this.formState.getValue('secondaryOutsort')) {
        this.formState.getField('mirror').setPristine(false);
        this.formState.getField('secondaryOutsort').setPristine(false);
      }

      overrides.secondaryTopxMirrored =
        this.formState.getValue('secondaryTopxMirrored') &&
        buckets.some((bucket) => bucket.secondaryMirrorBucket !== undefined);

      this.formState.setValues(overrides);
    } else {
      overrides.secondaryTopxMirrored =
        this.queryBuckets.selectedQuery.get('secondaryTopxMirrored') &&
        buckets.some((bucket) => bucket.secondaryMirrorBucket !== undefined);

      this.applyToAllBuckets(
        overrides,
        $dataviews.isViewTypeCompatible(oldViewType, viewType, this.queryBuckets.selectedQuery)
      );
    }
  }

  @computed
  get loading() {
    return !this.preventQuery && this.queryBuckets.loading;
  }

  @computed
  get loadedCount() {
    return this.queryBuckets.loadedCount;
  }

  @computed
  get useFpa() {
    return this.queryBuckets.selectedQuery?.get('use_fpa');
  }

  // used to hide and show the FPA options when the user selects a time slice on a chart.
  @computed
  get isQueryMetricValidForFpa() {
    if (!this.formState) {
      return true;
    }

    return VALID_FPA_METRICS.includes(this.formState.getValue('outsort'));
  }

  @computed
  get fpaType() {
    return this.queryBuckets.selectedQuery?.get('fpa.type');
  }

  @computed
  get timeRange() {
    let query = this.queryBuckets.selectedQuery;
    if (!query) {
      query = this.queryBuckets.activeBucketCount ? this.queryBuckets.activeBuckets[0].firstQuery : QueryModel.create();
    }
    const { lookback_seconds, starting_time, ending_time } = query.serialize();

    return {
      lookback_seconds,
      starting_time,
      ending_time
    };
  }

  @computed
  get seriesIntervalSeconds() {
    const explorerQueryModel = this.createExplorerQueryModelFromSelectedQuery();
    const { fastData, minsPolling, lookback_seconds, ending_time, starting_time } = explorerQueryModel.get();

    const timeWindow = lookback_seconds || moment.utc(ending_time).diff(moment.utc(starting_time), 'second');
    const isFastData = fastData === 'Fast' || (fastData === 'Auto' && timeWindow > 24 * 60 * 60);
    return minsPolling * (isFastData ? 3600 : 60);
  }

  @computed
  get timeDisplay() {
    const { lookback_seconds, starting_time, ending_time } = this.timeRange;

    if (lookback_seconds === 0) {
      return `${starting_time} to ${ending_time}`;
    }

    return lookback_seconds;
  }

  @computed
  get appliedFilters() {
    let query = this.queryBuckets.selectedQuery;
    if (!query && this.queryBuckets.activeBucketCount) {
      query = this.queryBuckets.activeBuckets[0].firstQuery || QueryModel.create();
    }

    // @TODO question for Stan - without this protection and default object returned below, we get errors when reloading the browser while a dashboard is in edit mode
    if (query) {
      return query.serialize().filters;
    }

    return {};
  }

  @computed
  get lastUpdated() {
    return this.queryBuckets.lastUpdated;
  }

  @computed
  get title() {
    return this.queryBuckets.title;
  }

  get isLegacyMDS() {
    return this.queryBuckets.serialize().length > 1;
  }

  reflow() {
    if (this.component && this.component.reflow) {
      this.component.reflow();
    }
  }

  @action
  destroy() {
    this.hash = null;
    this.resetTimeConfig = [];
    this.timeOverride = null;
    this.formState = null;
    this.clear();
  }

  clear() {
    this.eachBucket('unsubscribe');
  }

  refresh() {
    this.eachBucket('refresh');
  }

  gotoDefaultDash(overrides) {
    if (!this.history) {
      return;
    }

    const {
      all_devices,
      device_labels,
      device_name,
      device_sites,
      device_types,
      filters,
      lookback_seconds,
      starting_time,
      ending_time
    } = this.queryBuckets.selectedQuery.get();

    const json = Object.assign(
      {
        all_devices,
        device_labels,
        device_name,
        device_sites,
        device_types,
        filters,
        lookback_seconds,
        starting_time,
        ending_time
      },
      overrides
    );

    window.open(
      `/v4/library/dashboards/${$dictionary.get('defaultExplorerDashboard')}/urlParams/${encodeURIComponent(
        JSON.stringify(json)
      )}`,
      '_blank'
    );
  }

  @action
  updateLookbackSeconds(lookback) {
    if (!this.queryBuckets.activeBucketCount) {
      return;
    }

    this.timeOverride = {
      lookback_seconds: lookback,
      update_frequency: 0
    };

    this.eachBucket('applyToEachQuery', [this.timeOverride]);
    this.saveAndReinitialize();
  }

  @action
  updateTimeRange(starting, ending) {
    if (!this.queryBuckets.activeBucketCount) {
      return;
    }

    const query = this.queryBuckets.activeBuckets[0].firstQuery;

    const lookback_seconds = query.get('lookback_seconds');
    const starting_time = query.get('starting_time');
    const ending_time = query.get('ending_time');
    const fastData = query.get('fastData');

    let originalWidth = lookback_seconds;
    const start = Math.floor(starting / 1000);
    const end = Math.floor(Math.min(ending, Date.now()) / 1000);
    const width = end - start;

    if (originalWidth === 0) {
      originalWidth = timezone.momentFn(query.ending_time).diff(timezone.momentFn(query.starting_time), 'seconds');
    }

    if (width !== originalWidth) {
      this.resetTimeConfig.push({
        lookback_seconds,
        starting_time,
        ending_time,
        fastData,
        time_override: !!this.resetTimeConfig.length
      });

      this.timeOverride = {
        lookback_seconds: 0,
        update_frequency: 0,
        starting_time: timezone
          .momentFn(start * 1000)
          .utc()
          .format(DEFAULT_DATETIME_FORMAT),
        ending_time: timezone
          .momentFn(end * 1000)
          .utc()
          .format(DEFAULT_DATETIME_FORMAT),
        time_override: true
      };

      this.eachBucket('applyToEachQuery', [this.timeOverride]);
      this.saveAndReinitialize();
    }
  }

  @action
  resetTimeRange(empty = false) {
    if (empty && this.timeOverride) {
      this.timeOverride = null;
      this.resetTimeConfig = [];
    } else if (this.resetTimeConfig.length) {
      this.timeOverride = this.resetTimeConfig.pop();
      this.eachBucket('applyToEachQuery', [this.timeOverride]);
      this.saveAndReinitialize();
    }
  }

  overlayFilters(filters, parametricOverrides) {
    if (filters) {
      if (filters.connector === 'Any') {
        nestFilterGroup(filters);
      }
      this.queryBuckets.activeBuckets.forEach((bucket) => {
        bucket.queries.each((query) => {
          let filtersObj;
          if (parametricOverrides && parametricOverrides.filters) {
            filtersObj = parametricOverrides.filters;
          } else {
            filtersObj = query.get('filters');
          }

          if (filtersObj && filtersObj.filterGroups && filtersObj.filterGroups.length) {
            if (filtersObj.connector === 'Any') {
              nestFilterGroup(filtersObj);
            }

            filtersObj.filterGroups = (filters.filterGroups || []).concat(filtersObj.filterGroups);

            /**
             * If override_specific, go through filtersObj and override any of the filterFields
             * which matched what the user selected
             * */
            if (parametricOverrides && parametricOverrides.specific) {
              filtersObj.filterGroups.forEach((group) => {
                group.filters.forEach((filter) => {
                  if (filter.filterField === parametricOverrides.filterField) {
                    filter.filterValue = parametricOverrides.filterValue;
                  }
                });
              });
            }

            query.set({ filters: filtersObj });
          } else {
            query.set({ filters });
          }
        });
      });
    }
  }

  createExplorerQueryModelFromSelectedQuery() {
    return ExplorerQueryModel.createFromQueryModel(this.queryBuckets.selectedQuery);
  }

  createQueryModelFromExplorerQueryModel(explorerQueryModel) {
    if (this.timeOverride) {
      explorerQueryModel.set(this.timeOverride);
    }
    return QueryModel.create(explorerQueryModel.serialize());
  }

  @action
  applyToAllQueries(options, suppressFetch, normalizeDepth) {
    const explorerQuery = this.createExplorerQueryModelFromSelectedQuery();
    explorerQuery.set(options);
    const overrides = this.createQueryModelFromExplorerQueryModel(explorerQuery).serialize();

    const fieldsToOmit = ['bucket', 'bucketIndex', 'descriptor', 'isPreviousPeriod'];
    if (!options.query_title) {
      fieldsToOmit.push('query_title');
    }

    // special logic to only override filters when explicitly provided
    // we may need to add more here in the future, not sure... this is mostly for legacy MDS support
    if (!options.filters) {
      fieldsToOmit.push('filters');
    }

    this.eachBucket('applyToEachQuery', [omit(overrides, fieldsToOmit)]);
    this.saveAndReinitialize(suppressFetch, normalizeDepth);
  }

  @action
  applyToAllBuckets(options, suppressFetch) {
    const explorerQuery = this.createExplorerQueryModelFromSelectedQuery();
    explorerQuery.set(options);
    const overrides = this.createQueryModelFromExplorerQueryModel(explorerQuery).serialize();
    this.queryBuckets.each(
      (bucket) =>
        bucket.firstQuery &&
        bucket.firstQuery.set(omit(overrides, ['bucket', 'bucketIndex', 'descriptor', 'query_title']))
    );
    this.saveAndReinitialize(suppressFetch);
  }

  @action
  applyToSelectedQuery(explorerQuery, suppressFetch = false) {
    this.queryBuckets.selectedQuery.set(this.createQueryModelFromExplorerQueryModel(explorerQuery).serialize());
    if (suppressFetch) {
      this.queryBuckets.lastUpdated = Date.now();
    }
    this.saveAndReinitialize(suppressFetch);
  }

  @action
  saveAndReinitialize(suppressFetch, normalizeDepth) {
    this.queryBuckets.save().then((hash) => {
      if (!suppressFetch && this.hasUpdateFrequency) {
        this.eachBucket('unsubscribe');
      }

      this.initializeHash(hash, 'apply', suppressFetch, normalizeDepth);
    });
  }

  /**
   * @param {string} hash
   * @param {object} overrides
   * @param {{ syncDepth?: boolean, overlayFilters?: boolean, parametricOverrides?: object }} [options]
   */
  @action
  initializeHashWithOverrides(hash, overrides, { syncDepth = false, overlayFilters = true, parametricOverrides } = {}) {
    this.queryBuckets.fetch({ query: { key: hash }, preserveSelection: true }).then(() => {
      const fieldsToOmit = [];

      if (overlayFilters) {
        this.overlayFilters(overrides.filters, parametricOverrides);
        fieldsToOmit.push('filters');
      }

      if (syncDepth) {
        this.queryBuckets.activeBuckets.forEach((bucket) =>
          bucket.queries.each((query) => {
            let depth = Math.min(1000, query.get('depth'));
            if (query.get('viz_type') === 'table') {
              const topx = query.get('topx');
              depth = topx;
            } else if (query.get('viz_type') !== 'matrix') {
              const topx = query.get('topx');
              depth = topx <= 20 ? topx * 2 : topx;
            }
            query.set({ depth });
          })
        );
      }

      this.applyToAllQueries(omit(overrides, fieldsToOmit), this.preventQuery, false);
    });
  }

  @action
  initializeHash(hash, source, suppressFetch = false, normalizeDepth = true) {
    this.hashSource = source || 'init';
    this.hash = hash;

    this.queryBuckets.hash = hash;

    if (suppressFetch !== true) {
      this.queryBuckets.fetch({ query: { key: hash }, preserveSelection: true }).then(() => {
        const { selectedQuery } = this.queryBuckets;
        if (normalizeDepth === true) {
          const topx = selectedQuery.get('topx');
          const depthThresholds = [10, 20, 75, 125, 200, 350];
          const topxThresholds = [1, 4, 8, 16, 25, 40];
          let depth = 100;
          const forceDepth = selectedQuery.get('forceDepth');
          const qDepth = selectedQuery.get('depth');
          if (qDepth && forceDepth) {
            depth = qDepth;
          } else {
            topxThresholds.forEach((threshold, index) => {
              if (topx >= threshold) {
                depth = depthThresholds[index];
              }
            });
          }
          if (selectedQuery.get('viz_type') === 'table') {
            depth = topx;
          }
          selectedQuery.set({ depth });
        }

        const { all_devices, device_name, device_labels, device_types, device_sites } = selectedQuery.get();

        // has at least one of the required fields
        if (
          all_devices ||
          device_name?.length > 0 ||
          device_labels?.length > 0 ||
          device_types?.length > 0 ||
          device_sites?.length > 0
        ) {
          this.eachBucket('subscribe');
        } else {
          this.queryBuckets.activeBuckets.forEach((bucket) => bucket.setLoading(false));
          showErrorToast('You must select at least one device.');
          this.setFullyLoaded();
        }
      });
    }
  }

  initializeQueries(queries, suppressFetch = false) {
    return getHashForQueries(queries).then((hash) => this.initializeHash(hash, 'init', suppressFetch));
  }

  setQueries(queries, options = {}) {
    const { subscribe = true } = options;
    this.queryBuckets.set(queries.map((query) => ({ query: QueryModel.create(query).serialize() })));
    if (subscribe) {
      this.eachBucket('subscribe');
    }
  }

  setQuery(query, options = {}) {
    const { save = false, subscribe = true } = options;
    if (!this.preventQuery) {
      this.queryBuckets.set([{ query }]);
      if (save === true) {
        this.queryBuckets.save().then(action((hash) => (this.hash = hash)));
      }
      if (subscribe === true) {
        this.eachBucket('subscribe');
      }
    }
  }

  loadCachedResults(queryModel, cachedResults) {
    this.setQuery(queryModel.serialize(), { subscribe: false });
    const { activeBuckets } = this.queryBuckets;

    // old style cache with a single bucket
    if (!Array.isArray(cachedResults.results[0])) {
      cachedResults.results = [cachedResults.results];
    }

    this.hideLoadMask = true;

    activeBuckets.forEach((bucket) => {
      bucket.loading = true;
      bucket.loadingCount = 1;
    });

    setTimeout(() => {
      activeBuckets.forEach((bucket, idx) => {
        bucket.loadedCount = 0;
        bucket.queryResults.reset();
        bucket.queryResults.bucket = bucket;
        bucket.queryResults.prefixableFieldUnits = queryModel.prefixableFieldUnits;
        bucket.queryResults.add(cachedResults.results[idx]);
        bucket.queryResults.sort();
        bucket.loading = false;
        bucket.loadedCount = 1;
      });

      this.queryBuckets.lastUpdated = Date.now();
      this.hideLoadMask = false;
    }, 0);
  }

  @action
  eachBucket(method, params = []) {
    if (method === 'subscribe') {
      this.histogram.start();
    }
    this.queryBuckets.each((bucket) => bucket[method](...params));
  }

  @action
  setFullyLoaded() {
    this.histogram.end();
    this.fullyLoaded = true;
  }

  @action
  registerFormState(form) {
    this.formState = form;
  }
}
