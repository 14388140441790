import { observable, action } from 'mobx';
import api from 'core/util/api';
import { Socket } from 'core/util';
import DiscoveryCollection from './DiscoveryCollection';

export class DiscoveryStore {
  @observable
  hasInitialized = false;

  socket;

  collection = new DiscoveryCollection();

  @action
  initialize() {
    const { $auth } = this.store;
    if (!$auth.isSubtenantUser && $auth.hasPermission('recon.enabled', { overrideForSudo: false })) {
      this.collection.fetch();

      this.socket = new Socket({
        outType: 'nmsDiscovery',
        inType: 'nmsDiscovery',
        frequency: 30,
        delaySend: true,
        listenToBroadcast: true,
        onSuccess: this.handleDiscoveryResponse,
        onError(err) {
          console.warn('Received Discovery Socket Error', err);
        },
        onReconnect: () => {
          this.socket.send();
        }
      });

      this.socket.send({ action: 'INIT' });
    }

    return Promise.resolve();
  }

  start() {
    this.socket.send({ action: 'HYDRATE' });
    return null;
  }

  // kicks off the discovery through Ranger
  startDiscovery(params) {
    const { address_ranges, credentials, agent: agentId } = params;

    const body = {
      discoveryID: `d${Date.now()}`,
      credentials,
      agentId,
      address_ranges
    };

    return api.post('/api/ui/discovery/start', { body }).then((result) => {
      this.collection.add(result);
      return result;
    });
  }

  rangerInspect(params) {
    // get the agentId from the device
    // deviceSummaries is not pages and should contain all devices
    const device = this.store.$devices.deviceSummaries.find((d) => d.device_name === params.deviceName);

    if (!device) {
      console.warn('Device not found');
      return Promise.reject();
    }

    const body = {
      agentId: device.device_kentik_agent_id,
      deviceName: params.deviceName,
      fetchMetrics: params.fetchMetrics
    };

    return api.post('/api/ui/discovery/ranger-inspect', { body }).then((result) => result);
  }

  startSnmpWalk(params) {
    // get the agentId from the device
    const device = this.store.$devices.deviceSummaries.find((d) => d.device_name === params.deviceName);

    if (!device) {
      console.warn('Device not found');
      return Promise.reject();
    }

    const body = {
      agentId: device.device_kentik_agent_id,
      deviceName: params.deviceName,
      oid: params.oid
    };

    return api.post('/api/ui/discovery/walk', { body }).then((result) => result);
  }

  // Send a flag to force update init stats
  hydrate() {
    this.socket.send({ action: 'HYDRATE' });
  }

  @action
  handleDiscoveryResponse = (res) => {
    const { discoveryId, type } = res;
    const data = res[type];

    if (type === 'init') {
      this.hasInitialized = true;
    }

    if (type === 'update' && discoveryId) {
      const discoveryModel = this.collection.get(discoveryId);

      if (discoveryModel) {
        discoveryModel.update(data);
        if (data.complete) {
          discoveryModel.set('completed', new Date());
        }
      } else {
        // New discovery inbound
        this.collection.add(data);
      }
    }

    return null;
  };
}

export default new DiscoveryStore();
