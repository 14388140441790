import { uniqBy } from 'lodash';
import { action, computed } from 'mobx';
import moment from 'moment';
import Model from 'core/model/Model';
import Collection from 'core/model/Collection';
import api from 'core/util/api';
import { formatDateTime } from 'core/util/dateUtils';
import $devices from 'app/stores/device/$devices';
import $metrics from 'app/stores/metrics/$metrics';

export default class DiscoveryModel extends Model {
  devices = new Collection();

  get url() {
    return `/api/ui/discovery/${this.id}`;
  }

  get defaults() {
    return {
      completed: false,
      canceled: false,
      num_addresses: 0,
      num_discovered: 0,
      num_unused: 0,
      discovered: [],
      time_started: '',
      time_updated: '',
      time_completed: ''
    };
  }

  @computed
  get status() {
    const { num_addresses, num_discovered, num_unused, canceled, completed } = this.get();
    const ipsScanned = Number(num_discovered) + Number(num_unused);

    const totalAddresses = Number(num_addresses);
    const percentComplete = totalAddresses < 1 ? '0' : Number(ipsScanned) / Number(num_addresses);

    // Time remaining will be found by calculating a scan rate from the start time -> current time, and applying that to the remaining ip count.

    return {
      completed,
      canceled,
      ipsScanned,
      ipsTotal: Number(num_addresses),
      devicesFound: Number(this.devices.size),
      percentComplete: completed ? 1 : percentComplete
    };
  }

  @computed
  get timeStart() {
    return formatDateTime(this.get('cdate'), 'MM-DD HH:mm');
  }

  @computed
  get timeCompleted() {
    return formatDateTime(this.get('completed'), 'MM-DD HH:mm');
  }

  @computed
  get secondsRemaining() {
    const { time_started, completed } = this.get();

    if (completed) {
      return 0;
    }

    if (!time_started) {
      return null;
    }

    // Linear extrapolation from ips scanned since the discovery started
    const { ipsTotal, ipsScanned } = this.status;
    const ipsRemaining = ipsTotal - ipsScanned;
    const nowSeconds = Math.round(Date.now() / 1000);
    const elapsedSeconds = Number.isInteger(time_started) ? nowSeconds - time_started : ipsScanned;
    const secondsPerIp = elapsedSeconds / Math.max(ipsScanned, 1);
    return Math.ceil(secondsPerIp * ipsRemaining);
  }

  @computed
  get timeRemaining() {
    if (this.secondsRemaining === 0) {
      return 'Time complete.';
    }

    if (!this.secondsRemaining) {
      return 'Calculating time remaining...';
    }

    return `About ${moment.duration(this.secondsRemaining, 'seconds').humanize()} remaining`;
  }

  update(data) {
    const normalizedData = Object.assign({}, this.defaults, data);
    const { discovered = [], ...discoveryAttributes } = normalizedData;

    if (discovered) {
      discovered.forEach((newDevice) => {
        const match = this.devices.models.find((device) => device.get('address') === newDevice.address);
        if (!match) {
          const [discoveredModel] = this.devices.add(newDevice);
          if (!discoveredModel.get('device_id')) {
            this.devices.select(discoveredModel, { multi: true });
          }
        }
      });
    }

    this.set(discoveryAttributes);
  }

  deserialize(data) {
    const normalizedData = Object.assign({}, this.defaults, data);
    const { devices = [], ...discoveryAttributes } = normalizedData;

    const uniqueDevices = uniqBy(
      devices.map((d) => ({ ...d.device, device_id: d.device_id, monitoring_template_id: d.monitoring_template_id })),
      'address'
    );
    const added = this.devices.add(uniqueDevices);

    added.forEach((d) => {
      if (!d.get('device_id')) {
        this.devices.select(d, { multi: true });
      }
    });

    return discoveryAttributes;
  }

  @action
  dismiss() {
    return this.save({ dismissed: new Date() }, { patch: true, toast: false }).then(() =>
      this.collection.remove([this.id])
    );
  }

  saveDevices() {
    const selectedDevices = this.devices.selected.map((m) => ({
      ...m.toJS(),
      agent_id: this.get('agent_id')
    }));

    return api
      .post('/api/ui/discovery/devices', {
        body: {
          devices: selectedDevices,
          job_id: this.id
        },
        rawResponse: true
      })
      .catch((response) => {
        const error = response.body?.error;

        if (error) {
          throw new Error(error);
        }

        throw new Error('There was an error while saving the devices.');
      })
      .then((response) => {
        const results = response.body;
        const failedDevices = [];

        results.forEach((result) => {
          const device = this.devices.models.find((d) => d.get('address') === result.address);

          if (device) {
            device.set(result);
            if (result.success === false) {
              failedDevices.push(device);
            }
          }
        });

        this.devices.selected = failedDevices;

        if (failedDevices.length) {
          throw new Error('Some of the devices encountered errors while attempting to save.');
        }

        return Promise.all([
          $metrics.deviceCollection.fetch({ force: true }),
          $metrics.availableMetricsCollection.fetch({ force: true }),
          $devices.loadDeviceSummaries()
        ]);
      });
  }
}
