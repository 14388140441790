import moment from 'moment';
import Collection from 'core/model/Collection';
import ServiceModel from './ServiceModel';

class TrackedServiceCollection extends Collection {
  get url() {
    return '/api/ui/ott/tracked-services';
  }

  get model() {
    return ServiceModel;
  }

  get defaultSortState() {
    return {
      field: 'name',
      direction: 'asc'
    };
  }

  get filterFieldWhitelist() {
    return new Set(['id', 'name']);
  }

  get lastUpdate() {
    return this.get().reduce((d, i) => Math.max(d, moment(i.get('edate')).valueOf()), 0);
  }
}

export default TrackedServiceCollection;
