import api from 'core/util/api';

class CustomerAnalyticsStore {
  topNexthopASNs;

  async syncTopNexthopASNs() {
    return api.get('/api/ui/interfaces/top-nexthop-asns').then((results) => {
      this.topNexthopASNs = results;
      return this.topNexthopASNs;
    });
  }

  // Use this rather than direct access to prevent repeated calls.
  async getTopNexhopASNs(force) {
    if (this.topNexthopASNs && !force) {
      return this.topNexthopASNs;
    }
    return this.syncTopNexthopASNs();
  }
}

export default new CustomerAnalyticsStore();
