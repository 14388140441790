import React from 'react';
import { computed } from 'mobx';
import { get } from 'lodash';
import $cost from 'app/stores/cost/$cost';
import $dictionary from 'app/stores/$dictionary';

import { Box, Icon, Text, Tooltip } from 'core/components';
import { BsExclamationTriangleFill, BsExclamationCircleFill } from 'react-icons/bs';

import Model from 'core/model/Model';
import ProviderModel from './ProviderModel';

const MBPS_FACTOR = 10 ** 6;

class CostGroupModel extends Model {
  get urlRoot() {
    return '/api/ui/cost/cost-group';
  }

  get defaults() {
    return {
      name: 'New Cost Group',
      costmodel: 'commit',
      interfaces: [],
      settings: {
        rate: 0,
        rateUnits: 'Gbps',
        currency: 'USD',
        meteredPercentile: '95',
        unitPrice: 0,
        computationMethod: 'peak-of-sums',
        interfaces: [],
        flatRateprice: 0
      }
    };
  }

  get messages() {
    return {
      create: `Cost Group "${this.get('name')}" was added successfully`,
      update: `Cost Group "${this.get('name')}" was updated successfully`,
      destroy: `Cost Group "${this.get('name')}" was removed successfully`
    };
  }

  static get meteredPercentileOptions() {
    return [
      { value: '90', label: '90th' },
      { value: '91', label: '91th' },
      { value: '92', label: '92th' },
      { value: '93', label: '93th' },
      { value: '94', label: '94th' },
      { value: '95', label: '95th' },
      { value: '96', label: '96th' },
      { value: '97', label: '97th' },
      { value: '98', label: '98th' },
      { value: '99', label: '99th' }
    ];
  }

  static get typeOptions() {
    return ['cloud_interconnect', 'paid_pni', 'free_pni', 'ix', 'transit', 'separator', 'backbone']
      .map((key) => {
        if (key === 'separator') {
          return { separator: true };
        }
        const prefix = key !== 'backbone' ? 'Edge ▸ ' : '';
        const label = $dictionary.get('interfaceClassification')?.connectivityTypes[key];
        return label ? { value: key, label: `${prefix}${label}` } : null;
      })
      .filter(Boolean);
  }

  static get typeTabs() {
    return ['backbone', 'cloud_interconnect', 'paid_pni', 'free_pni', 'ix', 'transit', 'none']
      .map((key) => {
        if (key === 'none') {
          return { value: 'none', label: 'None' };
        }
        const label = $dictionary.get('interfaceClassification')?.connectivityTypes[key];
        return label ? { value: key, label } : null;
      })
      .filter(Boolean);
  }

  static get costModelOptions() {
    return [
      {
        value: 'commit',
        label: 'Commit (Blended)',
        helpText: 'Monthly cost is calculated using rates across multiple tiers.'
      },
      {
        value: 'commit-current-tier',
        label: 'Commit (Volume)',
        helpText: "Monthly cost is calculated using current tier's rate times usage."
      },
      { value: 'flat-rate', label: 'Flat Rate', helpText: '' }
    ];
  }

  static get unitOptions() {
    return [
      { value: 'Gbps', label: 'Gbps', factor: 1000000000 },
      { value: 'Mbps', label: 'Mbps', factor: 1000000 }
    ];
  }

  static get computationMethodOptions() {
    return [
      {
        value: 'peak-of-sums',
        label: 'Peak of Sums (single interface sets)',
        helpText: [
          'A less common method for CIR contracts.',
          'Sample interval: 5 minutes. Time Range: one month.',
          '1. For each sample interval, separately sum the IN samples and the OUT samples of all interfaces in the set.',
          '2. Separately compute the p9Xth across the time range for IN and OUT.',
          '3. Take the greater of the IN or OUT p9Xth.'
        ]
      },
      {
        value: 'peak-of-sums-multi',
        label: 'Peak of Sums (multiple interface sets)',
        helpText: [
          'A rarely used method that addresses interfaces in multiple time zones by allowing one Commitment volume to be',
          'shared over separate p9Xth computations for each interface set.',
          'Sample interval: 5 minutes. Time Range: one month.',
          '1. Separately compute the Peak of Sums for each interface set.',
          '2. Sum the Peak of Sums results from all of the interface sets.'
        ]
      },
      {
        value: 'peak-of-sums-max',
        label: 'Peak of Sums (max of in/out)',
        helpText: [
          'A less common method for CIR contracts (used primarily by TATA Communications, AS6453).',
          'Sample interval: 5 minutes. Time Range: one month.',
          '1. For each sample interval, separately sum the IN samples and the OUT samples of all interfaces in the set.',
          '2. Take the greater of the IN or OUT sum for each sample interval.',
          '3. Compute the p9Xth over the time range for the resulting set.'
        ]
      },
      {
        value: 'sum-of-peaks',
        label: 'Sum of Peaks',
        helpText: [
          'The most common method for CIR contracts.',
          'Sample interval: 5 minutes. Time Range: one month.',
          '1. For each interface in the set, separately compute the p9Xth of all samples in the time range for IN and for OUT.',
          '2. For each interface in the set, take the max of the IN p9Xth and the OUT p9Xth.',
          '3. Sum the maxes of all interfaces in the set.'
        ]
      }
    ];
  }

  // gets current corresponding cost group from mn_cost_group (in case this is a historical cost group from mn_cost_group_history)
  @computed
  get current() {
    const provider = $cost.getProvider(this.get('provider_id'));
    const id = this.get('id');
    if (id) {
      return provider.costGroups.get(id);
    }
    return null;
  }

  @computed
  get costModel() {
    return CostGroupModel.costModelOptions.find((option) => option.value === this.get('costmodel')) || {};
  }

  @computed
  get isCommitCostModel() {
    return this.get('costmodel').includes('commit');
  }

  @computed
  get isFlatRateCostModel() {
    return this.get('costmodel') === 'flat-rate';
  }

  @computed
  get units() {
    return CostGroupModel.unitOptions.find((option) => option.value === this.get('settings.rateUnits')) || {};
  }

  @computed
  get currency() {
    const currency = this.get('settings.currency') || this.defaults.settings.currency;
    const model = $cost.currencies.find({ name: currency });
    return model ? model.get() : {};
  }

  @computed
  get billingDate() {
    const provider = $cost.getProvider(this.get('provider_id'));
    const costGroup = provider.costGroups.get(this.get('id'));
    return costGroup?.get('settings.billingDate') || provider?.get('settings.billingDate') || 1;
  }

  @computed
  get daysRemaining() {
    const { billingDate } = this;
    const todayDateOfMonth = new Date().getDate();
    const lastDateOfThisMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate();
    const lastDate = parseInt(billingDate) === 1 ? lastDateOfThisMonth : billingDate - 1;
    return billingDate ? lastDate - todayDateOfMonth + 1 : 0;
  }

  @computed
  get contractDate() {
    const provider = $cost.getProvider(this.get('provider_id'));
    const costGroup = provider.costGroups.get(this.get('id'));
    return costGroup?.get('settings.contractDate') || provider?.get('settings.contractDate') || null;
  }

  get dateTooltip() {
    const { daysRemaining, contractDate } = this;
    const isDueNextWeekText =
      Number.isFinite(daysRemaining) && daysRemaining > 0 && daysRemaining <= 7 ? 'Due next week' : null;
    const isContractEndingThisMonthText =
      contractDate && new Date(contractDate).getMonth() === new Date().getMonth() ? 'Contract ends this month' : null;
    const isBothText =
      isDueNextWeekText && isContractEndingThisMonthText ? (
        <ul style={{ margin: 0, paddingLeft: '16px' }}>
          <li>{isDueNextWeekText}</li>
          <li>{isContractEndingThisMonthText}</li>
        </ul>
      ) : null;

    return isDueNextWeekText || isContractEndingThisMonthText || isBothText ? (
      <Tooltip position="top" content={<Text>{isBothText || isDueNextWeekText || isContractEndingThisMonthText}</Text>}>
        <Icon
          icon={isContractEndingThisMonthText || isBothText ? BsExclamationCircleFill : BsExclamationTriangleFill}
          color={isContractEndingThisMonthText || isBothText ? 'danger' : 'warning'}
          width="16px"
        />
      </Tooltip>
    ) : (
      <Box width="16px" />
    );
  }

  // Deprecated
  @computed
  get cirUnits() {
    return this.units;
  }

  // Deprecated
  @computed
  get bandwidthUnits() {
    return this.units;
  }

  @computed
  get computationMethod() {
    return (
      CostGroupModel.computationMethodOptions.find(
        (option) => option.value === this.get('settings.computationMethod')
      ) || {}
    );
  }

  @computed
  get rate() {
    return parseFloat(this.get('settings.rate') || 0);
  }

  @computed
  get rateMbps() {
    return this.rateBps / MBPS_FACTOR;
  }

  @computed
  get rateBps() {
    return this.rate * this.units.factor;
  }

  // Deprecated
  @computed
  get bandwidth() {
    return this.rate;
  }

  @computed
  get unitPrice() {
    return parseFloat(this.get('settings.unitPrice') || 0);
  }

  get flatRatePrice() {
    return parseFloat(this.get('settings.flatRatePrice') || 0);
  }

  @computed
  get costTiers() {
    return this.get('settings.costTiers') || [];
  }

  @computed
  get flatRateCharges() {
    const { currencyRates } = $cost;
    const currency = this.get('settings.currency') || this.defaults.settings.currency;
    const charges = this.get('charges') || [];
    return charges.reduce((total, charge) => {
      const amount = +charge.amount || 0;
      let rate = 1;
      if (charge.currency && charge.currency !== currency) {
        rate = currencyRates[currency] / currencyRates[charge.currency];
      }
      return total + parseFloat(charge.annual_frequency === 12 ? +amount * rate : (+amount * rate) / 12);
    }, 0);
  }

  @computed
  get minimumSpend() {
    if (this.isCommitCostModel) {
      return this.rateMbps * this.unitPrice + this.flatRateCharges;
    }
    return this.flatRatePrice + this.flatRateCharges;
  }

  @computed
  get flatRateChargeBreakdown() {
    const { currencyRates } = $cost;
    const charges = this.get('charges') || [];
    const result = { total: 0, groupCharges: 0, intfCharges: 0, intfMap: {} };
    const currency = this.get('settings.currency') || this.defaults.settings.currency;

    charges.reduce((acc, charge) => {
      const { device_id, snmp_id, annual_frequency } = charge;
      let rate = 1;
      if (charge.currency && charge.currency !== currency) {
        rate = currencyRates[currency] / currencyRates[charge.currency];
      }
      const amount = parseFloat(
        annual_frequency === 12 ? +(charge.amount * rate || 0) : +(charge.amount * rate || 0) / 12
      );
      acc.total += amount;

      if (device_id === null && snmp_id === null) {
        acc.groupCharges += amount;
      } else {
        const key = `${device_id}-${snmp_id}`;
        acc.intfCharges += amount;
        acc.intfMap[key] = acc.intfMap[key] || { total: 0, numCharges: 0 };
        acc.intfMap[key].total += amount;
        acc.intfMap[key].numCharges += 1;
      }

      return acc;
    }, result);

    return result;
  }

  @computed
  get interfaces() {
    return this.get('interfaces').map((intf) => ({
      device_name: intf.device_name,
      device_id: parseInt(intf.device_id),
      snmp_id: parseInt(intf.snmp_id),
      site_id: parseInt(intf.site_id),
      egress: parseInt(intf.egress),
      ingress: parseInt(intf.ingress),
      cost: parseInt(intf.cost),
      trafficCost: parseInt(intf.trafficCost),
      chargesCost: parseInt(intf.chargesCost),
      snmp_speed: parseInt(intf.snmp_speed),
      snmp_alias: intf.snmp_alias,
      title: intf.title,
      connectivity_type: intf.connectivity_type,
      interface_description: intf.interface_description
    }));
  }

  @computed
  get interfacesMap() {
    return this.interfaces.reduce((intfMap, intf) => {
      intfMap[`${intf.device_id}-${intf.snmp_id}`] = intf;
      return intfMap;
    }, {});
  }

  // ingress/egress/cir expected in base bps (so is costTier[x].bandwidth values)
  getCommitEffectiveUnitPrice({ ingress, egress, cir, cirUnitPrice, costTiers = [] }) {
    const billBandwidth = Math.max(ingress, egress, cir);
    if (billBandwidth === cir || costTiers.length === 0) {
      return cirUnitPrice;
    }

    // At this point we know billBandwidth is greater than commit and tiers are present. Sort tiers then use to find applicable tier.
    const descBandwidthCostTiers = [...costTiers].sort((a, b) => b.bandwidth - a.bandwidth);

    // from desc bandwidth tiers, find first tier where bill bandwidth is >= tier bandwidth
    const tier = descBandwidthCostTiers.find(({ bandwidth }) => billBandwidth >= bandwidth);
    // if tier is found, use it, otherwise billBandwidth is still below lowest tier, so use cir cost.
    return parseFloat(tier ? tier.cost : cirUnitPrice);
  }

  // cost for commit groups minus any flat rate fees, for flat rate groups, just return flat fees as that's only basis we have for a cost/mbps calculation.
  getTrafficCost(ingress, egress, effectiveUnitPrice) {
    if (this.isCommitCostModel) {
      // unit costs are always in $/Mbps, do all calculations at that level.
      const ingressMbps = ingress / MBPS_FACTOR;
      const egressMbps = egress / MBPS_FACTOR;
      return Math.max(ingressMbps, egressMbps, this.rateMbps) * effectiveUnitPrice;
    }
    return this.flatRateCharges;
  }

  // ingress/egress are in bps
  getCost(ingress, egress, effectiveUnitPrice) {
    if (this.isCommitCostModel) {
      return this.getTrafficCost(ingress, egress, effectiveUnitPrice) + this.flatRateCharges;
    }
    return this.flatRateCharges;
  }

  getCostData(interfaceValues) {
    let ingress = 0;
    let egress = 0;

    const interfaces = this.interfaces.map((intf) => {
      const { device_id, snmp_id, snmp_alias, interface_description } = intf;
      const values = interfaceValues.find(
        (iv) =>
          parseInt(iv.dimensionToValue.device_id) === device_id && parseInt(iv.dimensionToValue.snmp_id) === snmp_id
      );

      const pn_in = parseInt(get(values, 'metricToValue.pn_in', 0) || 0);
      const pn_out = parseInt(get(values, 'metricToValue.pn_out', 0) || 0);

      ingress += pn_in;
      egress += pn_out;
      return {
        device_id,
        snmp_id,
        snmp_alias,
        interface_description,
        ingress: pn_in,
        egress: pn_out
      };
    });

    const utilizationTarget = (this.isCommitCostModel ? this.rate : parseFloat(this.bandwidth)) * this.units.factor;
    const utilizationLabel = this.isCommitCostModel ? 'Commit' : 'Bandwidth';

    const effectiveUnitPrice = this.isCommitCostModel
      ? this.getCommitEffectiveUnitPrice({
          ingress, // in bps
          egress, // in bps
          cir: this.rate * this.units.factor, // in bps
          cirUnitPrice: this.unitPrice,
          costTiers: this.costTiers
        })
      : 0;

    return {
      id: this.get('id'),
      name: this.get('name'),
      costModel: this.costModel,
      ingress,
      egress,
      interfaces,
      utilizationTarget,
      utilizationLabel,
      cost: this.getCost(ingress, egress, effectiveUnitPrice),
      trafficCost: this.getTrafficCost(ingress, egress, effectiveUnitPrice),
      effectiveUnitPrice,
      minCost: this.minimumSpend
    };
  }

  deserialize(data = {}) {
    const { provider, settings } = data;

    if (provider && provider.type) {
      provider.type = ProviderModel.fixProviderType(provider.type);
    }

    // Correct legacy cost tiers with no unitFactor
    if (settings && settings.costTiers && Array.isArray(settings.costTiers)) {
      settings.costTiers = settings.costTiers.map((tier) => {
        if (tier.unitFactor === undefined) {
          tier.bandwidth /= 10 ** 6;
          tier.unitFactor = 10 ** 6;
        }
        return tier;
      });
    }

    return data;
  }

  serialize(data = {}) {
    const { costmodel } = data;

    if (costmodel === 'flat-rate') {
      data.settings.unitPrice = undefined;
      data.settings.costTiers = [];
    }

    if (costmodel.includes('commit')) {
      data.settings.flatRatePrice = undefined;
    }

    return data;
  }
}

export default CostGroupModel;
