import Collection from 'core/model/Collection';
import { computed } from 'mobx';
import Category from './Category';

class CategoryCollection extends Collection {
  get model() {
    return Category;
  }

  get url() {
    return '/api/ui/reports/categories';
  }

  get defaultSortState() {
    return {
      field: 'name',
      direction: 'asc'
    };
  }

  @computed
  get presetCategories() {
    return this.unfiltered.filter((category) => category.get('preset'));
  }

  @computed
  get companyCategories() {
    return this.unfiltered.filter((category) => !category.get('preset'));
  }
}

export default CategoryCollection;
