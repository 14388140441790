import {
  InternalFilters,
  InternalFilterGroups,
  InboundFilters,
  InboundFilterGroups,
  OutboundFilters,
  OutboundFilterGroups,
  ThroughFilters,
  ThroughFilterGroups,
  OtherFilters,
  OtherFilterGroups
} from './inboundOutboundInternalQuery';

export default {
  aggregateTypes: ['p95th_bits_per_sec'],
  topx: 0,
  lookback_seconds: 86400,
  all_devices: true,
  show_overlay: false,
  show_total_overlay: false,
  aggregateFiltersEnabled: true,
  aggregateFilters: [
    {
      name: 'Internal',
      named: true,
      connector: 'All',
      not: false,
      autoAdded: '',
      saved_filters: [],
      ...InternalFilters,
      ...InternalFilterGroups
    },
    {
      name: 'Inbound',
      named: true,
      connector: 'All',
      not: false,
      autoAdded: '',
      saved_filters: [],
      ...InboundFilters,
      ...InboundFilterGroups
    },
    {
      name: 'Outbound',
      named: true,
      connector: 'All',
      not: false,
      autoAdded: '',
      saved_filters: [],
      ...OutboundFilters,
      ...OutboundFilterGroups
    },
    {
      name: 'Through',
      named: true,
      connector: 'All',
      not: false,
      autoAdded: '',
      saved_filters: [],
      ...ThroughFilters,
      ...ThroughFilterGroups
    },
    {
      name: 'Other',
      named: true,
      connector: 'All',
      not: false,
      autoAdded: '',
      saved_filters: [],
      ...OtherFilters,
      ...OtherFilterGroups
    }
  ]
};
