import Model from 'core/model/Model';

class CostSummaryModel extends Model {
  get defaults() {
    return {
      connTypes: {},
      providers: {},
      sites: {},
      siteMarkets: {}
    };
  }
}

export default CostSummaryModel;
