import Model from 'core/model/Model';
import UserCollection from 'app/stores/user/UserCollection';

class RbacRoleSetModel extends Model {
  users = new UserCollection([]);

  isRoleSet = true;

  get urlRoot() {
    return '/api/ui/rbac/role-sets';
  }

  get messages() {
    return {
      create: 'Role Set Added successfully',
      update: 'Role Set Updated successfully',
      destroy: 'Role Set Deleted successfully',
      duplicate: 'Role Set Duplicated successfully'
    };
  }

  getSortValue(field) {
    if (field === 'roles') {
      return this.get('roles')?.length || 0;
    }

    return super.getSortValue(field);
  }

  deserialize(data = {}) {
    const transformedData = super.deserialize(data);
    if (transformedData) {
      const { users } = transformedData;
      if (users && Array.isArray(users)) {
        this.users.set(users);
      }
    }
    return transformedData;
  }
}

export default RbacRoleSetModel;
