import { action, observable, computed } from 'mobx';
import safelyParseJSON from 'core/util/safelyParseJson';
import { getWorstHealth } from 'app/views/synthetics/utils/syntheticsUtils';
import { getTrafficFlowQuery } from './utils';

class AgentTargetTestResultsState {
  get defaults() {
    return {
      test: null,
      agent: null,
      target: null,
      hasResults: false,
      hasTraceResults: false,
      results: null,
      traceResults: null,
      aggregateResults: null, // the avg aggregate results returned from a syngest results request where aggregate:true
      trafficFlowQuery: {}
    };
  }

  test = this.defaults.test;

  agent = this.defaults.agent;

  @observable
  target = this.defaults.target;

  @observable
  hasResults = this.defaults.hasResults;

  @observable
  hasTraceResults = this.defaults.hasTraceResults;

  @observable.ref
  results = this.defaults.results;

  @observable.ref
  traceResults = this.defaults.traceResults;

  @observable.ref
  aggregateResults = this.defaults.aggregateResults;

  @observable.ref
  trafficFlowQuery = this.defaults.trafficFlowQuery;

  constructor({ store, test, agent, target }) {
    this.store = store;
    this.test = test;
    this.agent = agent;
    this.target = target;
  }

  checkForTestResults({ overall = {} } = {}) {
    return !!Object.keys(overall).length;
  }

  checkForTraceResults(results = {}) {
    return !!Object.keys(results || {}).length;
  }

  @action
  setTestResults(testResults, results, aggregateResults) {
    const { test, agent, target } = this;
    const { actualStartDate: startDate, actualEndDate: endDate } = test.agentResults;
    const { flowFiltersByIp } = testResults;
    const hasResults = this.checkForTestResults(results);
    // mesh tests don't need flow filters provided, they are built in the getTrafficFlowQuery util
    const allowFlowQuery = !!flowFiltersByIp?.[target] || test.isMesh;

    if (hasResults) {
      this.results = results;
      this.trafficFlowQuery = allowFlowQuery
        ? getTrafficFlowQuery(test, {
            $syn: this.store.$syn,
            startDate,
            endDate,
            flowFilters: flowFiltersByIp?.[target],
            agent_id: agent?.id,
            target: (testResults?.book?.agent_ip_map || {})[target]
          })
        : null;
    }

    this.hasResults = hasResults;
    this.aggregateResults = aggregateResults;
  }

  @action
  setTraceResults(traceResults, results) {
    const hasTraceResults = this.checkForTraceResults(results);

    if (hasTraceResults) {
      this.traceResults = results;
    }

    this.hasTraceResults = hasTraceResults;
  }

  @computed
  get displayFlowQuery() {
    if (this.test.isMesh || this.test.isAgentTest) {
      const targetAgentType = this.store.$syn.agents.get(this.target)?.get('agent_type');
      return !(this.agent.get('agent_type') === 'global' && targetAgentType === 'global');
    }
    return true;
  }

  @computed
  get resultTime() {
    const { test, hasResults } = this;
    const { resultTimeMs } = test.agentResults;

    if (hasResults && !!resultTimeMs) {
      return resultTimeMs / 1000;
    }

    return null;
  }

  @computed
  get agentHealthTs() {
    const { results, overallHealthTs, httpHealthTs, dnsHealthTs, pingHealthTs } = this;

    if (dnsHealthTs) {
      return dnsHealthTs.map((dnsData) => ({
        ...dnsData,
        data: safelyParseJSON(dnsData?.data) || []
      }));
    }

    if (httpHealthTs) {
      return httpHealthTs.map((httpData) => {
        const { health: httpHealth, time: httpTime } = httpData.overall_health;
        // attempt to find a matching entry in ping data
        const pingHealth = results?.ping?.[httpTime]?.overall_health?.health;
        // if we have ping data, determine the worst health between the two, falling back to http health if necessary
        const health = pingHealth ? getWorstHealth(httpHealth, pingHealth) : httpHealth;

        return { ...httpData, health };
      });
    }

    if (pingHealthTs) {
      return pingHealthTs;
    }

    return overallHealthTs; // fall back to overall health or null
  }

  @computed
  get overallHealthTs() {
    const { results, hasResults } = this;

    if (hasResults && !!results.overall) {
      return Object.values(results.overall).map((health) => ({
        ...health,
        time: +health.time * 1000
      }));
    }

    return null;
  }

  @computed
  get overallHealthTsResult() {
    const { results, hasResults, resultTime } = this;

    if (hasResults && !!results.overall[resultTime]) {
      return results.overall[resultTime];
    }

    return null;
  }

  @computed
  get pingHealthAggregates() {
    return this.aggregateResults?.ping || {};
  }

  @computed
  get dnsHealthAggregates() {
    return this.aggregateResults?.dns || {};
  }

  @computed
  get httpHealthAggregates() {
    return this.aggregateResults?.http || {};
  }

  @computed
  get throughputHealthAggregates() {
    return this.aggregateResults?.throughput || {};
  }

  // ping or knock
  @computed
  get pingHealthTs() {
    const { results, hasResults } = this;

    if (hasResults && !!results.ping) {
      return Object.values(results.ping).map((health) => ({
        ...health,
        ...health?.overall_health, // adds the 'health' property
        time: +health.time * 1000
      }));
    }

    return null;
  }

  // throughput
  @computed
  get throughputHealthTs() {
    const { results, hasResults } = this;
    if (hasResults && !!results?.throughput) {
      return Object.values(results?.throughput).map((health) => ({
        ...health,
        ...health?.overall_health, // adds the 'health' property
        time: +health.time * 1000
      }));
    }

    return null;
  }

  // dns resolution
  get dnsHealthTs() {
    const { results, hasResults } = this;
    if (hasResults && !!results.dns) {
      return Object.values(results.dns).map((health) => ({
        ...health,
        ...health?.overall_health, // adds the 'health' property
        time: +health.time * 1000
      }));
    }

    return null;
  }

  // ping or knock
  @computed
  get pingHealthTsResult() {
    const { results, hasResults, resultTime } = this;

    if (hasResults && !!results.ping?.[resultTime]) {
      return results.ping[resultTime];
    }

    return null;
  }

  // dns resolution
  @computed
  get dnsHealthTsResult() {
    const { results, hasResults, resultTime } = this;

    if (hasResults && !!results.dns?.[resultTime]) {
      return results.dns[resultTime];
    }

    return null;
  }

  // http or page load
  @computed
  get httpHealthTs() {
    const { results, hasResults } = this;

    if (hasResults && !!results.http) {
      return Object.values(results.http).map((health) => ({
        ...health,
        ...health?.overall_health, // adds the 'health' property
        time: +health.time * 1000
      }));
    }

    return null;
  }

  // http or page load
  @computed
  get httpHealthTsResult() {
    const { results, hasResults, resultTime } = this;

    if (hasResults && !!results.http?.[resultTime]) {
      return results.http[resultTime];
    }

    return null;
  }

  // page load or transaction
  @computed
  get harTsResult() {
    const { results, hasResults, resultTime } = this;

    if (hasResults && !!results.http?.[resultTime]?.har) {
      return results.http[resultTime].har;
    }

    return [];
  }

  // transaction
  @computed
  get screenshotsTsResult() {
    const { results, hasResults, resultTime } = this;

    if (hasResults && !!results.http?.[resultTime]?.screenshots) {
      return results.http[resultTime].screenshots;
    }

    return [];
  }

  // @computed
  get traceroutes() {
    const { traceResults, hasTraceResults } = this;
    if (hasTraceResults) {
      return Object.entries(traceResults).map(([ts, health]) => {
        const { count, distance, hopCount, probes, target_ip } = health;
        return {
          count,
          distance,
          hopCount,
          time: +ts * 1000,
          // we want to display the average value of a single probe in the 'path hops' graph
          // we'll already have the correct value in count.[average|distance] here, however parent test results will not
          // we define these 'actual' properties here as duplicates so we can consistently refer to data in the 'path hops' graph
          actualHopCount: count.average,
          actualDistance: distance.average,
          traces: [
            {
              agent_ip: '::',
              agentId: this.agent.id,
              hopCount,
              probes,
              target_ip
            }
          ]
        };
      });
    }
    return [];
  }
}

export default AgentTargetTestResultsState;
