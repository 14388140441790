import { action, computed, observable } from 'mobx';
import { uniqueId } from 'lodash';
import { isIpValid } from 'core/util/ip';
import moment from 'moment';
import { URL_DEFINED_TEST_TYPES } from 'shared/synthetics/constants';
import { getHealthTimeline, getTimelineAlarmsByResultTimeMs } from 'shared/synthetics/utils';
import {
  DEFAULT_LOOKBACK,
  DEFAULT_MESH_LOOKBACK,
  DEFAULT_MIN_LOOKBACK,
  TEST_TYPES,
  SYNGEST_CUTOVER
} from 'app/util/constants';
import { parseQueryString } from 'app/util/utils';
import {
  getTimelinebounds,
  setLookbackFromHistory,
  getMinLookback,
  getAggregationLabel
} from 'app/views/synthetics/utils/syntheticsUtils';
import { getStartAndEndFromLookback } from 'core/util/dateUtils';
import {
  processTestResults,
  processTraceResults,
  enrichTracerouteLookups,
  aggregateSubtestResults,
  getTraceroutes
} from './utils';
import AgentTestResultsState from './AgentTestResultsState';

// the number of intervals we want to show as a trend of test results
// ex: a 1 hour lookback would be trended to the last 4 hours
const TREND_INTERVAL_SIZE = 4;

class AgentResultsState {
  get defaults() {
    return {
      test: null,
      init: false,
      lookbackSeconds: DEFAULT_LOOKBACK,
      minLookbackSeconds: DEFAULT_MIN_LOOKBACK,
      startDate: null,
      endDate: null,
      actualStartDate: null,
      actualEndDate: null,
      loadingResults: false,
      loadingTraceResults: true,
      loadingAlarms: false,
      resultTimeMs: null,
      traceResultTimeMs: null, // we probably wont need this
      bgpStatus: null,
      pageTabId: 'results',
      defaultTabId: undefined,
      hasResults: false,
      hasTraceResults: false,
      hasAlarmResults: false,
      results: null,
      processedTestResults: null,
      processedTraceResults: null,
      traceResults: null,
      alarmResults: null,
      agents: observable.map({}, { deep: false }),
      aggregationData: {}, // returned from syngest results call --- includes maxRawLookback, isAggregated, and results properties

      // ----- begin trend test results -----
      // loading indicator representing the fetch for the entire trended query
      loadingTrendResults: false,
      // loading indicator used when fetching an individual interval in a trend query
      // after fetching, the interval results are cached
      loadingTrendDetailResults: false,
      // indicates a valid set of trend results
      hasTrendResults: false,
      // the test results for the entire trend query
      trendResults: null,
      // currently used to report on whether test results are aggregated
      trendAggregationData: {},
      // an observable map of AgentTestResultsState objects, keyed by agent id
      trendAgents: observable.map({}, { deep: false }),
      // an array sized by the number of supported intervals we want to report on in a trend
      // each entry will have a from/to date range and a place to cache results as they're loaded
      trendIntervals: new Array(TREND_INTERVAL_SIZE).fill(null).map(() => ({
        from: null,
        to: null,
        results: null
      })),
      // the index of the currently selected trend interval
      selectedTrendDetailIndex: TREND_INTERVAL_SIZE - 1,
      // ----- end trend test results -----

      displayOptions: {
        allowSettings: true,
        usePageComponent: true,
        shared: false,
        hideTitle: false,
        hideHealthAggAgentSelector: false,
        allowMesh: false,
        allowSankey: false,
        allowMap: false,
        allowHealthAgg: false,
        allowPath: false,
        allowHealthTimeline: false,
        allowBgp: false,
        allowAgentDetails: false,
        allowShare: true,
        allowExport: true
      }
    };
  }

  test = this.defaults.test;

  requestId = uniqueId('tr_');

  displayOptions = this.defaults.displayOptions;

  @observable
  init = this.defaults.init;

  @observable
  lookbackSeconds = this.defaults.lookbackSeconds;

  @observable
  minLookbackSeconds = this.defaults.minLookbackSeconds;

  @observable
  startDate = this.defaults.startDate;

  @observable
  endDate = this.defaults.endDate;

  @observable
  actualStartDate = this.defaults.actualStartDate;

  @observable
  actualEndDate = this.defaults.actualEndDate;

  @observable
  loadingResults = this.defaults.loadingResults;

  @observable
  loadingTraceResults = this.defaults.loadingTraceResults;

  @observable
  loadingAlarms = this.defaults.loadingAlarms;

  @observable
  resultTimeMs = this.defaults.resultTimeMs;

  @observable
  traceResultTimeMs = this.defaults.traceResultTimeMs;

  @observable
  bgpStatus = this.defaults.bgpStatus;

  @observable
  pageTabId = this.defaults.pageTabId;

  @observable
  defaultTabId = this.defaults.defaultTabId;

  @observable
  hasResults = this.defaults.hasResults;

  @observable
  hasTraceResults = this.defaults.hasTraceResults;

  @observable
  hasAlarmResults = this.defaults.hasAlarmResults;

  @observable.ref
  results = this.defaults.results;

  @observable.ref
  processedTestResults = this.defaults.processedTestResults;

  @observable.ref
  traceResults = this.defaults.traceResults;

  @observable.ref
  processedTraceResults = this.defaults.processedTraceResults;

  @observable.ref
  alarmResults = this.defaults.alarmResults;

  @observable.ref
  agents = this.defaults.agents;

  @observable.ref
  aggregationData = this.defaults.aggregationData;

  history = null;

  loadedResultsHashes = {};

  loadedTraceResultsHashes = {};

  // ----- begin trend test results -----

  @observable
  loadingTrendResults = this.defaults.loadingTrendResults;

  @observable loadingTrendDetailResults = this.defaults.loadingTrendDetailResults;

  @observable
  hasTrendResults = this.defaults.hasTrendResults;

  @observable.ref
  trendResults = this.defaults.trendResults;

  @observable.ref
  trendAggregationData = this.defaults.trendAggregationData;

  @observable.ref
  trendAgents = this.defaults.trendAgents;

  @observable.ref
  trendIntervals = this.defaults.trendIntervals;

  @observable
  selectedTrendDetailIndex = this.defaults.selectedTrendDetailIndex;

  loadedTrendResultsHashes = {};

  // ----- end trend test results

  constructor({ store, test }) {
    this.store = store;
    this.test = test;
  }

  newRequestID() {
    this.requestId = uniqueId('tr_');
  }

  hasLoadedResults(agent_id, target, type = '') {
    const test_id = this.test.id;
    const hashmap = type === 'trace' ? this.loadedTraceResultsHashes : this.loadedResultsHashes;
    if (hashmap[`${test_id}`]) {
      return true;
    }
    if (agent_id && hashmap[`${test_id}.${agent_id}`]) {
      return true;
    }
    if (agent_id && target && hashmap[`${test_id}.${agent_id}.${target}`]) {
      return true;
    }
    return false;
  }

  resetLoadState() {
    this.loadedResultsHashes = {};
    this.loadedTraceResultsHashes = {};
    this.loadedTrendResultsHashes = {};
  }

  configure({ startDate, endDate, lookbackSeconds, history, agent_id, target, force = false, ...displayOptions }) {
    const { test } = this;
    const { hasPing, hasBGPMonitoring, isPreview } = test;
    const isDns = test.get('test_type') === 'dns';

    if (agent_id) {
      this.setupAgentResults(agent_id, target);
    }

    if (!this.init || !this.hasLoadedResults(agent_id, target)) {
      Object.entries(displayOptions).forEach(([key, value]) => {
        if (this.displayOptions[key] !== undefined) {
          this.displayOptions[key] = value;
        }
      });

      const { usePageComponent, shared } = this.displayOptions;

      this.displayOptions.allowMesh = !isDns;
      this.displayOptions.allowSankey = !isDns;
      this.displayOptions.allowMap = !isDns || !this.store.$app.isExport;
      this.displayOptions.allowHealthAgg = isDns ? false : !isPreview;
      this.displayOptions.allowPath = hasPing;
      this.displayOptions.allowHealthTimeline = !isPreview;
      this.displayOptions.allowBgp = hasBGPMonitoring && usePageComponent && !isPreview;
      this.displayOptions.allowAgentDetails = !(shared || isPreview);
      this.history = history;
      this.agent_id = agent_id;
      this.target = target;

      this.initiate({ startDate, endDate, lookbackSeconds });
    } else if (
      force ||
      (startDate && this.startDate !== startDate) ||
      (endDate && this.endDate !== endDate) ||
      (lookbackSeconds && this.lookbackSeconds !== lookbackSeconds)
    ) {
      this.onDateRangeChange({ startDate, endDate, lookbackSeconds });
    }
  }

  initiate({ startDate, endDate, lookbackSeconds }) {
    const { history, test } = this;
    const { $exports } = this.store;
    const { allowSettings } = this.displayOptions;
    const hasLocationHistory =
      this.checkForDateRange(history?.location?.state || {}) || parseQueryString(history?.location?.search).start;
    const hasBeenProvededADateRange = this.checkForDateRange({ startDate, endDate, lookbackSeconds });
    const defaultLookbackSeconds = this.test.isMesh ? DEFAULT_MESH_LOOKBACK : this.lookbackSeconds;
    let initPromise = Promise.resolve({
      startDate: this.startDate,
      endDate: this.endDate,
      lookbackSeconds: defaultLookbackSeconds
    });

    // we support to many ways to configure date range
    if (hasBeenProvededADateRange) {
      initPromise = Promise.resolve({ startDate, endDate, lookbackSeconds });
    } else if (hasLocationHistory && allowSettings) {
      initPromise = setLookbackFromHistory({ $exports, history, test }).then(() => null);
    }

    return initPromise
      .then((dateObj) => {
        if (allowSettings) {
          return $exports
            .getSettings()
            .then(({ hashedDefaultTabId, hashedPageTabId, hashedLookbackSeconds, hashedStartDate, hashedEndDate }) => {
              if (hashedPageTabId !== undefined) {
                this.onPageTabChange(hashedPageTabId);
              }

              if (hashedDefaultTabId !== undefined) {
                this.onResultsTabChange(hashedDefaultTabId);
              }

              const hasDateRangeSettings = this.checkForDateRange({
                startDate: hashedStartDate,
                endDate: hashedEndDate,
                lookbackSeconds: hashedLookbackSeconds
              });

              if (hasDateRangeSettings && !hasBeenProvededADateRange) {
                return {
                  startDate: hashedStartDate,
                  endDate: hashedEndDate,
                  lookbackSeconds: hashedLookbackSeconds
                };
              }
              return dateObj;
            });
        }
        return dateObj;
      })
      .then((dateObj) => {
        this.onDateRangeChange(dateObj);
        this.toggleInit(true);
      });
  }

  @action
  reset() {
    this.newRequestID();
    clearTimeout(this.previewPollTimeoutResults);
    clearTimeout(this.previewPollTimeoutTraceResults);

    const entries = Object.entries(this.defaults);

    for (let i = 0; i < entries.length; i++) {
      const [key, value] = entries[i];
      this[key] = value;
    }
  }

  @action
  toggleInit = (init) => {
    this.init = init;
  };

  @action
  buildTrendIntervals({ startDate, endDate }) {
    // equally divide the time range by the number of trend intervals we want
    const intervalSize = Math.floor((endDate - startDate) / TREND_INTERVAL_SIZE);

    let fromTs = startDate;

    for (let i = 0; i < TREND_INTERVAL_SIZE; i++) {
      const from = fromTs;
      const fromMs = fromTs * 1000;
      const to = fromTs + intervalSize;
      const toMs = to * 1000;

      // populate the date range for the given interval
      this.trendIntervals[i] = {
        // from/to we'll use for test results queries
        from,
        to,
        // fromMs/toMs we'll use for chart plotting
        fromMs,
        toMs
      };

      // start the next interval where we left off
      fromTs += intervalSize;
    }
  }

  fetch({ startDate, endDate, trendStartDate }) {
    this.newRequestID();
    this.buildTrendIntervals({ startDate: trendStartDate, endDate });
    this.fetchTestResults({ start_time: startDate, end_time: endDate });
    this.fetchTrendTestResults({ start_time: trendStartDate, end_time: endDate });
    this.fetchTraceResults({ start_time: startDate, end_time: endDate });
    this.fetchAlarmResults({
      start: moment.utc(startDate * 1000).toISOString(),
      end: moment.utc(endDate * 1000).toISOString()
    });
  }

  checkForTestResults(results = {}) {
    const { health_ts = {}, tasks = {} } = results;
    const tsKeys = Object.keys(health_ts);
    const taskKeys = Object.keys(tasks);
    return tsKeys.length > 0 && taskKeys.length > 0;
  }

  checkForTraceResults({ results = {} }) {
    const { trace_ts = {} } = results;
    const tsKeys = Object.keys(trace_ts);
    return !!tsKeys.length;
  }

  processFetchTestResultsResponse(test, results) {
    const hasResults = this.checkForTestResults(results);

    if (hasResults) {
      const { health_ts = {} } = results;
      const tsKeys = Object.keys(health_ts);
      const timeMs = +tsKeys[tsKeys.length - 1] * 1000;
      const processedTestResults = processTestResults(test, results);
      const agentIds = Object.keys(processedTestResults);

      this.results = results;
      this.resultTimeMs = timeMs;
      this.processedTestResults = processedTestResults;

      // strip out the avg aggregate results for processing
      const { results: aggregateResults, ...aggregationData } = results?.book?.aggregationData || {};

      // merge the subtest aggregates into a form that we can hydrate down the chain
      const aggregates = aggregateSubtestResults({
        aggregateResults,
        testResults: processedTestResults
      });

      // capture the max raw lookback and aggregation state of the syngest results
      this.aggregationData = aggregationData;

      for (let i = 0; i < agentIds.length; i += 1) {
        const agentId = agentIds[i];
        this.setupAgentResults(agentId);
        this.agents.get(agentId).setTestResults(results, processedTestResults[agentId], aggregates?.[agentId]);
      }
    }

    this.hasResults = hasResults;
    this.loadingResults = false;
  }

  processFetchTrendTestResultsResponse(test, results) {
    const hasResults = this.checkForTestResults(results);
    if (hasResults) {
      const processedTestResults = processTestResults(test, results);
      const agentIds = Object.keys(processedTestResults);

      this.trendResults = results;

      // capture the max raw lookback and aggregation state of the syngest results
      this.trendAggregationData = results?.book?.aggregationData || {};

      for (let i = 0; i < agentIds.length; i += 1) {
        const agentId = agentIds[i];
        // sets up an AgentTestResultsState for the given agent if need be
        this.setupTrendAgentResults(agentId);

        // the AgentTestResultsState then sets the results as an AgentTargetTestResultsState
        // this final AgentTargetTestResultsState contains getters we use for things like httpHealthTs, pingHealthTs etc
        this.trendAgents.get(agentId).setTestResults(results, processedTestResults[agentId]);
      }
    }

    this.hasTrendResults = hasResults;
    this.loadingTrendResults = this.defaults.loadingTrendResults;
  }

  @action
  fetchTestResults(dateObj) {
    const { $syn } = this.store;
    const { requestId, test } = this;
    const { isPreset } = this.test;

    // reset state
    this.results = this.defaults.results;
    this.resultTimeMs = this.defaults.resultTimeMs;
    this.processedTestResults = this.defaults.processedTestResults;
    this.aggregationData = this.defaults.aggregationData;
    this.hasResults = this.defaults.hasResults;
    this.loadingResults = true;

    // use 'withAggregation' to explicitly ask for avg aggregate results
    const req = { ...dateObj, ids: [test.id], withAggregation: true };
    let hash = test.id;
    if (this.agent_id) {
      req.agent_ids = [this.agent_id];
      hash += `.${this.agent_id}`;
    }

    /*
      Send targets in the syngest request for all test types except url, page load, and transaction.
      If we sent targets for url and page load, we would not have ping/knock task data returned. 
      This is because the target for ping/knock task types chops down the original target to be just the hostname and would not match.
      Transaction tests do not send their targets because they would never match a task.

      It's particularly important to send in the target when fetching subtest results for a2a/mesh tests because:
        1. Instead of pulling all subtests for the given source agent, we will only pull the subtest for the source/target pair
        2. The aggregate average returned from syngest will be accurate
    */
    if (this.target && !URL_DEFINED_TEST_TYPES.includes(test.get('test_type'))) {
      req.targets = [this.target];
      hash += `.${this.target}`;
    }

    return $syn.requests.fetchTestResults(req, { preset: isPreset }).then(
      action((resp) => {
        const results = resp.tests_health?.[test.id] || {};

        // check if request is relevant before storing response
        if (requestId === this.requestId) {
          this.processFetchTestResultsResponse(test, results);
          this.loadedResultsHashes[hash] = true;

          // cache the test result for trends to use
          this.trendIntervals[this.selectedTrendDetailIndex].results = results;
        }
      })
    );
  }

  @action
  fetchTrendTestResults(dateObj) {
    const { $syn } = this.store;
    const { requestId, test } = this;
    const { isPreset } = this.test;

    // reset state
    this.trendResults = this.defaults.trendResults;
    this.trendAggregationData = this.defaults.trendAggregationData;
    this.hasTrendResults = this.defaults.hasTrendResults;
    this.selectedTrendDetailIndex = this.defaults.selectedTrendDetailIndex;
    this.loadingTrendResults = true;

    const req = { ...dateObj, ids: [test.id] };
    let hash = test.id;
    if (this.agent_id) {
      req.agent_ids = [this.agent_id];
      hash += `.${this.agent_id}`;
    }

    // see fetchTestResults
    if (this.target && !URL_DEFINED_TEST_TYPES.includes(test.get('test_type'))) {
      req.targets = [this.target];
      hash += `.${this.target}`;
    }

    return $syn.requests.fetchTestResults(req, { preset: isPreset }).then(
      action((resp) => {
        const results = resp.tests_health?.[test.id] || {};

        // check if request is relevant before storing response
        if (requestId === this.requestId) {
          this.processFetchTrendTestResultsResponse(test, results);
          this.loadedTrendResultsHashes[hash] = true;
        }
      })
    );
  }

  @action
  fetchTrendDetailResults({ id }) {
    // protect against string poisioning
    const intervalId = +id;
    // mark the selected detail interval
    this.selectedTrendDetailIndex = intervalId;
    // get the details of the selected interval
    const { from, to, results } = this.trendIntervals[intervalId] || {};

    if (results) {
      // run the cache results through the processor chain
      this.processFetchTestResultsResponse(this.test, results);
    } else if (from && to) {
      // mark the trend detail sections as loading
      this.loadingTrendDetailResults = true;

      this.fetchTestResults({ start_time: from, end_time: to }).finally(
        // reset detail loading state
        action(() => (this.loadingTrendDetailResults = this.defaults.loadingTrendDetailResults))
      );
    }
  }

  @action
  fetchTraceResults(dateObj) {
    const { $syn } = this.store;
    const { requestId, test, agent_id, target } = this;
    const { id, isPreset } = test;
    const { allowPath } = this.displayOptions;

    if (allowPath && !this.hasLoadedResults(agent_id, target, 'trace')) {
      // reset state
      this.traceResults = this.defaults.traceResults;
      this.traceResultTimeMs = this.defaults.traceResultTimeMs;
      this.processedTraceResults = this.defaults.processedTraceResults;
      this.hasTraceResults = this.defaults.hasTraceResults;
      this.loadingTraceResults = true;

      const req = { ...dateObj, id };
      let hash = id;
      if (agent_id) {
        req.agent_ids = [agent_id];
        hash += `.${agent_id}`;
      }

      if (target && isIpValid(target)) {
        req.target_ips = [target];
        hash += `.${target}`;
      }

      let targetAgentId;
      // For mesh and agent tests, we want to set the targetAgentId to be used when processing the trace reuslts
      if (target && (test.isMesh || test.isAgentTest)) {
        targetAgentId = isIpValid(target) ? $syn.agents.getTargetAgentsByIps([target])?.[0]?.id : target;
      }

      // for mesh and agent tests, target is now an agentId, so it should still be memoized
      if (target && (test.isMesh || test.isAgentTest)) {
        hash += `.${target}`;
      }

      // For hostname tests, or url based tests, that have trace results, we need the target to be the hostname, because ips change
      let targetHostname;
      if (test.isHostname || test.isUrlDefined) {
        targetHostname = test.agentResults.target;
      }

      $syn.requests.fetchTestTraceroutee(id, req, { preset: isPreset }).then(
        action((traceResults) => {
          // check if request is relevant before storing response
          if (requestId === this.requestId) {
            const hasTraceResults = this.checkForTraceResults(traceResults);

            if (hasTraceResults) {
              const { trace_ts = {} } = traceResults?.results || {};
              const tsKeys = Object.keys(trace_ts);
              const timeMs = +tsKeys[tsKeys.length - 1] * 1000;
              const processedTraceResults = processTraceResults(traceResults, targetAgentId, targetHostname, $syn);
              const agentIds = Object.keys(processedTraceResults);

              traceResults.lookups = enrichTracerouteLookups(traceResults.lookups, $syn);

              this.traceResults = traceResults;
              this.traceResultTimeMs = timeMs;
              this.processedTraceResults = processedTraceResults;

              for (let i = 0; i < agentIds.length; i += 1) {
                const agentId = agentIds[i];
                this.setupAgentResults(agentId);
                this.agents.get(agentId).setTraceResults(traceResults, processedTraceResults[agentId]);
              }
            }

            this.hasTraceResults = hasTraceResults;
            this.loadingTraceResults = false;
            this.loadedTraceResultsHashes[hash] = true;
          }
        })
      );
    }
  }

  /*
    returns a filter for querying against the alert manager alarms api
  */
  getAlarmFetchFilter() {
    const { test } = this;
    // initialize a filter with the source agent
    const filter = {
      keys: {
        filter: {
          agentId: {
            equals: this.agent_id
          }
        }
      }
    };

    if (test.isMesh || test.isAgentTest) {
      // strengthen the filter to include target agent id for agent/mesh tests
      filter.keys.filter.targetAgentId = {
        equals: this.target
      };
    }

    return filter;
  }

  @action
  fetchAlarmResults(dateObj) {
    const { $syn } = this.store;
    const { requestId, test } = this;

    // reset state
    this.alarmResults = this.defaults.alarmResults;
    this.hasAlarmResults = this.defaults.hasAlarmResults;
    this.loadingAlarmResults = true;

    return $syn.requests.getTestAlarms({ ...dateObj, ids: [test.id], filter: this.getAlarmFetchFilter() }).then(
      action((resp) => {
        // check if request is relevant before storing response
        if (requestId === this.requestId) {
          // alarm results will now contain all alarms for the source agent id and in the case of a2a/mesh, a target agent id
          // transaction and agent-targeted tests do not need to do anything further with their alarm results
          // all other test types should filter the selected target to ensure the alarm timeline is populated with accurate
          if (!test.isTransaction && !test.isAgentTest && !test.isMesh && resp?.alarms) {
            resp.alarms = resp.alarms.filter((alarm) => alarm?.details?.target === this.target);
          }

          this.alarmResults = resp;
          this.hasAlarmResults = !!resp?.alarms.length;
          this.loadingAlarmResults = false;
        }
      })
    );
  }

  @action
  setupAgentResults(id, target) {
    const { $syn } = this.store;

    if (!this.agents.get(id)) {
      this.agents.set(
        id,
        new AgentTestResultsState({
          store: this.store,
          test: this.test,
          agent: $syn.agents.get(id)
        })
      );
    }

    if (target) {
      this.agents.get(id).setupTargetResults(target);
    }
  }

  @action
  setupTrendAgentResults(id, target) {
    const { $syn } = this.store;

    if (!this.trendAgents.get(id)) {
      this.trendAgents.set(
        id,
        new AgentTestResultsState({
          store: this.store,
          test: this.test,
          agent: $syn.agents.get(id)
        })
      );
    }

    if (target) {
      this.trendAgents.get(id).setupTargetResults(target);
    }
  }

  onDateRangeChange({ lookbackSeconds, startDate, endDate }) {
    const { displayOptions, history } = this;
    const { $auth } = this.store;
    const dateObj = this.enforceMinLookback({ lookbackSeconds, startDate, endDate });
    const v1 = !!history.location.pathname.includes('/v1');

    if (displayOptions.usePageComponent && !$auth.hasSudo) {
      if (v1) {
        if (!dateObj.startDate || dateObj.startDate > SYNGEST_CUTOVER) {
          history.push(`${history.location.pathname.replace('/v1', '')}`, dateObj);
        }
      } else if (dateObj.startDate && dateObj.startDate < SYNGEST_CUTOVER) {
        history.push(`${history.location.pathname}/v1`, dateObj);
      }
    }

    // don't use lookbackSeconds, does not align well across services
    if (lookbackSeconds) {
      const { start, end } = getStartAndEndFromLookback(lookbackSeconds, 120);
      dateObj.actualStartDate = start.unix();
      dateObj.actualEndDate = end.unix();
    } else {
      dateObj.actualStartDate = dateObj.startDate;
      dateObj.actualEndDate = dateObj.endDate;
    }

    // get the trend start date based on interval size
    const trendDetailIntervalLength = dateObj.actualEndDate - dateObj.actualStartDate;
    const trendStartDate = dateObj.actualStartDate - trendDetailIntervalLength * (TREND_INTERVAL_SIZE - 1);

    dateObj.trendStartDate = trendStartDate;

    this.setDateRange(dateObj);
    this.resetLoadState();
    this.fetch({
      startDate: dateObj.actualStartDate,
      endDate: dateObj.actualEndDate,
      trendStartDate: dateObj.trendStartDate
    });

    if (displayOptions.allowSettings) {
      this.store.$exports.setHash(
        {
          hashedLookbackSeconds: dateObj.lookbackSeconds,
          hashedStartDate: dateObj.startDate,
          hashedEndDate: dateObj.endDate
        },
        true,
        true
      ); // merge and replace the querystring to just be the q hash
    }
  }

  @action
  setDateRange({ lookbackSeconds, startDate, endDate, minLookbackSeconds, actualStartDate, actualEndDate }) {
    // values could potentially come in via url params so make sure to coerce
    this.lookbackSeconds = +lookbackSeconds;
    this.startDate = +startDate;
    this.endDate = +endDate;
    this.actualStartDate = +actualStartDate;
    this.actualEndDate = +actualEndDate;

    if (minLookbackSeconds !== undefined) {
      this.minLookbackSeconds = minLookbackSeconds;
    }
  }

  @action
  onChangeResultTimeMs(resultTimeMs) {
    this.resultTimeMs = resultTimeMs;
  }

  @action
  onChangeTraceResultTimeMs(traceResultTimeMs) {
    this.traceResultTimeMs = traceResultTimeMs;
  }

  @action
  onPageTabChange = (id) => {
    const { allowSettings } = this.displayOptions;
    const { $exports } = this.store;
    this.pageTabId = id;
    if (allowSettings) {
      $exports.setHash({ hashedPageTabId: id }, true, true); // merge and replace the querystring to just be the q hash
    }
  };

  @action
  onResultsTabChange = (id) => {
    const { allowSettings } = this.displayOptions;
    const { $exports } = this.store;
    this.defaultTabId = id;
    if (allowSettings) {
      $exports.setHash({ hashedDefaultTabId: id }, true, true); // merge and replace the querystring to just be the q hash
    }
  };

  checkForDateRange({ startDate, endDate, lookbackSeconds }) {
    if (startDate !== undefined && endDate !== undefined && lookbackSeconds !== undefined) {
      return { startDate, endDate, lookbackSeconds };
    }

    return null;
  }

  enforceMinLookback({ lookbackSeconds, startDate, endDate }) {
    const { test } = this;
    return {
      lookbackSeconds,
      startDate,
      endDate,
      ...(getMinLookback(test, lookbackSeconds) || {})
    };
  }

  isTrendDetailSelected({ id }) {
    return this.selectedTrendDetailIndex === +id;
  }

  @computed
  get resultsKeys() {
    const { results } = this;

    return {
      agentKeys: Object.keys(results?.agents || {}),
      taskKeys: Object.keys(results?.tasks || {}),
      healthTsKeys: Object.keys(results?.health_ts || {}),
      healthAggKeys: Object.keys(results?.health_agg || {})
    };
  }

  @computed
  get agentTaskConfig() {
    const { results, resultsKeys, hasResults } = this;
    const config = {};

    if (hasResults) {
      const { agents } = results;
      const { agentKeys } = resultsKeys;

      for (let i = 0; i < agentKeys.length; i++) {
        const agentKey = agentKeys[i];
        const agent = agents[agentKeys[i]];

        if (agent.targets || agent.hostnames) {
          config[agentKey] = {};
        }

        if (agent.targets) {
          config[agentKey].targets = agent.targets;
        }

        if (agent.hostnames) {
          config[agentKey] = { ...config[agentKey], ...agent.hostnames };
        }
      }
    }

    return config;
  }

  @computed
  get overallHealth() {
    const { results, hasResults } = this;

    if (hasResults) {
      return Object.values(results.health_ts).map(({ overall_health }) => ({
        ...overall_health,
        time: +overall_health.time * 1000
      }));
    }

    return [];
  }

  @computed
  get latestHealth() {
    const { results, resultsKeys, hasResults, test } = this;
    const { isPreview } = test;
    const { healthTsKeys } = resultsKeys;

    if (!isPreview && hasResults) {
      return results.health_ts[healthTsKeys[healthTsKeys.length - 1]];
    }
    return undefined;
  }

  @computed
  get mesh() {
    const { results, hasResults } = this;
    const mesh = [];
    if (hasResults && results.mesh) {
      const rowKeys = Object.keys(results.mesh);
      rowKeys.sort((a, b) => results.mesh[a].name?.localeCompare(results.mesh[b].name));

      for (let i = 0; i < rowKeys.length; i += 1) {
        const row = results.mesh[rowKeys[i]];
        const columnKeys = Object.keys(row.columns);
        columnKeys.sort((a, b) => row.columns[a].name?.localeCompare(row.columns[b].name));
        const columns = [];

        for (let j = 0; j < columnKeys.length; j += 1) {
          const column = row.columns[columnKeys[j]];
          columns.push({ ...column, health: Object.values(column.health) });
        }

        mesh.push({ ...row, columns });
      }

      return mesh;
    }
    return undefined;
  }

  @computed
  get targetValue() {
    const { test } = this;
    const testType = test.get('test_type');
    let targetValue = test.get('config.target.value');

    if (testType === TEST_TYPES.IP_ADDRESS && targetValue.split(',').length > 1) {
      targetValue = 'IP Grid';
    }
    if (testType === TEST_TYPES.HOSTNAME && targetValue.split(',').length > 3) {
      targetValue = `${targetValue.split(',').slice(0, 3).join()},...`;
    }

    return targetValue;
  }

  @computed
  get bigDataSet() {
    const { test } = this;
    const agents = test.get('config.agents') || [];
    const testType = test.get('test_type');
    const bigTestTypes = [TEST_TYPES.APPLICATION_MESH, TEST_TYPES.NETWORK_GRID];

    return agents.length > 0 && bigTestTypes.includes(testType);
  }

  @computed
  get timelineBounds() {
    const { hasResults, results } = this;
    if (hasResults) {
      const { xMin, xMax, xAxisMin, xAxisMax, delta } = getTimelinebounds(results.health_ts);

      return { xMin, xMax, xAxisMin, xAxisMax, delta };
    }

    return this.traceTimelineBounds;
  }

  @computed
  get traceTimelineBounds() {
    const { hasTraceResults, traceResults } = this;

    if (hasTraceResults) {
      const { xMin, xMax, xAxisMin, xAxisMax, delta } = getTimelinebounds(traceResults?.results?.trace_ts);

      return { xMin, xMax, xAxisMin, xAxisMax, delta };
    }

    return {};
  }

  @computed
  get trendTimelineBounds() {
    const { hasTrendResults, trendResults } = this;

    if (hasTrendResults) {
      // for the trend bounds, we want to ignore the adjustment ('true' arg) and just fill to the ends with what we have
      const { xMin, xMax, xAxisMin, xAxisMax, delta } = getTimelinebounds(trendResults.health_ts, true);

      return { xMin, xMax, xAxisMin, xAxisMax, delta };
    }

    return {};
  }

  @computed
  get traceroutes() {
    const { hasTraceResults, traceResults } = this;

    if (hasTraceResults) {
      return getTraceroutes({ traceResults });
    }

    return [];
  }

  @computed
  get timestamps() {
    return Object.keys(this.results?.health_ts || {});
  }

  @computed
  get healthTimeline() {
    const { hasAlarmResults, alarmResults, timestamps } = this;

    if (timestamps.length > 0) {
      return getHealthTimeline({
        alarms: hasAlarmResults ? alarmResults.alarms : [],
        timestamps
      });
    }

    return [];
  }

  @computed
  get isAggregated() {
    return !!this.aggregationData?.isAggregated;
  }

  @computed
  get maxRawLookback() {
    return this.aggregationData?.maxRawLookback || 0;
  }

  @computed
  get canShowRawHealth() {
    return !!this.maxRawLookback && this.isAggregated;
  }

  @computed
  get timelineAlarmsByResultTimeMs() {
    return getTimelineAlarmsByResultTimeMs(this.healthTimeline);
  }

  @computed
  get selectedTimelineAlarms() {
    return this.timelineAlarmsByResultTimeMs[this.resultTimeMs] || [];
  }

  @computed
  get aggregation() {
    const { aggregationData } = this;
    return aggregationData?.aggregation || 0;
  }

  @computed
  get aggregationLabel() {
    return getAggregationLabel(this.aggregation);
  }
}

export default AgentResultsState;
