export default {
  metric: ['InterfaceID_src'],
  customAsGroups: false,
  aggregateTypes: ['p95th_bits_per_sec'],
  topx: 0,
  lookback_seconds: 86400,
  all_devices: true,
  show_overlay: false,
  show_total_overlay: false,
  aggregateFiltersEnabled: true,
  aggregateFiltersDimensionLabel: 'Interface',
  aggregateFilters: [
    {
      name: 'Ingress',
      metric: ['InterfaceID_src'],
      named: true,
      connector: 'All',
      not: false,
      autoAdded: '',
      saved_filters: [],
      filterGroups: [],
      filters: []
    },
    {
      name: 'Egress',
      metric: ['InterfaceID_dst'],
      named: true,
      connector: 'All',
      not: false,
      autoAdded: '',
      saved_filters: [],
      filterGroups: [],
      filters: []
    }
  ]
};
