import { computed } from 'mobx';
import Model from 'core/model/Model';
import $dictionary from 'app/stores/$dictionary';

class MetricConnectionModel extends Model {
  get defaults() {
    return {
      device_local: '',
      device_remote: '',
      interface_local: '',
      interface_remote: '',
      remote_display_name: '',
      interface_local_metadata: {},
      interface_remote_metadata: {},
      device_local_metadata: {},
      device_remote_metadata: {}
    };
  }

  // these fields get set in $metrics.processTopologyDataForDevice
  @computed
  get hasOverrides() {
    return (
      this.get('device_local_override') ||
      this.get('device_remote_override') ||
      this.get('interface_local_override') ||
      this.get('interface_remote_override') ||
      this.get('remote_display_name_override')
    );
  }

  @computed
  get localInterfaceModel() {
    const snmp_id = `${this.get('interface_local')}`;
    const interfaceCollection = this.collection?.parent?.get('interfaceCollection');

    if (interfaceCollection) {
      return interfaceCollection.find({ snmp_id });
    }

    return undefined;
  }

  @computed
  get inUtilization() {
    return this.localInterfaceModel?.get('IfInUtilization');
  }

  @computed
  get outUtilization() {
    return this.localInterfaceModel?.get('IfOutUtilization');
  }

  @computed
  get connectivityType() {
    const type =
      this.get('interface_local_metadata.connectivity_type') || this.get('interface_remote_metadata.connectivity_type');
    return $dictionary.get(`interfaceClassification.connectivityTypes.${type}`) || 'Unclassified';
  }

  @computed
  get networkBoundary() {
    const boundary =
      this.get('interface_local_metadata.network_boundary') || this.get('interface_remote_metadata.network_boundary');
    return $dictionary.get(`interfaceClassification.networkBoundaryTypes.${boundary}`) || 'None';
  }
}

export default MetricConnectionModel;
