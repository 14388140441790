import { action } from 'mobx';
import RecentViewsCollection from './RecentViewsCollection';
import TrendingViewsCollection from './TrendingViewsCollection';
import RecentView from './RecentView';

class RecentlyViewedStore {
  // data specific to the user. is this useful?
  collection = new RecentViewsCollection();

  trendingViewsCollection = new TrendingViewsCollection();

  // data shared with the entire company
  companyWideCollection = new RecentViewsCollection(true);

  initialize() {
    if (!this.store.$app.isSubtenant && !this.store.$app.isSharedLink) {
      this.collection.fetch();
      this.companyWideCollection.fetch();
    }
  }

  @action
  add(view) {
    const { $auth } = this.store;

    // Leave no trace when spoofing
    if ($auth.isSpoofed || this.store.$app.isSharedLink) {
      return false;
    }

    const model = new RecentView(view);
    return model.save({}, { toast: false }).then(() => {
      this.collection.fetch({ force: true });
      this.companyWideCollection.fetch({ force: true });
    });
  }

  getLastViewedDate(view, wholeCompany) {
    const collection = wholeCompany ? this.companyWideCollection : this.collection;
    return collection.recentViewsMap[`${view.type}-${view.id}`]?.get('edate');
  }

  isTrending(view) {
    return `${view.type}-${view.id}` in this.trendingViewsCollection.trendingViewsMap;
  }
}

export default new RecentlyViewedStore();
