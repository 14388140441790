import $auth from 'app/stores/$auth';
import Collection from 'core/model/Collection';

export default class RbacRoleUsersCollection extends Collection {
  roleId = null;

  constructor(roleId) {
    super();

    this.roleId = roleId;
  }

  get url() {
    return `/api/ui/rbac/roles/${this.roleId}/users`;
  }

  get defaultSortState() {
    return {
      field: 'user_full_name',
      direction: 'desc'
    };
  }

  async fetch(options) {
    if (!$auth.hasRbacPermissions(['rbac.role.users::read'])) {
      this.lastFetched = Date.now();
      this.hasFetched = true;

      return Promise.resolve();
    }

    return super.fetch(options);
  }
}
