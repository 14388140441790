export default {
  metric: ['i_device_site_name'],
  aggregateTypes: ['p95th_bits_per_sec'],
  all_devices: true,
  show_overlay: false,
  show_total_overlay: false,
  fastData: 'Fast',
  topx: 10,
  depth: 10
};
