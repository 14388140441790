import Collection from 'core/model/Collection';
import TrendingView from './TrendingView';

class TrendingViewsCollection extends Collection {
  trendingViewsMap = {};

  get url() {
    return '/api/ui/trending-views';
  }

  get model() {
    return TrendingView;
  }

  fetch(options) {
    return super.fetch(options).then(() => {
      this.trendingViewsMap = this.models.reduce((map, trendingView) => {
        map[`${trendingView.get('view_type')}-${trendingView.get('view_id')}`] = trendingView;
        return map;
      }, {});
    });
  }
}

export default TrendingViewsCollection;
