import { computed } from 'mobx';
import moment from 'moment';

import Model from 'core/model/Model';
import api from 'core/util/api';
import $dictionary from 'app/stores/$dictionary';
import $notifications from 'app/stores/notifications/$notifications';
import { POLICY_APPLICATION_LABELS, POLICY_APPLICATIONS, POLICY_MODES } from 'shared/alerting/constants';
import policySerializations from 'app/stores/alerting/policySerializations';
import {
  getBaselineOverallSetting,
  isDeviceStateChangePolicy,
  isInterfaceStateChangePolicy,
  isBGPNeighborStateChangeAlert,
  getPolicyManageUrl
} from 'app/stores/alerting/policyUtils';
import { getMetricOption } from 'app/util/policies';
import { get } from 'lodash';
import $labels from 'app/stores/label/$labels';

const dimensionFlowspecMap = {
  sourceIpCidr: 'IP_src',
  destIpCidr: 'IP_dst',
  protocols: 'Proto',
  srcPorts: 'Port_src',
  dstPorts: 'Port_dst'
};

export const defaultCondition = {
  type: 'static',
  operator: 'greaterThanOrEquals',
  value_select: 'metric_0',
  comparisonValue: 0,
  ratioSettings: {}
};

export const defaultThreshold = {
  severity: 'major',
  enabled: false,
  activate: { times: 5, timeWindow: 2, gracePeriod: 20, timeUnit: 'hour' },
  conditions: [defaultCondition],
  mitigations: [],
  notificationChannels: [],
  filtersJSON: ''
};

class PolicyModel extends Model {
  constructor(attributes, options = {}) {
    const { deserialize } = options;
    super(attributes);

    if (!attributes || Object.keys(attributes).length === 0) {
      attributes = {
        status: 'active',
        evaluationPeriod: '60s',
        baselineSettings: {
          weekendAware: false,
          startOffset: '86400s',
          length: '2419200s',
          minimalLength: '345600s'
        },
        metrics: ['packets'],
        dimensions: ['IP_dst'],
        thresholds: [],
        filters: { connector: 'All', filterGroups: [] }
      };
    }

    // TODO: I had to change this because it broke Policy Updown form.
    // There, we instantiate a policy with some (but not all) of the needed properties.
    // Now if you want to deserialize when instantiating, you have to pass it as an option.

    if (deserialize) {
      this.set(this.deserialize(attributes));
    }
  }

  get urlRoot() {
    return '/api/ui/alerting/policies';
  }

  get sortValues() {
    return {
      primaryDimension: () => this.primaryDimension || '',
      primaryMetric: () => this.primaryMetric || '',
      name: () => this.get('name').trim().toLowerCase()
    };
  }

  get dimensions() {
    return this.get('dimensions');
  }

  get metrics() {
    return this.get('metrics');
  }

  isFlowspecMethodValid = (mitigationMethodData) =>
    !Object.keys(dimensionFlowspecMap).some((key) =>
      this.hasInvalidDimensionsForFlowspecMatch(mitigationMethodData.flowspec, this.get('dimensions'), key)
    );

  hasInvalidDimensionsForFlowspecMatch = (flowSpecData, dimensions, type) => {
    const data = flowSpecData[`${type}`] || {};
    const { infer, status } = data;

    return infer && status === 'active' && !dimensions.includes(dimensionFlowspecMap[`${type}`]);
  };

  get thresholds() {
    return this.get('thresholds');
  }

  @computed
  get enabled() {
    return this.get('status') === 'active';
  }

  @computed
  get policyError() {
    if (this.get('status') === 'error') {
      return this.get('lastError');
    }

    return false;
  }

  get status() {
    return this.get('status');
  }

  @computed
  get expired() {
    const expireDate = this.get('expireDate');
    return expireDate ? moment(expireDate).isAfter() : false;
  }

  @computed
  get mitigations() {
    const thresholds = this.get('thresholds') || [];
    return thresholds.flatMap((threshold) => threshold.mitigations);
  }

  @computed
  get applicationLabel() {
    return POLICY_APPLICATION_LABELS[this.application];
  }

  @computed
  get application() {
    return this.get('application') || POLICY_APPLICATIONS.CORE;
  }

  @computed
  get primaryDimension() {
    const primaryDimension = (this.get('dimensions') || [])[0];
    return $dictionary.dimensionLabelsMap[primaryDimension]?.label || primaryDimension;
  }

  @computed
  get primaryMetric() {
    const primaryMetric = (this.get('metrics') || [])[0];
    return getMetricOption(primaryMetric, this.get('selectedMeasurement')).label;
  }

  @computed
  get manageUrl() {
    return getPolicyManageUrl(this.application, !this.isNew && this.id, this.isUpDownPolicy);
  }

  @computed
  get cloneUrl() {
    return getPolicyManageUrl(this.application, false, this.isUpDownPolicy);
  }

  get isDdosPolicy() {
    return this.get('application') === POLICY_APPLICATIONS.DDOS;
  }

  get isQueryBasedPolicy() {
    return !!this.get('daysToExpire');
  }

  get ruleId() {
    return this.get('activationSettings.alertManagerConfig.ruleId');
  }

  get activationMode() {
    return this.get('activationSettings.mode');
  }

  get policyName() {
    return this.get('name');
  }

  get policyID() {
    return this.get('id');
  }

  get legacyFlowDisabled() {
    return this.get('activationSettings.disableLegacyFlow');
  }

  @computed
  get isToggleMode() {
    return this.activationMode === POLICY_MODES.TOGGLE;
  }

  @computed
  get isMetricPolicy() {
    return this.get('application') === POLICY_APPLICATIONS.METRIC;
  }

  @computed
  get isUpDownPolicy() {
    return this.isMetricPolicy && this.isToggleMode && this.getApplicationMetadata('type') === 'state-change';
  }

  @computed
  get isDeviceUpDownPolicy() {
    return this.isUpDownPolicy && isDeviceStateChangePolicy(this);
  }

  @computed
  get isBGPNeighborStateChange() {
    return this.isUpDownPolicy && isBGPNeighborStateChangeAlert(this);
  }

  @computed
  get isInterfaceUpDownPolicy() {
    return this.isUpDownPolicy && isInterfaceStateChangePolicy(this);
  }

  get isExpressOverallBaselineSetting() {
    return getBaselineOverallSetting(this.get()) === 'express';
  }

  get isDefaultOverallBaselineSetting() {
    return getBaselineOverallSetting(this.get()) === 'default';
  }

  get isPrecisionOverallBaselineSetting() {
    return getBaselineOverallSetting(this.get()) === 'precision';
  }

  @computed
  get labels() {
    return this.id ? $labels.getLabels('policy', this.id) : [];
  }

  get omitDuringSerialize() {
    return [
      'lastEditTime',
      'creationTime',
      'id',
      'customerID',
      'overall_baseline_setting',
      'metric', // Where is this getting added? There shouldn't be a policy-level metric, only metrics
      'dimension_grouping_enabled',
      'dimensionGroupingOptions.NumGroupingDimensions',
      'is_auto_calc',
      'excludedIPs', // Does this need to be split off and used in a separate request?
      'silenced', // I'm assuming this gets covered by silentModeExpireDate
      'cidr6',
      'cidr'
    ];
  }

  getApplicationMetadata = (path) => get(this.get('applicationMetadata'), path);

  removeThresholdsWithNoConditions() {
    const thresholds = this.get('thresholds') || [];
    const newThresholds = thresholds.filter((threshold) => threshold.conditions.length > 0);
    this.set('thresholds', newThresholds);
  }

  addAdditionalNotificationsToPolicy(channelIds = []) {
    this.set(
      'thresholds',
      this.get('thresholds').map((t) => {
        if (!t.enabled) {
          return t;
        }

        return {
          ...t,
          notificationChannels: [...new Set([...t.notificationChannels, ...channelIds])]
        };
      })
    );
  }

  serialize = (data) => {
    const policy = policySerializations.serializeAll(data || this.get());
    return super.serialize(policy);
  };

  deserialize = (data) => {
    let policy = data;
    policy.application = policy.application === '' ? POLICY_APPLICATIONS.CORE : policy.application;

    if (policy.thresholds) {
      policy.thresholds = $notifications.addNotificationsToThresholds(policy.thresholds, policy);
    }

    policy = policySerializations.deserializeAll(policy);

    return policy;
  };

  enable() {
    this.set('status', 'active');

    return api.post(`${this.urlRoot}/${this.id}/enable`);
  }

  disable() {
    this.set('status', 'disabled');

    return api.post(`${this.urlRoot}/${this.id}/disable`);
  }

  clonePolicy(overrides = {}) {
    const clonedPolicy = this.duplicate({ save: false, deserialize: false, removeId: true });
    const { name, thresholds, activationSettings } = clonedPolicy.get();

    clonedPolicy.set({
      name: `${name} (Clone)`,
      thresholds: thresholds.map(({ thresholdID, policyID, ...threshold }) => threshold),
      activationSettings: Object.assign({}, activationSettings, { alertManagerConfig: {} }),
      minTrafficValue: 0,
      ...overrides
    });

    return clonedPolicy;
  }

  extractSubpolicy(overrides = {}) {
    const subpolicy = this.duplicate({
      save: false,
      deserialize: false,
      removeId: true
    });

    subpolicy.set({
      name: `Subpolicy for ${this.id}`,
      policy_id: `${this.id}`,
      parentId: `${this.id}`,
      isSubpolicy: true,
      status: 'active',
      thresholds: subpolicy.get('thresholds').map(({ id, policy_id, ...threshold }) => threshold),
      activationSettings: { disableLegacyFlow: true, mode: subpolicy.get('activationSettings.mode') },
      migrationSettings: {},
      rule: {},
      ...overrides
    });

    return subpolicy;
  }

  async save(...args) {
    let hasNotificationsAttached = false;

    if (args[0]) {
      const saveData = args[0];
      hasNotificationsAttached = saveData.thresholds?.find((t) => t.notificationChannels?.length);
    }

    if (hasNotificationsAttached) {
      return super
        .save(...args)
        .then(() => $notifications.collection.fetch({ force: true }))
        .then(() => {
          this.set('thresholds', $notifications.addNotificationsToThresholds(this.thresholds, this.get()));
        });
    }

    return super.save(...args);
  }

  fetch(...args) {
    // fetch notification channels first to prevent a race condition where the notification channels
    // are not available during deserialization of the policy collection
    return $notifications.collection.fetch().then(() => super.fetch(...args));
  }

  fetchActiveAlerts(options) {
    return api.post('/api/ui/alertingManager/alarms', {
      data: {
        filter: {
          applications: [],
          ruleIds: this.ruleId ? [this.ruleId] : undefined,
          ackStates: [
            'ALARM_ACKNOWLEDGEMENT_REQUIRED',
            'ALARM_ACKNOWLEDGEMENT_UNSPECIFIED',
            'ALARM_ACKNOWLEDGEMENT_NOT_ACKED'
          ],
          states: ['ALARM_STATE_ACTIVE'],
          severities: [],
          active: {
            start: null,
            end: null
          },
          includeArchived: false
        },
        pagination: {
          offset: 0,
          limit: options?.pagination?.limit || 1000
        }
      }
    });
  }

  getActiveAlertCount() {
    return this.fetchActiveAlerts({ pagination: { limit: 1 } }).then(
      (data) => parseInt(data?.pagination?.total_count) || 0
    );
  }
}

export default PolicyModel;
