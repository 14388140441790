import { observable } from 'mobx';
import Collection from 'core/model/Collection';
import NmsPolicyModel from './NmsPolicyModel';

class NmsPolicyCollection extends Collection {
  @observable.ref
  ruleIdToPolicyId = {};

  get url() {
    return '/api/ui/alerting/policies/nms';
  }

  get model() {
    return NmsPolicyModel;
  }

  get defaultSortState() {
    return {
      field: 'name'
    };
  }

  getModelByName = (name) => this.unfiltered.find((model) => model.get('name') === name);

  deserialize = (data) => {
    const policies = Array.isArray(data) ? data : [];

    policies.forEach((policy) => {
      const ruleId = policy?.rule_id;

      if (ruleId) {
        this.ruleIdToPolicyId[ruleId] = policy.id;
      }
    });

    return policies;
  };
}

export default NmsPolicyCollection;
