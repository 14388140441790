import { action, computed, observable } from 'mobx';
import { get } from 'lodash';
import moment from 'moment';
import semver from 'semver';

class PlanModel {
  @observable
  freeCredits = 0;

  @observable
  paidCredits = 0;

  @observable
  loading = false;

  @observable
  loadingUsage = false;

  @observable
  currentUsage = {};

  @observable
  previousMonthUsage = {};

  @observable
  burnRateData = {};

  @observable
  latestAgentVersion = '0.0.0';

  constructor({ store }) {
    this.store = store;
  }

  @action
  destroy() {
    // Clean up when completed
    this.freeCredits = 0;
    this.paidCredits = 0;
    this.currentUsage = {};
    this.previousMonthUsage = {};
    this.synthPlanPromise = undefined;
  }

  @action
  async load(options) {
    const { $syn } = this.store;
    const { force = false } = options || {};

    if (force || !this.synthPlanPromise) {
      this.loading = true;

      this.synthPlanPromise = $syn.requests.getSynthPlan().then((res) => {
        this.paidCredits = get(res, 'synthPlan.metadata.paidCredits', 0);
        this.freeCredits = get(res, 'synthPlan.metadata.freeCredits', 0);
        this.latestAgentVersion = semver.valid(semver.coerce(get(res, 'latestAgentVersion'))) || '0.0.0';
        this.loading = false;
      });
    }
    return this.synthPlanPromise;
  }

  @action
  setTestBurnRate(burnRateData) {
    const { $syn } = this.store;
    $syn.tests.each((testModel) => {
      const burnRateEntry = burnRateData.tests.find((bre) => bre.test_id.toString() === testModel.id.toString());
      testModel.set({ creditBurnRate: get(burnRateEntry, 'credits', 0) });
    });

    $syn.tests.lastUpdated = Date.now();
    // If collection has sortState field of credit related field, re-sort to stay in sync.
    const { field } = $syn.tests.sortState;
    if (['creditBurnRate'].includes(field)) {
      $syn.tests.sort();
    }
  }

  @action
  async syncTestCreditBurnRates() {
    const { $syn } = this.store;
    return $syn.requests.getTestCreditBurnRates().then((res) => {
      this.burnRateData = res;
      this.setTestBurnRate(this.burnRateData);
    });
  }

  @action
  async loadUsageData(options) {
    const { $syn } = this.store;
    const { force = false } = options || {};
    if (force || !this.currentUsage.lastLoaded) {
      this.loadingUsage = true;
      // synth plan data and tests are pre-reqs for usage data to make sense, so make sure they've been loaded before grabbing usage.
      const synthDataPromise = this.synthPlanPromise || this.load();
      const testFetchPromise = $syn.tests.hasFetched ? Promise.resolve() : $syn.tests.fetch();
      this.usagePromise = Promise.all([testFetchPromise, synthDataPromise]).then(() =>
        $syn.requests.getCreditUsage().then((res) => {
          Object.assign(this.currentUsage, get(res, 'current', {}), { lastLoaded: Date.now() });
          Object.assign(this.previousMonthUsage, get(res, 'previous', {}), { lastLoaded: Date.now() });
          this.burnRateData = get(res, 'burnRateData', {});
          this.setTestBurnRate(this.burnRateData);
          this.loadingUsage = false;
        })
      );
    }
    return this.usagePromise;
  }

  // getters
  @computed
  get creditsToEom() {
    // Usage is calculated daily, so if latestEntry date is present, minutes to EOM is from END of latestEntry day to EOM.
    // If latestEntry NOT present, assume it's first day of month and no daily stats have dropped yet, so calculate from start of today to EOM.
    const latestUsageDate = this.currentUsage.latestEntry
      ? moment.utc(this.currentUsage.latestEntry).endOf('day')
      : moment.utc().startOf('day');
    const minsToEom = moment.utc().endOf('month').diff(latestUsageDate, 'minutes');
    return get(this, 'burnRateData.burnRateMinute', 0) * minsToEom;
  }

  @computed
  get currentMonthCreditUsage() {
    return get(this, 'currentUsage.totalCredits', 0);
  }

  /**
   * Projected total credit usage for current month at current burn rate, takes into account previous actual usage for month.
   * IMPORTANT: if you make changes here, make the same changes in src/node/services/synthetics/creditEnforcement.js
   * @returns {number}
   */
  @computed
  get projectedEomCreditUsage() {
    return Math.ceil(this.currentMonthCreditUsage + this.creditsToEom);
  }

  /**
   * Total credits for a hypothetical month at current burnRate.
   * @returns {number}
   */
  @computed
  get monthlyCreditsAtCurrentBurn() {
    return get(this, 'burnRateData.burnRateMinute', 0) * 60 * 24 * 30;
  }

  @computed
  get maxCredits() {
    return this.paidCredits + this.freeCredits;
  }

  // utils
  /**
   * Calculate how many credits a user has based on their next month projection and optional pending test updates
   * @param [opts] { originalTestUsage = 0, pendingTestUsage = 0 }
   * @returns {number}
   */
  calculateRemainingCredits(opts = {}) {
    const { originalTestUsage = 0, pendingTestUsage = 0 } = opts;
    const computedOriginalUsage = this.monthlyCreditsAtCurrentBurn - originalTestUsage;
    const remainingCredits = this.maxCredits - (computedOriginalUsage + pendingTestUsage);
    return remainingCredits;
  }
}

export default PlanModel;
