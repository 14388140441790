import Collection from 'core/model/Collection';
import AlertStatsModel from './AlertStatsModel';

class AlertStatsCollection extends Collection {
  constructor({ data, ...otherOptions } = {}) {
    super(data, { threeWaySort: true, ...otherOptions });

    Object.assign(this, otherOptions || {});
  }

  get model() {
    return AlertStatsModel;
  }

  get url() {
    return '/api/ui/alerting/site-health';
  }

  get fetchMethod() {
    return 'post';
  }

  get defaultSortState() {
    return {
      field: 'totalCount',
      direction: 'desc'
    };
  }

  get secondarySort() {
    return { field: 'title', direction: 'asc' };
  }
}

export default AlertStatsCollection;
