import { action, computed } from 'mobx';
import { escapeRegExp, get, memoize } from 'lodash';
import api from 'core/util/api';
import { deepClone } from 'core/util';
import { ENTITY_TYPES } from 'shared/hybrid/constants';
import * as awsUtils from 'app/views/hybrid/utils/aws';
import { getRouteTableSummary, getSubnetRouteTableSummary } from 'shared/util/aws/utils';
import { getTagValue, getEntitySorter } from 'shared/util/map';
import CloudMapCollection from './CloudMapCollection';

const ENTITY_TYPES_WITHOUT_ACCOUNT_ID = ['Routers', 'CustomerGateways'];

const {
  LAG,
  VPC,
  SUBNET,
  ROUTER,
  ACCOUNT,
  FLOW_LOG,
  NETWORK_ACL,
  RESERVATION,
  VPN_GATEWAY,
  ROUTE_TABLE,
  NAT_GATEWAY,
  VPC_ENDPOINT,
  VPN_CONNECTION,
  TRANSIT_GATEWAY,
  INTERNET_GATEWAY,
  CUSTOMER_GATEWAY,
  AVAILABILITY_ZONE,
  DIRECT_CONNECTION,
  VIRTUAL_INTERFACE,
  NETWORK_INTERFACE,
  CORE_NETWORK_EDGE,
  VPC_PEERING_CONNECTION,
  CORE_NETWORK_ATTACHMENT,
  TRANSIT_GATEWAY_CONNECT,
  DIRECT_CONNECTION_GATEWAY,
  TRANSIT_GATEWAY_ATTACHMENT,
  TRANSIT_GATEWAY_CONNECT_PEER,
  TRANSIT_GATEWAY_VPC_ATTACHMENT
} = ENTITY_TYPES.get('aws');

class AWSCloudMapCollection extends CloudMapCollection {
  // will keep list of account ids that used for loading this collection
  // we need this variable to compare against user settings in $hybridMap disposer to decide either map topology need to be refreshed or no
  currentLoadedAccountIds = [];

  searchableIdPaths = ['id', 'Name', 'RegionName'];

  searchableCIDRPaths = [
    'IpAddress', // Vpcs, Subnets
    'CidrBlock', // Vpcs, Subnets
    'Associations', // DirectConnectGateways
    'NatGatewayAddresses', // NatGateways
    'children', // Routers

    'RequesterVpcInfo', // VpcPeeringConnections
    'AccepterVpcInfo', // VpcPeeringConnections
    'VgwTelemetry' // VpnConnections
  ];

  get fetchMethod() {
    return 'post';
  }

  get url() {
    return '/api/ui/topology/cloud-hierarchy/aws';
  }

  @computed
  get hasDefaultNetworks() {
    return this.unfiltered.some((model) => model.get('entityType') === VPC && model.get('IsDefault'));
  }

  get Summary() {
    return this.initialTopology.Summary;
  }

  /*
    Returns a unique list of account ids
    In the aws world, we're going to count OwnerId, OwnerAccount, ResourceOwnerId, TransitGatewayOwnerId as being account ids
  */
  @computed
  get accountIds() {
    return this.unfiltered.reduce((accountIds, model) => {
      ['OwnerId', 'OwnerAccount', 'ResourceOwnerId', 'TransitGatewayOwnerId'].forEach((accountLookup) => {
        const accountId = model.get(accountLookup);

        if (accountId && !accountIds.includes(accountId)) {
          accountIds.push(accountId);
        }
      });

      return accountIds;
    }, []);
  }

  @computed
  get filterStateGroups() {
    const query = this.filterState || '';

    return query
      .split(',')
      .map((term) => term.trim())
      .reduce(
        (acc, term) => {
          if (term === '') {
            return acc;
          }

          if (this.isValidIP(term)) {
            acc.cidrs.push(term);
            return acc;
          }

          if (this.accountIds.includes(term)) {
            acc.accountIds.push(term);
          } else if (term.includes('=')) {
            acc.tags.push(term);
          } else if (term.includes('-')) {
            acc.ids.push(term);
          }

          return acc;
        },
        {
          accountIds: [],
          ids: [],
          cidrs: [],
          tags: []
        }
      );
  }

  @computed
  get topology() {
    const { Hierarchy } = this.initialTopology;
    const platformHierarchy = {};

    platformHierarchy.Regions = Object.values(Hierarchy.Regions || {}).map((region) => {
      const newRegion = { ...region };
      const {
        [VPC]: vpcs,
        [TRANSIT_GATEWAY]: transitGateways,
        [CORE_NETWORK_EDGE]: coreNetworkEdges,
        [AVAILABILITY_ZONE]: availabilityZones
      } = newRegion;

      if (vpcs) {
        newRegion[VPC] = this.getMatchingModels(Object.values(vpcs).map((vpcNode) => vpcNode.VpcId));
      }

      if (availabilityZones) {
        newRegion[AVAILABILITY_ZONE] = this.getMatchingModels(
          Object.values(availabilityZones).map((availabilityZoneNode) => availabilityZoneNode.ZoneId)
        );
      }

      if (transitGateways) {
        newRegion[TRANSIT_GATEWAY] = this.getMatchingModels(
          Object.values(transitGateways).map((transitGatewayNode) => transitGatewayNode.TransitGatewayId)
        );
      }

      if (coreNetworkEdges) {
        newRegion[CORE_NETWORK_EDGE] = Object.values(coreNetworkEdges);
      }

      newRegion.isEmpty =
        !newRegion[VPC]?.length && !newRegion[TRANSIT_GATEWAY]?.length && !newRegion[CORE_NETWORK_EDGE]?.length;

      return newRegion;
    });

    return {
      ...this.initialTopology,
      Hierarchy: platformHierarchy,
      Routers: this.getModelsByEntityType(ROUTER),
      Lags: this.getModelsByEntityType(LAG),
      DirectConnections: this.getModelsByEntityType(DIRECT_CONNECTION),
      DirectConnectGateways: this.getModelsByEntityType(DIRECT_CONNECTION_GATEWAY),
      CustomerGateways: this.getModelsByEntityType(CUSTOMER_GATEWAY),
      VpnConnections: this.getModelsByEntityType(VPN_CONNECTION)
    };
  }

  getVpcTraffic(vpcId) {
    const { traffic } = this.initialTopology;
    const vpcTraffic = get(traffic, `vpcs.${vpcId}`);

    if (vpcTraffic && vpcTraffic.total_bits_per_second === undefined) {
      vpcTraffic.total_bits_per_second = [
        'inbound_bits_per_second',
        'outbound_bits_per_second',
        'inside_bits_per_second'
      ].reduce((sum, key) => sum + (vpcTraffic[key] || 0), 0);
    }

    return vpcTraffic;
  }

  @action
  async fetch(options = {}) {
    const fetchedAccountsIds = options?.data?.accountIds;

    if (fetchedAccountsIds && Array.isArray(fetchedAccountsIds)) {
      this.currentLoadedAccountIds = fetchedAccountsIds;
    }

    return super.fetch(options);
  }

  filter(query) {
    this.filterState = query || '';
    const { filterStateGroups } = this;
    const discreteFilters = Object.keys(filterStateGroups).reduce((acc, groupName) => {
      const values = filterStateGroups[groupName];

      if (values.length > 0) {
        if (groupName === 'accountIds') {
          return acc.concat({
            type: groupName,
            values,
            fn: (model) =>
              values.find((value) => {
                const hasAccountValue =
                  model.get('OwnerId') || model.get('OwnerAccount') || model.get('ResourceOwnerId');
                const entityType = model.get('entityType');

                // do not filter entities without aws owner or account id (e.g. CustomGateways)
                if (!hasAccountValue) {
                  // VPN connections don't have owner or account id, but they attached to transitGateways -> so we compare for transitGateway's owner id to search value
                  if (entityType === VPN_CONNECTION) {
                    const transitGatewayId = model.get('TransitGatewayId');
                    return this.models.some(
                      (tempModel) =>
                        // TransitGatewayAttachments are only inside VPCs entity types
                        tempModel.get('entityType') === VPC &&
                        tempModel
                          .get(TRANSIT_GATEWAY_ATTACHMENT)
                          .some(
                            (transitGatewayAttachment) =>
                              transitGatewayAttachment.TransitGatewayId === transitGatewayId &&
                              transitGatewayAttachment.TransitGatewayOwnerId === value
                          )
                    );
                  }

                  return !entityType || ENTITY_TYPES_WITHOUT_ACCOUNT_ID.includes(entityType);
                }

                return (
                  value === model.get('OwnerId') ||
                  value === model.get('OwnerAccount') ||
                  value === model.get('ResourceOwnerId')
                );
              })
          });
        }

        if (groupName === 'ids') {
          return acc.concat({
            type: groupName,
            values,
            fn: (model) => values.find((value) => model.searchableData.ids.find((id) => id === value))
          });
        }

        if (groupName === 'cidrs') {
          return acc.concat({
            type: groupName,
            values,
            fn: (model) =>
              values.find((value) => model.searchableData.cidrs.find((cidr) => this.isInSubnet(value, cidr)))
          });
        }

        if (groupName === 'tags') {
          return acc.concat({
            type: groupName,
            values,
            fn: (model) =>
              values.find((value) => {
                const tags = model.get('Tags');
                const tagValues = (tags && tags.map((tag) => `${tag.Key}=${tag.Value}`)) || [];

                return tagValues.find((tagValue) => {
                  const [modelTagKey, modelTagValue] = tagValue.split('=');
                  const [filterTagKey, filterTagValue] = value.split('=');

                  // find a complete key match and a partial value match
                  return (
                    modelTagKey === filterTagKey && new RegExp(escapeRegExp(filterTagValue), 'i').test(modelTagValue)
                  );
                });
              })
          });
        }
      }

      return acc;
    }, []);

    this.models.replace(this.get());

    if (discreteFilters.length > 0) {
      discreteFilters.forEach((filter) => {
        this.models.replace(
          this.models.filter((model) => {
            if (model.get('ZoneId')) {
              // we currently do not allow filtering out the availability zones
              // these are used for rendering the zone and subnets for a given vpc
              return true;
            }

            return filter.fn(model);
          })
        );
      });
    }

    this.sort();

    return this.models;
  }

  getSearchableCIDRList(data = {}) {
    return Object.keys(data).reduce((cidrs, itemType) => {
      const itemData = data[itemType];

      if (itemType === 'IpAddress' || itemType === 'CidrBlock') {
        return cidrs.concat(itemData);
      }

      if (itemType === 'Associations') {
        // DirectConnectGateways.Associations[].AllowedPrefixesToDirectConnectGateway[].Cidr
        const associations = itemData || [];

        return associations.reduce(
          (acc, association) =>
            acc.concat((association?.AllowedPrefixesToDirectConnectGateway || []).map((a) => a?.Cidr)),
          cidrs
        );
      }

      if (itemType === 'children') {
        // Routers.children
        const routerChildren = Object.values(itemData || {});

        return routerChildren.reduce((acc, routerChild) => {
          if (routerChild?.interface_cidr) {
            return acc.concat(routerChild?.interface_cidr);
          }

          return acc;
        }, cidrs);
      }

      if (itemType === NETWORK_INTERFACE) {
        // TransitGatewayAttachments.NetworkInterfaces[].PrivateIpAddress
        const networkInterfaces = Object.values(itemData || {});

        return networkInterfaces.reduce(
          (acc, networkInterface) => acc.concat(networkInterface?.PrivateIpAddress),
          cidrs
        );
      }

      if (itemType === 'routeTableSummary') {
        const routes = itemData?.routes || [];

        return routes.reduce((acc, route) => acc.concat(route?.DestinationCidrBlock), cidrs);
      }

      if (itemType === 'RequesterVpcInfo' || itemType === 'AccepterVpcInfo') {
        // VpcPeeringConnections.RequesterVpcInfo.CidrBlock
        // VpcPeeringConnections.AccepterVpcInfo.CidrBlock
        return cidrs.concat(itemData?.CidrBlock);
      }

      if (itemType === 'VgwTelemetry') {
        // VpnConnections.VgwTelemetry[].OutsideIpAddress
        const telemetry = itemData || [];

        return telemetry.reduce((acc, item) => acc.concat(item?.OutsideIpAddress), cidrs);
      }

      if (itemType === 'NatGatewayAddresses') {
        return itemData?.reduce(
          (acc, natGatewayAddress) => acc.concat(natGatewayAddress?.PrivateIp, natGatewayAddress?.PublicIp),
          cidrs
        );
      }

      return cidrs;
    }, []);
  }

  get entityTypesToIgnoreInHydration() {
    return [CORE_NETWORK_EDGE];
  }

  getEntityNameFromTagValue(entity) {
    return entity.Name ?? getTagValue(entity, 'Name') ?? entity.id;
  }

  deserialize(response) {
    // capture the initial topology response
    const { Hierarchy: responseHierarchy, Entities: responseEntities, ...rest } = response;
    this.initialTopology = {
      Hierarchy: deepClone(responseHierarchy),
      Entities: deepClone(responseEntities),
      ...rest
    };

    // hydrate the hierarchy
    this.initialTopology.Hierarchy.Regions = this.initialTopology.Hierarchy.Regions.reduce(
      (acc, region) => ({
        ...acc,
        [region.id]: this.hydrateHierarchy({ entity: region, entityType: 'Regions', as: 'map' })
      }),
      {}
    );

    const { Entities, Hierarchy } = this.initialTopology;

    // get a comprehensive list of models for the collection
    let models = [
      { entityType: ROUTER, entityId: 'device_id' },
      { entityType: LAG, entityId: 'LagId', allowUnlinked: true },
      { entityType: DIRECT_CONNECTION, entityId: 'ConnectionId', allowUnlinked: true },
      { entityType: DIRECT_CONNECTION_GATEWAY, entityId: 'DirectConnectGatewayId', allowUnlinked: true },
      { entityType: CUSTOMER_GATEWAY, entityId: 'CustomerGatewayId' },
      { entityType: VPN_CONNECTION, entityId: 'VpnConnectionId', allowUnlinked: true }
    ].reduce((acc, entityConfig) => acc.concat(this.getActiveEntities(entityConfig)), []);

    if (Hierarchy && Hierarchy.Regions) {
      const regionalModels = Object.values(Hierarchy.Regions).reduce((acc, region) => {
        const {
          [VPC]: vpcs = null,
          [AVAILABILITY_ZONE]: availabilityZones = null,
          [TRANSIT_GATEWAY]: transitGateways = null
        } = region;

        if (vpcs) {
          const vpcModels = Object.values(vpcs).map(
            ({
              VpcId,
              [SUBNET]: subnets,
              [ROUTE_TABLE]: routeTables,
              [VPN_GATEWAY]: vpnGateways,
              [VPC_ENDPOINT]: vpcEndpoints,
              [INTERNET_GATEWAY]: internetGateways,
              [VPC_PEERING_CONNECTION]: vpcPeeringConnections,
              [CORE_NETWORK_ATTACHMENT]: coreNetworkAttachments,
              [TRANSIT_GATEWAY_ATTACHMENT]: transitGatewayAttachments
            }) => {
              const vpc = get(Entities, `${VPC}.${VpcId}`);

              if (!vpc) {
                return null;
              }

              vpc.id = VpcId;
              vpc.traffic = this.getVpcTraffic(VpcId);
              vpc.Name = this.getEntityNameFromTagValue(vpc);
              vpc.RegionName = region.Name;
              vpc[ROUTE_TABLE] = routeTables;
              vpc.entityType = VPC;

              if (subnets) {
                vpc[SUBNET] = Object.values(subnets)
                  .map(({ SubnetId }) => {
                    const hierarchySubnet = subnets[SubnetId];
                    const entitySubnet = get(Entities, `${SUBNET}.${SubnetId}`, { SubnetId });

                    return {
                      ...entitySubnet,
                      ...hierarchySubnet,
                      id: SubnetId,
                      Name: this.getEntityNameFromTagValue(entitySubnet),
                      RegionName: region.Name,
                      instanceSummary: Entities.InstanceSummary.Regions[region.Name]?.Vpcs[VpcId]?.Subnets[SubnetId]
                    };
                  })
                  .sort(getEntitySorter());

                vpc[NAT_GATEWAY] = Object.values(subnets)
                  .flatMap(({ NatGateways = {} }) =>
                    Object.values(NatGateways).map((gateway) => {
                      gateway.id = gateway.NatGatewayId;
                      gateway.Name = this.getEntityNameFromTagValue(gateway);
                      gateway.RegionName = region.Name;
                      return gateway;
                    })
                  )
                  .sort(getEntitySorter());
              }

              if (internetGateways) {
                vpc[INTERNET_GATEWAY] = Object.values(internetGateways)
                  .map((attachment) => {
                    const { InternetGatewayId } = attachment;
                    const gateway = get(Entities, `${INTERNET_GATEWAY}.${InternetGatewayId}`, { InternetGatewayId });

                    gateway.id = InternetGatewayId;
                    gateway.Name = this.getEntityNameFromTagValue(gateway);
                    gateway.RegionName = region.Name;
                    gateway.VpcId = vpc.id;
                    return gateway;
                  })
                  .sort(getEntitySorter());
              }

              vpc[TRANSIT_GATEWAY_ATTACHMENT] = Object.keys(transitGatewayAttachments)
                .map((TransitGatewayAttachmentId) => {
                  const attachment =
                    get(Entities, `${TRANSIT_GATEWAY_ATTACHMENT}.${TransitGatewayAttachmentId}`) ||
                    get(Entities, `${TRANSIT_GATEWAY_VPC_ATTACHMENT}.${TransitGatewayAttachmentId}`);

                  if (!attachment) {
                    return null;
                  }

                  const { TransitGatewayId, Association } = attachment;

                  attachment.id = TransitGatewayAttachmentId;
                  attachment.Name = this.getEntityNameFromTagValue(attachment);
                  attachment.RegionName = region.Name;
                  attachment.VpcId = vpc.id;

                  const tgwAttachmentRouteTableId =
                    Association?.State === 'associated' ? Association.TransitGatewayRouteTableId : null;

                  if (tgwAttachmentRouteTableId) {
                    attachment.tgwRouteTableSummary = awsUtils.getTransitGatewayRouteTableSummary(Entities, [
                      tgwAttachmentRouteTableId
                    ]);
                  }

                  const gateway = get(Entities, `${TRANSIT_GATEWAY}.${TransitGatewayId}`, { TransitGatewayId });

                  gateway.id = TransitGatewayId;
                  gateway.Name = this.getEntityNameFromTagValue(gateway);
                  gateway.RegionName = region.Name;

                  attachment.TransitGateway = gateway;
                  return attachment;
                })
                .filter((e) => e)
                .sort(getEntitySorter());

              if (vpcPeeringConnections) {
                vpc[VPC_PEERING_CONNECTION] = Object.values(vpcPeeringConnections)
                  .map(({ VpcPeeringConnectionId }) => {
                    const connection = get(Entities, `${VPC_PEERING_CONNECTION}.${VpcPeeringConnectionId}`, {
                      VpcPeeringConnectionId
                    });

                    connection.id = VpcPeeringConnectionId;
                    connection.Name = this.getEntityNameFromTagValue(connection);
                    connection.RegionName = region.Name;
                    return connection;
                  })
                  .sort(getEntitySorter());
              }

              if (vpnGateways) {
                vpc[VPN_GATEWAY] = Object.values(vpnGateways)
                  .map(({ VpnGatewayId }) => {
                    const gateway = get(Entities, `${VPN_GATEWAY}.${VpnGatewayId}`, { VpnGatewayId });

                    gateway.id = VpnGatewayId;
                    gateway.Name = this.getEntityNameFromTagValue(gateway);
                    gateway.RegionName = region.Name;
                    gateway.VpcId = vpc.id;
                    return gateway;
                  })
                  .sort(getEntitySorter());
              }

              if (vpcEndpoints) {
                vpc[VPC_ENDPOINT] = Object.values(vpcEndpoints)
                  .map(({ VpcEndpointId }) => {
                    const endpoint = get(Entities, `${VPC_ENDPOINT}.${VpcEndpointId}`, { VpcEndpointId });

                    endpoint.id = VpcEndpointId;
                    endpoint.Name = this.getEntityNameFromTagValue(endpoint);
                    endpoint.RegionName = region.Name;
                    const splitServiceName = endpoint.ServiceName?.split('.');
                    endpoint.ShortServiceName =
                      splitServiceName?.[splitServiceName.length - 1].toUpperCase() || endpoint.ServiceName;
                    return endpoint;
                  })
                  .sort(getEntitySorter());
              }

              if (coreNetworkAttachments) {
                vpc[CORE_NETWORK_ATTACHMENT] = Object.values(coreNetworkAttachments)
                  .map(({ AttachmentId }) => {
                    const attachment = get(Entities, `${CORE_NETWORK_ATTACHMENT}.${AttachmentId}`, { AttachmentId });

                    attachment.id = AttachmentId;
                    attachment.Name = this.getEntityNameFromTagValue(attachment);
                    attachment.RegionName = region.Name;

                    return attachment;
                  })
                  .sort(getEntitySorter());
              }

              return vpc;
            }
          );

          acc = acc.concat(vpcModels);
        }

        if (availabilityZones) {
          const availabilityZoneModels = Object.values(availabilityZones)
            .map(({ ZoneId }) => {
              const node = get(Entities, `${AVAILABILITY_ZONE}.${ZoneId}`);

              if (node) {
                node.id = ZoneId;
              }

              return node;
            })
            .sort((a, b) => a.ZoneName.localeCompare(b.ZoneName));

          acc = acc.concat(availabilityZoneModels);
        }

        if (transitGateways) {
          const transitGatewayModels = Object.values(transitGateways)
            .map(({ TransitGatewayId, TransitGatewayAttachment, TransitGatewayRouteTables }) => {
              const transitGateway = get(Entities, `${TRANSIT_GATEWAY}.${TransitGatewayId}`);

              if (transitGateway) {
                const routeTableIds = Object.keys(TransitGatewayRouteTables || {});

                transitGateway.id = TransitGatewayId;
                transitGateway.Name = this.getEntityNameFromTagValue(transitGateway);
                transitGateway.RegionName = region.Name;
                transitGateway.routeTableSummary = awsUtils.getTransitGatewayRouteTableSummary(Entities, routeTableIds);

                if (TransitGatewayAttachment) {
                  transitGateway.TransitGatewayAttachment = Object.values(TransitGatewayAttachment).map(
                    ({ TransitGatewayAttachmentId }) =>
                      get(Entities, `${TRANSIT_GATEWAY_ATTACHMENT}.${TransitGatewayAttachmentId}`)
                  );
                }
              }

              return transitGateway;
            })
            .sort(getEntitySorter());

          acc = acc.concat(transitGatewayModels);
        }

        return acc;
      }, []);

      models = models.concat(regionalModels);
    }

    const modelsWithSearchableData = this.addSearchableDataToModels({
      entities: response.Hierarchy.Regions,
      models
    });

    return modelsWithSearchableData;
  }

  /**
   * will generate list of option for given entity type
   * in addition will provide label keys and entity
   * that can be used for the search further
   */
  getEntitiesByType(entityType) {
    if (entityType === 'Instance') {
      return Object.values(this.Entities[RESERVATION])
        .map(({ Instances }) => Instances)
        .flat(2);
    }

    return super.getEntitiesByType(entityType);
  }

  @action
  fetchConnectivityCheckerTopology(params) {
    const excludedKeys = [
      LAG,
      FLOW_LOG,
      NETWORK_ACL,
      VPN_GATEWAY,
      TRANSIT_GATEWAY,
      INTERNET_GATEWAY,
      CUSTOMER_GATEWAY,
      VIRTUAL_INTERFACE,
      AVAILABILITY_ZONE,
      DIRECT_CONNECTION,
      TRANSIT_GATEWAY_CONNECT,
      TRANSIT_GATEWAY_CONNECT_PEER,
      TRANSIT_GATEWAY_VPC_ATTACHMENT
    ].join(',');

    return this.fetch({ query: { excludedKeys }, ...params });
  }

  findCoreNetworkEdgeByAttachment(coreNetworkAttachmentId) {
    const coreNetworkAttachment = this.Entities?.[CORE_NETWORK_ATTACHMENT]?.[coreNetworkAttachmentId];

    if (!coreNetworkAttachment) {
      return null;
    }

    const { EdgeLocation, CoreNetworkId } = coreNetworkAttachment;

    return Object.values(this.Entities?.[CORE_NETWORK_EDGE] ?? {})?.find(
      (edge) => edge.CoreNetworkId === CoreNetworkId && edge.EdgeLocation === EdgeLocation
    );
  }

  @action
  computeVpcRouteTableSummaryForce = memoize((vpcId) => {
    const { Entities } = this.topology;

    const vpcRouteTables = Object.values(Entities[ROUTE_TABLE] || {}).filter(
      (routeTable) => routeTable.VpcId === vpcId
    );

    const vpcRouteTableIds = vpcRouteTables.map((routeTable) => routeTable.id);
    // this is required, because it computes association to subnet
    const routeTableSummary = getRouteTableSummary(Entities, vpcRouteTableIds);
    routeTableSummary.routes = routeTableSummary.routes.map((route) => {
      const { routeTableId } = route;

      if (!routeTableId) {
        return route;
      }

      const routeTable = Entities[ROUTE_TABLE][routeTableId] ?? null;
      if (routeTable) {
        route.routeTable = routeTable;
      }

      return route;
    });
    return routeTableSummary;
  });

  @action
  computeSubnetRouteTableSummaryForce = memoize((subnetId) => {
    const { Entities } = this.topology;
    const subnet = Entities[SUBNET][subnetId] ?? null;

    if (!subnet) {
      console.warn('Unable to find subnet ', subnetId);
      return null;
    }

    // this is required, because it computes association to subnet
    const routeTableSummary = this.computeVpcRouteTableSummaryForce(subnet.VpcId);

    const vpcRouteTables = routeTableSummary.routeTables.map((routeTableId) => Entities[ROUTE_TABLE][routeTableId]);
    return getSubnetRouteTableSummary(Entities, vpcRouteTables, subnet.id);
  });

  @action
  executeRouteTableSummaryApi(entityId, entityType, query = {}) {
    return api
      .get(`/api/ui/topology/aws/routeTableSummary/${entityType}/${entityId}`, { query, showErrorToast: false })
      .catch((error) => {
        // slowly compute on client side
        console.error(
          'Unable to fetch route table summary from api, falling back to UI $store force methods. Api error:',
          error
        );

        if (entityType === SUBNET) {
          return this.computeSubnetRouteTableSummaryForce(entityId);
        }

        if (entityType === VPC) {
          return this.computeVpcRouteTableSummaryForce(entityId);
        }

        console.warn('No handling for entityType', entityType);
        return null;
      })
      .then((routeTableSummary) => {
        if (!routeTableSummary) {
          return null;
        }

        const { Entities } = this.topology;

        // add route table object to route that reference route table id
        routeTableSummary.routes = routeTableSummary.routes.map((route) => {
          const { routeTableId } = route;

          if (!routeTableId) {
            return route;
          }

          const routeTable = Entities[ROUTE_TABLE][routeTableId] ?? null;
          if (routeTable) {
            if (routeTable.subnetIds === undefined || !routeTable.main === undefined) {
              const associations = routeTable.Associations || [];
              const subnetAssociations = associations.filter((association) => !!association.SubnetId);
              routeTable.main = associations.some((association) => association.Main);
              routeTable.subnetIds = subnetAssociations.map((association) => association.SubnetId);
            }

            if (!routeTable.vpcId === undefined) {
              routeTable.vpcId = routeTable.VpcId;
            }

            if (!routeTable.gatewayIds === undefined) {
              routeTable.gatewayIds = Array.from(new Set(routeTable.Routes.map((r) => r.gatewayId)));
            }

            route.routeTable = routeTable;
          }

          return route;
        });

        return routeTableSummary;
      });
  }

  @action
  computeSubnetRouteTableSummary(subnetId, query = {}) {
    return this.executeRouteTableSummaryApi(subnetId, SUBNET, query);
  }

  @action
  computeVpcRouteTableSummary(vpcId, query = {}) {
    return this.executeRouteTableSummaryApi(vpcId, VPC, query);
  }

  @computed
  get awsAccountEntities() {
    const awsAccounts = this.topology.Entities[ACCOUNT] ?? {};
    return Object.values(awsAccounts);
  }
}

export default AWSCloudMapCollection;
