import getBaseQuery from './getBaseQuery';

export default function countryQuery(queryFilters, asn, direction) {
  return {
    ...getBaseQuery({
      queryFilters,
      asn,
      metric: [`Geography_${direction}`],
      direction,
      useAsPathFilter: direction !== 'src'
    }),
    viz_type: 'geoHeatMap',
    depth: 350,
    topx: 40
  };
}
