import { computed, action, observable } from 'mobx';

import Collection from 'core/model/Collection';
import { greekPrefix } from 'core/util/greekPrefixing';

import QueryResultModel from './QueryResultModel';

class QueryResultsCollection extends Collection {
  @observable.ref
  bucket = null;

  // key to compare once chart is rendered with data
  comparisonKey = null;

  prefixableFieldUnits = {};

  defaultSortField = null;

  get model() {
    return QueryResultModel;
  }

  get url() {
    return '';
  }

  get defaultSortState() {
    return {
      direction: 'desc'
    };
  }

  get baseSort() {
    return {
      field: 'isOverlay',
      direction: 'desc'
    };
  }

  @computed
  get rawRows() {
    return this.models.filter((model) => model.get('isRaw'));
  }

  @computed
  get overlayRows() {
    return this.models.filter((model) => model.get('isOverlay'));
  }

  @computed
  get fpaRows() {
    return this.models.filter((model) => model.get('isFpa'));
  }

  @computed
  get nonFpaRows() {
    return this.models.filter((model) => !model.get('isFpa'));
  }

  @computed
  get outsortNonOverlays() {
    return this.unfiltered.filter((model) => !model.get('isOverlay') && !model.get('isFpa') && !model.get('isRaw'));
  }

  @computed
  get nonOverlayRows() {
    return this.models.filter((model) => !model.get('isOverlay') && !model.get('isFpa') && !model.get('isRaw'));
  }

  @computed
  get prefix() {
    // Return map of units -> prefixes
    const prefix = {};

    // Prefixable fields is now map of fieldNames -> units for convenience
    const prefixableFieldNames = Object.keys(this.prefixableFieldUnits);
    const prefixableUnits = prefixableFieldNames.map((name) => this.prefixableFieldUnits[name]);
    const { nonOverlayRows, overlayRows } = this;

    // Find sorted field and prefix on its unit if applicable
    let outsortField = this.sortState.field;
    if (!prefixableFieldNames.includes(outsortField)) {
      outsortField = this.defaultSortField;
    }
    const outsortUnit = this.prefixableFieldUnits[outsortField];

    // For when column being sorted is all undefined's
    let ignoreSortPrefix;

    if (outsortField) {
      const rows = nonOverlayRows.length === 0 ? overlayRows : nonOverlayRows;
      const data = rows.map((model) => model.get(outsortField));

      if (data.filter((d) => d !== undefined).length > 0) {
        prefix[outsortUnit] = greekPrefix(data);
      } else {
        ignoreSortPrefix = true;
      }
    }

    // Prefix on all other prefixable units
    const otherUnits = [...new Set(prefixableUnits.filter((unit) => unit !== outsortUnit || ignoreSortPrefix))];
    otherUnits.forEach((unit) => {
      const fields = prefixableFieldNames.filter((name) => this.prefixableFieldUnits[name] === unit);
      const data = fields.reduce(
        (combined, field) =>
          combined.concat(this.map((model) => model.get(field)).filter((value) => value !== undefined)),
        []
      );

      prefix[unit] = greekPrefix(data);
    });

    return prefix;
  }

  @computed
  get outsortArray() {
    let outsortField = this.sortState.field;
    if (!this.prefixableFields.includes(outsortField)) {
      outsortField = this.defaultSortField;
    }
    return this.nonOverlayRows.map((model) => model.get(outsortField));
  }

  @computed
  get comparingModel() {
    return this.models.find((model) => model.get('comparing'));
  }

  getRawDataRows = (withOverlays = false) => {
    if (withOverlays) {
      return this.models.filter((model) => model.hasRawData);
    }
    return this.models.filter((model) => model.hasRawData && !model.get('isOverlay'));
  };

  getRawData(withOverlays = false) {
    return this.getRawDataRows(withOverlays).map((result) => result.toJS());
  }

  @action
  overwrite(data) {
    const moddedIds = {};

    if (!Array.isArray(data) || !data.length) {
      return;
    }

    data.forEach((row) => {
      const existingRecord = this.find({ key: row.key });
      if (existingRecord) {
        existingRecord.set(row);
        if (existingRecord.hasRawData && !row.rawData && !row.has_forecast) {
          existingRecord.set({ rawData: null });
        }
        moddedIds[existingRecord.id] = true;
      } else {
        moddedIds[this.add(row)[0].id] = true;
      }
    });

    this.remove(this.nonOverlayRows.filter((model) => !moddedIds[model.id]).map((model) => model.id));
  }
}

export default QueryResultsCollection;
