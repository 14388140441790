import { action } from 'mobx';
import { get } from 'lodash';

import Collection from 'core/model/Collection';
import $moduleConfig from 'app/stores/moduleConfig/$moduleConfig';
import InterfaceGroupModel from 'app/stores/interfaceGroup/InterfaceGroupModel';

class ModuleConfigCollection extends Collection {
  get moduleName() {
    throw new Error('moduleName getter not implemented');
  }

  get collectionProperty() {
    throw new Error('collectionProperty getter not implemented');
  }

  get url() {
    throw new Error('url property is not relevant for module configs');
  }

  get interfaceGroupCollection() {
    return null;
  }

  get defaults() {
    return [];
  }

  async save() {
    let promise = Promise.resolve();

    if (this.interfaceGroupCollection) {
      promise = Promise.all(
        this.get()
          .filter((model) => model.interfaceGroup)
          .map((model) => model.interfaceGroup.save({}, { toast: false }))
      );
    }

    return promise.then(() =>
      $moduleConfig.saveModuleConfig({
        module: this.moduleName,
        settings: {
          [this.collectionProperty]: this.serialize()
        }
      })
    );
  }

  @action
  async fetch(options = {}) {
    const { preserveSelection } = options;
    const label = 'fetching';

    this.lastFetched = Date.now();
    this.requestStatus = label;
    this.hasFetched = true;

    if (!preserveSelection) {
      this.clearSelection();
    }

    let promise = $moduleConfig.fetchModuleConfig(this.moduleName, { force: true }).then((settings) => {
      const models = get(settings, this.collectionProperty, this.defaults);
      return this.processData(models);
    });

    if (this.interfaceGroupCollection) {
      promise = Promise.all([promise, this.interfaceGroupCollection.fetch({ force: true })]).then((response) => {
        this.each((model) => {
          const interfaceGroupId = model.get('interfaceGroupId');
          const interfaceGroupModel = interfaceGroupId ? this.interfaceGroupCollection.get(interfaceGroupId) : null;
          if (interfaceGroupModel) {
            model.setInterfaceGroup(interfaceGroupModel);
            interfaceGroupModel.fetch();
          } else {
            const igModel = new InterfaceGroupModel(model.interfaceGroupDetails);
            this.interfaceGroupCollection.add(igModel);
            model.setInterfaceGroup(igModel);
          }
        });

        return response;
      });
    }

    return promise
      .catch((error) => {
        this.error = { label, body: error };
        this.models.clear();
        throw error;
      })
      .finally(() => {
        this.requestStatus = null;
      });
  }
}

export default ModuleConfigCollection;
