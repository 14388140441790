import Model from 'core/model/Model';

class ProviderModel extends Model {
  get defaults() {
    return {
      settings: {}
    };
  }

  get urlRoot() {
    return '/api/ui/ott/provider';
  }
}

export default ProviderModel;
