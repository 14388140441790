import DeckFactory from 'app/stores/decks/DeckFactory';

export default function synthPerformanceDeckFactory() {
  const deck = new DeckFactory('i_synthetics');
  const { widgets, layout, createWidget, newRow } = deck;

  createWidget({
    name: 'testStatusSummary',
    h: 2,
    w: 12,
    smallH: 2
  });

  newRow(10);

  createWidget({
    name: 'incidentLog',
    h: 11,
    w: 12,
    smallW: 4,
    smallH: 11
  });

  newRow(7);

  createWidget({
    name: 'meshes',
    h: 16,
    w: 9,
    smallW: 4,
    smallH: 12
  });

  createWidget({
    name: 'recentlyAddedTests',
    h: 7,
    w: 3,
    smallH: 7
  });

  createWidget({
    name: 'agentsOverview',
    h: 5,
    w: 3,
    smallH: 5
  });

  createWidget({
    name: 'creditUtilization',
    h: 4,
    w: 3,
    smallH: 5
  });

  return {
    widgets,
    layout
  };
}
