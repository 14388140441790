import { get, set, isArray } from 'lodash';
import moment from 'moment';

import safelyParseJSON from 'core/util/safelyParseJson';

import {
  // getStatusMessage,
  getWorstHealth
} from 'app/views/synthetics/utils/syntheticsUtils';
import { DEFAULT_DATETIME_FORMAT } from 'core/util/dateUtils';
import { greekIt } from 'app/util/utils';

// export function findCurrentHealthFromAlarms(alarms = {}) {
//   if (alarms.alarms) {
//     return alarms.alarms.reduce((agg, curr) => {
//       const { severity } = curr;
//       const test_id = curr.dimensionToKeyPart.synthetic;
//       if (!agg[test_id]) {
//         agg[test_id] = severity === 'major' ? 'warning' : severity;
//       } else if (agg[test_id] === 'warning' && severity === 'critical') {
//         agg[test_id] = 'critical';
//       }
//       return agg;
//     }, {});
//   }

//   return {};
// }

// export function combineHealthResults(synBackResults, bgpResults) {
//   bgpResults.health.forEach(b => {
//     const i = synBackResults.health.findIndex(s => s.test_id === b.test_id);
//     if (i !== -1) {
//       const sHealth = synBackResults.health[i].overall_health.health;
//       const bHealth = b.overall_health.health;
//       synBackResults.health[i].overall_health.health = getWorstHealth(sHealth, bHealth);
//     } else {
//       synBackResults.health.push(b);
//     }
//   });

//   return synBackResults;
// }

// agent utils
export function getOptionsForAgents(agents = [], options = {}) {
  const { showAsNameInLabel = false } = options;
  const iteratorFn = (agent) => {
    const { id, agent_type, agent_family, agent_impl, agent_status, asn, as_name, metadata, cluster_id } = agent.get([
      'id',
      'agent_type',
      'agent_family',
      'agent_impl',
      'agent_status',
      'asn',
      'as_name',
      'metadata',
      'cluster_id'
    ]);

    return {
      ...metadata,
      agent_impl,
      agent_status,
      value: id,
      label: showAsNameInLabel ? `${agent.label} - ${as_name}` : agent.label,
      labels: agent.labels.map((label) => label.get()), // labels AKA tags
      agent_family,
      agent_type,
      icon: agent.icon,
      asFormatted: agent.asFormatted,
      locationInfo: agent.locationInfo,
      site_name: agent.siteDisplayName,
      status: agent.status,
      cluster_id,
      asn,
      as_name
    };
  };
  if (Array.isArray(agents)) {
    return agents.map((agent) => iteratorFn(agent));
  }
  return iteratorFn(agents);
}

export function getFlowQuery({
  agent,
  targetAgent,
  test,
  flowFilters = [],
  metric,
  lookbackSeconds,
  startDate,
  endDate
}) {
  let query_title = test && `Traffic with ${test.get('display_name')}`;
  const filtersToInject = [...flowFilters];

  if (agent && !targetAgent && agent.get('site.title')) {
    filtersToInject.push({
      filterField: 'i_device_site_name',
      operator: '=',
      filterValue: agent.get('site.title')
    });
  }

  if (agent && targetAgent && agent.get('site.title') && targetAgent.get('site.title')) {
    const agentSiteTitle = agent.get('site.title');
    const targetAgentSiteTitle = targetAgent.get('site.title');
    filtersToInject.push(
      {
        filterField: 'kt_src_site_title',
        operator: '=',
        filterValue: agentSiteTitle
      },
      {
        filterField: 'kt_dst_site_title',
        operator: '=',
        filterValue: targetAgentSiteTitle
      }
    );
    query_title = `Traffic between ${agentSiteTitle} and ${targetAgentSiteTitle}`;
  }

  return {
    all_devices: true,
    aggregateTypes: ['p95th_bits_per_sec'],
    aggregateThresholds: {
      p95th_bits_per_sec: 0,
      p95th_pkts_per_sec: 0
    },
    customAsGroups: false,
    depth: 100,
    metric: metric || [],
    mirror: true,
    mirrorUnits: true,
    sync_axes: true,
    outsort: 'p95th_bits_per_sec',
    query_title,
    lookback_seconds: lookbackSeconds || 0,
    starting_time: startDate ? moment.unix(startDate).utc().format(DEFAULT_DATETIME_FORMAT) : startDate,
    ending_time: endDate ? moment.unix(endDate).utc().format(DEFAULT_DATETIME_FORMAT) : endDate,
    show_overlay: false,
    show_total_overlay: false,
    topx: 8,
    units: ['bytes'],
    viz_type: 'stackedArea',
    aggregates: [
      {
        value: 'p95th_bits_per_sec',
        column: 'f_sum_both_bytes',
        fn: 'percentile',
        label: 'Bits/s Sampled at Ingress + Egress 95th Percentile',
        rank: 95,
        unit: 'bytes',
        group: 'Bits/s Sampled at Ingress + Egress',
        origLabel: '95th Percentile',
        sample_rate: 1,
        raw: true,
        name: 'p95th_bits_per_sec'
      }
    ],
    filters: {
      connector: 'All',
      filterGroups: [
        {
          connector: 'All',
          filters: filtersToInject,
          saved_filters: [],
          filterGroups: []
        }
      ]
    }
  };
}

export const flowBasedTypeMap = {
  asn: { metric: 'AS_src', filterField: 'src_as', bidiFilterField: 'src|dst_as', zeroValue: '0' },
  cdn: { metric: 'src_cdn', filterField: 'src_cdn', bidiFilterField: 'src|dst_cdn', zeroValue: '' },
  country: { metric: 'Geography_src', filterField: 'src_geo', bidiFilterField: 'src|dst_geo', zeroValue: '-' },
  region: {
    metric: 'src_geo_region',
    filterField: 'src_geo_region',
    bidiFilterField: 'src|dst_geo_region',
    zeroValue: '-'
  },
  city: { metric: 'src_geo_city', filterField: 'src_geo_city', bidiFilterField: 'src|dst_geo_city', zeroValue: '-' }
};

export function getTopXQuery({ type }) {
  const typeMap = flowBasedTypeMap[type];
  return getFlowQuery({
    flowFilters: [
      {
        filterField: 'i_src_network_bndry_name',
        operator: '=',
        filterValue: 'external'
      },
      {
        filterField: typeMap.filterField,
        operator: '<>',
        filterValue: typeMap.zeroValue
      }
    ],
    metric: [typeMap.metric],
    lookbackSeconds: 3600
  });
}

export function getTopSitesQuery({ type, value }) {
  return getFlowQuery({
    flowFilters: [
      {
        filterField: 'i_src_network_bndry_name',
        operator: '=',
        filterValue: 'external'
      },
      {
        filterField: flowBasedTypeMap[type].bidiFilterField,
        operator: '=',
        filterValue: value
      }
    ],
    metric: ['i_device_site_name'],
    lookbackSeconds: 3600
  });
}

export function getTrafficChartData(activeBuckets) {
  const [inbound, outbound] = activeBuckets;
  if (inbound.fullyLoaded && outbound.fullyLoaded) {
    return activeBuckets.reduce(
      (acc, curr, i) => {
        const type = i === 0 ? 'Inbound' : 'Outbound';
        const rows = curr.queryResults.nonOverlayRows;
        const [query = null] = rows;
        let heading = `No ${type} Traffic`;
        if (query !== null) {
          acc.visible = true;
          heading = `${greekIt(query.get('p95th_bits_per_sec'), { fix: 0, suffix: 'bps' }).displayFull} ${type}`;
          const flowData = query.get('rawData').both_bits_per_sec.flow;
          acc.flowData[type.toLowerCase()] = flowData.map((arr) => {
            arr.splice(2, 1);
            arr[1] = Math.round((arr[1] + Number.EPSILON) * 100) / 100;
            return arr;
          });
        } else {
          acc.flowData[type.toLowerCase()] = null;
        }
        acc.heading = i === 0 ? heading : `${acc.heading}, ${heading}`;
        return acc;
      },
      {
        visible: false,
        heading: '',
        flowData: {}
      }
    );
  }
  return null;
}

export function getTrafficFlowQuery(test, options) {
  const { $syn, startDate, endDate, flowFilters, agent_id, target } = options;

  const flowQuery = getFlowQuery({
    agent: agent_id && ($syn.agents.get(agent_id) || null),
    targetAgent: target && ($syn.agents.get(target) || null),
    test,
    startDate,
    endDate,
    flowFilters
  });

  if (
    flowFilters &&
    flowFilters.length &&
    flowFilters.filter(
      (filter) => filter.filterField === 'inet_src_addr' || filter.filterField === 'i_dst_network_bndry_name'
    ).length === 2
  ) {
    flowQuery.filters.connector = 'Any';
    flowQuery.filters.filterGroups = flowQuery.filters.filterGroups.reduce((acc, curr) => {
      acc.push({
        ...curr,
        filters: [...curr.filters].map((filter) => {
          if (filter.filterField === 'i_dst_network_bndry_name') {
            return {
              ...filter,
              filterField: 'simple_trf_prof',
              filterValue: 'inbound'
            };
          }

          return filter;
        })
      });

      acc.push({
        ...curr,
        filters: [...curr.filters].map((filter) => {
          if (filter.filterField === 'i_dst_network_bndry_name') {
            return {
              ...filter,
              filterField: 'simple_trf_prof',
              filterValue: 'outbound'
            };
          }

          return filter;
        })
      });

      acc.push({
        ...curr,
        filters: [...curr.filters].map((filter) => {
          if (filter.filterField === 'i_dst_network_bndry_name') {
            return {
              ...filter,
              filterField: 'simple_trf_prof',
              filterValue: 'internal'
            };
          }

          return filter;
        })
      });

      return acc;
    }, []);
  }

  return flowQuery;
}

export function getFilenameFromPath({ path }) {
  const regex = /([\w\d_-]*)\.?[^\\/]*$/; // Get file name without extension
  const [extension, filename] = path?.match(regex) || [];
  return filename || extension || path;
}

// export function getStatusMessageFromHttpMetricsTs({ httpMetricsTs, resultTimeMs }) {
// const selectedMetric = httpMetricsTs.find(({ time }) => time === `${resultTimeMs / 1000}`) || {};
// const [data = {}] = safelyParseJSON(selectedMetric.data) || [];
// const { statusMessage, statusEncoding } = data;
// // Transaction Tests Error Reasons
// return getStatusMessage(statusMessage, statusEncoding);
// }

export function getS3Files({ measurement, path }) {
  const files = get(measurement, `data[0].s3_data.${path}`);
  if (isArray(files)) {
    return files.map((file) => ({
      key: file?.path,
      name: getFilenameFromPath({ path: file?.path }) || 'N/A',
      path: `/api/ui/synthetics/tests/results/object/${file?.path}`,
      time: +(measurement?.time || 0) * 1000,
      error: !file?.path
    }));
  }
  return null;
}

export function getTarget(test, target, target_agent) {
  if (test.isHttp || test.isPageLoad || test.isHostname) {
    return test.get('config.target').value;
  }

  // In mesh and agent to agent tests, we want the target be a target_agent
  // target agent is an array, but we only want the first element
  if ((test.isMesh || test.isAgentTest) && !!target_agent && target_agent.length > 0) {
    return target_agent[0];
  }

  if (test.isDns) {
    // we dont use test.get('config.target') because is returns the config.target.value, but we need to use the resolver value
    // that is passed from the table
    return target;
  }

  if (test.isTransaction) {
    return 'transaction';
  }

  return target;
}

// rename this something agentHealthTs from full test results
export function processTestResults(test, results) {
  const agentKeys = Object.keys(results.agents);
  const taskKeys = Object.keys(results.tasks);
  const healthTsKeys = Object.keys(results.health_ts);
  const agentHealthTs = {};

  // for every timestamp
  for (let i = 0; i < healthTsKeys.length; i += 1) {
    const tsKey = healthTsKeys[i];
    const ts = results.health_ts[tsKey];
    const { tasks } = ts;

    // for every task
    for (let j = 0; j < taskKeys.length; j += 1) {
      const taskId = taskKeys[j];
      const task = results.tasks[taskId];
      const hasPing = !!(task.task.ping || task.task.knock);
      const hasHttp = !!(task.task.http || task.task.page_load || task.task.transaction);
      const hasDns = !!(task.task.dns || task.task.dns_sec);
      const hasThroughput = !!task.task.throughput;
      let target = getTarget(test, task.target, task.target_agent);

      const { agents } = tasks[taskId] || {};
      if (agents) {
        // for every agent
        for (let k = 0; k < agentKeys.length; k += 1) {
          const agentId = agentKeys[k];
          const measurement = agents[agentId];

          if (!agentHealthTs[agentId]) {
            agentHealthTs[agentId] = {
              targets: {},
              overall: {}
            };
          }

          if (measurement) {
            if (!agentHealthTs[agentId].overall[tsKey]) {
              agentHealthTs[agentId].overall[tsKey] = measurement.overall_health;
            } else {
              agentHealthTs[agentId].overall[tsKey] = getWorstHealth(
                measurement.overall_health,
                agentHealthTs[agentId].overall[tsKey]
              );
            }

            if (test.isDns) {
              if (hasDns) {
                target = task.task.dns?.resolver || task.task.dns_sec?.resolver;
              } else if (hasPing) {
                target = task.task.knock?.target || task.task.ping?.target;
              }
            }
            if (hasThroughput) {
              target = task.task.throughput.target_agent_id;
            }

            if (target) {
              if (!agentHealthTs[agentId].targets[target]) {
                agentHealthTs[agentId].targets[target] = {};
              }

              if (!agentHealthTs[agentId].targets[target].overall) {
                agentHealthTs[agentId].targets[target].overall = {};
              }

              if (!agentHealthTs[agentId].targets[target].overall[tsKey]) {
                agentHealthTs[agentId].targets[target].overall[tsKey] = measurement.overall_health;
              } else {
                agentHealthTs[agentId].targets[target].overall[tsKey] = getWorstHealth(
                  measurement.overall_health,
                  agentHealthTs[agentId].targets[target].overall[tsKey]
                );
              }

              if (hasPing) {
                if (!agentHealthTs[agentId].targets[target].ping) {
                  agentHealthTs[agentId].targets[target].ping = {};
                }

                agentHealthTs[agentId].targets[target].ping[tsKey] = measurement;
              }

              if (hasDns) {
                if (!agentHealthTs[agentId].targets[target].dns) {
                  agentHealthTs[agentId].targets[target].dns = {};
                }

                agentHealthTs[agentId].targets[target].dns[tsKey] = measurement;
              }

              if (hasHttp) {
                if (!agentHealthTs[agentId].targets[target].http) {
                  agentHealthTs[agentId].targets[target].http = {};
                }

                measurement.data = safelyParseJSON(measurement.data);
                measurement.screenshots = getS3Files({ measurement, path: 'screenshot' });
                measurement.har = getS3Files({ measurement, path: 'har' });
                agentHealthTs[agentId].targets[target].http[tsKey] = measurement;
              }
              if (hasThroughput) {
                if (!agentHealthTs[agentId].targets[target].throughput) {
                  agentHealthTs[agentId].targets[target].throughput = {};
                }

                agentHealthTs[agentId].targets[target].throughput[tsKey] = measurement;
              }
            }
          }
        }
      }
    }
  }

  return agentHealthTs;
}

export function enrichTracerouteLookups(lookups, $syn) {
  const agentIDs = Object.keys(lookups.agents || []);

  return agentIDs.reduce((acc, id) => {
    if (id) {
      const lookup = acc.agents[id];
      const agent = $syn.agents.get(id);

      if (lookup && agent) {
        if (agent.iconUrl) {
          acc.agents[id].agent_icon = agent.iconUrl;
        }
        if (agent.icon) {
          acc.agents[id].icon = agent.icon;
        }
      }
    }

    return acc;
  }, lookups);
}

export function processTraceResults(traceResults, targetAgentId, targetHostname, $syn) {
  const { results } = traceResults;
  const { trace_ts } = results;
  const tsKeys = Object.keys(trace_ts);
  const agentTraceResults = {};

  for (let i = 0; i < tsKeys.length; i += 1) {
    const ts = tsKeys[i];
    const { tasks } = trace_ts[ts];
    const taskKeys = Object.keys(tasks);

    for (let j = 0; j < taskKeys.length; j += 1) {
      const taskId = taskKeys[j];
      const { agents } = tasks[taskId];
      const agentKeys = Object.keys(agents);

      for (let k = 0; k < agentKeys.length; k += 1) {
        const agentId = agentKeys[k];
        const { target_ip } = agents[agentId];

        if (!agentTraceResults[agentId]) {
          agentTraceResults[agentId] = { targets: {} };
        }

        if (!agentTraceResults[agentId].targets[target_ip]) {
          agentTraceResults[agentId].targets[target_ip] = {};
        }

        agentTraceResults[agentId].targets[target_ip][ts] = agents[agentId];

        // targetAgentId should be set in agentToAgent tests as well as mesh test details pages
        if (targetAgentId && !agentTraceResults[agentId].targets[targetAgentId]) {
          agentTraceResults[agentId].targets[targetAgentId] = {};
        }
        // If targetAgentId is present, filter result to ONLY the target_ip's associated with the targetAgentId.
        if (
          targetAgentId &&
          `${$syn.agents.getTargetAgentsByIps([agents[agentId].target_ip])?.[0]?.id}` === targetAgentId
        ) {
          agentTraceResults[agentId].targets[targetAgentId][ts] = agents[agentId];
        }

        // targetHostname is only set on url tests that have trace data. Hostname, PageLoad, and URL tests.
        if (targetHostname) {
          if (!agentTraceResults[agentId].targets[targetHostname]) {
            agentTraceResults[agentId].targets[targetHostname] = {};
          }

          agentTraceResults[agentId].targets[targetHostname][ts] = agents[agentId];
        }
      }
    }
  }

  return agentTraceResults;
}

/*
Merges aggregate syngest results that contain averaged values
with min/max values determined from the full syngest resultset

This is returned in a format compatible with the AgentResultsState hydration routine

In this scheme, we have:
  - AgentResultsState at the root, followed by a map of
  - AgentTestResultsState, keyed by agent id, followed by a map of
  - AgentTargetTestResultsState, keyed by target id

It's at the AgentTargetTestResultsState level where we have the data that feeds subtest results
for things like time series charts (latency, jitter, packet loss)

The aggregates returned here will be used at that same level
*/
export function aggregateSubtestResults({ aggregateResults = {}, testResults = {} } = {}) {
  const hasAvgAggregateResults = Object.keys(aggregateResults).length > 0;
  const aggregates = {};

  if (hasAvgAggregateResults) {
    // get the agent ids from the syngest results
    const agentIds = Object.keys(testResults);

    agentIds.forEach((agentId) => {
      /*
      seed an entry by agent id to keep their targets
      hierarchy is now:

      <agentId>
        targets
    */
      if (!aggregates[agentId]) {
        aggregates[agentId] = { targets: {} };
      }

      // get all the target ids for the current agent
      const targetIds = Object.keys(testResults[agentId]?.targets || {});

      targetIds.forEach((targetId) => {
        /*
        seed an entry for the agent's targets, keyed by target id
        hierarchy is now:

        <agentId>
          targets
            <targetId>
      */
        if (!aggregates[agentId].targets[targetId]) {
          aggregates[agentId].targets[targetId] = {};
        }

        // get all the task types for the agent-target pair, leaving 'overall' out of the equation
        const taskTypes = Object.keys(testResults[agentId].targets?.[targetId] || {}).filter(
          (taskType) => taskType !== 'overall'
        );

        taskTypes.forEach((taskType) => {
          /*
          seed an entry for the task type
          hierarchy is now:

          <agentId>
            targets
              <targetId>
                <taskType>
        */
          if (!aggregates[agentId].targets[targetId][taskType]) {
            aggregates[agentId].targets[targetId][taskType] = {};
          }

          // now that we're within a task, we can process the health results
          // start with the aggregate which will have the avg

          /*
          get the average aggregates, we can use those to seed our metrics below
          this will look something like this:

          {
            avg_latency: 61476,
            avg_jitter: 0,
            packet_loss: 0
          }
        */
          const avgAggregateResult = aggregateResults?.[taskType] || {};

          /*
            our average aggregate already sniffed out the interesting metrics in the service call
            interrogate the keys of the average aggregate to get things set up

            hierarchy is now:

            <agentId>
              targets
                <targetId>
                  <taskType>
                    <metric>
                      avg
                      min
                      max
          */
          Object.keys(avgAggregateResult).forEach((metric) => {
            // set up the metrics for min/max/avg
            if (!aggregates[agentId].targets[targetId][taskType][metric]) {
              aggregates[agentId].targets[targetId][taskType][metric] = {
                avg: avgAggregateResult[metric] || 0,
                min: Number.MAX_VALUE, // sets a ceiling, sure to be replaced
                max: 0 // sets a minimum floor
              };
            }

            // now that we have a path from agentId -> targetId -> task -> metric, we can process the min/max results directly
            // we'll take the values of the timestamped health data and determine min and max values
            const minMaxResults = Object.values(testResults[agentId].targets[targetId][taskType]);
            minMaxResults.forEach((result) => {
              // set up 'setter' paths for agent->target->task->metric->min/max
              // we're careful to wrap the target in quotes to avoid issues with things like url targets
              const metricPath = `${agentId}.targets['${targetId}'].${taskType}.${metric}`;
              const minMetricPath = `${metricPath}.min`;
              const maxMetricPath = `${metricPath}.max`;

              // set the min and max
              set(aggregates, minMetricPath, Math.min(result[metric], get(aggregates, minMetricPath)));
              set(aggregates, maxMetricPath, Math.max(result[metric], get(aggregates, maxMetricPath)));
            });
          });
        });
      });
    });
  }

  return aggregates;
}

/*
  Returns data necessary to hydrate the Path View panel in test results.
  The output can be optionally filtered by agent ids, necessary for handling changes
  in the path hop graph based on filter form selections.
*/
export function getTraceroutes({ selectedAgentIds, traceResults = {} }) {
  const traceroutes = [];
  const { trace_ts = {} } = traceResults?.results || {};
  const tsKeys = Object.keys(trace_ts);

  for (let i = 0; i < tsKeys.length; i += 1) {
    const ts = +tsKeys[i];
    const { tasks, count, distance, hopCount } = trace_ts[ts];
    const taskKeys = Object.keys(tasks);
    const traceroute = {
      count,
      distance,
      hopCount: hopCount.total,
      time: +ts * 1000,
      actualHopCount: 0,
      actualDistance: 0
    };
    const traces = [];

    for (let j = 0; j < taskKeys.length; j += 1) {
      const taskId = +taskKeys[j];
      const { agents } = tasks[taskId];
      let agentKeys = Object.keys(agents);

      if (selectedAgentIds) {
        agentKeys = agentKeys.filter((agentId) => selectedAgentIds.includes(+agentId));
      }

      for (let k = 0; k < agentKeys.length; k += 1) {
        const agentId = +agentKeys[k];
        const agent = agents[agentId];
        const trace = {
          agentId,
          agent_ip: '::',
          hopCount: agent.hopCount,
          probes: agent.probes,
          target_ip: agent.target_ip
        };

        traces.push(trace);

        // agent.hopCount is the total hop count across all probes
        // this accumulates hop count and distance using the average value of a single probe from an agent
        traceroute.actualHopCount += agent.count.average;
        traceroute.actualDistance += agent.distance.average;
      }

      traceroute.traces = traces;
    }

    traceroutes.push(traceroute);
  }

  return traceroutes;
}
