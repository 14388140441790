import { groupBy } from 'lodash';
import { computed } from 'mobx';
import Collection from 'core/model/Collection';
import api from 'core/util/api';
import $devices from 'app/stores/device/$devices';
import { mergeHealthStates } from 'app/views/hybrid/utils/health';
import TopologyHealthModel from './TopologyHealthModel';

const descriptions = {
  device_performance_cpu_busy: '# device(s) with high CPU utilization',
  device_performance_mem_in_use: '# device(s) with high memory utilization',
  interface_availability_operstatus: '# interface(s) with errors',
  interface_availability_down: '# interface(s) down',
  interface_performance_inoctets: '# interface(s) with high inbound utilization',
  interface_performance_outoctets: '# interface(s) with high outbound utilization'
};

function getDescription(type, num) {
  const description = descriptions[type] || `# ${type}`;
  return description.replace('#', num).replace('(s)', num !== 1 ? 's' : '');
}

function getDeviceData(device_id) {
  const device = $devices.deviceSummariesById[device_id];

  if (device) {
    const { device_name, device_type, label: device_labels, site_id, title: site_name } = device;
    return { device_id: Number(device_id), device_name, device_type, device_labels, site_id, site_name };
  }

  return null;
}

export default class TopologyHealthCollection extends Collection {
  constructor(topology, { doLookups, ...options } = {}) {
    const data = [];

    if (topology) {
      Object.entries(topology.health.devices || {}).forEach(([device_id, deviceHealth]) => {
        if (deviceHealth.overall_state !== 'GOOD') {
          const deviceData = getDeviceData(device_id);

          if (deviceData) {
            deviceHealth.checks.forEach((check) => {
              if (check.state !== 'GOOD' && check.check_name !== 'device_rollup') {
                data.push({ ...deviceData, check, level: 'device' });
              }
            });
          }
        }
      });

      Object.entries(topology.health.interfaces || {}).forEach(([device_id, interfaces]) => {
        const deviceData = getDeviceData(device_id);

        if (deviceData) {
          Object.entries(interfaces).forEach(([snmp_id, interfaceHealth]) => {
            if (interfaceHealth.overall_state !== 'GOOD') {
              interfaceHealth.checks.forEach((check) => {
                if (check.state !== 'GOOD') {
                  data.push({ ...deviceData, snmp_id: Number(snmp_id), check, level: 'interface' });
                }
              });
            }
          });
        }
      });
    }

    super(data, options);

    if (doLookups) {
      this.lookupInterfaceDescriptions();
    }
  }

  get model() {
    return TopologyHealthModel;
  }

  get filterFieldWhitelist() {
    return new Set([
      'displayName',
      'check.state',
      'check.check_name',
      'device_id',
      'device_name',
      'site_id',
      'site_name',
      'snmp_id',
      'interface_description'
    ]);
  }

  get defaultGroupBy() {
    return 'site_name';
  }

  get defaultSortState() {
    return { field: 'site_name', direction: 'asc' };
  }

  get secondarySort() {
    return { field: 'check.state', direction: 'desc' };
  }

  @computed
  get overallState() {
    const states = new Set(['GOOD']);

    this.models.forEach((model) => {
      states.add(model.get('check.state'));
    });

    return mergeHealthStates(...states);
  }

  @computed
  get byState() {
    const states = {};

    this.models.forEach((model) => {
      const state = model.get('check.state');

      if (!states[state]) {
        states[state] = 0;
      }

      states[state] += 1;
    });

    return states;
  }

  @computed
  get byType() {
    const byType = groupBy(this.models, (model) => model.normalizedCheckName);

    Object.keys(byType).forEach((type) => {
      const models = byType[type];

      byType[type] = {
        type,
        length: models.length,
        description: getDescription(type, models.length),
        collection: new TopologyHealthCollection(null, { models }),
        models
      };
    });

    return byType;
  }

  @computed
  get numSites() {
    const siteIds = new Set();

    this.models.forEach((model) => {
      siteIds.add(model.get('site_id'));
    });

    return siteIds.size;
  }

  getIssues(filter = {}, options = {}) {
    const fields = Object.keys(filter);
    const models = this.models.filter((model) => fields.every((field) => model.get(field) === filter[field]));
    return new TopologyHealthCollection(null, { ...options, models });
  }

  lookupInterfaceDescriptions() {
    const interfaceLookups = {};

    this.models.forEach((model) => {
      if (model.has('snmp_id') && !model.has('interface_description')) {
        const device_id = model.get('device_id');
        const snmp_id = model.get('snmp_id');
        const key = `${device_id}|${snmp_id}`;

        interfaceLookups[key] = interfaceLookups[key] || { device_id, snmp_id, models: [] };
        interfaceLookups[key].models.push(model);
      }
    });

    Object.values(interfaceLookups).forEach(({ device_id, snmp_id, models }) => {
      api
        .get(`/api/ui/lookups/interfaces?device_id=${device_id}&snmp_id=${snmp_id}`)
        .then((results) => {
          const intf = results[0];

          if (intf) {
            models.forEach((model) => {
              model.set('interface_description', intf.interface_description);
            });
          }
        })
        .catch((error) => {
          console.error(`Error fetching interface ${device_id}:${snmp_id}`, error);
        });
    });
  }
}
