import { action, observable } from 'mobx';
import { showErrorToast, showSuccessToast } from 'core/components/toast';
import api from 'core/util/api';
import $auth from 'app/stores/$auth';
import OtpCollection from './OtpCollection';
import UserCollection from './UserCollection';

class UserStore {
  collection = new UserCollection();

  @observable
  fullUserProfile = {};

  @observable
  resettingApiToken = false;

  @observable
  disablingTotp = false;

  @observable
  otpauth_url;

  @observable
  totpSecret;

  otps = new OtpCollection();

  @action
  fetchUserProfile() {
    return api.get('/api/ui/profile/full').then((profile) => {
      // backwards compatibility
      if (!profile.themeSetting) {
        profile.themeSetting = profile.settings?.setup?.darkThemeEnabled ? 'dark' : 'light';
      }
      this.fullUserProfile = profile;
    });
  }

  @action
  updateUserProfile(user, options) {
    const { toast = true, label = 'User Profile' } = options || {};

    return api.put('/api/ui/user/profile', { data: user }).then(
      () => {
        this.fullUserProfile = { ...this.fullUserProfile, ...user };
        $auth.activeUser = { ...($auth.activeUser || {}), ...user };
        if (toast) {
          showSuccessToast(`${label} updated successfully`);
        }
      },
      (error) => {
        showErrorToast(`${label} could not be saved at this time`);
        console.error('Error saving user profile', error);
      }
    );
  }

  get companyHasSuperAdmins() {
    return this.collection.models.some((m) => m.get('role') === 'Super Administrator');
  }

  @action
  updateUserEmail = (token) => {
    const data = { token };

    return api.post('/api/ui/profile/updateUserEmail', { showErrorToast: false, data, rawResponse: true }).then(
      (res) => {
        const { status } = res;
        return { success: true, status, msg: 'Email updated' };
      },
      (errRes) => {
        const { status } = errRes;
        const msg = (errRes.body && errRes.body.error) || 'Error updating email';
        return { success: false, status, msg };
      }
    );
  };

  @action
  initiatePasswordReset = () =>
    api.post('/api/ui/profile/initiatePasswordReset').then(
      () => {
        showSuccessToast('Password reset initiated. Please check your inbox.');
      },
      (error) => {
        showErrorToast('Unable to initiate password reset. Please try again.');
        console.error('Error initiating password reset', error);
      }
    );

  @action
  resendActivationEmail = () => {
    this.store.$auth.passwordReqInProgress = true;
    return api.post('/api/ui/profile/resendActivationEmail').then(
      () => {
        this.store.$auth.passwordReqInProgress = false;
        showSuccessToast('Activation email resent. Please check your inbox.');
      },
      (error) => {
        this.store.$auth.passwordReqInProgress = false;
        showErrorToast('Unable to resend activation email. Please try again.');
        console.error('Error resending activation email', error);
      }
    );
  };

  @action
  generateTotpSecret = () =>
    api.get('/api/ui/totp/register').then(
      (success) => {
        this.otpauth_url = success.otpauth_url;
        this.totpSecret = success.secret;
      },
      (error) => {
        showErrorToast('TOTP Secret could not be generated. Please try again.');
        console.error('Error generating TOTP secret', error);
      }
    );

  @action
  fetchOtps = () =>
    api.get('/api/ui/totp/').then(
      (data) => {
        this.otps.set(data);
        this.fullUserProfile.totpEnabled = this.otps.hasEnabledOtp;
      },
      (error) => {
        showErrorToast('Totps  could not be retrieved.');
        console.error('Error retrieving Totps', error);
      }
    );

  @action
  registerTotp = (token, isYubi, name) => {
    const data = {
      secret: isYubi ? undefined : this.totpSecret,
      token,
      name,
      type: isYubi ? 'yubi' : 'token'
    };

    return api.post('/api/ui/totp/register', { data, showErrorToast: false }).then(
      (otps) => {
        this.otps.set(otps);
        this.otpauth_url = false;
        this.totpSecret = false;
        this.fullUserProfile.totpEnabled = true;
        this.fullUserProfile.isYubiOtp = !!isYubi;
        showSuccessToast('TOTP Registration was successful');
      },
      (error) => {
        showErrorToast('TOTP Token validation was unsuccessful. Please try again.');
        console.error('Error validating TOTP secret', error);
        throw new Error(error);
      }
    );
  };

  @action
  disableTotp = () => {
    this.disablingTotp = true;

    return api.del(`/api/ui/totp/${$auth.getActiveUserProperty('id')}`).then(
      () => {
        this.fullUserProfile.totpEnabled = false;
        this.fullUserProfile.isYubiOtp = false;
        this.disablingTotp = false;
        this.otps.reset();
        showSuccessToast('TOTP is now disabled.');
      },
      (error) => {
        this.disablingTotp = false;
        showErrorToast('TOTP could not be disabled at this time');
        console.error('Error saving user profile', error);
      }
    );
  };

  @action
  updateTotp(totp, params) {
    return totp.save(params).then(
      action(() => {
        this.fullUserProfile.totpEnabled = this.otps.hasEnabledOtp;
      })
    );
  }

  @action
  disableUserTotps(userId) {
    this.disablingTotp = true;

    return api.post(`/api/ui/totp/disableAllTotps/${userId}`).then(
      (data) => {
        this.otps.reset();
        this.otps.set(data);
        const userModel = this.collection.get(userId);
        if (userModel) {
          userModel.set({ totpEnabled: false });
        }
        if (userId === this.fullUserProfile.id) {
          this.fullUserProfile.totpEnabled = false;
        }

        this.disablingTotp = false;
      },
      (error) => {
        this.disablingTotp = false;
        showErrorToast('TOTP could not be disabled at this time');
        console.error('Error saving user profile', error);
      }
    );
  }

  @action
  resetUserApiToken(userId) {
    this.resettingApiToken = true;

    return api.post(`/api/ui/profile/resetApiKey/${userId}`).then(
      (response) => {
        this.resettingApiToken = false;
        // Only update fullUserProfile if resetting for current user.
        if (userId === $auth.getActiveUserProperty('id')) {
          this.fullUserProfile.user_api_key = response.newApiKey;
        }
        return response.newApiKey;
      },
      (error) => {
        this.resettingApiToken = false;
        showErrorToast('API Token could not be updated');
        console.error('API Token could not be updated', error);
      }
    );
  }

  @action
  inviteDemoUsers = ({ invitees, message }) => {
    const data = { invitees, message };

    return api.post('/api/ui/demo/invite', { data }).then(
      () => {
        showSuccessToast('Invitations sent. Users should receive emails soon.');
      },
      (error) => {
        showErrorToast('Unable to invite users.');
        console.error('Error inviting demo users', error);
      }
    );
  };

  exportPdf = (search = '') => {
    const date = new Date().toISOString().slice(0, 10).replace(/-/g, '');
    this.store.$exports.fetchExport({
      path: '/api/ui/export/exportPage',
      type: 'pdf',
      fileName: `export-users-${date}`,
      exportOptions: { location: `/v4/export/settings/users${search}` }
    });
  };

  exportCsv = (data) => {
    const path = '/api/ui/user/csv';
    const date = new Date().toISOString().slice(0, 10).replace(/-/g, '');
    const fileName = `export-users-${date}`;
    const type = 'csv';
    const options = { path, fileName, type };

    this.store.$exports.addLoadingExport(options);
    return api.post(path, { data, rawResponse: true }).then((response) => {
      this.store.$exports.clearLoadingExport(options);
      this.store.$exports.addPayload(response.text, options);
    });
  };
}

export default new UserStore();
