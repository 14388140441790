import { computed } from 'mobx';
import moment from 'moment';

import Model from 'core/model/Model';
import $dictionary from 'app/stores/$dictionary';
import { formatDuration } from 'core/util/dateUtils';
import { POLICY_APPLICATIONS } from 'shared/alerting/constants';

export default class ActiveMitigationModel extends Model {
  get urlRoot() {
    return '/api/ui/mitigations';
  }

  @computed
  get triggeredByDDoSPolicy() {
    return this.get('policyApplication') === POLICY_APPLICATIONS.DDOS;
  }

  @computed
  get triggeredByCustomInsight() {
    const policyId = this.get('policyID');

    return policyId && !this.triggeredByDDoSPolicy;
  }

  @computed
  get isManual() {
    return this.get('source') === 'manual';
  }

  @computed
  get trigger() {
    return this.isManual ? 'Manual' : this.get('policyName') || this.get('policyID');
  }

  @computed
  get tenantID() {
    const applicationMetadata = this.get('applicationMetadata') || {};
    const { tenantID, type } = applicationMetadata;

    if (tenantID && type === 'subtenant') {
      return tenantID;
    }
    return false;
  }

  @computed
  get platform() {
    const platformName = this.get('platformName');
    const platformId = this.get('platformID');
    return platformName || platformId;
  }

  @computed
  get startDay() {
    const start = this.get('start');
    return moment(start).startOf('day').format('MMM DD, YYYY');
  }

  @computed
  get startWeek() {
    const start = this.get('start');
    const weekEnd = moment(start).endOf('week').format('MMM DD, YYYY');
    const weekStart = moment(start).startOf('week').format('MMM DD, YYYY');
    return `${weekStart} to ${weekEnd}`;
  }

  @computed
  get minDurationRemainingInSeconds() {
    const isActive = this.get('isActive');
    const state = this.get('state');
    const start = this.get('start');
    const source = this.get('source');
    const autoStopTTL = this.get('autoStopTTL') || 0;
    const gracePeriod = parseInt(this.get('gracePeriod')) || 0;
    const policyEvaluationPeriod = parseInt(this.get('policyEvaluationPeriod')) || 0;
    const thresholdInactivityTimeUntilClear = parseInt(this.get('thresholdInactivityTimeUntilClear')) || 0;

    // if inactive then there's no time remaining
    if (!isActive) {
      return 0;
    }

    // For manual mitigations, return the remaining auto stop time or 0
    if (source === 'manual') {
      if (autoStopTTL > 0 && start) {
        const diffInSeconds = moment().diff(moment(start), 'seconds');
        const remainingTTL = diffInSeconds > 0 ? autoStopTTL - diffInSeconds : autoStopTTL;
        return remainingTTL > 0 ? remainingTTL : 0;
      }

      return autoStopTTL;
    }

    // if End Grace then the time remaining is just the grace period
    // TODO: we could get fancy here are subtract the time since the End Grace started.  Will need API to return the current state date/time when fetching the collection
    if (state === 'END_GRACE') {
      return gracePeriod;
    }

    // Otherwise the alert is active and time remaining is grace period + policy evaluation period + threshold inactivity time until clear
    return gracePeriod + policyEvaluationPeriod + thresholdInactivityTimeUntilClear;
  }

  @computed
  get minTimeRemaining() {
    if (this.minDurationRemainingInSeconds === 0) {
      return null;
    }

    return formatDuration(this.minDurationRemainingInSeconds);
  }

  @computed
  get displayState() {
    const state = this.get('state').replace('MANUAL_', '');
    return $dictionary.get(`mitigations.stateLabels.${state}`, this.get('state'));
  }

  // Groups of display states
  @computed
  get status() {
    const state = this.get('state').replace('MANUAL_', '');
    const stateToStatusHash = {
      END_GRACE: 'Active',
      CLEARING: 'Active',
      STARTING: 'Active',
      MITIGATING: 'Active',
      ACK_REQ: 'Waiting',
      END_WAIT: 'Waiting',
      START_WAIT: 'Waiting',
      CLEARING_FAIL: 'Failed',
      STARTING_FAIL: 'Failed',
      ARCHIVED: 'Inactive',
      CLEAR: 'Inactive'
    };

    return stateToStatusHash[state];
  }

  deserialize = (data) => {
    let mitigationData = data;

    if (Array.isArray(data)) {
      [mitigationData] = data;
    }

    // Note: we receive this from the API as seconds but pass it to node (when creating a manual mitigation) as minutes. Node then passes it to the API as seconds.
    mitigationData.autoStopTTL = parseInt(mitigationData.autoStopTTL) || 0;

    return mitigationData;
  };
}
