import { computed, observable } from 'mobx';
import { defaultsDeep, uniqBy } from 'lodash';

import Model from 'core/model/Model';
import $alerting from 'app/stores/alerting/$alerting';
import $insights from 'app/stores/insight/$insights';

import {
  INSIGHT_SEVERITY_LABELS,
  INSIGHT_SEVERITY_COLORS,
  INSIGHT_SEVERITY_RANKS
} from 'app/stores/insight/insightConstants';
import formatMetricValue from 'app/util/formatMetricValue';

import {
  getCapacityMetric,
  getComparisonData,
  getDeserializedDimensionToValue,
  getDimensionKeys,
  getMetrics
} from 'app/stores/insight/insightUtils';
import { getMetricPolicyChartingConfig } from 'app/stores/alerting/policyUtils';
import classNames from 'classnames';
import $capacity from 'app/stores/capacityPlan/$capacity';
import policySerializations from 'app/stores/alerting/policySerializations';
import moment from 'moment';
import { DEFAULT_DATETIME_FORMAT } from 'core/util/dateUtils';
import { POLICY_APPLICATIONS } from 'shared/alerting/constants';
import { getKmetricDimensionLabelForPolicy, getMeasurementModelDataFromPolicy } from 'app/util/policies';

const mappedMetrics = { bps: 'bytes', bytes: 'total_bytes', peak_bps: 'bytes' };
const percentageMetrics = [
  'flows_week_over_week',
  'bps_day_over_day',
  'bps_week_over_week',
  'hopcount_percent_change',
  'last_average_packet_loss_percent',
  'new_average_packet_loss_percent',
  'last_max_packet_loss_percent',
  'new_max_packet_loss_percent'
];
const latencyMetrics = [
  'last_average_latency_without_zero',
  'new_average_latency_without_zero',
  'last_stddev_latency_without_zero',
  'new_stddev_latency_without_zero',
  'last_max_latency',
  'new_max_latency',
  'last_latency',
  'new_latency'
];

const metricLabels = {
  last_average_latency_without_zero: 'Last Week Avg Latency',
  new_average_latency_without_zero: 'New Avg Latency',
  last_stddev_latency_without_zero: 'Last Week Latency Std Dev',
  new_stddev_latency_without_zero: 'New Latency Std Dev',
  last_max_latency: 'Last Week Max Latency',
  new_max_latency: 'New Max Latency',
  last_latency: 'Latency',
  new_latency: 'New Latency',
  last_average_packet_loss_percent: 'Last Week Avg Packet Loss',
  new_average_packet_loss_percent: 'New Avg Packet Loss',
  last_max_packet_loss_percent: 'Last Week Max Packet Loss',
  new_max_packet_loss_percent: 'New Max Packet Loss',
  last_hop_count: 'Hop Count',
  new_hop_count: 'New Hop Count',
  ingress_flow_avg: 'Ingress Flow Average',
  egress_flow_avg: 'Egress Flow Average',
  ingress_snmp_avg: 'Ingress SNMP Average',
  egress_snmp_avg: 'Egress SNMP Average',
  last_agent_count: 'Agent Count',
  last_attempt_count: 'Test Attempts',
  last_error_count: 'Errors',
  last_timeout_count: 'Timeouts',
  last_success_count: 'Successful Tests',
  new_agent_count: 'New Agent Count',
  new_attempt_count: 'New Test Attempts',
  new_error_count: 'New Errors',
  new_timeout_count: 'New Timeouts',
  new_success_count: 'New Successful Tests',
  num_ifaces_in_plan: 'Number of interfaces in plan',
  iface_limit: 'Interfaces Limit'
};

const getCustomDescription = (insightModel) => {
  const insightNameToDescriptionMap = {
    'capacity.errors.maxinterfacesinplan': () => {
      const id = insightModel.dimensionToValue.capacity_plan_id;
      // TODO - refactor to not import $capacity store directly
      const capacityPlanModel = $capacity.collection.find({ id });
      if (!capacityPlanModel) {
        return null;
      }
      const name = capacityPlanModel.get('name');
      const limit = insightModel.getValue('iface_limit').formattedValue;
      const num_ifs = insightModel.getValue('num_ifaces_in_plan').formattedValue;
      return `Plan "${name}" has interface count exceeding the limit (${num_ifs}/${limit})`;
    },
    'security.embargoed_countries': () => {
      const { embargo_countries } = insightModel?.dimensionToValue || {};
      return `${insightModel.definition.description}${embargo_countries ? `: ${embargo_countries}` : ''}`;
    }
  };
  return insightNameToDescriptionMap[insightModel.insightName]?.();
};

// can pass either an actual model or just plain attributes here
export const getDescription = (model) => {
  const definition = model.definition || $insights.getType(model);
  // TODO - the above use of $insights circular import may cause issues for testing. Should consider refactor to remove

  const customDescription = getCustomDescription(model);
  if (customDescription) {
    return customDescription;
  }

  const insight = model.get ? model.get() : model;

  // Description priorities:
  // 1. We have an override.
  if (definition && definition.description && typeof definition.description === 'function') {
    const description = definition.description(insight);

    if (description) {
      return description;
    }
  }
  // 2. We have a plainDescription from the api.
  if (insight.plainDescription) {
    return insight.plainDescription;
  }
  // 3. The definition has a static description.
  if (definition && definition.description) {
    return definition.description;
  }
  // 4. We have nothing.
  return '(no description)';
};

class InsightModel extends Model {
  @observable.shallow
  relatedAlarms = [];

  constructor(options = {}) {
    super(options);

    Object.assign(this, options);
  }

  get insightStore() {
    return this.collection ? this.collection.$insights : this.$insights;
  }

  get urlRoot() {
    return '/api/ui/insights';
  }

  get sortValues() {
    return {
      primaryDimension: () => (this.primaryDimension ? this.primaryDimension.formatted : ''),
      primaryValue: () => (this.primaryValue ? this.primaryValue.value : -1),
      severity: () => INSIGHT_SEVERITY_RANKS[this.get('severity')] || 0,
      severityDisplay: () => INSIGHT_SEVERITY_RANKS[this.get('severity')] || 0,
      creationTime: () => new Date(this.get('creationTime')).getTime(),
      startTime: () => new Date(this.get('startTime')).getTime(),
      endTime: () => (this.get('endTime') ? new Date(this.get('endTime')) : Date.now())
    };
  }

  get severityDisplay() {
    return INSIGHT_SEVERITY_LABELS[this.get('severity')];
  }

  get severityColor() {
    return INSIGHT_SEVERITY_COLORS[this.get('severity')];
  }

  get state() {
    return this.isCustomInsight ? this.get('alerting.state') : 'kentik';
  }

  get ackReq() {
    return this.state === 'ackReq';
  }

  @computed
  get className() {
    return classNames(this.get('insightName'), this.get('dataSourceType'));
  }

  @computed
  get query() {
    return this.insightStore.getInsightQuery(this.get());
  }

  @computed
  get isFrequentPatternAnalysisCompatible() {
    const names = [
      'core.networkHealth.deviceTrafficIncrease',
      'factors.interface.utilization.drop',
      'factors.interface.utilization.spike'
    ];
    return names.includes(this.insightName);
  }

  get isAlarm() {
    // check the dataSourceType but also check the isInisght flag to weed out any "Network Health" insights
    return this.get('dataSourceType') === 'alerting' && !this.get('alerting.isInsight');
  }

  @computed
  get insightName() {
    return this.get('insightName') || '';
  }

  get isCustomInsight() {
    return this.insightName.startsWith('custom.insight');
  }

  @computed
  get isComparisonInsight() {
    return this.get('dataSourceType') === 'comparison';
  }

  get isFlowSnmpInsight() {
    return this.insightName === 'flow_snmp_diff';
  }

  get isCapacityInsight() {
    return this.insightName.startsWith('operate.capacity');
  }

  get isCapacityErrorInsight() {
    return this.insightName.startsWith('capacity.errors.');
  }

  get isFactorialInsight() {
    return this.insightName.startsWith('factors.interface');
  }

  get isSNMPFlowSummaryInsight() {
    return this.insightName === 'summary.snmp.flow.diff';
  }

  get isTransitShiftInsight() {
    return this.insightName === 'test.edge.transitasnshift.ksql';
  }

  get isMetricPolicy() {
    return this.get('alerting.policy.application') === POLICY_APPLICATIONS.METRIC;
  }

  get isDDoSPolicy() {
    return this.get('alerting.policy.application') === POLICY_APPLICATIONS.DDOS;
  }

  getIsInsightType(insightType) {
    return this.insightName === insightType;
  }

  getIsLatencyMetric(metric) {
    return latencyMetrics.includes(metric);
  }

  getIsPercentageMetric(metric) {
    return percentageMetrics.includes(metric);
  }

  get alarmId() {
    return this.isAlarm ? this.get('alerting.id') || '' : '';
  }

  get alarmTrigger() {
    return this.isAlarm ? this.get('alerting.baselineAlarmTrigger') || 'ACT_NOT_USED_BASELINE' : null;
  }

  get alarmTriggerText() {
    return this.isAlarm ? $alerting.getBaselineDescription(this.alarmTrigger) : null;
  }

  @computed
  get definition() {
    return this.insightStore.getType(this);
  }

  @computed
  get policyChanged() {
    const lastEdit = this.get('alerting.policy', {}).lastEditTime;
    const startTime = this.get('startTime');

    return lastEdit && startTime ? lastEdit > startTime : false;
  }

  get startTime() {
    return this.get('startTime');
  }

  get endTime() {
    return this.get('endTime');
  }

  get label() {
    return this.definition ? this.definition.label : '(unknown insight)';
  }

  get family() {
    return (this.definition && this.definition.family) || '---';
  }

  get tags() {
    return {
      'data-insightfamily': this.family,
      'data-insightname': this.label
    };
  }

  @computed
  get detailsLink() {
    return this.definition && this.definition.detailsLink ? this.definition.detailsLink(this.get()) : null;
  }

  @computed
  get description() {
    return getDescription(this);
  }

  @computed
  get isEnabled() {
    return this.definition?.status === 'enabled';
  }

  @computed
  get visualization() {
    return this.definition ? this.definition.visualization : null;
  }

  @computed
  get dimensions() {
    const insight = this.get();
    let dimensions = getDimensionKeys(insight);

    if (this.isFlowSnmpInsight) {
      dimensions = ['device_id', 'snmp_id'];
      if (!this.flowSnmpIsNull.ingress) {
        dimensions.push('ingress_corrective_action', 'ingress_detection_context');
      }
      if (!this.flowSnmpIsNull.egress) {
        dimensions.push('egress_corrective_action', 'egress_detection_context');
      }
    }

    dimensions = dimensions
      .map((dimension) => this.insightStore.getDimension(insight, dimension))
      .filter((d) => d && d.value !== undefined && d.value !== '');

    if (this.isFlowSnmpInsight) {
      dimensions = dimensions.filter((d) => d.value !== '0');
    }

    return dimensions;
  }

  @computed
  get primaryDimension() {
    const insight = this.get();
    const dimensions = getDimensionKeys(insight);
    return dimensions.length > 0 ? this.insightStore.getDimension(insight, dimensions[0]) : null;
  }

  @computed
  get dimensionToValue() {
    if (this.isAlarm) {
      return this.get('alerting.dimensionToKeyPart') || {};
    }

    if (this.isComparisonInsight) {
      const { interestingKeys } = getComparisonData(this.get());
      if (interestingKeys.length > 1) {
        return interestingKeys.map((key) => key.dimensionToValue);
      }
      return interestingKeys.reduce((map, key) => Object.assign(map, key.dimensionToValue), {});
    }

    return this.get('dimensionToValue') || {};
  }

  @computed
  get reconDimensions() {
    const dimensionObject = {};
    Object.keys(this.dimensionToValue).forEach((kt_dimension) => {
      const dimension = getKmetricDimensionLabelForPolicy(this.policyObject, kt_dimension);
      dimensionObject[dimension] = this.dimensionToValue[kt_dimension];
    });

    // Use Map to ensure order.
    const dimensions = new Map();
    const orderedDimensions = ['device_name', 'if_interface_name'];
    orderedDimensions.forEach((dimension) => {
      if (dimensionObject[dimension]) {
        dimensions[dimension] = dimensionObject[dimension];
        delete dimensionObject[dimension];
      }
    });

    Object.entries(dimensionObject).forEach(([key, value]) => {
      dimensions[key] = value;
    });

    return dimensions;
  }

  @computed
  get reconDevice() {
    const { device_name } = this.reconDimensions;
    return device_name ? this.insightStore.store.$metrics.deviceModelByName(device_name) : null;
  }

  @computed
  get reconMeasurement() {
    const { measurement } = getMeasurementModelDataFromPolicy(this.policyObject);
    return measurement;
  }

  reconQuery = (options) => {
    const metrics = this.get('alerting.policy.metrics');
    const measurement = this.reconMeasurement;
    const dimensions = Object.keys(this.reconDimensions);
    const filters = {
      connector: 'All',
      filterGroups: [
        {
          name: '',
          named: false,
          connector: 'All',
          not: false,
          autoAdded: '',
          filters: dimensions.map((dimension) => {
            const value = this.reconDimensions[dimension];
            return {
              filterField: dimension,
              metric: '',
              aggregate: '',
              operator: '=',
              filterValue: typeof value === 'string' ? `$_kntq$${value}$_kntq$` : value
            };
          }),
          saved_filters: [],
          filterGroups: []
        }
      ]
    };
    const queryOptions = {
      selectedDimensions: dimensions,
      selectedSortOrder: 'desc',
      selectedLimitCount: 10,
      selectedAggFunc: 'avg',
      selectedWindowSize: 0,
      selectedTransformation: 'none',
      selectedRollupsLimit: 10,
      selectedRollupsAggFunc: ['min', 'avg', 'max', 'last'],
      selectedVisualization: 'area',
      filters
    };
    const params = { metrics, measurement, queryOptions };
    return this.insightStore.store.$metrics.getFullMetricsQuery(defaultsDeep(options, params));
  };

  @computed
  get metrics() {
    return getMetrics(this.get());
  }

  getValue(metric) {
    const value = this.get(`metricToValue.${metric}`);

    if (this.isAlarm && $alerting.hasMissingValue(this.get('alerting'), metric)) {
      return {
        metric: null,
        value: 'Missing',
        formatted: 'Missing',
        formattedValue: 'Missing',
        formattedMetric: null,
        toString() {
          return this.formatted;
        }
      };
    }

    let formattedValue;
    let formattedMetric;

    if (this.getIsPercentageMetric(metric)) {
      formattedValue = Math.abs(Math.round(value * 100));
      formattedMetric = `% ${value >= 0 ? 'increase' : 'decrease'}`;
    } else if (this.getIsLatencyMetric(metric)) {
      ({ value: formattedValue, metric: formattedMetric } = formatMetricValue(value / 1000, 'latency'));
    } else {
      ({ value: formattedValue, metric: formattedMetric } = formatMetricValue(value, mappedMetrics[metric] || metric));
    }

    if (metricLabels[metric]) {
      formattedMetric = metricLabels[metric];
    }

    const joiner = formattedMetric.startsWith('%') ? '' : ' ';

    return {
      metric,
      value,
      formatted: `${formattedValue}${joiner}${formattedMetric}`,
      formattedMetric,
      formattedValue,
      toString() {
        return this.formatted;
      }
    };
  }

  @computed
  get values() {
    const values = this.metrics.map((metric) => this.getValue(metric));
    return uniqBy(values, ({ formatted }) => formatted);
  }

  @computed
  get avgValues() {
    return this.values.filter(({ metric }) => metric.endsWith('_avg'));
  }

  @computed
  get flowSnmpIsNull() {
    const values = this.values.filter((value) => value.metric.endsWith('_is_null'));
    const valuesAreNull = (dir) => (value) => value.metric.startsWith(dir) && value.value === 1;

    return { ingress: values.some(valuesAreNull('ingress')), egress: values.some(valuesAreNull('egress')) };
  }

  @computed
  get primaryValue() {
    const overTimeMetric = this.metrics.find((m) => m.endsWith('_day_over_day') || m.endsWith('_week_over_week'));

    if (overTimeMetric) {
      return this.getValue(overTimeMetric);
    }

    if (this.isComparisonInsight) {
      return null;
    }

    if (this.isCapacityInsight) {
      const { property } = getCapacityMetric(this.get());
      return this.getValue(property);
    }

    return this.metrics.length > 0 ? this.getValue(this.metrics[0]) : null;
  }

  @computed
  get baseline() {
    return this.isAlarm ? $alerting.getBaseline(this.get('alerting')) : null;
  }

  @computed
  get capacity() {
    return this.isAlarm ? $alerting.getCapacity(this.get('alerting')) : this.insightStore.getCapacity(this);
  }

  @computed
  get threshold() {
    return this.getThreshold();
  }

  @computed
  get thresholds() {
    return this.isAlarm ? $alerting.getThresholds(this.get('alerting')) : [];
  }

  getThreshold(metric = this.metrics[0]) {
    return this.isAlarm ? $alerting.getThreshold(this.get('alerting'), metric) : null;
  }

  setAcknowledged() {
    const alerting = this.get('alerting');

    const newAlerting = { ...alerting, state: 'clear', threshold: { ...alerting.threshold, ackRequired: false } };

    this.set('alerting', newAlerting);
  }

  @computed
  get policyObject() {
    // get access to a deserialized, UI-based version of the contained policy
    return policySerializations.deserializeAll(JSON.parse(JSON.stringify(this.get('alerting.policy'))));
  }

  @computed
  get isUpDownPolicy() {
    return this.policyObject?.applicationMetadata?.type === 'state-change';
  }

  @computed
  get isDeviceUpDownPolicy() {
    return this.isUpDownPolicy && this.policyObject?.applicationMetadata?.subtype === 'devices';
  }

  @computed
  get isInterfaceUpDownPolicy() {
    return this.isUpDownPolicy && this.policyObject?.applicationMetadata?.subtype === 'interfaces';
  }

  @computed
  get isBgpNeighborPolicy() {
    return this.isUpDownPolicy && this.policyObject?.applicationMetadata?.subtype === 'bgp_neighbors';
  }

  getMetricPolicyThresholdChartConfig(vizOptions = {}) {
    const queryOptions = {};

    queryOptions.starting_time = moment
      .utc(this.get('startTime'))
      .subtract(30, 'minutes')
      .format(DEFAULT_DATETIME_FORMAT);
    queryOptions.ending_time = moment.utc(this.get('endTime')).format(DEFAULT_DATETIME_FORMAT);

    return {
      ...getMetricPolicyChartingConfig(this.policyObject.metricConfig, queryOptions),
      ...vizOptions
    };
  }

  deserialize(data) {
    if (data.insight) {
      const { insight, policy, threshold, ...rest } = data;
      insight.id = insight.insightID;
      Object.assign(insight, rest);

      if (insight.alerting) {
        insight.alerting.policy = policy && policy.policy;
        insight.alerting.threshold = threshold;

        insight.dimensionToValue = getDeserializedDimensionToValue(insight.dimensionToValue);
        insight.alerting.dimensionToKeyPart = getDeserializedDimensionToValue(insight.alerting.dimensionToKeyPart);
      }

      if (data.dataExplorerQuery && !insight.dataExplorerQuery) {
        insight.dataExplorerQuery = data.dataExplorerQuery;
      }

      this.insightStore.mergeLookups(data);

      return insight;
    }

    data.id = data.insightID;

    return data;
  }
}

export default InsightModel;
