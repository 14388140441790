import Collection from 'core/model/Collection';

import CommentModel from './CommentModel';

class CommentCollection extends Collection {
  get model() {
    return CommentModel;
  }

  get url() {
    return '/api/ui/comments';
  }

  getAckCommentForEntityById = (entityId, entityType = 'alertAck') =>
    this.models.find((model) => model.get('entityId') === entityId && model.get('entityType') === entityType);

  getAlertComments = (alertModel) =>
    this.models.filter(
      (model) => model.get('entityId') === alertModel.id || model.get('entityId') === alertModel.autoAckId
    );

  getCommentsForEntityById = (entityId) => this.models.filter((model) => model.get('entityId') === entityId);
}

export default CommentCollection;
