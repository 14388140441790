import getBaseQuery from './getBaseQuery';

export default function uniquePrefixesQuery(queryFilters, asn, direction) {
  const directionLabel = direction === 'src' ? 'Source' : 'Destination';

  return {
    ...getBaseQuery({ queryFilters, asn, direction }),
    topx: 8,
    depth: 75,
    viz_type: 'gauge',
    units: [`unique_${direction}_ip`, 'bytes', 'packets'],

    aggregateTypes: [
      `avg_${direction}_route_prefix`,
      `p95th_${direction}_route_prefix`,
      `max_${direction}_route_prefix`,
      'p95th_bits_per_sec',
      'p95th_pkts_per_sec'
    ],
    aggregateThresholds: {
      p95th_bits_per_sec: 0,
      p95th_pkts_per_sec: 0,
      avg_src_route_prefix: 0,
      p95th_src_route_prefix: 0,
      max_src_route_prefix: 0,
      avg_dst_route_prefix: 0,
      p95th_dst_route_prefix: 0,
      max_dst_route_prefix: 0
    },
    aggregates: [
      {
        value: `avg_${direction}_route_prefix`,
        column: `f_hll(inet_${direction}_route_prefix,0.0001)`,
        fn: 'average',
        label: `Unique Route Prefixes ${directionLabel} Average`,
        unit: `unique_${direction}_route_prefix`,
        group: `Unique Route Prefixes ${directionLabel}`,
        origLabel: 'Average',
        sample_rate: 1,
        raw: true,
        name: `avg_${direction}_route_prefix`
      },
      {
        value: `p95th_${direction}_route_prefix`,
        column: `f_hll(inet_${direction}_route_prefix,0.0001)`,
        fn: 'percentile',
        rank: 95,
        label: `Unique Route Prefixes ${directionLabel} 95th Percentile`,
        unit: `unique_${direction}_route_prefix`,
        group: `Unique Route Prefixes ${directionLabel}`,
        origLabel: '95th Percentile',
        sample_rate: 1,
        name: `p95th_${direction}_route_prefix`
      },
      {
        value: `max_${direction}_route_prefix`,
        column: `f_hll(inet_${direction}_route_prefix,0.0001)`,
        fn: 'max',
        label: `Unique Route Prefixes ${directionLabel} Max`,
        unit: `unique_${direction}_route_prefix`,
        group: `Unique Route Prefixes ${directionLabel}`,
        origLabel: 'Max',
        sample_rate: 1,
        name: `max_${direction}_route_prefix`
      },
      {
        value: 'p95th_bits_per_sec',
        column: 'f_sum_both_bytes',
        fn: 'percentile',
        label: 'Bits/s Sampled at Ingress + Egress 95th Percentile',
        rank: 95,
        unit: 'bytes',
        group: 'Bits/s Sampled at Ingress + Egress',
        origLabel: '95th Percentile',
        sample_rate: 1,
        name: 'p95th_bits_per_sec'
      },
      {
        value: 'p95th_pkts_per_sec',
        column: 'f_sum_both_pkts',
        fn: 'percentile',
        rank: 95,
        label: 'Packets/s Sampled at Ingress + Egress 95th Percentile',
        unit: 'packets',
        group: 'Packets/s Sampled at Ingress + Egress',
        origLabel: '95th Percentile',
        sample_rate: 1,
        name: 'p95th_pkts_per_sec'
      }
    ]
  };
}
