import Collection from 'core/model/Collection';
import PingResultModel from './PingResultModel';

class PingResultCollection extends Collection {
  get model() {
    return PingResultModel;
  }

  get defaultSortState() {
    return { field: 'agent', direction: 'asc' };
  }

  get secondarySort() {
    return { field: 'target', value: 'asc' };
  }
}

export default PingResultCollection;
