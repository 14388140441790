import { toJS } from 'mobx';
import api from 'core/util/api';

export function normalizeQueries(queries) {
  return Array.isArray(queries) ? queries : [queries];
}

export function getQueriesForHash(hash, options = {}) {
  const { normalize = true } = options;
  return api.get(`/api/ui/urlHash?key=${hash}`).then((response) => (normalize ? normalizeQueries(response) : response));
}

export function getHashForQueries(queries, options = {}) {
  const { persist = false } = options;
  return api.post(`/api/ui/urlHash${persist ? '/persist' : ''}`, {
    data: { queries: toJS(queries) }
  });
}

export function persistHash(hash) {
  return api.post('/api/ui/urlHash/persistKey', {
    data: { key: hash }
  });
}

/**
 * Gets a hash for an object, optionally merging it with a current hash
 */
export function getHashForObject(val, key, { persist = false } = {}) {
  return api.post('/api/ui/urlHash/merge', {
    data: { val: toJS(val), key, persist }
  });
}

export function getObjectForHash(hash) {
  return api.get(`/api/ui/urlHash?key=${hash}`).then((response) => response);
}

/**
 * Gets an unwrapped, serialized query object for a hash.
 * This assumes the hash will only ever have one bucket, with one query,
 * which means no legacy queries can use this.
 */
export function getQueryForHash(hash) {
  return getQueriesForHash(hash).then((buckets) => {
    const { query } = buckets[0];
    if (query.filters_obj && !query.filters) {
      query.filters = query.filters_obj;
      delete query.filters_obj;
    }
    return query;
  });
}

/**
 * Gets a hash for a single, serialized query with the proper bucket wrapping
 * that Explorer will expect (since it has to deal with legacy queries).
 * @param query query obj
 * @param options Obj with following keys
 *   persist (boolean)
 */
export function getHashForQuery(query, options) {
  const queries = [
    {
      bucket: query.bucket,
      isOverlay: false,
      query
    }
  ];

  return getHashForQueries(queries, options);
}

window.getHashForQueries = getHashForQueries;
