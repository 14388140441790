import { computed, action } from 'mobx';
import { omit, uniqueId } from 'lodash';

import Model from 'core/model/Model';
import $dashboards from 'app/stores/dashboard/$dashboards';
// import $alerts from 'app/stores/$alerts';
// import $savedViews from 'app/stores/$savedViews';
// import { normalizeQueries } from 'app/stores/query/urlHash';
import { getSavedFilters } from 'app/util/filters';

class SavedFilter extends Model {
  get defaults() {
    return {
      filter_level: 'company'
    };
  }

  get urlRoot() {
    return '/api/ui/saved-filters';
  }

  get omitDuringSerialize() {
    return ['isFiltersPopoverOpen'];
  }

  @action
  duplicate = () => {
    this.loadFilters().then(() => {
      const attributes = omit(this.get(), 'id');
      attributes.filter_name = `${this.get('filter_name')} ${uniqueId()}`;
      attributes.filter_level = 'company';
      const dupe = this.collection.build(attributes);
      dupe.save(attributes);
    });
  };

  @action
  handleToggleFiltersPopover = () => this.set({ isFiltersPopoverOpen: !this.get('isFiltersPopoverOpen') });

  @action
  handlePopoverInteraction = (state) => this.set({ isFiltersPopoverOpen: state });

  @action
  loadFilters = () => {
    if (!this.fullyLoaded) {
      return this.fetch();
    }
    return Promise.resolve();
  };

  @computed
  get fullyLoaded() {
    return this.has('filters');
  }

  /**
   * Returns the total number of filters in all FilterGroups
   */
  @computed
  get numFilters() {
    const groups = this.get('filters') && this.get('filters').filterGroups;
    return groups && groups.reduce((acc, curr) => acc + curr.filters.length, 0);
  }

  @computed
  get numGroups() {
    const groups = this.get('filters') && this.get('filters').filterGroups;
    return groups.length;
  }

  @computed
  get isPreset() {
    return this.get('filter_level') === 'global';
  }

  @computed
  get inUse() {
    return (
      (this.policies && this.policies.length > 0) ||
      this.dashboards.length > 0 ||
      (this.savedViews && this.savedViews.length > 0)
    );
  }

  @computed
  get policies() {
    console.error('IMPLEMENT THIS');
    return undefined;
  }

  @computed
  get dashboards() {
    return $dashboards.collection.get().reduce((acc, dashboard) => {
      const query = dashboard.get('query');
      const inUse = getSavedFilters(query.filters).some((filter) => filter.filter_id === this.id);

      if (inUse) {
        const id = dashboard.get('id');
        const dash_title = dashboard.get('dash_title');
        return acc.concat({ id, name: dash_title });
      }

      return acc;
    }, []);
  }

  @computed
  get savedViews() {
    console.error('IMPLEMENT THIS');
    return undefined;
    // return $savedViews.savedViews.reduce((acc, curr) => {
    //   const view = curr.toJS();
    //   const query = normalizeQueries(view.query);
    //   const inUse = query.some(
    //     q => q.query && getSavedFilters(q.query.filters_obj).some(filter => filter.filter_id === this.id)
    //   );

    //   if (inUse) {
    //     return acc.concat({ id: view.id, name: view.view_name });
    //   }

    //   return acc;
    // }, []);
  }

  get messages() {
    return {
      create: `Filter ${this.get('filter_name')} was added successfully`,
      update: `Filter ${this.get('filter_name')} was updated successfully`,
      destroy: `Filter ${this.get('filter_name')} was removed successfully`,
      duplicate: 'Filter duplicated successfully'
    };
  }

  get removalConfirmText() {
    return {
      title: 'Remove Saved Filter',
      text: `Are you sure you want to remove ${this.get('filter_name')}?`
    };
  }
}

export default SavedFilter;
