import React from 'react';
import { action, computed, observable } from 'mobx';
import { Flex, Tag, Text } from 'core/components';
import { showSuccessToast } from 'core/components/toast';
import api from 'core/util/api';
import CustomDimensionCollection from './CustomDimensionCollection';
import CustomDimensionModel from './CustomDimensionModel';
import AsGroupCollection from './AsGroupCollection';

const MARKET_DIMENSIONS = ['kt_src_market', 'kt_dst_market'];
const MARKET_DIMENSION_DIRECTIONS = { kt_src_market: 'src', kt_dst_market: 'dst' };

class CustomDimensionStore {
  collection = new CustomDimensionCollection();

  @observable
  markets = [];

  @observable
  marketDimensions = {};

  @observable
  marketsLoaded = false;

  asGroups = new AsGroupCollection();

  @computed
  get maxDimensionsLimit() {
    return this.store.$auth.getPermission('customDimensions.maxDimensions');
  }

  @computed
  get totalDimensionsCount() {
    return this.collection.models.length;
  }

  @computed
  get isMaxDimensionsReached() {
    return this.totalDimensionsCount >= this.maxDimensionsLimit;
  }

  @computed
  get maxPopulatorsLimit() {
    return this.store.$auth.getPermission('customDimensions.maxPopulators');
  }

  @computed
  get isMaxPopulatorsReached() {
    return this.collection.totalPopulatorsCount >= this.maxPopulatorsLimit;
  }

  @computed
  get totalPopulatorsCount() {
    return this.collection.totalPopulatorsCount;
  }

  @computed
  get isPopulatorsCloseToLimit() {
    // close to limit if there are less than 10% populators left
    const closeEnoughCounter = Math.floor(this.maxPopulatorsLimit * 0.1);
    return (
      this.maxPopulatorsLimit - this.totalPopulatorsCount < closeEnoughCounter &&
      this.maxPopulatorsLimit - this.totalPopulatorsCount > 0
    );
  }

  @computed
  get populatorsRemaining() {
    const result = this.maxPopulatorsLimit - this.totalPopulatorsCount;
    return result <= 0 ? 0 : result;
  }

  @computed
  get customDimensionsRemaining() {
    const result = this.maxDimensionsLimit - this.totalDimensionsCount;
    return result <= 0 ? 0 : result;
  }

  @action
  loadMarketDimension() {
    this.marketsLoaded = false;

    return Promise.all(
      ['src', 'dst'].map((direction) =>
        api
          .get(`/api/ui/markets/${direction}`)
          .then((response) => new CustomDimensionModel(response))
          .then(
            action((dimension) => {
              this.marketDimensions[dimension.get('col_name')] = dimension;
              // populators is paged collection, so set limit 300 to override the default fetch amount.
              dimension.populators.setSortState('id');
              return dimension.populators;
            })
          )
      )
    ).then(
      action(([srcPopulators, dstPopulators]) => {
        this.markets = srcPopulators.map((model) => {
          const name = model.get('value');
          const countries = model.get('country');

          return {
            srcId: model.id,
            dstId: dstPopulators.find({ value: name }).id,
            name,
            countries: countries || []
          };
        });
        this.marketsLoaded = true;
      })
    );
  }

  saveMarket(market) {
    const existingMarket = this.markets.find((m) => m.srcId === market.srcId);
    if (!existingMarket) {
      this.markets.push(market);
    } else {
      Object.assign(existingMarket, market);
    }

    if (Object.keys(this.marketDimensions).length === MARKET_DIMENSIONS.length) {
      // Update src and dest dimensions
      return Promise.all(
        MARKET_DIMENSIONS.map((name) => {
          let populator;
          const idField = `${MARKET_DIMENSION_DIRECTIONS[name]}Id`;

          if (existingMarket) {
            // Update populator
            populator = this.marketDimensions[name].populators.find({
              id: market[idField]
            });
          } else {
            populator = this.marketDimensions[name].populators.forge();
          }

          return populator
            .save(
              {
                value: market.name,
                direction: MARKET_DIMENSION_DIRECTIONS[name],
                country: market.countries || []
              },
              {
                toast: false
              }
            )
            .then(
              action(() => {
                market[idField] = populator.id;
                return true;
              })
            );
        })
      ).then(([srcSuccess, dstSuccess]) => {
        if (srcSuccess && dstSuccess) {
          showSuccessToast(`Custom Geo ${market.name} saved successfully`, {
            title: existingMarket ? 'Custom Geo Updated' : 'Custom Geo Added'
          });
        }
      });
    }

    console.error('No src/dst custom geo dimensions defined');

    return Promise.resolve();
  }

  @computed
  get customDimensionOptions() {
    const { $dictionary } = this.store;
    const [sourceCdMap, destCdMap] = $dictionary.get('chartTypes.Custom', [{}, {}]);

    return this.collection.map((customDimension) => {
      const value = customDimension.get('name');
      const label = customDimension.get('display_name');

      let direction = 'Either';
      if (sourceCdMap[value] === label) {
        direction = 'Source';
      } else if (destCdMap[value] === label) {
        direction = 'Destination';
      }

      return {
        value,
        label: (
          <Flex alignItems="center" overflow="hidden" flex={1}>
            <Text ellipsis>{label}</Text>
            <Tag ml="4px" minimal small>
              {direction}
            </Tag>
          </Flex>
        )
      };
    });
  }

  @action
  deleteMarket(market) {
    return Promise.all(
      MARKET_DIMENSIONS.map((name) => {
        const idField = `${MARKET_DIMENSION_DIRECTIONS[name]}Id`;
        const populator = this.marketDimensions[name].populators.find({ id: market[idField] });
        return populator.destroy();
      })
    ).then(
      action(() => {
        this.markets.remove(market);
      })
    );
  }
}

export default new CustomDimensionStore();
