import { action, autorun, computed, observable } from 'mobx';
import moment from 'moment';
import { get } from 'lodash';

import api, { registerAuthStore } from 'core/util/api';
import { timezone } from 'core/util/dateUtils';
import Socket from 'core/util/Socket';
import { isDev } from 'core/util/env';
import { isGoogleCloud } from '@kentik/ui-shared/util/map';

import { prefixRoute } from 'app/util/URL';
import { DEFAULT_EXPIRED_PASSWORD_DURATION } from 'app/util/constants';
import submitErrorReport from 'core/util/submitErrorReport';

export class AuthStore {
  @observable
  activeUser;

  @observable
  authenticated = false;

  @observable
  authenticating = false;

  @observable
  loadingStatus = '';

  @observable
  isTrialNoticeVisible = false;

  @observable
  isSynOnboarding = false;

  @observable
  loginInterstitialOpen = false;

  @observable
  hideLoginInterstitial = true;

  @observable
  passwordReqInProgress = false;

  @observable
  ssoLookupReqInProgress = false;

  @observable
  showLoginRecaptcha = false;

  @observable
  isEnvironmentModalVisible = false;

  @observable
  openConfig;

  @observable
  twoFactorDelay = 0;

  twoFactorVerifyRequired = false;

  constructor() {
    registerAuthStore(this);
  }

  destroy() {
    this.clearActiveUser();
  }

  // Don't "observe" this or it will reload app in original tab when spoofing to a non-admin user.
  get isAdministrator() {
    return this.getActiveUserProperty('user_level', 0) > 0;
  }

  get isPreciselyAdministrator() {
    return this.getActiveUserProperty('user_level', 0) === 1;
  }

  get isSuperAdministrator() {
    return this.getActiveUserProperty('user_level', 0) >= 2 || this.hasSudo;
  }

  get isPresetCompany() {
    return this.getActiveUserProperty('company_id') === this.store.$dictionary.get('templateDashboardsCID');
  }

  @computed
  get kibanaUrl() {
    return this.openConfig?.kibanaUrl;
  }

  @computed
  get grafanaUrl() {
    return this.openConfig?.grafanaUrl;
  }

  @computed
  get userTimezone() {
    return this.getActiveUserProperty('userTimezone', 'UTC');
  }

  @computed
  get hasSudo() {
    return this.getActiveUserProperty('sudoEnabled') || this.getActiveUserProperty('landlord.sudoEnabled');
  }

  @computed
  get isSudoer() {
    return !this.isDemoUser && (this.hasSudo || this.isSpoofed);
  }

  @computed
  get needsActivation() {
    return this.getActiveUserProperty('needsActivation', false);
  }

  @action
  showEnvironmentModal = () => {
    this.isEnvironmentModalVisible = true;
  };

  @action
  hideEnvironmentModal = () => {
    this.isEnvironmentModalVisible = false;
    if (document.title.startsWith('*** ')) {
      document.title = document.title.substr(4);
    }
  };

  @computed
  get isAbleToDemo() {
    return (isDev || this.currentRegion === 'US') && !this.isDemoUser;
  }

  @computed
  get isAbleToInviteFromDemo() {
    return (
      (isDev || this.currentRegion === 'US') &&
      !!(
        (!this.isSpoofed && this.isAdministrator) ||
        (this.isSpoofed && this.getUnderlyingUserProperty('user_level') >= 1) ||
        (this.isSpoofed && this.getUnderlyingUserProperty('company.company_name').includes('kentik'))
      )
    );
  }

  @computed
  get isDemoUser() {
    return this.isSpoofed && !!this.getActiveUserProperty('user_email', '').match(/demouser-\d+-\d+@kentik.com/);
  }

  getEnvironmentSettings(env) {
    const { $colors } = this.store;
    const companyFullName = this.isDemoUser ? this.spooferCompanyFullName : this.companyFullName;

    const demo = {
      envName: 'Demo',
      envColor: $colors.getDemoEnvironmentColor(),
      envDesc: 'A pre-configured network setup of Kentik owned and operated devices.'
    };

    const prod = { envName: 'Production', envColor: 'primary', envDesc: `Regular ${companyFullName} environment` };

    if (env === 'demo') {
      return demo;
    }
    if (env === 'production') {
      return prod;
    }

    return this.isDemoUser ? demo : prod;
  }

  @computed
  get isAK() {
    return this.getActiveUserProperty('user_email') === 'akagawa@kentik.com';
  }

  @computed
  get isDan() {
    return this.getActiveUserProperty('user_email') === 'drohan@kentik.com';
  }

  @computed
  get isJoe() {
    return this.getActiveUserProperty('user_email') === 'joer@kentik.com';
  }

  @computed
  get isGreg() {
    return this.getUnderlyingUserProperty('id') === '15267';
  }

  @computed
  get isSubtenantUser() {
    return !!this.getActiveUserProperty('userGroup');
  }

  @computed
  get subtenantConfig() {
    return this.getActiveUserProperty('userGroup.config', {});
  }

  @computed
  get isTenantNotificationChannelCreationAllowed() {
    return this.getActiveUserProperty('userGroup.config.allowNotificationChannelCreation');
  }

  @computed
  get isSpoofed() {
    return (
      this.getActiveUserProperty('chfAdmin.id') &&
      this.getActiveUserProperty('id') !== this.getActiveUserProperty('chfAdmin.id')
    );
  }

  @computed
  get isLandlordSpoofed() {
    return (
      this.getActiveUserProperty('landlord.id') &&
      this.getActiveUserProperty('id') !== this.getActiveUserProperty('landlord.id')
    );
  }

  @computed
  get isExpiredTrial() {
    return (
      this.getActiveUserProperty('company.company_status') === 'EXP' &&
      this.isTrial &&
      this.getActiveUserProperty('company.trial_end_date') &&
      moment(this.getActiveUserProperty('company.trial_end_date')).diff(moment()) <= 0
    );
  }

  @computed
  get isActiveTrial() {
    return (
      this.getActiveUserProperty('company.company_status') !== 'EXP' &&
      this.isTrial &&
      this.getActiveUserProperty('company.trial_end_date') &&
      moment(this.getActiveUserProperty('company.trial_end_date')).diff(moment()) > 0
    );
  }

  @computed
  get trialDaysRemaining() {
    if (!this.isActiveTrial) {
      return 0;
    }

    return moment(this.getActiveUserProperty('company.trial_end_date')).diff(moment(), 'days');
  }

  @computed
  get isCompany() {
    return this.getActiveUserProperty('company.company_plan_id') === 'CUSTOMER';
  }

  @computed
  get isCompanyUnderlying() {
    return this.getUnderlyingUserProperty('company.company_plan_id') === 'CUSTOMER';
  }

  @computed
  get isTrial() {
    return this.getActiveUserProperty('company.company_plan_id') === 'TRIAL';
  }

  get isTotpExpired() {
    if (!this.activeUser) {
      return true;
    }

    const user = this.activeUser.chfAdmin || this.activeUser;
    const { last2fa = 0, totpTtl = 300000 } = user;

    return Date.now() - last2fa > totpTtl;
  }

  @computed
  get isInactiveCompany() {
    return this.getActiveUserProperty('company.company_status') === 'INACTIVE';
  }

  @computed
  get isSuspendedCompany() {
    return this.getActiveUserProperty('company.company_status') === 'SUSP';
  }

  @computed
  get activeUserDisplay() {
    if (!this.activeUser) {
      return '';
    }

    const { chfAdmin, user_name, user_full_name, id } = this.activeUser;
    const name = user_full_name || user_name;
    return chfAdmin && chfAdmin.id !== id ? `[Kentik] ${chfAdmin.user_full_name}` : name;
  }

  @computed
  get maxEnabledAlertPolicies() {
    return this.getPermission('alerts.limits.activePolicies') || 120;
  }

  @computed
  get maxAlertPolicies() {
    return this.getPermission('alerts.limits.totalPolicies') || 200;
  }

  @computed
  get snmpEstablished() {
    return this.getActiveUserProperty('company.has_snmp', false);
  }

  // TODO this is not accurate! $interfaceClass has the only accurate lookup but is EXPENSIVE.
  @computed
  get interfacesClassified() {
    return this.getActiveUserProperty('company.interfaces_classified', 0);
  }

  // TODO this is not accurate! $interfaceClass has the only accurate lookup but is EXPENSIVE.
  @computed
  get interfacesClassifiedDisplay() {
    return Math.round(this.interfacesClassified * 100).toString();
  }

  @computed
  get interfaceClassThreshold() {
    return this.getActiveUserProperty('company.interfaces_class_threshold', 0.75);
  }

  // TODO this is not accurate! $interfaceClass has the only accurate lookup but is EXPENSIVE.
  @computed
  get interfaceClassBelowThreshold() {
    return this.interfacesClassified < this.interfaceClassThreshold;
  }

  @computed
  get providerClassBelowThreshold() {
    return false; // TODO: implement backend for provider class
  }

  @computed
  get networkClassEstablished() {
    return this.getActiveUserProperty('company.network_classification_established', false);
  }

  @computed
  get companySettings() {
    return this.getActiveUserProperty('company.settings', {});
  }

  @computed
  get onboardingSettings() {
    return {
      ...this.companySettings.setup,
      ...this.userSettings.setup
    };
  }

  @computed
  get userSettings() {
    const { $colors } = this.store;
    return Object.assign(
      { visualizations: $colors.defaultVisualizationSettings },
      this.getActiveUserProperty('settings', {})
    );
  }

  @computed
  get companyId() {
    return this.getUnderlyingUserProperty('company.id') || this.getUnderlyingUserProperty('company_id');
  }

  @computed
  get companyFullName() {
    return this.getActiveUserProperty(
      'company.company_name_full',
      get(this, 'openConfig.subtenancy.company.company_name_full')
    );
  }

  @computed
  get spooferCompanyFullName() {
    return this.getActiveUserProperty('chfAdmin.company.company_name_full', '');
  }

  @computed
  get currentRegion() {
    return this.openConfig.region;
  }

  @computed
  get envName() {
    return this.openConfig.envName;
  }

  @computed
  get sentryUrl() {
    return this.getActiveUserProperty('company.sentryDSN');
  }

  getActiveUserProperty(property, defaultValue) {
    return get(this.activeUser, property, defaultValue);
  }

  getUnderlyingUserProperty(property, defaultValue) {
    if (this.isSpoofed) {
      return this.getActiveUserProperty(`chfAdmin.${property}`, defaultValue);
    }

    if (this.isLandlordSpoofed) {
      return this.getActiveUserProperty(`landlord.${property}`, this.getActiveUserProperty(property, defaultValue));
    }

    return this.getActiveUserProperty(property, defaultValue);
  }

  @computed
  get isExpiredPassword() {
    const setDate = Date.parse(this.getActiveUserProperty('password_reset_date'));
    const authenticationType = this.getActiveUserProperty('authenticationType');
    const totpEnabled = this.getActiveUserProperty('totpEnabled');
    const expiredDuration =
      this.getActiveUserProperty('company.settings.expired_password_duration_millis') ||
      DEFAULT_EXPIRED_PASSWORD_DURATION;

    return (
      !this.store.$app.isExport &&
      !this.isSpoofed &&
      !this.isLandlordSpoofed &&
      totpEnabled !== true &&
      authenticationType !== 'sso' &&
      !this.getActiveUserProperty('company.settings.disable_password_expiration') &&
      Date.now() - setDate - expiredDuration > 0
    );
  }

  @action
  initialize() {
    if (!isDev) {
      this.csrfToken = document.getElementsByName('_token')[0].content;
      this.ssoErrorMsg = document.getElementsByName('errorMsg')[0].content;
    }
  }

  @action
  async requestPasswordReset(params) {
    const { user_email: username, recaptcha, skipEmail } = params;
    const data = { username, recaptcha, skipEmail }; // legacy naming as reset could use username OR email
    const rawResponse = true;

    if (skipEmail) {
      return api.post('/api/ui/password/requestreset', { data });
    }
    this.passwordReqInProgress = true;

    return api.post('/api/ui/password/requestreset', { showErrorToast: false, data, rawResponse }).then(
      (res) => {
        const { status } = res;
        this.passwordReqInProgress = false;
        return { success: true, status, msg: 'Password set request successful' };
      },
      (res) => {
        const { status } = res;
        this.passwordReqInProgress = false;
        return { success: false, status, msg: 'Password set request unsuccessful' };
      }
    );
  }

  @action
  setTwoFactorDelay = (delay) => {
    this.twoFactorDelay = delay;
  };

  @action
  async passwordSet(params) {
    const { password, token } = params;
    const data = { pass: password, passConfirm: password, token };
    const rawResponse = true;

    this.passwordReqInProgress = true;
    return api.post('/api/ui/password/reset', { showErrorToast: false, data, rawResponse }).then(
      (res) => {
        const { status } = res;
        this.passwordReqInProgress = false;
        return { success: true, status, msg: 'Password successfully set.' };
      },
      (errRes) => {
        const { status } = errRes;
        const msg = (errRes.body && errRes.body.error) || 'Error setting password';
        this.passwordReqInProgress = false;
        return { success: false, status, msg };
      }
    );
  }

  @action
  async getOpenConfig() {
    if (this.openConfig) {
      return this.openConfig;
    }

    // must use cb (cacheBust) param here to keep Safari from 'helping' and making the req before the cookie
    // for the orginal '/' req has been set, thus resetting csrf token AFTER '/' req was made, but before login call,
    // thus giving csrf errors on everything until browser refresh.
    return api.get(prefixRoute(`open-config?cb=${Date.now()}`), { showErrorToast: false }).then(
      (res) => {
        this.openConfig = res;
        return res;
      },
      (error) => {
        throw error;
      }
    );
  }

  @action
  async ssoLookup({ email, recaptcha }) {
    this.ssoLookupReqInProgress = true;
    return api.post('/api/ui/ssolookup', { showErrorToast: false, data: { email, recaptcha } }).then(
      (res) => {
        this.ssoLookupReqInProgress = false;
        return res;
      },
      () => {
        this.ssoLookupReqInProgress = false;
        return { companies: [] };
      }
    );
  }

  @action
  async getSsoStatus() {
    return api.get(`/api/ui/sso/status/${this.getActiveUserProperty('company_id')}`).then(
      (res) => res,
      () => ({})
    );
  }

  @action
  async verifyTotp(params) {
    const { token, force } = params;
    const data = { token };
    if (force) {
      data.force = true;
    }
    return api
      .post('/api/ui/totp/verify', { showErrorToast: false, data })
      .then(
        () => {
          const user = this.activeUser.chfAdmin || this.activeUser;
          user.last2fa = Date.now();
          this.twoFactorVerifyRequired = false;
          if (!this.hideLoginInterstitial && this.isCompany) {
            this.loginInterstitialOpen = true;
          }
          return this.twoFactorVerifyRequired;
        },
        () => {
          this.twoFactorVerifyRequired = true;
          return this.twoFactorVerifyRequired;
        }
      )
      .then((twoFactorVerifyRequired) => {
        if (!twoFactorVerifyRequired) {
          return this.store.initializeApp().then(() => twoFactorVerifyRequired);
        }

        return twoFactorVerifyRequired;
      });
  }

  @action
  async verifyAuth() {
    return api.get(prefixRoute('whoami'), { noRedirect: true, showErrorToast: false }).then(
      (res) => {
        if (res.user) {
          this.activeUser = res.user;
          this.authenticated = true;
          this.authenticating = false;
          this.isTrialNoticeVisible = this.isTrial;
          this.isSharedUser = res.user.isSharedUser;

          // always show the login interstitial, no matter where they land.
          if (!this.hideLoginInterstitial && this.isCompany) {
            this.loginInterstitialOpen = true;
          }
        }
        return this.authenticated;
      },
      () => {
        this.clearActiveUser();
        return this.authenticated;
      }
    );
  }

  @action
  async verifySharedLink() {
    if (!this.store.$app.isSharedLink) {
      return Promise.resolve(true);
    }

    const pathParts = window.location.pathname.split('/');
    const hash = pathParts[pathParts.length - 1];

    return api.get('/api/ui/verify', { noRedirect: true, showErrorToast: false, query: { hash } }).then(
      ({ data }) => data,
      () => {
        this.clearActiveUser();
        return this.authenticated;
      }
    );
  }

  @action
  async authenticate(credentials) {
    this.authenticating = true;
    const data = {
      user_email: credentials.user_email,
      password: credentials.password,
      'g-recaptcha-response': credentials.recaptcha,
      noRedirect: true
    };

    // hide the error Toast on API requests, because we're handling it different for Login
    // noRedirect prevents logout on unsuccessful login, which avoids remounting the login form and clearing errors
    return api
      .post('/api/ui/login', { showErrorToast: false, noRedirect: true, data, rawResponse: true })
      .then(
        (res) => {
          const { body, headers } = res;
          if (body.user) {
            this.showLoginRecaptcha = false;
            this.activeUser = body.user;
            this.authenticated = true;
            this.authenticating = false;
            this.isTrialNoticeVisible = this.isTrial;
            this.isSynOnboarding = get(headers, 'syn-onboarding', false);

            // if there's no TOTP enabled, we can show the interstitial.
            if (!this.activeUser.totpEnabled && !this.hideLoginInterstitial && this.isCompany) {
              this.loginInterstitialOpen = true;
            }
          }

          return { success: true, user: this.activeUser };
        },
        (errRes) => {
          this.showLoginRecaptcha = errRes?.body?.recaptcha || false;
          this.authenticating = false;

          const errorMsg = errRes?.body?.message || '';
          const aclRegex = new RegExp('ACL. (.*) is not allowlisted');
          let ip = null;
          if (aclRegex.test(errorMsg)) {
            [, ip] = aclRegex.exec(errorMsg);
          }

          return { success: false, isAcl: !!ip, ip };
        }
      )
      .then(({ success, user, isAcl, ip }) => {
        if (success && !this.twoFactorVerifyRequired) {
          return this.store.initializeApp().then(() => ({ success, user }));
        }

        return { success, user, isAcl, ip };
      });
  }

  initializePendo() {
    const { $app, $devices, $dictionary, $lookups, $setup, $companySettings } = this.store;

    if ((!this.isSpoofed || this.isDemoUser) && !isDev && !$app.isSharedLink && window.pendo) {
      const envName = $dictionary.get('envName');
      if (envName === 'Kentik') {
        const { platform, userAgent } = window.navigator;
        const { clientWidth, clientHeight } = document.querySelector('body');
        const { pathname } = window.location;
        submitErrorReport({
          clientWidth,
          clientHeight,
          info: {
            history: $app.routeHistory,
            componentName: '$auth.initializePendo()'
          },
          pathname,
          platform,
          userAgent,
          error: 'Env name is Kentik',
          errorInfo: { componentStack: '' }
        });
      }
      const userId = `${envName}-${this.getUnderlyingUserProperty('id')}`;
      const companyId = this.getUnderlyingUserProperty('company.id') || this.getUnderlyingUserProperty('company_id');
      const companyStatus = this.getUnderlyingUserProperty('company.company_status');
      const authenticationType = this.getActiveUserProperty('authenticationType');

      let authenticationMethod = 'password';
      if (this.getUnderlyingUserProperty('last2fa')) {
        authenticationMethod = '2fa';
      } else if (authenticationType === 'sso') {
        authenticationMethod = 'sso';
      }

      let pendoConfig = {
        visitor: {
          id: userId,
          lastUsedVersion: 'v4'
        },
        account: {
          id: `${envName}-${companyId}`,
          company_id: companyId
        }
      };

      $lookups.policies().then(() => {
        const { activeDeviceSummaries: devices } = $devices;

        pendoConfig = Object.assign({}, pendoConfig, {
          visitor: {
            ...pendoConfig.visitor,
            // Note: intentionally including PII here now per Keith
            name: this.getUnderlyingUserProperty('user_full_name'),
            email: this.getUnderlyingUserProperty('user_email'),
            role: this.getUnderlyingUserProperty('user_level'),
            status: this.getUnderlyingUserProperty('user_status'),
            isMkpUser: !!this.getUnderlyingUserProperty('user_group_id'),
            tenantId: this.getUnderlyingUserProperty('user_group_id'),
            tenantName: this.getUnderlyingUserProperty('userGroup.name'),
            demoUser: this.isDemoUser,
            authenticationMethod,
            envName,
            ...$setup.getSettings('why', {})
          },
          account: {
            id: `${envName}-${companyId}`,
            company_id: companyId,
            name: this.getUnderlyingUserProperty('company.company_name'),
            is_paying: this.isCompanyUnderlying,
            company_type: this.getUnderlyingUserProperty('company.company_plan_id'),
            company_status: companyStatus,
            platform: $companySettings.edition,
            tags: $companySettings.tags,
            aws_device_count: devices.filter((device) => device.cloud_provider === 'aws').length,
            gcp_device_count: devices.filter((device) => isGoogleCloud(device.cloud_provider)).length,
            azure_device_count: devices.filter((device) => device.cloud_provider === 'azure').length,
            synth_test_count: this.getUnderlyingUserProperty('company.synthTestCount'),
            nms_device_count: this.getUnderlyingUserProperty('company.nmsDeviceCount'),
            mkp_user_count: this.getUnderlyingUserProperty('company.mkpUserCount'),
            hasKube: this.getPermission('kubeMap.enabled'),
            hasKMI: this.getPermission('marketIntel.enabled'),
            hasIdpCert: this.getUnderlyingUserProperty('company.hasIdpCert')
          },
          events: {
            guidesLoaded: this.handlePendoGuidesLoaded
          }
        });

        if (companyStatus === 'TRIAL') {
          Object.assign(pendoConfig, {
            trial_start: this.getUnderlyingUserProperty('company.cdate'),
            trial_end: this.getUnderlyingUserProperty('company.trial_end_date')
          });
        }

        window.pendo.initialize(pendoConfig);

        if (
          !this.isDemoUser &&
          $devices.hasReceivedFlow &&
          !this.isSubtenantUser &&
          !$setup.getSettings('loggedInWithFlow')
        ) {
          $setup.updateSettings({ loggedInWithFlow: true });

          this.track('loggedInWithFlow', { userId });
        }
      });
    }

    return null;
  }

  handlePendoGuidesLoaded = () => {
    if (this.isDemoUser) {
      setTimeout(() => {
        const active = window.pendo.getActiveGuide();

        if (active) {
          const { id } = active.guide;
          let marketoField = null;

          switch (id) {
            case '5jIkfFJrbxFjl0Rd3vRS77GPQ0k':
              marketoField = 'Trial_GT_VPN_Viewed__c';
              break;
            case 'ZMmUJTFpHA9_MEzefLl7_qBcPy0':
              marketoField = 'Trial_GT_Cost_Analytics_Viewed__c';
              break;
            case 'T72vL94WGRm0r25QjNRHErKVAYk':
              marketoField = 'Trial_GT_Peering_Viewed__c';
              break;
            case 'rynF6YZDOBEFoHlo9tmPEO_zB3c':
              marketoField = 'Trial_GT_DDoS_Viewed__c';
              break;
            case 'elBnJxBXrr72qEQ9-5R018-ZCq4':
              marketoField = 'Trial_GT_Synthetics_Viewed__c';
              break;
            case 'tnkTa8hnCVigW1D1UhcF0XD5umE':
              marketoField = 'Trial_GT_Cloud_Viewed__c';
              break;
            default:
          }

          if (marketoField) {
            api.post('/api/ui/marketo', { data: { [marketoField]: true } }).catch(() => null);
          }
        }
      }, 10000);
    }
  };

  track(eventName, payload) {
    if (!this.isSpoofed && !isDev && window.pendo && typeof window.pendo.track === 'function') {
      window.pendo.track(eventName, payload);
    }
  }

  @action
  clearTrialNotice = () => (this.isTrialNoticeVisible = false);

  @action
  handleToggleLoginInterstitial = () => (this.loginInterstitialOpen = !this.loginInterstitialOpen);

  @action handleToggleObservationDeckInterstitial = () =>
    (this.isObservationDeckInterstitialVisible = !this.isObservationDeckInterstitialVisible);

  @action
  handleSetShowInterstitial = (val) => {
    this.hideLoginInterstitial = val;
    window.localStorage.setItem('kentik.hideEventInterstitial', this.hideLoginInterstitial);
  };

  getPermission = (path) => {
    const permission = this.getActiveUserProperty(`permissions.${path}`);

    if (permission !== undefined) {
      return permission;
    }

    return null;
  };

  hasPermission = (path, options) => {
    const { overrideForSudo = true, overrideForSpoof = false } = options || {};
    const permission = this.getPermission(path);

    if (this.hasSudo && overrideForSudo) {
      return true;
    }

    if (this.isSpoofed && overrideForSpoof) {
      return true;
    }

    return permission !== undefined && permission !== null ? permission : false;
  };

  hasRbacPermissions = (requiredPermissions = []) => {
    const userRbacPermissions = this.getActiveUserProperty('rbacPermissions') || [];
    return requiredPermissions.every((p) => userRbacPermissions.includes(p));
  };

  @action
  setUserSettings(settings, options = {}) {
    return api.post('/api/ui/user/settings', { ...options, data: { settings } }).then((updatedSettings) => {
      this.activeUser.settings = updatedSettings;
    });
  }

  @action
  clearActiveUser = () => {
    this.loginInterstitialOpen = false;
    this.authenticated = false;
    this.activeUser = null;
    this.twoFactorVerifyRequired = false;
  };

  @action
  requestAssistance() {
    return api.post('/api/ui/trial/assistance');
  }

  @action
  logout = async () => {
    // Shared users shouldn't get bounced to the login screen if an error occurs
    // they also won't be aware an actual session exists under the hood
    if (this.store.$app.isSharedLink) {
      return Promise.resolve(false);
    }

    return api.get('/api/ui/logout', { noRedirect: true }).then((result) => {
      if (window.sessionStorage) {
        window.sessionStorage.clear();
      }

      this.clearActiveUser();
      return result;
    });
  };

  socketSessionSubscribe() {
    if (!this.sessionSocket) {
      this.sessionSocket = new Socket({
        outType: 'sessionEvent',
        inType: 'sessionEvent',
        listenToBroadcast: true,
        frequency: 'heartbeat',
        delaySend: true,
        onSuccess: (data) => {
          if (data && data.code === 401) {
            console.warn('Received 401 on Socket.', data);
            this.store.logout(401);
          }
        },
        onError(err) {
          console.warn('Received Socket Error (session)', err);
        }
      });
    }
    this.sessionSocket.setPayload({});
    this.sessionSocket.send();
  }
}

const $auth = new AuthStore();

autorun(() => {
  timezone.value = $auth.userTimezone;
});

export default $auth;
