import { action, computed } from 'mobx';
import { omit } from 'lodash';
import Model from 'core/model/Model';
import { PLATFORM_TYPES } from 'app/stores/mitigations/mitigationsConstants';
import $notifications from 'app/stores/notifications/$notifications';
import $mitigations from 'app/stores/mitigations/$mitigations';
import mitigationSerializations from 'app/stores/mitigations/mitigationSerializations';

export default class MitigationMethodModel extends Model {
  get urlRoot() {
    return '/api/ui/mitigations/methods';
  }

  get defaults() {
    return {
      name: '',
      description: ''
    };
  }

  get messages() {
    return {
      create: `Mitigation method "${this.get('name')}" was added successfully`,
      update: `Mitigation method "${this.get('name')}" was updated successfully`,
      destroy: `Mitigation method "${this.get('name')}" was removed successfully`
    };
  }

  @computed
  get type() {
    return PLATFORM_TYPES.find((type) => this.has(type.value))?.value || '';
  }

  @computed
  get typeLabel() {
    return $mitigations.getPlatformType(this.type)?.label;
  }

  @computed
  get details() {
    return this.get(this.type.toLowerCase()) || {};
  }

  @computed
  get canBeUsedForManualMitigation() {
    if (this.type !== 'flowspec') {
      return true;
    }

    const { protocols, sourceIpCidr, destIpCidr } = this.details;

    // Exclude methods that infer protocol
    if (protocols?.infer) {
      return false;
    }

    // Exclude methods that infer both source and destination IP
    if (sourceIpCidr?.infer && destIpCidr?.infer) {
      return false;
    }

    return true;
  }

  getNotificationChannels = (data) => {
    const { id } = data;

    return !id
      ? []
      : $notifications.findNotificationsByMitigationMethod(data.id).map((notification) => notification.id);
  };

  @action
  async save(attributes = {}, options = {}) {
    return (
      super
        // shutting off setOnPatchSuccess here so that we can make sure that deserialize is called after notifications are reloaded
        .save(attributes, { ...options, setOnPatchSuccess: false })
        .then((res) =>
          // reload the notification channels as they may have changed after saving the method
          $notifications.collection.fetch({ force: true }).then(() => {
            res.notificationChannels = this.getNotificationChannels(res);
            this.set(res);
            return res;
          })
        )
    );
  }

  @action
  duplicate = (options = {}) => {
    const { removeId = true, save = true } = options;
    const attributes = removeId ? omit(this.get(), ['id']) : this.get();
    attributes.name += ' - Copy';

    if (!this.collection) {
      return new this.constructor(attributes);
    }
    const serializedAttributes = this.serialize(attributes);
    const dupe = this.collection.build(serializedAttributes);

    if (save) {
      dupe.save(attributes);
    }

    return dupe;
  };

  serialize(data) {
    const serializedData = {
      [data.type]: mitigationSerializations.serializeMethodType(data),
      name: data.name,
      description: data.description || '',
      notificationChannels: data.notificationChannels,
      ackRequired: !!data.ackRequired,
      excludedIpCidrs: data.excludedIpCidrs || [],
      gracePeriod: `${data.gracePeriod * 60}s`
    };

    return super.serialize(serializedData);
  }

  deserialize(rawData) {
    const data = rawData.method || rawData;
    const type = PLATFORM_TYPES.find((option) => data[option.value])?.value;

    const deserializedData = Object.assign(this.defaults, data, {
      type,
      [type]: mitigationSerializations.deserializeMethodType(data, type),
      notificationChannels: this.getNotificationChannels(data),
      ackRequired: data.ackRequired ? data.ackRequired : false,
      excludedIpCidrs: data.excludedIpCidrs || [],
      gracePeriod: !data.gracePeriod ? 30 : `${data.gracePeriod}`.replace('s', '') / 60
    });

    return super.deserialize(deserializedData);
  }
}
