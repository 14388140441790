import React from 'react';
import { action, computed, observable } from 'mobx';
import { get, uniq, uniqBy } from 'lodash';

import { Box, Flex, Icon, Text } from 'core/components';
import api from 'core/util/api';
import IncompleteDeviceCollection from 'app/stores/device/IncompleteDeviceCollection';
import NonCloudDeviceCollection from 'app/stores/device/NonCloudDeviceCollection';
import DeviceSubtypeIcon, { deviceSubTypeIconMap } from 'app/components/device/DeviceSubtypeIcon';
import { getDeviceTypeOptions } from 'app/util/devices';

import { isGoogleCloud } from '@kentik/ui-shared/util/map';
import DeviceWarningsCollection from 'app/stores/device/DeviceWarningsCollection';
import { modelFinder } from 'app/stores/util/modelFinder';
import DeviceCollection from './DeviceCollection';
import DeviceModel from './DeviceModel';
import InterfaceCollection from '../interface/InterfaceCollection';

const DEVICE_OPTIONS_LIMIT = 300;

export class DeviceStore {
  @observable
  fetchFlowCount = 0;

  collection = new DeviceCollection();

  nonCloudCollection = new NonCloudDeviceCollection();

  incompleteDevices = new IncompleteDeviceCollection();

  deviceWarnings = new DeviceWarningsCollection();

  @observable.ref
  deviceSummaries = [];

  // has received flow for ANYTHING, including pseudo-devices (synthetics). generally you don't want this.
  @observable
  hasReceivedAnyFlow = false;

  // has received flow specifically for devices
  @observable
  hasReceivedFlow = false;

  @observable
  masterBGPDevices = [];

  @action
  initialize() {
    if (this.store.$auth.getActiveUserProperty('company.company_status') === 'V') {
      return Promise.all([this.collection.fetch(), this.fetchFlowHeartbeat()]).then(([devices, hasFlow]) => {
        this.constructDeviceSummaries();
        // start checking for flow every 60 seconds if they have devices, but we haven't received flow yet.
        if (hasFlow === false && devices?.length) {
          this.flowHeartbeatInterval = setInterval(this.fetchFlowHeartbeat, 60000);
        }
      });
    }
    return Promise.resolve();
  }

  /**
   * This is only private to make test mocks easier.
   * @returns {(name: string) => import('./DeviceModel').default | undefined}
   * @private
   */
  @computed
  get _byName() {
    return modelFinder(this.collection, 'device_name');
  }

  /**
   * @returns {(name: string) => import('./DeviceModel').default | undefined}
   */
  byName(device_name) {
    return this._byName(device_name);
  }

  @action
  navigateToDevice = (deviceName) => {
    this.history.push(`/v4/core/quick-views/devices/${deviceName}`);
  };

  @action
  fetchDevice = (deviceName) =>
    api.get(`/api/ui/devices/details/${deviceName}`).then((attributes) => {
      const device = new DeviceModel();
      device.set(device.deserialize(attributes));

      const interfaces = new InterfaceCollection(attributes.all_interfaces, {
        parent: device,
        sortState: { field: 'interface_description', direction: 'asc' }
      });
      device.set('interfaceCollection', interfaces);

      // save counts so they don't change after filtering
      device.set('interfaceCount', interfaces.unfilteredSize);
      device.set('downInterfaceCount', interfaces.downInterfaces.length);

      interfaces.fetchInterfaceFlow(device);

      return device;
    });

  @computed({ keepAlive: true })
  get deviceSummariesById() {
    const deviceSummariesById = {};
    this.deviceSummaries.forEach((deviceSummary) => {
      deviceSummariesById[deviceSummary.id] = deviceSummary;
    });
    return deviceSummariesById;
  }

  @computed({ keepAlive: true })
  get deviceSummariesByName() {
    const deviceSummariesByName = {};
    this.deviceSummaries.forEach((deviceSummary) => {
      deviceSummariesByName[deviceSummary.device_name] = deviceSummary;
    });
    return deviceSummariesByName;
  }

  @computed({ keepAlive: true })
  get activeDeviceSummariesById() {
    const deviceSummariesById = {};
    this.activeDeviceSummaries.forEach((deviceSummary) => {
      deviceSummariesById[deviceSummary.id] = deviceSummary;
    });
    return deviceSummariesById;
  }

  @computed({ keepAlive: true })
  get activeDeviceSummariesByName() {
    const deviceSummariesByName = {};
    this.activeDeviceSummaries.forEach((deviceSummary) => {
      deviceSummariesByName[deviceSummary.device_name] = deviceSummary;
    });
    return deviceSummariesByName;
  }

  @computed
  get companySettings() {
    return {
      flow_ips: this.store.$companySettings.flow_ips,
      port: this.store.$companySettings.port
    };
  }

  @computed
  get routerSettings() {
    return {
      snmpPollingIps: this.store.$dictionary.get('general_router_settings.router_snmp_polling_ips')
    };
  }

  @computed
  get activeDeviceSummaries() {
    return this.deviceSummaries.filter((device) => device.device_status === 'V');
  }

  // builds the { label, value } from deviceSummaries instead of the whole collection
  // assuming whenever we show options, we only want options associated with active devices
  @computed
  get deviceSummaryOptions() {
    return this.activeDeviceSummaries.map(
      ({ device_name, id, device_type, device_subtype, ranger_icmp_ip, ranger_snmp_ip }) => ({
        value: device_name,
        label: device_name,
        icon: <DeviceSubtypeIcon type={device_type} />,
        id,
        device_type,
        device_subtype,
        ranger_icmp_ip,
        ranger_snmp_ip
      })
    );
  }

  @computed
  get deviceSummaryByIdOptions() {
    return this.activeDeviceSummaries
      .map(({ device_name, id, device_type, device_subtype }) => ({
        value: id,
        label: device_name,
        icon: <DeviceSubtypeIcon type={device_type} mr={1} />,
        id,
        device_type,
        device_subtype
      }))
      .sort((a, b) => (b.label > a.label ? -1 : 1));
  }

  @computed
  get deviceSummariesByType() {
    return this.activeDeviceSummaries.reduce((acc, summary) => {
      const { device_type, device_subtype } = summary;

      if (!acc[device_type]) {
        acc[device_type] = [];
      }
      acc[device_type].push(summary);

      // If they're the same, we don't want to double add!
      if (device_type !== device_subtype) {
        if (!acc[device_subtype]) {
          acc[device_subtype] = [];
        }
        acc[device_subtype].push(summary);
      }
      return acc;
    }, {});
  }

  /**
   * {
   *  router: 8,
   *  host-nprobe-dns-www: 1
   * }
   */
  @computed
  get flowDeviceTypeCounts() {
    return this.activeDeviceSummaries.reduce((acc, { device_type, device_subtype }) => {
      acc[device_type] = acc[device_type] ? acc[device_type] + 1 : 1;

      // If they're the same, we don't want to double count!
      if (device_type !== device_subtype) {
        acc[device_subtype] = acc[device_subtype] ? acc[device_subtype] + 1 : 1;
      }
      return acc;
    }, {});
  }

  @computed
  get uniqueSubtypes() {
    return uniq(this.activeDeviceSummaries.map(({ device_subtype }) => device_subtype));
  }

  @computed
  get deviceNameOptions() {
    return this.activeDeviceSummaries.map(({ device_name }) => ({
      value: device_name,
      label: device_name
    }));
  }

  getDeviceOptionsForSite(site_id) {
    return this.activeDeviceSummaries
      .filter((device) => device.site_id === site_id)
      .map(({ device_name }) => ({
        value: device_name,
        label: device_name
      }));
  }

  @computed
  get hasDnsProbe() {
    return this.hasSubtype('kprobe');
  }

  @computed
  get hasDevices() {
    return this.activeDeviceSummaries.length > 0;
  }

  @computed
  get deviceSiteOptions() {
    return this.activeDeviceSummaries.map(({ device_name, title }) => ({
      value: device_name,
      label: `${title} - ${device_name}`
    }));
  }

  hasSubtype(subtype) {
    return this.activeDeviceSummaries.some((device) => device.device_subtype === subtype);
  }

  @computed
  get hasKappaDevice() {
    return this.hasSubtype('kappa');
  }

  @computed
  get hasGCEDevice() {
    return this.activeDeviceSummaries.some((device) => isGoogleCloud(device.cloud_provider));
  }

  @computed
  get hasAWSDevice() {
    return this.activeDeviceSummaries.some((device) => device.cloud_provider === 'aws');
  }

  @computed
  get hasAzureDevice() {
    return this.activeDeviceSummaries.some((device) => device.cloud_provider === 'azure');
  }

  @computed
  get hasOCIDevice() {
    return this.activeDeviceSummaries.some((device) => device.cloud_provider === 'oci');
  }

  @computed
  get nonCloudDeviceCount() {
    return this.activeDeviceSummaries.filter((device) => !device.cloud_provider).length;
  }

  @computed
  get nonNMSDeviceCount() {
    // A device can be NMS and flow. Look for sending_ip to determine if it could be sending flow.
    return this.activeDeviceSummaries.filter(
      (device) => device.sending_ips.length > 0 && device.sending_ips[0].length > 0
    ).length;
  }

  @computed
  get cloudDeviceCount() {
    return this.activeDeviceSummaries.filter((device) => !!device.cloud_provider).length;
  }

  @computed
  get cloudProviders() {
    return uniq(this.activeDeviceSummaries.map(({ cloud_provider }) => cloud_provider));
  }

  getCloudDeviceCount(provider, planId) {
    return this.activeDeviceSummaries.filter(
      (device) => device.cloud_provider === provider && (planId === undefined || device.plan_id === planId)
    ).length;
  }

  /**
   * Populate this.deviceSummaries and resulting data. Historically this was a separate call but it was largely just
   * duplicating data from our Device Collections API so it was removed.
   * TODO In future we would like to remove this.deviceSummaries and feed everything from Device Collections.
   * TODO Resulting data should probably be @computed rather than directly constructed
   */
  @action
  loadDeviceSummaries = () => this.collection.fetch({ force: true }).then(() => this.constructDeviceSummaries());

  /**
   * Populate this.deviceSummaries and resulting data. Historically this was a separate call but it was largely just
   * duplicating data from our Device Collections API so it was removed.
   * TODO In future we would like to remove this.deviceSummaries and feed everything from Device Collections.
   * TODO Resulting data should probably be @computed rather than directly constructed
   */
  @action
  constructDeviceSummaries() {
    // TODO would be nice to avoid cloning the collection
    const response = [...this.collection.toJS()];

    let deviceIdOptionsLabel = (device) => `${device.id} (${device.device_name})`;
    let deviceNameOptionsLabel = (device) => `${device.device_name} (${device.id})`;

    if (response?.length < DEVICE_OPTIONS_LIMIT) {
      // TODO JSX inside of MobX makes webpack cry :(
      deviceIdOptionsLabel = (device) => {
        const { device_name, device_subtype, id } = device;
        return (
          <>
            <Flex alignItems="center">
              <Icon icon={deviceSubTypeIconMap[device_subtype]} color="muted" mr="4px" />
              <Box flex={1}>
                <Text as="div" mr="4px" ellipsis>
                  {device_name}
                </Text>
                <Text muted small>
                  {id}
                </Text>
              </Box>
            </Flex>
          </>
        );
      };
      deviceNameOptionsLabel = (device) => {
        const { device_name, device_subtype } = device;
        return (
          <Flex alignItems="center">
            <Icon icon={deviceSubTypeIconMap[device_subtype]} color="muted" mr="4px" />
            <Box flex={1}>{device_name}</Box>
          </Flex>
        );
      };
    }

    const deviceIdOptions = response.map((option) => ({
      value: option.id,
      name: option.device_name,
      filterLabel: option.device_name,
      label: deviceIdOptionsLabel(option)
    }));

    const deviceNameOptions = response.map((option) => ({
      value: option.device_name,
      name: option.device_name,
      label: deviceNameOptionsLabel(option)
    }));

    const siteNameOptions = uniqBy(
      response.map((option) => ({ value: option.title, label: option.title })).filter((option) => option.value),
      (option) => option.value
    );

    const siteIdOptions = uniqBy(
      response.map((option) => ({ value: `${option.site_id}`, label: option.title })).filter((option) => option.label),
      (option) => option.value
    );

    this.deviceSummaries = response;

    this.store.$dictionary.addFilterFieldValueOptions({
      i_device_id: deviceIdOptions,
      i_device_name: deviceNameOptions,
      i_device_subtype: getDeviceTypeOptions(this.uniqueSubtypes),
      i_ult_exit_device_name: deviceNameOptions,
      ktappprotocol__snmp_device_metrics__i_device_name: deviceNameOptions,
      ktappprotocol__snmp__i_device_name: deviceNameOptions,
      ktappprotocol__st__i_device_name: deviceNameOptions,
      i_device_site_name: siteNameOptions,
      i_ult_exit_site: siteNameOptions,
      ktappprotocol__snmp__i_device_site_name: siteNameOptions,
      ktappprotocol__snmp_device_metrics__i_device_site_name: siteNameOptions,
      ktappprotocol__st__i_device_site_name: siteNameOptions,
      i_device_site_id: siteIdOptions
    });

    return this.deviceSummaries;
  }

  @action
  loadDeviceDetails(deviceId) {
    return api.get(`/api/ui/devices/${deviceId}`).then((response) => {
      this.collection.selected.set(response);
      return this.collection.selected;
    });
  }

  // does this Company have any flow at all?
  @action
  fetchFlowHeartbeat = () =>
    api.get('/api/ui/devices/flowHeartbeat').then((result) => {
      this.hasReceivedAnyFlow = !!result?.has_flow;
      this.hasReceivedFlow = !!result?.has_device_flow;

      if (this.hasReceivedFlow) {
        clearInterval(this.flowHeartbeatInterval);
      }

      return result;
    });

  fetchMasterBGPDevices() {
    return api.get('/api/ui/devices/getMasterBgpDevicesList').then((response) => {
      this.masterBGPDevices = response.map((device) => ({
        label: device.device_name,
        value: device.id
      }));
    });
  }

  fetchBgpIngestIps(deviceId) {
    return api.get(`/api/ui/companySettings/getBgpIngestIps/${deviceId || ''}`);
  }

  fetchAllDeviceStatus() {
    return api.get('/api/ui/devices/status');
  }

  loadNonCloudStatus() {
    return api.get('/api/ui/devices/non-cloud-status');
  }

  loadDeviceStatus(deviceId) {
    return api.get(`/api/ui/devices/status/${deviceId}`).then((response) => response[deviceId]);
  }

  @computed
  get activeDeviceLabelOptions() {
    const labelMap = {};
    this.activeDeviceSummaries.forEach((device) =>
      device.labels.forEach((label) => {
        labelMap[label.id] = label;
      })
    );
    return this.store.$labels.getLabelOptions(Object.values(labelMap));
  }

  getDeviceSummaryOptionByName({ deviceName, activeDevicesOnly = true }) {
    const deviceSummaryOption = this.deviceSummaryOptions.find((t) => t.value === deviceName);
    if (activeDevicesOnly) {
      return deviceSummaryOption;
    }
    const fallback = {
      value: deviceName,
      label: `${deviceName} (removed)`
    };
    return deviceSummaryOption || fallback;
  }

  getDeviceSummaryByName({ deviceName, activeDevicesOnly = true }) {
    const deviceSummary = this.deviceSummariesByName[deviceName];
    if (activeDevicesOnly) {
      return deviceSummary;
    }
    const fallback = { device_name: deviceName };
    return deviceSummary || fallback;
  }

  /**
   * { device_types, device_labels, device_sites, device_name }
   */
  @action
  getUniqueSelectedDevices(identifiers) {
    const { $sites, $labels } = this.store;
    const devices = [];

    if (!$sites.collection.hasFetched || !$labels.labels.hasFetched) {
      return devices;
    }

    const { device_ids, device_labels, device_sites, device_types, device_name, activeDevicesOnly } = identifiers;

    if (Array.isArray(device_ids)) {
      devices.push(...device_ids.map((id) => this.deviceSummariesById[id]));
    }

    if (Array.isArray(device_labels)) {
      device_labels.forEach((labelId) => {
        const label = this.store.$labels.labels.get(labelId);
        if (label) {
          devices.push(
            ...label
              .get('items')
              .filter((item) => item.item_type === 'device')
              .map((item) => this.deviceSummariesById[item.item_id])
          );
        }
      });
    }

    if (Array.isArray(device_sites)) {
      device_sites.forEach((siteId) => {
        const site = this.store.$sites.collection.get(siteId);
        if (site) {
          devices.push(...site.devices);
        }
      });
    }

    if (Array.isArray(device_types)) {
      device_types.forEach((type) => devices.push(...(this.deviceSummariesByType[type] || [])));
    }

    if (Array.isArray(device_name)) {
      devices.push(...device_name.map((name) => this.getDeviceSummaryByName({ deviceName: name, activeDevicesOnly })));
    }

    // take only the uniques and get rid of garbage
    return uniqBy(
      devices.filter((device) => device),
      'device_name'
    );
  }

  filterMissingDevices(identifiers) {
    const { all_devices, device_labels, device_sites, device_types, device_name } = identifiers;
    const { $sites, $labels } = this.store;

    if (all_devices) {
      return identifiers;
    }

    return {
      all_devices,
      device_name: device_name.filter((name) => this.deviceSummariesByName[name]),
      device_types: device_types.filter(
        (type) => this.deviceSummariesByType[type] && this.deviceSummariesByType[type].length
      ),
      device_sites: device_sites.filter((site) => $sites.collection.get(site)),
      device_labels: device_labels.filter((label) => $labels.labels.get(label))
    };
  }

  getDeviceOptions(valueField = 'device_name', deviceFilter) {
    const deviceOptions = {};
    let { deviceSummaries } = this;

    if (deviceFilter) {
      deviceSummaries = deviceSummaries.filter(deviceFilter);
    }

    deviceSummaries.forEach((device) => {
      const { device_name, device_subtype, title } = device;
      const site = title || 'Unassigned';

      const deviceOption = {
        value: device[valueField],
        label: device_name,
        className: device_subtype,
        icon: deviceSubTypeIconMap[device_subtype],
        site
      };

      if (!deviceOptions[site]) {
        deviceOptions[site] = [];
      }

      deviceOptions[site].push(deviceOption);
    });

    return deviceOptions;
  }

  /**
   * Pass in a string of device_ids, get a comma separated string of device_names (for QueryModel)
   */
  getDeviceNames(ids, options = {}) {
    if (!ids) {
      return '';
    }

    const mapFn = (id) => {
      const device = this.deviceSummariesById[id];
      return device?.device_name || (options?.showUnmatched ? '<missing device>' : null);
    };

    if (Array.isArray(ids)) {
      return ids.map(mapFn).filter((device) => device);
    }

    return ids
      .split(',')
      .map(mapFn)
      .filter((device) => device);
  }

  containsKappa({ device_name, device_labels, device_sites, device_types, device_ids }) {
    if (Array.isArray(device_types) && device_types.includes('kappa')) {
      return true;
    }

    return this.getUniqueSelectedDevices({
      device_ids,
      device_name,
      device_labels,
      device_sites,
      device_types
    }).some((device) => device.device_subtype === 'kappa');
  }

  containsDnsProbe({ device_name, device_labels, device_sites, device_types, device_ids }) {
    if (Array.isArray(device_types) && device_types.includes('kprobe')) {
      return true;
    }

    return this.getUniqueSelectedDevices({
      device_ids,
      device_name,
      device_labels,
      device_sites,
      device_types
    }).some((device) => ['kprobe', 'aws_subnet', 'gcp_subnet', 'azure_subnet'].includes(device.device_subtype));
  }

  getDeviceTypeFromSubtype(device_subtype) {
    const deviceSubtypeRecord = this.store.$dictionary.dictionary.device_subtypes[device_subtype];
    return deviceSubtypeRecord ? deviceSubtypeRecord.parent_type : undefined;
  }

  getDeviceSubtypeColumns(subtype) {
    return api.get(`/api/ui/devices/subtype-col/${subtype}`);
  }

  isCloudSubtype(subtype) {
    return ['aws_subnet', 'gcp_subnet', 'azure_subnet'].includes(subtype);
  }

  isRouter(deviceId) {
    // eslint-disable-next-line eqeqeq
    const device = this.deviceSummariesById[deviceId];
    return !!(device && device.device_type === 'router');
  }

  isMetricsDevice(deviceId) {
    return !!this.store.$metrics.deviceCollection.get(deviceId);
  }

  isCloudDevice(deviceOrDeviceId) {
    const deviceId = parseInt(deviceOrDeviceId);

    if (Number.isNaN(deviceId)) {
      const device = deviceOrDeviceId || {};
      return this.isCloudSubtype(get(device.attributes || device, 'device_subtype'));
    }

    const device = this.deviceSummariesById[deviceId];
    return !!(device && device.cloud_export_id);
  }

  cloudProvider(device = {}) {
    return this.isCloudDevice(device) ? get(device.attributes || device, 'device_subtype').split('_')[0] : undefined;
  }

  bulkUpdateDeviceMonitoringTemplate(deviceIds, monitoring_template_id) {
    return api
      .post('/api/ui/devices/bulkUpdateDeviceMonitoringTemplate', { data: { deviceIds, monitoring_template_id } })
      .then(
        (monitoringTemplateId) => {
          deviceIds.forEach((deviceId) => {
            const model = this.nonCloudCollection.get(deviceId);
            if (model && model.isNMS) {
              model.set('monitoring_template_id', monitoringTemplateId);
            }
          });
        },
        (err) => {
          console.error(err);
        }
      );
  }

  exportPdf = (search = '') => {
    const date = new Date().toISOString().slice(0, 10).replace(/-/g, '');
    this.store.$exports.fetchExport({
      path: '/api/ui/export/exportPage',
      type: 'pdf',
      fileName: `export-devices-${date}`,
      exportOptions: { location: `/v4/export/infrastructure/devices${search}` }
    });
  };

  exportCsv = (data) => {
    const path = '/api/ui/devices/csv?filterCloud=true';
    const date = new Date().toISOString().slice(0, 10).replace(/-/g, '');
    const fileName = `export-devices-${date}`;
    const type = 'csv';
    const options = { path, fileName, type };

    this.store.$exports.addLoadingExport(options);
    return api.post(path, { data, rawResponse: true }).then((response) => {
      this.store.$exports.clearLoadingExport(options);
      this.store.$exports.addPayload(response.text, options);
    });
  };
}

const deviceStore = new DeviceStore();

export default deviceStore;
