import { computed } from 'mobx';

import Collection from 'core/model/Collection';
import ChannelModel from './ChannelModel';

export default class SupportedChannelsCollection extends Collection {
  get url() {
    return '/api/ui/notifications/supported';
  }

  get model() {
    return ChannelModel;
  }

  get defaultSortState() {
    return {
      field: 'channelType',
      direction: 'asc'
    };
  }

  @computed
  get channelOptions() {
    return this.generateSelectOptions({ valueKey: 'channelType', labelKey: 'channelDisplayName' }).sort((a, b) =>
      (a.label || '').localeCompare(b.label)
    );
  }

  deserialize(data) {
    data = super.deserialize(data);

    if (data.supportedChannels) {
      return data.supportedChannels;
    }

    return [];
  }
}
