import { DEFAULT_MONITORING_TEMPLATE_PRESET } from '@kentik/ui-shared/nms/deviceMonitoringTemplates';
import { Collection } from 'core/model';
import { computed } from 'mobx';
import MonitoringTemplateModel from './MonitoringTemplateModel';

export default class MonitoringTemplatesCollection extends Collection {
  get url() {
    return '/api/ui/recon/monitoringTemplates';
  }

  get model() {
    return MonitoringTemplateModel;
  }

  @computed
  get defaultTemplate() {
    return this.unfiltered.find((model) => model.get('preset') === DEFAULT_MONITORING_TEMPLATE_PRESET);
  }

  @computed
  get presets() {
    return this.unfiltered.filter((model) => model.isPreset);
  }

  @computed
  get customTemplates() {
    return this.unfiltered.filter((model) => !model.isPreset);
  }
}
