import api from 'core/util/api';

class NetworkBi {
  navigateToAsn = (asn) => {
    const as_number = asn
      .match(/\(.*\)/)[0]
      .replace('(', '')
      .replace(')', '');

    this.history.push(`/v4/core/quick-views/asn/${as_number}`);
  };

  navigateToNetworkBoundary = (netBoundary) => {
    this.history.push(`/v4/core/quick-views/network-boundary/${netBoundary}`);
  };

  navigateToConnectivityType = (connType) => {
    this.history.push(`/v4/core/quick-views/connectivity-type/${connType}`);
  };

  fetchIpData(ip_address) {
    return api.get('/api/ui/lookups/ip', { query: { ip_address } });
  }

  navigateToIp = (ip) => {
    const ipAddress = ip.match(/^(.+)\//)[1];
    this.history.push(`/v4/core/quick-views/ip/${encodeURIComponent(ipAddress)}`, { ip });
  };

  navigateToPort = (portProto) => {
    const port = portProto.match(/\(([^)]+)\//)[1];
    this.history.push(`/v4/core/quick-views/port/${port}`);
  };

  navigateToRoutePrefix = (routePrefix) => {
    const [prefix, len] = routePrefix.split('/');
    this.history.push(`/v4/core/quick-views/route-prefix?prefix=${encodeURIComponent(prefix)}&len=${len}`);
  };

  navigateToASPath = (asPath) => {
    this.history.push(`/v4/core/quick-views/aspath?path=${encodeURIComponent(asPath)}`);
  };

  navigateToInetFamily = (inetFamily) => {
    this.history.push(`/v4/core/quick-views/inet-family/${inetFamily}`);
  };

  navigateToProtocol = (protocol) => {
    const protocolNumber = protocol.match(/\(([^)]+)\)/)[1];
    this.history.push(`/v4/core/quick-views/protocol/${protocolNumber}`);
  };
}

export default new NetworkBi();
