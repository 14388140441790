import React from 'react';
import { isFunction } from 'lodash';

import Text from 'core/components/Text';
import api from 'core/util/api';
import { RPKI_OPTIONS } from 'app/util/constants';
import Cache from 'core/util/Cache';

function lookup(url, value, label, matcher, extraMatchers, cache) {
  if (cache) {
    const cachedResults = cache.get(url);
    if (cachedResults) {
      return cachedResults;
    }
  }

  return api.get(url).then(
    (success) => {
      const options = [];

      success.forEach((result) => {
        const option = {
          value: isFunction(value) ? value(result) : result[value],
          label: isFunction(label) ? label(result) : result[label || value],
          matcher: matcher ? result[matcher] : undefined
        };

        // prevent empty labels
        if (option.label === '' && option.value === '') {
          option.label = 'None';
        }

        if (extraMatchers) {
          extraMatchers.forEach((match) => {
            option[match] = result[match];
          });
        }

        // If there's multiple matches, just show the raw value so nothing is misleading
        const dupeIdx = options.findIndex((opt) => option.value === opt.value);
        if (dupeIdx >= 0) {
          options[dupeIdx].label = option.value || 'None';
        } else {
          options.push(option);
        }
      });

      if (cache) {
        cache.put(url, options);
      }

      return options;
    },
    () => []
  );
}

function lookupWithMetadata(url, value, label, matcher) {
  return api.get(url).then(
    (success) => {
      const options = [];
      const { models, metadata } = success;

      models.forEach((result) => {
        const option = {
          value: isFunction(value) ? value(result) : result[value],
          label: isFunction(label) ? label(result) : result[label || value],
          matcher: matcher ? result[matcher] : undefined
        };

        // If there's multiple matches, just show the raw value so nothing is misleading
        const dupeIdx = options.findIndex((opt) => option.value === opt.value);
        if (dupeIdx >= 0) {
          options[dupeIdx].label = option.value;
        } else {
          options.push(option);
        }
      });

      return { options, metadata };
    },
    () => []
  );
}

function arrayLookupGenerator(filter, fn) {
  const filters = Array.isArray(filter) ? filter : [filter];

  return () =>
    Promise.all(filters.map(fn)).then((allMatches) => {
      const map = allMatches.flat().reduce((acc, option) => {
        acc[option.value] = option;
        return acc;
      }, {});

      return Object.values(map);
    });
}

/**
 * This function is used for local filtering of what we assume to be complete in-memory options lists.
 * It assumes a user preference for exact value matches, then startsWith value matches, then contains label matches;
 * shown in that order of precedence. IF USING THIS FUNCTION, ensure this behavior is desired. As of November 2017 all
 * usages of this function (countries and protocols) have been validated.
 *
 * @param options
 * @param filter
 */
function localOptionFilter(options, filter) {
  const parsedFilter = Array.isArray(filter) ? filter.length && filter[0] : filter;
  const lowerFilter = (parsedFilter || '').toLowerCase();

  let valueMatches = options.filter(({ value }) => (value || '').toString().toLowerCase() === lowerFilter);
  valueMatches = valueMatches.concat(
    options.filter((option) => {
      const { value } = option;
      return !valueMatches.includes(option) && (value || '').toString().toLowerCase().startsWith(lowerFilter);
    })
  );
  return valueMatches.concat(
    options.filter((option) => {
      const { matcher, label } = option;
      return (
        !valueMatches.includes(option) &&
        ((matcher !== undefined ? matcher : label) || '').toLowerCase().includes(lowerFilter)
      );
    })
  );
}

function encodeTerms(filter) {
  if (Array.isArray(filter)) {
    return filter.map((f) => encodeURIComponent(f)).join('|');
  }

  return encodeURIComponent(filter);
}

const DEBOUNCE_DURATION = 250;

export class LookupStore {
  protocolPromise;

  protocolNamePromise;

  countriesPromise;

  siteCountriesPromise;

  debouncer = {};

  constructor(options = {}) {
    this.cache = new Cache();

    this.promises = {};

    this.autoCompleteFilterQueryHandlers = {
      kt_dst_market: this.market,
      kt_src_market: this.market,

      input_port: this.interfaces,
      output_port: this.interfaces,
      ult_exit_port: this.interfaces,
      'input|output_port': this.interfaces,
      InterfaceID_src: this.interfaces,
      InterfaceID_dst: this.interfaces,
      ktappprotocol__snmp__output_port: this.interfaces,
      ktappprotocol__st__output_port: this.interfaces,

      i_input_interface_description: this.interfaceDescriptions,
      i_output_interface_description: this.interfaceDescriptions,
      i_ult_exit_interface_description: this.interfaceDescriptions,
      'i_input|output_interface_description': this.interfaceDescriptions,

      i_input_snmp_alias: this.snmpAliases,
      i_output_snmp_alias: this.snmpAliases,
      i_ult_exit_snmp_alias: this.snmpAliases,
      'i_input|output_snmp_alias': this.snmpAliases,

      i_src_provider_classification: this.providers,
      i_dst_provider_classification: this.providers,
      'i_src|dst_provider_classification': this.providers,
      i_ult_provider_classification: this.providers,
      ktappprotocol__snmp__i_dst_provider_classification: this.providers,
      ktappprotocol__st__i_dst_provider_classification: this.providers,

      protocol: this.protocols,
      i_protocol_name: this.protocolNames,
      Proto: this.protocols,

      dst_geo: this.countries,
      src_geo: this.countries,
      'src|dst_geo': this.countries,
      Geography_src: this.countries,
      Geography_dst: this.countries,
      i_device_site_country: this.siteCountries,
      i_ult_exit_site_country: this.siteCountries,

      i_site_market: this.siteMarkets,
      i_ult_exit_site_market: this.siteMarkets,

      dst_geo_region: this.regions,
      src_geo_region: this.regions,
      'src|dst_geo_region': this.regions,

      dst_geo_city: this.cities,
      src_geo_city: this.cities,
      'src|dst_geo_city': this.cities,

      src_as: this.asNumbers,
      src_nexthop_as: this.asNumbers,
      src_second_asn: this.asNumbers,
      src_third_asn: this.asNumbers,
      dst_as: this.asNumbers,
      dst_nexthop_as: this.asNumbers,
      dst_second_asn: this.asNumbers,
      dst_third_asn: this.asNumbers,
      'src|dst_as': this.asNumbers,
      AS_src: this.asNumbers,
      AS_dst: this.asNumbers,
      src_nexthop_asn: this.asNumbers,
      dst_nexthop_asn: this.asNumbers,

      i_src_as_name: this.asNames,
      i_src_nexthop_as_name: this.asNames,
      i_src_second_as_name: this.asNames,
      i_src_third_as_name: this.asNames,
      i_dst_as_name: this.asNames,
      i_dst_nexthop_as_name: this.asNames,
      i_dst_second_asn_name: this.asNames,
      i_dst_third_asn_name: this.asNames,
      'i_src|dst_as_name': this.asNames,

      i_device_label: this.deviceLabels,

      dst_flow_tags: this.flowTags,
      src_flow_tags: this.flowTags,
      'src|dst_flow_tags': this.flowTags,

      application: this.applications,

      src_cdn: this.cdns,
      dst_cdn: this.cdns,
      'src|dst_cdn': this.cdns,

      customer_provider: this.customerProviders,

      service_provider: this.ottServiceProviders,
      service_name: this.ottServices,
      service_type: this.ottServiceTypes,

      cloud_provider: this.cloudProviders,
      kt_aws_src_region: this.awsRegion,
      kt_aws_dst_region: this.awsRegion,
      kt_az_src_region: this.azureRegion,
      kt_az_dst_region: this.azureRegion,
      kt_aws_src_vpc_name: this.awsVpcName,
      kt_aws_dst_vpc_name: this.awsVpcName,
      'kt_aws_src|dst_vpc_name': this.awsVpcName,
      kt_aws_src_subnet_name: this.awsSubnetName,
      kt_aws_dst_subnet_name: this.awsSubnetName,
      'kt_aws_src|dst_subnet_name': this.awsSubnetName,
      i_dst_rpki_min_name: this.rpkiQuickStatus,

      i_input_interface_group: this.interfaceGroups,
      i_output_interface_group: this.interfaceGroups,
      'i_input|output_interface_group': this.interfaceGroups,

      i_src_asn_member_of_ix: this.peeringdbIx,
      i_dst_asn_member_of_ix: this.peeringdbIx,
      'i_src|dst_asn_member_of_ix': this.peeringdbIx,
      i_src_asn_available_at_facility: this.peeringdbFac,
      i_dst_asn_available_at_facility: this.peeringdbFac,
      'i_src|dst_asn_available_at_facility': this.peeringdbFac,
      i_src_asn_peering_policy: this.peeringdbPPolicy,
      i_dst_asn_peering_policy: this.peeringdbPPolicy,
      'i_src|dst_asn_peering_policy': this.peeringdbPPolicy
    };

    this.autoCompleteDimensionHandlers = {
      Geography_dst: this.countries,
      Geography_src: this.countries,

      dst_geo_region: this.regionIds,
      src_geo_region: this.regionIds,

      dst_geo_city: this.cityIds,
      src_geo_city: this.cityIds,

      InterfaceID_src: this.interfaces,
      InterfaceID_dst: this.interfaces,

      Proto: this.protocols,

      AS_src: this.asNumbers,
      AS_dst: this.asNumbers,
      src_nexthop_asn: this.asNumbers,
      dst_nexthop_asn: this.asNumbers,
      src_second_asn: this.asNumbers,
      dst_second_asn: this.asNumbers,
      src_third_asn: this.asNumbers,
      dst_third_asn: this.asNumbers
    };

    Object.assign(this, options);
  }

  getAutoCompleteFilterHandler = (fieldName) =>
    this.getAutoCompleteHandler(fieldName, this.autoCompleteFilterQueryHandlers);

  getAutoCompleteDimensionHandler = (fieldName) =>
    this.getAutoCompleteHandler(fieldName, this.autoCompleteDimensionHandlers);

  getAutoCompleteHandler = (fieldName, handlers) => {
    let handler = handlers[fieldName];

    if (!handler) {
      if (this.store.$dictionary.flatCustomDimensionOption.find((opt) => opt.value === fieldName)) {
        handler = (filter, options) =>
          this.populators(fieldName, filter, options).then((opts) => [{ value: '', label: 'None' }].concat(opts));
      }
    }

    return handler;
  };

  _clearDebounce(id) {
    if (this.debouncer[id]) {
      clearTimeout(this.debouncer[id]);
    }
  }

  _debounce(fn, id) {
    return new Promise((resolve) => {
      this._clearDebounce(id);

      this.debouncer[id] = setTimeout(() => {
        delete this.debouncer[id];
        resolve(fn());
      }, DEBOUNCE_DURATION);
    });
  }

  _lookup(filter, lookupFn, options = {}) {
    const { debounce = true, id = 'empty', promiseKey } = options;

    if (promiseKey && this.promises[promiseKey]) {
      return this.promises[promiseKey];
    }

    let promise;
    if (filter && debounce) {
      promise = this._debounce(lookupFn, promiseKey || id);
    } else {
      this._clearDebounce();
      promise = lookupFn();
    }

    if (promiseKey) {
      this.promises[promiseKey] = promise;

      promise.finally(() => {
        delete this.promises[promiseKey];
      });
    }

    return promise;
  }

  market = (filter, options) => this.populators('kt_src_market', filter, options);

  populators = (customDimension, filter, options) => {
    const { label } = options || {};
    const lookupFn = () =>
      lookup(
        `/api/ui/lookups/customdimensions/${customDimension}/populators?term=${encodeTerms(filter) || ''}`,
        'field_value',
        label || 'field_value'
      );

    return this._lookup(filter, lookupFn, options);
  };

  customerProviders = (filter, options) => {
    const lookupFn = () => lookup(`/api/ui/lookups/customerProviders?term=${encodeTerms(filter) || ''}`, 'provider');
    return this._lookup(filter, lookupFn, options);
  };

  providers = (filter, options) => {
    const lookupFn = () => lookup(`/api/ui/lookups/providers?term=${encodeTerms(filter) || ''}`, 'provider');
    return this._lookup(filter, lookupFn, options);
  };

  _buildQueryString(term, filters, orderBy) {
    const queryStringComponents = [];

    if (Array.isArray(term)) {
      term.forEach((t) => queryStringComponents.push(`term=${encodeURIComponent(t)}`));
    } else {
      queryStringComponents.push(`term=${encodeURIComponent(term)}`);
    }

    if (orderBy) {
      queryStringComponents.push(`orderBy=${orderBy}`);
    }

    if (filters) {
      Object.keys(filters).forEach((key) => queryStringComponents.push(`${key}=${filters[key]}`));
    }

    return queryStringComponents.join('&');
  }

  devices = async (filter) => localOptionFilter(this.store.$devices.deviceNameOptions, filter);

  deviceInterfaces = (term, options, filters) => {
    const queryString = this._buildQueryString(term, filters, 'device_id');

    const lookupFn = () =>
      lookup(
        `/api/ui/lookups/interfaces?${queryString}`,
        (option) => `${option.device_id}|${option.snmp_id}`,
        (option) => option
      );

    return this._lookup(term, lookupFn, options);
  };

  deviceInterfacesWithMetadata = (term, options, filters = {}) => {
    filters.totalCount = true;
    const queryString = this._buildQueryString(term, filters, 'device_id');

    const lookupFn = () =>
      lookupWithMetadata(
        `/api/ui/lookups/interfaces?${queryString}`,
        (option) => `${option.device_id}|${option.snmp_id}`,
        (option) => option
      );

    return this._lookup(term, lookupFn, options);
  };

  interfaces = (term, options, filters) => {
    const queryString = this._buildQueryString(term, filters, 'device_id');

    const lookupFn = () =>
      lookup(`/api/ui/lookups/interfaces?${queryString}`, 'snmp_id', (option) => (
        <span>
          {option.interface_description} ({option.snmp_id})
          <br />
          <Text small muted>
            {option.snmp_alias}
          </Text>
        </span>
      ));

    return this._lookup(term, lookupFn, options);
  };

  interfaceDescriptions = (term, options, filters) => {
    const params = {
      term: encodeTerms(term) || '',
      orderBy: 'interface_description',
      strict: true,
      ...filters
    };
    const queryString = Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join('&');

    const lookupFn = () =>
      lookup(`/api/ui/lookups/interfaces?${queryString}`, 'interface_description', (option) => (
        <span>
          {option.interface_description}
          <br />
          <Text small muted>
            {option.snmp_alias}
          </Text>
        </span>
      ));

    return this._lookup(term, lookupFn, options);
  };

  snmpAliases = (filter, options) => {
    const lookupFn = () =>
      lookup(
        `/api/ui/lookups/interfaces?term=${encodeTerms(filter) || ''}&orderBy=snmp_alias&strict=true`,
        'snmp_alias'
      );

    return this._lookup(filter, lookupFn, options);
  };

  protocols = (filter, lookupOptions = {}) => {
    const { ignoreFilter } = lookupOptions;

    if (!this.protocolPromise) {
      this.protocolPromise = lookup(
        '/api/ui/lookups/protocols?orderBy=name',
        (option) => option.id.toString(),
        (option) => (
          <span>
            {option.name} <Text muted>({option.id})</Text>{' '}
            <Text small muted>
              {option.description}
            </Text>
          </span>
        ),
        'name'
      );
    }

    return this.protocolPromise.then((options) => (ignoreFilter ? options : localOptionFilter(options, filter)));
  };

  protocolNames = (filter, lookupOptions = {}) => {
    const { ignoreFilter } = lookupOptions;

    if (!this.protocolNamePromise) {
      this.protocolNamePromise = lookup(
        '/api/ui/lookups/protocols?orderBy=name',
        'name',
        (option) => (
          <span>
            {option.name} <Text muted>({option.id})</Text>
            <br />
            <Text small muted>
              {option.description}
            </Text>
          </span>
        ),
        'name'
      );
    }

    return this.protocolNamePromise.then((options) => (ignoreFilter ? options : localOptionFilter(options, filter)));
  };

  countries = (filter, lookupOptions = {}) => {
    const { ignoreFilter } = lookupOptions;

    if (!this.countriesPromise) {
      this.countriesPromise = lookup('/api/ui/lookups/countries', 'iso_country_code', 'country_name');
      this.countriesPromise.then((options) => {
        this.countryOptions = options;
      });
    }

    return this.countriesPromise.then((options) => (ignoreFilter ? options : localOptionFilter(options, filter)));
  };

  siteCountries = (filter, lookupOptions = {}) => {
    const { ignoreFilter } = lookupOptions;

    if (!this.siteCountriesPromise) {
      this.siteCountriesPromise = lookup('/api/ui/lookups/siteCountries', 'country', 'country_name');
      this.siteCountriesPromise.then((options) => {
        this.siteCountryOptions = options;
      });
    }

    return this.siteCountriesPromise.then((options) => (ignoreFilter ? options : localOptionFilter(options, filter)));
  };

  regions = (filter, options) => {
    const lookupFn = () => lookup(`/api/ui/lookups/regions?term=${encodeTerms(filter) || ''}`, 'region_name');
    return this._lookup(filter, lookupFn, options);
  };

  regionIds = (filter, options) => {
    const lookupFn = () =>
      lookup(`/api/ui/lookups/regions?term=${encodeTerms(filter) || ''}`, 'region_code', 'region_name');

    return this._lookup(filter, lookupFn, options);
  };

  regionsByCountry = (filter, options, country) => {
    const lookupFn = () =>
      lookup(`/api/ui/lookups/regions?iso_country_code=${country}&term=${encodeTerms(filter) || ''}`, 'region_name');
    return this._lookup(filter, lookupFn, options);
  };

  // fetches city names from mn_geo_city_latlong
  cities = (filter, options) => {
    const lookupFn = () => lookup(`/api/ui/lookups/cities?term=${encodeTerms(filter) || ''}`, 'city_name');
    return this._lookup(filter, lookupFn, options);
  };

  // fetches city names from mn_geo_city (some currently not present in mn_geo_latlong)
  cityNames = (filter, options) => {
    const lookupFn = () => lookup(`/api/ui/lookups/cityNames?term=${encodeTerms(filter) || ''}`, 'city_name');
    return this._lookup(filter, lookupFn, options);
  };

  cityIds = (filter, options) => {
    const lookupFn = () => lookup(`/api/ui/lookups/cities?term=${encodeTerms(filter) || ''}`, 'id', 'city_name');
    return this._lookup(filter, lookupFn, options);
  };

  citiesByCountry = (country = '', region = '', filter = '', options) => {
    const lookupFn = () =>
      lookup(
        `/api/ui/lookups/cities?iso_country_code=${country}${region ? `&region_name=${region}` : ''}&term=${encodeTerms(
          filter
        )}`,
        'city_name',
        null,
        null,
        ['coord_lat', 'coord_long']
      );

    return this._lookup(filter, lookupFn, options);
  };

  asNumbers = (filter, options = {}) => {
    const { limit = 100 } = options;

    const lookupFn = arrayLookupGenerator(filter, (f) =>
      lookup(
        `/api/ui/lookups/asns?term=${encodeTerms(f) || ''}&limit=${limit}&orderBy=id`,
        (option) => option.id.toString(),
        (option) => (
          <span>
            {option.id}{' '}
            <Text key={option.id} muted>
              ({option.description})
            </Text>
          </span>
        ),
        'description'
      )
    );

    return this._lookup(filter, lookupFn, options);
  };

  asNames = (filter, options) => {
    const lookupFn = arrayLookupGenerator(filter, (f) =>
      lookup(`/api/ui/lookups/asns?term=${encodeTerms(f) || ''}&orderBy=description`, 'description', (option) => (
        <span>
          {option.description}
          <br />
          <Text small muted>
            {option.id}
          </Text>
        </span>
      ))
    );

    return this._lookup(filter, lookupFn, options);
  };

  asData = (filter, options) => {
    const cache = this.cache.create('asData', 3600000);

    const { limit = 100 } = options;
    const lookupFn = arrayLookupGenerator(filter, (f) =>
      lookup(
        `/api/ui/lookups/asns?term=${encodeTerms(f) || ''}&limit=${limit}&orderBy=id`,
        'id',
        'description',
        'id',
        null,
        cache
      )
    );

    return this._lookup(filter, lookupFn, options);
  };

  asGroup = (filter, options = {}) => {
    const { arrayValues = false, prependLabel = false } = options;
    const lookupFn = arrayLookupGenerator(filter, (f) =>
      lookup(
        `/api/ui/lookups/asGroup?term=${encodeTerms(f) || ''}`,
        arrayValues ? (option) => option.asn.reduce((acc, asn) => acc.concat(asn?.id || []), []) : 'field_value',
        prependLabel ? (option) => `Group: ${option.field_value}` : 'field_value',
        'asn'
      )
    );

    return this._lookup(filter, lookupFn, options);
  };

  asNamesKmi = (filter, options) => {
    const myKmiNetworkArr = this.store.$marketIntel.myAsnArr;
    const lookupFn = arrayLookupGenerator(filter, (f) =>
      lookup(
        `/api/ui/lookups/asns/public?term=${encodeTerms(f) || ''}&orderBy=id`,
        (option) => option.id.toString(),
        (option) => (option.description ? option.description.slice(0, -3) : option.id.toString())
      )
    );

    return this._lookup(filter, lookupFn, options).then((results) => {
      if (filter === '' && myKmiNetworkArr.length > 0) {
        const myOptions = myKmiNetworkArr.map((myAsn) => ({
          value: myAsn.toString(),
          label: 'My Network',
          myNetwork: true
        }));
        return [...myOptions, ...results];
      }
      return results;
    });
  };

  cdns = (filter, options) => {
    const lookupFn = () => lookup(`/api/ui/lookups/cdns?term=${encodeTerms(filter) || ''}`, 'name');
    return this._lookup(filter, lookupFn, options);
  };

  ottAddUnknown = (results) =>
    results
      .concat({
        label: 'Unknown',
        matcher: undefined,
        value: 'unknown'
      })
      .sort((a, b) => a.value.localeCompare(b.value));

  ottServiceProviders = (filter, options) => {
    const lookupFn = () => lookup(`/api/ui/lookups/ott/provider?term=${encodeTerms(filter) || ''}`, 'name');
    return this._lookup(filter, lookupFn, options).then(this.ottAddUnknown);
  };

  ottServices = (filter, options) => {
    const lookupFn = () => lookup(`/api/ui/lookups/ott/service?term=${encodeTerms(filter) || ''}`, 'name');
    return this._lookup(filter, lookupFn, options).then(this.ottAddUnknown);
  };

  ottServiceTypes = (filter, options) => {
    const lookupFn = () => lookup(`/api/ui/lookups/ott/type?term=${encodeTerms(filter) || ''}`, 'name');
    return this._lookup(filter, lookupFn, options).then(this.ottAddUnknown);
  };

  deviceLabels = (filter, options) => {
    const lookupFn = () =>
      lookup(
        `/api/ui/lookups/deviceLabels?term=${encodeTerms(filter) || ''}&orderBy=name`,
        (option) => option.id.toString(),
        'name'
      );
    return this._lookup(filter, lookupFn, options);
  };

  flowTags = (filter, options) => {
    const lookupFn = () => lookup(`/api/ui/lookups/flowtags?term=${encodeTerms(filter) || ''}`, 'flow_tag');
    return this._lookup(filter, lookupFn, options);
  };

  sites = (filter, options) => {
    const lookupFn = () => lookup(`/api/ui/lookups/sites?term=${encodeTerms(filter) || ''}`, 'id', 'title');
    return this._lookup(filter, lookupFn, options);
  };

  siteMarkets = (filter, options) => {
    const lookupFn = () => lookup(`/api/ui/lookups/siteMarkets?term=${encodeTerms(filter) || ''}`, 'name', 'name');
    return this._lookup(filter, lookupFn, options);
  };

  plans = (filter, options) => {
    const lookupFn = () => lookup(`/api/ui/lookups/plans?term=${encodeTerms(filter) || ''}`, 'id', 'name');
    return this._lookup(filter, lookupFn, options);
  };

  cloudProviders = (filter, options) => {
    const lookupFn = () => lookup(`/api/ui/lookups/cloudProvider?term=${encodeTerms(filter) || ''}`, 'cloud_provider');
    return this._lookup(filter, lookupFn, options);
  };

  gceProject = (filter, options) => {
    const lookupFn = () =>
      lookup(
        `/api/ui/lookups/gceProject?term=${encodeTerms(filter) || ''}`,
        (option) => option,
        (option) => option,
        'gce_project'
      );
    return this._lookup(filter, lookupFn, options);
  };

  awsRegion = (filter, options) => {
    const lookupFn = () =>
      lookup(
        `/api/ui/lookups/awsRegion?term=${encodeTerms(filter) || ''}`,
        (option) => option,
        (option) => option,
        'aws_region'
      );
    return this._lookup(filter, lookupFn, options);
  };

  awsVpcName = (filter, options) => {
    const lookupFn = () =>
      lookup(
        `/api/ui/lookups/awsVpcName?term=${encodeTerms(filter) || ''}`,
        (option) => option.id,
        (option) => option.name,
        'aws_region'
      );
    return this._lookup(filter, lookupFn, options);
  };

  awsSubnetName = (filter, options) => {
    const lookupFn = () =>
      lookup(
        `/api/ui/lookups/awsSubnetName?term=${encodeTerms(filter) || ''}`,
        (option) => option.id,
        (option) => option.name,
        'aws_region'
      );
    return this._lookup(filter, lookupFn, options);
  };

  azureRegion = (filter, options) => {
    const lookupFn = () =>
      lookup(
        `/api/ui/lookups/azureRegion?term=${encodeTerms(filter) || ''}`,
        (option) => option,
        (option) => option,
        'azure_region'
      );
    return this._lookup(filter, lookupFn, options);
  };

  rpkiQuickStatus = (filter, options) =>
    this._lookup(filter, async () => RPKI_OPTIONS.filter((option) => option.value.includes(filter)), options);

  vrfs = (term = '', filter = {}, options = {}) => {
    const queryString = this._buildQueryString(term, filter, 'id');

    const lookupFn = () => lookup(`/api/ui/lookups/vrfs?${queryString}`, 'id', 'name');

    return this._lookup(term, lookupFn, options);
  };

  applications = (filter, options) => {
    const lookupFn = () =>
      lookup(
        `/api/ui/lookups/applications?term=${encodeTerms(filter) || ''}`,
        'name',
        (option) => (
          <span>
            {option.name}{' '}
            <Text small muted>
              ({option.description})
            </Text>
          </span>
        ),
        'description'
      );
    return this._lookup(filter, lookupFn, options);
  };

  policies = (filter, lookupOptions = {}) => {
    const { ignoreFilter } = lookupOptions;

    if (!this.policiesPromise) {
      this.policiesPromise = lookup('/api/ui/lookups/policies', 'id', 'name', 'name');
    }

    return this.policiesPromise.then((options) => (ignoreFilter ? options : localOptionFilter(options, filter)));
  };

  insightNames = (filter) => {
    const names = this.store.$insights.typesList
      .sort((a, b) => a.label.localeCompare(b.label))
      .map(({ insightName, label, family }) => ({
        label: (
          <span>
            {label}
            <br />
            <Text small muted>
              {family}
            </Text>
          </span>
        ),
        matcher: label,
        value: insightName
      }));

    return localOptionFilter(names, filter);
  };

  interfaceGroups = (filter, options) => {
    const lookupFn = () => lookup(`/api/ui/lookups/interfaceGroups?term=${encodeTerms(filter) || ''}`, 'id', 'name');
    return this._lookup(filter, lookupFn, options);
  };

  // PeeringDB Lookups
  peeringdbFac = (filter, options) => {
    const lookupFn = () => lookup(`/api/ui/lookups/peeringdb/fac?term=${encodeTerms(filter) || ''}`, 'id', 'name');
    return this._lookup(filter, lookupFn, options);
  };

  peeringdbIx = (filter, options) => {
    const lookupFn = () => lookup(`/api/ui/lookups/peeringdb/ix?term=${encodeTerms(filter) || ''}`, 'id', 'name');
    return this._lookup(filter, lookupFn, options);
  };

  peeringdbPPolicy = (filter, options) => {
    const lookupFn = () => lookup(`/api/ui/lookups/peeringdb/policy?term=${encodeTerms(filter) || ''}`, 'policy');
    return this._lookup(filter, lookupFn, options);
  };

  as_number = this.asNumbers;

  as_name = this.asNames;

  as_data = this.asData;

  site = this.sites;

  tag = this.flowTags;

  country = this.countries;

  interface_name = this.snmpAliases;

  interface_description = this.interfaceDescriptions;

  region = this.regionsByCountry;

  cloud_provider = this.cloudProviders;

  gce_proj_id = this.gceProject;

  aws_region = this.awsRegion;

  azure_region = this.azureRegion;

  interface_groups = this.interfaceGroups;

  fac_id = this.peeringdbFac;

  ix_id = this.peeringdbIx;
}

export default new LookupStore();
