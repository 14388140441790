import Collection from 'core/model/Collection';
import SavedFilter from './SavedFilter';

class SavedFilterCollection extends Collection {
  get url() {
    return '/api/ui/saved-filters';
  }

  get model() {
    return SavedFilter;
  }

  get defaultSortState() {
    return {
      field: 'filter_name',
      direction: 'asc'
    };
  }

  get presetFilters() {
    return [
      {
        label: 'Company',
        fn: (model) => model.get('filter_level') === 'company'
      },
      {
        label: 'Preset',
        fn: (model) => model.get('filter_level') === 'global'
      }
    ];
  }

  get filterFieldWhitelist() {
    return new Set(['filter_name', 'filter_description']);
  }

  deserialize(data) {
    const deserialized = super.deserialize(data);
    deserialized.forEach((savedFilter) => {
      if (savedFilter.filters && ['any', 'all'].includes(savedFilter.filters.connector)) {
        savedFilter.filters.connector = savedFilter.filters.connector === 'any' ? 'Any' : 'All';
      }
    });
    return deserialized;
  }
}

export default SavedFilterCollection;
