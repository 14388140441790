import { observable } from 'mobx';
import Collection from 'core/model/Collection';
import SilenceModel from './SilenceModel';

class SilenceCollection extends Collection {
  get url() {
    return '/api/ui/alertingManager/alarms/silence';
  }

  get model() {
    return SilenceModel;
  }

  get fetchMethod() {
    return 'post';
  }

  @observable.ref
  totalCount = 0;

  @observable.ref
  serverFilter = {
    pagination: {
      limit: 1000,
      offset: 0
    }
  };

  async fetch() {
    return super.fetch({ data: this.serverFilter, fetchUrl: '/api/ui/alertingManager/alarms/silence/list' });
  }

  deserialize(data = {}) {
    const { silences, pagination } = data;
    this.totalCount = pagination?.total_count;

    return super.deserialize(silences);
  }
}

export default SilenceCollection;
