import CommentCollection from './CommentCollection';
import CommentModel from './CommentModel';

class CommentsStore {
  collection = new CommentCollection();

  saveNewComment({ comment, entityType, entityId, activeUser }, options = {}) {
    const { toast = true } = options;
    const commentModel = new CommentModel({ comment, entityType, entityId });

    return commentModel.save({}, { toast }).then((savedComment) => {
      commentModel.set(savedComment);
      commentModel.updateUserInfo(activeUser);

      this.collection.add(commentModel);

      return commentModel;
    });
  }
}

export default new CommentsStore();
