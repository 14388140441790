import Collection from 'core/model/Collection';
import { computed } from 'mobx';
import MetricConnectionModel from './MetricConnectionModel';

class MetricConnectionCollection extends Collection {
  get model() {
    return MetricConnectionModel;
  }

  @computed
  get filterFieldWhitelist() {
    return new Set([
      'interface_local',
      'interface_remote_metadata.lldp_rem_port_id',
      'interface_local_metadata.interface_description',
      'interface_local_metadata.snmp_alias',
      'interface_local_metadata.interface_ip',
      'interface_local_metadata.snmp_speed',
      'device_remote',
      'device_remote_metadata.device_name',
      'interface_local_metadata.lldp_rem_sys_name',
      'interface_remote',
      'interface_local_metadata.lldp_rem_port_id',
      'interface_remote_metadata.interface_description',
      'interface_remote_metadata.snmp_alias',
      'connectivityType',
      'networkBoundary'
    ]);
  }
}

export default MetricConnectionCollection;
