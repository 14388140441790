import { computed } from 'mobx';
import Collection from 'core/model/Collection';
import DiscoveryModel from './DiscoveryModel';

export default class DiscoveryCollection extends Collection {
  get model() {
    return DiscoveryModel;
  }

  get url() {
    return '/api/ui/discovery';
  }

  get defaultSortState() {
    return { field: 'cdate', direction: 'desc' };
  }

  @computed
  get activeDiscoveries() {
    return this.models.filter((model) => !model.get('dismissed'));
  }
}
