import React from 'react';
import { action, computed, observable } from 'mobx';
import { get, memoize } from 'lodash';

import { showErrorToast, showInfoToast, showSuccessToast } from 'core/components/toast';
import Collection from 'core/model/Collection';

import { getHashForObject, getQueriesForHash } from 'app/stores/query/urlHash';
import { createQueryStringFromObject, parseQueryString } from 'app/util/utils';
import Export from 'app/components/Export';
import ExportWorker from 'app/assets/Export.worker.js';

export class ExportsStore {
  recentExports = new Collection([], { sortState: { field: 'generated', direction: 'desc' } });

  @observable
  loadingExports = observable.array();

  @observable
  dialogVisible = false;

  hashPromise;

  worker;

  constructor(options = {}) {
    Object.assign(this, options);
  }

  @action
  addPayload = (payload, options) => {
    const entry = { payload, ...options, generated: new Date() };
    this.recentExports.add(entry);
    if (options.showToast !== false) {
      if (options.emailParams) {
        showSuccessToast(
          <div>
            <div>Your report has been successfully shared via email.</div>
          </div>,
          {
            title: 'Email complete'
          }
        );
      } else {
        showSuccessToast(
          <div>
            <div>Your export is ready for download.</div>
            <div>
              <Export {...entry} />
            </div>
          </div>,
          {
            title: 'Export complete',
            timeout: 0
          }
        );
      }
    }
  };

  @action
  addLoadingExport = (options) => {
    const exportVehicle = options?.emailParams ? 'email export' : 'export';
    this.loadingExports.push(options);
    showInfoToast(
      <span>
        {`Your ${exportVehicle} is processing. You may continue with other activities, and we will notify you when your ${exportVehicle} is
        complete.`}
      </span>,
      {
        title: 'Export requested',
        timeout: 4000
      }
    );
  };

  @action
  clearLoadingExport = (options) => {
    const loadingExport = this.isLoading(options);
    this.loadingExports.remove(loadingExport);
  };

  @action
  toggleDialogVisible = () => {
    this.dialogVisible = !this.dialogVisible;
  };

  @action
  setHash(newSettings, merge = true, resetUrlParams = false) {
    if (this.hashPromise) {
      return this.hashPromise.then(() => this.setHash(newSettings, merge, resetUrlParams));
    }

    const { search } = this.history.location;
    let urlParams = {};

    if (merge && search) {
      urlParams = parseQueryString(search);
    }

    this.hashPromise = getHashForObject(newSettings, urlParams.q).then((hash) => {
      this.history.replace({
        ...this.history.location,
        search: createQueryStringFromObject(resetUrlParams ? { q: hash } : { ...urlParams, q: hash })
      });

      this.hashPromise = undefined;

      return hash;
    });

    return this.hashPromise;
  }

  getQueriesForHash = memoize((hash, options) => getQueriesForHash(hash, options));

  @computed
  get hashString() {
    // there's a bug where this.history isn't always up to date, use window.location when available instead
    const { search } = typeof window !== 'undefined' ? window.location : this.history.location;

    if (search) {
      const urlParams = parseQueryString(search);

      if (urlParams.q) {
        return urlParams.q;
      }
    }

    return '';
  }

  getSettings() {
    if (this.store.$app.isSharedLink) {
      const { hash } = this.store.$sharedLinks.data.metadata;
      if (hash) {
        return this.getQueriesForHash(hash, { normalize: false });
      }

      return Promise.resolve({});
    }

    if (this.hashString) {
      return this.getQueriesForHash(this.hashString, { normalize: false });
    }

    return Promise.resolve({});
  }

  initWorker() {
    if (!this.worker) {
      this.worker = new ExportWorker();
      this.worker.onerror = this.onWorkerError;
      this.worker.onmessage = this.onWorkerMessage;
    }
  }

  onWorkerError = ({ message }) => {
    console.warn('Worker Error:', message);
    this.worker = undefined;
  };

  // Handles worker events
  onWorkerMessage = ({ data }) => {
    const { type, output, fileOptions } = data;

    if (type === 'done') {
      this.finishExportCsv(output, fileOptions);
    }
  };

  postWorkerMessage(data) {
    if (!this.worker) {
      this.onWorkerError({ message: 'Worker not initialized.' });
      return;
    }

    try {
      this.worker.postMessage(data);
    } catch (error) {
      this.onWorkerError({ message: 'Could not post a message to the worker.' });
    }
  }

  exportBucketCsv(dataview, bucket, raw) {
    const path = `csv/${dataview.hash}`;
    const appName =
      this.store.$app.isSubtenant && this.store.$auth.hasPermission('subtenancy.advancedMode')
        ? get(this.store.$auth.openConfig, 'subtenancy.config.branding.appName', '').replace(/\s/g, '')
        : 'kentik';
    const fileName = `${appName ? `${appName}-` : ''}export-${raw ? 'chart-' : ''}data-${dataview.hash}`;
    const type = 'csv';
    const options = { path, fileName, type };
    const isFilterDimension = bucket.firstQuery.get('filterDimensionsEnabled');
    let payload;

    this.addLoadingExport(options);

    if (isFilterDimension) {
      payload = raw ? bucket.filterDimensionRawCsv : bucket.filterDimensionCsv;
    } else {
      payload = raw ? bucket.rawCsv : bucket.csv;
    }

    this.clearLoadingExport(options);
    this.addPayload(payload, options);
  }

  getExportFileName(fileName) {
    const appName =
      this.store.$app.isSubtenant && this.store.$auth.hasPermission('subtenancy.advancedMode')
        ? get(this.store.$auth.openConfig, 'subtenancy.config.branding.appName', '').replace(/\s/g, '')
        : 'kentik';

    return `${appName ? `${appName}-` : ''}${fileName}`;
  }

  exportCsv(options) {
    const { columns, data, asyncData, fileName } = options;

    const fileOptions = {
      fileName: this.getExportFileName(fileName),
      type: 'csv'
    };

    this.initWorker();
    this.addLoadingExport(fileOptions);

    if (data && Array.isArray(data)) {
      this.postWorkerMessage({ lines: data, columns, fileOptions });
    } else if (asyncData && typeof asyncData === 'function') {
      asyncData().then((lines) => {
        this.postWorkerMessage({ lines, fileOptions });
      });
    } else {
      this.clearLoadingExport(fileOptions);

      showErrorToast(null, {
        title: 'Export failed',
        timeout: 5000,
        message:
          'Your export failed to process. Please try again, or contact support@kentik.com for further assistance.'
      });
    }
  }

  finishExportCsv(output, fileOptions) {
    this.clearLoadingExport(fileOptions);
    this.addPayload(output, fileOptions);
  }

  @action
  fetchExport(options) {
    const {
      urlParams,
      emailParams,
      path,
      type,
      showToast = true,
      passLocation = false,
      exportOptions,
      fileName,
      date
    } = options;
    const { scale, fullPage, sleepDuration, generatePdfTimeout, selectorOptions, selector } = exportOptions || {};
    const req = new XMLHttpRequest();
    const appName =
      this.store.$app.isSubtenant && this.store.$auth.hasPermission('subtenancy.advancedMode')
        ? get(this.store.$auth.openConfig, 'subtenancy.config.branding.appName', '').replace(/\s/g, '')
        : 'kentik';
    options.fileName = `${appName ? `${appName}-` : ''}${fileName}`;

    const body = {
      appName,
      format: type,
      urlParams,
      emailParams,
      location: exportOptions ? exportOptions.location : undefined,
      scale: exportOptions && scale ? scale : undefined,
      fullPage: exportOptions && fullPage ? fullPage : undefined,
      selectorOptions,
      selector,
      date
    };

    // only include this in body if it was explicitly set in exportOptions
    if (sleepDuration) {
      Object.assign(body, { sleepDuration });
    }
    if (generatePdfTimeout) {
      Object.assign(body, { generatePdfTimeout });
    }

    if (passLocation) {
      body.location = `${
        this.history.location.pathname.startsWith('/v4/')
          ? this.history.location.pathname.replace('/v4/', '/v4/export/')
          : this.history.location.pathname
      }${this.history.location.search}`;
    }

    req.open('POST', path, true);
    req.setRequestHeader('X-CSRF-Token', this.store.$auth.csrfToken);
    req.setRequestHeader('Content-Type', 'application/json');
    req.responseType = 'blob';
    req.timeout = 600000;

    req.onload = () => {
      this.clearLoadingExport(options);

      if (req.status >= 400) {
        showErrorToast(null, {
          title: 'Export failed',
          timeout: 5000,
          message:
            'Your export failed to process. Please try again, or contact support@kentik.com for further assistance.'
        });
      } else {
        this.addPayload(req.response, options);
      }
    };

    req.onerror = () => {
      this.clearLoadingExport(options);
      showErrorToast(null, {
        title: 'Export failed',
        timeout: 5000,
        message:
          'Your export failed to process. Please try again, or contact support@kentik.com for further assistance.'
      });
    };

    if (showToast) {
      this.addLoadingExport(options);
    } else {
      showInfoToast(
        <span>
          Your export is processing. You may continue with other activities, and we will notify you if we encounter any
          issues.
        </span>,
        {
          title: 'Export requested',
          timeout: 4000
        }
      );
    }

    req.send(JSON.stringify(body));
  }

  isLoading({ path, type }) {
    return this.loadingExports.find((exp) => exp.path === path && exp.type === type);
  }
}

export default new ExportsStore();
