import {
  getBaselineMinLookbackSeconds,
  getBaselineMinLookbackValues,
  getBaselineOverallSetting
} from 'app/stores/alerting/policyUtils';

function deserialize(policy) {
  const {
    baselineSettings = {
      startOffset: '',
      length: ''
    },
    ...restPolicy
  } = policy;

  const finalizedPolicyWithBaseline = {
    ...restPolicy,
    baselineSettings: {
      ...baselineSettings,
      startOffset: Number(baselineSettings.startOffset.replace('s', '')),
      length: Number(baselineSettings.length.replace('s', '')),
      ...getBaselineMinLookbackValues(baselineSettings.minimalLength),
      datapointAggregationMethod:
        baselineSettings.datapointAggregationMethod !== 'None' ? baselineSettings.datapointAggregationMethod : '',
      comparisonValueAggregateFunction:
        baselineSettings.comparisonValueAggregateFunction !== 'None'
          ? baselineSettings.comparisonValueAggregateFunction
          : '',
      neighbourhoodAggregationMethod:
        baselineSettings.neighbourhoodAggregationMethod !== 'None'
          ? baselineSettings.neighbourhoodAggregationMethod
          : '',
      neighbourhoodRadius: baselineSettings.neighbourhoodRadius || 1
    }
  };

  finalizedPolicyWithBaseline.overall_baseline_setting = getBaselineOverallSetting(finalizedPolicyWithBaseline);

  return finalizedPolicyWithBaseline;
}

function serialize(policy) {
  const { baselineSettings, ...restPolicy } = policy;

  if (!policy.baselineSettings) {
    return restPolicy;
  }

  // drop the UI-only field
  const { minimal_length_unit, ...restBaselineSettings } = baselineSettings;

  return {
    ...restPolicy,
    baselineSettings: {
      ...restBaselineSettings,
      startOffset: `${baselineSettings.startOffset}s`,
      length: `${baselineSettings.length}s`,
      // this comes to us in seconds initially from the api
      minimalLength: `${getBaselineMinLookbackSeconds(
        baselineSettings.minimalLength,
        baselineSettings.minimal_length_unit
      )}s`,
      datapointAggregationMethod: baselineSettings.datapointAggregationMethod || 'None',
      neighbourhoodAggregationMethod: baselineSettings.neighbourhoodAggregationMethod || 'None',
      neighbourhoodRadius: baselineSettings.neighbourhoodAggregationMethod ? baselineSettings.neighbourhoodRadius : 0,
      comparisonValueAggregateFunction: baselineSettings.comparisonValueAggregateFunction || 'None'
    }
  };
}

export default {
  serialize,
  deserialize
};
