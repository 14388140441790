import { get } from 'lodash';
import { action, autorun, observable } from 'mobx';

import { getQueriesForHash } from 'app/stores/query/urlHash';
import $app from 'app/stores/$app';
import DataViewModel from 'app/stores/query/DataViewModel';
import ExplorerQueryModel from 'app/stores/query/ExplorerQueryModel';
import QueryModel from 'app/stores/query/QueryModel';
import applyMaxDateRangeToQuery from 'app/util/mkp/applyMaxDateRangeToQuery';
import {
  applyTenantCustomDimensionFilters,
  augmentQueryForCustomDimensions,
  getCustomDimensionsFromOverrides
} from 'app/util/mkp/customDimensionUtils';
import getTenantPreviewFilters from 'app/util/mkp/getTenantPreviewFilters';
import { deepClone } from 'core/util';
import { timezone } from 'core/util/dateUtils';

class ExplorerStore {
  dataview = new DataViewModel();

  @observable.ref
  formState;

  tagPrefix = 'tag_';

  @observable
  suppressed = false;

  // This is used by DashboardItemForm.
  // It sits in this store, because we use $explorer.loadView() to load the item query to edit.
  // queryModel then gets shared across the ItemExplorer, Clone Dash Item and Dash Item Save functionality.
  // We should probably refactor all of this.
  queryModel = undefined;

  /**
   * @param query {object}
   * @returns {Promise<string>}
   */
  getQueryPath(query) {
    const model = query instanceof QueryModel ? query : QueryModel.create(query);
    return this.loadFromQueries(model).then(() => `/v4/core/explorer/${this.dataview.hash}`);
  }

  navigateToExplorer(query, openInNewWindow = false) {
    this.getQueryPath(query).then((path) => {
      if (openInNewWindow === true) {
        window.open(path, '_blank');
      } else {
        this.history.push(path);
      }
    });
  }

  initializeHashDisposer(navigate = true) {
    if (this.dataview) {
      this.dataview.history = this.history;
    }

    if (!this.hashDisposer) {
      this.hashDisposer = autorun(() => {
        if (!this.dataview.hash || (this.hasLoaded && this.dataview.hashSource === 'init')) {
          return;
        }

        if (!$app.isExport && navigate) {
          const state = { lastHash: this.lastHash };
          const path = `/v4/core/explorer/${this.dataview.hash}`;

          if (this.hasLoaded && this.lastHash) {
            this.history.push(path, state);
          } else if (!this.store.$app.isSharedLink) {
            this.history.replace(path, state);
          }
        }

        this.lastHash = this.dataview.hash;
        this.hasLoaded = true;
      });
    }
  }

  @action
  loadView(view, navigate, overrides) {
    const isPreviewFromTenant = get(overrides, 'filters.filterGroups', []).find(
      (filterGroup) => filterGroup.name && filterGroup.name.startsWith('mkp_preview')
    );

    if (this.store.$app.isSubtenant || get(this.history.location, 'state.previewingTenant') || isPreviewFromTenant) {
      const custom_dimensions =
        (isPreviewFromTenant && getCustomDimensionsFromOverrides(overrides)) ||
        this.store.$auth.getActiveUserProperty('userGroup.config.custom_dimensions') ||
        get(this.history.location.state, 'previewingTenant.config.custom_dimensions');

      const maxDateRange =
        this.store.$auth.getActiveUserProperty('userGroup.config.max_date_range') ||
        get(this.history.location.state, 'previewingTenant.config.max_date_range');

      if (maxDateRange) {
        return getQueriesForHash(view.get('saved_query_id')).then(([{ query }]) => {
          const { lookback_seconds, starting_time, ending_time } = applyMaxDateRangeToQuery(query, maxDateRange);
          return this.loadHash(
            view.get('saved_query_id'),
            navigate,
            augmentQueryForCustomDimensions(
              view,
              { ...(overrides || {}), lookback_seconds, starting_time, ending_time },
              custom_dimensions
            )
          );
        });
      }
      return this.loadHash(
        view.get('saved_query_id'),
        navigate,
        augmentQueryForCustomDimensions(view, overrides, custom_dimensions)
      );
    }
    return this.loadHash(view.get('saved_query_id'), navigate, overrides);
  }

  @action
  reloadView(view) {
    this.loadView(view);
  }

  @action
  loadHash(hash, navigate, overrides) {
    this.initializeHashDisposer(navigate);

    if (hash) {
      if (overrides) {
        this.dataview.initializeHashWithOverrides(hash, overrides);
      } else {
        this.dataview.initializeHash(hash);
      }
    } else if (this.lastHash) {
      if (overrides) {
        this.dataview.initializeHashWithOverrides(this.lastHash, overrides);
      } else {
        this.dataview.initializeHash(this.lastHash);
      }
    } else {
      const defaultQuery = QueryModel.create();
      defaultQuery.set(overrides);
      this.loadFromQueries(defaultQuery);
    }
  }

  reset() {
    const defaultQuery = QueryModel.create();
    this.loadFromQueries(defaultQuery);
  }

  @action
  reloadHash(hash) {
    if (hash && hash !== this.lastHash) {
      this.dataview.initializeHash(hash);
    } else {
      this.history.replace(`/v4/core/explorer/${this.lastHash}`, { lastHash: this.lastHash });
    }
  }

  loadFromQueries(queries) {
    if (!Array.isArray(queries)) {
      queries = [queries];
    }

    return this.dataview.initializeQueries(
      queries.map((query) => ({
        bucket: query.get('bucket'),
        query: query.serialize()
      }))
    );
  }

  loadPreset() {
    this.initializeHashDisposer();

    let defaultQuery;

    if (window.localStorage && window.localStorage.kt_explorer_preset) {
      defaultQuery = QueryModel.create(JSON.parse(window.localStorage.kt_explorer_preset));
      delete window.localStorage.kt_explorer_preset;
    } else {
      defaultQuery = QueryModel.create();
    }

    this.loadFromQueries(defaultQuery);
  }

  loadDefaults() {
    this.initializeHashDisposer();

    const defaultQuery = QueryModel.create();

    const { defaultAggregateTypes, defaultAggregates, defaultAggregateThresholds, defaultAggregateSorts } =
      this.store.$auth.userSettings;

    if (defaultAggregates) {
      defaultQuery.set({ aggregates: defaultAggregates });
    }
    if (defaultAggregateTypes) {
      defaultQuery.set({ aggregateTypes: defaultAggregateTypes });
    }
    if (defaultAggregateThresholds) {
      defaultQuery.set({ aggregateThresholds: defaultAggregateThresholds });
    }
    if (defaultAggregateSorts) {
      if (defaultAggregateSorts.outsortValue) {
        defaultQuery.set({ outsort: defaultAggregateSorts.outsortValue });
      }
      if (defaultAggregateSorts.secondaryOutsortValue) {
        defaultQuery.set({ secondaryOutsort: defaultAggregateSorts.secondaryOutsortValue });
      }
      if (defaultAggregateSorts.secondaryTopxSeparateValue) {
        defaultQuery.set({ secondaryTopxSeparate: defaultAggregateSorts.secondaryTopxSeparateValue });
      }
    }

    this.loadFromQueries(defaultQuery);
  }

  loadUrlParams(params) {
    this.initializeHashDisposer();

    const defaultQuery = QueryModel.create();
    if (params) {
      defaultQuery.set(JSON.parse(decodeURIComponent(params)));
    }

    this.loadFromQueries(defaultQuery);
  }

  applySuppressedChanges = () => {
    if (this.formState) {
      this.suppressed = false;
      this.onFormChange({ form: this.formState, formValues: this.formState.getValues(), suppress: false });
    }
  };

  cancelSuppressedChanges = () => {
    if (this.formState) {
      this.formState.reset();
      this.suppressed = false;
    }
  };

  applyTenantFilter = (tenant) => {
    const currentFilters = deepClone(this.dataview.queryBuckets.selectedQuery.get('filters'));

    this.history.replace(this.history.location.pathname, {
      ...this.history.location.state,
      previewingTenant: tenant.toJS()
    });

    const tenantPreviewFilters = getTenantPreviewFilters(tenant, currentFilters);
    const tenantCustomDimensions = tenant.get('config').custom_dimensions;
    const filters =
      tenantCustomDimensions && tenantCustomDimensions.length
        ? applyTenantCustomDimensionFilters(tenantCustomDimensions, tenantPreviewFilters)
        : tenantPreviewFilters;

    const maxDateRange = tenant.get('config').max_date_range;
    let timeOverrides = {};
    if (maxDateRange) {
      const { lookback_seconds, starting_time, ending_time } = applyMaxDateRangeToQuery(
        this.dataview.queryBuckets.selectedQuery.toJS(),
        maxDateRange
      );
      timeOverrides = { lookback_seconds, starting_time, ending_time };
    }
    this.loadHash(null, false, {
      filters,
      ...timeOverrides
    });
  };

  onFormChange = ({ form, formValues, field, suppress }) => {
    if (
      !suppress &&
      !this.suppressed &&
      (!field || !field.initialConfig || !field.initialConfig.suppressAutoSubmit) &&
      form.dirty &&
      form.validate()
    ) {
      form.submit(() => {
        const model = ExplorerQueryModel.createFromQueryModel(this.dataview.queryBuckets.selectedQuery);
        model.set(formValues);

        if (this.queryModel) {
          this.queryModel = model;
        }

        this.apply(QueryModel.create(model.serialize()));
        setTimeout(() => form.setModel(model));

        if (this.onSubmit) {
          this.onSubmit(model);
        }

        this.suppressed = false;
      });
    } else if (suppress) {
      this.suppressed = true;
    }
  };

  @action
  registerFormState(form, onSubmit) {
    this.formState = form;
    this.onSubmit = onSubmit;
    if (this.dataview) {
      this.dataview.registerFormState(form);
    }

    this.formState.onChange = this.onFormChange;

    this.timeDisposer =
      this.timeDisposer ||
      autorun(() => {
        const { dataview, formState } = this;
        if (formState.dirtyGroups && formState.dirtyGroups.time) {
          dataview.resetTimeRange(true);
          const { lookback_seconds, starting_time, ending_time } = formState.getValues();
          let width = lookback_seconds;
          if (!width) {
            width = timezone.momentFn(ending_time).diff(timezone.momentFn(starting_time), 'second');
          }
          if (width > 3600 * 24 * 7) {
            formState.getField('show_overlay').init(false);
          }
        }
        if (dataview.timeOverride) {
          ['lookback_seconds', 'starting_time', 'ending_time'].forEach((fieldName) => {
            formState.getField(fieldName).init(dataview.timeOverride[fieldName]);
          });
        }
      });
  }

  apply(query, redrawOnly = false, debounce = true) {
    if (redrawOnly) {
      this.dataview.applyToSelectedQuery(query, redrawOnly);
    } else {
      if (this.debouncedApply) {
        clearTimeout(this.debouncedApply);
      }

      this.dataview.queryBuckets.activeBuckets.forEach((bucket) => {
        bucket.setLoading(true);
        bucket.queryResults.setRequestStatus('fetching');
      });

      if (debounce) {
        this.debouncedApply = setTimeout(() => this.dataview.applyToSelectedQuery(query), 2000);
      } else {
        this.dataview.applyToSelectedQuery(query);
      }
    }
  }

  preset(query) {
    if (window.localStorage) {
      window.localStorage.kt_explorer_preset = JSON.stringify(query);
    }
  }

  @action
  clear() {
    if (this.hashDisposer) {
      this.hashDisposer = null;
    }
    if (this.timeDisposer) {
      this.timeDisposer();
      this.timeDisposer = null;
    }
    this.hasLoaded = false;
    this.lastHash = null;
    this.formState = null;
    this.onSubmit = null;
  }

  @action
  destroy(preserveHash = false) {
    if (this.hashDisposer) {
      this.hashDisposer();
      this.hashDisposer = null;
    }
    if (this.timeDisposer) {
      this.timeDisposer();
      this.timeDisposer = null;
    }
    this.dataview.destroy();
    this.hasLoaded = false;
    if (!preserveHash) {
      this.lastHash = null;
    }
    this.formState = null;
    this.suppressed = false;
  }
}

export default new ExplorerStore();
