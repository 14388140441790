import Collection from 'core/model/Collection';
import DnsResultModel from './DnsResultModel';

class DnsResultCollection extends Collection {
  get model() {
    return DnsResultModel;
  }

  get defaultSortState() {
    return { field: 'agent', direction: 'asc' };
  }

  get secondarySort() {
    return { field: 'target', value: 'asc' };
  }
}

export default DnsResultCollection;
