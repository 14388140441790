import { escapeRegExp, get } from 'lodash';
import { computed, observable } from 'mobx';
import CloudMapCollection from './CloudMapCollection';

export default class KubeCloudMapCollection extends CloudMapCollection {
  searchableIdPaths = ['id'];

  searchableCIDRPaths = [
    'spec.clusterIP',
    'spec.clusterIPs',
    'status.addresses',
    'status.hostIP',
    'status.podIP',
    'status.podIPs',
    'metadata.kubernetesNetworkConfig.serviceIpv6Cidr',
    'metadata.kubernetesNetworkConfig.serviceIpv4Cidr'
  ];

  @observable.ref
  initialTopology = { Hierarchy: { regions: [] }, Entities: {}, Links: [], traffic: [] };

  get url() {
    return '/api/ui/topology/cloud-hierarchy/kube';
  }

  @computed
  get topology() {
    const { initialTopology } = this;
    const { Hierarchy } = initialTopology;

    return {
      ...this.initialTopology,
      Hierarchy: {
        regions: Hierarchy.regions.map((region) => this.hydrateHierarchy({ entity: region, entityType: 'region' }))
      }
    };
  }

  @computed
  get filterStateGroups() {
    const query = this.filterState || '';
    const groups = { ids: [], cidrs: [] };

    query
      .split(',')
      .map((term) => term.trim())
      .filter(Boolean)
      .forEach((term) => {
        if (this.isValidIP(term)) {
          groups.cidrs.push(term);
        } else {
          groups.ids.push(term);
        }
      });

    return groups;
  }

  getSearchableCIDRList(data) {
    return this.searchableCIDRPaths
      .flatMap((path) => {
        const value = get(data, path);

        if (typeof value === 'string') {
          return value;
        }

        if (Array.isArray(value)) {
          return value.map((item) => {
            if (typeof item === 'string') {
              return item;
            }

            if ('ip' in item) {
              return item.ip;
            }

            if ('address' in item && (item.type === 'InternalIP' || item.type === 'ExternalIP')) {
              return item.address;
            }

            return null;
          });
        }

        return null;
      })
      .filter(Boolean);
  }

  getEntity({ entityType, entityId }) {
    const { Entities } = this.initialTopology;

    if (entityType === 'resources') {
      for (const type in Entities) {
        if (Entities[type][entityId]) {
          return Entities[type][entityId];
        }
      }

      return {};
    }

    return super.getEntity({ entityType, entityId });
  }

  filter(query) {
    const discreteFilters = [];
    this.filterState = query || '';

    Object.entries(this.filterStateGroups).forEach(([groupName, values]) => {
      if (values.length > 0) {
        if (groupName === 'ids') {
          discreteFilters.push({
            type: groupName,
            values,
            fn: (model) =>
              values.find((value) =>
                model.searchableData.ids.find((id) => new RegExp(escapeRegExp(value), 'i').test(id))
              )
          });
        } else if (groupName === 'cidrs') {
          discreteFilters.push({
            type: groupName,
            values,
            fn: (model) =>
              values.find((value) => model.searchableData.cidrs.find((cidr) => this.isInSubnet(value, cidr)))
          });
        }
      }
    });

    this.models.replace(this.get());

    if (discreteFilters.length > 0) {
      discreteFilters.forEach((filter) => {
        this.models.replace(this.models.filter((model) => filter.fn(model)));
      });
    }

    this.sort();

    return this.models;
  }

  deserialize(response) {
    const { Entities } = response;
    this.initialTopology = response;

    const models = ['regions', 'clusters', 'namespaces'].reduce(
      (acc, entityType) => acc.concat(this.getActiveEntities({ entityType, allowUnlinked: true })),
      []
    );

    const clusters = response.Hierarchy.regions.reduce((acc, region) => acc.concat(region.clusters), []);
    const modelsWithSearchableData = this.addSearchableDataToModels({
      entities: clusters,
      models
    });

    // add IPs to cluster entities
    modelsWithSearchableData
      .filter((model) => model.entityType === 'clusters')
      .forEach((cluster) => {
        Object.assign(Entities.clusters[cluster.id], { ips: cluster.kentik?.searchableCIDRs || [] });
      });

    return modelsWithSearchableData;
  }
}
