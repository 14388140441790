import { action, computed, observable } from 'mobx';
import { uniqueId } from 'lodash';
import moment from 'moment';
import { TEST_STATUS } from 'shared/synthetics/constants';
import { getHealthTimeline, getTimelineAlarmsByResultTimeMs, makeAlertManagerAlarm } from 'shared/synthetics/utils';
import {
  DEFAULT_LOOKBACK,
  DEFAULT_MESH_LOOKBACK,
  DEFAULT_MIN_LOOKBACK,
  TEST_TYPES,
  SYNGEST_CUTOVER
} from 'app/util/constants';
import { parseQueryString } from 'app/util/utils';
import {
  getTimelinebounds,
  setLookbackFromHistory,
  getMinLookback,
  getAggregationLabel
} from 'app/views/synthetics/utils/syntheticsUtils';
import { getStartAndEndFromLookback } from 'core/util/dateUtils';
import { processTestResults, enrichTracerouteLookups, getTraceroutes } from './utils';

class TestResultsState {
  get defaults() {
    return {
      test: null,
      init: false,
      lookbackSeconds: DEFAULT_LOOKBACK,
      minLookbackSeconds: DEFAULT_MIN_LOOKBACK,
      startDate: null,
      endDate: null,
      actualStartDate: null,
      actualEndDate: null,
      loadingTraceResults: false,
      traceResultTimeMs: null,
      pageTabId: 'agents',
      defaultTabId: undefined,
      hasTraceResults: false,
      loadingAlarmResults: false,
      hasAlarmResults: false,
      alarmResults: null,
      timestamps: [],
      loadingTimestamps: false,
      loadingResults: false,
      resultsTs: {},
      resultTime: null,
      aggregationData: {}, // returned from syngest results call --- includes maxRawLookback and isAggregated properties
      displayOptions: {
        allowSettings: true,
        usePageComponent: true,
        shared: false,
        hideTitle: false,
        hideHealthAggAgentSelector: false,
        allowMesh: false,
        allowSankey: false,
        allowMap: false,
        allowHealthAgg: false,
        allowPath: false,
        allowHealthTimeline: false,
        allowBgp: false,
        allowAgentDetails: false,
        allowShare: true,
        allowExport: true,
        allowSummary: true
      }
    };
  }

  test = this.defaults.test;

  requestId = uniqueId('tr_');

  displayOptions = this.defaults.displayOptions;

  @observable
  init = this.defaults.init;

  @observable
  lookbackSeconds = this.defaults.lookbackSeconds;

  @observable
  minLookbackSeconds = this.defaults.minLookbackSeconds;

  @observable
  startDate = this.defaults.startDate;

  @observable
  endDate = this.defaults.endDate;

  @observable
  actualStartDate = this.defaults.actualStartDate;

  @observable
  actualEndDate = this.defaults.actualEndDate;

  @observable
  pageTabId = this.defaults.pageTabId;

  @observable
  defaultTabId = this.defaults.defaultTabId;

  history = null;

  @observable
  loadingAlarmResults = this.defaults.loadingAlarmResults;

  @observable
  hasAlarmResults = this.defaults.hasAlarmResults;

  @observable.ref
  alarmResults = this.defaults.alarmResults;

  @observable.ref
  timestamps = this.defaults.timestamps;

  @observable
  loadingTimestamps = this.defaults.loadingTimestamps;

  resultsTs = this.defaults.resultsTs;

  @observable
  loadingResults = false;

  @observable
  loadingTraceResults = this.defaults.loadingTraceResults;

  @observable
  traceResultTimeMs = this.defaults.traceResultTimeMs;

  @observable
  hasTraceResults = this.defaults.hasTraceResults;

  @observable.ref
  traceResults = this.defaults.hasTraceResults;

  @observable
  resultTime = this.defaults.resultTime;

  @observable.ref
  aggregationData = this.defaults.aggregationData;

  meshState = {};

  constructor({ store, test }) {
    this.store = store;
    this.test = test;
  }

  newRequestID() {
    this.requestId = uniqueId('tr_');
  }

  configure({ startDate, endDate, lookbackSeconds, history, force = false, ...displayOptions } = {}) {
    const { test } = this;
    const { hasPing, hasBGPMonitoring } = test;
    const isDns = test.get('test_type') === TEST_TYPES.DNS;

    if (!this.init) {
      Object.entries(displayOptions).forEach(([key, value]) => {
        if (this.displayOptions[key] !== undefined) {
          this.displayOptions[key] = value;
        }
      });

      const { usePageComponent, shared } = this.displayOptions;

      this.displayOptions.allowMesh = !isDns;
      this.displayOptions.allowSankey = !isDns;
      this.displayOptions.allowMap = !isDns || !this.store.$app.isExport;
      this.displayOptions.allowPath = hasPing;
      this.displayOptions.allowHealthTimeline = true;
      this.displayOptions.allowBgp = hasBGPMonitoring && usePageComponent;
      this.displayOptions.allowAgentDetails = !shared;
      this.history = history;

      this.initiate({ startDate, endDate, lookbackSeconds });
    } else if (
      force ||
      (startDate && this.startDate !== startDate) ||
      (endDate && this.endDate !== endDate) ||
      (lookbackSeconds && this.lookbackSeconds !== lookbackSeconds)
    ) {
      this.onDateRangeChange({ startDate, endDate, lookbackSeconds });
    }
  }

  initiate({ startDate, endDate, lookbackSeconds }) {
    const { history, test } = this;
    const { $exports } = this.store;
    const { allowSettings } = this.displayOptions;
    const hasLocationHistory =
      this.checkForDateRange(history?.location?.state || {}) || parseQueryString(history?.location?.search).start;
    const hasBeenProvidedADateRange = this.checkForDateRange({ startDate, endDate, lookbackSeconds });
    const defaultLookbackSeconds = this.test.isMesh ? DEFAULT_MESH_LOOKBACK : this.lookbackSeconds;
    let initPromise = Promise.resolve({
      startDate: this.startDate,
      endDate: this.endDate,
      lookbackSeconds: defaultLookbackSeconds
    });

    // we support too many ways to configure date range
    if (hasBeenProvidedADateRange) {
      initPromise = Promise.resolve({ startDate, endDate, lookbackSeconds });
    } else if (hasLocationHistory && allowSettings) {
      initPromise = setLookbackFromHistory({ $exports, history, test }).then(() => null);
    }

    return initPromise
      .then((dateObj) => {
        if (allowSettings) {
          return $exports
            .getSettings()
            .then(({ hashedDefaultTabId, hashedPageTabId, hashedLookbackSeconds, hashedStartDate, hashedEndDate }) => {
              if (hashedPageTabId !== undefined) {
                this.onPageTabChange(hashedPageTabId);
              }

              if (hashedDefaultTabId !== undefined) {
                this.onResultsTabChange(hashedDefaultTabId);
              }

              const hasDateRangeSettings = this.checkForDateRange({
                startDate: hashedStartDate,
                endDate: hashedEndDate,
                lookbackSeconds: hashedLookbackSeconds
              });

              if (hasDateRangeSettings && !hasBeenProvidedADateRange) {
                return {
                  startDate: hashedStartDate,
                  endDate: hashedEndDate,
                  lookbackSeconds: hashedLookbackSeconds
                };
              }
              return dateObj;
            });
        }
        return dateObj;
      })
      .then((dateObj) => {
        this.onDateRangeChange(dateObj);
      });
  }

  @action
  reset() {
    this.newRequestID();

    const entries = Object.entries(this.defaults);

    for (let i = 0; i < entries.length; i += 1) {
      const [key, value] = entries[i];
      this[key] = value;
    }
  }

  fetch({ startDate, endDate }) {
    this.newRequestID();
    this.fetchResultsTimelines({ start_time: startDate, end_time: endDate });
    this.fetchAlarmResults({
      start: moment.utc(startDate * 1000).toISOString(),
      end: moment.utc(endDate * 1000).toISOString()
    });
    this.fetchTraceResults({ start_time: startDate, end_time: endDate });
  }

  @action
  fetchResultsTimelines(dateObj) {
    const { $syn } = this.store;
    const { requestId, test } = this;
    const { isPreset } = test;

    this.loadingTimestamps = true;
    this.timestamps = this.defaults.timestamps;
    this.resultsTs = this.defaults.resultsTs;
    this.resultTime = this.defaults.resultTime;
    this.aggregationData = this.defaults.aggregationData;

    if (!this.init) {
      this.init = true;
    }

    $syn.requests.fetchTestResultsTimestamps({ ...dateObj, test_id: `${test.id}` }, { preset: isPreset }).then(
      action(({ timestamps, aggregation }) => {
        // check if request is relevant before storing response
        if (requestId === this.requestId) {
          const hasTimestamps = !!timestamps.length;
          const ts = hasTimestamps ? timestamps[timestamps.length - 1] : null;

          this.timestamps = hasTimestamps ? timestamps : [];
          this.resultTime = +ts;
          this.loadingTimestamps = false;

          if (hasTimestamps) {
            this.fetchResultsByTs(ts, aggregation);
          }
        }
      })
    );
  }

  @action
  fetchAlarmResults(dateObj) {
    const { $syn } = this.store;
    const { requestId, test } = this;

    // reset state
    this.alarmResults = this.defaults.alarmResults;
    this.hasAlarmResults = this.defaults.hasAlarmResults;
    this.loadingAlarmResults = true;

    return $syn.requests.getTestAlarms({ ...dateObj, ids: [test.id] }).then(
      action((resp) => {
        // check if request is relevant before storing response
        if (requestId === this.requestId) {
          this.alarmResults = {
            ...resp,
            alarms: resp.alarms.map(makeAlertManagerAlarm)
          };
          this.hasAlarmResults = !!resp?.alarms.length;
          this.loadingAlarmResults = false;
        }
      })
    );
  }

  @action
  fetchResultsByTs(ts, aggregation) {
    const { $syn } = this.store;
    const { requestId, test } = this;
    const { isPreset } = test;

    if (!this.resultsTs[ts]) {
      this.loadingResults = true;

      $syn.requests
        .fetchTestResultsByTimestamp({ timestamp: ts, aggregation, test_id: `${test.id}` }, { preset: isPreset })
        .then(
          action((resp) => {
            // check if request is relevant before storing response
            if (requestId === this.requestId) {
              this.resultsTs[ts] = resp?.tests_health || null;

              // capture the max raw lookback and aggregation state of the syngest results
              this.aggregationData = resp?.tests_health?.book?.aggregationData || {};

              // add the aggregation returned from the timestamps call to aggregationData
              // this will now match what comes back from subtest result responses
              this.aggregationData.aggregation = aggregation;

              if (this.resultTime === +ts) {
                this.loadingResults = false;
              }
            }
          })
        );
    }
  }

  @action
  fetchTraceResults(dateObj) {
    const { $syn } = this.store;
    const { requestId, test } = this;
    const { id, isPreset } = test;
    const { allowPath } = this.displayOptions;

    if (allowPath) {
      // reset state
      this.traceResults = this.defaults.traceResults;
      this.traceResultTimeMs = this.defaults.traceResultTimeMs;
      this.hasTraceResults = this.defaults.hasTraceResults;
      this.loadingTraceResults = true;

      $syn.requests.fetchTestTraceroutee(id, { ...dateObj, id }, { preset: isPreset }).then(
        action((traceResults) => {
          // check if request is relevant before storing response
          if (requestId === this.requestId) {
            const hasTraceResults = this.checkForTraceResults(traceResults);

            if (hasTraceResults) {
              const { trace_ts = {} } = traceResults?.results || {};
              const tsKeys = Object.keys(trace_ts);
              const timeMs = +tsKeys[tsKeys.length - 1] * 1000;

              traceResults.lookups = enrichTracerouteLookups(traceResults.lookups, $syn);

              this.traceResults = traceResults;
              this.traceResultTimeMs = timeMs;
            }

            this.hasTraceResults = hasTraceResults;
            this.loadingTraceResults = false;
          }
        })
      );
    }
  }

  checkForTraceResults({ results = {} }) {
    const { trace_ts = {} } = results;
    const tsKeys = Object.keys(trace_ts);
    return !!tsKeys.length;
  }

  onDateRangeChange({ lookbackSeconds, startDate, endDate }) {
    const { displayOptions, history } = this;
    const { $auth } = this.store;
    const dateObj = this.enforceMinLookback({ lookbackSeconds, startDate, endDate });
    const v1 = !!history?.location.pathname.includes('/v1');

    if (displayOptions.usePageComponent && !$auth.hasSudo) {
      if (v1) {
        if (!dateObj.startDate || dateObj.startDate > SYNGEST_CUTOVER) {
          history.push(`${history.location.pathname.replace('/v1', '')}`, dateObj);
        }
      } else if (dateObj.startDate && dateObj.startDate < SYNGEST_CUTOVER) {
        history.push(`${history.location.pathname}/v1`, dateObj);
      }
    }

    // don't use lookbackSeconds, does not align well across services
    if (lookbackSeconds) {
      const { start, end } = getStartAndEndFromLookback(lookbackSeconds, 120);
      dateObj.actualStartDate = start.unix();
      dateObj.actualEndDate = end.unix();
    } else {
      dateObj.actualStartDate = dateObj.startDate;
      dateObj.actualEndDate = dateObj.endDate;
    }

    this.fetch({ startDate: dateObj.actualStartDate, endDate: dateObj.actualEndDate });
    this.setDateRange(dateObj);

    if (displayOptions.allowSettings) {
      this.store.$exports.setHash(
        {
          hashedLookbackSeconds: dateObj.lookbackSeconds,
          hashedStartDate: dateObj.startDate,
          hashedEndDate: dateObj.endDate
        },
        true,
        true
      ); // merge and replace the querystring to just be the q hash
    }
  }

  @action
  setDateRange({ lookbackSeconds, startDate, endDate, minLookbackSeconds, actualStartDate, actualEndDate }) {
    // values could potentially come in via url params so make sure to coerce
    this.lookbackSeconds = +lookbackSeconds;
    this.startDate = +startDate;
    this.endDate = +endDate;
    this.actualStartDate = +actualStartDate;
    this.actualEndDate = +actualEndDate;

    if (minLookbackSeconds !== undefined) {
      this.minLookbackSeconds = minLookbackSeconds;
    }
  }

  @action
  onChangeResultTimeMs(resultTimeMs) {
    this.resultTime = resultTimeMs / 1000;

    this.fetchResultsByTs(this.resultTime, this.aggregation);
  }

  @action
  onChangeTraceResultTimeMs(traceResultTimeMs) {
    this.traceResultTimeMs = traceResultTimeMs;
  }

  @action
  onPageTabChange = (id) => {
    const { allowSettings } = this.displayOptions;
    const { $exports } = this.store;
    this.pageTabId = id;
    if (allowSettings) {
      $exports.setHash({ hashedPageTabId: id }, true, true); // merge and replace the querystring to just be the q hash
    }
  };

  @action
  onResultsTabChange = (id) => {
    const { allowSettings } = this.displayOptions;
    const { $exports } = this.store;
    this.defaultTabId = id;
    if (allowSettings) {
      $exports.setHash({ hashedDefaultTabId: id }, true, true); // merge and replace the querystring to just be the q hash
    }
  };

  checkForDateRange({ startDate, endDate, lookbackSeconds }) {
    if (startDate !== undefined && endDate !== undefined && lookbackSeconds !== undefined) {
      return { startDate, endDate, lookbackSeconds };
    }

    return null;
  }

  enforceMinLookback({ lookbackSeconds, startDate, endDate }) {
    const { test } = this;

    return {
      lookbackSeconds,
      startDate,
      endDate,
      ...(getMinLookback(test, lookbackSeconds) || {})
    };
  }

  @computed
  get loading() {
    const { init, loadingResults, loadingTimestamps, loadingAlarmResults } = this;
    return !init || loadingResults || loadingTimestamps || loadingAlarmResults;
  }

  @computed
  get resultTimeMs() {
    const { resultTime } = this;

    return resultTime ? resultTime * 1000 : null;
  }

  @computed
  get healthTimeline() {
    const { alarmResults, timestamps } = this;

    if (timestamps.length > 0) {
      // show an empty timeline when alerting is disabled or the test is not active (paused)
      const disableTimeline =
        this.test.get('config.healthSettings.disableAlerts') || this.test.get('test_status') !== TEST_STATUS.ACTIVE;

      return getHealthTimeline({
        alarms: disableTimeline ? [] : alarmResults?.alarms || [],
        timestamps
      });
    }

    return [];
  }

  @computed
  get results() {
    const { resultTime, resultsTs, loading } = this;

    if (loading) {
      return null;
    }

    return resultsTs[resultTime] || null;
  }

  @computed
  get hasResults() {
    const { results } = this;

    if (results) {
      const { health_ts = {}, tasks = {} } = results;
      const tsKeys = Object.keys(health_ts);
      const taskKeys = Object.keys(tasks);
      return tsKeys.length > 0 && taskKeys.length > 0;
    }

    return false;
  }

  @computed
  get mesh() {
    const { results, hasResults } = this;
    const mesh = [];
    if (hasResults && results.mesh) {
      const rowKeys = Object.keys(results.mesh);
      rowKeys.sort((a, b) => results.mesh[a].name?.localeCompare(results.mesh[b].name));

      for (let i = 0; i < rowKeys.length; i += 1) {
        const row = results.mesh[rowKeys[i]];
        const columnKeys = Object.keys(row.columns);
        columnKeys.sort((a, b) => row.columns[a].name?.localeCompare(row.columns[b].name));
        const columns = [];

        for (let j = 0; j < columnKeys.length; j += 1) {
          const column = row.columns[columnKeys[j]];
          columns.push({ ...column, health: Object.values(column.health) });
        }

        mesh.push({ ...row, columns });
      }

      return mesh;
    }
    return undefined;
  }

  @computed
  get agentHealthTs() {
    const { test, results, hasResults } = this;

    if (hasResults) {
      return processTestResults(test, results);
    }

    return null;
  }

  @computed
  get resultsKeys() {
    const { results } = this;

    return {
      agentKeys: Object.keys(results?.agents || {}),
      taskKeys: Object.keys(results?.tasks || {}),
      healthTsKeys: Object.keys(results?.health_ts || {}),
      healthAggKeys: Object.keys(results?.health_agg || {})
    };
  }

  @computed
  get agentTaskConfig() {
    const { results, resultsKeys, hasResults } = this;
    const config = {};

    if (hasResults) {
      const { agents } = results;
      const { agentKeys } = resultsKeys;

      for (let i = 0; i < agentKeys.length; i++) {
        const agentKey = agentKeys[i];
        const agent = agents[agentKeys[i]];

        if (agent.targets || agent.hostnames) {
          config[agentKey] = {};
        }

        if (agent.targets) {
          config[agentKey].targets = agent.targets;
        }

        if (agent.hostnames) {
          config[agentKey] = { ...config[agentKey], ...agent.hostnames };
        }
      }
    }

    return config;
  }

  @computed
  get latestHealth() {
    const { results, resultsKeys, hasResults, test } = this;
    const { isPreview } = test;
    const { healthTsKeys } = resultsKeys;

    if (!isPreview && hasResults) {
      return results.health_ts[healthTsKeys[healthTsKeys.length - 1]];
    }

    return null;
  }

  @computed
  get targetValue() {
    const { test } = this;
    const testType = test.get('test_type');
    let targetValue = test.get('config.target.value');

    if (testType === TEST_TYPES.IP_ADDRESS && targetValue.split(',').length > 1) {
      targetValue = 'IP Grid';
    }
    if (testType === TEST_TYPES.HOSTNAME && targetValue.split(',').length > 3) {
      targetValue = `${targetValue.split(',').slice(0, 3).join()},...`;
    }

    return targetValue;
  }

  @computed
  get bigDataSet() {
    const { test } = this;
    const agents = test.get('config.agents') || [];
    const testType = test.get('test_type');
    const bigTestTypes = [TEST_TYPES.APPLICATION_MESH, TEST_TYPES.NETWORK_GRID];

    return agents.length > 0 && bigTestTypes.includes(testType);
  }

  @computed
  get timelineBounds() {
    const { timestamps } = this;
    if (timestamps?.length) {
      const { xMin, xMax, xAxisMin, xAxisMax, delta } = getTimelinebounds(timestamps);

      return { xMin, xMax, xAxisMin, xAxisMax, delta };
    }

    return this.traceTimelineBounds;
  }

  @computed
  get traceTimelineBounds() {
    const { hasTraceResults, traceResults } = this;

    if (hasTraceResults) {
      const { xMin, xMax, xAxisMin, xAxisMax, delta } = getTimelinebounds(traceResults?.results?.trace_ts);

      return { xMin, xMax, xAxisMin, xAxisMax, delta };
    }

    return {};
  }

  @computed
  get traceroutes() {
    const { hasTraceResults, traceResults } = this;

    if (hasTraceResults) {
      return getTraceroutes({ traceResults });
    }

    return [];
  }

  @computed
  get aggregation() {
    const { aggregationData } = this;
    return aggregationData?.aggregation || 0;
  }

  @computed
  get isAggregated() {
    return !!this.aggregationData?.isAggregated;
  }

  @computed
  get aggregationLabel() {
    return getAggregationLabel(this.aggregation);
  }

  @computed
  get maxRawLookback() {
    return this.aggregationData?.maxRawLookback || 0;
  }

  @computed
  get canShowRawHealth() {
    return !!this.maxRawLookback && this.isAggregated;
  }

  @computed
  get timelineAlarmsByResultTimeMs() {
    return getTimelineAlarmsByResultTimeMs(this.healthTimeline);
  }

  @computed
  get selectedTimelineAlarms() {
    return this.timelineAlarmsByResultTimeMs[this.resultTimeMs] || [];
  }
}

export default TestResultsState;
