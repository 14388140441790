import { get, maxBy, minBy, memoize, uniqBy } from 'lodash';

import { deserializeCidrDimension } from 'shared/alertingManager/utils';
import { greekPrefix } from 'core/util/greekPrefixing';
import $insights from 'app/stores/insight/$insights';
import $dictionary from 'app/stores/$dictionary';

const warnOnce = memoize((message) => console.warn(message));

export function getDeserializedDimensionToValue(dimensionToValue) {
  return Object.keys(dimensionToValue).reduce((acc, key) => {
    const newKey = deserializeCidrDimension(key, $dictionary.get('cidrMetrics'));
    acc[newKey] = dimensionToValue[key];
    return acc;
  }, {});
}

export function validateInsight(insight) {
  const { insightID, insightName } = insight;
  const insightDef = $insights.getType(insight);

  if (!insightDef) {
    warnOnce(`Unsupported insight type: ${insightName}`);
    return false;
  }

  const { isValid } = insightDef;

  if (isValid && !isValid(insight)) {
    warnOnce(`Bad insight received: ${insightID}`);
    return false;
  }

  return true;
}

export function getPercentage(from, to) {
  return (to - from) / from || 0;
}

export function getComparisonsPrefix(insight) {
  const topKeys = get(insight, 'comparison.newTopKeys', []);
  return greekPrefix(topKeys.map((key) => get(key, 'metricToValueNew.bps', 0)));
}

export function getCapacityMetric(insight) {
  const { insightName, metricToValue } = insight;

  if (insightName === 'operate.capacity.capacity') {
    const { inUtilization, outUtilization } = metricToValue;

    const utilizations = [
      { value: inUtilization, direction: 'in', property: 'inUtilization' },
      { value: outUtilization, direction: 'out', property: 'outUtilization' }
    ];

    return maxBy(utilizations, ({ value }) => value);
  }

  if (insightName === 'operate.capacity.runout') {
    const { monthsToRunoutIn, monthsToRunoutOut, weeksToRunoutIn, weeksToRunoutOut } = metricToValue;

    const runouts = [
      { value: monthsToRunoutIn, unit: 'month', direction: 'in', property: 'monthsToRunoutIn' },
      { value: monthsToRunoutOut, unit: 'month', direction: 'out', property: 'monthsToRunoutOut' },
      { value: weeksToRunoutIn, unit: 'week', direction: 'in', property: 'weeksToRunoutIn' },
      { value: weeksToRunoutOut, unit: 'week', direction: 'out', property: 'weeksToRunoutOut' }
    ];

    return minBy(
      runouts.filter(({ value }) => value > 0),
      ({ value, unit }) => value * (unit === 'month' ? 30 : 7)
    );
  }

  return null;
}

export function getComparisonData(insight) {
  const {
    newTopKeys,
    oldTopKeys = [],
    avgAbsRankChange,
    numNewKeys,
    numKeysMissing,
    mostDecreaseKey,
    thresholds = {}
  } = insight.comparison;

  const {
    avgRankChange: avgAbsRankChangeThreshold = 0.75,
    maxValueDropAnyKey: dropPercentageThreshold = -0.95,
    nNewKeys: numNewKeysThreshold = 5,
    nKeysMissing: numKeysMissingThreshold = 5,
    nStickyKeys: numStickyKeys = 1,
    nStickyKeysMustNotLeaveTopN: numStickyKeysLeaveThreshold
  } = thresholds;

  const numStickyKeysLeave = oldTopKeys
    .slice(0, numStickyKeys)
    .filter(({ rankNew }) => !rankNew || rankNew > 10).length;

  const newKeys = numNewKeys > numNewKeysThreshold ? newTopKeys.filter(({ rankOld }) => !rankOld || rankOld > 10) : [];

  const oldKeys =
    numKeysMissing > numKeysMissingThreshold || numStickyKeysLeave >= numStickyKeysLeaveThreshold
      ? oldTopKeys.filter(({ rankNew }) => !rankNew || rankNew > 10)
      : [];

  const dropPercentage = getPercentage(
    get(mostDecreaseKey, 'metricToValueOld.bytes'),
    get(mostDecreaseKey, 'metricToValueNew.bytes')
  );

  const dropKey = dropPercentage < dropPercentageThreshold ? mostDecreaseKey : null;

  const rankChangeKey =
    avgAbsRankChange > avgAbsRankChangeThreshold
      ? maxBy(newTopKeys, ({ rankOld, rankNew }) => Math.abs(rankOld - rankNew))
      : null;

  const interestingKeys = uniqBy(
    [].concat(newKeys, oldKeys, dropKey || [], rankChangeKey || []),
    ({ rankOld, rankNew }) => `${rankOld}-${rankNew}`
  );

  return {
    newKeys,
    oldKeys,
    dropPercentage,
    dropKey,
    rankChangeKey,
    interestingKeys
  };
}

export function getMetrics(insight) {
  const { insightName } = insight;
  const metricToValue = get(insight, 'metricToValue', {});

  if (insightName === 'cdn.embedded_cache_programs') {
    return ['peak_bps'];
  }

  if (insightName && insightName.includes('synthetics')) {
    if (metricToValue.problem_latency_severity > 0) {
      return [
        'last_average_latency_without_zero',
        'new_average_latency_without_zero',
        'last_stddev_latency_without_zero',
        'new_stddev_latency_without_zero',
        'last_max_latency',
        'new_max_latency'
      ];
    }

    if (metricToValue.problem_packet_loss_severity > 0) {
      return [
        'last_average_packet_loss_percent',
        'new_average_packet_loss_percent',
        'last_max_packet_loss_percent',
        'new_max_packet_loss_percent'
      ];
    }
  }

  let metrics = get(insight, 'alerting.policy.metrics', []).filter(
    (metric) => get(metricToValue, metric) !== undefined
  );

  if (metrics.length === 0) {
    metrics = Object.keys(metricToValue);
  }

  return metrics;
}

export function getDimensionKeys(insight) {
  const { insightName } = insight;

  if (insight.alerting) {
    return get(insight, 'alerting.policy.dimensions', []);
  }

  if (insight.comparison) {
    const keys = Object.keys(get(insight, 'comparison.newTopKeys.0.dimensionToValue', {}));

    if (insightName.startsWith('comparison.city')) {
      return keys.filter((key) => key.endsWith('_geo_city'));
    }
    if (insightName.startsWith('comparison.region')) {
      return keys.filter((key) => key.endsWith('_geo_region'));
    }
    return keys;
  }

  if (insightName.startsWith('operate.capacity')) {
    return ['device_id', 'snmp_id'];
  }

  if (insightName === 'cdn.embedded_cache_programs') {
    return ['cdn'];
  }

  if (insightName.includes('synthetics')) {
    return [
      'agent_alias',
      'agent_cloud_region',
      'agent_cloud_provider',
      'agent_asn',
      'agent_ip',
      'test_id',
      'test_type',
      'test_target',
      'dst_inet_addr'
    ];
  }

  return Object.keys(insight.dimensionToValue || {});
}

export function getFilterOperator(filter) {
  // we need to look up against the dictionary to determine the proper 'equals' operator as
  // just assuming '=' would leave out src/dst ip fields that use 'ILIKE'
  const operatorOptions = $dictionary.filterOperatorOptions[filter] || $dictionary.standardFilterOperators;
  const operatorOption = operatorOptions.find((option) => option.label === 'equals');

  return operatorOption ? operatorOption.value : '==';
}

// translate insight dimension to query filter
export function getQueryFilter(dim, value) {
  const dimension = deserializeCidrDimension(dim, $dictionary.get('cidrMetrics'));

  const fieldConfig = $dictionary.get(`queryFilters.metricToFilterParsers.${dimension}`);
  let filterField = dimension;

  // attempt to translate the insight dimension to a filter config in the dictionary
  // we'll only use that if the match has exactly one entry, otherwise fall back to the insight dimension
  // dictionary entries that have multiple entries (src and dst) such as 'ASNTopTalkers' are never expected to be in an insight
  if (!dimension.includes('__snmp__') && fieldConfig && fieldConfig.length === 1) {
    filterField = fieldConfig[0].field;
  }

  // surprise! these dictionary entries have multiple entries and are in some insights - use the first config
  if (
    dimension === 'dst_route_prefix_len' ||
    dimension === 'src_route_prefix_len' ||
    dimension === 'dst_proto_port' ||
    dimension === 'src_proto_port'
  ) {
    filterField = fieldConfig[0].field;
  }

  // dont translate device_id to device_name if the value is numeric
  if (dimension === 'i_device_id' && /^\d+$/.test(value)) {
    filterField = 'i_device_id';
  }

  return {
    filterField,
    operator: getFilterOperator(filterField),
    filterValue: value
  };
}

export function getQueryDimensionFilters(dimensionToKeyPart) {
  const filters = [];

  // build the filters, ignore i_device_id.
  Object.keys(dimensionToKeyPart).forEach((dimension) => {
    if (dimension !== 'i_device_id' && dimension !== 'Traffic') {
      filters.push(getQueryFilter(dimension, dimensionToKeyPart[dimension]));
    }
  });

  return filters;
}
