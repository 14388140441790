import { action } from 'mobx';
import moment from 'moment';

import { Model } from 'core/model';
import api from 'core/util/api';
import { DEFAULT_DATETIME_FORMAT } from 'core/util/dateUtils';
import { showSuccessToast } from 'core/components/toast';
import { POLICY_APPLICATIONS, SUPPRESSION_TYPES } from 'shared/alerting/constants';
import safelyParseJSON from 'shared/util/safelyParseJson';

export default class SuppressionModel extends Model {
  get urlRoot() {
    return '/api/ui/alerting/suppressions';
  }

  serialize(data = {}) {
    const {
      application,
      ruleId,
      rule_id,
      dimensionToKeyPart,
      startTime,
      expirationTime,
      endTime,
      agentIds, // used for synth
      testIds, // used for synth
      strict,
      comment,
      applicationMetadata = {}
    } = data;

    const suppression = {
      application,
      ruleId: ruleId || rule_id,
      dimensionToKeyPart,
      comment,
      type: this.type,
      strict,
      agentIds,
      testIds,
      startTime,
      expirationTime: endTime || expirationTime,
      applicationMetadata:
        typeof applicationMetadata === 'object' ? JSON.stringify(applicationMetadata, null, 2) : applicationMetadata
    };

    return super.serialize({ suppression });
  }

  deserialize(data = {}) {
    const serializedSuppression = data?.suppression || data;
    const { user_id, rule_id, start_time, end_time, application_metadata, ...restSuppression } = serializedSuppression;

    const suppression = {
      ...restSuppression,
      userId: user_id,
      ruleId: rule_id,
      startTime: start_time,
      expirationTime: end_time,
      applicationMetadata: safelyParseJSON(application_metadata)
    };

    return super.deserialize(suppression);
  }

  getDestroyUrl = (url, suppressionID) =>
    url ?? (this?.collection?.urlPaths?.destroy ? `${this.collection.urlPaths.destroy}/${suppressionID}` : this.url);

  handleDestroySuccess = (options) => {
    const { toast = true, remove = true } = options;
    return action((success) => {
      if (toast) {
        showSuccessToast(this.messages.destroy, {
          title: this.showToastTitles ? 'Removed' : undefined
        });
      }

      if (this.collection) {
        if (remove) {
          this.collection.remove([this.id]);
        }

        this.collection.clearSelection();
      }

      this.requestStatus = null;

      return success;
    });
  };

  handleDestroyFail = (options) =>
    action((error) => {
      console.error('destroyModel error', error);
      if (options.optimistic && this.collection) {
        this.collection.add([this.get()]);
      }
      this.error = { label: 'destroying', body: error };
      this.requestStatus = null;

      return error;
    });

  getSortValue(field) {
    if (field === 'startTime') {
      return this.startTime?.startTime;
    }

    if (field === 'expirationDetails') {
      return this.get('expirationTime');
    }

    return super.getSortValue(field);
  }

  @action
  destroyModel = async (options = {}) => {
    const { url } = options;
    this.requestStatus = 'destroying';

    return api
      .del(this.getDestroyUrl(url, this.get('id')), { data: this.get() })
      .then(this.handleDestroySuccess(options), this.handleDestroyFail(options));
  };

  get expired() {
    const expirationTime = this.get('expirationTime');
    return !expirationTime ? false : moment(expirationTime).isBefore(moment());
  }

  get expirationDetails() {
    const expirationTime = this.get('expirationTime');
    if (!expirationTime) {
      return { expirationDay: null, expired: false, duration: null };
    }
    const momentDate = moment(expirationTime);
    return {
      expirationDay: momentDate.format(DEFAULT_DATETIME_FORMAT),
      duration: moment.duration(momentDate.diff(moment())).humanize()
    };
  }

  get startTime() {
    const startTime = this.get('startTime');
    return moment(startTime).format(DEFAULT_DATETIME_FORMAT);
  }

  get startDetails() {
    const startTimeMoment = moment(this.get('startTime'));
    return {
      started: startTimeMoment.isSameOrBefore(moment()),
      futureStart: startTimeMoment.isAfter(moment()),
      diff: moment.duration(startTimeMoment.diff(moment())).humanize()
    };
  }

  get userId() {
    return this.get('userId');
  }

  get ruleId() {
    return this.get('ruleId');
  }

  get createdDay() {
    const created = this.get('cdate');

    return moment(created).format(DEFAULT_DATETIME_FORMAT);
  }

  get comment() {
    return this.get('comment');
  }

  get messages() {
    return {
      create: `Suppression created${this.comment ? ` '${this.comment}'` : ''}. It will take effect in a few minutes.`,
      update: `Suppression updated ${this.comment ? `'${this.comment}'` : ''}`,
      destroy: `Suppression ${this.comment ? `for '${this.comment}' was` : ''} removed successfully`,
      duplicate: `Suppression ${this.comment ? `for '${this.comment}' was` : ''} duplicated successfully`
    };
  }

  get agentIds() {
    // assemble a unique list of all agent ids --- those from creation and others picked up in filters during editing
    const allAgentIds = [].concat(this.get('agentIds') || [], this.get('key_filter.filter.agentId.in.values') || []);
    const uniqueAgentIds = Array.from(new Set(allAgentIds));

    return uniqueAgentIds.filter((agentId) => !!this.store.$syn.agents.get(agentId));
  }

  get testIds() {
    // assemble a unique list of all test ids --- those from creation and others picked up in filters during editing
    const allTestIds = [].concat(this.get('testIds') || [], this.get('key_filter.filter.testId.in.values') || []);
    const uniqueTestIds = Array.from(new Set(allTestIds));

    return uniqueTestIds.filter((testId) => !!this.store.$syn.tests.get(testId));
  }

  get applicationMetadata() {
    return this.get('applicationMetadata', {});
  }

  get measurement() {
    return this.applicationMetadata.measurement;
  }

  get type() {
    const { type, application } = this.applicationMetadata;
    const keyFilters = this.get('key_filter');
    const hasFilters = Object.keys(keyFilters?.filter || {}).length || keyFilters?.filters?.length;
    let suppressionType = type;

    if (this.agentIds.length > 0) {
      suppressionType = SUPPRESSION_TYPES.AGENT;
    } else if (this.testIds.length > 0) {
      suppressionType = SUPPRESSION_TYPES.SYNTH;
    } else {
      if (!hasFilters && this.ruleId) {
        suppressionType = SUPPRESSION_TYPES.POLICY;
      }

      if (hasFilters && application === POLICY_APPLICATIONS.METRIC) {
        suppressionType = SUPPRESSION_TYPES.METRIC;
      }

      if (hasFilters && application === POLICY_APPLICATIONS.NMS) {
        suppressionType = SUPPRESSION_TYPES.NMS;
      }

      // These are bad legacy types that should be 'condition'
      if (type === 'partial' || type === 'full') {
        suppressionType = SUPPRESSION_TYPES.CONDITION;
      }
    }

    return suppressionType || SUPPRESSION_TYPES.CONDITION;
  }

  get isEditable() {
    return !this.get('isLegacy');
  }

  get policyDisplayName() {
    const { $alerting } = this.store;
    const rulePolicyMatch = $alerting.policyCollection.getPolicyByRuleId(this.get('ruleId'));
    if (rulePolicyMatch) {
      return `${rulePolicyMatch.get('name')} - (${rulePolicyMatch.id})`;
    }

    const policyId = this.applicationMetadata?.policyId || this.get('policyID');
    const policy = $alerting.policyCollection.models.find(
      (collectionPolicy) => `${collectionPolicy.id}` === `${policyId}`
    );

    if (policy) {
      const policyName = policy.policy_name || policy.get('name');

      if (policyName) {
        return `${policyName} - (${policyId})`;
      }

      return policyId;
    }

    return 'Missing Policy';
  }

  get dimensions() {
    const filter = this.get('key_filter.filter', {});

    return Object.keys(filter).reduce(
      (allFilters, filterKey) => ({
        ...allFilters,
        [filterKey]: filter[filterKey]?.equals
      }),
      {}
    );
  }

  get rawFilters() {
    return Object.entries(this.dimensions).map(([key, value]) => ({
      filterField: key,
      filterValue: value
    }));
  }
}
