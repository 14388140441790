import { action, computed, observable } from 'mobx';
import Model from 'core/model/Model';
import $devices from 'app/stores/device/$devices';
import $interfaces from 'app/stores/interface/$interfaces';
import {
  getHealthState,
  getHealthStateFromValue,
  getIfOperStatusLabel,
  healthStates
} from 'app/views/hybrid/utils/health';

const checkDisplayNames = {
  device_performance_cpu_busy: 'CPU Utilization',
  device_performance_mem_in_use: 'Memory Utilization',
  interface_availability_operstatus: 'Interface Error',
  interface_availability_down: 'Interface Down',
  interface_performance_inoctets: 'Interface Inbound Utilization',
  interface_performance_outoctets: 'Interface Outbound Utilization'
};

const checkValueFormatters = {
  device_performance_cpu_busy: 'percent',
  device_performance_mem_in_use: 'percent',
  interface_availability_operstatus: 'operstatus',
  interface_availability_down: 'operstatus',
  interface_performance_inoctets: 'percent',
  interface_performance_outoctets: 'percent'
};

export default class TopologyHealthModel extends Model {
  @observable
  entityModelIsLoading = false;

  get sortValues() {
    return {
      'check.state': () => this.severity
    };
  }

  @computed
  get normalizedCheckName() {
    const checkName = this.get('check.check_name');
    const value = this.get('check.value');

    if (checkName === 'interface_availability_operstatus' && value === 2) {
      return 'interface_availability_down';
    }

    return checkName;
  }

  @computed
  get displayName() {
    const checkName = this.normalizedCheckName;
    return checkDisplayNames[checkName] || checkName;
  }

  @computed
  get displayValue() {
    const checkName = this.normalizedCheckName;
    const value = this.get('check.value');
    const formatter = checkValueFormatters[checkName];

    switch (formatter) {
      case 'percent':
        return `${value}%`;

      case 'operstatus':
        return getIfOperStatusLabel(value);

      default:
        return value;
    }
  }

  @computed
  get displayDeviceLabels() {
    const device_labels = this.get('device_labels') || [];
    return device_labels
      .map(({ name }) => name)
      .filter(Boolean)
      .sort((a, b) => a.localeCompare(b))
      .join(', ');
  }

  @computed
  get healthState() {
    const checkName = this.get('check.check_name');
    const value = this.get('check.value');
    const state = this.get('check.state');

    switch (checkName) {
      case 'device_performance_cpu_busy':
        return getHealthStateFromValue({ type: 'cpu', value });

      case 'device_performance_mem_in_use':
        return getHealthStateFromValue({ type: 'mem', value });

      case 'interface_performance_inoctets':
        return getHealthStateFromValue({ type: 'interfacePerformanceIn', value });

      case 'interface_performance_outoctets':
        return getHealthStateFromValue({ type: 'interfacePerformanceOut', value });

      default:
        return getHealthState(state);
    }
  }

  // a numerical severity for sorting purposes
  @computed
  get severity() {
    const checkName = this.normalizedCheckName;
    const state = this.get('check.state');
    const value = this.get('check.value');

    // WARNING: 100, CRITICAL: 1000
    let severity = 10 ** (healthStates.indexOf(state) + 1);

    if (checkName === 'interface_availability_down') {
      severity += 900;
    } else if (checkName === 'interface_availability_operstatus') {
      severity += 800 + value;
    } else {
      severity += value;
    }

    return severity;
  }

  async fetchEntityModel() {
    const level = this.get('level');

    if (level === 'interface') {
      return this.fetchInterfaceModel();
    }

    return this.fetchDeviceModel();
  }

  async fetchDeviceModel() {
    if (this.deviceModel) {
      return this.deviceModel;
    }

    this.setEntityModelIsLoading(true);

    return $devices.fetchDevice(this.get('device_name')).then((model) => {
      this.setEntityModelIsLoading(false);
      this.deviceModel = model;
      return model;
    });
  }

  async fetchInterfaceModel() {
    if (this.interfaceModel) {
      return this.interfaceModel;
    }

    this.setEntityModelIsLoading(true);

    return $interfaces
      .fetchInterface({ device_name: this.get('device_name'), snmp_id: this.get('snmp_id') })
      .then((model) => {
        this.setEntityModelIsLoading(false);
        this.interfaceModel = model;
        return model;
      });
  }

  @action
  setEntityModelIsLoading(value) {
    this.entityModelIsLoading = Boolean(value);
  }
}
