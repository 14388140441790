import Collection from 'core/model/Collection';
import CustomApplication from './CustomApplication';

class CustomApplicationCollection extends Collection {
  get url() {
    return '/api/ui/custom-applications';
  }

  get model() {
    return CustomApplication;
  }
}

export default CustomApplicationCollection;
