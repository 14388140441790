import { action, computed } from 'mobx';
import { cloneDeep, flatten, isString, pick } from 'lodash';
import moment from 'moment';
import { FPA_ADVANCED_DEFAULTS } from '@kentik/ui-shared/fpa/constants';
import Model from 'core/model/Model';
import deepClone from 'core/util/deepClone';
import { injectLegacySavedFilters } from 'core/util/filters';
import { overwriteFilter } from '@kentik/ui-shared/filters';
import $dataviews from 'app/stores/$dataviews';
import $dictionary from 'app/stores/$dictionary';
import $devices from 'app/stores/device/$devices';
import $auth from 'app/stores/$auth';
import $app from 'app/stores/$app';
import { DEFAULT_DATE_FORMAT, DEFAULT_DATETIME_FORMAT, getQueryTimeInterval } from 'core/util/dateUtils';
import { getAppProtocol } from 'app/util/dimensions';

// Pass me an object, not a model instance, or else
export function queryHasDevices(query) {
  return (
    query.all_devices ||
    (query.device_name && query.device_name.length > 0) ||
    (query.device_labels && query.device_labels.length > 0) ||
    (query.device_sites && query.device_sites.length > 0) ||
    (query.device_types && query.device_types.length > 0)
  );
}

function getAggregate(aggregateType, metrics = {}) {
  let aggregate = null;

  Object.keys(metrics).some((group) => {
    const aggregatesOrGroup = metrics[group];
    let matchingAggregate;

    if (Array.isArray(aggregatesOrGroup)) {
      matchingAggregate = aggregatesOrGroup.find((agg) => agg.value === aggregateType);
    } else {
      matchingAggregate = getAggregate(aggregateType, aggregatesOrGroup);
    }

    if (matchingAggregate) {
      // Return a copy so we don't modify the dictionary
      const fullGroupName = matchingAggregate.group ? `${group} ${matchingAggregate.group}` : group;
      const origLabel = matchingAggregate.origLabel || matchingAggregate.label;

      aggregate = {
        ...matchingAggregate,
        group: fullGroupName,
        origLabel,
        label: `${fullGroupName} ${origLabel}`
      };
    }

    return !!aggregate;
  });

  return aggregate;
}

export function getAggregates(aggregateTypes) {
  const aggregates = $dictionary.get('aggregates');

  const types = [...aggregateTypes];
  if (types.some((agg) => agg.includes('flows_per_sec')) && !types.some((agg) => agg.includes('bits_per_sec'))) {
    types.push('avg_bits_per_sec');
  }
  return types.map((aggregateType) => getAggregate(aggregateType, aggregates)).filter((agg) => agg);
}

function findAggregate(aggregates, value) {
  return aggregates.find((aggregate) => aggregate.value === value);
}

/*
 * This is going to build a full list of required aggregates for the provided top-level ones by getting aggregate(s)
 * for each composite provided and merging them all together. Will not allow dupes as long as top-level ones are not
 * ever used in composites.
 */
export function getAllAggregates(aggregates) {
  const aggregateComposites = $dictionary.get('aggregateComposites');

  return aggregates.reduce((all, aggregate) => {
    const { fn, leftOperand, rightOperand } = aggregate;

    if (fn === 'composite') {
      const secondaryAggregates = [];
      const leftAggregate = findAggregate(aggregateComposites, leftOperand);
      if (leftAggregate) {
        secondaryAggregates.push(leftAggregate);
      }

      const rightAggregate = findAggregate(aggregateComposites, rightOperand);
      if (rightAggregate) {
        secondaryAggregates.push(rightAggregate);
      }

      const allSecondaryAggregates = getAllAggregates(secondaryAggregates);

      return all.concat(
        allSecondaryAggregates.filter((agg) => !all.includes(agg)),
        aggregate
      );
    }

    return all.concat(aggregate);
  }, []);
}

export const templateTargetFieldOptions = [
  { value: 'dimensions', label: 'Dimensions', iconCls: 'device_hub' },
  { value: 'filter', label: 'Filters', iconCls: 'filter_list' },
  { value: 'device_name', label: 'Devices', iconCls: 'storage' },
  { value: 'metrics', label: 'Metrics', iconCls: 'merge_type' }
];

/**
 QueryModel
 - deserialized from server JSON via `QueryModel.create()`
 - serialize to get JSON to send to server via `this.serialize()`
 - adapter to construct QueryFormModel from QueryModel via `QueryFormModel.create()`
 - QueryFormModel will serialize to same as QueryModel

 QueryFormModel
 - only fields that sidebar needs
 - @computed fields to exactly map to QueryModel
 - serialize to get to QueryModel - (TODO: excluding all fields that don't belong in QueryModel)
 */
export default class QueryModel extends Model {
  constructor(attributes = {}) {
    if (attributes.filters_obj && !attributes.filters) {
      attributes.filters = attributes.filters_obj;
    }
    super(attributes);
  }

  get defaults() {
    const userDnsLookup = $auth.getActiveUserProperty('userDnsLookup');
    const userHistoryState = $auth.getActiveUserProperty('userHistoryState');

    return {
      all_devices: true,
      aggregateTypes: ['avg_bits_per_sec', 'p95th_bits_per_sec', 'max_bits_per_sec'],
      aggregateThresholds: {},
      // rawAggregates affords the ability to provide our own aggregates in a query
      // if they are sent in as part of a query, they will be a complete replacement
      // over any other aggregates that may be calculated as part of properties.
      // the most common use case for this is to be able to issue a single query and receive
      // multiple raw datasets for which we can do what we wish with
      // NOTE: in order to use rawAggregates, ensure you also send in a matching aggregateTypes array
      rawAggregates: undefined,
      appProtocol: undefined,
      bracketOptions: null,
      bucket: 'Left +Y Axis',
      bucketIndex: 0,
      hideCidr: false,
      cidr: 32,
      cidr6: 128,

      customAsGroups: $dictionary.get('asGroups.populated'),

      cutFn: {},
      cutFnRegex: {},
      cutFnSelector: {},
      depth: 100,
      descriptor: '',
      device_name: 'all_devices',

      // an array of { id: 8 }, similar to saved filters.
      device_labels: [],
      device_sites: [],

      // valid options: ['router', 'host-nprobe-dns-www']
      device_types: [],

      fastData: 'Auto',
      filterDimensionsEnabled: false,
      filterDimensionName: 'Total',
      filterDimensionOther: false,
      filterDimensionSort: false,
      filterDimensions: {},
      aggregateFiltersEnabled: false,
      aggregateFiltersDimensionLabel: '',
      aggregateFilters: [],
      hostname_lookup: userDnsLookup !== 'OFF' && userDnsLookup !== 'false',
      isOverlay: false,
      isPreviousPeriod: false,
      lookback_seconds: 3600,
      from_to_lookback: 3600,
      generatorDimensions: [],
      generatorPanelMinHeight: 250,
      generatorMode: false,
      generatorColumns: 1,
      generatorQueryTitle: '{{generator_series_name}}',
      generatorTopx: 8,
      kmetrics: {},
      fpa: {
        type: 'cpd_fpa',
        min_support_pct: FPA_ADVANCED_DEFAULTS.MIN_SUPPORT_PCT,
        viz_perc: FPA_ADVANCED_DEFAULTS.VIZ_PERC,
        max_itemset_length: FPA_ADVANCED_DEFAULTS.MAX_ITEMSET_LENGTH,
        window_size: FPA_ADVANCED_DEFAULTS.WINDOW_SIZE,
        chg_thr: FPA_ADVANCED_DEFAULTS.CHG_THR,
        time: {
          starting_time: '',
          ending_time: '',
          lookback_seconds: 0,
          time_format: $auth.userTimezone
        },
        compare_time: {
          starting_time: '',
          ending_time: '',
          lookback_seconds: 0,
          time_format: $auth.userTimezone
        }
      },
      matrixBy: [],
      metric: [],
      minsPolling: undefined,
      forceMinsPolling: false,
      reAggInterval: undefined, // INT used in to_timestamp
      reAggFn: undefined, // String: 'avg', 'count', 'max', 'min', 'sum'.
      mirror: false,
      mirrorUnits: true,
      outsort: 'max_bits_per_sec',
      overlay_day: -7,
      overlay_timestamp_adjust: false,
      period_over_period: false,
      period_over_period_lookback: 1,
      period_over_period_lookback_unit: 'week', // 'hour', 'day', 'week', 'month'
      query_title: '',
      source: '', // optional string showing where query originated (ex: 'insight'), used by query engine
      // populated when we're loading a SavedView into Explorer.
      saved_query_id: undefined,
      secondaryOutsort: '',
      secondaryTopxSeparate: false,
      secondaryTopxMirrored: false,
      show_overlay: userHistoryState !== 'OFF' && userHistoryState !== 'false',
      show_total_overlay: true,
      starting_time: '',
      ending_time: '',
      sync_all_axes: false,
      sync_axes: false,
      sync_extents: true,
      show_site_markers: false,
      template_id: undefined,
      time_format: $auth.userTimezone,
      topx: 8,
      tsp: {
        baseline: { enabled: false, seasonalities: [60] },
        clustering: { enabled: false },
        forecasting: { enabled: false, samples: 60, seasonalities: [60] }
      },
      update_frequency: 0,
      use_fpa: false,
      use_kmetrics: false,
      use_log_axis: false,
      use_secondary_log_axis: false,
      use_alt_timestamp_field: false,
      viz_type: 'stackedArea',
      tenantPreviewFilters: undefined
    };
  }

  @computed
  get units() {
    return this.aggregates.reduce(
      (units, { unit }) => (unit && !units.includes(unit) ? units.concat(unit) : units),
      []
    );
  }

  @computed
  get prefixableFieldUnits() {
    const aggregateTypes = this.get('aggregateTypes');
    const unitsToPrefix = $dictionary.get('unitsToPrefix');

    // Uses 'parentUnit' (when provided) to bucket e.g. in_bytes with bytes for the purposes of prefixing
    const prefixableFieldUnits = this.aggregates
      .map(({ value, unit, parentUnit }) => ({ value, unit: parentUnit || unit }))
      .filter(
        (aggregate) =>
          aggregate.unit && unitsToPrefix.includes(aggregate.unit) && aggregateTypes.includes(aggregate.value)
      )
      .reduce((fieldUnits, aggregate) => {
        fieldUnits[aggregate.value] = aggregate.unit;
        return fieldUnits;
      }, {});

    if (this.get('aggregateFiltersEnabled') === true) {
      this.get('aggregateFilters').forEach((aggregateFilter) => {
        prefixableFieldUnits[aggregateFilter.name] = this.outsortUnit;
      });
    }

    return prefixableFieldUnits;
  }

  @computed
  get aggregates() {
    if (this.has('rawAggregates')) {
      return this.get('rawAggregates');
    }

    const aggregateTypes = this.get('aggregateTypes');
    const viz_type = this.get('viz_type');
    const bucket = this.get('bucket');
    const outsort = this.get('outsort') || '';

    const mappedAggregates = getAggregates(aggregateTypes);
    // Mixin composite aggregates
    const aggregates = getAllAggregates(mappedAggregates);

    const isLogsum = outsort && outsort.startsWith('sum_logsum');

    if (isLogsum) {
      aggregates.push({ value: outsort, fn: 'sum', column: 'kt_intell_order', unit: 'kt_intell_order' });
    }

    const vizTypeConfig = $dataviews.getConfig(viz_type);
    const bucketConfig = vizTypeConfig.buckets.find((b) => b.name === bucket);
    if (bucketConfig) {
      const sample_rate = bucketConfig.sampleRateFactor || 1;

      aggregates.forEach((aggregate) => {
        if (aggregate.value.includes('sample_rate')) {
          aggregate.sample_rate = sample_rate * 0.01;
        } else {
          aggregate.sample_rate = sample_rate;
        }
      });
    }

    let outsortAggregate;
    if (isLogsum) {
      const logsumAggregates = mappedAggregates.filter(
        (aggregate) => aggregate.unit === outsort.replace('sum_logsum_', '')
      );
      outsortAggregate = logsumAggregates.find((aggregate) => aggregate.fn === 'average') || logsumAggregates[0];
    } else {
      outsortAggregate = mappedAggregates.find((aggregate) => aggregate.value === outsort);
    }
    if (outsortAggregate) {
      // this was previously evaluated to be false for table type, true otherwise
      // the previous table type was based off the VirtualizedTable component however we now use
      // the ResultsTable component that works off raw data
      outsortAggregate.raw = true;
    }

    const secondaryOutsort = this.get('secondaryOutsort');
    if (vizTypeConfig.allowsSecondaryOverlay && secondaryOutsort && this.get('secondaryTopxSeparate') === false) {
      const secondaryOutsortAggregate = aggregates.find((aggregate) => aggregate.value === secondaryOutsort);
      if (secondaryOutsortAggregate) {
        // this was previously evaluated to be false for table type, true otherwise
        // the previous table type was based off the VirtualizedTable component however we now use
        // the ResultsTable component that works off raw data
        secondaryOutsortAggregate.raw = true;
        if (bucketConfig) {
          const secondaryBucketIndex =
            this.get('secondaryTopxMirrored') === true
              ? bucketConfig.secondaryMirrorBucket
              : bucketConfig.secondaryOverlayBucket;
          secondaryOutsortAggregate.sample_rate = vizTypeConfig.buckets[secondaryBucketIndex].sampleRateFactor;
        }
      }
    }

    return aggregates;
  }

  @computed
  get outsortAggregate() {
    const outsort = this.get('outsort');
    const { aggregates } = this;
    if (outsort && outsort.startsWith('sum_logsum')) {
      const aggregateTypes = this.get('aggregateTypes');
      return aggregates.find((agg) => aggregateTypes.includes(agg.value) && agg.unit === this.outsortUnit);
    }
    return aggregates.find((agg) => agg.value === outsort);
  }

  @computed
  get outsortDataKey() {
    const outsort = this.get('outsort');
    if (outsort && outsort.startsWith('sum_logsum')) {
      return this.outsortAggregate.value;
    }
    return this.get('outsort');
  }

  @computed
  get outsortUnit() {
    return this.getOutsortUnit(this.get('outsort'));
  }

  @computed
  get secondaryOutsortUnit() {
    return this.getOutsortUnit(this.get('secondaryOutsort'));
  }

  @computed
  get prefixUnit() {
    return this.getPrefixUnit(this.get('outsort'));
  }

  @computed
  get secondaryPrefixUnit() {
    return this.getPrefixUnit(this.get('secondaryOutsort'));
  }

  getOutsortUnit(outsort) {
    if (outsort && outsort.startsWith('sum_logsum')) {
      return outsort.replace('sum_logsum_', '');
    }
    const outsortAggregate = this.aggregates.find((aggregate) => aggregate.value === outsort);
    return outsortAggregate && outsortAggregate.unit;
  }

  getPrefixUnit(outsort) {
    const outsortAggregate = this.aggregates.find((aggregate) => aggregate.value === outsort);
    return outsortAggregate && (outsortAggregate.parentUnit || outsortAggregate.unit);
  }

  @computed
  get autoDeviceString() {
    return ['device_name', 'device_labels', 'device_sites', 'device_types']
      .filter((label) => this.get(label).length > 0)
      .map((label) => {
        const labelString = label.split('_').join(' ');
        const filterList = this.get(label);
        return `${labelString} ${filterList.join(' ')}`;
      })
      .join(', ');
  }

  @computed
  get autoTitle() {
    const {
      query_title,
      outsort,
      secondaryOutsort,
      metric,
      matrixBy,
      mirror,
      generatorMode,
      generatorDimensions,
      topx,
      filterDimensionsEnabled,
      filterDimensionName
    } = this.get();

    if (query_title) {
      return query_title;
    }

    const aggregateLabels = $dictionary.get('aggregateLabels');
    const units = $dictionary.get('units');
    const chartTypesValidations = $dictionary.get('chartTypesValidations');
    const chartTypeInverses = $dictionary.get('chartTypeInverses');

    let sortName = '';

    this.aggregates.some(({ value, label }) => {
      if (value === outsort) {
        sortName = `${aggregateLabels[value.startsWith('sum_logsum') ? 'sum_logsum' : value] || label} ${
          units[this.outsortUnit]
        }`;
        return true;
      }
      return false;
    });

    if (secondaryOutsort) {
      this.aggregates.some(({ value, label }) => {
        if (value === secondaryOutsort) {
          sortName = `${sortName}, ${aggregateLabels[value] || label} ${units[this.secondaryOutsortUnit]}`;
          return true;
        }
        return false;
      });
    }

    let metrics = '';
    let generatorMatrix = '';
    if (filterDimensionsEnabled && filterDimensionName !== '') {
      metrics = filterDimensionName;
    } else if (generatorMode && generatorDimensions && generatorDimensions.length) {
      metrics = generatorDimensions.map((m) => chartTypesValidations[m]).join(', ');
      generatorMatrix = ` for Top ${topx} ${metric.map((m) => chartTypesValidations[m]).join(', ')}`;
    } else {
      metrics = chartTypesValidations[metric] || metric.map((m) => chartTypesValidations[m]).join(', ');
    }

    let matrix = '';
    if (matrixBy && matrixBy.length) {
      matrix = ` vs Top ${chartTypesValidations[matrixBy] || matrixBy.map((m) => chartTypesValidations[m]).join(', ')}`;
    }

    if (mirror) {
      if (generatorMode && generatorDimensions && generatorDimensions.length) {
        matrix = ` vs Top ${generatorDimensions.map((m) => chartTypesValidations[chartTypeInverses[m] || m]).join(', ')}`;
      } else {
        matrix = ` vs Top ${metric.map((m) => chartTypesValidations[chartTypeInverses[m] || m]).join(', ')}`;
      }
    }

    return `${
      filterDimensionsEnabled || metric.indexOf('Traffic') !== -1 ? '' : 'Top '
    }${metrics}${matrix}${generatorMatrix} by ${sortName}`;
  }

  /**
   * Returns an array of `filterField` that are currently in use for this Query. This is used to determine if the
   * query contains any overridable `filterField`s to be used for Parametric Dashboards
   */
  @computed
  get activeFilterFields() {
    const { filters } = this.get();

    if (!filters || !filters.filterGroups) {
      return [];
    }

    return flatten(filters.filterGroups.map((group) => group.filters.map((filter) => filter.filterField)));
  }

  @computed
  get host_selected() {
    const { all_devices, device_name } = this.get();
    const { deviceSummaries: devices } = $devices;
    if (all_devices) {
      return !!devices.find((device) => device.device_type.startsWith('host'));
    }
    return !!devices.find(
      (device) => device.device_type.startsWith('host') && device_name.includes(device.device_name)
    );
  }

  get queryDurationRaw() {
    const lookback_seconds = this.get('lookback_seconds');
    const starting_time = this.get('starting_time');
    const ending_time = this.get('ending_time');
    const time_format = this.get('time_format');

    let start;
    let end;

    const getMomentFn = time_format === 'UTC' ? moment.utc : moment;

    if (lookback_seconds) {
      // "this month"
      if (lookback_seconds === 1) {
        start = getMomentFn().startOf('month');
        end = getMomentFn();
      }
      // "last month"
      else if (lookback_seconds === 2) {
        start = getMomentFn().startOf('month').subtract(1, 'month');
        end = getMomentFn().startOf('month');
      } else {
        start = getMomentFn().subtract(lookback_seconds, 'second');
        end = getMomentFn();
      }
    } else {
      start = getMomentFn(starting_time);
      end = getMomentFn(ending_time);
    }

    return end.diff(start);
  }

  // human readable duration
  get queryDuration() {
    return moment.duration(this.queryDurationRaw).humanize();
  }

  get dateRangeDisplay() {
    const lookback_seconds = this.get('lookback_seconds');
    const starting_time = this.get('starting_time');
    const ending_time = this.get('ending_time');
    const time_format = this.get('time_format');

    let start;
    let end;

    const getMomentFn = time_format === 'UTC' ? moment.utc : moment;

    if (lookback_seconds) {
      // "this month"
      if (lookback_seconds === 1) {
        start = getMomentFn().startOf('month');
        end = getMomentFn();
      }
      // "last month"
      else if (lookback_seconds === 2) {
        start = getMomentFn().startOf('month').subtract(1, 'month');
        end = getMomentFn().startOf('month');
      } else {
        start = getMomentFn().subtract(lookback_seconds, 'second');
        end = getMomentFn();
      }
    } else {
      start = getMomentFn(starting_time);
      end = getMomentFn(ending_time);
    }

    let utcOffset;
    if (typeof time_format === 'number') {
      start = start.utcOffset(time_format);
      end = end.utcOffset(time_format);
      utcOffset = time_format;
    } else {
      utcOffset = time_format === 'UTC' ? 0 : moment().utcOffset() / 60;
    }

    const startOut = start.format(DEFAULT_DATE_FORMAT);
    const endOut = end.format(DEFAULT_DATE_FORMAT);

    return `${startOut}${startOut !== endOut ? ` to ${endOut}` : ''} UTC${
      time_format !== 'UTC' ? getMomentFn().utcOffset(utcOffset).format('ZZ') : ''
    }`;
  }

  // NOTE: these invert functions expect a serialized query!
  invertDimensions(query) {
    query.metric = query.metric.map((metric) => $dictionary.get(`chartTypeInverses.${metric}`, metric));
  }

  invertAggregateTypes(query) {
    query.aggregateTypes = query.aggregateTypes.map((aggregateType) =>
      $dictionary.get(`unitInverses.${aggregateType}`, aggregateType)
    );
  }

  invertAggregateThresholds(query) {
    query.aggregateThresholds = Object.keys(query.aggregateThresholds).reduce((thresholds, threshold) => {
      const newKey = $dictionary.get(`unitInverses.${threshold}`, threshold);
      thresholds[newKey] = query.aggregateThresholds[threshold];
      return thresholds;
    }, {});
  }

  invertOutsorts(query) {
    const { outsort, secondaryOutsort } = query;
    query.outsort = $dictionary.get(`unitInverses.${outsort}`, outsort);
    query.secondaryOutsort = $dictionary.get(`unitInverses.${secondaryOutsort}`, secondaryOutsort);
  }

  invertFilters(query) {
    const { filters } = query;

    if (filters && filters.filterGroups) {
      filters.filterGroups.forEach((group) => this.invertFilterGroup(group));
    }
  }

  invertFilterGroup(group) {
    if (group && group.filters) {
      group.filters.forEach((filter) => {
        filter.filterField = $dictionary.get(
          `queryFilters.filterTypeInverses.${filter.filterField}`,
          filter.filterField
        );
        if (filter.filterField === 'i_host_direction') {
          if (filter.filterValue === 'in') {
            filter.filterValue = 'out';
          } else if (filter.filterValue === 'out') {
            filter.filterValue = 'in';
          }
        }
      });
    }
    if (group && group.filterGroups) {
      group.filterGroups.forEach((innerGroup) => this.invertFilterGroup(innerGroup));
    }
  }

  invertFilterDimensions(query) {
    if (query && query.filterDimensionsEnabled) {
      const { filterDimensions } = query;
      if (filterDimensions && filterDimensions.filterGroups) {
        filterDimensions.filterGroups.forEach((group) => this.invertFilterGroup(group));
      }
    }
  }

  invert() {
    const query = this.serialize();
    if (query.mirror) {
      this.invertDimensions(query);
      this.invertFilters(query);
      this.invertFilterDimensions(query);

      if (query.mirrorUnits) {
        this.invertAggregateTypes(query);
        this.invertAggregateThresholds(query);
        this.invertOutsorts(query);
      }
    }
    return query;
  }

  /**
   * @returns {PartialObject<QueryModel>}
   */
  serialize() {
    const query = deepClone(this.get());
    const rawAggregates = this.get('rawAggregates');

    if (!query.metric.length) {
      query.metric = ['Traffic'];
    }
    // query.filters = query.filters_obj || query.filters;
    query.aggregates = rawAggregates || this.aggregates.map((aggregate) => ({ ...aggregate, name: aggregate.value }));
    query.units = this.units;

    if (!query.filterDimensionsEnabled) {
      query.filterDimensionName = 'Total';
      query.filterDimensionOther = false;
      query.filterDimensionSort = false;
      query.filterDimensions = {};
    }

    if (query.period_over_period) {
      // disable incompatible options
      query.secondaryOutsort = '';
      query.show_overlay = false;
      query.mirror = false;
      query.generatorMode = false;

      if (!$dataviews.getOption(query.viz_type)?.supportsPeriodOverPeriod) {
        query.viz_type = 'stackedArea';
      }
    }

    const validKeys = [
      ...Object.keys(this.defaults),
      'aggregates',
      'filters',
      'units',
      'pivot',
      'template_id',
      'forceDepth',
      'meta'
    ];
    if ($app.isExport) {
      validKeys.push('target_company_id');
    }
    // only return the keys which are valid for a QueryModel;
    return pick(query, validKeys);
  }

  /**
   * @returns {QueryModel}
   */
  @action
  static create(query) {
    const queryCopy = cloneDeep(query || {});
    return QueryModel.doCreate(queryCopy);
  }

  /**
   * DO NOT CALL ME DIRECTLY. I WILL MUTATE YOUR QUERY AND YOU WILL SUFFER.
   * @returns {QueryModel}
   * @private
   */
  @action
  static doCreate(query) {
    // bw compat for all_selected vs all_devices
    if (query.all_selected !== undefined && query.all_selected !== null) {
      query.all_devices = query.all_selected;
    }

    // clear out device_name if all_devices
    if (query.all_devices) {
      query.device_name = [];
    }

    // bw compat for device names being a comma delimited str
    if (!Array.isArray(query.device_name)) {
      if (query.device_name) {
        query.device_name = query.device_name.split(',');
      } else {
        query.device_name = [];
      }
    }

    // bw compat for older reAggInterval
    if (query.reAggInterval && isString(query.reAggInterval) && !parseInt(query.reAggInterval)) {
      if (query.reAggInterval === 'hour') {
        query.reAggInterval = 3600;
      } else if (query.reAggInterval === 'day') {
        query.reAggInterval = 3600 * 24;
      } else if (query.reAggInterval === 'week') {
        query.reAggInterval = 3600 * 24 * 7;
      } else if (query.reAggInterval === 'month') {
        query.reAggInterval = 3600 * 24 * 30;
      } else {
        query.reAggInterval = 'auto';
      }
    }

    // bw compat for older reAggFn
    if (query.reAggFn === 'auto') {
      query.reAggFn = 'none';
    }

    // drop devices not in deviceSummaries
    // ...unless this is a public share! Removing device names will display an error and not load the visualization
    // (and we don't want public shares to load the devicesSummaries)
    if (!$auth.isSharedUser && query.device_name) {
      query.device_name = query.device_name.filter((deviceName) => !!$devices.activeDeviceSummariesByName[deviceName]);
    }

    if (query.metric && !Array.isArray(query.metric)) {
      query.metric = query.metric.split(',');
    }

    // Handling of units/aggregates, need to map legacy units to new aggregateTypes for multi-metric
    // Throw out units and aggregates, which we ultimately compute and then units computed goes away
    // and aggregateTypes gets renamed to units everywhere
    if (!query.aggregateTypes && query.units && !Array.isArray(query.units)) {
      if (query.outsort === 'agg_total') {
        query.outsort = `agg_total_${query.units}`;
        query.aggregateTypes = [query.outsort];
      } else {
        query.aggregateTypes = $dictionary.get(`unitsLegacy.${query.units}`);
      }

      if (query.secondaryUnits) {
        const secondaryAggregateTypes = $dictionary.get(`unitsLegacy.${query.secondaryUnits}`);
        if (secondaryAggregateTypes) {
          secondaryAggregateTypes.forEach((aggregateType) => {
            if (!query.aggregateTypes.includes(aggregateType)) {
              query.aggregateTypes.push(aggregateType);
            }
          });
          query.secondaryOutsort = $dictionary.get(`unitsToOutsortLegacy.${query.secondaryUnits}`);
        }
      }
    }
    query.units = undefined;
    query.aggregates = undefined;

    // Only allowed to set starting_time and ending_time if query is custom
    if (query.lookback_seconds > 0) {
      query.starting_time = null;
      query.ending_time = null;
      // and the inverse - custom queries must have a valid starting_time and ending_time in order to be valid (for creation/execution purposes)
    } else if (!query.starting_time || !query.ending_time) {
      query.lookback_seconds = 3600;
    }
    // remap legacy filters_obj to new filters member
    if (!query.filters && query.filters_obj) {
      query.filters = query.filters_obj;
      delete query.filters_obj;
    }

    // cleanup old filters_obj structs we don't use anymore to match our form configs
    if (query.filters) {
      delete query.filters.custom;
      delete query.filters.filterString;
      if (query.filters.filterGroups && query.filters.filterGroups.length) {
        query.filters.filterGroups
          .filter((group) => group)
          .forEach((group) => {
            delete group.id;
            delete group.filterString;
            group.named = group.named || false;
            group.name = group.name || '';
            group.saved_filters = group.saved_filters || [];

            if (group.filters && group.filters.length) {
              group.filters.forEach((filter) => delete filter.id);
            }
          });
      }
    }

    if (query.saved_filters && query.saved_filters.length > 0) {
      if (!query.filters.filterGroups) {
        query.filters.filterGroups = [];
      }
      query.filters = injectLegacySavedFilters(query.saved_filters, query.filters);
      delete query.saved_filters;
    }

    const queryFilters = query.filters;
    const queryFilterGroups = queryFilters && queryFilters.filterGroups;

    overwriteFilter(queryFilters, { i_device_type: { filterField: 'i_device_subtype' } });

    const appProtocol = getAppProtocol(query.metric, queryFilterGroups);
    // If fastData settings come in, such that we don't allow them, fix them to avoid invalid option/dirty state in Explorer
    const interval = getQueryTimeInterval(query);
    if (
      (interval < 3 * 3600 || interval > 31 * 24 * 3600) &&
      (!appProtocol || (appProtocol && !appProtocol.metadata.fullDataOnly))
    ) {
      query.fastData = 'Auto';
    }

    // Clean up minsPolling if it hasn't been forced
    if (query.minsPolling && !query.forceMinsPolling) {
      delete query.minsPolling;
    }

    const app_protocols = $dictionary.get('app_protocols');
    const minsPollingMap = new Map(
      [
        [app_protocols.azure_express_route_metrics, { min: 5 }],
        [app_protocols.ktranslate_cisco_ras, { min: 5, softMin: 10 }],
        [app_protocols.snmp, { min: 5, softMin: 10 }],
        [app_protocols.snmp_device_metrics, { min: 5, softMin: 10 }]
      ].filter(([key]) => key)
    );

    // force minsPolling for the above appProtocols if minsPolling isn't at least `min`
    if (minsPollingMap.has(appProtocol) && (query.minsPolling || 0) < minsPollingMap.get(appProtocol).min) {
      query.minsPolling = minsPollingMap.get(appProtocol).softMin ?? minsPollingMap.get(appProtocol).min;
    }

    const queryModel = new QueryModel(query);

    const { buckets } = $dataviews.getConfig(queryModel.get('viz_type'));
    const defaultBucket = buckets[0].name;
    const queryBucket = queryModel.get('bucket');

    if (!queryBucket || !buckets.find((bucket) => bucket.name === queryBucket)) {
      queryModel.set({ bucket: defaultBucket });
    }

    // assign sort if not included or bad
    if (!query.outsort || !queryModel.aggregates.find((aggregate) => aggregate.value === query.outsort)) {
      queryModel.set({ outsort: queryModel.get('aggregateTypes')[0] });
    }

    if (isString(queryModel.get('topx'))) {
      queryModel.set({ topx: parseInt(queryModel.get('topx'), 10) });
    }

    return queryModel;
  }
}

const getTotalTrafficOverlay = (query) => {
  const totalOverlayQuery = QueryModel.create(query.serialize());

  const filters = query.get('filters');
  const filterGroups = filters && filters.filterGroups;

  totalOverlayQuery.set({
    isOverlay: true,
    appProtocol: getAppProtocol(query.get('metric'), filterGroups, true),
    metric: ['Traffic'],
    descriptor: 'Total'
  });

  return totalOverlayQuery;
};

const getHistoricalOverlay = (query) => {
  const overlayQuery = QueryModel.create(query.serialize());

  let lookback_seconds = overlayQuery.get('lookback_seconds');
  let starting_time = overlayQuery.get('starting_time');
  let ending_time = overlayQuery.get('ending_time');
  const overlay_day = overlayQuery.get('overlay_day');

  if (lookback_seconds) {
    // "this month"
    if (lookback_seconds === 1) {
      starting_time = moment.utc().startOf('month');
      ending_time = moment.utc();
    }
    // "last month"
    else if (lookback_seconds === 2) {
      starting_time = moment.utc().startOf('month').subtract(1, 'month');
      ending_time = moment.utc().startOf('month');
    } else {
      starting_time = moment.utc().subtract(lookback_seconds, 'second');
      ending_time = moment.utc();
    }
    lookback_seconds = 0;
  } else {
    starting_time = moment.utc(starting_time);
    ending_time = moment.utc(ending_time);
  }

  const filters = query.get('filters');
  const filterGroups = filters && filters.filterGroups;

  overlayQuery.set({
    isOverlay: true,
    metric: ['Traffic'],
    appProtocol: getAppProtocol(query.get('metric'), filterGroups, true),
    descriptor: `Historical Total: ${overlay_day * -1} days back`,
    overlay_timestamp_adjust: true,
    lookback_seconds,
    starting_time: starting_time.add(overlay_day * 24, 'hour').format(DEFAULT_DATETIME_FORMAT),
    ending_time: ending_time.add(overlay_day * 24, 'hour').format(DEFAULT_DATETIME_FORMAT)
  });

  return overlayQuery;
};

export { getTotalTrafficOverlay, getHistoricalOverlay };
