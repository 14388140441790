import React, { useEffect, useMemo, useState } from 'react';
import makeCancelable, { CanceledError } from 'core/util/cancelablePromise';
import { inject } from 'mobx-react';

export default function storeLoader(...collections) {
  return (WrappedComponent) =>
    inject('$store')(({ $store, ...props }) => {
      const [loading, setLoading] = useState(true);

      const stores = useMemo(
        () =>
          collections.map((collection) => {
            let force = false;
            if (collection.store) {
              force = collection.force;
              // make sure this comes last to avoid pulling props from it
              collection = collection.store;
            }
            const [storeName, collectionName = 'collection'] = collection.split('.');

            return {
              store: $store[storeName],
              storeName,
              collection: $store[storeName][collectionName],
              force
            };
          }),
        [$store]
      );

      const [request, setRequest] = useState(null);

      useEffect(() => {
        setRequest(makeCancelable(Promise.all(stores.map(({ collection, force }) => collection.fetch({ force })))));
      }, [stores]);

      useEffect(() => {
        request?.promise
          .then(() => {
            setRequest(null);
            setLoading(false);
          })
          .catch((err) => {
            if (!(err instanceof CanceledError)) {
              setLoading(false);
              console.error('Error loading required resource', err);
            }
          });
        return () => {
          if (request) {
            request.cancel();
          }
        };
      }, [request]);

      return (
        <WrappedComponent
          $store={$store}
          {...props}
          {...stores.reduce((acc, { storeName, store }) => {
            acc[storeName] = store;
            return acc;
          }, {})}
          loading={loading}
        />
      );
    });
}
