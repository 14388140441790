import { computed } from 'mobx';
import CloudMapModel from './CloudMapModel';

export default class GCPMapModel extends CloudMapModel {
  /*
    returns a multidimensional array used for searching gcp tags on the model

    [
      ['environment', 'production'],
      ['account', 'finance'],
      ...
    ]

    @TODO we're not currently seeing any entities with tags exposed. this is here as the foundation for when we do
  */
  @computed
  get searchTags() {
    const tags = this.get('tags') || {};
    return Object.keys(tags).map((key) => [key, tags[key]]);
  }
}
