import { defaultThreshold } from 'app/stores/alerting/PolicyModel';
import { ALERT_SEVERITIES } from 'shared/alerting/constants';

function deserialize(policy) {
  const { thresholds = [] } = policy;
  const { thresholdID, ...templateThreshold } = thresholds[0] || defaultThreshold;

  const initThresholds = ALERT_SEVERITIES.map((severity) => {
    const existingThreshold = thresholds.find((threshold) => threshold.severity === severity);
    if (existingThreshold) {
      return {
        ...existingThreshold,
        enabled: true
      };
    }

    const defaultEnabled = severity === 'major' && thresholds.length === 0;
    const newThreshold = {
      ...templateThreshold,
      severity,
      // If there are no enabled thresholds, then enable Major by default
      enabled: defaultEnabled,
      ackRequired: false,
      filters: null,
      mitigations: [],
      notificationChannels: [],
      conditions: []
    };

    return newThreshold;
  });

  return { ...policy, thresholds: initThresholds };
}

function serialize(policy) {
  return policy;
}

export default {
  serialize,
  deserialize
};
