import React from 'react';
import { action, computed } from 'mobx';
import api from 'core/util/api';
import Collection from 'core/model/Collection';
import { Box, showErrorToast, showSuccessToast, Text } from 'core/components';

import asnBehindHorizonQuery from 'app/stores/peering/queries/asnBehindHorizonQuery';
import countryQuery from 'app/stores/peering/queries/countryQuery';
import discoverPeersSankeyQuery from 'app/stores/peering/queries/discoverPeersSankeyQuery';
import externalTrafficQuery from 'app/stores/peering/queries/externalTrafficQuery';
import trafficQuery from 'app/stores/peering/queries/trafficQuery';
import trafficRatioQuery from 'app/stores/peering/queries/trafficRatioQuery';
import uniqueAsnsQuery from 'app/stores/peering/queries/uniqueAsnsQuery';
import uniqueIpsQuery from 'app/stores/peering/queries/uniqueIpsQuery';
import uniquePrefixesQuery from 'app/stores/peering/queries/uniquePrefixesQuery';
import PeeringdbMappingCollection from 'app/stores/peering/PeeringdbMappingCollection';
import { getAddressString } from 'app/util/geocode';

class PeeringStore {
  collection = new Collection();

  queries = {
    discoverPeersSankeyQuery,
    trafficQuery,
    uniqueAsnsQuery,
    uniqueIpsQuery,
    uniquePrefixesQuery,
    trafficRatioQuery,
    externalTrafficQuery,
    asnBehindHorizonQuery,
    countryQuery
  };

  peeringdbEntityOptions = undefined;

  @action
  async fetchPeeringData(peeringConfig) {
    const { $query } = this.store;
    const traffic_profile = peeringConfig.get('traffic_profile');
    const interface_type = peeringConfig.get('interface_type') || [];
    const lookback = peeringConfig.get('lookback');
    const min_traffic = peeringConfig.get('min_traffic');
    const topn = peeringConfig.get('topn');
    const hops = peeringConfig.get('hops') || [];
    const sites = peeringConfig.get('sites') || [];
    const countries = peeringConfig.get('countries') || [];
    const siteCountries = peeringConfig.get('siteCountries') || [];
    const excluded_asns = peeringConfig.get('excluded_asns') || [];
    const key = `peering_tp${traffic_profile}_it${interface_type}_topn${topn}_excl${excluded_asns}_lb${lookback}_mt${min_traffic}_hop${hops}_site${sites}_ctr${countries}_stC${siteCountries}`;

    const cachedResults = $query.getCachedQueryResults(key, $query.getMaxAgeByLookback(lookback * 86400));

    if (cachedResults) {
      this.collection = new Collection(cachedResults.results, {
        sortState: { field: 'avg_in_bits_per_sec', direction: 'desc' }
      });

      return cachedResults;
    }

    return api
      .get(
        `/api/ui/peering/discover-peers?hops=${['AS_dst', ...hops].join(',')}&sites=${sites.join(
          ','
        )}&countries${countries.join(',')}&siteCountries=${siteCountries.join(',')}`
      )
      .then((results) => {
        this.collection = new Collection(results, { sortState: { field: 'avg_in_bits_per_sec', direction: 'desc' } });
        this.store.$query.cacheQueryResults(key, this.collection);

        return results;
      });
  }

  getFilteredCollection(peeringdbPolicy, peeringdbRatio) {
    if (peeringdbPolicy.length !== 0 || peeringdbRatio.length !== 0) {
      const filteredResults = this.collection.filter(
        (model) => {
          const policy_general = model.get('peeringdbData') && model.get('peeringdbData').data.policy_general;
          const info_ratio = model.get('peeringdbData') && model.get('peeringdbData').data.info_ratio;

          return (
            (peeringdbPolicy.length === 0 || peeringdbPolicy.includes(policy_general)) &&
            (peeringdbRatio.length === 0 || peeringdbRatio.includes(info_ratio))
          );
        },
        { immutable: true } // dont actually filter the collection just return an array of filtered models
      );

      return new Collection(filteredResults, { sortState: this.collection.sortState });
    }

    return this.collection;
  }

  @action
  fetchTrafficProfile() {
    return api.get('/api/ui/peering/traffic-profile');
  }

  fetchPeeringAsn(asn) {
    return api.get(`/api/ui/peering/peeringdb/asn?asn=${asn}`).then((res) => (res?.noContent ? null : res));
  }

  fetchPeeringNetworkEntities() {
    return api.get('/api/ui/peering/peeringdb/network');
  }

  fetchPeeringInfo(asns) {
    return api.get(`/api/ui/peering/peeringdb/info?asns=${asns.join(',')}`);
  }

  fetchPeeringMapOptions(asns) {
    return Promise.resolve().then(() => {
      if (asns && Array.isArray(asns) && asns.length) {
        return api.get(`/api/ui/peering/peeringdb/map/options?asns=${asns.join(',')}`);
      }
      if (!this.peeringdbEntityOptions) {
        return api.get('/api/ui/peering/peeringdb/map/options').then((res) => {
          this.peeringdbEntityOptions = res;
          return res;
        });
      }
      return this.peeringdbEntityOptions;
    });
  }

  fetchPeeringdbFacOptions() {
    return this.fetchPeeringMapOptions().then(({ facilities }) =>
      facilities
        .map((fac) => ({
          name: fac.name,
          label: (
            <Box>
              <Text as="div">{fac.name}</Text>
              <Text small muted>
                {getAddressString({
                  address: fac.address1,
                  city: fac.city,
                  country: fac.country,
                  region: fac.state,
                  postal: fac.zipcode
                })}
              </Text>
            </Box>
          ),
          filterLabel: `${fac.name} , ${getAddressString({
            address: fac.address1,
            city: fac.city,
            country: fac.country,
            region: fac.state,
            postal: fac.zipcode
          })}`,
          value: fac.id,
          address: getAddressString({
            address: fac.address1,
            city: fac.city,
            country: fac.country,
            region: fac.state,
            postal: fac.zipcode
          }),
          latitude: fac.latitude,
          longitude: fac.longitude,
          city: fac.city,
          country: fac.country,
          state: fac.state
        }))
        .sort((a, b) => a.name.localeCompare(b.name))
    );
  }

  fetchPeeringdbIxOptions() {
    return this.fetchPeeringMapOptions().then(({ exchanges }) =>
      [...exchanges].map((ix) => ({ label: ix.name, value: ix.id })).sort((a, b) => a.label.localeCompare(b.label))
    );
  }

  @computed
  get peerInterfaceOptions() {
    return this.store.$interfaces.collection.unfiltered
      .map((interf) => ({
        value: `${interf.get('device_id')}$${interf.get('snmp_id')}`,
        label: interf.get('interface_description')
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  getPeeringdbMappingCollection(asns) {
    return Promise.all([this.fetchPeeringMapOptions(asns), api.get('/api/ui/peering/peeringdb/map/all')]).then(
      ([{ facilities, exchanges, other }, mappings]) => {
        const fac = facilities.map((fac_entry) => {
          const mapped_sites = mappings.sites
            .filter((map) => parseInt(map.fac_id) === fac_entry.id)
            .reduce((acc, mapping) => {
              const { site_id, site } = mapping;
              acc.sites = (acc.sites || []).concat({ ...site });
              acc.kentik_mapped_id = (acc.kentik_mapped_id || []).concat(site_id);
              return acc;
            }, {});

          return {
            ...fac_entry,
            ...mapped_sites,
            category: 'Facilities',
            key: `f${fac_entry.id}`
          };
        });
        const ix = exchanges.map((ix_entry) => {
          const mapped_interface_ids = mappings.interfaces
            .filter((mapping) => parseInt(mapping.ix_id) === ix_entry.id)
            .reduce((acc, mapping) => {
              const { device_id, snmp_id, id, ix_id, ...rest } = mapping;
              if (device_id && snmp_id) {
                acc.interfaces = (acc.interfaces || []).concat({
                  device_id,
                  snmp_id,
                  ...rest
                });
                acc.kentik_mapped_id = (acc.kentik_mapped_id || []).concat(`${device_id}$${snmp_id}`);
              }
              return acc;
            }, {});

          return {
            ...ix_entry,
            ...mapped_interface_ids,
            category: 'Exchanges',
            key: `i${ix_entry.id}`
          };
        });

        // Note: this is plenty fast but in general we can optimize this by not looping other potentially

        const otherOptions = [];
        if (asns && other) {
          other.forEach((other_entry) => {
            if (other_entry.type === 'fac') {
              const mapped_other_sites = mappings.sites
                .filter(({ fac_id }) => parseInt(fac_id) === other_entry.id)
                .reduce((acc, mapping) => {
                  const { site_id, site } = mapping;
                  acc.sites = (acc.sites || []).concat({ ...site });
                  acc.kentik_mapped_id = (acc.kentik_mapped_id || []).concat(site_id);
                  return acc;
                }, {});

              if (mapped_other_sites.sites?.length) {
                otherOptions.push({
                  ...other_entry,
                  ...mapped_other_sites,
                  category: 'Custom - Facilities',
                  key: `cf${other_entry.id}`
                });
              }
            } else if (other_entry.type === 'ix') {
              const mapped_other_interface_ids = mappings.interfaces
                .filter((mapping) => parseInt(mapping.ix_id) === other_entry.id)
                .reduce((acc, mapping) => {
                  const { device_id, snmp_id, id, ix_id, ...rest } = mapping;
                  if (device_id && snmp_id) {
                    acc.interfaces = (acc.interfaces || []).concat({
                      device_id,
                      snmp_id,
                      ...rest
                    });
                    acc.kentik_mapped_id = (acc.kentik_mapped_id || []).concat(`${device_id}$${snmp_id}`);
                  }
                  return acc;
                }, {});

              if (mapped_other_interface_ids.interfaces?.length) {
                otherOptions.push({
                  ...other_entry,
                  ...mapped_other_interface_ids,
                  category: 'Custom - Exchanges',
                  key: `ci${other_entry.id}`
                });
              }
            }
          });
        }

        const collection = new PeeringdbMappingCollection(fac.concat(ix, otherOptions));
        collection.group('category');
        return collection;
      }
    );
  }

  getPeeringdbMapping(id, type) {
    return Promise.resolve().then(() => api.get(`/api/ui/peering/peeringdb/map?${type}_id=${id}`));
  }

  setPeeringdbMappings(mapCollection) {
    return Promise.resolve()
      .then(() => {
        const { fac_map, ix_map } = mapCollection.extractValidMappings();
        api.post('/api/ui/peering/peeringdb/map', { body: { fac_map, ix_map } });
      })
      .then(
        (success) => {
          showSuccessToast('PeeringDB mappings saved.');
          return success;
        },
        (error) => {
          console.error('PeeringDB mapping save error', error);
          showErrorToast('Error saving PeeringDB mappings.');
          return error;
        }
      );
  }

  clearAllMappings() {
    return Promise.resolve().then(api.del('/api/ui/peering/peeringdb/map/all'));
  }

  getQuery(name) {
    return this.queries[name];
  }
}

export default new PeeringStore();
