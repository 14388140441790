import { action, computed } from 'mobx';
import { defaultsDeep } from 'lodash';

import { api, deepClone } from 'core/util';
import DeckCollection from 'app/stores/decks/DeckCollection';
import widgets from 'app/components/decks/widgets';
import observationDeckFactory from 'app/stores/decks/observationDeckFactory';
import synthPerformanceDeckFactory from 'app/stores/decks/synthPerformanceDeckFactory';
import cloudDeckFactory from 'app/stores/decks/cloudDecks/cloudDeckFactory';
import azureDeckFactory from 'app/stores/decks/cloudDecks/azureDeckFactory';
import ociDeckFactory from 'app/stores/decks/cloudDecks/ociDeckFactory';
import awsDeckFactory from 'app/stores/decks/cloudDecks/awsDeckFactory';
import gcpDeckFactory from 'app/stores/decks/cloudDecks/gcpDeckFactory';
import reconDeckFactory from './reconDeckFactory';

function filterWidgets(canAccessPropertyName, store) {
  return Object.keys(widgets).filter((widgetName) => {
    const canAccess = widgets[widgetName][canAccessPropertyName];
    if (typeof canAccess === 'function') {
      return canAccess({ ...store });
    }
    return canAccess === undefined ? true : canAccess;
  });
}

function decorateWidgetNames(widgetNames) {
  return widgetNames.map((widgetName) => ({ name: widgetName, ...widgets[widgetName] }));
}

function generateItemLayout(id, config, defaults) {
  return defaultsDeep({}, config, defaults, {
    lg: {
      i: `${id}`,
      x: 0,
      y: 0,
      w: 4,
      h: 3,
      minH: 1,
      minW: 2,
      maxH: 20,
      maxW: 16,
      isDraggable: true,
      isResizable: true
    },
    sm: {
      i: `${id}`,
      x: 0,
      y: 0,
      w: 1,
      h: 3,
      minH: 1,
      minW: 1,
      maxH: 20,
      maxW: 1,
      isDraggable: true,
      isResizable: true
    }
  });
}

function generateLayout(deckWidgets, deckLayout) {
  const layout = deepClone(deckLayout) || { lg: [], sm: [] };

  for (let i = 0; i < deckWidgets.length; i += 1) {
    const { widgetName, id } = deckWidgets[i];
    const { layoutDefaults } = widgets[widgetName];
    const lg = layout.lg[i];
    const sm = layout.sm[i];
    const itemLayout = generateItemLayout(id, { lg, sm }, layoutDefaults);
    layout.lg[i] = itemLayout.lg;
    layout.sm[i] = itemLayout.sm;
  }

  return layout;
}

class DecksStore {
  collection = new DeckCollection();

  getLayout(deck) {
    return generateLayout(deck.widgets, deck.layout);
  }

  /**
   * This will be restricted based on canView
   *
   * Returns an object of widgets
   */
  @computed
  get widgets() {
    return filterWidgets('canView', this.store).reduce((acc, widgetName) => {
      acc[widgetName] = widgets[widgetName];
      return acc;
    }, {});
  }

  /**
   * This will be restricted based on canSelect and the provided filter
   *
   * Returns an array of widgets
   */
  getWidgets(filter) {
    if (!filter || !filter.trim()) {
      return decorateWidgetNames(filterWidgets('canSelect', this.store));
    }

    const filtersLower = filter.toLowerCase().trim().split(' ');
    return decorateWidgetNames(
      filterWidgets('canSelect', this.store).filter((widgetName) => {
        const { title, description } = widgets[widgetName];

        return filtersLower.every(
          (filterLower) => title.toLowerCase().includes(filterLower) || description.toLowerCase().includes(filterLower)
        );
      })
    );
  }

  filterInvalidWidgets(deckWidgets) {
    return deckWidgets.filter((deckWidget) => widgets[deckWidget.widgetName]);
  }

  deckFactories = {
    oci: ociDeckFactory,
    gcp: gcpDeckFactory,
    aws: awsDeckFactory,
    cloud: cloudDeckFactory,
    azure: azureDeckFactory,
    recon: reconDeckFactory,
    home: observationDeckFactory,
    synthPerformanceDash: synthPerformanceDeckFactory
  };

  resetDeck({ type, clean }) {
    const decks = this.collection.get().filter((model) => model.get('type') === type);
    this.collection.remove(decks);

    const config = clean ? { widgets: [], layout: { lg: [], sm: [] } } : this.deckFactories[type](this);
    return this.collection.add({ type, share_level: 'self', config, isDefault: true });
  }

  async getDefaultDeck({ type, share_level = 'self', canCustomize }) {
    // Return existing built ones if they're here
    const decks = this.collection.get().filter((model) => model.get('type') === type);
    if (decks.length > 0) {
      return decks;
    }

    if (!canCustomize) {
      const config = this.deckFactories[type](this);
      return this.collection.add({ type, share_level, config, isDefault: true });
    }

    return api.get('/api/ui/decks/default', { query: { type, share_level } }).then((deckConfigs) => {
      if (deckConfigs && deckConfigs.length > 0) {
        return this.collection.add(deckConfigs);
      }

      const config = this.deckFactories[type](this);
      return this.collection.add({ type, share_level, config, isDefault: true });
    });
  }

  @action
  remoteAddWidget({ widgetName, config }) {
    return this.getDefaultDeck({ type: 'home', canCustomize: true }).then((decks) => {
      const deck = decks.find((d) => d.get('share_level') === 'self');
      deck.addWidget(widgetName, config);
      return deck.save({}, { toast: false });
    });
  }
}

export default new DecksStore();
