import getBaseQuery from './getBaseQuery';

export default function uniqueIpsQuery(queryFilters, asn, direction) {
  const directionLabel = direction === 'src' ? 'Source' : 'Destination';

  return {
    ...getBaseQuery({ queryFilters, asn, direction }),
    topx: 8,
    depth: 75,
    viz_type: 'gauge',
    units: [`unique_${direction}_ip`, 'bytes', 'packets'],
    aggregateTypes: [
      `avg_${direction}_ip`,
      `p95th_${direction}_ip`,
      `max_${direction}_ip`,
      'p95th_bits_per_sec',
      'p95th_pkts_per_sec'
    ],
    aggregateThresholds: {
      p95th_bits_per_sec: 0,
      p95th_pkts_per_sec: 0,
      avg_dst_ip: 0,
      p95th_dst_ip: 0,
      max_dst_ip: 0,
      avg_src_ip: 0,
      p95th_src_ip: 0,
      max_src_ip: 0
    },
    aggregates: [
      {
        value: `avg_${direction}_ip`,
        column: `f_hll(inet_${direction}_addr,0.0001)`,
        fn: 'average',
        label: `${directionLabel} IPs Unique Count Average`,
        unit: `unique_${direction}_ip`,
        group: `${directionLabel} IPs Unique Count`,
        origLabel: 'Average',
        sample_rate: 1,
        raw: true,
        name: `avg_${direction}_ip`
      },
      {
        value: `p95th_${direction}_ip`,
        column: `f_hll(inet_${direction}_addr,0.0001)`,
        fn: 'percentile',
        rank: 95,
        label: `${directionLabel} IPs Unique Count 95th Percentile`,
        unit: `unique_${direction}_ip`,
        group: `${directionLabel} IPs Unique Count`,
        origLabel: '95th Percentile',
        sample_rate: 1,
        name: `p95th_${direction}_ip`
      },
      {
        value: `max_${direction}_ip`,
        column: `f_hll(inet_${direction}_addr,0.0001)`,
        fn: 'max',
        label: `${directionLabel} IPs Unique Count Max`,
        unit: `unique_${direction}_ip`,
        group: `${directionLabel} IPs Unique Count`,
        origLabel: 'Max',
        sample_rate: 1,
        name: `max_${direction}_ip`
      },
      {
        value: 'p95th_bits_per_sec',
        column: 'f_sum_both_bytes',
        fn: 'percentile',
        label: 'Bits/s Sampled at Ingress + Egress 95th Percentile',
        rank: 95,
        unit: 'bytes',
        group: 'Bits/s Sampled at Ingress + Egress',
        origLabel: '95th Percentile',
        sample_rate: 1,
        name: 'p95th_bits_per_sec'
      },
      {
        value: 'p95th_pkts_per_sec',
        column: 'f_sum_both_pkts',
        fn: 'percentile',
        rank: 95,
        label: 'Packets/s Sampled at Ingress + Egress 95th Percentile',
        unit: 'packets',
        group: 'Packets/s Sampled at Ingress + Egress',
        origLabel: '95th Percentile',
        sample_rate: 1,
        name: 'p95th_pkts_per_sec'
      }
    ]
  };
}
