import { computed } from 'mobx';

import Model from 'core/model/Model';
import $companySettings from 'app/stores/companySettings/$companySettings';

import { providerLogos } from 'app/views/edge/cost/components/ProviderLogo';
import CostGroupCollection from './CostGroupCollection';

class ProviderModel extends Model {
  get urlRoot() {
    return '/api/ui/cost/provider';
  }

  get defaults() {
    return {
      name: 'New Provider',
      type: 'transit',
      settings: {
        billingDate: 1
      }
    };
  }

  get messages() {
    return {
      create: `Provider "${this.get('name')}" was added successfully`,
      update: `Provider "${this.get('name')}" was updated successfully`,
      destroy: `Provider "${this.get('name')}" was removed successfully`
    };
  }

  static get logoOptions() {
    return [{ logo: null, value: null, label: '-- Reset (Auto-detect) --' }, ...providerLogos];
  }

  static get billingOptions() {
    return [
      { value: 'weekly', label: 'Weekly' },
      { value: 'monthly', label: 'Monthly' }
    ];
  }

  // fix legacy types
  static fixProviderType(type) {
    if (type === 'free-peer') {
      return 'free_pni';
    }
    if (type === 'paid-peer') {
      return 'paid_pni';
    }

    return type;
  }

  @computed
  get logo() {
    return ProviderModel.logoOptions.find((option) => option.value === this.get('settings.logo'));
  }

  @computed
  get billing() {
    return ProviderModel.billingOptions.find((option) => option.value === this.get('billing'));
  }

  @computed
  get minimumSpend() {
    let value = 0;

    this.costGroups.each((costGroup) => {
      value += costGroup.minimumSpend;
    });

    return {
      value,
      currency: $companySettings.currency
    };
  }

  @computed
  get flatRateCharges() {
    let value = 0;

    this.costGroups.each((costGroup) => {
      value += costGroup.flatRateCharges;
    });

    return {
      value,
      currency: $companySettings.currency
    };
  }

  getCostData(costGroups, interfaceValues) {
    let ingress = 0;
    let egress = 0;
    let cost = 0;
    let minCost = 0;

    const costGroupSummaries = costGroups.map((costGroup) => {
      const costGroupSummary = costGroup.getCostData(interfaceValues);
      const { ingress: currIngress, egress: currEgress, cost: currCost, minCost: currMinCost } = costGroupSummary;

      ingress += currIngress;
      egress += currEgress;
      minCost += currMinCost;
      cost += currCost;
      return costGroupSummary;
    });

    return {
      name: this.get('name'),
      ingress,
      egress,
      minCost,
      cost,
      costGroups: costGroupSummaries
    };
  }

  getCostGroup(id) {
    return this.costGroups.find({ id });
  }

  getCommitCostData(interfaceValues) {
    const costGroups = this.costGroups.get().filter((costGroup) => costGroup.isCommitCostModel);
    return this.getCostData(costGroups, interfaceValues);
  }

  getFlatRateCostData(interfaceValues) {
    const costGroups = this.costGroups.get().filter((costGroup) => costGroup.isFlatRateCostModel);
    return this.getCostData(costGroups, interfaceValues);
  }

  getAllCostData(interfaceValues) {
    const commitData = this.getCommitCostData(interfaceValues);
    const flatRateData = this.getFlatRateCostData(interfaceValues);
    const totalData = {
      name: this.get('name'),
      ingress: commitData.ingress + flatRateData.ingress,
      egress: commitData.egress + flatRateData.egress,
      minCost: commitData.minCost + flatRateData.minCost,
      cost: commitData.cost + flatRateData.cost,
      costGroups: [...commitData.costGroups, ...flatRateData.costGroups]
    };

    return {
      flatRateData,
      commitData,
      totalData
    };
  }

  deserialize(data = {}) {
    const { costGroups, ...rest } = data;
    const { type } = rest;

    this.costGroups = new CostGroupCollection(costGroups);

    return {
      ...rest,
      type: ProviderModel.fixProviderType(type)
    };
  }
}

export default ProviderModel;
