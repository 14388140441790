import {
  MIT2BGP_TEMPLATE_MATCHES,
  MIT2BGP_NUMERIC_OPERATORS,
  MIT2BGP_ALGORITHM_OPTIONS
} from '../mitigationsConstants';

const DESERIALIZE_TEMPLATE = {
  algo: MIT2BGP_ALGORITHM_OPTIONS.map((option, idx) => ({
    type: option.value,
    // Enable top 2 options by default
    enabled: idx < 2
  })),
  goal: {},
  template: { matches: [], actions: [] }
};

// Turn matches{} into array where keys becomes 'type'
function serializeMatches(matches) {
  const matchKeys = Object.keys(matches);
  const serializedMatches = [];

  matchKeys.forEach((matchKey) => {
    const match = matches[matchKey];
    const { vals = [], groups = [], infer, enabled } = match;
    const serializedMatch = { type: matchKey };

    // Omit disabled fields
    if (!enabled) {
      return;
    }

    if (infer) {
      serializedMatch.infer = infer;
    }

    // Skip parsing vals if inferred
    serializedMatch.vals = [];

    // Individual conditions
    vals.forEach((entry) => {
      // Drop empty values
      if (entry.val) {
        serializedMatch.vals.push({ val: [entry.val], op: [entry.op] });
      }
    });

    // Grouped conditions
    groups.forEach((group) => {
      const apiEntry = { val: [], op: [] };
      group.vals.forEach((entry) => {
        // Drop empty values
        if (entry.val) {
          apiEntry.val.push(entry.val);
          apiEntry.op.push(entry.op);
        }
      });

      serializedMatch.vals.push(apiEntry);
    });

    serializedMatches.push(serializedMatch);
  });

  return serializedMatches;
}

// Turn actions{} into array where keys becomes 'type'
function serializeActions(actions) {
  // Combine sample & terminal into single value for api
  const sampleTerminalAction = [];

  if (actions['FS_ACTION_TYPE_ACTION:SAMPLE']?.enabled) {
    sampleTerminalAction.push('sample');
    actions['FS_ACTION_TYPE_ACTION:SAMPLE'].enabled = false;
  }

  if (actions['FS_ACTION_TYPE_ACTION:TERMINAL']?.enabled) {
    sampleTerminalAction.push('terminal');
    actions['FS_ACTION_TYPE_ACTION:TERMINAL'].enabled = false;
  }

  actions.FS_ACTION_TYPE_ACTION = { val: sampleTerminalAction.join('-'), enabled: sampleTerminalAction?.length > 0 };

  // construct actions
  const actionsKeys = Object.keys(actions);
  const serializedActions = [];

  actionsKeys.forEach((actionKey) => {
    const { enabled, ...action } = actions[actionKey];

    if (enabled) {
      serializedActions.push({ type: actionKey, ...action });
    }
  });

  return serializedActions;
}

function serializeAlgorithms(algorithms) {
  // API expects algo to be array of 1
  const serializedAlgorithms = [];
  algorithms.forEach(({ type, enabled, ...algoItem }) => {
    if (enabled) {
      if (type.includes('port')) {
        delete algoItem.v4budget;
        delete algoItem.v6budget;
        algoItem.traffic /= 100;
        algoItem.exclude = algoItem.exclude_port;
      }

      if (type.includes('ip')) {
        delete algoItem.noproto;
        delete algoItem.traffic;
        algoItem.v4budget = -algoItem.v4budget;
        algoItem.v6budget = -algoItem.v6budget;
        algoItem.exclude = algoItem.exclude_ip;
      }

      delete algoItem.exclude_port;
      delete algoItem.exclude_ip;

      serializedAlgorithms.push({ [type]: { ...algoItem } });
    }
  });
  return serializedAlgorithms;
}

function serialize(mit2bgp) {
  if (mit2bgp?.algo) {
    mit2bgp.algo = serializeAlgorithms(mit2bgp.algo);
  }

  if (mit2bgp?.goal) {
    mit2bgp.goal.traffic /= 100;
  }

  if (mit2bgp?.template?.matches) {
    mit2bgp.template.matches = serializeMatches(mit2bgp.template.matches);
  }

  if (mit2bgp?.template?.actions) {
    mit2bgp.template.actions = serializeActions(mit2bgp.template.actions);
  }

  return mit2bgp;
}

function deserializeMatches(matches) {
  const deserializedMatches = {};

  matches.forEach((match) => {
    const { vals = [], infer, type: matchType } = match;
    const deserializedMatch = { vals: [], groups: [], enabled: true };
    const defaultOp = MIT2BGP_NUMERIC_OPERATORS[0].value;
    const { canInfer } = MIT2BGP_TEMPLATE_MATCHES.find((option) => option.value === matchType);

    if (canInfer) {
      deserializedMatch.infer = infer;
    }

    // If intentionally blank
    if (vals.length === 0) {
      deserializedMatch.vals.push({ val: '' });
    }

    vals.forEach((entry) => {
      // Individual values
      if (entry.val?.length === 1) {
        deserializedMatch.vals.push({ val: entry.val[0], op: entry.op[0] || defaultOp });
      }

      // Grouped values
      if (entry.val?.length > 1) {
        const groupEntry = { vals: [] };
        entry.val.forEach((valItem, idx) => {
          groupEntry.vals.push({ val: valItem, op: entry.op[idx] || defaultOp });
        });
        deserializedMatch.groups.push(groupEntry);
      }
    });

    deserializedMatches[matchType] = deserializedMatch;
  });

  return deserializedMatches;
}

function deserializeActions(actions) {
  const deserializedActions = {};
  actions.forEach(({ type, val }) => {
    if (type === 'FS_ACTION_TYPE_ACTION') {
      val.split('-').forEach(
        (subVal) =>
          (deserializedActions[`FS_ACTION_TYPE_ACTION:${subVal.toUpperCase()}`] = {
            enabled: true
          })
      );
    } else {
      deserializedActions[type] = { enabled: true, val };
    }
  });
  return deserializedActions;
}

function deserializeAlgorithms(algorithms) {
  const usedAlgoTypes = {};
  const deserializedAlgorithms = algorithms.map((algoItem) => {
    const type = Object.keys(algoItem)[0];
    const algoType = algoItem[type];
    usedAlgoTypes[type] = true;

    // budgets are stored as negative numbers
    if (type.includes('ip')) {
      algoType.v4budget = -(algoType?.v4budget || -4);
      algoType.v6budget = -(algoType?.v6budget || -16);
      algoType.exclude_ip = algoType.exclude || [];
    }

    if (type.includes('port')) {
      algoType.exclude_port = algoType.exclude || [];
      algoType.traffic *= 100;
    }

    delete algoType.exclude;

    return { type, enabled: true, ...algoType };
  });

  MIT2BGP_ALGORITHM_OPTIONS.forEach((option) => {
    if (!usedAlgoTypes[option.value]) {
      // If this is a newly created model, we want to defer to the default settings for enabled/disabled (so we don't set enabled/disabled).
      // If this is an existing model, we want to set the remaining algos to false, since they were intentionally left out.
      const algorithm = { type: option.value };
      if (algorithms.length) {
        algorithm.enabled = false;
      }
      deserializedAlgorithms.push(algorithm);
    }
  });

  return deserializedAlgorithms;
}

function deserialize(mit2bgp) {
  if (mit2bgp?.template?.matches) {
    mit2bgp.template.matches = deserializeMatches(mit2bgp.template.matches);
  }

  if (mit2bgp?.template?.actions) {
    mit2bgp.template.actions = deserializeActions(mit2bgp.template.actions);
  }

  if (mit2bgp?.goal) {
    mit2bgp.goal.traffic *= 100;
  }

  // deserialize algo items, if any exist
  if (mit2bgp?.algo?.length) {
    mit2bgp.algo = deserializeAlgorithms(mit2bgp.algo);
  }

  return Object.assign({}, DESERIALIZE_TEMPLATE, mit2bgp);
}

export default {
  serialize,
  deserialize
};
