import Collection from 'core/model/Collection';
import { computed } from 'mobx';
import PeeringdbMappingModel from './PeeringdbMappingModel';

class PeeringdbMappingCollection extends Collection {
  get url() {
    return '/api/ui/peering/peeringdb/map/all';
  }

  get model() {
    return PeeringdbMappingModel;
  }

  @computed
  get assignedCount() {
    return this.models.filter((model) => model.has('kentik_mapped_id')).length;
  }

  @computed
  get toMapCount() {
    return this.models.filter((model) => model.has('kentik_mapped_id') && !model.get('key', '').startsWith('c')).length;
  }

  @computed
  get hasMappingOptions() {
    return this.models.filter((model) => !model.get('key', '').startsWith('c')).length > 0;
  }

  extractValidMappings() {
    const fac_map = {};
    const ix_map = {};
    this.models
      .filter((model) => model.has('kentik_mapped_id'))
      .forEach((mapping) => {
        const { category, id, kentik_mapped_id } = mapping.get();
        if (category === 'Facilities') {
          kentik_mapped_id.forEach((site_id) => {
            const key = `${site_id}$${id}`;
            fac_map[key] = { site_id, fac_id: id };
          });
        } else {
          kentik_mapped_id.forEach((interface_id) => {
            const [device_id, snmp_id] = interface_id.split('$');
            ix_map[interface_id] = { device_id, snmp_id, ix_id: id };
          });
        }
      });
    return { fac_map: Object.values(fac_map), ix_map: Object.values(ix_map) };
  }
}

export default PeeringdbMappingCollection;
