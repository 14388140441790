import Collection from 'core/model/Collection';
import SwaggerModel from './SwaggerModel';

class SwaggerCollection extends Collection {
  get url() {
    return '/api/ui/tester/links';
  }

  get model() {
    return SwaggerModel;
  }
}

export default SwaggerCollection;
