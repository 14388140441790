import Collection from 'core/model/Collection';
import QueryModel from '../query/QueryModel';
import DashboardModel from './DashboardModel';

export class DashboardCollection extends Collection {
  /**
   * Stores a map of category names -> array of dashboard models. Reset on each fetch.
   */
  categoryDashboardMap = {};

  /**
   * Stores a map
   */
  slugIdMap = {};

  get url() {
    return '/api/ui/dashboards';
  }

  get model() {
    return DashboardModel;
  }

  get defaultGroupBy() {
    return 'category.name';
  }

  get presetFilters() {
    return [
      {
        label: 'Personal',
        fn: (model) => model.isUserLevel
      },
      {
        label: 'Company',
        fn: (model) => model.isCompanyLevel && !model.isPreset
      },
      {
        label: 'Presets',
        fn: (model) => model.isPreset
      }
    ];
  }

  get favorites() {
    return this.unfiltered.filter((view) => view.isFavorite);
  }

  useAsyncAdd = true;

  get defaultSortState() {
    return {
      field: 'dash_title',
      direction: 'asc'
    };
  }

  updateMaps() {
    this.categoryDashboardMap = {};
    this.slugIdMap = {};

    this.get().forEach((model) => {
      const categoryName = model.get('category.name');

      if (!this.categoryDashboardMap[categoryName]) {
        this.categoryDashboardMap[categoryName] = [model];
      } else {
        this.categoryDashboardMap[categoryName].push(model);
      }

      this.slugIdMap[model.slugName] = model.get('id');
    });
  }

  setLastUpdated = () => {
    this.lastUpdated = Date.now();
    this.updateMaps();
  };

  deserialize(data) {
    data = super.deserialize(data);
    data.forEach((row) => {
      row.query = QueryModel.create(row.query || {}).get();
    });
    return data;
  }
}
