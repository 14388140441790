import { action, computed } from 'mobx';
import Model from 'core/model/Model';
import $auth from 'app/stores/$auth';
import $setup from 'app/stores/$setup';

export default class CloudExportTask extends Model {
  get defaults() {
    const { currentRegion } = $auth;

    const isPortalHost = currentRegion === 'US' || currentRegion === '';
    const isEu = currentRegion === 'EU';

    let api_root = isPortalHost ? 'https://api.kentik.com' : `https://api.${currentRegion.toLowerCase()}.kentik.com`;
    let flow_dest = isPortalHost ? 'https://flow.kentik.com' : `https://flow.${currentRegion.toLowerCase()}.kentik.com`;
    if (isEu) {
      api_root = 'https://api.kentik.eu';
      flow_dest = 'https://flow.kentik.eu';
    }

    return {
      enabled: true,
      properties: {
        apply_bgp: false,
        api_root,
        flow_dest,
        task_status: 'PENDING'
      }
    };
  }

  @computed
  get hasDevices() {
    const devices = this.get('devices');

    return devices && devices.length > 0;
  }

  @action
  resetTaskStatus() {
    const properties = this.get('properties');

    return this.save({
      enabled: true,
      properties: {
        ...properties,
        task_status: 'PENDING',
        error_message: ''
      }
    });
  }

  @action
  async save(attributes = {}, options = {}) {
    return super.save(attributes, options).then((result) => {
      $setup.getPlans({ force: true });

      return result;
    });
  }

  get urlRoot() {
    return '/api/ui/cloudExport';
  }

  get sortValues() {
    return {
      properties: () => this.get('properties.gce_project') || this.get('properties.aws_region')
    };
  }

  get omitDuringSerialize() {
    return ['devices', 'fps2hTotal', 'fps5mTotal', 'flowVol30dTotal', 'awsChecker', 'mouseover', 'plan'];
  }

  get isKentikManagedS3() {
    return !!this.get('properties.kentik_managed_s3_region');
  }

  get awsBucketPath() {
    if (this.get('properties.kentik_managed_s3_region')) {
      return `arn:aws:s3:::${this.get('properties.aws_bucket')}`;
    }
    return `${this.get('properties.aws_bucket')}.s3-${this.get('properties.aws_region')}.amazonaws.com`;
  }

  get removalConfirmText() {
    return {
      title: 'Remove Cloud Export',
      text: `Are you sure you want to remove ${this.get('name')}?`
    };
  }

  /*
    Populates the properties.log_collection_types array with an optional list of logs we want to collect

    when:

    [] - metadata-only export
    ['flow'] - collect flow logs
    ['firewall'] - collect firewall logs (currently Azure-only support)
    ['gcp_cloud_run'] - collect gcp_cloud_run logs (GCP-only support)
  */
  getLogCollectionTypes({ cloudProvider, exportDetails }) {
    const logCollectionTypes = [];

    // initialize the 'flow' type using the 'manual_setup_type' found in every export
    let flow = exportDetails.manual_setup_type === 'collect_flow_logs';
    const gcpCloudRun = exportDetails.manual_setup_type === 'gcp_cloud_run';

    if (cloudProvider === 'azure') {
      // azure has their own switch that can override the manual_setup_type
      // the value of azure_collect_flow_logs might be a string, so use JSON.parse to coerce to bool
      flow = JSON.parse(exportDetails.properties.azure_collect_flow_logs);
    }

    if (flow) {
      logCollectionTypes.push('flow');
    }

    if (exportDetails.properties.enable_firewall_log_collection) {
      logCollectionTypes.push('firewall');
    }

    if (gcpCloudRun) {
      logCollectionTypes.push('gcp_cloud_run');
    }

    return logCollectionTypes;
  }

  serialize(data) {
    const { properties } = data;
    if (properties.apply_bgp) {
      if (properties.device_bgp_type !== 'device') {
        properties.device_bgp_neighbor_ip = null;
        properties.device_bgp_neighbor_ip6 = null;
        properties.device_bgp_neighbor_asn = null;
        properties.device_bgp_password = null;
        properties.device_bgp_flowspec = false;
        properties.device_bgp_label_unicast = false;
        properties.bgpPeerIP4 = null;
        properties.bgpPeerIP6 = null;
      }
    }

    if (properties.aws_metadata_only) {
      properties.aws_bucket = 'KENTIK-NO-FLOW';
    }

    if (!properties.azure_collect_flow_logs) {
      properties.azure_storage_account = 'kentik-no-flow';
    }

    if (data.cloud_provider) {
      data.properties.log_collection_types = this.getLogCollectionTypes({
        cloudProvider: data.cloud_provider,
        exportDetails: data
      });

      // if we don't have a cloud provider here, it's likely that we're executing a patch update for task status
      // in this case we're not editing the enrichment scope and will want to skip this part
      if (data.cloud_provider === 'azure') {
        // clean out temporary properties in the enrichment scope and trim resource groups if needed
        properties.azure_enrichment_scope = properties.azure_enrichment_scope.reduce((acc, item) => {
          const { isValid = true, message, ...cleanedItem } = item;

          if (isValid) {
            if (cleanedItem.resourceGroups?.length === 0) {
              // when resource groups are empty, just remove it altogether
              delete cleanedItem.resourceGroups;
            }

            return acc.concat(cleanedItem);
          }

          return acc;
        }, []);
      } else {
        // only send the enrichment scope for azure providers
        delete properties.azure_enrichment_scope;
      }
    }

    return super.serialize(data);
  }

  deserialize(data) {
    if (data) {
      const { properties } = data;
      if (properties) {
        if (properties.aws_bucket === 'KENTIK-NO-FLOW') {
          properties.aws_metadata_only = true;
          properties.aws_bucket = '';
        }
        if (properties.azure_storage_account === 'kentik-no-flow') {
          properties.azure_storage_account = '';
          properties.azure_collect_flow_logs = false;
        } else {
          properties.azure_collect_flow_logs = true;
        }
      }
    }

    return super.deserialize(data);
  }
}
