import Collection from 'core/model/Collection';

import InterfaceGroupModel from './InterfaceGroupModel';

class InterfaceGroupCollection extends Collection {
  type;

  constructor(data = [], options = {}) {
    super(data, options);
    const { type } = options;
    this.type = type;
  }

  get url() {
    return this.type ? `/api/ui/interfaceGroup?type=${this.type}` : '/api/ui/interfaceGroup';
  }

  get model() {
    return InterfaceGroupModel;
  }
}

export default InterfaceGroupCollection;
