import getBaseQuery from './getBaseQuery';

export default function externalTrafficQuery(queryFilters, asn, direction) {
  return {
    ...getBaseQuery({
      queryFilters,
      asn,
      metric: [
        'i_device_site_name',
        'i_device_id',
        `i_${direction}_connect_type_name`,
        `i_${direction}_provider_classification`
      ],
      direction
    }),
    show_total_overlay: true,
    depth: 100,
    topx: 100,
    viz_type: 'table',
    outsort: 'avg_bits_per_sec',
    aggregateTypes: ['avg_bits_per_sec', 'p95th_bits_per_sec', 'max_bits_per_sec'],
    aggregateThresholds: {
      p95th_bits_per_sec: 0,
      avg_dst_as: 0,
      p95th_dst_as: 0,
      max_dst_as: 0,
      p95th_pkts_per_sec: 0,
      avg_dst_ip: 0,
      p95th_dst_ip: 0,
      max_dst_ip: 0,
      avg_src_ip: 0,
      p95th_src_ip: 0,
      max_src_ip: 0,
      avg_src_route_prefix: 0,
      p95th_src_route_prefix: 0,
      max_src_route_prefix: 0,
      avg_bits_per_sec: 0,
      max_bits_per_sec: 0
    },
    aggregates: [
      {
        value: 'avg_bits_per_sec',
        column: 'f_sum_both_bytes',
        fn: 'average',
        label: 'Bits/s Sampled at Ingress + Egress Average',
        unit: 'bytes',
        group: 'Bits/s Sampled at Ingress + Egress',
        origLabel: 'Average',
        sample_rate: 1,
        raw: false,
        name: 'avg_bits_per_sec'
      },
      {
        value: 'p95th_bits_per_sec',
        column: 'f_sum_both_bytes',
        fn: 'percentile',
        label: 'Bits/s Sampled at Ingress + Egress 95th Percentile',
        rank: 95,
        unit: 'bytes',
        group: 'Bits/s Sampled at Ingress + Egress',
        origLabel: '95th Percentile',
        sample_rate: 1,
        raw: false,
        name: 'p95th_bits_per_sec'
      },
      {
        value: 'max_bits_per_sec',
        column: 'f_sum_both_bytes',
        fn: 'max',
        label: 'Bits/s Sampled at Ingress + Egress Max',
        unit: 'bytes',
        group: 'Bits/s Sampled at Ingress + Egress',
        origLabel: 'Max',
        sample_rate: 1,
        raw: false,
        name: 'max_bits_per_sec'
      }
    ]
  };
}
