export default {
  all_devices: true,
  filters: {
    connector: 'All',
    filterGroups: [
      {
        connector: 'All',
        name: '',
        named: false,
        autoAdded: false,
        saved_filters: [],
        filterGroups: [],
        not: false,
        filters: [{ filterField: 'i_dst_network_bndry_name', operator: '=', filterValue: 'external' }]
      }
    ]
  },
  metric: ['service_name'],
  show_overlay: false,
  show_total_overlay: false,
  topx: 25,
  depth: 25,
  viz_type: 'table'
};
