import ModuleConfigCollection from 'app/stores/moduleConfig/ModuleConfigCollection';
import $interfaceGroup from 'app/stores/interfaceGroup/$interfaceGroup';

import InterfaceClassModel from './InterfaceClassModel';

class InterfaceClassCollection extends ModuleConfigCollection {
  get moduleName() {
    return 'ic';
  }

  get collectionProperty() {
    return 'icRules';
  }

  get model() {
    return InterfaceClassModel;
  }

  get interfaceGroupCollection() {
    return $interfaceGroup.icCollection;
  }

  get defaults() {
    return [
      {
        connectivityType: 'transit',
        networkBoundary: 'external'
      },
      {
        connectivityType: 'paid_pni',
        networkBoundary: 'external'
      },
      {
        connectivityType: 'free_pni',
        networkBoundary: 'external'
      },
      {
        connectivityType: 'ix',
        networkBoundary: 'external'
      },
      {
        connectivityType: 'customer',
        networkBoundary: 'external'
      },
      // {
      //   connectivityType: 'cloud_interconnect',
      //   networkBoundary: 'external'
      // },
      // {
      //   connectivityType: 'virtual_cross_connect',
      //   networkBoundary: 'external'
      // },
      // {
      //   connectivityType: 'aggregation_interconnect',
      //   networkBoundary: 'internal'
      // },
      // {
      //   connectivityType: 'datacenter_interconnect',
      //   networkBoundary: 'internal'
      // },
      {
        connectivityType: 'backbone',
        networkBoundary: 'internal'
      },
      {
        connectivityType: 'embedded_cache',
        networkBoundary: 'internal'
      }
    ];
  }
}

export default InterfaceClassCollection;
