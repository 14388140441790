import { action } from 'mobx';
import Collection from 'core/model/Collection';
import BgpResultModel from './BgpResultModel';

class BgpResultCollection extends Collection {
  get model() {
    return BgpResultModel;
  }

  get defaultGroupBy() {
    return 'prefix';
  }

  get defaultSortState() {
    return {
      field: 'prefix'
    };
  }

  @action
  toggleGroup(group) {
    this.groupBy = group ? this.defaultGroupBy : null;
  }
}

export default BgpResultCollection;
