import { action, computed, observable, toJS } from 'mobx';
import Collection from 'core/model/Collection';
import { isEqual } from 'lodash';
import InsightVoteModel from './InsightVoteModel';

export const ignoreInsightNamePrefixes = ['test', 'operate.capacity', 'custom.insight.V4 Synth'];

class InsightVotesCollection extends Collection {
  @observable.ref
  serverFilter = {};

  constructor({ $insights }) {
    super({});

    this.$insights = $insights;

    this.fetchOptions = {
      limit: 1000,
      ignoreInsightNamePrefixes,
      groupLimit: -1
    };

    this.resetState();
  }

  get url() {
    return '/api/ui/insights/votes/search';
  }

  get fetchMethod() {
    return 'post';
  }

  get queuedFetchKey() {
    return this.id;
  }

  get model() {
    return InsightVoteModel;
  }

  get length() {
    return this.size;
  }

  @action
  setServerFilter(filter) {
    if (!isEqual(filter, toJS(this.serverFilter))) {
      this.serverFilter = { ...filter };

      return this.queuedFetch();
    }

    return this.fetch();
  }

  getServerFilter() {
    const { insightType, ...filter } = toJS(this.serverFilter);

    Object.entries(filter).forEach(([field, value]) => {
      if (!value || value.length === 0) {
        delete filter[field];
      }
    });

    if (filter.families) {
      filter.insightNames = (filter.insightNames || []).concat(
        Object.values(this.$insights.insightTypes)
          .filter((type) => filter.families.includes(type.family))
          .map((type) => type.insightName)
      );
    }

    return filter;
  }

  @action
  clearFilters() {
    this.serverFilter = {};

    super.clearFilters();

    this.queuedFetch();
  }

  @computed
  get hasFilter() {
    return (
      Object.keys(this.serverFilter).length > 0 ||
      !!this.filterState ||
      !!this.activePresetFilter ||
      this.discreteFilters.length > 0
    );
  }

  async fetch(options = {}) {
    const { data = {} } = options;
    return super.fetch({
      ...options,
      data: {
        ...this.fetchOptions,
        ...this.getServerFilter(),
        ...data
      }
    });
  }

  async queuedFetch(options = {}) {
    const { data = {} } = options;
    this.lastFetched = Date.now();
    return super.queuedFetch({
      ...options,
      data: {
        ...this.fetchOptions,
        ...this.getServerFilter(),
        ...data
      }
    });
  }

  deserialize(rawData) {
    return super.deserialize(rawData?.votes || []);
  }
}

export default InsightVotesCollection;
