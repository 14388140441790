import { computed } from 'mobx';
import Collection from 'core/model/Collection';
import api from 'core/util/api';
import collatePromises from 'core/util/collatePromises';
import CustomDimensionModel from './CustomDimensionModel';

class CustomDimensionCollection extends Collection {
  get defaultSortState() {
    return {
      field: 'id',
      direction: 'desc'
    };
  }

  get url() {
    return '/api/ui/customdimensions';
  }

  get urlWithoutRebuildingDevices() {
    return `${this.url}?skipSync=1`;
  }

  get model() {
    return CustomDimensionModel;
  }

  get filterFieldWhitelist() {
    return new Set(['id', 'display_name', 'name', 'type']);
  }

  @computed
  get totalPopulatorsCount() {
    return this.reduce((carry, customD) => {
      // if populatorCount is 'Loading' we should treat it as 0
      const populatorCount = Number.isInteger(customD.get('populatorCount')) ? customD.get('populatorCount') : 0;
      carry += populatorCount ?? 0;
      return carry;
    }, 0);
  }

  async rebuildCustomerDevices() {
    return api.get(`${this.url}/rebuildDevices`);
  }

  // fetch populator counts can happen in multiple places, and due to async nature, may be called multiple times
  // before intial call has resolved with actions that could make initial call inaccurate. For this reason only
  // process results from last call via collatePromises to ensure accurate count.
  async fetchPopulatorCounts() {
    const promise = api.get('/api/ui/customdimensions/populatorcounts', {
      showErrorToast: false,
      rawResponse: true
    });
    return collatePromises({ key: 'CustomDimensionCollection.fetchPopulatorCounts', promise }).then(
      ({ body: popCounts }) => {
        this.each((customD) => {
          // only update bulk (popCount === null), and set to 0 if no results came back so no eternal spinner.
          const id = customD.get('id');

          const initialPopulatorCount = (popCounts[id] && popCounts[id].populatorCount) || 0;

          /**
           * if custom dimension was auto generated, we need to check if intial populators count matches last queued batch
           * If it doesnt match, it mean autogenerated populators are not persisted into the postgress yes, so we will show Pending...
           */
          if (customD.isAutoGenerated) {
            customD.set('populatorCount', 'Loading...');
            customD.fetchPopulatorsQueueData().then((response) => {
              if (response.length === 0) {
                customD.set('populatorCount', initialPopulatorCount);
                return;
              }

              const totalPopulatorsApplied =
                +(response[0]?.upserts?.total ?? 0) - +(response[0]?.upserts?.invalid ?? 0);

              if (totalPopulatorsApplied > 0 && totalPopulatorsApplied > initialPopulatorCount) {
                customD.set('populatorCount', 'Pending...');
                return;
              }

              // always default to initial
              customD.set('populatorCount', initialPopulatorCount);
            });
          } else {
            customD.set('populatorCount', initialPopulatorCount);
          }
        });
      },
      () => {
        this.each((customD) => {
          // just set to 0 if no results came back so no eternal spinner.
          customD.set('populatorCount', 0);
        });
      }
    );
  }
}

export default CustomDimensionCollection;
