import Model from 'core/model/Model';

class CurrencyModel extends Model {
  deserialize(data = {}) {
    const { name } = data;
    let symbol = '';

    if (name) {
      try {
        const numberParts = new Intl.NumberFormat(undefined, { style: 'currency', currency: name }).formatToParts(0);
        symbol = numberParts && numberParts[0] ? numberParts[0].value : name;
      } catch (err) {
        symbol = name;
      }
    }

    return {
      ...data,
      symbol
    };
  }
}

export default CurrencyModel;
