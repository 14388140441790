import Model from 'core/model/Model';
import { computed } from 'mobx';
import $labels from 'app/stores/label/$labels';

class CredentialModel extends Model {
  get urlRoot() {
    return '/api/ui/credential';
  }

  @computed
  get labels() {
    return $labels.getLabels(
      'credential_vault',
      this.isNew ? this.id : this.get('credentials') && this.get('credentials')[0]?.id
    );
  }

  @computed
  get inUse() {
    const consumers = this.get('consumers');
    return consumers ? consumers.length > 0 : false;
  }

  @computed
  get deviceConsumers() {
    const consumers = this.get('consumers');
    return consumers?.filter((consumer) => consumer.type === 'nms') || [];
  }

  @computed
  get synthConsumers() {
    const consumers = this.get('consumers');
    return consumers?.filter((consumer) => consumer.type === 'synth') || [];
  }

  get initialTokenValue() {
    return { key: '', value: '' };
  }

  get defaults() {
    return {
      tokens: [this.initialTokenValue]
    };
  }

  deserialize(data) {
    if (data) {
      const tokens = (data.credentials || []).map((cred) => ({ key: cred.name.split('.')[1], value: null }));
      return super.deserialize({ ...data, tokens });
    }

    return super.deserialize(data);
  }

  get messages() {
    return {
      create: 'Credential was added successfully',
      update: `Credential "${this.get('name')}" was updated successfully`,
      destroy: `Credential "${this.get('name')}" was removed successfully`
    };
  }
}

export default CredentialModel;
