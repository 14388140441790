import { action } from 'mobx';

import DeviceCollection from '../device/DeviceCollection';

class InterfacesDeviceCollection extends DeviceCollection {
  fetch() {
    // For now, we want to exclude cloud devices from IC, eventually we may want to change this
    return super.fetch({ query: { filterCloud: true } });
  }

  getFilterValues(model) {
    return ['device_name'].map((name) => model[name] || model.get(name));
  }

  get secondarySort() {
    return {
      field: 'interfaceCount',
      direction: 'desc'
    };
  }

  get defaultSortState() {
    return {
      field: 'percentMatched',
      direction: 'desc'
    };
  }

  @action
  updateDeviceInterfaceMatchData(deviceMatches) {
    Object.keys(deviceMatches).forEach((key) => {
      const matchInfo = deviceMatches[key];
      if (matchInfo) {
        const model = this.get(key);
        if (model) {
          model.set({ loadingInterfaceResults: false, ...matchInfo });
        }
      }
    });
  }
}

export default InterfacesDeviceCollection;
