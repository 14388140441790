import Validator from 'validatorjs';
import { memoize } from 'lodash';
import { MAX_SAMPLING_RATE, MIN_SAMPLING_RATE } from '@kentik/ui-shared/hybrid/constants';

import $dictionary from 'app/stores/$dictionary';
import $clouds from 'app/stores/clouds/$clouds';

import AzureExportConfigFields from 'app/views/setup/tasks/cloud_v2/CloudExportConfigWizard/components/azure/AzureExportConfigFields';
import AzureEnrichmentScope from 'app/views/setup/tasks/cloud_v2/CloudExportConfigWizard/components/azure/AzureEnrichmentScope';
import AzureAutomatedSetup from 'app/views/setup/tasks/cloud_v2/CloudExportConfigWizard/components/azure/AzureAutomatedSetup';
import AzureCliSetup from 'app/views/setup/tasks/cloud_v2/CloudExportConfigWizard/components/azure/AzureCliSetup';

import { buildOptionsFromMap } from 'core/form/components/modalSelect/selectHelpers';
import { trimTransform } from '../utils';

export const AZURE_CLOUD_EXPORT_CONFIG_STEP = 'azure-export-configuration';
export const AZURE_CLOUD_ENRICHMENT_SCOPE_STEP = 'azure-enrichment-scope';
export const AZURE_CLOUD_FINAL_STEP = 'azure-final-steps';
export const AZURE_TERRAFORM_STEP = 'azure-terraform';
export const AZURE_POWERSHELL_STEP = 'azure-powershell';

const storageAcctRules = ['required', 'min:3', 'max:24', 'string', 'alpha_num', 'azureUniqueStorageAccount'];

export const AZURE_EXPORT_ADDITIONAL_STEPS = [
  {
    id: AZURE_CLOUD_EXPORT_CONFIG_STEP,
    title: 'Kentik Export AZURE Configuration',
    component: AzureExportConfigFields,
    registerValidators: (form, model) => {
      Validator.registerAsync('unique_sub_id', (value, args, attribute, passes) => {
        // this will check if the subscription id already has the security principal enabled by another export
        // see checkSubscriptionIdAccess() in src/node/services/cloudExport/checkCloudProviderIdEligibility.js for logic
        const azureSubId = form.getValue('properties.azure_subscription_id');
        return $clouds
          .checkCloudProviderIdEligibility({
            cloudProvider: 'azure',
            ids: [azureSubId]
          })
          .then((response) => {
            if (!response) {
              // $clouds.checkCloudProviderIdEligibility returns nothing, will only throw an error
              // no response means no error, all good to proceed
              passes(true);
            }
          })
          .catch((err) => {
            passes(false, err.message);
          });
      });
      Validator.register(
        'azureUniqueStorageAccount',
        (value) =>
          model.collection.filter(
            (cloudExport) =>
              cloudExport.id !== model.id &&
              cloudExport.get('cloud_provider') === 'azure' &&
              cloudExport.get('properties').azure_storage_account === value,
            { immutable: true }
          ).length === 0,
        'The Storage Account Name must be unique'
      );
      Validator.register(
        'samplingRate',
        function samplingRate(value) {
          const { properties } = this.validator.input;
          if (properties?.sampling_type !== 'sampling_rate') {
            return true;
          }

          return value >= MIN_SAMPLING_RATE && value <= MAX_SAMPLING_RATE;
        },
        `Sampling Rate must be a positive number between ${MIN_SAMPLING_RATE} and ${MAX_SAMPLING_RATE}`
      );
    },
    fieldsToRemove: ($cloudExportWizard) => {
      if ($cloudExportWizard.isFlowLogCollectionEnabled || $cloudExportWizard.isFirewallLogCollectionEnabled) {
        return [];
      }
      return ['properties.azure_storage_account'].filter((optionalField) =>
        $cloudExportWizard.formHasField(optionalField)
      );
    },
    generateFields: memoize(
      () => ({
        'properties.azure_subscription_id': {
          label: 'Subscription ID',
          helpText: `Authorize the Azure portal to create a Service Principal representing NSG Flow Exporter.
              Your Azure role (e.g. Global Administrator) must allow you to grant access by enterprise applications.`,
          rules: [
            'required',
            'max:36',
            'regex:^({){0,1}[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(}){0,1}$',
            'unique_sub_id'
          ],
          transform: {
            out: trimTransform
          }
        },
        'properties.azure_security_principal_enabled': {
          defaultValue: false
        },
        'properties.azure_resource_group': {
          label: 'Resource Group to Monitor',
          rules: 'required_if:cloud_provider,azure',
          transform: {
            out: trimTransform
          }
        },
        'properties.azure_collect_flow_logs': {
          label: 'Flow Log Collection',
          defaultValue: 'false'
        },
        'properties.azure_storage_account': {
          label: 'Storage Account Name',
          helpText:
            'Flow logs will be exported to this Kentik Cloud from an Azure Storage Account with the above name. The name must be a globally unique.',
          rules: storageAcctRules,
          transform: {
            out: trimTransform
          }
        },
        'properties.azure_location': {
          label: 'Location',
          options: buildOptionsFromMap($dictionary.getCloudMetadataKeyToNameMap('azure')),
          rules: 'required'
        },
        'properties.azure_access_check': {},
        'properties.azure_enrichment_scope': {
          isComplexArray: true
        },
        'properties.azure_enrichment_scope[].subscriptionId': {},
        'properties.azure_enrichment_scope[].isValid': {
          defaultValue: true
        },
        'properties.azure_enrichment_scope[].resourceGroups': {
          defaultValue: []
        },
        'properties.azure_enrichment_scope[].message': {
          defaultValue: null
        },
        'properties.sampling': { defaultValue: null },
        'properties.sampling_rate': {
          label: 'Sampling Rate',
          defaultValue: null,
          rules: ['samplingRate'],
          transform: {
            out: (value) => (value ? parseInt(value, 10) : null)
          }
        },
        'properties.sampling_type': { defaultValue: null }
      }),
      ($cloudExportWizard) => $cloudExportWizard.selectedCloudProvider
    )
  },
  {
    id: AZURE_CLOUD_ENRICHMENT_SCOPE_STEP,
    title: 'Azure Metadata Enrichment Scope',
    component: AzureEnrichmentScope
  }
];

export const AZURE_TERRAFORM_CONFIG_STEP = {
  id: AZURE_TERRAFORM_STEP,
  title: 'Azure Terraform',
  component: AzureAutomatedSetup
};

export const AZURE_POWERSHELL_CONFIG_STEP = {
  id: AZURE_POWERSHELL_STEP,
  title: 'Azure Powershell',
  component: AzureCliSetup
};
