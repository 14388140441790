import Collection from 'core/model/Collection';
import ServiceHistoryModel from './ServiceHistoryModel';

class ServiceHistoryCollection extends Collection {
  get url() {
    return '/api/ui/ott/service-history';
  }

  get model() {
    return ServiceHistoryModel;
  }

  async fetch({ names, fromDate }) {
    return super.fetch({ query: { names, fromDate } });
  }
}

export default ServiceHistoryCollection;
