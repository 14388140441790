import { action, computed, observable } from 'mobx';
import { get, isEmpty, uniq } from 'lodash';
import moment from 'moment';

import Model from 'core/model/Model';
import { trimmedArrFromStr } from 'core/util';
import { arrayToCommaSeparatedString, commaSeparatedStringToArray } from 'app/util/utils';
import {
  userDefinedTestTypes,
  flowBasedTestTypes,
  agentMatrixTestTypes,
  urlDefinedTestTypes,
  hostnameTestTypes,
  SYNTH_TRACE_PERIOD_DEFAULT,
  TEST_TYPES,
  dnsTestTypes
} from 'app/util/constants';
import { inputHasIpV6, getRawHealthTimeRange } from 'app/views/synthetics/utils/syntheticsUtils';
import { getCreditCostPerMin } from 'shared/synthetics/calculateCreditUtils';
import {
  TEST_INTERVAL_MIN,
  DEFAULT_THRESHOLD_MS_VALUES,
  DEFAULT_MBPS_VALUES,
  ALERTING_TYPES
} from 'shared/synthetics/constants';
import TestResultsState from './TestResultsState';
import PreviewTestResultsState from './PreviewTestResultsState'; // see the fetch override for when we swap this in instead of TestResultsState
import AgentResultsState from './AgentResultsState';

const CONTENT_TYPE = 'content-type';

const hasPing = (tasks = []) => tasks.some((item) => item === 'ping' || item === 'knock');

class TestModel extends Model {
  get defaults() {
    return {
      health: null,
      healthErrors: [],
      healthLoaded: false,
      creditBurnRate: 0, // expected to be in credits/min
      latestResult: null
    };
  }

  // must be here so destroy works properly.
  get urlRoot() {
    return '/api/ui/synthetics/tests';
  }

  @observable
  agentResults = new AgentResultsState({ store: this.store, test: this });

  @observable
  results = new TestResultsState({ store: this.store, test: this });

  @observable
  dataviews = observable.map({}, { deep: true });

  @observable
  togglingStatus = false;

  @computed
  get isPaused() {
    return this.get('test_status') === 'P';
  }

  @computed
  get isPreview() {
    return this.get('test_status') === 'I';
  }

  @computed
  get hasPing() {
    return hasPing(this.get('config.tasks', []));
  }

  @computed
  get hasTraceroute() {
    return this.get('config.tasks', []).includes('traceroute');
  }

  @computed
  get hasBGPMonitoring() {
    return this.get('config.tasks', []).includes('bgp-monitor');
  }

  @computed
  get isTargetHttps() {
    if (this.isUrlDefined) {
      const url = new URL(this.get('config.target.value'));
      return url.protocol === 'https:';
    }
    return false;
  }

  @computed
  get isIpTargetSubTest() {
    return this.isFlowBased || this.isBulkIpTest;
  }

  @computed
  get isMesh() {
    return agentMatrixTestTypes.includes(this.get('test_type'));
  }

  @computed
  get isAgentTest() {
    return this.get('test_type') === TEST_TYPES.AGENT;
  }

  @computed
  get isFlowBased() {
    return flowBasedTestTypes.includes(this.get('test_type'));
  }

  @computed
  get isThroughputTest() {
    return this.get('config.tasks', []).includes('throughput');
  }

  @computed
  get isDns() {
    return dnsTestTypes.includes(this.get('test_type'));
  }

  @computed
  get isHighDensityGrid() {
    return [TEST_TYPES.DNS_GRID, TEST_TYPES.NETWORK_GRID].includes(this.get('test_type'));
  }

  @computed
  get isBulkIpTest() {
    return [TEST_TYPES.IP_ADDRESS, TEST_TYPES.NETWORK_GRID].includes(this.get('test_type'));
  }

  @computed
  get isUserDefined() {
    return userDefinedTestTypes.includes(this.get('test_type'));
  }

  @computed
  get isUrlDefined() {
    return urlDefinedTestTypes.includes(this.get('test_type'));
  }

  @computed
  get isHostname() {
    return hostnameTestTypes.includes(this.get('test_type'));
  }

  @computed
  get isPageLoad() {
    return this.get('test_type') === TEST_TYPES.PAGE_LOAD;
  }

  @computed
  get isHttp() {
    return this.get('test_type') === TEST_TYPES.URL;
  }

  @computed
  get isBgp() {
    return this.get('test_type') === TEST_TYPES.BGP_MONITOR;
  }

  @computed
  get isTransaction() {
    return this.get('test_type') === TEST_TYPES.TRANSACTION;
  }

  @computed
  get validDnsIpsConfig() {
    return this.get('config.healthSettings.dnsValidIps', '');
  }

  @computed
  get hasValidDnsIpsConfig() {
    return !isEmpty(this.validDnsIpsConfig);
  }

  @computed
  get isTargetEditable() {
    const { NETWORK_GRID, AGENT, APPLICATION_MESH } = TEST_TYPES;
    return this.isNew || [NETWORK_GRID, AGENT, APPLICATION_MESH].includes(this.get('test_type'));
  }

  @computed
  get healthStatusRaw() {
    if (this.get('test_status') === 'A') {
      if (!this.get('healthLoaded')) {
        return -2;
      }

      const sort = ['healthy', 'warning', 'critical', 'failing'];
      const sortIndex = sort.indexOf(this.get('health'));
      // tests that had health but do not know are considered failing (3); -1 will be 'pending'
      return sortIndex === -1 && this.get('results_first_valid') !== null ? 3 : sortIndex;
    }

    return -3; // Force really low value for paused
  }

  @computed
  get creditsPerMonth() {
    // base creditBurnRate is in credits/min, so adjust for monthly value.
    return this.get('creditBurnRate') * 60 * 24 * 30;
  }

  @computed
  get labels() {
    return this.store.$labels.getLabels('synth_test', this.id);
  }

  @computed
  get display_name() {
    return this.get('display_name') || this.get('config.name');
  }

  @computed
  get isPerAgentAlerting() {
    return (
      this.get('config.alerting.alertingType') === ALERTING_TYPES.AGENT ||
      this.get('config.alerting.trigger_per_agent_dst')
    );
  }

  @computed
  get isPerTestAlerting() {
    return !this.isPerAgentAlerting;
  }

  @computed
  get frequency() {
    const config = this.get('config');
    return config?.period || config?.ping?.period;
  }

  @computed
  get frequencyLabel() {
    if (typeof this.frequency === 'number') {
      const frequencyInSeconds = this.frequency;

      if (frequencyInSeconds < 60) {
        return `every ${frequencyInSeconds} seconds`;
      }

      if (frequencyInSeconds === 60) {
        return 'every minute';
      }

      if (frequencyInSeconds > 60) {
        return `every ${frequencyInSeconds / 60} minutes`;
      }
    }

    return '';
  }

  // used when searching collections for test data
  @computed
  get filterValue() {
    return `${this.get('display_name')} ${this.get('test_type')}`;
  }

  get omitDuringSerialize() {
    return ['created_by', 'last_updated_by', 'creditBurnRate', 'health', 'healthLoaded'];
  }

  getEncodedKeyValuesStr(arr = [], encode = (val) => val) {
    return arr
      .reduce((agg, curr) => {
        const { key = '', value = '' } = curr;
        const trimmedKey = key.trim();
        const trimmedValue = value.trim();
        if (trimmedKey.length > 0 && trimmedValue.length > 0) {
          agg.push(encode(`${trimmedKey}=${trimmedValue}`));
        }
        return agg;
      }, [])
      .join('&');
  }

  getDecodedKeyValueArr(str = '', decode = (val) => val) {
    return str.split('&').map((item) => {
      const o = item.split('=');
      try {
        return { key: decode(o[0]), value: decode(o[1]) };
      } catch (e) {
        console.warn('Error decoding', str, this.get(), e);
      }
      return { key: o[0], value: o[1] };
    });
  }

  getObjectFromKeyValueHeaders(arr, opts = { allowEmptyValues: false }) {
    if (Array.isArray(arr) && arr.length > 0) {
      return arr.reduce((next, curr) => {
        const { key, value } = curr;
        const oKey = key.toLowerCase().trim();
        const oValue = value.trim();
        if (oKey.length > 0 && (opts.allowEmptyValues || oValue.length > 0)) {
          next[oKey] = oValue;
        }
        return next;
      }, {});
    }
    return {};
  }

  serializeHttpConfig(config) {
    const { bodyType, css_selectors, formValues, headers, method, params } = config.http;

    // turn params into query string and append to target
    if (Array.isArray(params) && params.length > 0) {
      const paramsStr = this.getEncodedKeyValuesStr(params, encodeURI);
      config.target.value = `${config.target.value}${paramsStr.length > 0 ? '?' : ''}${paramsStr}`;
    }

    // create headers and css_selectors maps from form array of key/value pairs
    config.http.hidden_headers = this.serializeHTTPHiddenHeaders(headers);
    config.http.headers = this.getObjectFromKeyValueHeaders(headers);
    config.http.css_selectors = this.getObjectFromKeyValueHeaders(css_selectors);

    if (method !== 'GET') {
      // if body is using form key/values, convert that to encoded string for body and set content-type
      if (bodyType === 'x-www-form-urlencoded' && Array.isArray(formValues) && formValues.length > 0) {
        config.http.body = this.getEncodedKeyValuesStr(formValues);
        config.http.headers[CONTENT_TYPE] = 'application/x-www-form-urlencoded';
      }

      // if body is raw text, set respective content-type
      if (bodyType === 'raw') {
        const { rawType } = config.http;
        const contentType = ['javascript', 'json', 'xml'].includes(rawType) ? 'application' : 'text';
        config.http.headers[CONTENT_TYPE] = `${contentType}/${rawType}`;
      }

      // remove body for bodyType === none
      if (bodyType === 'none') {
        delete config.http.body;
      }
    } else {
      // remove body for GET requests
      delete config.http.body;
    }

    // remove configs that should never go to DB
    delete config.http.params;
    delete config.http.formValues;
    delete config.http.bodyType;
    delete config.http.rawType;
  }

  getArrayOfKeyValuePairs(object) {
    return Object.keys(object).map((key) => ({ key, value: object[key] }));
  }

  deserializeHTTPConfigHeaders(headers, hidden_headers) {
    return this.getArrayOfKeyValuePairs(headers).map((header) => ({
      ...header,
      hide: hidden_headers.includes(header.key)
    }));
  }

  serializeHTTPHiddenHeaders(headers) {
    return headers.reduce((acc, { hide, key }) => (hide ? acc.concat(key) : acc), []);
  }

  deserializeHttpConfig(config) {
    const { body, css_selectors = {}, headers = {}, hidden_headers = [], method } = config.http;

    // if there a query string, populate config.http.params and reset config.target.value without params
    const paramsIndex = config.target.value.indexOf('?');
    if (paramsIndex !== -1) {
      const params = config.target.value.substring(paramsIndex + 1);
      config.http.params = this.getDecodedKeyValueArr(params, decodeURI);
      config.target.value = config.target.value.substring(0, paramsIndex);
    }

    // if headers has content-type, set bodyType and rawType and delete content-type header
    const contentType = headers[CONTENT_TYPE];
    const rawTypes = ['plain', 'javascript', 'json', 'html', 'xml'];
    if (contentType) {
      const contentTypeArr = contentType.split('/');
      const type = contentTypeArr.length > 1 ? contentTypeArr[1] : '';
      let deleteContentType = false;
      if (type === 'x-www-form-urlencoded') {
        config.http.bodyType = 'x-www-form-urlencoded';
        config.http.formValues = this.getDecodedKeyValueArr(body);
        config.http.body = '';
        deleteContentType = true;
      } else if (rawTypes.includes(type)) {
        config.http.bodyType = 'raw';
        config.http.rawType = type;
        deleteContentType = true;
      }
      if (deleteContentType) {
        delete headers[CONTENT_TYPE];
      }
    } else if (method !== 'GET') {
      // without a contentType, and if method is not GET, we can assume there was no post body
      config.http.bodyType = 'none';
    }

    // translate { key1: value1, key2: value2 } => [{ key: key1, value: value1 }, { key: key2, value: value2 }]
    config.http.headers = this.deserializeHTTPConfigHeaders(headers, hidden_headers);
    delete config.http.hidden_headers;
    config.http.css_selectors = this.getArrayOfKeyValuePairs(css_selectors);
  }

  serialize(data) {
    const { config, test_type } = data;

    if (config) {
      // TODO: get rid of this hack and figure out why config.target is getting set to { type: '', value: ''} for mesh
      if (this.isMesh) {
        config.target = {};
      }

      if (this.isBulkIpTest) {
        config.family = inputHasIpV6(config.target.value) ? 'DUAL' : 'v4';
        config.target.value = trimmedArrFromStr(config.target.value);
      }

      if (config.target && config.target.value && typeof config.target.value !== 'string') {
        // remove duplicate entries
        if (Array.isArray(config.target.value)) {
          config.target.value = [...new Set(config.target.value)].join(',');
        }
        config.target.value = `${config.target.value}`;
      }

      if (config.agents && Array.isArray(config.agents)) {
        config.agents = Array.from(
          new Set(
            config.agents.map((id) => {
              if (typeof id === 'string') {
                return parseInt(id, 10);
              }
              return id;
            })
          )
          // omit deleted agents that stuck around if they were the only agent on the test when agent was deleted
        ).filter((id) => this.store.$syn.agents.get(id));
      }

      if (dnsTestTypes.includes(test_type)) {
        if (!!config.ping && !hasPing(config.tasks)) {
          config.ping = null;
        }
        if (!!config.trace && !this.hasTraceroute) {
          config.trace = null;
        }

        if (!Array.isArray(config.servers)) {
          config.family = inputHasIpV6(config.servers) ? 'DUAL' : 'v4';
          config.servers = trimmedArrFromStr(config.servers);
        }
      } else {
        config.protocol = config.protocol ? config.protocol : 'icmp';
      }

      if (config.ping) {
        config.ping.count = config.ping.count ? parseInt(config.ping.count) : 5;
        config.ping.period = config.ping.period ? parseInt(config.ping.period) : 60;
        config.ping.expiry = config.ping.expiry ? parseInt(config.ping.expiry) : 3000;
        config.ping.delay = config.ping.delay ? parseInt(config.ping.delay) : 0;
      }
      if (config.trace) {
        // Sync trace period with ping period while ping period is 60 or above, otherwise set trace period to hard floor of 60
        const pingPeriod = get(config, 'ping.period', 60);
        config.trace.period = pingPeriod < SYNTH_TRACE_PERIOD_DEFAULT ? SYNTH_TRACE_PERIOD_DEFAULT : pingPeriod;
        config.trace.count = config.trace.count ? parseInt(config.trace.count) : 3;
        config.trace.expiry = config.trace.expiry ? parseInt(config.trace.expiry) : 15000;
        config.trace.limit = config.trace.limit ? parseInt(config.trace.limit) : 30;
        config.trace.port = config.trace.port ? parseInt(config.trace.port) : 443;
        config.trace.protocol = config.trace.protocol ? config.trace.protocol : 'tcp';
        config.trace.delay = config.trace.delay ? parseInt(config.trace.delay) : 0;
      }

      if (this.isUrlDefined) {
        const period = config.period ? parseInt(config.period) : 60;
        config.period = period;
        config.expiry = config.expiry ? parseInt(config.expiry) : 3000;
      }

      if (this.isUrlDefined && !this.isTransaction) {
        if (config.http) {
          this.serializeHttpConfig(config);
        }
        const { period } = config;
        config.ping.period = period;
        config.trace.period = period;
      }

      config.port = config.port ? parseInt(config.port) : 0;
      config.rollup_level = config.rollup_level ? parseInt(config.rollup_level) : 1;

      if (config.bgp?.origin) {
        config.bgp.origin = arrayToCommaSeparatedString(config.bgp.origin, false);
      }

      if (config.bgp?.upstream) {
        config.bgp.upstream = arrayToCommaSeparatedString(config.bgp.upstream, false);
      }

      config.healthSettings.response_headers = this.getObjectFromKeyValueHeaders(
        config.healthSettings.response_headers,
        { allowEmptyValues: true }
      );

      Object.entries(config.healthSettings).forEach(([key, value]) => {
        if (key.endsWith('MetricUnit')) {
          // get the metric related to this unit selector
          const metric = key.replace('MetricUnit', '');

          // do not send the metric unit selector field as part of the test config
          delete config.healthSettings[key];

          if (value === 'stdDev') {
            // zero out the ms values so synback will honor stdDev values
            config.healthSettings[`${metric}Warning`] = 0;
            config.healthSettings[`${metric}Critical`] = 0;
          }
        }
      });

      if (config.throughput) {
        config.throughput.period = get(config, 'ping.period', 60);

        // If a user sets bandwidth to 0, we remove it from the config so its not sent to the backend
        // This will test maximum throughput. Only allowed for TCP
        if (config.throughput.protocol.toLowerCase() === 'tcp' && !config.throughput.bandwidth) {
          delete config.throughput.bandwidth;
        }
      }
    }
    return super.serialize(data);
  }

  deserialize(data) {
    const { config, test_type } = data;

    if (config) {
      if (urlDefinedTestTypes.includes(test_type) && test_type !== TEST_TYPES.TRANSACTION && config.http) {
        this.deserializeHttpConfig(config);
      }

      const arrTestTypes = [TEST_TYPES.AGENT, TEST_TYPES.IP_ADDRESS, TEST_TYPES.NETWORK_GRID];
      if (arrTestTypes.includes(test_type) && config.target && config.target.value) {
        if (test_type === TEST_TYPES.AGENT) {
          config.target.value = [parseInt(config.target.value)];
        } else {
          // multiple target IP tests
          config.target.value = `${config.target.value}`.replace(/\s/g, '').split(',').join(', ');
        }
      }

      if (dnsTestTypes.includes(test_type)) {
        if (Array.isArray(config.servers)) {
          config.servers = config.servers.join(', ');
        }
        if (!isEmpty(config?.healthSettings?.dnsValidIps)) {
          // NOTE: Remove extra whitespaces and Re-add 1 space uniformly
          config.healthSettings.dnsValidIps = config.healthSettings.dnsValidIps
            .replace(/\s/g, '')
            .split(',')
            .join(', ');
        }
      }

      if (config.family === '') {
        config.family = 'v4';
      }

      if (hasPing(config.tasks)) {
        config.protocol = config.protocol || 'tcp';

        if (config.protocol === 'icmp') {
          if (dnsTestTypes.includes(test_type) && config.ping.port !== 0) {
            config.ping.port = 0;
          } else if (config.port !== 0) {
            config.port = 0;
          }
        }
      }

      if (config.trace) {
        // in case we have any tests that were created before we fixed >15m trace freq bug
        const period = config.period || config.ping?.period || SYNTH_TRACE_PERIOD_DEFAULT;
        if (config.trace.period < period) {
          config.trace.period = period;
        }
        config.trace.protocol = config.trace.protocol || 'tcp';
        config.trace.port = config.trace.port || 443;
      }

      if (config.bgp?.origin) {
        config.bgp.origin = commaSeparatedStringToArray(config.bgp.origin);
      }

      if (config.bgp?.upstream) {
        config.bgp.upstream = commaSeparatedStringToArray(config.bgp.upstream);
      }

      if (config.healthSettings?.response_headers) {
        const responseHeaders = config.healthSettings.response_headers;
        config.healthSettings.response_headers = this.getArrayOfKeyValuePairs(responseHeaders);
      }

      if (config.healthSettings) {
        // a toggleable metric will have stddev/ms companion fields
        // just get one of the stddev fields as a test for a metric that is toggleable
        const toggleableMetrics = Object.keys(config.healthSettings).filter((key) => key.endsWith('WarningStddev'));

        toggleableMetrics.forEach((stdDevMetric) => {
          // get the base metric name
          const metric = stdDevMetric.replace(/WarningStddev$/, '');
          // get warning/critical field names for ms unit metrics
          const warningMsFieldName = `${metric}Warning`;
          const criticalMsFieldName = `${metric}Critical`;
          // we're using stddev units when the both ms values are 0
          const isStdDev =
            config.healthSettings[warningMsFieldName] === 0 && config.healthSettings[criticalMsFieldName] === 0;

          // set the unit for the toggleable metric
          config.healthSettings[`${metric}MetricUnit`] = isStdDev ? 'stdDev' : 'ms';

          if (isStdDev) {
            if (metric.includes('throughput')) {
              // throughput fields have their own default values
              config.healthSettings[warningMsFieldName] = DEFAULT_MBPS_VALUES.warning;
              config.healthSettings[criticalMsFieldName] = DEFAULT_MBPS_VALUES.critical;
            } else {
              // set the ms values to default values so we don't initialize with invalid values under the covers
              config.healthSettings[warningMsFieldName] = DEFAULT_THRESHOLD_MS_VALUES.warning;
              config.healthSettings[criticalMsFieldName] = DEFAULT_THRESHOLD_MS_VALUES.critical;
            }
          }
        });
      }

      if (config.throughput) {
        // If there is no bandwidth set for throughput, set it to 0
        // This will test maximum throughput. Only allowed for TCP
        if (config.throughput.protocol.toLowerCase() === 'tcp' && !config.throughput.bandwidth) {
          config.throughput.bandwidth = 0;
        }
      }
    }

    return super.deserialize(data);
  }

  togglePlayPause() {
    const { $syn } = this.store;
    const currentStatus = this.get('test_status');
    // only toggle if currently active or paused. Hands off for everything else.
    if (currentStatus === 'A' || currentStatus === 'P') {
      const test_status = currentStatus === 'A' ? 'P' : 'A';
      this.togglingStatus = true;
      return $syn.requests.updateTestStatus(this.id, { id: this.id, test_status }).then(
        () => {
          this.set({ test_status });
          this.togglingStatus = false;
          // do sync that save normally does.
          // rethink
          $syn.agents.fetch({ force: true });
          $syn.plan.syncTestCreditBurnRates();
        },
        () => (this.togglingStatus = false)
      );
    }
    // save returns promise, so do likewise to support caller chaining.
    return Promise.resolve();
  }

  @action
  async fetch(...fetchArgs) {
    return super.fetch(...fetchArgs).then(() => {
      if (this.isPreview) {
        // here we can safely determine the test status and override test results state for preview cases
        this.results = new PreviewTestResultsState({ store: this.store, test: this });
      }
    });
  }

  // TODO: following save and destroy overrides are a "hammer" approach to keeping agent collection in sync with tests, but will do for now.
  // implement scalpel approach (model sync apis, un-nesting tests from agent models, etc) when once things stabilize a bit more (if needed).
  @action
  async save(attributes = {}, options = {}) {
    return super.save(attributes, options).then((results) => {
      // rethink
      // call load agents, but don't wait
      this.store.$syn.agents.fetch({ force: true });
      this.store.$syn.plan.syncTestCreditBurnRates();

      return results;
    });
  }

  @action
  async destroy(options) {
    return super.destroy(options).then((results) => {
      // rethink
      // call load agents, but don't wait
      this.store.$syn.agents.fetch({ force: true });
      this.store.$syn.plan.syncTestCreditBurnRates();

      return results;
    });
  }

  get messages() {
    return {
      create: 'Test was added successfully',
      update: 'Test was updated successfully',
      destroy: 'Test was removed successfully'
    };
  }

  get isPreset() {
    return this.store.$dictionary.get('templateDashboardsCID') === this.get('company_id');
  }

  getSortValue(field) {
    if (field === 'display_name') {
      return this.get('display_name').toLowerCase();
    }

    return super.getSortValue(field);
  }

  // IMPORTANT: if you make changes here, make the same changes in src/node/services/synthetics/creditEnforcement.js
  // Currently returns monthly cost
  calculateTestCredits(pendingValues = {}) {
    const { test_type, config } = { ...this.get(), ...pendingValues };
    const { agents } = config;
    const agentData = [];
    if (agents && agents.length > 0) {
      agents.reduce((acc, agentId) => {
        const agentModel = this.store.$syn.agents.get(agentId);
        if (agentModel) {
          acc.push(agentModel);
        } else {
          console.error('Test contains agent id not found in agent collections. agentId: ', agentId, 'test: ', this.id);
        }
        return acc;
      }, agentData);
    }
    // include bi-directional agent for cost estimates if it exists
    const reciprocalAgent = config.reciprocal ? this.store.$syn.agents.get(config.target.value) : null;
    const testCreditsPerMin = getCreditCostPerMin({ agentData, test_type, config, reciprocalAgent }).total;
    return Math.ceil(testCreditsPerMin * TEST_INTERVAL_MIN);
  }

  @computed
  get mesh() {
    const latestResult = this.get('latestResult');
    const mesh = [];
    if (!!latestResult && latestResult.mesh) {
      const rowKeys = Object.keys(latestResult.mesh);
      rowKeys.sort((a, b) => latestResult.mesh[a].name?.localeCompare(latestResult.mesh[b].name));

      for (let i = 0; i < rowKeys.length; i += 1) {
        const row = latestResult.mesh[rowKeys[i]];
        const columnKeys = Object.keys(row.columns);
        columnKeys.sort((a, b) => row.columns[a].name?.localeCompare(row.columns[b].name));
        const columns = [];

        for (let j = 0; j < columnKeys.length; j += 1) {
          const column = row.columns[columnKeys[j]];
          columns.push({ ...column, health: Object.values(column.health) });
        }

        mesh.push({ ...row, columns });
      }

      return mesh;
    }
    return undefined;
  }

  @computed
  get latestResultTimeMs() {
    const latestResult = this.get('latestResult');

    if (latestResult) {
      return +latestResult.overall_health.time * 1000;
    }

    return undefined;
  }

  @computed
  get isScheduled() {
    const scheduleConfig = this.get('config.schedule');

    return !!scheduleConfig?.enabled;
  }

  @computed
  get isWithinScheduledWindow() {
    if (this.isScheduled) {
      const { start, end } = this.get('config.schedule');

      if (Number.isFinite(start) && Number.isFinite(end)) {
        if (end === 0) {
          // 'never expire' is enabled - just check to make sure we're after the start date
          return moment.unix().isAfter(moment.unix(start));
        }

        // given both a start and end date, check if we're within range
        return moment.unix().isBetween(moment.unix(start), moment.unix(end));
      }
    }

    return false;
  }

  /*
    returns the startDate, endDate, and lookbackSeconds of the last known scheduled test result
    which can then be used to set a new date range for test results

    we only want to return a last known result date range when:
    - 'never expire' is disabled
    - the end date on the schedule is not in the future
    - the end date is less than 1 year old
    - we're not already on the last known date
  */
  @computed
  get lastKnownScheduledResult() {
    if (this.isScheduled) {
      const end = this.get('config.schedule.end');

      // an end date that is 0 means we have 'never expire' enabled
      if (Number.isFinite(end) && end !== 0) {
        const unixEndDate = moment.unix(end);
        const today = moment();
        const isAlreadyOnLastKnownDate = moment.unix(this.results.actualEndDate).isSame(unixEndDate);
        const isSameOrBeforeToday = unixEndDate.isBefore(today);
        const isLessThanAYearOld = today.diff(unixEndDate, 'years') < 1;

        if (!isAlreadyOnLastKnownDate && isSameOrBeforeToday && isLessThanAYearOld) {
          return getRawHealthTimeRange({ endDate: end, maxRawLookback: this.results.defaults.lookbackSeconds });
        }
      }
    }

    return null;
  }

  @action
  async loadNotificationChannels() {
    const alerting = this.get('config.alerting', {});

    if (alerting.rule_id || alerting.ktrac_rule_id) {
      // make sure we load only unique channels as we can have duplicates in a web test with bgp
      const channels = uniq(
        [alerting.rule_id, alerting.ktrac_rule_id].reduce(
          (acc, ruleId) =>
            acc.concat(Object.keys(this.store.$notifications.collection.selectorChannels.alertmanRule?.[ruleId] || {})),
          []
        )
      );

      this.get('config').notifications = { channels };
    }

    return this;
  }
}

export default TestModel;
