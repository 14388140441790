import { observable } from 'mobx';
import { get } from 'lodash';

import api from 'core/util/api';

class ModuleConfigStore {
  configs = observable.map({}, { deep: false });

  promises = {};

  get(moduleName, path, defaultValue) {
    let moduleConfig = this.configs.get(moduleName);

    if (path) {
      moduleConfig = get(moduleConfig, path);
    }

    return moduleConfig || defaultValue;
  }

  async saveModuleConfig({ module, settings }) {
    const { company_id } = this.store.$auth.activeUser;

    if (!this.store.$auth.isAdministrator) {
      console.warn('Member attempting to save a module config', module);
      this.configs.set(module, settings);
      return undefined;
    }

    return api
      .put(`/api/ui/module-config/${company_id}/${module}`, {
        body: {
          settings
        }
      })
      .then(() => {
        this.configs.set(module, settings);
      });
  }

  async fetchModuleConfig(module, options = {}) {
    const { force = false } = options;
    const { company_id } = this.store.$auth.activeUser;

    if (this.configs.has(module) && !force) {
      return this.configs.get(module);
    }

    if (this.promises[module] && !force) {
      return this.promises[module];
    }

    this.promises[module] = api.get(`/api/ui/module-config/${company_id}/${module}`).then((settings) => {
      this.configs.set(module, settings);
      return this.configs.get(module);
    });

    return this.promises[module];
  }
}

export default new ModuleConfigStore();
