import { buildFilterGroup } from '../../../core/util/filters';

function getDefaultObservationDeck(store) {
  const { $cost } = store;
  const defaultOperateDeckConfig = {
    widgets: [
      { id: 'i_542', widgetName: 'trafficOverview' },
      { id: 'i_579', config: { default_tab_id: 'interfaces' }, widgetName: 'trafficBreakdown' },
      { id: 'i_776', widgetName: 'cost' },
      { id: 'i_808', widgetName: 'peering' },
      { id: 'i_817', widgetName: 'capacity' }
    ],
    layout: {
      lg: [
        {
          h: 7,
          i: 'i_542',
          w: 9,
          x: 0,
          y: 0
        },
        {
          h: 6,
          i: 'i_579',
          w: 9,
          x: 0,
          y: 7
        },
        {
          h: 4,
          i: 'i_776',
          w: 3,
          x: 9,
          y: 0
        },
        {
          h: 4,
          i: 'i_808',
          w: 3,
          x: 9,
          y: 4
        },
        {
          h: 4,
          i: 'i_817',
          w: 3,
          x: 9,
          y: 8
        }
      ],
      sm: [
        {
          h: 7,
          i: 'i_542',
          w: 1,
          x: 0,
          y: 0
        },
        {
          h: 6,
          i: 'i_579',
          w: 1,
          x: 0,
          y: 11
        },
        {
          h: 4,
          i: 'i_776',
          w: 1,
          x: 0,
          y: 7
        },
        {
          h: 4,
          i: 'i_808',
          w: 1,
          x: 0,
          y: 7
        },
        {
          h: 4,
          i: 'i_817',
          w: 1,
          x: 0,
          y: 11
        }
      ]
    }
  };

  if (!$cost.canViewCosts) {
    defaultOperateDeckConfig.widgets.splice(2, 1);
    defaultOperateDeckConfig.layout.lg.splice(2, 1);
    defaultOperateDeckConfig.layout.sm.splice(2, 1);
  }

  return defaultOperateDeckConfig;
}

function addFirstThreeSites(state, widgets, layout, store) {
  const { $sites } = store;

  if ($sites.collection.unfilteredSize < 2) {
    return;
  }

  const width = $sites.collection.unfilteredSize < 3 ? 6 : 4;

  // Top 3 sites by alpha :)
  $sites.collection
    .get()
    .slice(0)
    .sort((a, b) => (a.get('title').toLowerCase() < b.get('title').toLowerCase() ? -1 : 1))
    .slice(0, 3)
    .forEach((site) => {
      const widgetId = `i_site${site.id}`;
      widgets.push({ id: widgetId, widgetName: 'siteTrafficProfile', config: { site_id: site.id } });
      layout.lg.push({ i: widgetId, x: state.x, y: state.y, w: width, h: 4 });
      layout.sm.push({ i: widgetId, x: 0, y: state.smallY, w: 1, h: 4 });
      state.smallY += 4;
      state.x += width;
    });
  state.y += 4;
  state.x = 0;
}

function addOtherFlow(state, widgets, layout, store) {
  const { troubleshooting, ddos, peering } = state.why;
  const { $cost, $auth } = store;

  if (peering && $cost.canViewCosts) {
    widgets.push({ id: 'i_cost', widgetName: 'cost' });
    layout.lg.push({ i: 'i_cost', x: state.x, y: state.y, w: 3, h: 4 });
    layout.sm.push({ i: 'i_cost', x: 0, y: state.y, w: 1, h: 4 });
    state.y += state.x >= 9 ? 4 : 0;
    state.x = state.x >= 9 ? 0 : state.x + 3;
  }

  if (ddos) {
    widgets.push({ id: 'i_ddos', widgetName: 'ddos' });
    layout.lg.push({ i: 'i_ddos', x: state.x, y: state.y, w: 3, h: 4 });
    layout.sm.push({ i: 'i_ddos', x: 0, y: state.y, w: 1, h: 4 });
    state.y += state.x >= 9 ? 4 : 0;
    state.x = state.x >= 9 ? 0 : state.x + 3;
  }

  if (peering && troubleshooting) {
    widgets.push({ id: 'i_capacity', widgetName: 'capacity' });
    layout.lg.push({ i: 'i_capacity', x: state.x, y: state.y, w: 3, h: 4 });
    layout.sm.push({ i: 'i_capacity', x: 0, y: state.y, w: 1, h: 4 });
    state.y += state.x >= 9 ? 4 : 0;
    state.x = state.x >= 9 ? 0 : state.x + 3;
  }

  if (peering) {
    widgets.push({ id: 'i_peering', widgetName: 'peering' });
    layout.lg.push({ i: 'i_peering', x: state.x, y: state.y, w: 3, h: 4 });
    layout.sm.push({ i: 'i_peering', x: 0, y: state.y, w: 1, h: 4 });
    state.y += state.x >= 9 ? 4 : 0;
    state.x = state.x >= 9 ? 0 : state.x + 3;

    if ($auth.hasPermission('cdn.enabled')) {
      widgets.push({ id: 'i_cdn', widgetName: 'cdn' });
      layout.lg.push({ i: 'i_cdn', x: state.x, y: state.y, w: 3, h: 4 });
      layout.sm.push({ i: 'i_cdn', x: 0, y: state.y, w: 1, h: 4 });
      state.y += state.x >= 9 ? 4 : 0;
      state.x = state.x >= 9 ? 0 : state.x + 3;
    }

    if ($auth.hasPermission('ott.enabled')) {
      widgets.push({ id: 'i_ott', widgetName: 'ott' });
      layout.lg.push({ i: 'i_ott', x: state.x, y: state.y, w: 3, h: 4 });
      layout.sm.push({ i: 'i_ott', x: 0, y: state.y, w: 1, h: 4 });
      state.y += 4;
    }
  }

  state.x = 0;
}

function addAwsWidgets(state, widgets, layout) {
  // AWS VPC traffic by Transit Gateway
  widgets.push({ id: `i_cloud_${state.idx}`, widgetName: 'vpcByTransitGateway' });
  layout.lg.push({ i: `i_cloud_${state.idx}`, x: 0, y: state.y, h: 6, w: 4 });
  layout.sm.push({ i: `i_cloud_${state.idx}`, x: 0, y: state.smallY, w: 1, h: 4 });
  state.idx += 1;
  state.smallY += 4;

  // AWS Traffic by Gateway Type
  widgets.push({ id: `i_cloud_${state.idx}`, widgetName: 'awsGatewayTraffic' });
  layout.lg.push({ i: `i_cloud_${state.idx}`, x: 4, y: state.y, h: 6, w: 4 });
  layout.sm.push({ i: `i_cloud_${state.idx}`, x: 0, y: state.smallY, w: 1, h: 4 });
  state.idx += 1;
  state.smallY += 4;

  // AWS Reject actions
  widgets.push({
    id: `i_cloud_${state.idx}`,
    widgetName: 'awsRejectActions',
    config: { dst: 'vpc', src: 'vpc', metric: 'avg_pkts_per_sec', trf_profile: 'cloud internal' }
  });
  layout.lg.push({ i: `i_cloud_${state.idx}`, x: 8, y: state.y, h: 6, w: 4 });
  layout.sm.push({ i: `i_cloud_${state.idx}`, x: 0, y: state.smallY, w: 1, h: 4 });
  state.idx += 1;
  state.smallY += 4;

  state.y += 6;
}

function addGcpWidgets(state, widgets, layout) {
  let query = {
    query_title: `${state.isMultiCloud ? 'GCP ' : ''}VM to VM Latency by 98P in ms`,
    aggregateTypes: ['p98th_appl_latency_ms'],
    device_types: ['gcp-subnet', 'gcp_subnet'],
    metric: ['ktsubtype__gcp_subnet__STR02'],
    matrixBy: ['ktsubtype__gcp_subnet__STR03'],
    outsort: 'p98th_appl_latency_ms',
    viz_type: 'matrix',
    show_total_overlay: false
  };
  widgets.push({ id: `i_gcp_${state.idx}`, widgetName: 'dataExplorerQuery', config: { query } });
  layout.lg.push({ i: `i_gcp_${state.idx}`, x: 0, y: state.y, h: 6, w: 4 });
  layout.sm.push({ i: `i_gcp_${state.idx}`, x: 0, y: state.smallY, w: 1, h: 6 });
  state.idx += 1;
  state.smallY += 6;

  query = {
    query_title: `${state.isMultiCloud ? 'GCP ' : ''}Outbound Flow Distribution`,
    device_types: ['gcp-subnet', 'gcp_subnet'],
    metric: [
      'src_proto_port',
      'ktsubtype__gcp_subnet__STR02',
      'ktsubtype__gcp_subnet__STR06',
      'ktsubtype__gcp_subnet__STR08',
      'AS_dst',
      'IP_dst'
    ],
    filters: {
      connector: 'All',
      filterGroups: [
        buildFilterGroup({
          filters: [
            {
              filterField: 'i_trf_profile',
              operator: '=',
              filterValue: 'from cloud to outside'
            }
          ]
        })
      ]
    },
    viz_type: 'sunburst',
    show_total_overlay: false
  };
  widgets.push({ id: `i_gcp_${state.idx}`, widgetName: 'dataExplorerQuery', config: { query } });
  layout.lg.push({ i: `i_gcp_${state.idx}`, x: 4, y: state.y, h: 6, w: 4 });
  layout.sm.push({ i: `i_gcp_${state.idx}`, x: 0, y: state.smallY, w: 1, h: 6 });
  state.idx += 1;
  state.smallY += 6;

  query = {
    query_title: `${state.isMultiCloud ? 'GCP ' : ''}Instance to Instance Traffic View`,
    device_types: ['gcp-subnet', 'gcp_subnet'],
    metric: ['ktsubtype__gcp_subnet__STR02', 'ktsubtype__gcp_subnet__STR03'],
    filters: {
      connector: 'All',
      filterGroups: [
        buildFilterGroup({
          saved_filters: [
            {
              filter_id: 4967,
              is_not: false
            }
          ]
        })
      ]
    },
    viz_type: 'horizon',
    show_total_overlay: false
  };
  widgets.push({ id: `i_gcp_${state.idx}`, widgetName: 'dataExplorerQuery', config: { query } });
  layout.lg.push({ i: `i_gcp_${state.idx}`, x: 8, y: state.y, h: 6, w: 4 });
  layout.sm.push({ i: `i_gcp_${state.idx}`, x: 0, y: state.smallY, w: 1, h: 4 });
  state.idx += 1;
  state.y += 6;
  state.smallY += 6;
}

function addAzureWidgets(state, widgets, layout) {
  let query = {
    query_title: `${state.isMultiCloud ? 'Azure ' : ''}VM to VM  Data Transfer`,
    device_types: ['azure_subnet'],
    matrixBy: ['kt_az_dst_inst_name'],
    metric: ['kt_az_src_inst_name'],
    viz_type: 'matrix',
    show_total_overlay: false
  };
  widgets.push({ id: `i_azure_${state.idx}`, widgetName: 'dataExplorerQuery', config: { query } });
  layout.lg.push({ i: `i_azure_${state.idx}`, x: 0, y: state.y, h: 6, w: 4 });
  layout.sm.push({ i: `i_azure_${state.idx}`, x: 0, y: state.smallY, w: 1, h: 6 });
  state.idx += 1;
  state.smallY += 6;
  state.x += 4;

  query = {
    query_title: `${state.isMultiCloud ? 'Azure ' : ''}Top Flows by Security Group Action`,
    device_types: ['azure_subnet'],
    metric: ['ktsubtype__azure_subnet__STR02'],
    aggregateTypes: [
      'avg_flows_per_sec',
      'p95th_flows_per_sec',
      'max_flows_per_sec',
      'p95th_bits_per_sec',
      'p95th_pkts_per_sec'
    ],
    outsort: 'avg_flows_per_sec',
    filters: {
      connector: 'All',
      filterGroups: [
        buildFilterGroup({
          filters: [
            {
              filterField: 'i_trf_profile',
              operator: '=',
              filterValue: 'from outside to cloud'
            }
          ]
        })
      ]
    }
  };
  widgets.push({ id: `i_azure_${state.idx}`, widgetName: 'dataExplorerQuery', config: { query } });
  layout.lg.push({ i: `i_azure_${state.idx}`, x: state.x, y: state.y, h: 6, w: 4 });
  layout.sm.push({ i: `i_azure_${state.idx}`, x: 0, y: state.smallY, w: 1, h: 6 });
  state.idx += 1;
  state.smallY += 6;
  state.x += 4;

  query = {
    query_title: `${state.isMultiCloud ? 'Azure ' : ''}Dropped Flows by Security Group/Rule`,
    device_types: ['azure_subnet'],
    aggregateType: [
      'avg_flows_per_sec',
      'p95th_flows_per_sec',
      'max_flows_per_sec',
      'p95th_bits_per_sec',
      'p95th_pkts_per_sec'
    ],
    outsort: 'avg_flows_per_sec',
    metric: ['ktsubtype__azure_subnet__STR04', 'ktsubtype__azure_subnet__STR00'],
    filters: {
      connector: 'All',
      filterGroups: [
        buildFilterGroup({
          filters: [
            {
              filterField: 'ktsubtype__azure_subnet__STR02',
              operator: '=',
              filterValue: 'Denied'
            }
          ]
        })
      ]
    },
    viz_type: 'line'
  };
  widgets.push({ id: `i_azure_${state.idx}`, widgetName: 'dataExplorerQuery', config: { query } });
  layout.lg.push({ i: `i_azure_${state.idx}`, x: state.x, y: state.y, h: 6, w: 4 });
  layout.sm.push({ i: `i_azure_${state.idx}`, x: 0, y: state.smallY, w: 1, h: 6 });
  state.idx += 1;
  state.smallY += 6;
  state.y += 6;
  state.x = 0;
}

export default function observationDeckFactory({ store }) {
  const { $clouds, $devices, $setup, $syn } = store;
  const what = $setup.getSettings('what');
  const { synth, flow, aws, gcp, azure } = what || {};
  const hasCloud = what ? aws || gcp || azure : $clouds.hasClouds;
  const hasFlow = what ? flow : $devices.nonCloudDeviceCount;
  const hasSynth = what ? synth : !!$syn.tests.unfilteredSize;

  // Existence of "why" tells us whether the user is legacy
  const why = $setup.getSettings('why');
  if (why) {
    const widgets = [];
    const layout = { lg: [], sm: [] };
    const state = {
      y: 0,
      smallY: 0,
      x: 0,
      idx: 1,
      why,
      isMultiCloud:
        ($devices.hasAWSDevice ? 1 : 0) + ($devices.hasGCEDevice ? 1 : 0) + ($devices.hasAzureDevice ? 1 : 0) > 1
    };

    if ((hasCloud && hasFlow) || (hasCloud && hasSynth) || (hasFlow && hasSynth)) {
      // Multi-Product

      widgets.push({ id: `i_tabbedChart_${state.idx}`, widgetName: 'trafficOverview' });
      layout.lg.push({ i: `i_tabbedChart_${state.idx}`, x: 0, y: 0, h: 7, w: 9 });
      layout.sm.push({ i: `i_tabbedChart_${state.idx}`, x: 0, y: 0, h: 7, w: 1 });
      state.smallY += 7;
      state.idx += 1;

      widgets.push({
        id: `i_insights_${state.idx}`,
        widgetName: 'insightsOverview',
        config: { creationTimeDuration: '168h' }
      });
      layout.lg.push({ i: `i_insights_${state.idx}`, x: 9, y: 0, h: 7, w: 3 });
      layout.sm.push({ i: `i_insights_${state.idx}`, x: 0, y: state.smallY, w: 1, h: 6 });
      state.y += 7;
      state.smallY += 6;
      state.idx += 1;

      if (hasFlow) {
        addFirstThreeSites(state, widgets, layout, store);
      }

      if (hasCloud) {
        if ($devices.hasAWSDevice) {
          addAwsWidgets(state, widgets, layout);
        }

        if ($devices.hasGCEDevice) {
          addGcpWidgets(state, widgets, layout);
        }

        if ($devices.hasAzureDevice) {
          addAzureWidgets(state, widgets, layout);
        }
      }

      if (synth) {
        widgets.push({ id: 'i_agentMesh', widgetName: 'agentMesh' });
        layout.lg.push({ i: 'i_agentMesh', x: state.x, y: state.y, w: 6, h: 4 });
        layout.sm.push({ i: 'i_agentMesh', x: 0, y: state.smallY, w: 1, h: 6 });
        state.x = 6;
        state.smallY += 6;
      }

      if (hasFlow) {
        addOtherFlow(state, widgets, layout, store);
      }
    } else if (hasCloud && !hasFlow && !hasSynth) {
      // Cloud Only
      // Cloud Traffic Overview
      widgets.push({ id: `i_cloud_${state.idx}`, widgetName: 'cloudTrafficOverview' });
      layout.lg.push({ i: `i_cloud_${state.idx}`, x: state.x, y: state.y, h: 7, w: 9 });
      layout.sm.push({ i: `i_cloud_${state.idx}`, x: 0, y: state.smallY, w: 1, h: 7 });
      state.idx += 1;
      state.smallY += 7;

      widgets.push({
        id: `i_insights_${state.idx}`,
        widgetName: 'insightsOverview',
        config: { creationTimeDuration: '168h' }
      });
      layout.lg.push({ i: `i_insights_${state.idx}`, x: 9, y: 0, h: 7, w: 3 });
      layout.sm.push({ i: `i_insights_${state.idx}`, x: 0, y: state.smallY, w: 1, h: 6 });
      state.y += 7;
      state.smallY += 6;
      state.idx += 1;

      if ($devices.hasAWSDevice) {
        addAwsWidgets(state, widgets, layout);
      }

      if ($devices.hasGCEDevice) {
        addGcpWidgets(state, widgets, layout);
      }

      if ($devices.hasAzureDevice) {
        addAzureWidgets(state, widgets, layout);
      }
    } else if (hasFlow && !hasCloud && !hasSynth) {
      widgets.push({ id: `i_tabbedChart_${state.idx}`, widgetName: 'trafficOverview' });
      layout.lg.push({ i: `i_tabbedChart_${state.idx}`, x: 0, y: 0, h: 7, w: 9 });
      layout.sm.push({ i: `i_tabbedChart_${state.idx}`, x: 0, y: 0, h: 7, w: 1 });
      state.smallY += 7;
      state.idx += 1;

      widgets.push({
        id: `i_insights_${state.idx}`,
        widgetName: 'insightsOverview',
        config: { creationTimeDuration: '168h' }
      });
      layout.lg.push({ i: `i_insights_${state.idx}`, x: 9, y: 0, h: 7, w: 3 });
      layout.sm.push({ i: `i_insights_${state.idx}`, x: 0, y: state.smallY, w: 1, h: 6 });
      state.y += 7;
      state.smallY += 6;
      state.idx += 1;

      // Flow Only
      addFirstThreeSites(state, widgets, layout, store);

      addOtherFlow(state, widgets, layout, store);
    } else {
      // Synth Only
      widgets.push({
        id: 'i_incidentLog',
        widgetName: 'incidentLog'
      });
      layout.lg.push({ i: 'i_incidentLog', x: 0, y: state.y, w: 12, h: 11 });
      layout.sm.push({ i: 'i_incidentLog', x: 0, y: state.smallY, w: 4, h: 11 });
    }

    return {
      widgets,
      layout
    };
  }

  return getDefaultObservationDeck(store);
}
