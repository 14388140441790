export default {
  aggregateTypes: ['p95th_bits_per_sec'],
  lookback_seconds: 86400, // day
  all_devices: true,
  show_overlay: false,
  show_total_overlay: false,
  aggregateFiltersEnabled: true,
  aggregateFiltersDimensionLabel: 'AS Number',
  aggregateFilters: [
    {
      metric: ['AS_src', 'AS_dst'],
      name: 'Internal',
      named: true,
      connector: 'All',
      not: false,
      autoAdded: '',
      filters: [],
      filterGroups: [
        {
          name: '',
          named: false,
          connector: 'All',
          not: false,
          autoAdded: '',
          filters: [{ filterField: 'i_src_network_bndry_name', operator: '=', filterValue: 'internal' }],
          filterGroups: [
            {
              name: '',
              named: false,
              connector: 'All',
              not: false,
              autoAdded: '',
              filters: [{ filterField: 'i_src_connect_type_name', operator: '<>', filterValue: 'backbone' }],
              saved_filters: []
            }
          ]
        },
        {
          name: '',
          named: false,
          connector: 'All',
          not: false,
          autoAdded: '',
          filters: [{ filterField: 'i_ult_exit_network_bndry_name', operator: '=', filterValue: 'internal' }],
          filterGroups: [
            {
              name: '',
              named: false,
              connector: 'All',
              not: false,
              autoAdded: '',
              filters: [{ filterField: 'i_ult_exit_connect_type_name', operator: '<>', filterValue: 'backbone' }],
              saved_filters: []
            }
          ]
        }
      ]
    },
    {
      metric: ['AS_dst'],
      name: 'Ingress',
      named: true,
      connector: 'All',
      not: false,
      autoAdded: '',
      filters: [{ filterField: 'i_src_network_bndry_name', operator: '=', filterValue: 'external' }]
    },
    {
      metric: ['AS_src'],
      name: 'Egress',
      named: true,
      connector: 'All',
      not: false,
      autoAdded: '',
      filters: [{ filterField: 'i_dst_network_bndry_name', operator: '=', filterValue: 'external' }]
    }
  ]
};
