export const baseDdosFactorsQuery = {
  fastData: 'Auto',
  show_overlay: false,
  show_total_overlay: true,
  viz_type: 'stackedArea',
  topx: 10,
  depth: 10
};

// DDos Factors - Ingress Interfaces - Top ingress Interfaces / Site / Device (limit 10)
export const ddosFactorsIngressInterfaces = {
  ...baseDdosFactorsQuery,
  aggregateTypes: ['p95th_bits_per_sec', 'p95th_pkts_per_sec'],
  metric: ['InterfaceID_src', 'i_device_site_name', 'i_device_id']
};

// DDos Factors - Source countries by number of unique source IPs
export const ddosFactorsSourceCountries = {
  ...baseDdosFactorsQuery,
  metric: ['Geography_src'],
  aggregateTypes: ['max_src_ip'],
  aggregates: [
    {
      value: 'max_src_ip',
      column: 'f_hll(inet_src_addr,0.0001)',
      fn: 'max',
      label: 'Unique IPs Source IPs Unique Count Max',
      unit: 'unique_src_ip',
      group: 'Unique IPs Source IPs Unique Count',
      origLabel: 'Max',
      sample_rate: 1,
      raw: true,
      name: 'max_src_ip'
    }
  ]
};

// DDos Factors - Traffic Patterns
export const ddosFactorsTrafficPatterns = {
  ...baseDdosFactorsQuery,
  show_total_overlay: false,
  outsort: 'p95th_bits_per_sec',
  metric: ['src_route_prefix_len', 'dst_proto_port', 'tcp_flags'],
  aggregateTypes: ['max_src_ip', 'p95th_bits_per_sec', 'p95th_pkts_per_sec']
};

// DDos Factors - Packet size distribution
export const ddosFactorsPacketSizeDistribution = {
  ...baseDdosFactorsQuery,
  viz_type: 'bar',
  topx: 15,
  show_total_overlay: false,
  aggregateTypes: ['p95th_pkts_per_sec'],
  metric: ['sampledpktsize']
};

// DDos Factors - Source Services
export const ddosFactorsSourceServices = {
  ...baseDdosFactorsQuery,
  topx: 15,
  outsort: 'p95th_bits_per_sec',
  metric: ['src_proto_port'],
  aggregateTypes: ['max_src_ip', 'p95th_bits_per_sec', 'p95th_pkts_per_sec']
};

export const DDOS_QUERIES = {
  INGRESS_INTERFACES: 'ddos.factors.ingress.interfaces',
  TRAFFIC_PATTERNS: 'ddos.factors.traffic.patterns',
  SOURCE_COUNTRIES: 'ddos.factors.source.countries',
  SOURCE_SERVICES: 'ddos.factors.source.services',
  PACKET_SIZE_DISTRIBUTION: 'ddos.factors.packet.size.distribution'
};

const ddosFactorQueries = {
  'ddos.factors.ingress.interfaces': ddosFactorsIngressInterfaces,
  'ddos.factors.traffic.patterns': ddosFactorsTrafficPatterns,
  'ddos.factors.source.countries': ddosFactorsSourceCountries,
  'ddos.factors.source.services': ddosFactorsSourceServices,
  'ddos.factors.packet.size.distribution': ddosFactorsPacketSizeDistribution
};

export default ddosFactorQueries;
