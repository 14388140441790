import React from 'react';
import { action, observable } from 'mobx';
import { orderBy } from 'lodash';
import $dictionary from 'app/stores/$dictionary';
import { api, Socket } from 'core/util';
import { Flex, Text } from 'core/components';

import { commaSeparatedStringToArray } from 'app/util/utils';
import CompanyCollection from './CompanyCollection';

class SudoStore {
  companyCollection = new CompanyCollection();

  companyAdminCollection = new CompanyCollection(undefined, { admin: true });

  @observable
  sessionSpoofed = false;

  @observable
  showSudoEjectButton = false;

  @observable
  sudoOpen = false;

  @observable
  assetMigrationOpen = false;

  selectedCompanyId = null;

  suppressEjectButton = false;

  getCompanyUserOptions(companyId) {
    return api.get(`/api/ui/sudo/companies/${companyId}/users`).then((results) =>
      orderBy(results, ['user_group_id', 'user_level', 'user_name'], ['desc', 'desc', 'asc']).map((user) => {
        const { id, user_level, user_full_name, user_email, user_group_id } = user;
        const role = this.store.$dictionary.get(`userLevels.${user_level}`);

        return {
          value: id,
          label: (
            <Flex flex={1} alignItems="center" height={38}>
              <div>
                {`${user_full_name} (${user_email})`}
                <br />
                <Text opacity={0.75} small>
                  {user_group_id ? '[SUBTENANT] ' : ''}
                  {role}
                </Text>
              </div>
            </Flex>
          ),
          filterLabel: `${user_full_name} (${user_email})`,
          user_group_id,
          role,
          user_level
        };
      })
    );
  }

  @action
  clearSelectedCompanyId = () => {
    this.selectedCompanyId = null;
  };

  @action
  setSudoOpen = (isOpen, selectedCompanyId = null) => {
    this.sudoOpen = isOpen;
    this.selectedCompanyId = selectedCompanyId;
  };

  @action
  launchDemoSession = ({ token } = {}) => {
    const { $auth } = this.store;

    if ($auth.isAbleToDemo) {
      if (window.sessionStorage) {
        window.sessionStorage.clear();
      }

      api.post('/api/ui/demo/start', { data: { token } }).then(() => {
        this.redirectOrReloadWindow('/v4/kentik-map?#showguide');
      });
    }
  };

  @action
  clearDemoSession = (options = {}) =>
    this.clearSpoofSession({ ...options, route: '/api/ui/demo/end', redirect: '/v4/core' });

  @action
  launchSudoSession = (params, options = {}) => {
    this.suppressEjectButton = true;

    if (window.sessionStorage) {
      window.sessionStorage.clear();
    }

    return api.post('/api/ui/sudo/spoof', { ...options, data: params }).then(() => {
      window.localStorage.setItem('spoofData', JSON.stringify(params));
      this.redirectOrReloadWindow(options.redirect);
    });
  };

  isSpoofedAs = ({ companyId, userId }) => {
    const spoofData = JSON.parse(window.localStorage.getItem('spoofData'));
    const { $auth } = this.store;
    return (
      spoofData?.reason &&
      `${$auth.getActiveUserProperty('company_id')}` === `${companyId}` &&
      `${$auth.getActiveUserProperty('id')}` === `${userId}`
    );
  };

  isSpoofReasonEnabled = (target_cid) => {
    const spoofReasonExemptCompanies = $dictionary.get('spoofReasonExemptCompanies');

    if (spoofReasonExemptCompanies === 'all') {
      return false;
    }

    const exemptCids = commaSeparatedStringToArray(spoofReasonExemptCompanies);

    if (exemptCids && exemptCids.length && target_cid) {
      return !exemptCids.includes(target_cid.toString());
    }

    return true; // spoof reason required by default
  };

  getSpoofData = (field) => {
    const spoofData = JSON.parse(window.localStorage.getItem('spoofData'));
    if (field) {
      return spoofData[field];
    }
    return spoofData;
  };

  @action
  clearSpoofSession = (options = {}) => {
    const { reload } = options;
    const { route = '/api/ui/sudo/unspoof' } = options;

    this.suppressEjectButton = true;

    if (window.sessionStorage) {
      window.sessionStorage.clear();
    }

    window.localStorage.removeItem('spoofData');

    return api.get(route).then(() => {
      if (reload) {
        this.redirectOrReloadWindow(options.redirect);
      }
    });
  };

  redirectOrReloadWindow = (redirect) => {
    if (redirect) {
      window.location = redirect;
    } else {
      window.location.reload();
    }
  };

  @action
  showSudoChange = (id) => {
    const { $auth } = this.store;

    this.showSudoEjectButton = id !== $auth.getActiveUserProperty('id');

    if (this.showSudoEjectButton) {
      if (!document.title.startsWith('***')) {
        document.title = `*** ${document.title}`;
      }

      if ($auth.isDemoUser || ($auth.isAbleToDemo && !$auth.isLandlordSpoofed)) {
        $auth.showEnvironmentModal();
      }
    }
  };

  sudoSubscribe() {
    const { $auth } = this.store;

    if (!this.sudoSocket && ($auth.isSpoofed || $auth.hasSudo)) {
      this.sudoSocket = new Socket({
        id: $auth.getUnderlyingUserProperty('id'),
        outType: 'sudo',
        inType: 'sudo',
        frequency: 30,
        delaySend: true,
        onSuccess: action(() => {
          const { id } = $auth.activeUser;

          if (this.suppressEjectButton) {
            return;
          }

          $auth.verifyAuth().then(() => this.showSudoChange(id));
        }),
        onError(err) {
          console.warn('Received Sudo Error (sudo)', err);
        },
        onReconnect: () => {
          this.sudoSocket.send();
        }
      });
      this.sudoSocket.setPayload({});
      this.sudoSocket.send();
    }
  }
}

export default new SudoStore();
