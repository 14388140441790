import { METRICS_DEVICE_FIELD } from 'app/stores/metrics/metricsConstants';
import { POLICY_APPLICATIONS } from 'shared/alerting/constants';

function deserialize(policy) {
  const { deviceSelector, ...restPolicy } = policy;

  if (!deviceSelector) {
    return {
      ...restPolicy,
      selected_devices: { all_devices: true }
    };
  }

  let device_labels = deviceSelector.labels || [];
  device_labels = device_labels.map((id) => parseInt(id));
  let device_sites = deviceSelector.sites || [];
  device_sites = device_sites.map((id) => parseInt(id));

  return {
    ...restPolicy,
    selected_devices: {
      all_devices: deviceSelector.all,
      device_name: deviceSelector.names || [],
      device_labels,
      device_sites,
      device_types: deviceSelector.types || []
    }
  };
}

function serialize(policy) {
  const { selected_devices, ...restPolicy } = policy;

  const deviceSelector = {
    all: selected_devices.all_devices,
    names: selected_devices.device_name || [],
    labels: selected_devices.device_labels || [],
    sites: selected_devices.device_sites || [],
    types: selected_devices.device_types || []
  };

  // If metric policy, specify field for device IDs to be properly located
  deviceSelector.deviceIdField = restPolicy.application === POLICY_APPLICATIONS.METRIC ? METRICS_DEVICE_FIELD : '';

  return {
    ...restPolicy,
    deviceSelector
  };
}

export default {
  serialize,
  deserialize
};
