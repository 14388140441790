import propertiesSerialization from './policyPropertiesSerialization';
import baselineSerialization from './policyBaselineSerialization';
import devicesSerialization from './policyDevicesSerialization';
import filtersSerialization from './policyFiltersSerialization';
import thresholdsSerialization from './policyThresholdsSerialization';
import thresholdsInitSerialization from './policyThresholdsInitSerialization';

const deserializeAll = (policy) => {
  let finalPolicy = policy;

  finalPolicy = propertiesSerialization.deserialize(finalPolicy);
  finalPolicy = baselineSerialization.deserialize(finalPolicy);
  finalPolicy = devicesSerialization.deserialize(finalPolicy);
  finalPolicy = filtersSerialization.deserialize(finalPolicy);
  finalPolicy = thresholdsSerialization.deserialize(finalPolicy);
  finalPolicy = thresholdsInitSerialization.deserialize(finalPolicy);

  return finalPolicy;
};

const serializeAll = (policy) => {
  let finalPolicy = policy;

  finalPolicy = propertiesSerialization.serialize(finalPolicy);
  finalPolicy = baselineSerialization.serialize(finalPolicy);
  finalPolicy = devicesSerialization.serialize(finalPolicy);
  finalPolicy = filtersSerialization.serialize(finalPolicy);
  finalPolicy = thresholdsSerialization.serialize(finalPolicy);
  finalPolicy = thresholdsInitSerialization.serialize(finalPolicy);

  return finalPolicy;
};

export default {
  deserializeBaseline: baselineSerialization.deserialize,
  serializeBaseline: baselineSerialization.serialize,
  deserializeDevices: devicesSerialization.deserialize,
  serializeDevices: devicesSerialization.serialize,
  deserializeFilters: filtersSerialization.deserialize,
  serializeFilters: filtersSerialization.serialize,
  deserializeProperties: propertiesSerialization.deserialize,
  serializeProperties: propertiesSerialization.serialize,
  deserializeThresholds: thresholdsSerialization.deserialize,
  serializeThresholds: thresholdsSerialization.serialize,
  deserializeThresholdsInit: thresholdsInitSerialization.deserialize,
  serializeAll,
  deserializeAll
};
