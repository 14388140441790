import { action, computed, observable } from 'mobx';

import Model from 'core/model/Model';
import api from 'core/util/api';
import { getIPSortValue } from 'core/util/sortUtils';
import $devices from 'app/stores/device/$devices';
import $kentikAgents from 'app/stores/kentikAgent/$kentikAgents';
import $setup from 'app/stores/$setup';
import $labels from 'app/stores/label/$labels';
import $metrics from 'app/stores/metrics/$metrics';
import { ApiBatcher } from 'app/stores/util/apiBatcher';
import { showSuccessToast } from 'core/components';
import MetricDeviceModel from 'app/stores/metrics/MetricDeviceModel';

const deviceTypeAcronyms = {
  router: 'Router',
  host: 'cHst',
  'host-nprobe-basic': 'nHst',
  'host-nprobe-dns-www': 'KPb'
};

const fpsFetch = new ApiBatcher({ url: '/api/ui/devices/batch/fps', batchWindow: 100 });
const flowFetch = new ApiBatcher({ url: '/api/ui/devices/batch/flow', batchWindow: 100 });

class DeviceModel extends Model {
  @observable.ref
  metricsDevice = null;

  @observable.ref
  connections = null;

  @observable
  hasFetched = false;

  @observable
  fps_data;

  @observable
  _fpsLoading = true;

  @observable
  _flowLoading = true;

  @observable
  _hasFlow = false;

  /**
   * when checking whether fps is currently loading, you probably want to ensure that it _is_ loading.
   * note that fpsFetch debounces these requests cleanly so it won't spam the API.
   *
   * prefer using `.hasFlow()` for most simple flow checks
   * @returns {boolean}
   */
  @computed
  get fpsLoading() {
    // we can use existing fps_data or raw_fps if it was delivered alongside the original device data
    // otherwise we fetch from batch FPS API
    const isLoading = !this.fps_data && this.get('raw_fps') == null && this._fpsLoading;
    if (isLoading) {
      this._fetchFps();
    }
    return isLoading;
  }

  /**
   * You shouldn't have to call this directly.
   *
   * @returns {Promise<boolean>}
   * @private
   */
  @action
  async _fetchFlow() {
    const result = await flowFetch.fetch(Number.parseInt(this.id));
    this._flowLoading = false;
    this._hasFlow = !!result;
    return this._hasFlow;
  }

  @action
  async loadDeviceStatus() {
    return api.get(`/api/ui/devices/status/${this.id}`).then((response) => {
      const status = response[this.id];

      if (status) {
        this.set(status);
      }

      return status;
    });
  }

  @computed
  get hasFlow() {
    if (this._flowLoading && this._fpsLoading) {
      // trigger flow fetch if necessary (we can use FPS data here if it's already loaded!)
      this._fetchFlow();
    }
    if (!this._flowLoading) {
      // expected response
      return this._hasFlow;
    }
    if (!this._fpsLoading) {
      // we can make use of fps data if already loaded, but we don't want to trigger a fetch unnecessarily
      return this.rawFps > 0 || null;
    }
    // null value means that it's still loading, you can check for this condition or just treat it as falsy
    return null;
  }

  @computed
  get urlRoot() {
    return '/api/ui/devices';
  }

  get defaults() {
    return {
      device_snmp_v3_conf_enabled: false,
      sending_ips: [''],
      cdn_attr: 'N',
      labels: [],
      bgpPeerIP4: null,
      bgpPeerIP6: null,
      minimize_snmp: false,
      bgp_enabled: true,
      device_bgp_type: 'device',
      device_gnmi_v1_conf: null
    };
  }

  get omitDuringSerialize() {
    return ['plan', 'fps5m'];
  }

  @computed
  get percentClassified() {
    if (!this.get('interface_classification')) {
      return 0;
    }
    const classified = this.get('interface_classification.interfacesClassifiedCount') ?? 0;
    const total = this.get('interface_classification.interfaceCount') ?? 0;
    return total === 0 ? 0 : Math.round((classified / total) * 100);
  }

  /**
   * Fetch FPS data from KDE.
   * @private
   * @returns {Promise<void>}
   */
  @action
  async _fetchFps() {
    const result = await fpsFetch.fetch(Number.parseInt(this.id));
    this._fpsLoading = false;
    if (result) {
      this.fps_data = result;
    } else {
      // zero out flow for device which has no recorded flow data
      this.fps_data = { fps: 0, downsampled: false, downsampled_fps: 0 };
    }
  }

  async savePartial() {
    return this.save(
      { device_status: this.get('device_status') || 'INI' },
      { url: '/api/ui/devices/partial', toast: false }
    );
  }

  saveComplete() {
    const url = this.isNew && this.get('device_status') !== 'INI' ? '/api/ui/devices/' : '/api/ui/devices/complete';
    return this.save({}, { url, toast: false });
  }

  serialize(data) {
    const site_id = data.site?.id ?? data.site_id;
    const plan_id = data.plan?.id ?? data.plan_id;

    if (!data.snmp_collection_method?.includes('kproxy')) {
      data.device_snmp_ip = null;
      data.device_snmp_community = '';
      data.device_snmp_v3_conf_enabled = false;
    }

    data.device_gnmi_v1_conf = data.streaming_telemetry_collection_method?.includes('kproxy')
      ? { ...data.device_gnmi_v1_conf, dialoutServer: 'auto' }
      : null;

    // we want to store ST timeout as Go duration, ie "20s"
    if (data.streaming_telemetry_collection_options?.timeout) {
      data.streaming_telemetry_collection_options.timeout = `${data.streaming_telemetry_collection_options.timeout}s`;
    }

    // we want to store SNMP timeout as Go duration, ie "20s"
    if (data.kentik_agent_snmp_options?.timeout) {
      data.kentik_agent_snmp_options.timeout = `${data.kentik_agent_snmp_options.timeout}s`;
    }

    const device_snmp_v3_conf =
      data.device_snmp_v3_conf_enabled && data.device_snmp_v3_conf.UserName.trim() ? data.device_snmp_v3_conf : null;

    if (data.bgp_enabled === false) {
      data.device_bgp_type = 'none';
    } else if (data.bgp_enabled === true) {
      if (data.device_bgp_type === 'other_device') {
        delete data.device_bgp_neighbor_ip;
        delete data.device_bgp_neighbor_ip6;
      }
      if (data.device_bgp_neighbor_ip || data.device_bgp_neighbor_ip6) {
        data.device_bgp_type = 'device';
      } else if (data.use_bgp_device_id) {
        data.device_bgp_type = 'other_device';
      }
    }

    if (data.device_bgp_type === 'device' || data.device_bgp_type === 'none') {
      data.use_bgp_device_id = null;
    }

    if ($devices.getDeviceTypeFromSubtype(data.device_subtype) !== 'host-nprobe-dns-www') {
      delete data.cdn_attr;
    }

    if (data.device_bgp_type !== 'device') {
      data.device_bgp_neighbor_ip = null;
      data.device_bgp_neighbor_ip6 = null;
      data.device_bgp_neighbor_asn = null;
      data.device_bgp_password = null;
      data.device_bgp_flowspec = false;
      data.device_bgp_label_unicast = false;
      data.bgpPeerIP4 = null;
      data.bgpPeerIP6 = null;
    }

    if (data.device_bgp_type !== 'none') {
      data.use_asn_from_flow = false;
    }

    if (data.interfaceCollection) {
      delete data.interfaceCollection;
    }

    return super.serialize({ ...data, site_id, plan_id, device_snmp_v3_conf });
  }

  deserialize(data) {
    if (data && data[this.jsonRoot]) {
      data = super.deserialize(data);
    }

    if (data) {
      data.bgp_enabled = data.device_bgp_type !== 'none';

      if (data.device_bgp_neighbor_ip && data.device_bgp_neighbor_ip6) {
        data.bgp_ip_type = 'both';
      } else if (data.device_bgp_neighbor_ip6) {
        data.bgp_ip_type = 'ipv6';
      } else {
        data.bgp_ip_type = 'ipv4';
      }
    }

    // remove the "s" from the end of the durations
    if (data.streaming_telemetry_collection_options) {
      const { timeout } = data.streaming_telemetry_collection_options;
      data.streaming_telemetry_collection_options.timeout = timeout?.replace(/s$/, '');
    }

    if (data.kentik_agent_snmp_options) {
      const { timeout } = data.kentik_agent_snmp_options;
      data.kentik_agent_snmp_options.timeout = timeout?.replace(/s$/, '');
    }

    if (data.metricsDevice?.id) {
      this.metricsDevice ??= new MetricDeviceModel();
      this.metricsDevice.set(this.metricsDevice.deserialize(data.metricsDevice));
    }

    return data;
  }

  @action
  async save(attributes = {}, options = {}) {
    const { isNew } = this;

    return super.save(attributes, options).then((result) => {
      $setup.getPlans({ force: true });

      if (this.get('device_status') !== 'INI') {
        $devices.loadDeviceSummaries();
      }

      if (isNew && this.isNMS) {
        $metrics.deviceCollection.fetch({ force: true });
      }

      return result;
    });
  }

  @action
  async unarchive(options = {}) {
    const { toast = true, url = `${this.url}/unarchive` } = options;

    this.requestStatus = 'updating';

    return api.put(url).then(
      () => {
        this.set('device_status', 'V');
        this.requestStatus = null;

        if (toast) {
          showSuccessToast(`Device ${this.get('device_name')} was restored successfully`);
        }

        return true;
      },
      (error) => {
        this.error = { label: 'updating' };
        this.requestStatus = null;
        throw error;
      }
    );
  }

  @action
  async fetch(options = {}) {
    if (!this.hasFetched || options.force) {
      return super.fetch(options).then(() => {
        this.hasFetched = true;
      });
    }
    return Promise.resolve();
  }

  @action
  async destroy(options) {
    const { isActive } = this;

    return super.destroy({ remove: !isActive, ...options }).then((result) =>
      $devices.loadDeviceSummaries().then(() => {
        if (isActive) {
          this.set('device_status', 'D');
        }

        return result;
      })
    );
  }

  get sortValues() {
    return {
      id: () => parseInt(this.get('id')),
      sending_ips: () =>
        this.get('sending_ips') && this.get('sending_ips').length && getIPSortValue(this.get('sending_ips')[0]),
      deviceType: () => this.deviceType.toLowerCase(),
      site_name: () => (this.get('site_name') ? this.get('site_name').toLowerCase() : 'zzzzzz'),
      'stats.RibPrefixes': () => this.get('stats.RibPrefixes') || 0 + this.get('stats.RibPrefixes6') || 0
    };
  }

  get messages() {
    return {
      create: `Device ${this.get('device_name')} was added successfully`,
      update: `Device ${this.get('device_name')} was updated successfully`,
      destroy: `Device ${this.get('device_name')} was removed successfully`
    };
  }

  /**
   * Returns us the number of Interfaces that were Classified / Matched by a particular Rule. Need this at the
   * device level to show breakdowns in the Rule Details Dialog when testing Rules against Devices.
   */
  interfacesMatchedByRule = (rule) => {
    const rules = this.get('rules');

    if (rule && rules) {
      return rules.find((r) => r.id === rule.id);
    }

    return null;
  };

  @computed
  get labels() {
    if (this.has('labels')) {
      return $labels.getLabelsById(this.get('labels').map((label) => label.id));
    }
    return $labels.getLabels('device', this.id);
  }

  // we use this field to allow searching by label
  @computed
  get appliedLabels() {
    return this.labels.length > 0 ? this.labels.map((label) => label.get('name')).join(' ') : 'no labels';
  }

  @computed
  get hasVendor() {
    return !!this.get('device_vendor_type') || !!this.get('vendor');
  }

  @computed
  get vendor() {
    return this.get('device_vendor_type') || this.get('vendor') || this.get('device_model_type') || this.get('model');
  }

  @computed
  get temperature_last() {
    return this.get('temperature')?.last_instant;
  }

  @computed
  get isUnobservable() {
    return this.get('nms_status') === 'unobservable';
  }

  @computed
  get statusDisplay() {
    const status = this.get('nms_status');
    if (!status) {
      return 'Unknown';
    }

    return status.charAt(0).toUpperCase() + status.slice(1);
  }

  @computed
  get statusIntent() {
    const status = this.get('nms_status');

    if (!status) {
      return 'none';
    }

    const statusIntent = {
      down: 'danger',
      up: 'success',
      unknown: 'none',
      unobservable: 'warning',
      possibly_down: 'warning'
    };

    return statusIntent[status];
  }

  @computed
  get statusRaw() {
    const { bgpStatus, downSampledFps } = this;
    const flowStatusRaw = downSampledFps > 0 ? 0 : 1;

    const snmpStatusRaw = this.get('snmp') ? 0 : 1;

    let bgpStatusRaw;
    if (bgpStatus.v4.established || bgpStatus.v6.established) {
      bgpStatusRaw = 0;
    } else if (!bgpStatus.v4.configured && !bgpStatus.v6.configured) {
      bgpStatusRaw = 1;
    } else {
      bgpStatusRaw = 2;
    }

    return flowStatusRaw + snmpStatusRaw + bgpStatusRaw;
  }

  /**
   * Note that this can be somewhat expensive on KDE. The advantage is that it gives a result which is extremely recent (unless local caching gets in the way)
   * @returns {number|null}
   */
  @computed
  get rawFps() {
    if (!this.fps_data && this.get('raw_fps') == null) {
      this._fetchFps();
      return null;
    }

    let fps = 0;
    if (this.get('raw_fps') != null) {
      // legacy method - just grab the raw_fps from the server
      fps = parseFloat(this.get('raw_fps'));
    } else if (this.fps_data) {
      if (parseInt(this.fps_data.avg_device_sample_rate) === 0 && this.get('metadata')) {
        fps = Math.ceil((this.fps_data.avg_sample_rate / this.get('metadata').device_sample_rate) * this.fps_data.fps);
      } else {
        fps = Math.ceil((this.fps_data.avg_sample_rate / this.fps_data.avg_device_sample_rate) * this.fps_data.fps);
      }
    }

    return Number.isNaN(fps) ? 0 : fps;
  }

  /**
   * Note that this can be somewhat expensive on KDE. The advantage is that it gives a result which is extremely recent (unless local caching gets in the way)
   * @returns {number|null}
   */
  @computed
  get downSampledFps() {
    if (!this.fps_data && this.get('downsampled_fps') == null) {
      this._fetchFps();
      return null;
    }

    let fps = 0;
    if (this.get('downsampled_fps') != null) {
      fps = parseFloat(this.get('downsampled_fps'));
    } else if (this.fps_data) {
      fps = Math.ceil(this.fps_data.fps);
    }

    return Number.isNaN(fps) ? 0 : fps;
  }

  @computed
  get flowType() {
    return this.get('stats.FlowType');
  }

  @computed
  get isActive() {
    return this.get('device_status') === 'V';
  }

  @computed
  get isArchived() {
    return this.get('device_status') === 'D';
  }

  @computed
  get isIncomplete() {
    return this.get('device_status') === 'INI';
  }

  @computed
  get flowSource() {
    const isAgent = parseInt(this.get('stats.IsAgent'));

    if (!this.rawFps) {
      return 'none';
    }
    // 0, NaN, null etc
    if (!isAgent) {
      return 'client';
    }
    if (isAgent === 1) {
      return 'agent';
    }
    return 'none';
  }

  @computed
  get bgpConfigured() {
    const { v4, v6 } = this.bgpStatus;

    return v4.configured || v6.configured;
  }

  @computed
  get hasLabels() {
    return this.get('labels') && this.get('labels').length > 0;
  }

  @computed
  get deviceType() {
    return this.get('device_subtype') || deviceTypeAcronyms[this.get('device_type')];
  }

  @computed
  get sendingIpsDisplay() {
    return this.get('sending_ips') && this.get('sending_ips').join(', ');
  }

  getDefaultSampleRateForDeviceType = (device_type) => (device_type === 'router' ? 1024 : 32);

  get snmpVersion() {
    if (!this.get('snmp_enabled')) {
      return false;
    }

    if (this.get('device_snmp_v3_conf_enabled') && this.get('device_snmp_v3_conf.UserName')) {
      return 'V3';
    }

    return 'V2';
  }

  @computed
  get snmpStatus() {
    return this.get('snmp_enabled') && this.get('snmp_healthy');
  }

  @computed
  get stStatus() {
    return this.stEnabled && this.get('st_healthy');
  }

  @computed
  get flowConfigured() {
    return !!this.get('sending_ips').length && !!this.get('device_sample_rate');
  }

  @computed
  get nmsConfigured() {
    return this.isNMS || this.isICMPOnly;
  }

  @computed
  get snmpEnrichmentStatus() {
    if (this.isICMPOnly) {
      return 'unknown';
    }
    return this.snmpStatus;
  }

  @computed
  get stEnrichmentStatus() {
    if (!this.stEnabled || this.isICMPOnly) {
      return 'unknown';
    }
    return this.get('st_healthy') ? 'up' : 'down';
  }

  @computed
  get snmpNmsStatus() {
    if (!this.isNMS || this.isICMPOnly) {
      return 'unknown';
    }
    return this.get('nms_status');
  }

  @computed
  get stNmsStatus() {
    if (!this.isNMS || this.isICMPOnly) {
      return 'unknown';
    }
    return this.hasStreamingTelemetry ? 'up' : 'down';
  }

  @computed
  get icmpStatus() {
    if (!this.isICMPOnly) {
      return 'unknown';
    }
    return this.get('nms_status');
  }

  @computed
  get bgpStatus() {
    const status = {
      v4: { established: false, configured: false },
      v6: { established: false, configured: false }
    };

    if (this.get('device_bgp_type') === 'device') {
      status.v4 = {
        established: parseInt(this.get('stats.RibSessionState')) === 1,
        configured: !!this.get('metadata.device_bgp_neighbor_ip')
      };
      status.v6 = {
        established: parseInt(this.get('stats.RibSessionState6')) === 1,
        configured: !!this.get('metadata.device_bgp_neighbor_ip6')
      };
    } else if (this.get('device_bgp_type') === 'other_device') {
      status.v4 = { established: true, configured: true };
      status.v6 = { established: true, configured: true };
    }

    return status;
  }

  @computed
  get bgpCombinedStatus() {
    const { v4, v6 } = this.bgpStatus;
    let status = 'not configured';

    if (v4.configured || v6.configured) {
      if (v4.established || v6.established) {
        status = 'established';
      } else {
        status = 'not established';
      }
    }

    return status;
  }

  @computed
  get bgpUpdates() {
    return {
      v4: parseInt(this.get('stats.RibUpdates')) || 0,
      v6: parseInt(this.get('stats.RibUpdates6')) || 0
    };
  }

  @computed
  get stEnabled() {
    const st = this.get('device_gnmi_v1_conf') || false;
    return st && st.dialoutServer === 'auto';
  }

  deviceFilter(filterField = 'km_device_id') {
    return {
      connector: 'All',
      filterGroups: [
        {
          connector: 'All',
          not: false,
          filters: [
            {
              filterField,
              operator: '=',
              filterValue: `${this.id}`
            }
          ]
        }
      ]
    };
  }

  @computed
  get isNMS() {
    return this.get('snmp_collection_method')?.includes('kentik_agent');
  }

  @computed
  get isICMPOnly() {
    return !!this.get('ranger_icmp_ip');
  }

  @computed
  get hasStreamingTelemetry() {
    return this.get('streaming_telemetry_collection_method')?.includes('kentik_agent');
  }

  /**
   * Names of the credentials in use by the device
   */
  @computed
  get credentials() {
    const credentials = [];

    if (this.get('device_status') === 'V') {
      if (this.isNMS) {
        const name = this.get('kentik_agent_snmp_options.credential_name') || this.get('agent_snmp_credential_name');
        if (name) {
          credentials.push(name);
        }
      }

      if (this.hasStreamingTelemetry) {
        const name =
          this.get('streaming_telemetry_collection_options.credential_name') || this.get('agent_st_credential_name');
        if (name) {
          credentials.push(name);
        }
      }
    }

    return credentials;
  }

  @computed
  get agentName() {
    const agentId = this.get('device_kentik_agent_id');

    // check loading to force a recompute after it finishes
    if (agentId && !$kentikAgents.collection.loading) {
      return $kentikAgents.collection.get(agentId)?.name ?? agentId;
    }

    return null;
  }

  @computed
  get cpuUtilizationQuery() {
    return {
      use_kmetrics: true,
      show_overlay: false,
      show_total_overlay: false,
      kmetrics: {
        measurement: '/system/cpus',
        dimensions: ['device_name'],
        metrics: [
          {
            name: 'total/instant',
            type: 'gauge'
          }
        ],
        range: {
          lookback: 'PT86400S'
        },
        merge: {
          dimensions: ['cpu_index'],
          aggregate: 'avg'
        },
        window: {
          size: 0,
          fn: {
            'total/instant': 'avg'
          }
        },
        rollups: {
          'avg_total/instant': {
            metric: 'total/instant',
            aggregate: 'avg'
          },
          'max_total/instant': {
            metric: 'total/instant',
            aggregate: 'max'
          },
          'last_total/instant': {
            metric: 'total/instant',
            aggregate: 'last'
          }
        },
        sort: [
          {
            name: 'avg_total/instant',
            direction: 'desc'
          },
          {
            name: 'max_total/instant',
            direction: 'desc'
          },
          {
            name: 'last_total/instant',
            direction: 'desc'
          }
        ],
        limit: 10,
        includeTimeseries: 10,
        viz: {
          type: 'area',
          rollup: '',
          metric: 'total/instant'
        },
        filters: this.deviceFilter()
      }
    };
  }

  @computed
  get memoryUtilizationQuery() {
    return {
      use_kmetrics: true,
      show_overlay: false,
      show_total_overlay: false,
      kmetrics: {
        measurement: '/system/memory',
        dimensions: ['device_name'],
        metrics: [
          {
            name: 'utilization',
            type: 'gauge'
          }
        ],
        merge: {
          aggregate: 'avg',
          dimensions: ['index']
        },
        range: {
          lookback: 'PT86400S'
        },
        window: {
          size: 0,
          fn: {
            utilization: 'avg'
          }
        },
        rollups: {
          avg_utilization: {
            metric: 'utilization',
            aggregate: 'avg'
          },
          max_utilization: {
            metric: 'utilization',
            aggregate: 'max'
          },
          last_utilization: {
            metric: 'utilization',
            aggregate: 'last'
          }
        },
        sort: [
          {
            name: 'avg_utilization',
            direction: 'desc'
          },
          {
            name: 'max_utilization',
            direction: 'desc'
          },
          {
            name: 'last_utilization',
            direction: 'desc'
          }
        ],
        limit: 5,
        includeTimeseries: 5,
        viz: {
          type: 'area',
          rollup: '',
          metric: 'utilization'
        },
        filters: this.deviceFilter()
      }
    };
  }

  @computed
  get temperatureQuery() {
    return {
      use_kmetrics: true,
      show_overlay: false,
      show_total_overlay: false,
      kmetrics: {
        measurement: '/components/temperature',
        dimensions: ['device_name'],
        metrics: [
          {
            name: 'instant',
            type: 'gauge'
          }
        ],
        range: {
          lookback: 'PT86400S'
        },
        merge: {
          dimensions: ['index'],
          aggregate: 'max'
        },
        window: {
          size: 0,
          fn: {
            instant: 'avg'
          }
        },
        rollups: {
          avg_instant: {
            metric: 'instant',
            aggregate: 'avg'
          },
          max_instant: {
            metric: 'instant',
            aggregate: 'max'
          },
          last_instant: {
            metric: 'instant',
            aggregate: 'last'
          }
        },
        sort: [
          {
            name: 'avg_instant',
            direction: 'desc'
          },
          {
            name: 'max_instant',
            direction: 'desc'
          },
          {
            name: 'last_instant',
            direction: 'desc'
          }
        ],
        limit: 5,
        includeTimeseries: 5,
        viz: {
          type: 'area',
          rollup: '',
          metric: 'instant'
        },
        filters: this.deviceFilter()
      }
    };
  }

  @computed
  get trafficQuery() {
    return {
      all_devices: false,
      device_name: [this.get('device_name')],
      lookback_seconds: 86400,
      aggregateTypes: ['avg_bits_per_sec'],
      show_overlay: false,
      show_total_overlay: false
    };
  }
}

export default DeviceModel;
