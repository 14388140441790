import { computed } from 'mobx';
import { orderBy } from 'lodash';
import SavedFilterCollection from 'app/stores/savedFilter/SavedFilterCollection';

export class SavedFilterStore {
  collection = new SavedFilterCollection();

  constructor(options = {}) {
    Object.assign(this, options);
  }

  initialize() {
    this.collection.fetch();
  }

  @computed
  get selectOptions() {
    return orderBy(
      this.collection.unfiltered.map((model) => ({
        value: model.get('id'),
        label: model.get('filter_name') || 'Unnamed',
        id: model.get('id'),
        filters: model.get('filters'),
        numFilters: model.numFilters
      })),
      [(option) => option.label.toLowerCase(), 'asc']
    );
  }

  getSavedFilterById(filter_id) {
    // eslint-disable-next-line eqeqeq
    return this.collection.get().find((f) => f.id == filter_id);
  }
}

export default new SavedFilterStore();
