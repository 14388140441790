import Model from 'core/model/Model';
import $devices from 'app/stores/device/$devices';
import { computed } from 'mobx';

class KentikAgentModel extends Model {
  get urlRoot() {
    return '/api/ui/kentik-agent/agents';
  }

  @computed
  get isRangerEnabled() {
    return !!this.get('capabilities')?.find((capability) => capability.name === 'ranger' && capability.running);
  }

  @computed
  get closestDevice() {
    const closest_device_id = this.get('closest_device_id');
    return $devices.deviceSummaries.find((device) => parseInt(device.id) === closest_device_id);
  }

  @computed
  get groupRank() {
    if (this.get('configured') === undefined) {
      return 1;
    }

    switch (this.get('configured')) {
      case false:
        return -1;
      case true:
        return 0;
      default:
        return 1;
    }
  }

  @computed
  get name() {
    return this.get('display_name') || this.get('host_name');
  }

  deserialize(data) {
    const deserialized = { ...data, id: data.agent_id };
    if (data.site_id) {
      deserialized.site = { id: data.site_id };
    }

    return deserialized;
  }
}

export default KentikAgentModel;
