import { greekIt } from 'app/util/utils';
import Model from 'core/model/Model';
import { greekPrefix } from 'core/util/greekPrefixing';
import { computed } from 'mobx';

import {
  METRICS_INTERFACE_ADMIN_STATUS_MAP,
  METRICS_INTERFACE_COMMON_CAPACITIES,
  METRICS_INTERFACE_OPER_STATUS_MAP,
  METRICS_INTERFACE_TYPES
} from './metricsConstants';

class MetricInterfaceModel extends Model {
  get urlRoot() {
    return '/api/ui/recon/interfaces';
  }

  get defaults() {
    return {
      id: '',
      company_id: '',
      parent_id: '',
      index: '',
      name: '',
      description: '',
      type: '',
      mtu: '',
      speed: '',
      physical_address: '',
      logical: false,

      IfOutErrors: 0,
      IfOutDiscards: 0,
      IfInErrors: 0,
      IfInDiscards: 0
    };
  }

  get title() {
    return this.get('name');
  }

  get subType() {
    return 'Interface';
  }

  get sortValues() {
    return {
      bitRate: () => {
        const { inbound, outbound } = this.bitRate;
        return Math.max(inbound ?? -1, outbound ?? -1);
      },
      utilization: () => {
        const { inbound, outbound } = this.utilization;
        return Math.max(inbound ?? -1, outbound ?? -1);
      },
      device: () => (this.get('device.name') || '').toLowerCase()
    };
  }

  // used to render the type column on the interfaces table
  @computed
  get displayType() {
    return METRICS_INTERFACE_TYPES[this.get('type')] || this.get('type');
  }

  @computed
  get displayCapacityGreek() {
    const { displayFull } = greekIt(this.get('speed') * 1e6, { fix: 0, suffix: '' });
    return displayFull;
  }

  @computed
  get displayCapacity() {
    const capacity = this.get('speed');
    if (METRICS_INTERFACE_COMMON_CAPACITIES.includes(capacity)) {
      return this.displayCapacityGreek;
    }

    if (capacity === '0') {
      return 'Unspecified';
    }

    return 'Other';
  }

  @computed
  get adminStatus() {
    const status = this.get('if_AdminStatus');
    return METRICS_INTERFACE_ADMIN_STATUS_MAP[status] || status;
  }

  @computed
  get bitRate() {
    const inbound = this.get('IfInBitRate');
    const outbound = this.get('IfOutBitRate');
    return { inbound, outbound };
  }

  @computed
  get bitRatePrefix() {
    const inValue = this.get('IfInBitRate') || 0;
    const outValue = this.get('IfOutBitRate') || 0;
    return greekPrefix([inValue, outValue], 1);
  }

  @computed
  get inBitRateDisplay() {
    const value = this.get('IfInBitRate');
    if (value === null || value === undefined) {
      return '--';
    }
    const { displayFull } = greekIt(value, { forcePrefix: this.bitRatePrefix, suffix: '', fix: 2 });
    return displayFull;
  }

  @computed
  get outBitRateDisplay() {
    const value = this.get('IfOutBitRate');
    if (value === null || value === undefined) {
      return '--';
    }
    const { displayFull } = greekIt(value, { forcePrefix: this.bitRatePrefix, suffix: '', fix: 2 });
    return displayFull;
  }

  @computed
  get discardsTotal() {
    return this.get('IfInDiscards') + this.get('IfOutDiscards');
  }

  @computed
  get errorsTotal() {
    return this.get('IfInErrors') + this.get('IfOutErrors');
  }

  @computed
  get operStatus() {
    const status = this.get('if_OperStatus');
    return METRICS_INTERFACE_OPER_STATUS_MAP[status] || status;
  }

  // used to render the utilization column on the interfaces table
  @computed
  get utilization() {
    const inbound = this.get('IfInUtilization');
    const outbound = this.get('IfOutUtilization');
    return {
      inbound,
      outbound
    };
  }

  @computed
  get deviceId() {
    return this.get('device').id;
  }

  @computed
  get filters() {
    return {
      connector: 'All',
      filterGroups: [
        {
          name: '',
          named: false,
          connector: 'All',
          not: false,
          autoAdded: '',
          filters: [
            {
              filterField: 'km_device_id',
              operator: '=',
              filterValue: `${this.deviceId}`
            },
            {
              filterField: 'ifindex',
              operator: '=',
              filterValue: this.get('index')
            }
          ]
        }
      ]
    };
  }

  @computed
  get queryCards() {
    return [
      {
        title: 'Inbound',
        query: this.inboundMetricsQuery
      },
      {
        title: 'Outbound',
        query: this.outboundMetricsQuery
      }
    ];
  }

  getUtilizationQuery(direction = 'in') {
    const utilization = `${direction}-utilization`;

    return {
      use_kmetrics: true,
      show_overlay: false,
      show_total_overlay: false,
      kmetrics: {
        measurement: '/interfaces/counters',
        dimensions: ['ifindex', 'name'],
        metrics: [
          {
            name: utilization,
            type: 'gauge'
          }
        ],
        range: {
          lookback: 'PT86400S'
        },
        window: {
          size: 0,
          fn: {
            [`${utilization}`]: 'avg'
          }
        },
        rollups: {
          [`p95_${utilization}`]: { metric: utilization, aggregate: 'p95' },
          [`max_${utilization}`]: { metric: utilization, aggregate: 'max' },
          [`last_${utilization}`]: { metric: utilization, aggregate: 'last' }
        },
        limit: 1,
        includeTimeseries: 1,
        viz: { type: 'area', metric: utilization },
        filters: this.filters
      }
    };
  }

  getErrorsQuery(direction = 'in') {
    const errors = `${direction}-errors`;

    return {
      use_kmetrics: true,
      show_overlay: false,
      show_total_overlay: false,
      kmetrics: {
        measurement: '/interfaces/counters',
        dimensions: ['ifindex', 'name'],
        metrics: [
          {
            name: errors,
            type: 'gauge'
          }
        ],
        range: {
          lookback: 'PT86400S'
        },
        window: {
          size: 3600,
          fn: {
            [`${errors}`]: 'sum'
          }
        },
        rollups: {
          [`p95_${errors}`]: { metric: errors, aggregate: 'p95' },
          [`max_${errors}`]: { metric: errors, aggregate: 'max' },
          [`last_${errors}`]: { metric: errors, aggregate: 'last' }
        },
        limit: 1,
        includeTimeseries: 1,
        viz: { type: 'area', metric: errors },
        filters: this.filters
      }
    };
  }

  @computed
  get inUtilizationQuery() {
    return this.getUtilizationQuery('in');
  }

  @computed
  get outUtilizationQuery() {
    return this.getUtilizationQuery('out');
  }

  @computed
  get inErrorsQuery() {
    return this.getErrorsQuery('in');
  }

  @computed
  get outErrorsQuery() {
    return this.getErrorsQuery('out');
  }
}

export default MetricInterfaceModel;
