import { observable, action, computed } from 'mobx';
import $auth from 'app/stores/$auth';

class Tour {
  @observable
  activeStepId;

  @observable
  priorStepId;

  @observable
  running = false;

  @observable
  loading = false;

  forcePriorOnPrev = false;

  isComplete = false;

  @observable
  steps = [];

  @observable.ref
  form;

  options = {};

  constructor(name, steps, options) {
    this.name = name;
    this.steps = steps;

    if (options) {
      Object.assign(this.options, options);
    }
  }

  @computed
  get activeStepIndex() {
    return this.steps.findIndex((step) => step.id === this.activeStepId);
  }

  @computed
  get activeStep() {
    return this.steps.find((step) => step.id === this.activeStepId);
  }

  @computed
  get priorStepIndex() {
    return this.steps.findIndex((step) => step.id === this.priorStepId);
  }

  @computed
  get priorStep() {
    return this.steps.find((step) => step.id === this.priorStepId);
  }

  @computed
  get isLastStep() {
    return this.activeStepId === this.steps[this.steps.length - 1].id;
  }

  get model() {
    return this.options && this.options.model;
  }

  complete() {
    this.running = false;
    this.isComplete = true;
    this.setActiveStep(undefined);

    if (this.options.onComplete) {
      this.options.onComplete(this.name);
    }

    $auth.track(`tour-${this.name}-completed`);
  }

  @action
  setActiveStep(activeStepId, options = {}) {
    if (this.options.onChange) {
      this.options.onChange(activeStepId, this.activeStepId);
    } else {
      this.forcePriorOnPrev = options.forcePriorOnPrev || false;
      this.priorStepId = this.activeStepId;
      this.activeStepId = activeStepId;
    }

    if (activeStepId) {
      $auth.track(`tour-${this.name}-${activeStepId}`);
    }
  }

  @action
  start(stepId) {
    this.running = true;

    this.setActiveStep(stepId || this.steps[0].id);
  }

  @action
  stop() {
    this.running = false;
    this.setActiveStep(undefined);

    $auth.track(`tour-${this.name}-stopped`);
  }

  @action
  close = () => {
    this.complete();
  };

  @action
  showStep = (stepId) => {
    if (!this.running) {
      this.start();
    }

    this.setActiveStep(stepId, { forcePriorOnPrev: true });
  };

  @action
  setModel = (model) => {
    this.options.model = model;
  };

  @action
  setSteps = (steps) => {
    this.steps = steps;
  };

  @action
  finish = () => {
    this.complete();
  };

  @action
  next = () => {
    const next = this.steps[this.activeStepIndex + 1];
    if (next) {
      this.setActiveStep(next.id);
    }
  };

  @action
  prev = () => {
    const prev = this.forcePriorOnPrev ? this.priorStep : this.steps[this.activeStepIndex - 1];
    if (prev) {
      this.setActiveStep(prev.id);
    }
  };

  @action
  setLoading(loading) {
    this.loading = loading;
  }

  setOptions(options) {
    if (this.options) {
      Object.assign(this.options, options);
    } else {
      this.options = options;
    }
  }
}

export default Tour;
