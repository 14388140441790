import { computed } from 'mobx';
import Model from 'core/model/Model';

export default class Tag extends Model {
  get urlRoot() {
    return '/api/ui/flow-tags';
  }

  @computed
  get created() {
    return this.get('created_date');
  }

  @computed
  get edited() {
    return this.get('updated_date');
  }

  get omitDuringSerialize() {
    return ['mac_count', 'addr_count', 'edited_by', 'created_date', 'updated_date'];
  }

  get messages() {
    return {
      create: `Tag ${this.get('value')} was added successfully`,
      update: `Tag ${this.get('value')} was updated successfully`,
      destroy: `Tag ${this.get('value')} was removed successfully`
    };
  }

  get removalConfirmText() {
    return {
      title: 'Remove Tag',
      text: `Are you sure you want to remove ${this.get('value')}?`
    };
  }

  /**
   * For field count is greater than actual length of field array, field was too large and has been truncated.
   * Legacy populators don't have count fields and actual fields are not arrays, so values should compare equal
   * at MAX_SAFE_INTEGER and just return false.
   * @returns {boolean}
   */
  @computed
  get truncatedFields() {
    const macCount = parseInt(this.get('mac_count'));
    const addrCount = parseInt(this.get('addr_count'));
    const macList = this.get('mac');
    const addrList = this.get('addr');

    // only evaluate if fields are correct (hippo tags), otherwise bail with false.
    if (!Number.isNaN(macCount) && !Number.isNaN(addrCount) && Array.isArray(macList) && Array.isArray(addrList)) {
      return macCount > macList.length || addrCount > addrList.length;
    }
    return false;
  }

  serialize(data) {
    return { tag: data };
  }
}
