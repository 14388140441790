import { action, computed, observable } from 'mobx';
import Collection from 'core/model/Collection';
import { api } from 'core/util/index';
import CustomDimensionModel from './CustomDimensionModel';
import AsGroupModel, { AS_GROUP_DIMENSIONS } from './AsGroupModel';

class AsGroupCollection extends Collection {
  @observable
  dimensions = {};

  get url() {
    return '';
  }

  get model() {
    return AsGroupModel;
  }

  @computed
  get populated() {
    return this.get().length > 0;
  }

  @action
  fetch = (options) => {
    let needRefresh = false;
    this.setRequestStatus('fetching');
    this.hasFetched = true;
    return Promise.all(
      AS_GROUP_DIMENSIONS.map(({ name, displayName }) =>
        CustomDimensionModel.fetchByName(name)
          .then((dimension) => {
            if (!dimension) {
              const newDimension = new CustomDimensionModel();
              needRefresh = true;
              return newDimension
                .create(
                  { display_name: displayName, name },
                  { toast: false, url: `${newDimension.urlRoot}?skipSync=1` }
                )
                .then(() => newDimension);
            }

            return dimension;
          })
          .then((dimension) => {
            this.dimensions[name] = dimension;
            dimension.populators.setSortState('id');
            return dimension.populators.fetch(options).then(() => dimension.populators);
          })
      )
    ).then(
      action(async ([srcPopulators, dstPopulators]) => {
        const groupData = srcPopulators.map((src) => {
          const name = src.get('value');
          const dst = dstPopulators.find({ value: name });
          return AsGroupModel.getData(src, dst);
        });

        this.set(groupData);
        this.setRequestStatus(null);

        if (needRefresh) {
          await api.get('/api/ui/customdimensions/rebuildDevices');
        }
      })
    );
  };
}

export default AsGroupCollection;
