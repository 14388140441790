import React from 'react';
import { computed } from 'mobx';

import Collection from 'core/model/Collection';
import { Flex, Text } from 'core/components';
import CompanyPlanTag from 'app/components/CompanyPlanTag';

import CompanyModel from './CompanyModel';

class CompanyCollection extends Collection {
  isAdmin = false;

  constructor(data, options) {
    super(data, options);

    const { admin } = options || {};
    if (admin) {
      this.isAdmin = true;
    }
  }

  get urlPaths() {
    if (this.isAdmin) {
      return {
        fetch: '/api/ui/sudo/companies/full',
        update: '/api/ui/sudo/companies'
      };
    }
    return undefined;
  }

  get defaultSortState() {
    return {
      field: 'company_name',
      direction: 'asc'
    };
  }

  get presetFilters() {
    return [
      {
        label: 'Active Trials',
        fn: (model) => model.isActiveTrial
      },
      {
        label: 'Expired Trials',
        fn: (model) => model.isExpiredTrial
      },
      {
        label: 'Suspended',
        fn: (model) => model.isSuspendedCompany
      },
      {
        label: 'Free Plans',
        fn: (model) => model.isFreePlan
      },
      {
        label: 'Customers',
        fn: (model) => model.isActiveCustomer
      },
      {
        label: 'Kentik Companies',
        fn: (model) => model.isKentik,
        iconName: 'eye-open'
      }
    ];
  }

  get url() {
    return '/api/ui/sudo/companies';
  }

  get model() {
    return CompanyModel;
  }

  @computed
  get selectOptions() {
    return this.get().map((model) => ({
      value: model.companyId,
      label: (
        <Flex flex={1} alignItems="center" justifyContent="space-between" height={38} gap={2}>
          <div>
            {model.get('company_name')}
            <br />
            <Text muted small>
              Company ID: {model.companyId}
            </Text>
          </div>
          <div>
            <CompanyPlanTag value={model.companyPlan} />
          </div>
        </Flex>
      ),
      plan: model.companyPlan,
      filterLabel: model.get('company_name')
    }));
  }
}

export default CompanyCollection;
