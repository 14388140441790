import Collection from 'core/model/Collection';
import { computed } from 'mobx';
import flatten from 'lodash/flatten';
import $notifications from 'app/stores/notifications/$notifications';
import TenantModel from './TenantModel';

class TenantCollection extends Collection {
  get defaultSortState() {
    return {
      field: 'name',
      direction: 'asc'
    };
  }

  get url() {
    return '/api/ui/mkp-settings/tenant';
  }

  get model() {
    return TenantModel;
  }

  // Get list of tenants
  @computed
  get tenantAlertingOptions() {
    return this.models
      .map((tenant) => ({
        label: tenant.get('name'),
        value: `${tenant.get('id')}`
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  // Get map of subpolicies with their associated tenants
  @computed
  get subpolicyIdToTenant() {
    return this.models.reduce((tenantMap, tenant) => {
      const alerts = tenant.get('config.alerts') || [];
      alerts.forEach(({ subpolicy_id }) => {
        if (subpolicy_id) {
          tenantMap[subpolicy_id] = tenant;
        }
      });
      return tenantMap;
    }, {});
  }

  // Get map of policies with array of associated subpolicies & tenants
  @computed
  get policiesWithSubpolicies() {
    return this.models.reduce((policyMap, tenant) => {
      const alerts = tenant.get('config.alerts') || [];
      alerts.forEach(({ policy_id, subpolicy_id }) => {
        if (policy_id && subpolicy_id) {
          const entry = { subpolicy_id, tenant };
          policyMap[policy_id] = Array.isArray(policyMap[policy_id]) ? [...policyMap[policy_id], entry] : [entry];
        }
      });
      return policyMap;
    }, {});
  }

  // Get an array of subpolicies
  getSubpolicies(tenantIds) {
    const policies = this.models
      // optionally filter models to only the selected tenants
      .filter((model) => !tenantIds || tenantIds.length === 0 || tenantIds.includes(`${model.id}`))
      .map((model) => model.get('config.alerts') || []);

    // return a single level flattened array
    return flatten(policies);
  }

  // Get an array of subpolicy rule ids
  getSubpolicyRuleIds(tenantIds) {
    const ruleIds = this.models
      // optionally filter models to only the selected tenants
      .filter((model) => !tenantIds || tenantIds.length === 0 || tenantIds.includes(`${model.id}`))
      .map((model) => {
        const alerts = model.get('config.alerts') || [];
        // return the array of policy rule ids
        return alerts.map((alert) => alert.activationSettings?.alertManagerConfig?.ruleId).filter((ruleId) => !!ruleId);
      });

    // return a single level flattened array
    return flatten(ruleIds);
  }

  async fetch(...args) {
    return $notifications.collection.fetch().then(() => super.fetch(...args));
  }
}

export default TenantCollection;
