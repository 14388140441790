import React from 'react';
import { Text, Box, Heading, ButtonGroup, Button, Flex, Icon } from 'core/components';
import { FiBriefcase, FiPaperclip, FiArrowUpRight } from 'react-icons/fi';
import KentikLogo from 'app/components/KentikLogo';
import $app from '../$app';

const Logo = () => <KentikLogo onlyMark style={{ width: 80 }} />;

export const V4_LANDING = {
  id: 'v4-landing',
  hideFooterButtons: true,
  content: (tour) => (
    <Box>
      <Box px={3} runningText textAlign="center">
        <Logo />

        <Heading fontWeight="heavy" level={3} mt={0}>
          Kentik is AIOps for Network Professionals
        </Heading>

        <Text as="p" runningText large color="muted" textAlign="left">
          Thanks for Kentik’s new V4 release! This release is packed with new features, and we’re excited to get you up
          to speed. We have put together a quick guide to help you get oriented; get started by clicking the button
          below, or choose a topic to skip to that part of the tour.
        </Text>
      </Box>

      <Box maxWidth={500} mx="auto" my={3}>
        <Button large fill height={60} intent="primary" onClick={() => tour.showStep('new-stuff')}>
          <Flex justifyContent="center" alignItems="center">
            <Icon icon="play" iconSize={20} />
            <Text fontSize={20} fontWeight={600} ml={1}>
              Start the Tour
            </Text>
          </Flex>
        </Button>
      </Box>

      <Flex maxWidth={600} mx="auto" mt={2} mb={3}>
        <Box flex={1}>
          <ButtonGroup fill minimal vertical>
            <Button
              textAlign="left"
              icon="star-empty"
              rightIcon="chevron-right"
              onClick={() => tour.showStep('new-stuff')}
            >
              What&apos;s new?
            </Button>
            <Button
              textAlign="left"
              icon={FiBriefcase}
              rightIcon="chevron-right"
              onClick={() => tour.showStep('old-stuff')}
            >
              Where are existing features?
            </Button>
          </ButtonGroup>
        </Box>
        <Box flex={1}>
          <ButtonGroup fill minimal>
            <Button
              minimal
              textAlign="left"
              icon={FiPaperclip}
              rightIcon={FiArrowUpRight}
              onClick={() => window.open('https://kb.kentik.com/v4/index.html')}
            >
              View Documentation
            </Button>
          </ButtonGroup>
        </Box>
      </Flex>
    </Box>
  ),
  footer: () => null
};

export const NEW_STUFF = {
  id: 'new-stuff',
  hideFooterButtons: true,
  content: (
    <Box px={3} runningText textAlign="center">
      <Logo />

      <Heading fontWeight="heavy" level={3} mt={0}>
        What&apos;s new?
      </Heading>
      <Text as="p" runningText large textAlign="left" mb={2}>
        The Kentik V4 portal is focused on making it easier for you to manage your network and meet your business goals
        by leveraging your traffic data. We accomplish this via both insights and workflows.
      </Text>

      <Box as="ul" runningText textAlign="left" mb={2}>
        <Box as="li" large>
          <Text fontWeight="bold">Insights</Text> automatically advise you of interesting or anomalous behaviors that
          have been intelligently discovered by our platform.
        </Box>
        <Box as="li" large>
          <Text fontWeight="bold">Workflows</Text> are built to provide actionable information you need to understand
          and solve specific use cases.
        </Box>
      </Box>
    </Box>
  )
};

export const OLD_STUFF = {
  id: 'old-stuff',
  hideFooterButtons: true,
  content: (
    <Box px={3} runningText textAlign="center">
      <Logo />

      <Heading fontWeight="heavy" level={3} mt={0}>
        Where are existing features?
      </Heading>
      <Text as="p" runningText large textAlign="left" mb={2}>
        Don&apos;t worry! Library, Data Explorer, and Alerting are all still available to you! And, just in case, the
        previous Kentik version (V3) is still available to you. Click Next to see how to access these features in this
        release.
      </Text>
    </Box>
  )
};

export const COMING_SOON = {
  id: 'coming-soon',
  hideFooterButtons: true,
  content: (
    <Box px={3} runningText textAlign="center">
      <Logo />

      <Heading fontWeight="heavy" level={3} mt={0}>
        What&apos;s coming soon?
      </Heading>
      <Text as="p" runningText large textAlign="left" mb={2}>
        More capabilities are coming, including more comprehensive coverage of Peering Analytics and DDoS Protection use
        cases. We are also developing new workflows such as:
      </Text>
      <Box as="ul" runningText textAlign="left" mb={2}>
        <Box as="li" large>
          <Text fontWeight="bold">Container Networking</Text> - Visualize service communication traffic (e.g.
          pod-to-pod) by tapping into the overlay network and alerting upon traffic anomalies that may indicate
          performance problems or service degradation.
        </Box>
        <Box as="li" large>
          <Text fontWeight="bold">Subscriber Intelligence</Text> - Provides the ability to tag traffic records with
          subscriber ID, to improve customer service efficiency and proactively discover service issues, network abuse,
          or application usage that impacts customer experience.
        </Box>
        <Box as="li" large>
          <Text fontWeight="bold">MyKentik Portal </Text> - Self-service analytics portal that enables curated,
          self-service network traffic visibility for downstream customers.
        </Box>
      </Box>
    </Box>
  )
};

export const HELP_FEEDBACK = {
  id: 'help-feedback',
  position: 'bottom',
  title: 'Help and Feedback',
  content: (
    <Box>
      If you have any questions, issues, feature requests, or any other type of feedback, please reach out to our team
      by using the Give Feedback button. This button will automatically provide Kentik with diagnostic data to help us
      understand what you are trying to accomplish in the application.
    </Box>
  )
};

export const SEARCH = {
  id: 'search',
  position: 'bottom',
  title: 'Search',
  content: (
    <Box>
      We’ve revamped our search feature to make finding your data fast and easy. Search for IP addresses, ASNs,
      interfaces or devices and jump directly into the details.
    </Box>
  ),
  onActivate: () => {
    $app.showSearch();
  },
  onDeactivate: () => {
    $app.hideSearch();
  }
};

export const INSIGHTS = {
  id: 'insights',
  position: 'left-top',
  title: 'Insights Drawer',
  content: (
    <Box>
      The Insights Drawer is where Kentik delivers interesting, actionable facts about your network, delivered in real
      time. Insights shown here are automatically generated or can be configured manually, and will be filtered to
      relevant content on a page-by-page basis.
    </Box>
  ),
  onActivate: ({ toggleMenu }) => {
    toggleMenu(true);
  },
  onDeactivate: ({ toggleMenu }) => {
    toggleMenu(false);
  }
};

export const OPEN_TOUR = {
  id: 'open-tour',
  position: 'bottom',
  title: 'Re-open Tour',
  content: 'If you would like to take this tour again at any point, the link will be here!'
};

export const NETWORK_EXPLORER = {
  id: 'network-explorer',
  title: 'Network Explorer',
  position: 'left-top',
  content: (
    <Box>
      <Text as="div">
        Network Explorer is a suite of built-in, interactive visualizations of your data that help you analyze traffic
        and drill in according to any context, such as:
      </Text>
      <Box as="ul">
        <Box as="li">Applications</Box>
        <Box as="li">IP &amp; BGP Routing</Box>
        <Box as="li">GeoIP</Box>
        <Box as="li">Network and Traffic Map</Box>
      </Box>
    </Box>
  )
};

export const WORKFLOWS = {
  id: 'workflows',
  title: 'Workflows',
  position: 'left-top',
  content: (
    <Box>
      <Text as="div" mb={2}>
        Next, let&apos;s explore <Text fontWeight="bold">workflows</Text>, a brand new set of tools designed to help you
        manage and monitor your network traffic. There are four main modules, or groups of related workflows, in Kentik:
        Core, Edge, Protect, and Service Provider.
      </Text>
    </Box>
  )
};

export const CORE_WORKFLOWS = {
  id: 'core-workflows',
  title: 'Core Workflows',
  position: 'left-top',
  content: (
    <Box>
      <Text as="div" mb={2}>
        Our <Text fontWeight="bold">Core</Text> module aims to help you maintain your network and understand your
        traffic patterns.
      </Text>
      <Text as="div" fontWeight="bold">
        Capacity Planning
      </Text>
      Manage how traffic volume on your interfaces compares with targets defined in capacity plans that specify
      utilization targets for groups of interfaces.
    </Box>
  )
};

export const EDGE_WORKFLOWS = {
  id: 'edge-workflows',
  title: 'Edge Workflows',
  position: 'left-top',
  content: (
    <Box>
      <Text as="div" mb={2}>
        Next, let&apos;s explore the <Text fontWeight="bold">Edge</Text> module, a set of tools designed to help
        infrastructure teams manage and monitor their external connectivity.
      </Text>
      <Box mb={2}>
        <Text as="div" fontWeight="bold">
          Connectivity Costs
        </Text>
        Monitor and control costs for external transit/peering links and estimate network costs, predict cost overages,
        and help Ops teams to proactively shift traffic to alternative paths when available.
      </Box>

      <Box mb={1}>
        <Text as="div" fontWeight="bold">
          Traffic Engineering <Text muted>(found in the Menu)</Text>
        </Text>
        Workflow to identify logical traffic groups to shift from congested links to resolve conditions that lead to
        service impacting incidents.
      </Box>

      <Box>
        <Text as="div" fontWeight="bold">
          Peering &amp; Interconnection
        </Text>
        Discover new potential peers, what facilities they share with you, and how much traffic you can offload or
        optimize by peering with a new network.
      </Box>
    </Box>
  )
};

export const PROTECT_WORKFLOWS = {
  id: 'protect-workflows',
  title: 'Protect Workflows',
  position: 'left-top',
  content: (
    <Box>
      <Text as="div" mb={2}>
        The <Text fontWeight="bold">Protect</Text> module focuses on aspects of network security such as DDoS, Threat
        Feeds, and BGP RPKI.
      </Text>
      <Box>
        <Text as="div" fontWeight="bold">
          DDoS Protection
        </Text>
        Monitor dozens of common volumetric attack profiles on your network, and mitigate attacks in real time.
      </Box>
    </Box>
  )
};

export const SERVICE_PROVIDER_WORKFLOWS = {
  id: 'service-provider-workflows',
  title: 'Service Provider Workflows',
  position: 'left-top',
  content: (
    <Box>
      <Text as="div" mb={2}>
        Finally, if you are a service provider, our <Text fontWeight="bold">Service Provider</Text> module can help you
        gain industry-leading business context from your network and traffic data.
      </Text>
      {/* <Box mb={2}> */}
      {/*  <Text as="div" fontWeight="bold"> */}
      {/*    Customer Analytics */}
      {/*  </Text> */}
      {/*  Find prospective customers, or analyze and troubleshoot existing customers in areas such as cost or routing. */}
      {/* </Box> */}

      <Box mb={2}>
        <Text as="div" fontWeight="bold">
          CDN Analytics
        </Text>
        Visualize the traffic that’s coming into your network from CDNs, either directly or via embedded caches.
      </Box>

      <Box>
        <Text as="div" fontWeight="bold">
          OTT Service Tracking <Text muted>(found in the Menu)</Text>
        </Text>
        Identify the Over the Top services that generate traffic that impacts your network.
      </Box>
    </Box>
  )
};

export const NETWORK_MAP = {
  id: 'network-map',
  position: 'left-top',
  title: 'Network Map',
  content: (
    <Box>
      See the devices, sites, and service providers that make up your network environment, the links between them, and
      the real-time traffic on those links. Investigate common conditions without running queries, and share views with
      less network-savvy colleagues.
    </Box>
  )
};

export const EXPLORER = {
  id: 'explorer',
  position: 'left',
  title: 'Data Explorer',
  content: (
    <Box>
      A newly redesigned experience can be found as a part of <Text fontWeight="bold">Core</Text>.
    </Box>
  )
};

export const LIBRARY = {
  id: 'library',
  position: 'left',
  title: 'Library',
  content: <Box>Find your dashboards, saved views, and Kentik-curated content in one convenient location.</Box>
};

export const ALERTING = {
  id: 'alerting',
  position: 'left',
  title: 'Insights & Alerting',
  content: (
    <Box>
      V3 Alerting has been integrated into our Insights product. You can manage your V3 alert policies via{' '}
      <Text fontWeight="bold">Custom Insights</Text>.
    </Box>
  )
};

export const NAVMENU = {
  id: 'navmenu',
  position: 'bottom-left',
  title: 'Navigation Menu',
  content: <Box>You can find all of these things and more in our new navigation menu by clicking this button.</Box>
};

export const NAVMENU_PRODUCTS = {
  id: 'navmenu-products',
  position: 'bottom',
  title: 'Modules',
  content: (
    <Box>Our product is organized into feature modules, each of which groups together workflows of a common theme.</Box>
  ),
  onActivate: ({ onMenuToggle }) => {
    onMenuToggle(true);
  },
  onDeactivate: ({ onMenuToggle }) => {
    onMenuToggle(false);
  }
};

export const NAVMENU_SETTINGS = {
  id: 'navmenu-settings',
  position: 'bottom',
  title: 'Platform Settings, Licenses and Integrations',
  content: (
    <Box>
      Here you will find Settings (formerly known as Admin), your license information, and list of integrations Kentik
      provides out-of-the-box.
    </Box>
  ),
  onActivate: ({ onMenuToggle }) => {
    onMenuToggle(true);
  },
  onDeactivate: ({ onMenuToggle }) => {
    onMenuToggle(false);
  }
};
