import { computed } from 'mobx';
import { get } from 'lodash';

import $auth from 'app/stores/$auth';
import $insights from 'app/stores/insight/$insights';
import { HTTP_HEADER_REGEX } from '@kentik/ui-shared/util/regex';
import Model from 'core/model/Model';
import {
  getChannelUsageMap,
  NOTIFICATION_OAUTH_BLANK_SECRET_VALUE
} from '@kentik/ui-shared/notifications/notificationUtilities';
import $alerting from 'app/stores/alerting/$alerting';
import $mitigations from 'app/stores/mitigations/$mitigations';
import $mkp from 'app/stores/mkp/$mkp';
import $syn from 'app/stores/synthetics/$syn';

class NotificationModel extends Model {
  get urlRoot() {
    return '/api/ui/notifications';
  }

  get sortValues() {
    return {
      name: () => this.get('name').trim().toLowerCase()
    };
  }

  get defaults() {
    return {
      channelType: 'email',
      insightNames: [],
      insightFamilies: []
    };
  }

  get id() {
    if (this.has('channelID')) {
      return this.get('channelID');
    }

    return this.optimisticId;
  }

  get(attribute) {
    if (attribute === 'id') {
      return this.id;
    }

    return super.get(attribute);
  }

  @computed
  get dailyDigest() {
    const config = this.get('config');
    const dailyTime = get(config, 'batchingConfig.dailyTimesToSend[0]');
    const insightsDigest = get(config, 'renderingConfig.insightsDigest');
    return !!(insightsDigest && dailyTime);
  }

  @computed
  get channelIsInUse() {
    // if the channel is in use if it is attached to a mitigation / policy etc. - currently used for pre-delete checks
    // a channel is in use if there are conjunctions on it
    return (this.attributes.config.selectorConfig?.conjunctions?.length || 0) > 0;
  }

  @computed
  get usedBySummary() {
    const { policyCollection } = $alerting;
    const { methodsCollection } = $mitigations;
    const usageMap = getChannelUsageMap(policyCollection, methodsCollection, $syn.tests, $mkp.tenants, this);

    return {
      policies: Object.keys(usageMap.policies),
      mitigationMethod: usageMap.mitigationMethods,
      insights: usageMap.insights,
      insightFamilies: usageMap.insightFamilies,
      subpolicies: Object.values(usageMap.subpolicies),
      synthTest: Object.keys(usageMap.synthTest)
    };
  }

  @computed
  get isNew() {
    return !this.get('channelID');
  }

  get isActiveUserChannel() {
    const config = this.get('config');
    const userChannelUserID = get(config, 'userChannelUserID');
    return userChannelUserID === $auth.getActiveUserProperty('id');
  }

  getInsights(conjunctions) {
    const insightNames = [];
    const insightFamilies = [];

    conjunctions.forEach((conjunction) => {
      conjunction.selectors.forEach((selector) => {
        if (selector.lhs === 'insightName') {
          insightNames.push(selector.rhs);
        }
        if (selector.lhs === 'insightFamily') {
          insightFamilies.push(selector.rhs);
        }
      });
    });

    return {
      insightNames,
      insightFamilies
    };
  }

  hasInsightName = (name) => this.get('insightNames').includes(name);

  hasInsightFamily = (name) => this.get('insightFamilies').includes(name);

  removeAssociation = (associationModel) => {
    const notificationConfig = this.get('config');
    const { conjunctions } = notificationConfig.selectorConfig;
    const newAttributes = {};

    notificationConfig.selectorConfig.conjunctions = conjunctions.filter((conjunction) => {
      const { selectors } = conjunction;

      return !selectors.find(
        (selector) =>
          (selector.lhs === associationModel.get('lhs') && `${selector.rhs}` === `${associationModel.id}`) ||
          `${selector.rhs}` === `${associationModel.get('associationId')}`
      );
    });

    newAttributes.config = notificationConfig;

    if (associationModel.get('where') === 'Insight') {
      newAttributes.insightNames = (this.get('insightNames') || []).filter(
        (insightName) => insightName !== associationModel.id
      );
    }

    if (associationModel.get('where') === 'Insight Family') {
      newAttributes.insightFamilies = (this.get('insightFamilies') || []).filter(
        (family) => family !== associationModel.id
      );
    }

    return this.save(newAttributes);
  };

  deserialize(data) {
    if (data) {
      const deserializedData =
        data && data.channels && Array.isArray(data.channels)
          ? super.deserialize(data.channels[0])
          : super.deserialize(data);

      const conjunctions = get(deserializedData, 'config.selectorConfig.conjunctions', []);

      deserializedData.config = deserializedData.config || {};
      deserializedData.config.selectorConfig = deserializedData.config.selectorConfig || {};
      deserializedData.config.selectorConfig.conjunctions = conjunctions;
      deserializedData.config.syslogConfig = deserializedData.config.syslogConfig || {};

      const { insightNames, insightFamilies } = this.getInsights(conjunctions);

      const { sendingConfig } = deserializedData.config;

      // custom headers - turn { foo: 123, bar: 'abc' } into ['foo: 123', 'bar: abc']
      if (sendingConfig?.customHeaders) {
        const { customHeaders } = deserializedData.config.sendingConfig;
        deserializedData.config.sendingConfig.customHeaders = Object.keys(customHeaders).map(
          (key) => `${key}: ${customHeaders[key]}`
        );
      }

      // oauth handling
      if (sendingConfig?.credentialsVaultHandle) {
        // if there's a vault handle, there won't be client id and secret, replace them with asterisk
        sendingConfig.oauth2ClientId = NOTIFICATION_OAUTH_BLANK_SECRET_VALUE;
        sendingConfig.oauth2ClientSecret = NOTIFICATION_OAUTH_BLANK_SECRET_VALUE;
      }

      if (sendingConfig?.oauth2Scopes) {
        sendingConfig.oauth2Scopes = sendingConfig.oauth2Scopes.join(',');
      }

      // if not specifically added, make sure these are booleans
      if (sendingConfig) {
        sendingConfig.oauth2TlsVerify = sendingConfig.oauth2TlsVerify === true;
        sendingConfig.requestTlsVerify = sendingConfig.requestTlsVerify === true;
      }

      // api likes to send these as strings
      deserializedData.config.syslogConfig.facility = parseInt(deserializedData.config.syslogConfig.facility || 0);
      deserializedData.config.syslogConfig.port = parseInt(deserializedData.config.syslogConfig.port || 0);
      deserializedData.config.syslogConfig.severity = parseInt(deserializedData.config.syslogConfig.severity || 0);
      deserializedData.config.userChannelUserID = parseInt(deserializedData.config.userChannelUserID || 0);

      return {
        ...deserializedData,
        enabled: deserializedData && deserializedData.status === 'enabled',
        insightNames,
        insightFamilies
      };
    }

    return super.deserialize(data);
  }

  // simple insight selector
  buildSelector = (lhs, rhs) => ({ selectors: [{ lhs, op: '=', rhs }] });

  get omitDuringSerialize() {
    return [];
  }

  serialize(data) {
    const { id, creationTime, editTime, enabled, insightNames, insightFamilies, config, ...otherData } = data;

    const status = enabled ? 'enabled' : 'disabled';
    const names = Array.isArray(insightNames) ? insightNames : [];
    let families = Array.isArray(insightFamilies) ? insightFamilies : [];

    // remove unknown families
    families = families.filter((family) => $insights.definitionCollection.insightFamilies.includes(family));

    const nameSelectors = names.map((name) => this.buildSelector('insightName', name));
    const familySelectors = families.map((name) => this.buildSelector('insightFamily', name));

    // remove any existing insight selectors
    let conjunctions = get(config, 'selectorConfig.conjunctions', []).slice(0);
    conjunctions = conjunctions.filter(
      (conjunction) =>
        conjunction.selectors.length > 1 ||
        (conjunction.selectors.length === 1 && !['insightName', 'insightFamily'].includes(conjunction.selectors[0].lhs))
    );

    // add new insight selectors
    config.selectorConfig = {
      conjunctions: conjunctions.concat(nameSelectors, familySelectors)
    };

    // daily digest times
    if (!get(config, 'renderingConfig.insightsDigest')) {
      config.batchingConfig = {
        ...config.batchingConfig,
        dailyTimesToSend: []
      };
    }

    const { sendingConfig } = config;

    // custom headers - turn ['foo: 123', 'bar: abc'] into { foo: 123, bar: 'abc' }
    if (sendingConfig?.customHeaders) {
      sendingConfig.customHeaders = sendingConfig.customHeaders.reduce((acc, header) => {
        const regexResults = header.match(HTTP_HEADER_REGEX);

        if (regexResults?.groups?.key && regexResults.groups.value) {
          acc[regexResults.groups.key] = regexResults.groups.value.trim();
        }

        return acc;
      }, {});
    }

    // oauth handling
    if (
      sendingConfig?.credentialsVaultHandle &&
      (sendingConfig?.oauth2ClientId === NOTIFICATION_OAUTH_BLANK_SECRET_VALUE ||
        sendingConfig?.oauth2ClientSecret === NOTIFICATION_OAUTH_BLANK_SECRET_VALUE)
    ) {
      // if there's a credentials vault handle and the user didn't update the client id and secret, don't let the blank values go through
      delete sendingConfig.oauth2ClientId;
      delete sendingConfig.oauth2ClientSecret;
    }

    if (sendingConfig?.oauth2Scopes) {
      sendingConfig.oauth2Scopes = sendingConfig.oauth2Scopes.split(',');
    } else {
      delete sendingConfig.oauth2Scopes;
    }

    // api chokes if these are not numeric
    config.syslogConfig.facility = parseInt(config.syslogConfig.facility);
    config.syslogConfig.port = parseInt(config.syslogConfig.port);
    config.syslogConfig.severity = parseInt(config.syslogConfig.severity);
    config.userChannelUserID = parseInt(config.userChannelUserID);

    this.set({ status, enabled, id: data.channelID });

    return super.serialize({
      ...otherData,
      config,
      status,
      insightNames: names,
      insightFamilies: families
    });
  }
}

export default NotificationModel;
