import InterfaceGroupInterfaceCollection from 'app/stores/interfaceGroup/InterfaceGroupInterfaceCollection';
import CapacityInterfaceGroupInterface from 'app/stores/capacityPlan/CapacityInterfaceGroupInterface';
import { action } from 'mobx';
import $dictionary from 'app/stores/$dictionary';
import { formatBytesGreek } from 'core/util';

export default class CapacityInterfaceGroupInterfaceCollection extends InterfaceGroupInterfaceCollection {
  get model() {
    return CapacityInterfaceGroupInterface;
  }

  @action
  set = (models) => {
    this.original = [];
    this.add(models);
    this.setLastUpdated();
    setTimeout(() => this.sort('util', 'desc'), 0); // this is a hack because cant defaultSort a computed field - thanks mobx.
  };

  @action
  group(value) {
    this.groupBy = value
      ? {
          value,
          groupByFn: (model) => model[value] || model.get(value) || 'None'
        }
      : null;
  }

  get filterFieldBlacklist() {
    return new Set(['connectivity_type', 'snmp_speed']);
  }

  getFilterValues(model) {
    const values = super.getFilterValues(model);
    values.push($dictionary.get(`interfaceClassification.connectivityTypes.${model.get('connectivity_type')}`));
    values.push(formatBytesGreek(model.get('snmp_speed') * 1000000, ''));
    return values;
  }
}
